import "./BasicDetailsSection.css";

import { Link } from "react-router-dom";

import { ValidatorConstants } from "../../../../../../constants";
import { GetProjectDetailsResponse } from "../../../../../../service/publicquery";
import { getCountryNameByCode } from "../../../../../../utils";
import { getPublicValidatorRoute } from "../../../../../../utils/routes";
import { Column, ReadonlyText, Row } from "../../../../../../widget";
import { useBasicDetailsSection } from "./useBasicDetailsSection";

interface BasicDetailsSectionProps {
  projectDetails: GetProjectDetailsResponse;
}

export const BasicDetailsSection = ({ projectDetails }: BasicDetailsSectionProps): JSX.Element => {
  const { totalProjectArea } = useBasicDetailsSection(projectDetails);

  return (
    <div>
      <Row spacingV="ll">
        <Column span={6}>
          <h2>Details</h2>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={5}>
          <ReadonlyText label="Registry name" value={projectDetails.name} />
        </Column>
        <Column span={5}>
          <ReadonlyText
            label="Registry ID"
            value={projectDetails.projectReference?.externalReference || "Not available"}
            noDataAvailable={!projectDetails.projectReference?.externalReference}
          />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={5}>
          <ReadonlyText label="Project type" value={projectDetails.projectType.displayName} />
        </Column>
        <Column span={5}>
          <ReadonlyText
            label="Total area"
            value={totalProjectArea || "Not available"}
            noDataAvailable={!totalProjectArea}
          />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={5}>
          <ReadonlyText
            label="Grid reference"
            value={projectDetails.locationGridReference || "No grid reference available"}
            noDataAvailable={!projectDetails.locationGridReference}
          />
        </Column>
        <Column span={5}>
          <ReadonlyText
            label="County / Region / State"
            value={projectDetails.addressRegion || "No location available"}
            noDataAvailable={!projectDetails.addressRegion}
          />
        </Column>
      </Row>
      <Row>
        <Column span={5}>
          <ReadonlyText
            label="Country"
            value={getCountryNameByCode(projectDetails.addressCountryCode) || "No location available"}
            noDataAvailable={!projectDetails.addressCountryCode}
          />
        </Column>
        <Column span={5}>
          <Row spacingV="m">
            <Column span={12}>
              <h3>Validator</h3>
            </Column>
          </Row>
          <Row align="center" className="BasicDetailsSection_ValidatorContainer">
            {projectDetails.validator?.files && projectDetails.validator?.files.length > 0 && (
              <div className="ImageContainer">
                <img src={projectDetails.validator.files[0].file.url} alt="ValidatorLogo" />
              </div>
            )}
            <Column span={9}>
              {projectDetails.validator?.uuid &&
              ValidatorConstants.VALIDATOR_UUIDS.includes(projectDetails.validator?.uuid) ? (
                <Link
                  to={{
                    pathname: getPublicValidatorRoute(projectDetails?.validator?.uuid),
                    search: `?projectUuid=${projectDetails.uuid}`,
                  }}
                >
                  {projectDetails?.validator?.displayName}
                </Link>
              ) : (
                <p className="SectionText body1">
                  {projectDetails.validator?.displayName || "No validator selected for this project"}
                </p>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
    </div>
  );
};
