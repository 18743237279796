import { OrganisationTypeConstants } from "../../../../../../../../constants";
import { useAuth } from "../../../../../../../../useAuth";
import { BarChart, Column, PieChart, Row } from "../../../../../../../../widget";
import { StandardSpecificData, Step } from "../types";
import { reduceChartComponents, reduceChartComponentsWithSuffix } from "../utils";

interface RenderChartProps {
  step: Step;
  key: string;
  data: StandardSpecificData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any;
}

export const RenderChart = ({ step, key, data, expressionContext }: RenderChartProps): JSX.Element => {
  const { currentUserType } = useAuth();
  if (!step.components) {
    return <div />;
  }
  switch (true) {
    case step.components.every((component) => component.componentProperties?.chartType === "Doughnut"):
      return (
        <Row>
          <Column span={12}>
            <PieChart
              size={160}
              centerTextHidden
              data={reduceChartComponents(step.components, data, `${key}.${step.key}`, expressionContext)}
              hasSuffix
              dataWithSuffix={reduceChartComponentsWithSuffix(
                step.components,
                data,
                `${key}.${step.key}`,
                expressionContext
              )}
              isGradient={currentUserType === OrganisationTypeConstants.ASSET_MANAGER}
            />
          </Column>
        </Row>
      );
    case step.components.every((component) => component.componentProperties?.chartType === "SBar"):
      return (
        <Row>
          <Column span={12}>
            <BarChart
              data={reduceChartComponents(step.components, data, `${key}.${step.key}`, expressionContext)}
              suffix={step.components[0].componentProperties.suffix}
            />
          </Column>
        </Row>
      );
    default:
      return <div />;
  }
};
