import "./Pagination.css";

import React from "react";

import { ArrowLeftDarkIcon, ArrowRightDarkIcon } from "../../../assets";
import { DOTS, usePagination } from "./usePagination";

export interface PaginationProps {
  onPageChange: (currentPage: number) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
}

export const Pagination: React.FC<PaginationProps> = (props): JSX.Element | null => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage = 1, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  return paginationRange ? (
    <div className="Pagination">
      <button className={`PaginationItem ${currentPage === 1 ? "disabled" : ""}`} onClick={onPrevious}>
        <ArrowLeftDarkIcon />
      </button>
      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span key={idx} className="PaginationItem Dots">
              &#8230;
            </span>
          );
        }

        return (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className={`PaginationItem ${pageNumber === currentPage ? "selected" : ""}`}
            onClick={() => onPageChange(+pageNumber)}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        className={`PaginationItem ${currentPage === paginationRange[paginationRange.length - 1] ? "disabled" : ""}`}
        onClick={onNext}
      >
        <ArrowRightDarkIcon />
      </button>
    </div>
  ) : null;
};
