import "./IconPill.css";

import React from "react";

import { capitalize } from "../../../utils";

export type IconPillVariant = "coral";

interface IconPillProps {
  variant: IconPillVariant;
  label: string;
  link?: string;
  icon?: React.ReactNode;
}

export const IconPill = ({ label, variant, link, icon }: IconPillProps): JSX.Element => {
  return link ? (
    <a href={link} target="_blank" rel="noopener noreferrer" className={`IconPill IconPill${capitalize(variant)} Link`}>
      {icon && <div className="IconPillIcon">{icon}</div>}
      <p className="body2">{label}</p>
    </a>
  ) : (
    <div className={`IconPill IconPill${capitalize(variant)}`}>
      {icon && <div className="IconPillIcon">{icon}</div>}
      <p className="body2">{label}</p>
    </div>
  );
};
