import "./TextArea.css";

import { ReactNode, Ref } from "react";

import { FormLabel } from "../../FormLabel";
import { useTextArea } from "./useTextArea";

export interface TextAreaProps {
  name: string;
  label?: string;
  allowLabelDangerousHtml?: boolean;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  resizable?: boolean;
  optional?: boolean;
  hasResize?: boolean;
  maxLength?: number;
  displayCharacterCount?: boolean;
  tooltip?: ReactNode;
  debounceMilliseconds?: number;
  onChange: (value: string) => void;
  forwardRef?: Ref<HTMLTextAreaElement>;
}

export const TextArea = ({
  name,
  label,
  allowLabelDangerousHtml,
  value,
  placeholder,
  disabled = false,
  resizable = false,
  optional = false,
  hasResize = false,
  maxLength = 500,
  displayCharacterCount = false,
  tooltip,
  debounceMilliseconds,
  forwardRef,
  onChange,
}: TextAreaProps): JSX.Element => {
  const { currentValue, onTextAreaChange } = useTextArea(value, debounceMilliseconds, onChange);

  return (
    <div
      className={`form_field TextArea ${disabled ? `TextArea_disabled` : ""} ${resizable ? `TextArea_resizable` : ""}`}
    >
      <FormLabel label={label} optional={optional} tooltip={tooltip} allowDangerousHtml={allowLabelDangerousHtml}>
        <textarea
          style={{ resize: hasResize ? "both" : "none" }}
          className="body1"
          id={name}
          name={name}
          value={currentValue}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onTextAreaChange}
          maxLength={maxLength}
          ref={forwardRef}
        />
        {displayCharacterCount && (
          <div className="body2 TextAreaCharacterCount">
            {currentValue.length || 0}/{maxLength}
          </div>
        )}
      </FormLabel>
    </div>
  );
};
