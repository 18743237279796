import { useCallback, useEffect, useState } from "react";

import { ProjectActivitiesConstants } from "../../../constants";
import { logError } from "../../../service/error";
import { getActivityAggregate } from "../../../service/query";
import { Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { ChartData } from "../../../widget";

interface UseActivitiesReturnData {
  activityTypeChartData: ChartData;
  activityStatusChartData: ChartData;
  activityCodeChartData: ChartData;
}
export const useActivities = (): UseActivitiesReturnData => {
  const { currentDeveloperUuid } = useAuth();

  const [activityTypeChartData, setActivityTypeChartData] = useState<ChartData>({});
  const [activityStatusChartData, setActivityStatusChartData] = useState<ChartData>({});
  const [activityCodeChartData, setActivityCodeChartData] = useState<ChartData>({});

  const fetchData = useCallback(async (): Promise<void> => {
    if (currentDeveloperUuid) {
      const [activityTypeRes, activityStatusRes, activityCodeRes] = await Promise.all([
        getActivityAggregate({
          groupBy: "activityDefinition.displayName",
          filterBy: "developer",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDeveloperUuid,
        }),
        getActivityAggregate({
          groupBy: "activity.status",
          filterBy: "developer",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDeveloperUuid,
        }),
        getActivityAggregate({
          groupBy: "activityDefinition.standard.displayName",
          filterBy: "developer",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDeveloperUuid,
        }),
      ]);

      if (activityTypeRes.status === Status.Success && activityTypeRes.data) {
        setActivityTypeChartData(
          activityTypeRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }

      if (activityStatusRes.status === Status.Success && activityStatusRes.data) {
        setActivityStatusChartData(
          activityStatusRes.data.reduce<ChartData>((previous, current) => {
            switch (current.key) {
              case ProjectActivitiesConstants.STATUS_IN_PROGRESS:
              case ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED:
                // eslint-disable-next-line no-param-reassign
                previous[ProjectActivitiesConstants.STATUS_IN_PROGRESS] =
                  +current.value +
                  (previous[ProjectActivitiesConstants.STATUS_IN_PROGRESS] !== undefined
                    ? previous[ProjectActivitiesConstants.STATUS_IN_PROGRESS]
                    : 0);
                break;
              case ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED:
                // eslint-disable-next-line no-param-reassign
                previous[ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED] = +current.value;
                break;
              case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_VVB:
              case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_REGISTRY:
              case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE:
              case ProjectActivitiesConstants.STATUS_ASSESSMENT_ASSIGNED:
              case ProjectActivitiesConstants.STATUS_ASSESSMENT_IN_PROGRESS:
              case ProjectActivitiesConstants.STATUS_ASSESSMENT_AWAITING_REVIEW:
              case ProjectActivitiesConstants.STATUS_REVIEW_ASSIGNED:
              case ProjectActivitiesConstants.STATUS_REVIEW_IN_PROGRESS:
              case ProjectActivitiesConstants.STATUS_REVIEW_COMPLETE:
                // eslint-disable-next-line no-param-reassign
                previous["In review"] =
                  +current.value + (previous["In review"] !== undefined ? previous["In review"] : 0);
                break;
              case ProjectActivitiesConstants.STATUS_APPROVED:
              case ProjectActivitiesConstants.STATUS_REJECTED:
              case ProjectActivitiesConstants.STATUS_WITHDRAWN:
              case ProjectActivitiesConstants.STATUS_SUPERSEDED:
              case ProjectActivitiesConstants.STATUS_SUBMITTED:
                // eslint-disable-next-line no-param-reassign
                previous[ProjectActivitiesConstants.STATUS_COMPLETE] =
                  +current.value +
                  (previous[ProjectActivitiesConstants.STATUS_COMPLETE] !== undefined
                    ? previous[ProjectActivitiesConstants.STATUS_COMPLETE]
                    : 0);
                break;
              default:
                logError({ error: `Invalid activityStatus "${current.key}" in Activities List / Status KPI` });
            }
            return previous;
          }, {})
        );
      }

      if (activityCodeRes.status === Status.Success && activityCodeRes.data) {
        setActivityCodeChartData(
          activityCodeRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    activityTypeChartData,
    activityStatusChartData,
    activityCodeChartData,
  };
};
