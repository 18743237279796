import { PillVariant } from "../../widget";

export class ProjectTypesConstants {
  public static readonly FORESTRY_AND_WOODLAND_CREATION_UUID = "f841838b-8ea4-4854-a26d-aeeb6b5284c7";

  public static readonly HEDGEROW_AND_AGROFORESTRY_UUID = "81fe62ab-32f1-4019-9e74-40f76d8a1fb5";

  public static readonly REWILDLING_UUID = "fcd54321-6ff6-48fa-b42d-fd485205fea3";

  public static readonly PEATLAND_UUID = "7a173441-af80-4b2d-8043-f995c6ec8059";

  public static readonly SOIL_CARBON_UUID = "f4b67ac1-dcec-4e81-adab-a6fa6a96079f";

  public static readonly BIO_ENERGY_UUID = "b2962df1-f83b-4e99-885d-6eb008211b5f";

  public static readonly SEAGRASS_RESTORATION_UUID = "3d78fa92-0769-4400-8138-3f2a5bf37264";

  public static readonly CONSTRUCTED_WETLAND_UUID = "c7686b9b-3209-445a-88ce-03845168b577";

  public static readonly SALTMARSH_RESTORATION_UUID = "ee020ebb-f1f0-49d6-84d6-84c0e305aadf";

  public static readonly ENHANCED_WEATHERING_UUID = "77a61b62-429e-486a-8508-a023a876c90c";

  public static readonly BIOCHAR_UUID = "385b5d60-f97a-4652-b63e-cc6af292d1f2";

  public static readonly LIVESTOCK_FEED_UUID = "5ddd0af4-1c0b-410c-9df4-e6f76ad37cfa";

  public static readonly PROJECT_TYPES_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
    "Green project": "green",
    "Brown project": "coral",
    "Grey project": "grey",
    "Blue project": "cyan",
  };
}
