import { Link } from "react-router-dom";

import bgImage from "../../../assets/images/Landscape.jpg";
import { AuthenticationTemplate } from "../../../templates";
import { getLogonRoute } from "../../../utils/routes";
import { Column, InformationBox, Row } from "../../../widget";
import { ForgottenPasswordForm } from "./form";
import { useForgottenPassword } from "./useForgottenPassword";

export const ForgottenPassword = (): JSX.Element => {
  const { passwordReset, setPasswordReset } = useForgottenPassword();

  return (
    <AuthenticationTemplate imageSrc={bgImage}>
      <Row spacingV={passwordReset ? "ll" : "m"}>
        <Column span={12}>
          <h1>Forgotten Password</h1>
        </Column>
      </Row>
      {passwordReset ? (
        <Row spacingV="ll">
          <Column span={12}>
            <InformationBox variant="success" title="We've sent you a password reset link">
              If a Kana account exists with this email address, we will have sent you a link to reset your password.
              Please click the link and follow the instructions on screen
            </InformationBox>
          </Column>
        </Row>
      ) : (
        <>
          <Row spacingV="ll">
            <Column span={12}>
              <span className="body1">Enter your email address and we will send you a link to reset your password</span>
            </Column>
          </Row>
          <ForgottenPasswordForm onSuccess={setPasswordReset} />
        </>
      )}
      <Row>
        <Column span={12}>
          <span className="body1">
            <Link to={getLogonRoute()}>Back to log in</Link>
          </span>
        </Column>
      </Row>
    </AuthenticationTemplate>
  );
};
