import { Dispatch, SetStateAction } from "react";

/**
 * Use when we need to set a button's isLoading prop for the duration of an api call to prevent duplicate calls
 * @param fn function to wrap
 * @param setIsLoading setStateAction to change
 * @returns TReturn original fn return promise
 */
export const useIsLoadingWrapper = <TArgs extends unknown[], TReturn>(
  fn: (...args: TArgs) => Promise<TReturn>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
): ((...args: TArgs) => Promise<TReturn>) => {
  return (...args: TArgs) => {
    setIsLoading(true);
    return fn(...args).finally(() => setIsLoading(false));
  };
};
