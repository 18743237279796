import { ProjectAccessConstants } from "../../../../../../../constants";
import { ActionButtons, Autocomplete, Column, ErrorBox, Row, Select } from "../../../../../../../widget";
import { SendAccessInvitationModal } from "../../../../../components";
import { useInviteForm } from "./useInviteForm";

export const InviteForm = (): JSX.Element => {
  const {
    onSubmit,
    onCancel,
    developerUuid,
    developerList,
    onDeveloperTypeSearch,
    onDeveloperSelect,
    accessLevelKey,
    onAccessLevelChange,
    onSendInvitation,
    showSendInvitationModal,
    onCloseSendInvitationModal,
    errors,
  } = useInviteForm();

  return (
    <form onSubmit={onSubmit}>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={4}>
            <ErrorBox
              title="Please solve the below issues before sending the invitation"
              text={errors.map((error) => error.message)}
            />
          </Column>
        </Row>
      )}
      {developerList && (
        <Row spacingV="ll">
          <Column span={4}>
            <Autocomplete
              initialKey={developerUuid}
              label="Developer"
              placeholder="Start typing to find the developer"
              data={developerList}
              onSelect={onDeveloperSelect}
              onTypeSearch={onDeveloperTypeSearch}
            />
          </Column>
        </Row>
      )}
      <Row spacingV="4xl">
        <Column span={4}>
          <Select
            label="Access level"
            placeholder=""
            data={ProjectAccessConstants.ACCESS_LEVEL_ROLE_OPTIONS}
            initialKey={accessLevelKey}
            value={accessLevelKey}
            onChange={onAccessLevelChange}
          />
        </Column>
      </Row>
      <ActionButtons submitButtonLabel="Send Invitation" onCancel={onCancel} />
      <SendAccessInvitationModal
        show={showSendInvitationModal}
        onSendAccessInvitation={onSendInvitation}
        closeModal={onCloseSendInvitationModal}
      />
    </form>
  );
};
