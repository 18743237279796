import bgImage from "../../../assets/images/Mountain.jpg";
import { AuthenticationTemplate } from "../../../templates";
import { Column, Row } from "../../../widget";
import { LogonForm } from "./form";

export const Logon = (): JSX.Element => {
  return (
    <AuthenticationTemplate imageSrc={bgImage}>
      <div>
        <Row spacingV="ll">
          <Column span={12}>
            <h1>Log In</h1>
          </Column>
        </Row>
        <LogonForm />
      </div>
    </AuthenticationTemplate>
  );
};
