import "./HandleTooltipRender.css";

import { ReactElement } from "react";

import { formatNumber } from "../../../../../utils";

export const HandleTooltipRender = (
  node: ReactElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleProps: any,
  min = 0,
  max = 100,
  formatValue: ((val: number | undefined) => string | undefined) | undefined = undefined
): ReactElement => {
  const { value } = handleProps;
  // eslint-disable-next-line react/destructuring-assignment
  const isSecondHandle = node.props.className.indexOf("rc-slider-handle-2") > -1;
  // enableTooltip(id, dragging, min, max);
  return (
    <div>
      {node}
      <div
        className={`tooltip ${isSecondHandle ? "tooltip-2" : ""}`}
        style={{ left: `${((value - min) / (max - min)) * 100}%` }}
      >
        {formatValue ? formatValue(value) : formatNumber(value)}
      </div>
    </div>
  );
};
