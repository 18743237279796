// from https://github.com/ant-design/ant-design/blob/master/components/radio/group.tsx
import useMergedState from "rc-util/lib/hooks/useMergedState";
import * as React from "react";

import { RadioGroupContextProvider } from "./Context";
import type { RadioChangeEvent, RadioGroupButtonStyle, RadioGroupProps } from "./Interface";
import Radio from "./Radio";

const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      // NCU: the values seem to match here
      // eslint-disable-next-line react/prop-types
      prefixCls = "Radio",
      // eslint-disable-next-line react/prop-types
      defaultValue,
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      className = "",
      // eslint-disable-next-line react/prop-types
      options,
      // eslint-disable-next-line react/prop-types
      buttonStyle = "outline" as RadioGroupButtonStyle,
      // eslint-disable-next-line react/prop-types
      disabled,
      // eslint-disable-next-line react/prop-types
      children,
      // eslint-disable-next-line react/prop-types
      style,
      // eslint-disable-next-line react/prop-types
      id,
      // eslint-disable-next-line react/prop-types
      onMouseEnter,
      // eslint-disable-next-line react/prop-types
      onMouseLeave,
      // eslint-disable-next-line react/prop-types
      onChange,
      // eslint-disable-next-line react/prop-types
      name,
      // eslint-disable-next-line react/prop-types
      optionType,
    }: RadioGroupProps,
    ref
  ): JSX.Element => {
    const [stateValue, setValue] = useMergedState(defaultValue, {
      value,
    });

    const onRadioChange = (ev: RadioChangeEvent): void => {
      const lastValue = stateValue;
      const val = ev.target.value;
      if (value === undefined) {
        setValue(val);
      }
      if (onChange && val !== lastValue) {
        onChange(ev);
      }
    };

    const renderGroup = (): JSX.Element => {
      const groupPrefixCls = `${prefixCls}-group`;
      let childrenToRender = children;
      // 如果存在 options, 优先使用
      // eslint-disable-next-line react/prop-types
      if (options && options.length > 0) {
        // eslint-disable-next-line react/prop-types
        childrenToRender = options.map((option) => {
          if (typeof option === "string" || typeof option === "number") {
            // 此处类型自动推导为 string
            return (
              <Radio
                key={option.toString()}
                prefixCls={prefixCls}
                disabled={disabled}
                value={option}
                checked={value === option}
              >
                {option}
              </Radio>
            );
          }
          // 此处类型自动推导为 { label: string value: string }
          return (
            <Radio
              key={`radio-group-value-options-${option.value}`}
              prefixCls={prefixCls}
              disabled={option.disabled || disabled}
              value={option.value}
              checked={value === option.value}
              style={option.style}
            >
              {option.label}
            </Radio>
          );
        });
      }

      const classString = `${groupPrefixCls} ${groupPrefixCls}-${buttonStyle} ${className}`;
      return (
        <div
          className={classString}
          style={style}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          id={id}
          ref={ref}
        >
          {childrenToRender}
        </div>
      );
    };

    return (
      <RadioGroupContextProvider
        value={{
          onChange: onRadioChange,
          value,
          disabled,
          name,
          optionType,
        }}
      >
        {renderGroup()}
      </RadioGroupContextProvider>
    );
  }
);

export default React.memo(RadioGroup);
