import { Modal } from "../../../../widget";
import { RegisterInterestModalForm } from "./form";

interface RegisterInterestModalProps {
  developerName: string;
  show: boolean;
  closeModal: () => void;
}

export const RegisterInterestModal = ({ developerName, show, closeModal }: RegisterInterestModalProps): JSX.Element => {
  return (
    <Modal show={show} title="Register Interest" onClose={closeModal}>
      <RegisterInterestModalForm developerName={developerName} closeModal={closeModal} />
    </Modal>
  );
};
