import { RefObject, useEffect, useRef, useState } from "react";

import { OrganisationTypeConstants } from "../../../constants";
import { useActivityWizardContextSwitcher } from "../../../route/shared/utils/activities/useActivityWizardContextSwitcher";
import { useAuth } from "../../../useAuth";
import { useClickOutside } from "../../../utils";
import { Thread } from "../models";

interface useDiscussionProps {
  internalThreads: Thread[];
  externalThreads: Thread[];
  closeDiscussion: () => void;
}

interface useDiscussionReturnData {
  discussionRef: RefObject<HTMLDivElement>;
  showNewDiscussion: boolean;
  internalTeamSelected: boolean;
  externalSelected: boolean;
  handleInternalTeamSelect: () => void;
  handleExternalSelect: () => void;
  externalUserType: string;
  canWriteExternalDiscussion: boolean;
}

export const useDiscussion = ({
  internalThreads,
  externalThreads,
  closeDiscussion,
}: useDiscussionProps): useDiscussionReturnData => {
  const { currentUserType } = useAuth();

  const { canWriteExternalDiscussion } = useActivityWizardContextSwitcher();

  const [internalTeamSelected, setInternalTeamSelected] = useState<boolean>(false);
  const [externalSelected, setExternalSelected] = useState<boolean>(false);

  const externalUserType =
    currentUserType === OrganisationTypeConstants.DEVELOPER
      ? OrganisationTypeConstants.VERIFIER
      : OrganisationTypeConstants.DEVELOPER;

  const discussionRef = useRef<HTMLDivElement>(null);

  useClickOutside(discussionRef, () => {
    closeDiscussion();
  });

  const handleInternalTeamSelect = (): void => {
    setInternalTeamSelected(true);
    setExternalSelected(false);
  };

  const handleExternalSelect = (): void => {
    setExternalSelected(true);
    setInternalTeamSelected(false);
  };

  useEffect(() => {
    if (externalThreads.length && !internalThreads.length) {
      handleExternalSelect();
    } else {
      handleInternalTeamSelect();
    }
  }, []);

  return {
    discussionRef,
    showNewDiscussion:
      (!internalThreads.length && !externalThreads.length) || (!internalThreads.length && !canWriteExternalDiscussion),
    internalTeamSelected,
    externalSelected,
    externalUserType,
    canWriteExternalDiscussion,
    handleInternalTeamSelect,
    handleExternalSelect,
  };
};
