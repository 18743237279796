import "./CertificationsSection.css";

import { BadgeData, FileType } from "../../../../../../../models";
import { Badge, Column, Divider, Row } from "../../../../../../../widget";

interface CertificationsSectionProps {
  certificates: BadgeData[];
}

export const CertificationsSection = ({ certificates }: CertificationsSectionProps): JSX.Element => {
  return (
    <div className="CertificationsSection">
      <Row spacingV="2xl" className="CertificationsSectionDividerRow">
        <Column span={8}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <div className="CertificationsHeader">
            <h2>Certifications</h2>
          </div>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          {certificates.map((c) => (
            <Badge
              key={c.uuid}
              label={c.displayName}
              imageSrc={c.files.find((f) => f.type === FileType.SmallLogo)?.file.url}
            />
          ))}
        </Column>
      </Row>
    </div>
  );
};
