import { useEffect } from "react";

interface UseErrorBoxReturnData {
  elementId: string;
  messages: string[];
}

export const useErrorBox = (text: string | string[], animation: boolean): UseErrorBoxReturnData => {
  const elementId = `ErrorBox${new Date().getTime()}`;
  const messages = Array.isArray(text) ? text : [text];

  useEffect(() => {
    if (animation) document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return {
    elementId,
    messages,
  };
};
