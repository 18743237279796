import { BadgeDetailsData } from "../../../route/public/badge/details/page/useDetails";
import { BadgeDetails, BadgeDetailsMetaTags } from "../components";
import { usePublicBadgeDetailsPageTemplate } from "./usePublicBadgeDetailsPageTemplate";

interface PublicBadgeDetailsPageTemplateProps {
  badgeDetailsData?: BadgeDetailsData;
}

export const PublicBadgeDetailsPageTemplate = ({
  badgeDetailsData,
}: PublicBadgeDetailsPageTemplateProps): JSX.Element => {
  const { dataIsLoading, badgeUuid, badgeProjects, navigateBackToProject, showContactUsModal, setShowContactUsModal } =
    usePublicBadgeDetailsPageTemplate();
  return (
    <div>
      {!dataIsLoading && badgeDetailsData && (
        <>
          <BadgeDetailsMetaTags badgeDetailsData={badgeDetailsData} />
          <BadgeDetails
            badgeDetailsData={badgeDetailsData}
            badgeUuid={badgeUuid}
            badgeProjects={badgeProjects}
            isPreview={false}
            showContactUsModal={showContactUsModal}
            setShowContactUsModal={setShowContactUsModal}
            navigateBackToProject={navigateBackToProject}
          />
        </>
      )}
    </div>
  );
};
