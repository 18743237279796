import "./CheckboxGroup.css";

import { ReactNode } from "react";

import { FormLabel } from "../../FormLabel";
import { useCheckboxGroup } from "./useCheckboxGroup";

export interface CheckboxGroupProps {
  checkboxesData: { [key: string]: string };
  value: string[];
  onChange: (e: string[]) => void;
  label?: string;
  order?: string[];
  allowLabelDangerousHtml?: boolean;
  tooltip?: ReactNode;
}

export const CheckboxGroup = ({
  checkboxesData,
  value = [],
  onChange,
  label,
  order,
  allowLabelDangerousHtml,
  tooltip,
}: CheckboxGroupProps): JSX.Element => {
  const { checkboxes } = useCheckboxGroup({ checkboxesData, value, onChange, order });

  return (
    <div className="CheckboxGroup">
      {label && (
        <FormLabel labelAlignment="left" label={label} tooltip={tooltip} allowDangerousHtml={allowLabelDangerousHtml} />
      )}
      <div className="CheckboxGroupItems">{checkboxes}</div>
    </div>
  );
};
