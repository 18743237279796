/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from "react";

import { OrganisationListingFileType, OurExpertiseData } from "../../../../../models";
import { FormAboutUsData, FormAtAGlanceData, FormHeroData, FormOurServicesData } from "../../../../shared";
import { CodesAndStandardsData } from "../models";

// Shared form data
export const getHeroFormattedData = (content: any, listingFiles: any): FormHeroData => {
  return {
    enabled: content?.hero?.enabled ? content?.hero?.enabled : false,
    title: content?.hero?.title,
    subtitle: content?.hero?.subtitle,
    imageFileUuid: content?.hero?.imageFileUuid,
    imageFileUrl: listingFiles.find((x: any) => x.uuid === content?.hero?.imageFileUuid)?.file?.url,
  };
};

export const getAtAGlanceFormattedData = (content: any): FormAtAGlanceData => {
  return {
    enabled: content?.atAGlance?.enabled !== undefined ? content?.atAGlance?.enabled : true,
  };
};

export const getOurServicesFormattedData = (content: any, listingFiles: any): FormOurServicesData => {
  return {
    enabled: content?.ourServices?.enabled ? content?.ourServices?.enabled : false,
    part1: content?.ourServices?.part1
      ? {
          content: content?.ourServices?.part1.content,
          title: content?.ourServices?.part1.title,
          imageFileUuid: content?.ourServices?.part1.imageFileUuid,
          imageFileUrl: listingFiles.find(
            (x: OrganisationListingFileType) => x.uuid === content?.ourServices?.part1.imageFileUuid
          )?.file?.url,
        }
      : undefined,
    part2: content?.ourServices?.part2
      ? {
          content: content?.ourServices?.part2.content,
          title: content?.ourServices?.part2.title,
          imageFileUuid: content?.ourServices?.part2.imageFileUuid,
          imageFileUrl: listingFiles.find(
            (x: OrganisationListingFileType) => x.uuid === content?.ourServices?.part2.imageFileUuid
          )?.file?.url,
        }
      : undefined,
    part3: content?.ourServices?.part3
      ? {
          content: content?.ourServices?.part3.content,
          title: content?.ourServices?.part3.title,
          imageFileUuid: content?.ourServices?.part3.imageFileUuid,
          imageFileUrl: listingFiles.find(
            (x: OrganisationListingFileType) => x.uuid === content?.ourServices?.part3.imageFileUuid
          )?.file?.url,
        }
      : undefined,
  };
};

export const getAboutUsFormattedData = (content: any, listingFiles: any): FormAboutUsData => {
  return {
    enabled: content?.aboutUs?.enabled ? content?.aboutUs?.enabled : false,
    content: content?.aboutUs?.content,
    imageFileUuid: content?.aboutUs?.imageFileUuid,
    imageFileUrl: listingFiles.find((x: OrganisationListingFileType) => x.uuid === content?.aboutUs?.imageFileUuid)
      ?.file?.url,
  };
};

// Verifier specific form data
export const getOurExpertiseFormattedData = (content: any): OurExpertiseData => {
  return {
    enabled: content?.ourMission?.enabled ? content?.ourMission?.enabled : false,
    content: content?.ourMission?.content,
  };
};

export const getCodesAndStandardsFormattedData = (content: any): CodesAndStandardsData => {
  return {
    enabled: content?.standards?.enabled ? content?.standards?.enabled : false,
    content: content?.standards?.content,
    standards: content?.standards?.standards,
  };
};

// Helper functions
export const setOldDefaultValues = (
  content: any,
  listingFiles: any,
  setHeroOldDefaultValues: Dispatch<SetStateAction<FormHeroData | undefined>>,
  setOurExpertiseOldDefaultValues: Dispatch<SetStateAction<OurExpertiseData | undefined>>,
  setCodesAndStandardsOldDefaultValues: Dispatch<SetStateAction<CodesAndStandardsData | undefined>>,
  setAtAGlanceOldDefaultValues: Dispatch<SetStateAction<FormAtAGlanceData | undefined>>,
  setOurServicesOldDefaultValues: Dispatch<SetStateAction<FormOurServicesData | undefined>>,
  setAboutUsOldDefaultValues: Dispatch<SetStateAction<FormAboutUsData | undefined>>
): void => {
  setHeroOldDefaultValues(getHeroFormattedData(content, listingFiles));
  setOurExpertiseOldDefaultValues(getOurExpertiseFormattedData(content));
  setCodesAndStandardsOldDefaultValues(getCodesAndStandardsFormattedData(content));
  setAtAGlanceOldDefaultValues(getAtAGlanceFormattedData(content));
  setOurServicesOldDefaultValues(getOurServicesFormattedData(content, listingFiles));
  setAboutUsOldDefaultValues(getAboutUsFormattedData(content, listingFiles));
};

export const setDefaultValues = (
  content: any,
  listingFiles: any,
  setHeroDefaultValues: Dispatch<SetStateAction<FormHeroData | undefined>>,
  setOurExpertiseDefaultValues: Dispatch<SetStateAction<OurExpertiseData | undefined>>,
  setCodesAndStandardsDefaultValues: Dispatch<SetStateAction<CodesAndStandardsData | undefined>>,
  setAtAGlanceDefaultValues: Dispatch<SetStateAction<FormAtAGlanceData | undefined>>,
  setOurServicesDefaultValues: Dispatch<SetStateAction<FormOurServicesData | undefined>>,
  setAboutUsDefaultValues: Dispatch<SetStateAction<FormAboutUsData | undefined>>
): void => {
  setHeroDefaultValues(getHeroFormattedData(content, listingFiles));
  setOurExpertiseDefaultValues(getOurExpertiseFormattedData(content));
  setCodesAndStandardsDefaultValues(getCodesAndStandardsFormattedData(content));
  setAtAGlanceDefaultValues(getAtAGlanceFormattedData(content));
  setOurServicesDefaultValues(getOurServicesFormattedData(content, listingFiles));
  setAboutUsDefaultValues(getAboutUsFormattedData(content, listingFiles));
};
