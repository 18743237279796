import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { Location, useLocation } from "react-router-dom";

interface IRouterContext {
  accessedLocations: Location[];
  setAccessedLocations: Dispatch<SetStateAction<Location[]>>;
  previousLocation?: Location;
}

const defaultState = {
  accessedLocations: [],
};

const RouterContext = createContext<IRouterContext | undefined>(undefined);

const getUpdatedLocations = (location: Location, accessedLocations: Location[]): Location[] => {
  const nextLocation = location;
  const currLocation = accessedLocations[accessedLocations.length - 1];
  const prevLocation = accessedLocations.length > 1 && accessedLocations[accessedLocations.length - 2];

  if (!currLocation) {
    return [location];
  }

  if (prevLocation && prevLocation.pathname === nextLocation.pathname) {
    accessedLocations.pop();
    return accessedLocations;
  }

  if (nextLocation.pathname !== currLocation.pathname) {
    return [...accessedLocations, nextLocation];
  }

  if (nextLocation.search !== currLocation.search) {
    const updatedLocations = [...accessedLocations];
    updatedLocations[updatedLocations.length - 1].search = location.search;
    return updatedLocations;
  }

  return accessedLocations;
};

export const RouterContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [accessedLocations, setAccessedLocations] = useState<Location[]>(defaultState.accessedLocations);

  const location = useLocation();

  const previousLocation = accessedLocations.length > 1 ? accessedLocations[accessedLocations.length - 2] : undefined;

  useEffect(() => {
    setAccessedLocations(getUpdatedLocations(location, accessedLocations));
  }, [location.pathname, location.search]);

  const memoedValue = useMemo(
    () => ({
      accessedLocations,
      setAccessedLocations,
      previousLocation,
    }),
    [accessedLocations]
  );

  return <RouterContext.Provider value={memoedValue}>{children}</RouterContext.Provider>;
};

export const useRouterContext = (): IRouterContext => {
  const context = useContext(RouterContext);
  if (context === undefined) {
    throw new Error("useRouterContext must be used within a RouterContextProvider");
  }
  return context;
};
