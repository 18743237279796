import { Column, Divider, Row } from "../../../../widget";
import { AddressForm, DetailsForm, IconForm, LogoForm } from "../../../shared";
import { useOrganisation } from "./useOrganisation";

const Organisation = (): JSX.Element => {
  const {
    dataIsLoading,
    organisationDefaultDetails,
    organisationDefaultAddress,
    organisationDefaultIcon,
    organisationDefaultLogo,
    countries,
    dispatch,
    objectUuid,
    objectType,
    objectKey,
    updateDetails,
  } = useOrganisation();

  return (
    <div>
      {!dataIsLoading && (
        <>
          <Row spacingV="m">
            <Column span={12}>
              <h2>Organisation details</h2>
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={12}>
              <span className="body1">These details will be used for your public listing in the directory.</span>
            </Column>
          </Row>
          {organisationDefaultDetails && (
            <DetailsForm
              objectUuid={objectUuid}
              objectKey={objectKey}
              updateDetails={updateDetails}
              organisationDefaultDetails={organisationDefaultDetails}
              dispatch={dispatch}
            />
          )}
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={5}>
              <h2>Address</h2>
            </Column>
          </Row>
          {organisationDefaultAddress && (
            <AddressForm
              countries={countries}
              organisationDefaultAddress={organisationDefaultAddress}
              dispatch={dispatch}
            />
          )}
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="m">
            <Column span={5}>
              <h2>Icon</h2>
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={12}>
              <span className="body1">This icon will be shown for your organisation in the public directory.</span>
            </Column>
          </Row>
          <IconForm
            objectUuid={objectUuid}
            objectType={objectType}
            organisationDefaultIcon={organisationDefaultIcon}
            dispatch={dispatch}
          />
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="m">
            <Column span={5}>
              <h2>Logo</h2>
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={12}>
              <span className="body1">This logo will be displayed on your public organisation profile.</span>
            </Column>
          </Row>
          <LogoForm
            objectUuid={objectUuid}
            objectType={objectType}
            organisationDefaultLogo={organisationDefaultLogo}
            dispatch={dispatch}
          />
        </>
      )}
    </div>
  );
};

export default Organisation;
