import { useNavigate } from "react-router-dom";

import { DeveloperSingleCardTemplate } from "../../../../../templates";
import { Column, ReadonlyText, Row } from "../../../../../widget";
import { EditForm } from "../../../../shared";
import { useEdit } from "./useEdit";

export const Edit = (): JSX.Element => {
  const navigate = useNavigate();
  const { pageTitle, dataIsLoading, userDetails, userUuid, roles, rolesInfo, userSettingsRoute } = useEdit();

  return (
    <div>
      {!dataIsLoading && (
        <DeveloperSingleCardTemplate
          activeElement="Settings"
          pageTitle={pageTitle}
          handleBackClick={() => navigate(userSettingsRoute)}
          backButtonText="Back to users"
        >
          {userDetails && (
            <>
              <Row spacingV="ll">
                <Column span={2}>
                  <ReadonlyText label="First name" value={userDetails.user.firstName} />
                </Column>
              </Row>
              <Row spacingV="ll">
                <Column span={2}>
                  <ReadonlyText label="Last name" value={userDetails.user.lastName} />
                </Column>
              </Row>
              <Row spacingV="ll">
                <Column span={2}>
                  <ReadonlyText label="Email address" value={userDetails.user.email} />
                </Column>
              </Row>
              <EditForm
                userUuid={userUuid}
                defaultRoleValue={userDetails.role}
                defaultActiveValue={userDetails.active}
                roles={roles}
                rolesInfo={rolesInfo}
                rowVersion={userDetails.rowVersion}
                userSettingsRoute={userSettingsRoute}
              />
            </>
          )}
        </DeveloperSingleCardTemplate>
      )}
    </div>
  );
};
