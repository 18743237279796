import { OverviewComponent } from "../components";
import { useOverview } from "./useOverview";

const Overview = (): JSX.Element => {
  const { isLoading, projectDetails, chartData, tags, badges, certifications, standardSpecificDefinition } =
    useOverview();
  return (
    <div>
      {!isLoading && standardSpecificDefinition && (
        <OverviewComponent
          projectDetails={projectDetails}
          chartData={chartData}
          tags={tags}
          badges={badges}
          certifications={certifications}
          standardSpecificDefinition={standardSpecificDefinition}
        />
      )}
    </div>
  );
};

export default Overview;
