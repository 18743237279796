import { useContext, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { OrganisationPermissionConstants, OrganisationTypeConstants } from "../../constants";
import { ProjectContext } from "../../route/shared/projects/project/ProjectContext";
import { useAuth } from "../../useAuth";
import { getAccessDeniedRoute } from "../../utils/routes";

interface useRouteGuardsReturnData {
  RequireAuth: ({ children }: { children: JSX.Element }) => JSX.Element;
  RequireDeveloperAuth: ({ children }: { children: JSX.Element }) => JSX.Element;
  RequireVerifierAuth: ({ children }: { children: JSX.Element }) => JSX.Element;
  RequireDevelopmentManagerAuth: ({ children }: { children: JSX.Element }) => JSX.Element;
  RequireAssetManagerAuth: ({ children }: { children: JSX.Element }) => JSX.Element;
  RequireUserPermission: ({
    children,
    userPermission,
    redirectRoute,
  }: {
    children: JSX.Element;
    userPermission: OrganisationPermissionConstants;
    redirectRoute?: string | undefined;
  }) => JSX.Element;
  RequireProjectPermission: ({
    children,
    redirectRoute,
  }: {
    children: JSX.Element;
    redirectRoute?: string | undefined;
  }) => JSX.Element;
}

export const useRouteGuards = (): useRouteGuardsReturnData => {
  const auth = useAuth();
  const prevRoute = useLocation();

  const RequireAuth = useMemo(
    () =>
      ({ children }: { children: JSX.Element }): JSX.Element => {
        if (!auth.isAuthenticated()) {
          return <Navigate to="/logon" state={{ pathname: prevRoute.pathname }} replace />;
        }
        return children;
      },
    [auth, prevRoute.pathname]
  );

  const RequireDeveloperAuth = useMemo(
    () =>
      ({ children }: { children: JSX.Element }): JSX.Element => {
        if (auth.currentUserType !== OrganisationTypeConstants.DEVELOPER) {
          return <Navigate to="/logon" replace />;
        }

        if (!auth.isAuthenticated()) {
          return <Navigate to="/logon" state={{ pathname: prevRoute.pathname }} replace />;
        }
        return children;
      },
    [auth, prevRoute.pathname]
  );

  const RequireVerifierAuth = useMemo(
    () =>
      ({ children }: { children: JSX.Element }): JSX.Element => {
        if (auth.currentUserType !== OrganisationTypeConstants.VERIFIER) {
          return <Navigate to="/logon" replace />;
        }

        if (!auth.isAuthenticated()) {
          return <Navigate to="/logon" state={{ pathname: prevRoute.pathname }} replace />;
        }

        return children;
      },
    [auth, prevRoute.pathname]
  );

  const RequireDevelopmentManagerAuth = useMemo(
    () =>
      ({ children }: { children: JSX.Element }): JSX.Element => {
        if (auth.currentUserType !== OrganisationTypeConstants.DEVELOPMENT_MANAGER) {
          return <Navigate to="/logon" replace />;
        }

        if (!auth.isAuthenticated()) {
          return <Navigate to="/logon" state={{ pathname: prevRoute.pathname }} replace />;
        }

        return children;
      },

    [auth, prevRoute.pathname]
  );

  const RequireAssetManagerAuth = useMemo(
    () =>
      ({ children }: { children: JSX.Element }): JSX.Element => {
        if (auth.currentUserType !== OrganisationTypeConstants.ASSET_MANAGER) {
          return <Navigate to="/logon" replace />;
        }

        if (!auth.isAuthenticated()) {
          return <Navigate to="/logon" state={{ pathname: prevRoute.pathname }} replace />;
        }
        return children;
      },
    [auth, prevRoute.pathname]
  );

  const RequireUserPermission = ({
    children,
    userPermission,
    redirectRoute,
  }: {
    children: JSX.Element;
    userPermission: OrganisationPermissionConstants;
    redirectRoute?: string;
  }): JSX.Element => {
    const { hasPermission } = useAuth();
    if (!hasPermission(userPermission as string)) {
      return <Navigate to={redirectRoute ?? getAccessDeniedRoute()} replace />;
    }

    return children;
  };

  const RequireProjectPermission = ({
    children,
    redirectRoute,
  }: {
    children: JSX.Element;
    redirectRoute?: string;
  }): JSX.Element => {
    const { isExternalProject } = useContext(ProjectContext);

    if (isExternalProject()) {
      return <Navigate to={redirectRoute ?? getAccessDeniedRoute()} replace />;
    }

    return children;
  };

  return {
    RequireAuth,
    RequireDeveloperAuth,
    RequireVerifierAuth,
    RequireDevelopmentManagerAuth,
    RequireAssetManagerAuth,
    RequireUserPermission,
    RequireProjectPermission,
  };
};
