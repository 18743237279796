import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { EyeBlueIcon } from "../../../../assets";
import { CursorChangeProps, ResultType, SelectData } from "../../../../models";
import { searchProjectDocumentTypes, SearchProjectDocumentTypesResponse } from "../../../../service/query";
import { ResultData } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { flattenObject } from "../../../../utils";
import { getSettingsViewDocumentTypeRoute } from "../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
} from "../../../../widget";
import { IconCellFormatterData } from "../../../../widget/data/DataGrid/models";

interface UseDocumentTypesReturnData {
  columns: DataGridColumnDefinition[];
  selectedDocumentTypeGroup?: string;
  setSelectedDocumentTypeGroup: Dispatch<SetStateAction<string | undefined>>;
  documentTypeGroups?: SelectData;
  setDocumentTypeGroups: Dispatch<SetStateAction<SelectData | undefined>>;

  refreshTypes: boolean;
  setRefreshTypes: Dispatch<SetStateAction<boolean>>;
  dataIsLoading: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useDocumentTypes = (): UseDocumentTypesReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [refreshTypes, setRefreshTypes] = useState(false);
  const [documentTypeGroups, setDocumentTypeGroups] = useState<SelectData>();

  const [selectedDocumentTypeGroup, setSelectedDocumentTypeGroup] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "name",
      name: "Type",
      dataType: "string",
    },
    {
      key: "supportsVariants",
      name: "Variant supported?",
      dataType: "boolean",
      formatter: "yesNo",
      filterable: false,
    },
    {
      key: "supportsMultipleFiles",
      name: "Multi-file upload supported?",
      dataType: "boolean",
      formatter: "yesNo",
      filterable: false,
    },
    {
      key: "supportedMimeTypes",
      name: "Extensions supported",
      dataType: "string",
      filterable: false,
      sortable: false,
    },
    {
      key: "viewLink",
      name: "View",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
      minWidth: 74,
    },
  ];

  const initialiseDocumentTypeGroup = (responseData: SearchProjectDocumentTypesResponse | undefined): void => {
    const filteredGroups = [] as SelectData;
    filteredGroups.push({ key: "general", value: "General" });
    if (responseData?.results?.length) {
      for (let i = 0; i < responseData?.results?.length; i++) {
        if (!filteredGroups.find((g) => g.value === responseData?.results[i].standard?.displayName)) {
          if (
            responseData?.results[i].standard?.standardUuid != null &&
            responseData?.results[i].standard?.displayName != null
          ) {
            filteredGroups.push({
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
              key: responseData.results[i].standard?.standardUuid!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
              value: responseData.results[i].standard?.displayName!,
            });
          }
        }
      }
    }
    const sortedGroups = filteredGroups.sort((a, b) => {
      const valueA = a.value;
      const valueB = b.value;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (valueA == null || valueA < valueB!) {
        return -1;
      }
      if (valueB == null || valueA > valueB) {
        return 1;
      }
      return 0;
    });
    setDocumentTypeGroups(sortedGroups);
    setSelectedDocumentTypeGroup("general");
  };

  const formatData = useCallback(
    (responseData: SearchProjectDocumentTypesResponse | undefined): ResultData[] => {
      if (documentTypeGroups === undefined) initialiseDocumentTypeGroup(responseData);

      return (
        responseData?.results?.map((d) => {
          const result = flattenObject(d);

          result.supportedMimeTypes = d.supportedMimeTypes !== null ? d.supportedMimeTypes.join(" / ") : null;

          result.viewLink = {
            action: () => {
              navigate(getSettingsViewDocumentTypeRoute(d.uuid, currentUserType));
            },
            icon: <EyeBlueIcon width={20} height={20} strokeWidth={1.5} />,
          } as IconCellFormatterData;

          return result;
        }) || []
      );
    },
    [refreshTypes]
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (selectedDocumentTypeGroup)
      if (selectedDocumentTypeGroup === "general")
        filterCriteria.standard = {
          standardUuid: {
            operator: "in",
            value: "null",
          },
        };
      else
        filterCriteria.standard = {
          standardUuid: {
            operator: "in",
            value: selectedDocumentTypeGroup,
          },
        };

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await searchProjectDocumentTypes({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });
    return data;
  };

  useEffect(() => {
    setRefreshTypes(!refreshTypes);
  }, [selectedDocumentTypeGroup]);

  return {
    dataIsLoading,
    columns,
    documentTypeGroups,
    refreshTypes,
    selectedDocumentTypeGroup,
    setRefreshTypes,
    setDocumentTypeGroups,
    setSelectedDocumentTypeGroup,
    onChange,
  };
};
