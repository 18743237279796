import { Dispatch, SetStateAction } from "react";

import { SearchActivityHistoryResponse } from "../../../../service/query";
import { getNewVersion } from "./resumeActivity";

export const versionConflictModalGotDisplayed = async (
  setDraftActivityHistoryUuid: Dispatch<SetStateAction<string | undefined>>,
  setNewVersionActivityHistory: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>,
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>,
  activityUuid: string,
  activityHistoryUuid?: string,
  versionNumber?: number
): Promise<boolean> => {
  const newVersion = activityHistoryUuid ? await getNewVersion(activityUuid, versionNumber) : undefined;

  if (newVersion) {
    setDraftActivityHistoryUuid(activityHistoryUuid);
    setNewVersionActivityHistory(newVersion);
    setShowVersionConflictModal(true);
    return true;
  }
  return false;
};
