import "./Avatar.css";

import { useAvatar } from "./useAvatar";

export interface AvatarProps {
  userUuid: string;
  name: string;
  imageSource?: string;
  onClick?: () => void;
  withName?: boolean;
  imageFirst?: boolean;
}

export const Avatar = ({
  userUuid,
  name,
  imageSource,
  onClick,
  withName = false,
  imageFirst = false,
}: AvatarProps): JSX.Element => {
  const { nameInitial, avatarBackgroundColor, avatarTextColor } = useAvatar(userUuid, name);

  return (
    <div className={`Avatar ${!withName ? "AvatarWithoutName" : ""} ${imageFirst ? "ImageFirst" : ""}`}>
      {withName && <span className="body1">{name}</span>}

      <div
        title={!withName ? name : undefined}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (onClick) {
              onClick();
            }
          }
        }}
        onClick={onClick}
        className={onClick ? "AvatarAnchor" : undefined}
      >
        {imageSource ? (
          <img className="AvatarImage" src={imageSource} alt="AvatarImage" />
        ) : (
          <span
            className="AvatarInitial"
            style={{
              backgroundColor: avatarBackgroundColor,
              color: avatarTextColor,
            }}
          >
            {nameInitial}
          </span>
        )}
      </div>
    </div>
  );
};
