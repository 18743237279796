import { ActionButtons, Column, ErrorBox, Modal, Row, TextInput } from "../../../../../../../widget";
import { CreateActivityVariantModalData, useCreateActivityVariantModal } from "./useCreateActivityVariantModal";

interface CreateActivityVariantModalProps {
  show: boolean;
  closeModal: () => void;
  modalData: CreateActivityVariantModalData;
}

export const CreateActivityVariantModal = ({
  show,
  closeModal,
  modalData,
}: CreateActivityVariantModalProps): JSX.Element => {
  const { variant, setVariant, errors, handleSubmit, handleClose, isHandleSubmitLoading } =
    useCreateActivityVariantModal({
      closeModal,
      modalData,
    });
  return (
    <Modal show={show} title="Activity Variant" onClose={handleClose}>
      <Row justify="center" spacingV="2xl">
        <Column span={10}>
          <p className="body1">
            Please provide a description of this activity to distinguish it from activities of the same type, for
            verification this is typically the vintage.
          </p>
        </Column>
      </Row>
      <form onSubmit={handleSubmit}>
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <Row justify="center" spacingV="2xl">
          <Column span={6}>
            <TextInput label="Variant" name="Variant" value={variant} onChange={(e) => setVariant(e)} />
          </Column>
        </Row>
        <Row justify="center">
          <Column span={6}>
            <ActionButtons
              fullWidth
              submitButtonLabel="Create activity"
              onCancel={handleClose}
              isLoading={isHandleSubmitLoading}
            />
          </Column>
        </Row>
      </form>
    </Modal>
  );
};
