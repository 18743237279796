import { isObject } from "../isType";
import { maybeJsonDateStringParser } from "../json/maybeJsonDateStringParser";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrayParseJsonDates = (input: any[] = []): any[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ret: any[] = [];
  for (let i = 0; i < input.length; i++) {
    const val = input[i];
    if (typeof val === "string") ret[i] = maybeJsonDateStringParser(val);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    else ret[i] = recursivelyParseJsonDates(val);
  }
  return ret;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const recursivelyParseJsonDates = (input: any = {}): any => {
  if (isObject(input)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ret: any = {};
    const entries = Object.entries(input);

    for (let i = 0; i < entries.length; i += 1) {
      const [objectKey, objectValue] = entries[i];
      ret[objectKey] = maybeJsonDateStringParser(objectValue);
      if (isObject(objectValue)) {
        ret[objectKey] = recursivelyParseJsonDates(objectValue);
      } else if (Array.isArray(objectValue)) {
        ret[objectKey] = arrayParseJsonDates(objectValue);
      }
    }
    return ret;
  }
  if (Array.isArray(input)) {
    return arrayParseJsonDates(input);
  }
  return input;
};
