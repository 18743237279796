import "./BadgeDetails.css";

import { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";

import { ArrowFullLeftDarkIcon } from "../../../../assets";
import { OrganisationTypeConstants } from "../../../../constants";
import { UuidType } from "../../../../models";
import { BadgeDetailsData } from "../../../../route/public/badge/details/page/useDetails";
import { getHostFromURL } from "../../../../utils";
import { Button, Column, HorizontalProjectCards, Logo, Pill, Row } from "../../../../widget";
import { DisplayHorizontalProject } from "../../../../widget/cards/HorizontalProjectCards/models";
import {
  ContactUsModal,
  ContentSection,
  ModalButton,
  RichContentSection,
  SocialMediaSection,
} from "../../../components";

interface BadgeDetailsProps {
  badgeDetailsData?: BadgeDetailsData;
  badgeUuid: string | undefined;
  badgeProjects: DisplayHorizontalProject[] | undefined;
  isPreview: boolean;
  showContactUsModal?: boolean;
  setShowContactUsModal?: Dispatch<SetStateAction<boolean>>;
  navigateBackToProject?: () => void;
}

export const BadgeDetails = ({
  badgeDetailsData,
  badgeUuid,
  badgeProjects,
  isPreview,
  showContactUsModal,
  setShowContactUsModal,
  navigateBackToProject,
}: BadgeDetailsProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get("projectUuid");

  return (
    <div className="PublicBadgeDetails">
      <div className="PublicBadgeDetailsPageTemplate">
        <Row spacingV="ll" className="LogoAndButtons" justify="center">
          <Column span={4}>
            <div className="BackToProjectButton">
              {!isPreview && projectUuid && (
                <Button
                  text="Back to project"
                  variant="secondary"
                  icon={<ArrowFullLeftDarkIcon />}
                  iconPosition="left"
                  onClick={navigateBackToProject}
                />
              )}
            </div>
          </Column>
          <Column span={4}>{badgeDetailsData?.logo && <Logo displayedLogo={badgeDetailsData.logo} />}</Column>
          <Column span={4}>
            {/* Removed as part of KAN-3909 https://kanaearth.atlassian.net/browse/KAN-3909 */}
            {/* Because we hid the Register page in order to remove the possibility of creating new accounts, we need to also remove this modal */}
            {!isPreview &&
              setShowContactUsModal &&
              badgeDetailsData?.calculatedContactable &&
              badgeDetailsData?.getInTouch.enabled && (
                <div className="ContactUsButton">
                  <ModalButton overrideText="Send an enquiry" onClick={() => setShowContactUsModal(true)} />
                </div>
              )}
          </Column>
        </Row>
        {badgeDetailsData?.displayName && (
          <Row spacingV="ml" justify="center">
            <Column span={12}>
              <h1>{badgeDetailsData.displayName}</h1>
            </Column>
          </Row>
        )}
        {badgeDetailsData?.hero && (
          <>
            {badgeDetailsData.hero.title && (
              <Row spacingV="m" justify="center">
                <Column span={10}>
                  <h2>{badgeDetailsData.hero.title}</h2>
                </Column>
              </Row>
            )}
            {badgeDetailsData.hero.subtitle && (
              <Row spacingV="ml" justify="center">
                <Column className="DescriptionColumn" span={12}>
                  <span className="body1">{badgeDetailsData.hero.subtitle}</span>
                </Column>
              </Row>
            )}
          </>
        )}
        {badgeDetailsData?.websiteUrl && (
          <Row spacingV="ml" justify="center">
            <Column span={12}>
              <Pill
                variant="blue"
                label={getHostFromURL(badgeDetailsData?.websiteUrl)}
                link={badgeDetailsData?.websiteUrl}
              />
            </Column>
          </Row>
        )}
        {badgeDetailsData?.socialMediaData && (
          <SocialMediaSection isPreview={isPreview} socialMedia={badgeDetailsData.socialMediaData} />
        )}
        {badgeDetailsData?.overview?.enabled && badgeDetailsData?.overview?.content && (
          <Row spacingV="ml">
            <Column span={12}>
              <ContentSection title="Overview" content={badgeDetailsData.overview.content} />
            </Column>
          </Row>
        )}
        {badgeDetailsData?.ourServices && (
          <Row spacingV="ml">
            <Column span={12}>
              <RichContentSection title="Our services" sectionData={badgeDetailsData.ourServices} />
            </Column>
          </Row>
        )}
        {badgeDetailsData?.showProjects && badgeProjects && !!badgeProjects.length && badgeDetailsData?.displayName && (
          <Row spacingV="ml">
            <Column span={12}>
              <HorizontalProjectCards
                uuidType={UuidType.Badge}
                codeUuid={badgeUuid}
                codeName={badgeDetailsData.displayName}
                projects={badgeProjects}
              />
            </Column>
          </Row>
        )}
      </div>
      {badgeDetailsData?.displayName && setShowContactUsModal && showContactUsModal && (
        <ContactUsModal
          organisationType={OrganisationTypeConstants.BADGE}
          developerName={badgeDetailsData.displayName}
          developerPhoneNumber={null}
          show={showContactUsModal}
          closeModal={() => {
            setShowContactUsModal(false);
          }}
        />
      )}
    </div>
  );
};
