import { OrganisationTypeConstants } from "../../../../../constants";
import { useAuth } from "../../../../../useAuth";

interface UseIssuancesReturnData {
  showTooltip: boolean;
}

export const useIssuances = (): UseIssuancesReturnData => {
  const { currentUserType } = useAuth();

  return {
    showTooltip: currentUserType === OrganisationTypeConstants.DEVELOPER,
  };
};
