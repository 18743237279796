import { RcFile } from "rc-upload/lib/interface";
import React, { Dispatch, RefObject, useEffect, useId, useRef, useState } from "react";

import { PreviewFile } from "../../../../models";
import { useClickOutside } from "../../../../utils";
import { Toast } from "../../../general";

interface useSingleFileReplaceReturnData {
  id: string;
  selectedFile?: PreviewFile;
  showDropdown: boolean;
  showDeleteFileModal: boolean;
  isFileUploading: boolean;
  dropdownRef: RefObject<HTMLDivElement>;
  handleClick: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  handleDeleteFile: () => void;
  handleKeyDownDeleteFile: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  setSelectedFile: Dispatch<React.SetStateAction<PreviewFile | undefined>>;
  onAction: (file: RcFile) => Promise<string>;
  onConfirmFileDeleteModal: () => void;
  onCloseFileDeleteModal: () => void;
}

interface useSingleFileReplaceProps {
  value: PreviewFile | undefined;
  maxFileSize: number;
  onChange: (e: PreviewFile | undefined) => void;
  onFileUpload?: (e: RcFile) => Promise<PreviewFile>;
  shouldConfirmDeletion?: boolean;
}

export const useSingleFileReplace = ({
  value,
  onChange,
  maxFileSize,
  onFileUpload,
  shouldConfirmDeletion,
}: useSingleFileReplaceProps): useSingleFileReplaceReturnData => {
  const id = useId();
  const [selectedFile, setSelectedFile] = useState<PreviewFile | undefined>(value);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedFile(value);
  }, [value]);

  const onAction = async (file: RcFile): Promise<string> => {
    setShowDropdown(false);
    setIsFileUploading(true);

    const fileSize = Number((file.size / 1024 / 1024).toFixed(4)); // MB

    if (fileSize > maxFileSize) {
      Toast.error({
        message: `Please select a file which is less than ${maxFileSize}MB`,
      });
      setIsFileUploading(false);

      return "";
    }

    let previewFile: PreviewFile = {
      file,
      mimeType: file.type,
      url: URL.createObjectURL(file),
      filename: file.type,
      uuid: file.uid,
    };

    // if on onFileUpload method is passed we await for the files to be uploaded.
    if (onFileUpload) {
      try {
        previewFile = await onFileUpload(file);
      } catch (error) {
        Toast.error({
          message: (error as string) || `Failed to upload ${file.name}`,
        });
        setIsFileUploading(false);

        return "";
      }
    }

    setSelectedFile(previewFile);
    onChange(previewFile);
    setIsFileUploading(false);

    return "";
  };

  const handleClick = (): void => {
    setShowDropdown(!showDropdown);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (e.key) {
      case "Enter":
        setShowDropdown(!showDropdown);
        break;
      case "Escape":
        setShowDropdown(false);
        break;
      default:
        break;
    }
  };

  const deleteFile = (): void => {
    setSelectedFile(undefined);
    onChange(undefined);
    setShowDropdown(false);
  };

  const handleDeleteFile = (): void => {
    if (shouldConfirmDeletion) {
      setShowDeleteFileModal(true);
    } else {
      deleteFile();
    }
  };

  const handleKeyDownDeleteFile = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (e.key) {
      case "Enter":
        handleDeleteFile();
        break;
      case "Escape":
        handleDeleteFile();
        break;
      default:
        break;
    }
  };

  const onCloseFileDeleteModal = (): void => {
    setShowDeleteFileModal(false);
  };

  const onConfirmFileDeleteModal = (): void => {
    deleteFile();
    setShowDeleteFileModal(false);
  };

  useClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    // This was working before double render on expression I had to comment it might be necessary if not double render
    // onChange(selectedFile);
    setShowDropdown(false);
  }, [selectedFile]);

  return {
    id,
    selectedFile,
    showDropdown,
    showDeleteFileModal,
    isFileUploading,
    dropdownRef,
    handleClick,
    handleKeyDown,
    handleDeleteFile,
    handleKeyDownDeleteFile,
    setSelectedFile,
    onAction,
    onConfirmFileDeleteModal,
    onCloseFileDeleteModal,
  };
};
