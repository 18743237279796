import "./Activities.css";

import { DeveloperTemplate } from "../../../templates";
import { ChartsSection } from "../../../templates/components";
import { Column, CursorDataGrid, Row, UserHeader } from "../../../widget";
import { useActivities } from "./useActivities";

const Activities = (): JSX.Element => {
  const {
    activityTypeChartData,
    activityStatusChartData,
    activityCodeChartData,
    activityDeveloperChartData,
    isLoading,
    columns,
    onChange,
  } = useActivities();

  return (
    <DeveloperTemplate activeElement="Activities">
      <div className="DevelopmentManagerActivities">
        <div className="HeaderContainer">
          <UserHeader />
        </div>
        <Row spacingV="ll">
          <Column span={12}>
            <h1>Activities</h1>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <ChartsSection
              title="At a glance"
              firstChartData={activityTypeChartData}
              firstChartTitle="Type"
              secondChartData={activityStatusChartData}
              secondChartTitle="Status"
              thirdChartData={activityCodeChartData}
              thirdChartTitle="Code"
              fourthChartData={activityDeveloperChartData}
              fourthChartTitle="Developer"
              direction="Vertical"
            />
          </Column>
        </Row>
        <div className="ActivitiesTable">
          <Row spacingV="m">
            <Column span={12}>
              <h2>Activities</h2>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <CursorDataGrid
                columns={columns}
                clientSide={false}
                filterable
                onChange={onChange}
                data={[]}
                dataIsLoading={isLoading}
                noDataMessage="No activities to display"
              />
            </Column>
          </Row>
        </div>
      </div>
    </DeveloperTemplate>
  );
};

export default Activities;
