import { FetchResult, Observable } from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";

import { getErrorMessageFromCode } from "../../../service/ValidationErrorFormatter";
import { Toast } from "../../../widget";
import { ERROR_NO_RECORD_FOUND } from "../../errorConstants";
import { logErrorWithDetail } from "./errorHandlerUtils";

// for HTTP 404 status codes, show an error message
// and log an error (as this should never occur in the normal
// course of events)
export const errorHandler404 = (networkError: NetworkError): Observable<FetchResult> | undefined => {
  logErrorWithDetail("GraphQL status code 404 received", networkError);
  Toast.error({
    message: getErrorMessageFromCode(ERROR_NO_RECORD_FOUND),
  });
  return undefined;
};
