import "./RichContentSection.css";

import { RichContentSectionData } from "../../../models";
import { isEven } from "../../../utils";
import { Column, Row } from "../../../widget";

interface RichContentSectionProps {
  title: string;
  sectionData: RichContentSectionData;
}

export const RichContentSection = ({ title, sectionData }: RichContentSectionProps): JSX.Element => {
  return (
    <div className="RichContentSection">
      <h2>{title}</h2>
      {Object.entries(sectionData)
        // filter enabled property, all null and undefined parts and also the parts that are missing content and title properties.
        .filter(([part, partData]) => {
          return part !== "enabled" && partData && partData.content && partData.title;
        })
        .map(([partName, partData], index) => (
          <div key={`${partName}_${partData.title}`} className="RichContentSectionPart">
            <Row reversed={!isEven(index) && partData?.imageUrl}>
              <Column
                className={partData?.imageUrl ? "" : "FullSpanText"}
                span={partData?.imageUrl ? 6 : 12}
                justify="center"
              >
                <h3>{partData.title}</h3>
                <p className="body1">{partData.content}</p>
                {partData.externalUrl && (
                  <a href={partData.externalUrl} target="_blank" rel="noreferrer">
                    Learn more
                  </a>
                )}
              </Column>
              {partData?.imageUrl && (
                <Column span={6} justify="start">
                  <img className="RichContentSectionPartImage" src={partData.imageUrl} alt={partData.title} />
                </Column>
              )}
            </Row>
          </div>
        ))}
    </div>
  );
};
