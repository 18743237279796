import "../Pagination/Pagination.css";
import "./CursorPagination.css";

import React from "react";

import { ArrowLeftDarkIcon, ArrowRightDarkIcon } from "../../../assets";

export interface CursorPaginationProps {
  onPrevious: () => void;
  onNext: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
}

export const CursorPagination: React.FC<CursorPaginationProps> = (props): JSX.Element | null => {
  const { onPrevious, onNext, hasNext, hasPrevious } = props;

  return (
    <div className="Pagination Pagination_withCursor">
      {hasPrevious ? (
        <button className="PaginationItem PaginationItem_previous" onClick={onPrevious}>
          <ArrowLeftDarkIcon />
          <div className="PaginationItemText">Previous</div>
        </button>
      ) : null}
      {hasNext ? (
        <button className="PaginationItem PaginationItem_next" onClick={onNext}>
          <div className="PaginationItemText">Next</div>
          <ArrowRightDarkIcon />
        </button>
      ) : null}
    </div>
  );
};
