import { Column, Row } from "../../../../widget";
import { BasicDetailsSection, PublicStandardSpecificSection } from "./components";
import { useDetails } from "./useDetails";

const Details = (): JSX.Element => {
  const { projectDetails, standardSpecificDefinition } = useDetails();

  return (
    <>
      <div className="PublicProjectTabSection">
        {projectDetails && (
          <Row>
            <Column span={12}>{projectDetails && <BasicDetailsSection projectDetails={projectDetails} />}</Column>
          </Row>
        )}
      </div>
      {standardSpecificDefinition && projectDetails?.standardSpecificData && (
        <div className="PublicProjectTabSection">
          <PublicStandardSpecificSection
            standardSpecificDefinition={standardSpecificDefinition}
            standardSpecificData={projectDetails?.standardSpecificData}
            projectData={projectDetails}
          />
        </div>
      )}
    </>
  );
};

export default Details;
