import "./ForgottenPasswordComplete.css";

import bgImage from "../../../assets/images/Landscape.jpg";
import { AuthenticationTemplate } from "../../../templates";
import { Column, ErrorBox, Row } from "../../../widget";
import { ForgottenPasswordCompleteForm } from "./form";
import { useForgottenPasswordComplete } from "./useForgottenPasswordComplete";

export const ForgottenPasswordComplete = (): JSX.Element => {
  const { urlToken } = useForgottenPasswordComplete();

  return (
    <AuthenticationTemplate imageSrc={bgImage}>
      <Row spacingV="m">
        <Column span={12}>
          <h1>Reset Your password</h1>
        </Column>
      </Row>
      {urlToken ? (
        <>
          <Row spacingV="ll">
            <Column span={12}>
              <span className="body1">Please enter a new password for your account.</span>
            </Column>
          </Row>
          <ForgottenPasswordCompleteForm urlToken={urlToken} />
        </>
      ) : (
        <Row className="ForgottenPasswordCompleteTokenNotFound">
          <Column span={12}>
            <ErrorBox text="Copy the link from the invitation and paste it directly into the browser" />
          </Column>
        </Row>
      )}
    </AuthenticationTemplate>
  );
};
