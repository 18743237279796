import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from "react";

import { MarketingAssetsConstants, OrganisationPermissionConstants } from "../../../../../../constants";
import {
  ActionButtons,
  Column,
  ErrorBox,
  KanaTooltip,
  RadioButtonGroup,
  Row,
  TextArea,
  TextInput,
  Toggle,
} from "../../../../../../widget";
import { RadioChangeEvent } from "../../../../../../widget/forms/Input/RadioButtonGroup/components";
import {
  CodesAndStandardsData,
  VerifierMarketingAssetsFormChangedAction,
  VerifierMarketingAssetsSubmitHandler,
} from "../../models";
import { useCodesAndStandardsForm } from "./useCodesAndStandardsForm";

interface CodesAndStandardsFormProps {
  verifierDisplayName: string;
  codesAndStandardsDefaultValues: CodesAndStandardsData;
  codesAndStandardsOldDefaultValues: CodesAndStandardsData;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<VerifierMarketingAssetsFormChangedAction>;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

export const CodesAndStandardsForm = forwardRef(
  (
    {
      verifierDisplayName,
      codesAndStandardsDefaultValues,
      codesAndStandardsOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      getCurrentFormsData,
    }: CodesAndStandardsFormProps,
    forwardedRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>
  ): JSX.Element => {
    const {
      enabled,
      content,
      woodlandChecked,
      peatlandChecked,
      woodlandDescription,
      peatlandDescription,
      radioButtonGroupData,
      errors,
      isHandleSubmitLoading,
      setEnabled,
      setContent,
      setWoodlandChecked,
      setPeatlandChecked,
      setWoodlandDescription,
      setPeatlandDescription,
      handleSubmit,
      handleCancel,
      hasPermission,
    } = useCodesAndStandardsForm(
      verifierDisplayName,
      codesAndStandardsDefaultValues,
      codesAndStandardsOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      forwardedRef,
      getCurrentFormsData
    );

    return (
      <>
        <Row spacingV="m">
          <Column span={4}>
            <h2>Codes and Standards</h2>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <span className="body1">
              This section shows the codes and standards you work with based on the projects assigned to you. The
              summary below can reflect the work and relationship you have with those organisations.
            </span>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={4}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row spacingV="ll">
            <Column span={12}>
              <Toggle label="Show this section on organisation profile" value={enabled} onChange={setEnabled} />
            </Column>
          </Row>
          <Row spacingV="ml">
            <Column span={10}>
              <TextArea
                name="CodesAndStandards-summary"
                label="Summary"
                disabled={!enabled}
                tooltip={
                  <KanaTooltip
                    tooltipHeader="Codes and Standards tooltip header"
                    tooltipText="Codes and Standards tooltip text"
                  />
                }
                value={content || ""}
                onChange={(v) => {
                  setContent(v);
                }}
                displayCharacterCount
                maxLength={MarketingAssetsConstants.CODES_AND_STANDARDS_SUMMARY_MAX_LENGTH}
              />
            </Column>
          </Row>
          <Row spacingV="ml">
            <Column span={10}>
              <RadioButtonGroup
                label="Woodland Carbon Code"
                data={radioButtonGroupData}
                disabled={!enabled}
                value={woodlandChecked}
                onChange={(e: RadioChangeEvent) => setWoodlandChecked(e.target.value)}
              />
            </Column>
          </Row>
          {woodlandChecked && (
            <Row spacingV="l">
              <Column span={10}>
                <TextInput
                  name="Woodland short description"
                  label="Short description"
                  disabled={!enabled}
                  value={woodlandDescription || ""}
                  onChange={(e: string) => setWoodlandDescription(e)}
                />
              </Column>
            </Row>
          )}
          <Row spacingV="ml">
            <Column span={10}>
              <RadioButtonGroup
                label="Peatland Code"
                data={radioButtonGroupData}
                disabled={!enabled}
                value={peatlandChecked}
                onChange={(e: RadioChangeEvent) => setPeatlandChecked(e.target.value)}
              />
            </Column>
          </Row>
          {peatlandChecked && (
            <Row spacingV="l">
              <Column span={10}>
                <TextInput
                  name="Peatland short description"
                  label="Short description"
                  disabled={!enabled}
                  value={peatlandDescription || ""}
                  onChange={(e: string) => setPeatlandDescription(e)}
                />
              </Column>
            </Row>
          )}
          {hasPermission(OrganisationPermissionConstants.WRITE) && (
            <Row spacingV="2xl">
              <Column span={4}>
                <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
              </Column>
            </Row>
          )}
        </form>
      </>
    );
  }
);
