import "./DocumentDetailsCell.css";

import { EyeBlueIcon } from "../../../../../assets";
import { CellFormatter } from "../../../../../widget/data/DataGrid/models";

export const DocumentDetailsCellFormatter: CellFormatter<string> = () => {
  return (value) => {
    return (
      <div className="DocumentDetailsButtonWrapper">
        <a href={value} className="DocumentDetailsButton">
          <EyeBlueIcon />
        </a>
      </div>
    );
  };
};
