import "./Tabs.css";

import { Link, Outlet, useLocation } from "react-router-dom";

import { TabElement, TabsList } from "../../../models";
import { TabNotificationIndicator } from "./components";

interface TabsProps {
  tabsList: TabsList;
  size?: "medium";
  className?: string;
}

export const Tabs = ({ size = "medium", tabsList, className = "" }: TabsProps): JSX.Element => {
  const location = useLocation();

  const tabElement = (tab: TabElement): JSX.Element => {
    return tab.isDisabled ? (
      <div
        key={tab.label}
        className={`body2 TabElement TabElement_${size}${
          location.pathname.includes(tab.route.split("?")[0]) ? " TabElement_active" : ""
        }`}
      >
        {tab.notify && <TabNotificationIndicator />}
        {tab.label}
      </div>
    ) : (
      <Link
        key={tab.label}
        to={tab.route}
        onClick={() => {
          // Required in order to not trigger the effect in the `useScrollToTop` custom hook
          window.isTab = true;
        }}
        className={`body2 TabElement TabElement_${size}${
          location.pathname.includes(tab.route.split("?")[0]) ? " TabElement_active" : ""
        }`}
      >
        {tab.notify && <TabNotificationIndicator />}
        {tab.label}
      </Link>
    );
  };

  return (
    <div>
      <nav className="Tabs">{tabsList.map((tab) => tabElement(tab))}</nav>
      <div className={`TabsContent ${className}`}>
        <Outlet />
      </div>
    </div>
  );
};
