import { FetchResult, Observable } from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";

import { getErrorMessageFromCode } from "../../../service/ValidationErrorFormatter";
import { Toast } from "../../../widget";
import { ERROR_APPLICATION } from "../../errorConstants";
import { logErrorWithDetail } from "./errorHandlerUtils";

// handle HTTP 400 status codes, which could be a result of:
// * the query being malformed (handled by the GraphQL server)
// * input validation failing (handled by the application)
export const errorHandler400 = (networkError: NetworkError): Observable<FetchResult> | undefined => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errors = networkError?.result.errors;
  if (!errors) {
    // malformed query; handle as an exception
    logErrorWithDetail("GraphQL status code 400 received (not a validation error)", networkError);
    Toast.error({
      message: getErrorMessageFromCode(ERROR_APPLICATION),
    });
    return undefined;
  }
  // input validation failing; pass back to caller
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors.forEach((e: any) => {
    // update the message to be human-readable, and
    // let the application code handle
    e.code = e.message;
    e.message = getErrorMessageFromCode(e.code, e.extensions);
  });
  return undefined;
};
