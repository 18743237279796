export class ValidatorConstants {
  public static readonly SOIL_ASSOCIATION_UUID = "a36ff50b-ca37-422e-8841-46a882d91d49";

  public static readonly ORGANIC_FARMERS_AND_GROWERS_UUID = "ffdae67a-ec72-4808-a4b9-f0ba7f318e62";

  public static readonly VALIDATOR_UUIDS = [
    ValidatorConstants.SOIL_ASSOCIATION_UUID,
    ValidatorConstants.ORGANIC_FARMERS_AND_GROWERS_UUID,
  ];
}
