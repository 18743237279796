import { DeveloperDetailsData } from "../../../route/public/developer/details/page/useDetails";
import { PublicDeveloperDetails, PublicDeveloperDetailsMetaTags } from "../components";
import { usePublicDeveloperDetailsPageTemplate } from "./usePublicDeveloperDetailsPageTemplate";

interface PublicDeveloperDetailsPageTemplateProps {
  developerDetailsData?: DeveloperDetailsData;
}

export const PublicDeveloperDetailsPageTemplate = ({
  developerDetailsData,
}: PublicDeveloperDetailsPageTemplateProps): JSX.Element => {
  const {
    dataIsLoading,
    projectsCoordinates,
    developerUuid,
    navigateBackToProject,
    showContactUsModal,
    setShowContactUsModal,
  } = usePublicDeveloperDetailsPageTemplate();
  return (
    <div>
      {!dataIsLoading && developerDetailsData && (
        <>
          <PublicDeveloperDetailsMetaTags developerDetailsData={developerDetailsData} />
          <PublicDeveloperDetails
            projectsCoordinates={projectsCoordinates}
            developerUuid={developerUuid}
            isPreview={false}
            developerDetailsData={developerDetailsData}
            navigateBackToProject={navigateBackToProject}
            showContactUsModal={showContactUsModal}
            setShowContactUsModal={setShowContactUsModal}
          />
        </>
      )}
    </div>
  );
};
