import "./SingleFileUpload.css";

import { forwardRef, ReactNode } from "react";

import { PreviewFile } from "../../../../models";
import { Logo } from "../../../data";
import { LoadingSpinner } from "../../../general";
import { useSingleFileUpload } from "./useSingleFileUpload";

export interface SingleFileUploadProps {
  label?: string;
  btnLabel?: string;
  maxFileSize?: number;
  informationLabel?: string;
  disabled?: boolean;
  previewUrl?: string | null;
  previewLabel?: string;
  tooltip?: ReactNode;
  accept?: string;
  // Workaround: value is used to receive the filename so we can properly display it in the wizard.
  // refactor whole component to use value internal state management to clear component instead of forward ref
  // see if we can return PreviewFile on the onChange method as well
  // this will imply modification across the whole project
  value?: string;
  onChange: (e: File) => void;
  onFileUpload?: (e: File) => Promise<PreviewFile>;
}

export const SingleFileUpload = forwardRef(
  (
    {
      label,
      btnLabel = "Choose a file",
      maxFileSize = 1,
      informationLabel,
      disabled = false,
      previewUrl = undefined,
      previewLabel = "Image preview",
      tooltip,
      accept = "*",
      value,
      onChange,
      onFileUpload,
    }: SingleFileUploadProps,
    forwardedRef
  ): JSX.Element => {
    const { isFileUploading, id, selectedFile, inputRef, onFileChange } = useSingleFileUpload({
      onChange,
      onFileUpload,
      forwardedRef,
      maxFileSize,
      value,
    });
    return (
      <div className="SingleFileUpload">
        <div
          className={`form_field SingleFileUploadInputWrapper ${
            disabled || isFileUploading ? "SingleFileUploadInputWrapper_disabled" : ""
          }`}
        >
          {label && (
            <div className="LabelValue LabelValue-right">
              <div>
                <label htmlFor={id}>{label}</label>
              </div>
              {tooltip}
            </div>
          )}
          <input
            type="file"
            id={id}
            disabled={disabled || isFileUploading}
            ref={inputRef}
            onChange={isFileUploading ? undefined : onFileChange}
            accept={accept}
          />
          <label
            className="SingleFileUploadInput"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            htmlFor={id}
            tabIndex={0}
            onKeyDown={(e) => (e.key === "Enter" ? inputRef.current?.click() : undefined)}
          >
            <span className="SingleFileUploadInputFileBox body2">{selectedFile && selectedFile.name}</span>
            {isFileUploading ? (
              <div className="SingleFileUploadInputBtn TextWithLoadingSpinner">
                <p className="body2">Uploading</p>
                <LoadingSpinner />
              </div>
            ) : (
              <span className="SingleFileUploadInputBtn body2">{btnLabel}</span>
            )}
          </label>
          <span className="body2">{informationLabel ?? `Max file size ${maxFileSize}MB`}</span>
        </div>
        {previewUrl && (
          <div className="SingleFileUploadPreview">
            <Logo displayedLogo={previewUrl} displayedLabel={previewLabel} />
          </div>
        )}
      </div>
    );
  }
);
