import "./VisualMediaModalBackdrop.css";

import { Dispatch, SetStateAction } from "react";

import { ArrowLeftLightIcon, ArrowRightLightIcon, CloseLightIcon } from "../../../../../assets";

export interface VisualMediaModalBackdropProps {
  show: boolean;
  onClick: Dispatch<SetStateAction<boolean>>;
  onVisualMediaChange: Dispatch<SetStateAction<number>>;
  currentVisualMediaNumber: number;
  maxVisualMediaNumber: number;
}

export const VisualMediaModalBackdrop = ({
  show,
  onClick,
  onVisualMediaChange,
  currentVisualMediaNumber,
  maxVisualMediaNumber,
}: VisualMediaModalBackdropProps): JSX.Element | null =>
  show ? (
    <div className="VisualMediaModalBackdrop">
      <div className="VisualMediaModalBackdropHeader">
        <button onClick={() => onClick(!show)} className="VisualMediaModalBackdropCloseButton">
          <CloseLightIcon />
          <h3>Close</h3>
        </button>
        <span className="body1">
          {currentVisualMediaNumber}/{maxVisualMediaNumber}
        </span>
      </div>
      <div className="VisualMediaModalBackdropPaginationLeft">
        {currentVisualMediaNumber > 1 && (
          <button onClick={() => onVisualMediaChange(currentVisualMediaNumber - 1)}>
            <ArrowLeftLightIcon />
          </button>
        )}
      </div>
      <div className="VisualMediaModalBackdropPaginationRight">
        {currentVisualMediaNumber < maxVisualMediaNumber && (
          <button onClick={() => onVisualMediaChange(currentVisualMediaNumber + 1)}>
            <ArrowRightLightIcon />
          </button>
        )}
      </div>
    </div>
  ) : null;
