import { Dispatch, SetStateAction, useState } from "react";

interface UseGalleryReturnData {
  showVisualMediaModal: boolean;
  displayedVisualMediaNumber: number;
  setShowVisualMediaModal: Dispatch<SetStateAction<boolean>>;
  setDisplayedVisualMediaNumber: Dispatch<SetStateAction<number>>;
}

export const useGallery = (): UseGalleryReturnData => {
  const [showVisualMediaModal, setShowVisualMediaModal] = useState(false);
  const [displayedVisualMediaNumber, setDisplayedVisualMediaNumber] = useState(1);

  return {
    showVisualMediaModal,
    displayedVisualMediaNumber,
    setShowVisualMediaModal,
    setDisplayedVisualMediaNumber,
  };
};
