import { useEffect } from "react";

export const useScroll = (onScroll: () => void): void => {
  useEffect(() => {
    const handleScroll = (): void => {
      onScroll();
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onScroll]);
};
