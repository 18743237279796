import { SearchActivityHistoryResponse } from "../../../../service/query";
import { ResultData } from "../../../../service/Shared";
import { flattenObject } from "../../../../utils";
import { DataGridButtonLinkCellFormatterData, DataGridColumnDefinition } from "../../../../widget";

interface UseMultiDraftModalReturnData {
  tableColumnsTopProjects: DataGridColumnDefinition[];
  data?: ResultData[];
}

export const useMultiDraftModal = (
  resume: (uuid: string, draftActivityHistory: SearchActivityHistoryResponse) => void,
  draftActivityHistory?: SearchActivityHistoryResponse
): UseMultiDraftModalReturnData => {
  const data = draftActivityHistory?.results?.map((el) => {
    const result = flattenObject(el);

    result.link = <DataGridButtonLinkCellFormatterData>{
      text: "Resume",
      action: () => resume(el.uuid, draftActivityHistory),
    };

    return result;
  });

  const tableColumnsTopProjects: DataGridColumnDefinition[] = [
    {
      key: "activity.activityDefinition.displayName",
      name: "Draft",
      dataType: "string",
      alignment: "left",
      formatter: "align",
    },
    {
      key: "createdAt",
      name: "Date created",
      dataType: "Date",
      formatter: "dateOnly",
      minWidth: 150,
      filterable: false,
    },
    {
      key: "link",
      name: "",
      dataType: "string",
      formatter: "buttonLink",
    },
  ];

  return {
    tableColumnsTopProjects,
    data,
  };
};
