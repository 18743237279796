import "./AboutUsSection.css";

interface AboutUsSectionProps {
  content: string;
  imageUrl: string;
  imageLayout?: "cover";
}

export const AboutUsSection = ({ content, imageUrl, imageLayout }: AboutUsSectionProps): JSX.Element => {
  return (
    <div className="AboutUsSection">
      <h2>About us</h2>
      <img
        src={imageUrl}
        alt="About us"
        style={imageLayout === "cover" ? { objectFit: "cover", width: "100%" } : undefined}
      />
      <p className="body1">{content}</p>
    </div>
  );
};
