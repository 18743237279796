import { useCallback, useEffect, useState } from "react";

import { OrganisationTypeConstants } from "../../../../constants";
import { SelectData } from "../../../../models";
import { getStatsAggregate } from "../../../../service/query";
import { ResultData, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { flattenObject } from "../../../../utils";
import { ChartData, DataGridColumnDefinition } from "../../../../widget";

interface UseMarketingStatsReturnData {
  metrics: SelectData;
  currentMetric: string;
  periodicities: SelectData;
  currentPeriodicity: string;
  chartData: ChartData;
  columns: DataGridColumnDefinition[];
  gridData: ResultData[];
  isLoading: boolean;
  setCurrentMetric: (value: string) => void;
  setCurrentPeriodicity: (value: string) => void;
}

export const useMarketingStats = (): UseMarketingStatsReturnData => {
  const { currentDeveloperUuid } = useAuth();

  const [metrics] = useState<SelectData>([
    { key: "Project View", value: "Project views" },
    { key: "Project Enquiry", value: "Project enquiries" },
    { key: `${OrganisationTypeConstants.DEVELOPER} Enquiry`, value: "Developer enquiries" },
  ]);
  const [periodicities] = useState<SelectData>([
    { key: "PreviousDay", value: "Last day" },
    { key: "PreviousWeek", value: "Last week" },
    { key: "PreviousMonth", value: "Last month" },
    { key: "PreviousQuarter", value: "Last quarter" },
    { key: "PreviousYear", value: "Last year" },
  ]);
  const [currentMetric, setCurrentMetric] = useState(metrics[0].key as string);
  const [currentPeriodicity, setCurrentPeriodicity] = useState(periodicities[0].key as string);
  const [chartData, setChartData] = useState<ChartData>({});
  const [gridData, setGridData] = useState<ResultData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const columns: DataGridColumnDefinition[] = [
    {
      name: "Project",
      key: "objectName",
      dataType: "string",
    },
    {
      name: "Metric",
      key: "metric",
      dataType: "string",
    },
    {
      name: "Time period",
      key: "periodicity",
      dataType: "string",
    },
    {
      name: "Views",
      key: "value",
      dataType: "number",
    },
  ];

  const loadData = useCallback(async (): Promise<void> => {
    // TODO ENG-236: restore this functionality as part of the Marketing Stats ticket (by adding
    // in the GraphQL service definitions manually)
    const metric = currentMetric.split(" ")[1];
    const objectType = currentMetric.split(" ")[0];
    const response = await getStatsAggregate({
      metric,
      periodicity: currentPeriodicity,
      objectType,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectUuid: objectType === OrganisationTypeConstants.DEVELOPER ? currentDeveloperUuid! : null,
    });

    if (response.status === Status.Success && response.data) {
      setChartData(
        response.data.reduce<ChartData>((previous, current) => {
          // eslint-disable-next-line no-param-reassign
          previous[current.objectName] = current.value;

          return previous;
        }, {})
      );
      setGridData(
        response.data.map((el) => {
          const result = flattenObject(el);

          result.metric = metrics.find((m) => m.key === currentMetric)?.value;
          result.periodicity = periodicities.find((p) => p.key === currentPeriodicity)?.value;
          return result;
        }) || []
      );
    }
  }, [currentMetric, currentPeriodicity]);

  useEffect(() => {
    loadData().finally(() => {
      setIsLoading(false);
    });
  }, [currentMetric, currentPeriodicity, currentDeveloperUuid]);

  return {
    metrics,
    currentMetric,
    periodicities,
    currentPeriodicity,
    chartData,
    columns,
    gridData,
    isLoading,
    setCurrentMetric,
    setCurrentPeriodicity,
  };
};
