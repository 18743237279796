import "./YoutubePlayer.css";

import { getYoutubeId } from "../../../utils";

interface YoutubePlayerProps {
  videoUrl: string;
}

export const YoutubePlayer = ({ videoUrl }: YoutubePlayerProps): JSX.Element => {
  const videoId = getYoutubeId(videoUrl);
  const src = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="YoutubePlayerContainer">
      <iframe title="YoutubePlayer" src={src} allow="fullscreen" />
    </div>
  );
};
