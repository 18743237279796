import "./SubmitActivityModal.css";

import { DownloadDarkIcon, FileCornerBlueIcon } from "../../../../assets";
import { ServiceError } from "../../../../service/Shared";
import { Button, Column, ErrorBox, InformationBox, Modal, RadioButtonGroup, Row, Select } from "../../../../widget";
import { RadioChangeEvent } from "../../../../widget/forms/Input/RadioButtonGroup/components";
import { ModalState } from "./models";
import { useSubmitActivityModal } from "./useSubmitActivityModal";

interface SubmitActivityModalProps {
  show: boolean;
  onClose: () => void;
  projectUuid?: string;
  groupUuid?: string;
  activityUuid: string;
  activityStatus: string;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
  isDraft: boolean;
  shouldRefreshActivities?: () => void;
  shouldRefreshProjectDetails?: () => void;
  exitActivityWizard?: () => void;
}

const errorBox = (errors: ServiceError[]): JSX.Element | undefined => {
  return errors && errors.length > 0 ? (
    <Row spacingV="ml" justify="center">
      <Column span={8}>
        <ErrorBox text={errors.map((error) => error.message)} />
      </Column>
    </Row>
  ) : undefined;
};

export const SubmitActivityModal = ({
  show,
  onClose,
  projectUuid,
  groupUuid,
  activityUuid,
  activityStatus,
  activityHistoryUuid,
  activityHistoryRowVersion,
  isDraft,
  shouldRefreshActivities,
  shouldRefreshProjectDetails,
  exitActivityWizard,
}: SubmitActivityModalProps): JSX.Element => {
  const {
    modalState,
    correctVVB,
    selectVVBData,
    isLoading,
    selectedVVB,
    errors,
    showDownloadMessage,
    currentVerifierDisplayName,
    setSelectedVVB,
    setCorrectVVBAndResetSelectedVVB,
    onCancel,
    onSubmitClick,
    onContinueClick,
    downloadActivityDocuments,
  } = useSubmitActivityModal({
    groupUuid,
    projectUuid,

    activityUuid,
    activityStatus,
    activityHistoryUuid,
    activityHistoryRowVersion,
    isDraft,
    shouldRefreshActivities,
    shouldRefreshProjectDetails,
    exitActivityWizard,
    onClose,
  });

  switch (modalState) {
    case ModalState.MANUALLY_SUBMIT_ACTIVITY:
      return (
        <Modal show={show} title="Manually Submit activity" onClose={onCancel}>
          <Row spacingV="ml" justify="center">
            <Column span={10}>
              <span className="body1">
                Since the VVB for this project or group is not on Kana, you will need to manually submit this activity.
                Please download the output document and all associated documents below, send them to the project’s VVB
                and then confirm the submission.
              </span>
            </Column>
          </Row>
          <Row spacingV="ml" justify="center">
            <Column span={10}>
              <div
                role="button"
                className="SubmitActivityModalDownloadDocumentsButton"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    downloadActivityDocuments();
                  }
                }}
                onClick={() => downloadActivityDocuments()}
                tabIndex={0}
              >
                <div className="DownloadDocumentsButtonSection">
                  <FileCornerBlueIcon />
                  <h3>Output + all associated documents</h3>
                </div>
                <div className="DownloadDocumentsButtonSection">
                  <h4>Download</h4>
                  <DownloadDarkIcon />
                </div>
              </div>
            </Column>
          </Row>
          <Row spacingV="ml" justify="center">
            <Column span={10}>
              <div className="SubmitActivityModalInfoBox">
                <InformationBox variant="info">
                  <p className="body2">
                    Please ensure your application has been accepted by your chosen VVB. If not, you are at risk of the
                    submission being cancelled and returned to you.
                  </p>
                </InformationBox>
              </div>
            </Column>
          </Row>
          {showDownloadMessage && (
            <Row spacingV="ml" justify="center">
              <Column span={10}>
                <div className="SubmitActivityModalInfoBox">
                  <InformationBox variant="info">
                    <p className="body2">
                      Due to the size of these files they may take a couple of minutes to prepare.
                      <br />
                      <br />
                      We will send you an email with a link to access them once they are ready for you to download, so
                      please keep an eye on your inbox.
                    </p>
                  </InformationBox>
                </div>
              </Column>
            </Row>
          )}
          <Row justify="center">
            <Column span={6}>
              <div className="SubmitActivityModalButtons">
                <Button text="Confirm submission" onClick={() => onSubmitClick(false)} isLoading={isLoading} />
                <Button variant="secondary" text="Cancel" onClick={onCancel} isLoading={isLoading} />
              </div>
            </Column>
          </Row>
        </Modal>
      );
    case ModalState.DIGITALLY_SUBMIT_ACTIVITY:
      return (
        <Modal show={show} title="Digitally Submit activity" onClose={onCancel}>
          <Row spacingV="ml" justify="center">
            <Column span={10}>
              <span className="body1">
                Submitting this activity will send a notification to the manager of the VVB organisation to begin the
                review process. All external discussions will be published and the activity will be locked whilst in
                review.
              </span>
            </Column>
          </Row>
          <Row spacingV="ml" justify="center">
            <Column span={10}>
              <div className="SubmitActivityModalInfoBox">
                <InformationBox variant="info">
                  <p className="body2">
                    Please ensure your application has been accepted by your chosen VVB. If not, you are at risk of the
                    submission being cancelled and returned to you.
                  </p>
                </InformationBox>
              </div>
            </Column>
          </Row>
          <Row justify="center">
            <Column span={6}>
              <div className="SubmitActivityModalButtons">
                <Button text="Submit" onClick={() => onSubmitClick(true)} isLoading={isLoading} />
                <Button variant="secondary" text="Cancel" onClick={onCancel} isLoading={isLoading} />
              </div>
            </Column>
          </Row>
        </Modal>
      );
    case ModalState.SUBMIT_ACTIVITY_MULTIPLE_VVBS:
      return (
        <Modal show={show} title="Submit activity" onClose={onCancel}>
          {errorBox(errors)}
          <Row spacingV="ml" justify="center">
            <Column span={10}>
              <div className="SubmitActivityModalInfoBox">
                <InformationBox title="Multiple VVBs for this group" variant="alert">
                  <p className="body2">
                    Currently there are multiple VVBs listed against the projects in this group. To proceed with
                    submission, all projects must all use the same VVB.
                    <br />
                    <br />
                    Please select a VVB below to be assigned to this group.
                  </p>
                </InformationBox>
              </div>
            </Column>
          </Row>
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <div className="SubmitActivityModalSelect">
                <Select
                  label="Please select your chosen VVB for the group"
                  placeholder="Select VVB"
                  data={selectVVBData}
                  value={selectVVBData.some((e) => e.key === selectedVVB) ? selectedVVB : undefined}
                  onChange={(e) => setSelectedVVB(e)}
                />
              </div>
            </Column>
          </Row>
          <Row justify="center">
            <Column span={6}>
              <div className="SubmitActivityModalButtons">
                <Button text="Continue" onClick={onContinueClick} isLoading={isLoading} />
                <Button variant="secondary" text="Cancel" onClick={onCancel} isLoading={isLoading} />
              </div>
            </Column>
          </Row>
        </Modal>
      );
    case ModalState.SUBMIT_ACTIVITY:
    default:
      return (
        <Modal show={show} title="Submit activity" onClose={onCancel}>
          {errorBox(errors)}
          <Row spacingV="ml" justify="center">
            <Column span={10}>
              <span className="body1">
                This project or group is currently listed with {currentVerifierDisplayName ?? "no verifier"} as the
                chosen VVB. Is this correct? Note: all projects within a group must be listed with the same VVB.
              </span>
            </Column>
          </Row>
          <Row spacingV={correctVVB || correctVVB === undefined ? "ml" : "l"} justify="center">
            <Column span={6}>
              <div className="SubmitActivityModalRadioButtons">
                <RadioButtonGroup
                  label="Correct VVB?"
                  data={[
                    { key: true, value: "Yes" },
                    { key: false, value: "No" },
                  ]}
                  direction="vertical"
                  value={correctVVB}
                  onChange={(e: RadioChangeEvent) => setCorrectVVBAndResetSelectedVVB(e.target.value)}
                />
              </div>
            </Column>
          </Row>
          {correctVVB === false && (
            <Row spacingV="ml" justify="center">
              <Column span={6}>
                <div className="SubmitActivityModalSelect">
                  <Select
                    label="Please select your chosen VVB for the project or group"
                    placeholder="Select VVB"
                    data={selectVVBData}
                    value={selectedVVB}
                    onChange={(e) => setSelectedVVB(e)}
                  />
                </div>
              </Column>
            </Row>
          )}
          <Row justify="center">
            <Column span={6}>
              <div className="SubmitActivityModalButtons">
                <Button text="Continue" onClick={onContinueClick} isLoading={isLoading} />
                <Button variant="secondary" text="Cancel" onClick={onCancel} isLoading={isLoading} />
              </div>
            </Column>
          </Row>
        </Modal>
      );
  }
};
