import "./SocialMediaSection.css";

import {
  FacebookBlueIcon,
  InstagramBlueIcon,
  LinkedinBlueIcon,
  TwitterBlueIcon,
  YoutubeBlueIcon,
} from "../../../assets";
import { SocialMediaData } from "../../../models";
import { Column, Row } from "../../../widget";

interface SocialMediaSectionProps {
  isPreview: boolean;
  socialMedia: SocialMediaData;
}

export const SocialMediaSection = ({ isPreview, socialMedia }: SocialMediaSectionProps): JSX.Element => {
  return (
    <Row spacingV="ll" justify="center">
      <Column span={3} className="SocialMediaSection">
        {isPreview ? (
          <div className="UnclickableIcons">
            {socialMedia.linkedinURL && <LinkedinBlueIcon />}
            {socialMedia.twitterUsername && <TwitterBlueIcon />}
            {socialMedia.instagramUsername && <InstagramBlueIcon />}
            {socialMedia.youtubeChannel && <YoutubeBlueIcon />}
            {socialMedia.facebookURL && <FacebookBlueIcon />}
          </div>
        ) : (
          <div className="ClickableIcons">
            {socialMedia.linkedinURL && (
              <a href={socialMedia.linkedinURL} target="_blank" rel="noopener noreferrer">
                <LinkedinBlueIcon />
              </a>
            )}
            {socialMedia.twitterUsername && (
              <a href={`https://twitter.com/${socialMedia.twitterUsername}`} target="_blank" rel="noopener noreferrer">
                <TwitterBlueIcon />
              </a>
            )}
            {socialMedia.instagramUsername && (
              <a
                href={`https://www.instagram.com/${socialMedia.instagramUsername}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramBlueIcon />
              </a>
            )}
            {socialMedia.youtubeChannel && (
              <a href={socialMedia.youtubeChannel} target="_blank" rel="noopener noreferrer">
                <YoutubeBlueIcon />
              </a>
            )}
            {socialMedia.facebookURL && (
              <a href={socialMedia.facebookURL} target="_blank" rel="noopener noreferrer">
                <FacebookBlueIcon />
              </a>
            )}
          </div>
        )}
      </Column>
    </Row>
  );
};
