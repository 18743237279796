const isUpperCase = (str: string): boolean => /^[A-Z]*$/.test(str);

export const locationToNaturalLanguage = (location: string): string => {
  const locationWithSpacing: string[] = [];
  location.split("").forEach((char) => {
    if (isUpperCase(char)) {
      locationWithSpacing.push(" ");
    }
    locationWithSpacing.push(char);
  });
  return locationWithSpacing
    .join("")
    .split(".")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" > ");
};
