import { Dispatch, FormEvent, ForwardedRef, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

import { FileType, FileUploadHandle } from "../../../../../../models";
import { uploadOrganisationFile } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import { OrganisationFormChangedAction, OrganisationFormChangedEnum } from "../../models";

interface UseIconFormReturnData {
  logoUploadRef: ForwardedRef<FileUploadHandle>;
  displayedLogo: string | null;
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  currentUserType: string;
  setLogo: Dispatch<SetStateAction<File | null>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleCancel: () => void;
  hasPermission: (permission: string) => boolean;
}

export const useLogoForm = (
  objectUuid: string,
  objectType: string,
  organisationDefaultIcon: string | null,
  dispatch: Dispatch<OrganisationFormChangedAction>
): UseIconFormReturnData => {
  const { hasPermission, currentUserType } = useAuth();

  const [submittedValues, setSubmittedValues] = useState<File | null>(null);

  const logoUploadRef = useRef<FileUploadHandle>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const [displayedLogo, setDisplayedLogo] = useState<string | null>(organisationDefaultIcon);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const resetForm = (): void => {
    if (logoUploadRef.current) {
      logoUploadRef.current.clearInput();
      setLogo(null);
      setSubmittedValues(null);
      setErrors([]);
    }
  };

  const getCurrentFormData = useCallback((): File | null => {
    return logo;
  }, [logo]);

  useEffect(() => {
    dispatch({
      type: OrganisationFormChangedEnum.SET_LOGO_FORM_CHANGED,
      value: submittedValues !== getCurrentFormData(),
    });
  }, [logo, submittedValues]);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (objectUuid && logo) {
      const logoRes = await uploadOrganisationFile({
        objectUuid,
        objectType,
        file: logo,
        type: FileType.LargeLogo,
      });

      if (logoRes.status === Status.Success && logoRes.data && logoUploadRef.current) {
        Toast.success({ message: "Organisation logo changed successfully" });
        setSubmittedValues(logo);
        logoUploadRef.current.clearInput();
        setDisplayedLogo(logoRes.data?.file.url);
      }

      if (logoRes.status === Status.Error) {
        setErrors(logoRes.errors);
      }
    }
  }, setIsHandleSubmitLoading);

  const handleCancel = (): void => {
    resetForm();
  };

  return {
    logoUploadRef,
    displayedLogo,
    errors,
    isHandleSubmitLoading,
    currentUserType,
    setLogo,
    handleCancel,
    handleSubmit,
    hasPermission,
  };
};
