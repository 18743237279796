import { Button, Column, Modal, Row } from "../../../../../../../../widget";

interface ResendAccessInvitationModalProps {
  show: boolean;
  onResendAccessInvitation: () => void;
  closeModal: () => void;
}

export const ResendAccessInvitationModal = ({
  show,
  onResendAccessInvitation,
  closeModal,
}: ResendAccessInvitationModalProps): JSX.Element => {
  return (
    <Modal show={show} title="Resend user access?" onClose={closeModal}>
      <Row justify="center" spacingV="2xl">
        <Column span={12}>
          <p className="body1">Are you sure you want to resend this invitation?</p>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={12}>
          <Button text="Yes" onClick={onResendAccessInvitation} />
          <Button variant="secondary" text="No, take me back" onClick={closeModal} />
        </Column>
      </Row>
    </Modal>
  );
};
