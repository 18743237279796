import { Dispatch, SetStateAction } from "react";

import { DownwardArrowLightIcon } from "../../../assets";
import { DownloadActivityFilesModal } from "../../../route/developer/components";
import { ProfileMenu, ProfileMenuItem } from "../../navigation";
import { Button } from "../Button";
import { useDownloadDropdown } from "./useDownloadDropdown";

interface DownloadDropdownProps {
  activityHistoryUuid: string;
  currentOnly: boolean;
  showDownloadActivityFilesModal: boolean;
  setShowDownloadActivityFilesModal: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

export const DownloadDropdown = ({
  className,
  activityHistoryUuid,
  showDownloadActivityFilesModal,
  setShowDownloadActivityFilesModal,
  currentOnly,
}: DownloadDropdownProps): JSX.Element => {
  const { setShowDownloadDropdown, showDownloadDropdown, isDocumentDownloading, downloadDropdownOptions } =
    useDownloadDropdown({
      activityHistoryUuid,
      currentOnly,
      setShowDownloadActivityFilesModal,
    });

  return (
    <div className={className}>
      <Button
        text="Download"
        loadingText="Downloading"
        isLoading={isDocumentDownloading}
        onClick={() => setShowDownloadDropdown(true)}
        icon={<DownwardArrowLightIcon />}
      />
      <ProfileMenu show={showDownloadDropdown} onClose={() => setShowDownloadDropdown(false)} position={{ top: "0" }}>
        {downloadDropdownOptions.map((el) => (
          <ProfileMenuItem
            key={el.id}
            profileMenuItem={el}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              el.action!();
            }}
          />
        ))}
      </ProfileMenu>

      {showDownloadActivityFilesModal && (
        <DownloadActivityFilesModal
          show={showDownloadActivityFilesModal}
          onClose={() => {
            setShowDownloadActivityFilesModal(false);
          }}
        />
      )}
    </div>
  );
};
