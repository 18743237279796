import { SingleFileDropdownUpload, SingleFileDropdownUploadProps } from "../SingleFileDropdownUpload";
import { SingleFileReplace, SingleFileReplaceProps } from "../SingleFileReplace";
import { SingleFileUploadPropsV2, SingleFileUploadV2 } from "../SingleFileUploadV2";

export type SingleFileUploadType = "Default" | "Dropdown" | "Replace";

export const SingleFileUploadTypeConstants: Record<string, SingleFileUploadType> = {
  Default: "Default",
  Dropdown: "Dropdown",
  Replace: "Replace",
};

export interface SingleFileUploadSwitcherProps
  extends SingleFileUploadPropsV2,
    SingleFileDropdownUploadProps,
    SingleFileReplaceProps {
  type?: SingleFileUploadType;
}

export const SingleFileUploadSwitcher = ({
  type = "Default",
  ...componentProps
}: SingleFileUploadSwitcherProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    (type === "Default" && <SingleFileUploadV2 {...componentProps} />) ||
    // eslint-disable-next-line react/jsx-props-no-spreading
    (type === "Dropdown" && <SingleFileDropdownUpload {...componentProps} />) ||
    // eslint-disable-next-line react/jsx-props-no-spreading
    (type === "Replace" && <SingleFileReplace {...componentProps} />) || <SingleFileUploadV2 {...componentProps} />
  );
};
