import { isDate, isObject, isPlainObject } from "../isType";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const recursivelyRemoveEmptyStrings = (input: any = {}): any => {
  if (input === null || input === undefined) return input;
  if (typeof input === "string") return input;
  if (isDate(input)) return input;
  if (!isPlainObject(input)) return input;
  if (isObject(input)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ret: any = {};
    const entries = Object.entries(input);

    for (let i = 0; i < entries.length; i++) {
      const [objectKey, objectValue] = entries[i];
      if (objectValue === "") ret[objectKey] = null;
      else if (isObject(objectValue)) {
        ret[objectKey] = recursivelyRemoveEmptyStrings(objectValue);
      } else if (Array.isArray(objectValue)) {
        ret[objectKey] = recursivelyRemoveEmptyStrings(objectValue);
      } else {
        ret[objectKey] = objectValue;
      }
    }
    return ret;
  }
  if (Array.isArray(input)) {
    return input.map((i) => recursivelyRemoveEmptyStrings(i));
  }
  return input;
};
