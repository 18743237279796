import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { HelpCircleDarkIcon, LogoutDarkIcon } from "../../../assets";
import { KanaConstants, OrganisationTypeConstants, UserType } from "../../../constants";
import { GetCurrentUserResponse } from "../../../service/query";
import { useAuth } from "../../../useAuth";
import { getProfileChangePasswordRoute, getProfileChangePersonalDetailsRoute } from "../../../utils/routes";
import { ProfileMenuItemType } from "../ProfileMenuItem";

interface UseUserHeaderReturnData {
  showProfileMenu: boolean;
  setShowProfileMenu: (flag: boolean) => void;
  currentUserData: GetCurrentUserResponse | undefined;
  optionList: ProfileMenuItemType[];
  currentUserType: UserType;
  onOptionSelect: (option: ProfileMenuItemType) => void;
}

export const useUserHeader = (): UseUserHeaderReturnData => {
  const navigate = useNavigate();

  const { user, logoutUser, currentUserType } = useAuth();

  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const initialOptionList: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "Change personal details",
      route: getProfileChangePersonalDetailsRoute(currentUserType),
    },
    {
      id: 2,
      value: "Change password",
      route: getProfileChangePasswordRoute(currentUserType),
    },
  ];

  if (currentUserType === OrganisationTypeConstants.DEVELOPER) {
    initialOptionList.push(
      {
        id: 3,
        value: "Help",
        icon: <HelpCircleDarkIcon />,
        action: () => window.open(KanaConstants.KANA_HELP_URL, "_blank"),
      },
      {
        id: 4,
        value: "Log out",
        icon: <LogoutDarkIcon />,
        action: () => logoutUser(),
      }
    );
  } else {
    initialOptionList.push({
      id: 3,
      value: "Log out",
      icon: <LogoutDarkIcon />,
      action: () => logoutUser(),
    });
  }

  const [optionList] = useState(initialOptionList);

  // eslint-disable-next-line unused-imports/no-unused-vars
  const onOptionSelect = (option: ProfileMenuItemType): void => {
    if (option.action) {
      option.action();
    }
    if (option.route) {
      navigate(option.route);
    }
    setShowProfileMenu(false);
  };

  return {
    showProfileMenu,
    setShowProfileMenu,
    currentUserData: user,
    optionList,
    currentUserType,
    onOptionSelect,
  };
};
