import { RcFile } from "rc-upload/lib/interface";
import { useEffect, useId, useState } from "react";

import { PreviewFile } from "../../../../models";
import { Toast } from "../../../general";

interface useSingleFileUploadReturnData {
  id: string;
  isFileUploading: boolean;
  selectedFile: PreviewFile | undefined;
  onAction: (file: RcFile) => Promise<string>;
}

interface useSingleFileUploadProps {
  value?: PreviewFile;
  onChange: (e: PreviewFile) => void;
  onFileUpload?: (e: RcFile, repeaterIndex?: number) => Promise<PreviewFile>;
  maxFileSize: number;
  repeaterIndex?: number;
}

export const useSingleFileUploadV2 = ({
  onChange,
  onFileUpload,
  maxFileSize,
  value,
  repeaterIndex,
}: useSingleFileUploadProps): useSingleFileUploadReturnData => {
  const id = useId();
  const [selectedFile, setSelectedFile] = useState<PreviewFile | undefined>(undefined);
  const [isFileUploading, setIsFileUploading] = useState(false);

  useEffect(() => {
    setSelectedFile(value);
  }, [value]);

  const onAction = async (file: RcFile): Promise<string> => {
    setIsFileUploading(true);

    const fileSize = Number((file.size / 1024 / 1024).toFixed(4)); // MB

    if (fileSize > maxFileSize) {
      Toast.error({
        message: `Please select a file which is less than ${maxFileSize}MB`,
      });
      setIsFileUploading(false);

      return "";
    }

    let previewFile: PreviewFile = {
      file,
      mimeType: file.type,
      url: URL.createObjectURL(file),
      filename: file.name,
      uuid: file.uid,
    };

    // if on onFileUpload method is passed we await for the files to be uploaded.
    if (onFileUpload) {
      try {
        previewFile = await onFileUpload(file, repeaterIndex);
      } catch (error) {
        Toast.error({
          message: (error as string) || `Failed to upload ${file.name}`,
        });
        setIsFileUploading(false);

        return "";
      }
    }

    setSelectedFile(previewFile);
    onChange(previewFile);
    setIsFileUploading(false);

    return "";
  };

  return {
    id,
    isFileUploading,
    selectedFile,
    onAction,
  };
};
