import "./GroupSection.css";

import { Button, Column, CursorDataGrid, Row } from "../../../../../../../widget";
import { useGroupSection } from "./useGroupSection";

interface GroupSectionProps {
  groupUuid: string;
  currentProjectUuid: string;
}

export const GroupSection = ({ groupUuid, currentProjectUuid }: GroupSectionProps): JSX.Element => {
  const {
    columns,
    onChange,
    dataIsLoading,
    defaultSortingCriteria,
    refreshProjects,
    hasWriteProjectDetailsPermission,
    editGroupOnClick,
  } = useGroupSection(groupUuid, currentProjectUuid);

  return (
    <>
      <Row spacingV="ml">
        <Column span={6}>
          <h2>Group</h2>
        </Column>
        {hasWriteProjectDetailsPermission && (
          <Column span={6} justify="end" className="GroupSectionEditButton">
            <Button variant="primary" size="small" onClick={editGroupOnClick} text="Edit group" />
          </Column>
        )}
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <p>This project is part of a group alongside the following other projects</p>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <CursorDataGrid
            columns={columns}
            filterable
            clientSide={false}
            onChange={onChange}
            data={[]}
            dataIsLoading={dataIsLoading}
            noDataMessage="No group projects to display"
            refresh={refreshProjects}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </Column>
      </Row>
    </>
  );
};
