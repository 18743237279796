import { SelectData } from "../../../../../../models";
import { ActionButtons, Column, ErrorBox, InformationBox, Row, Select, TextInput } from "../../../../../../widget";
import { useSendInvitationForm } from "./useSendInvitationForm";

interface SendInvitationFormProps {
  roles: SelectData | undefined;
  rolesInfo: string[];
  userSettingsRoute: string;
}

export const SendInvitationForm = ({ roles, rolesInfo, userSettingsRoute }: SendInvitationFormProps): JSX.Element => {
  const {
    firstName,
    lastName,
    email,
    role,
    errors,
    isHandleSubmitLoading,
    setFirstName,
    setLastName,
    setEmail,
    setRole,
    handleSubmit,
    handleCancel,
  } = useSendInvitationForm(userSettingsRoute);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={8}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={4}>
            <TextInput
              label="First name"
              name="firstName"
              value={firstName}
              onChange={(value) => setFirstName(value)}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={4}>
            <TextInput label="Last name" name="lastName" value={lastName} onChange={(value) => setLastName(value)} />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={4}>
            <TextInput
              type="email"
              label="Email address"
              name="email"
              value={email}
              onChange={(value) => setEmail(value)}
            />
          </Column>
        </Row>
        {roles && (
          <Row spacingV="4xl">
            <Column span={4}>
              <Select label="Role" initialKey={role} data={roles} onChange={(value) => setRole(value)} />
              <InformationBox variant="info" title="What can each role do?">
                <ul>
                  {rolesInfo.map((info, index) => (
                    <div key={info}>
                      <li>{info}</li>
                      {index !== rolesInfo.length - 1 && <br />}
                    </div>
                  ))}
                </ul>
              </InformationBox>
            </Column>
          </Row>
        )}
        <Row>
          <Column span={2}>
            <ActionButtons submitButtonLabel="Send Invite" onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
          </Column>
        </Row>
      </form>
    </>
  );
};
