import "./HorizontalProjectCards.css";

import { CircleArrowLeftDarkIcon, CircleArrowRightDarkIcon, RightArrowOrangeIcon } from "../../../assets";
import { UuidType } from "../../../models";
import { getBadgePublicProjectsRoute, getCodePublicProjectsRoute } from "../../../utils/routes";
import { Column, Row } from "../../layout";
import { ImageButton } from "../../layout/ExpandableCard/components";
import { HorizontalProjectCard } from "./components";
import { DisplayHorizontalProject } from "./models";
import { useHorizontalProjectCards } from "./useHorizontalProjectCards";

interface HorizontalProjectCardsProps {
  uuidType?: UuidType;
  codeUuid?: string;
  codeName: string;
  projects: DisplayHorizontalProject[];
}

export const HorizontalProjectCards = ({
  uuidType = UuidType.Code,
  codeUuid,
  codeName,
  projects,
}: HorizontalProjectCardsProps): JSX.Element => {
  const { hasLeftArrow, hasRightArrow, onRightArrowClick, onLeftArrowClick, cardsRef, onScroll, codeUuids } =
    useHorizontalProjectCards(codeUuid);
  return (
    <div className="HorizontalProjectCards">
      <Row spacingV="s">
        <Column span={12}>
          <div className="HorizontalProjectCardsHeader">
            <h2>Projects</h2>
            <ImageButton
              icon={<RightArrowOrangeIcon />}
              route={
                uuidType === UuidType.Code
                  ? getCodePublicProjectsRoute(codeUuid, codeUuids)
                  : getBadgePublicProjectsRoute(codeUuid)
              }
              linkText=" View all projects"
            />
          </div>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          {uuidType === UuidType.Code ? (
            <p className="body1">Some of the great projects who follow the {codeName} standards</p>
          ) : (
            <p className="body1">Some of the great projects who have the {codeName} badge</p>
          )}
        </Column>
      </Row>
      <div className="ArrowLayer">
        {hasLeftArrow && <CircleArrowLeftDarkIcon className="CircleArrowLeftDarkIcon" onClick={onLeftArrowClick} />}
        <Row>
          <div className="CardsSection" ref={cardsRef} onScroll={onScroll}>
            {projects.map((p) => (
              <div key={p.uuid}>
                <Column span={3}>
                  <HorizontalProjectCard project={p} />
                </Column>
              </div>
            ))}
          </div>
        </Row>
        {hasRightArrow && <CircleArrowRightDarkIcon className="CircleArrowRightDarkIcon" onClick={onRightArrowClick} />}
      </div>
    </div>
  );
};
