import { useState } from "react";

import { RadioButtonGroupData } from "../../../../models";
import { wordCount } from "../../../../utils";

interface AlignmentInterface {
  direction: "horizontal" | "vertical" | undefined;
}

interface UseRadioButtonGroupReturnData {
  alignment: AlignmentInterface;
}

export const useRadioButtonGroup = (
  data: RadioButtonGroupData,
  specifiedAlignment: AlignmentInterface
): UseRadioButtonGroupReturnData => {
  const [alignment, setAlignment] = useState<AlignmentInterface>(specifiedAlignment);

  if (!alignment.direction) {
    setAlignment({ direction: "horizontal" });

    if (data.length > 2 || data.find((option) => wordCount(String(option.key)) > 1)) {
      setAlignment({ direction: "vertical" });
    }
  }

  return {
    alignment,
  };
};
