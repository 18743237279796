import { useContext } from "react";

import { CursorChangeProps } from "../../../../../models";
import { ResultData } from "../../../../../service/Shared";
import { ActivityDiscussionContext } from "../ActivityDiscussionContext";

interface UseActivityDiscussionTableReturnData {
  refreshActivities: boolean;
  isDataLoading: boolean;
  triggerPagePrevious?: boolean;
  onRequestFetchDiscussions: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useDiscussionTable = (): UseActivityDiscussionTableReturnData => {
  const { refreshActivities, onRequestFetchDiscussions, isDataLoading, triggerPagePrevious } =
    useContext(ActivityDiscussionContext);

  return {
    isDataLoading,
    refreshActivities,
    onRequestFetchDiscussions,
    triggerPagePrevious,
  };
};
