import { Button, Column, CursorDataGrid, Row } from "../../../../../widget";
import { useList } from "./useList";

export const List = (): JSX.Element => {
  const { dataGridColumns, isLoading, onDataGridChange, onNewUserClick } = useList();
  return (
    <div>
      <Row spacingV="ll">
        <Column span={12}>
          <div className="SettingsUserListHeader">
            <h2>Users</h2>
            <Button text="New User" onClick={onNewUserClick} />
          </div>
        </Column>
      </Row>
      <CursorDataGrid
        columns={dataGridColumns}
        clientSide={false}
        filterable
        onChange={onDataGridChange}
        data={[]}
        dataIsLoading={isLoading}
        noDataMessage="No users to display"
      />
    </div>
  );
};
