import "./HorizontalStatusKPI.css";

interface HorizontalStatusKPIProps {
  status: number;
  projectName: string;
  activityName: string;
  activityVersion: string;
}

export const HorizontalStatusKPI = ({
  status,
  projectName,
  activityName,
  activityVersion,
}: HorizontalStatusKPIProps): JSX.Element => {
  return (
    <div className="HorizontalStatusContainer">
      <div className="HorizontalStatusHeader">
        {projectName && <p className="body2">{projectName}</p>}
        <p className="body3">{`${activityName} ${activityVersion}`}</p>
      </div>
      <div className="HorizontalStatusBar">
        <div className="HorizontalStatusBarFilled" style={{ flex: status }} />
        <div className="HorizontalStatusBarUnfilled" style={{ flex: 100 - status }} />
      </div>
      <p className="body3">{status}% complete</p>
    </div>
  );
};
