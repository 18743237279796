import { PublicTemplate, PublicVerifierDetailsPageTemplate } from "../../../../../templates";
import { useDetails } from "./useDetails";

const Details = (): JSX.Element => {
  const { dataIsLoading, verifierDetailsData } = useDetails();
  return (
    <PublicTemplate activeElement="Project list" isContentUnwrapped>
      {!dataIsLoading && verifierDetailsData && (
        <PublicVerifierDetailsPageTemplate verifierDetailsData={verifierDetailsData} />
      )}
    </PublicTemplate>
  );
};

export default Details;
