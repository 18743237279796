import { Dispatch } from "react";

import { OrganisationPermissionConstants } from "../../../../../../constants";
import { CountriesData } from "../../../../../../models";
import { ActionButtons, Autocomplete, Column, ErrorBox, Row, TextInput } from "../../../../../../widget";
import { OrganisationAddressData, OrganisationFormChangedAction } from "../../models";
import { useAddressForm } from "./useAddressForm";

interface AddressDataProps {
  countries: CountriesData | undefined;
  organisationDefaultAddress: OrganisationAddressData;
  dispatch: Dispatch<OrganisationFormChangedAction>;
}

export const AddressForm = ({ countries, organisationDefaultAddress, dispatch }: AddressDataProps): JSX.Element => {
  const {
    addressLine1,
    postcode,
    addressLine2,
    countryCode,
    region,
    city,
    errors,
    isHandleSubmitLoading,
    setAddressLine1,
    setPostcode,
    setAddressLine2,
    setCountryCode,
    setRegion,
    setCity,
    handleSubmit,
    handleCancel,
    hasPermission,
  } = useAddressForm(organisationDefaultAddress, dispatch);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ll">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}

      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={5}>
            <TextInput
              label="Address line 1"
              name="addressLine1"
              value={addressLine1}
              onChange={(value) => setAddressLine1(value)}
            />
          </Column>
          <Column span={5} offset={1}>
            <TextInput label="Postcode" name="postcode" value={postcode} onChange={(value) => setPostcode(value)} />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={5}>
            <TextInput
              label="Address line 2"
              name="addressLine2"
              value={addressLine2}
              onChange={(value) => setAddressLine2(value)}
            />
          </Column>
          <Column span={5} offset={1}>
            <TextInput
              label="County / Region / State"
              name="region"
              value={region}
              onChange={(value) => setRegion(value)}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={5}>
            <TextInput label="City" name="city" value={city} onChange={(value) => setCity(value)} />
          </Column>
          {countries && (
            <Column span={5} offset={1}>
              <Autocomplete
                label="Country"
                initialKey={countryCode}
                data={countries}
                onSelect={(value) => setCountryCode(value)}
              />
            </Column>
          )}
        </Row>
        {hasPermission(OrganisationPermissionConstants.WRITE) && (
          <Row spacingV="2xl">
            <Column span={5}>
              <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
            </Column>
          </Row>
        )}
      </form>
    </>
  );
};
