import kanaExpressionInterpreter from "../../../../../../../../lib/KanaExpressionInterpreter";
import { Column, Row } from "../../../../../../../../widget";
import { Component, DataType, StandardSpecificData, Step } from "../types";
import { ProcessComponents } from ".";
import { ProcessChart } from "./ProcessChart";

interface ProcessStepsProps {
  steps: Step[];
  key: string;
  data: StandardSpecificData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateData?: (dataPath: string, value: any, dataType: DataType) => void;
  displayOnlyReadonly?: boolean;
}

export const ProcessSteps = ({
  steps,
  key,
  data,
  expressionContext,
  updateData,
  displayOnlyReadonly = false,
}: ProcessStepsProps): JSX.Element => {
  return (
    <div className="StandardSpecificSectionStepContainer">
      {steps.map((step: Step) => (
        <div key={step.key}>
          {step &&
            step.components &&
            (step.components.find((component: Component) => component.component === "Chart") ? (
              <div>
                <Row spacingV="ml">
                  <Column span={6}>
                    <h3>{step.label}</h3>
                  </Column>
                </Row>
                {step.components.every(
                  (component: Component) =>
                    (component.editableConditionExpression !== undefined &&
                      !kanaExpressionInterpreter(component.editableConditionExpression, expressionContext)) ||
                    displayOnlyReadonly
                )
                  ? ProcessChart({ step, key, data, expressionContext, updateData, displayOnlyReadonly })
                  : ProcessComponents({
                      components: step.components,
                      key: `${key}.${step.key}`,
                      expressionContext,
                      updateData,
                      displayOnlyReadonly,
                    })}
              </div>
            ) : (
              step.components &&
              ProcessComponents({
                components: step.components,
                key: `${key}.${step.key}`,
                expressionContext,
                updateData,
                displayOnlyReadonly,
              })
            ))}
        </div>
      ))}
    </div>
  );
};
