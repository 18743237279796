import { DeveloperSingleCardTemplate } from "../../../../templates";
import { ChangePasswordForm } from "../../../shared";

const ChangePassword = (): JSX.Element => {
  return (
    <DeveloperSingleCardTemplate pageTitle="Change Password">
      <ChangePasswordForm />
    </DeveloperSingleCardTemplate>
  );
};

export default ChangePassword;
