import "./Projects.css";

import { PublicTemplate } from "../../../templates";
import { Column, Divider, Row } from "../../../widget";
import { ProjectsSearch } from "./components";

const Projects = (): JSX.Element => {
  return (
    <PublicTemplate activeElement="Project list">
      <Row spacingV="2xl">
        <Column span={12} className="ProjectsHeader">
          <h1>Projects</h1>
          <div className="body1">
            Browse the list of UK nature-based carbon projects and learn more about the positive impact they are making
          </div>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <ProjectsSearch />
    </PublicTemplate>
  );
};

export default Projects;
