import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { TotalMarketValueTotalReturnsData } from "../../../../models";
import { thousandsFormatter } from "../../../../utils";

interface useUnitsAveragePriceKPIReturnData {
  toggleUnitsAveragePrice: boolean;
  setToggleUnitsAveragePrice: Dispatch<SetStateAction<boolean>>;
  formattedValue: (string | undefined)[];
}

interface useUnitsAveragePriceKPIProps {
  averagePrice: TotalMarketValueTotalReturnsData;
}

export const useUnitsAveragePriceKPI = ({
  averagePrice,
}: useUnitsAveragePriceKPIProps): useUnitsAveragePriceKPIReturnData => {
  const [toggleUnitsAveragePrice, setToggleUnitsAveragePrice] = useState(false);

  const formattedValue = useMemo(() => {
    if (averagePrice) {
      const valueString = thousandsFormatter.format(averagePrice.value);

      const lastChar = valueString.at(-1);

      // Handle formatting for numbers < 1000
      if (lastChar && lastChar >= "0" && lastChar <= "9") {
        return [valueString];
      }
      return [valueString.slice(0, -1), lastChar];
    }
    return [];
  }, [averagePrice]);

  return {
    toggleUnitsAveragePrice,
    setToggleUnitsAveragePrice,
    formattedValue,
  };
};
