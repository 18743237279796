/* eslint-disable no-nested-ternary */
import { BarChart, Button, Column, DataGrid, ErrorBox, Row } from "../../../../../../widget";
import { useIssuancesForm } from "./useIssuancesForm";

export const IssuancesForm = (): JSX.Element => {
  const {
    chartData,
    dataGridColumns,
    dataGridData,
    isLoading,
    errors,
    onSubmit,
    writeIssuancePermission,
    isOnSubmitLoading,
  } = useIssuancesForm();
  return (
    <div>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      {!!Object.keys(chartData).length && (
        <Row spacingV="ll">
          <Column span={12}>
            <BarChart data={chartData} />
          </Column>
        </Row>
      )}
      <form onSubmit={onSubmit}>
        <Row spacingV="ll">
          <Column span={12}>
            <DataGrid
              clientSide
              columns={dataGridColumns}
              data={dataGridData}
              pagination={{ pageSize: dataGridData.length }}
              filterable={false}
              sortable={false}
              pageable={false}
              dataIsLoading={isLoading}
              noDataMessage="The project is not yet validated, so issuance data is not available
            yet."
            />
          </Column>
        </Row>
        {!isLoading && !!dataGridData.length && writeIssuancePermission && (
          <Row justify="end">
            <Column span={12}>
              <Button text="Save" type="submit" isLoading={isOnSubmitLoading} />
            </Column>
          </Row>
        )}
      </form>
    </div>
  );
};
