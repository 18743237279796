import { Helmet } from "react-helmet-async";

import { GetProjectDetailsResponse } from "../../../../service/publicquery";

interface PublicProjectTemplateMetaTagsProps {
  project: GetProjectDetailsResponse | undefined;
}

export const PublicProjectTemplateMetaTags = ({ project }: PublicProjectTemplateMetaTagsProps): JSX.Element | null => {
  return (
    <Helmet>
      <title>{project?.displayName}</title>
      <meta name="title" content={project?.displayName} />
      <meta name="description" content={project?.listing?.content?.longDescription} />
      <script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "Dataset",
            name: project?.displayName,
            description: project?.listing?.content?.longDescription,
            sourceOrganization: project?.developer.displayName,
          },
          null,
          "\t"
        )}
      </script>
    </Helmet>
  );
};
