import { ResultData } from "../service/Shared";

// Flatten an object e.g. `{ a: { b: { c: 10 } } }`
// becomes `{ "a.b.c": 10 }`
export const flattenObject = (
  // NCU: this function is dynamic by nature -> `any` is required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ob: any,
  prefix: string | undefined = undefined,
  result: ResultData | undefined = undefined
): ResultData => {
  const resultData = result || {};
  const currentPrefix = prefix ? `${prefix}.` : "";
  if (ob == null) return {};

  Object.keys(ob).forEach((key) => {
    if (typeof ob[key] === "object" && ob[key] !== null && !(ob[key] instanceof Date)) {
      flattenObject(ob[key], currentPrefix + key, resultData);
    } else {
      resultData[currentPrefix + key] = ob[key];
    }
  });
  return resultData;
};

// Get a value from a nested object where the key has been flattened
// e.g. the key is `"a.b.c"` and the object is `{ a: { b: { c: 10 } } }`
// NCU: this function is dynamic by nature -> `any` is required
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueFromFlattenedKey = (key: string, obj: any): any => {
  // NCU: this function is dynamic by nature -> `any` is required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let current: any = obj;
  key.split(".").forEach((prop) => {
    current = current[prop];
  });
  return current;
};
