import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from "react";

import { OrganisationPermissionConstants } from "../../../../../../constants";
import { ActionButtons, Column, ErrorBox, Row, Toggle } from "../../../../../../widget";
import { Section } from "../../../../../shared";
import {
  DeveloperMarketingAssetsFormChangedAction,
  DeveloperMarketingAssetsSubmitHandler,
  FormOurApproachData,
} from "../../models";
import { useOurApproachForm } from "./useOurApproachForm";

interface OurApproachProps {
  ourApproachDefaultValues: FormOurApproachData;
  ourApproachOldDefaultValues: FormOurApproachData;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<DeveloperMarketingAssetsFormChangedAction>;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

export const OurApproachForm = forwardRef(
  (
    {
      ourApproachDefaultValues,
      ourApproachOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      getCurrentFormsData,
    }: OurApproachProps,
    forwardedRef: ForwardedRef<DeveloperMarketingAssetsSubmitHandler>
  ): JSX.Element => {
    const {
      enabled,
      part1ImageUploadRef,
      part2ImageUploadRef,
      part3ImageUploadRef,
      part4ImageUploadRef,
      part1Title,
      part1Content,
      part1ImageUrl,
      part2Title,
      part2Content,
      part2ImageUrl,
      part3Title,
      part3Content,
      part3ImageUrl,
      part4Title,
      part4Content,
      part4ImageUrl,
      errors,
      isHandleSubmitLoading,
      setEnabled,
      setPart1Title,
      setPart1Content,
      setPart2Title,
      setPart2Content,
      setPart3Title,
      setPart3Content,
      setPart4Title,
      setPart4Content,
      handleSetImage,
      handleCancel,
      handleSubmit,
      hasPermission,
    } = useOurApproachForm(
      ourApproachDefaultValues,
      ourApproachOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      forwardedRef,
      getCurrentFormsData
    );

    return (
      <>
        <Row spacingV="m">
          <Column span={4}>
            <h2>Our approach</h2>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <span className="body1">Please fill out one or more of the following sections.</span>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={4}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row spacingV="ll">
            <Column span={12}>
              <Toggle label="Show this section on organisation profile" value={enabled} onChange={setEnabled} />
            </Column>
          </Row>
          <Section
            imageRef={part1ImageUploadRef}
            disabled={!enabled}
            header="Section 1"
            formName="our approach"
            sectionNumber={1}
            title={part1Title}
            content={part1Content}
            imageUrl={part1ImageUrl}
            setTitle={setPart1Title}
            setContent={setPart1Content}
            handleSetImage={handleSetImage}
          />
          <Section
            imageRef={part2ImageUploadRef}
            disabled={!enabled}
            header="Section 2"
            formName="our approach"
            sectionNumber={2}
            title={part2Title}
            content={part2Content}
            imageUrl={part2ImageUrl}
            setTitle={setPart2Title}
            setContent={setPart2Content}
            handleSetImage={handleSetImage}
          />
          <Section
            imageRef={part3ImageUploadRef}
            disabled={!enabled}
            header="Section 3"
            formName="our approach"
            sectionNumber={3}
            title={part3Title}
            content={part3Content}
            imageUrl={part3ImageUrl}
            setTitle={setPart3Title}
            setContent={setPart3Content}
            handleSetImage={handleSetImage}
          />
          <Section
            imageRef={part4ImageUploadRef}
            disabled={!enabled}
            header="Section 4"
            formName="our approach"
            sectionNumber={4}
            title={part4Title}
            content={part4Content}
            imageUrl={part4ImageUrl}
            setTitle={setPart4Title}
            setContent={setPart4Content}
            handleSetImage={handleSetImage}
          />
          {hasPermission(OrganisationPermissionConstants.WRITE) && (
            <Row spacingV="2xl">
              <Column span={4}>
                <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
              </Column>
            </Row>
          )}
        </form>
      </>
    );
  }
);
