import { Dispatch, SetStateAction, useState } from "react";

interface UseMergeProjectsModalReturnData {
  modalTitle: string;
  setModalTitle: Dispatch<SetStateAction<string>>;
}

export const useMergeProjectsModal = (): UseMergeProjectsModalReturnData => {
  const [modalTitle, setModalTitle] = useState<string>("");

  return {
    modalTitle,
    setModalTitle,
  };
};
