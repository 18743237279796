import "./ErrorBox.css";

import { ErrorIcon } from "../../../assets";
import { useErrorBox } from "./useErrorBox";

interface ErrorBoxProps {
  text: string | string[];
  title?: string;
  animation?: boolean;
}

export const ErrorBox = ({ text, title = "Please check", animation = true }: ErrorBoxProps): JSX.Element => {
  const { elementId, messages } = useErrorBox(text, animation);

  return (
    <div className="ErrorBox" id={elementId}>
      <div className="ErrorBoxHeaderArea">
        <div>
          <ErrorIcon />
        </div>
        <h3>{title}</h3>
      </div>
      <div className="ErrorBoxMessagesArea">
        {messages.length === 1 ? (
          <p className="body2">{messages[0]}</p>
        ) : (
          <ul>
            {messages.map((message, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`${index}_${message}`} className="body2">
                {message}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
