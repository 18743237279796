import { Modal } from "../../../../widget";
import { MergeProjectsModalForm } from "./form";
import { useMergeProjectsModal } from "./useMergeProjectsModal";

interface MergeProjectsModalProps {
  showMergeProjectsModal: boolean;
  closeModal: () => void;
}

export const MergeProjectsModal = ({ showMergeProjectsModal, closeModal }: MergeProjectsModalProps): JSX.Element => {
  const { setModalTitle, modalTitle } = useMergeProjectsModal();

  return (
    <Modal show={showMergeProjectsModal} title={modalTitle} onClose={closeModal}>
      <MergeProjectsModalForm closeModal={closeModal} setModalTitle={setModalTitle} />
    </Modal>
  );
};
