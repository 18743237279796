/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const formatFieldName = (value: string, auditDataChange?: string): string => {
  let node;
  // Handle special case for standardSpecificData, otherwise replace "." with " "
  if (auditDataChange === "Project standard specific data updated") {
    node = value.split(".").at(-1); // Get last element from json path (eg - for standardSpecificData)
  } else {
    node = value.replace(".", " ").replace("Uuid", "");
  }

  return (
    node!.charAt(0).toUpperCase() +
    node!
      .slice(1)
      .replace(/([A-Z][a-z]+)/g, " $1")
      .toLowerCase()
  );
};
