import "./PublicCodeComparisonTemplate.css";

import { useNavigate } from "react-router-dom";

import { StandardConstants } from "../../constants";
import { getPublicCodeDetailsRoute, getPublicProjectTypesRoute } from "../../utils/routes";
import { Column, Divider, Row, TextLink } from "../../widget";

export const PublicCodeComparisonTemplate = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="PublicCodeComparisonTemplate">
      <Row spacingV="m">
        <Column span={12}>
          <h1>Codes and Standards</h1>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <p className="body1">
            Codes and standards provide the framework for measuring and certifying the volume of carbon emission
            reductions of schemes. Schemes typically have to meet certain benchmarks and protocols outlined in detailed
            policies by the code or standard.
          </p>
          <p className="body1">
            There are a number of codes and standards currently running or being set up in the UK. Kana is working
            closely with these organisations as they grow to list their projects on the directory. Choose a code below
            to learn more.
          </p>
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <div className="CodesTableWrapper">
            <table>
              <tbody>
                <tr>
                  <th>
                    <h4>Listed on Kana</h4>
                  </th>
                  <td>
                    {/* TODO: Convert all following uuids to StandardConstants found in v11-project-types */}
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicCodeDetailsRoute(StandardConstants.WOODLAND_CARBON_CODE_UUID))}
                    >
                      Woodland Carbon Code
                    </button>
                  </td>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicCodeDetailsRoute(StandardConstants.PEATLAND_CODE_UUID))}
                    >
                      Peatland Code
                    </button>
                  </td>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicCodeDetailsRoute(StandardConstants.WILDER_CARBON_UUID))}
                    >
                      Wilder Carbon
                    </button>
                  </td>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicCodeDetailsRoute(StandardConstants.PURO_EARTH_UUID))}
                    >
                      Puro.earth
                    </button>
                  </td>
                </tr>
                <tr>
                  <th>
                    <h4>Projects not yet listed on Kana</h4>
                  </th>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicCodeDetailsRoute(StandardConstants.UK_CARBON_CODE_OF_CONDUCT_CODE_UUID))
                      }
                    >
                      UK Carbon Code of Conduct
                    </button>
                  </td>
                  <td>Saltmarsh Code</td>
                  <td>Seagrass Code</td>
                  <td>Bio Energy Code</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <h2>How we categorise codes and standards</h2>
        </Column>
      </Row>
      <div className="CodeCategoryContainer">
        <div className="CodeCategory CodeCategoryBrown">
          <p className="body1">Brown</p>
          <div>
            <p className="body1">
              Codes and standards which manage projects focused below the ground, e.g., peatland or soil
            </p>
          </div>
        </div>
        <div className="CodeCategory CodeCategoryGreen">
          <p className="body1">Green</p>
          <div>
            <p className="body1">
              Codes and standards which manage projects focused above the ground, e.g., woodland or hedgerow
            </p>
          </div>
        </div>
        <div className="CodeCategory CodeCategoryBlue">
          <p className="body1">Blue</p>
          <div>
            <p className="body1">
              Codes and standards which manage projects focused on the coast or bodies of water, e.g., saltmarsh or
              floodplain
            </p>
          </div>
        </div>
        <div className="CodeCategory CodeCategoryGrey">
          <p className="body1">Grey</p>
          <div>
            <p className="body1">
              Codes and standards which manage projects focused on the manufacture of products designed to capture
              carbon; e.g., biochar or enhanced weathering
            </p>
          </div>
        </div>
        <div className="CodeCategory CodeCategoryYellow">
          <p className="body1">Mixed</p>
          <div>
            <p className="body1">Codes and standards which manage projects across multiple project type categories</p>
          </div>
        </div>
      </div>
      <Row spacingV="2xl">
        <Column span={12}>
          <p className="body1">
            You can learn more about the different project types that fall under each of these categories{" "}
            <TextLink onAction={() => navigate(getPublicProjectTypesRoute())}>here</TextLink>
          </p>
        </Column>
      </Row>
    </div>
  );
};
