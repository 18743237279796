import { Navigate, Route, Routes } from "react-router-dom";

import {
  PublicBadgeDetailsParams,
  PublicCodeDetailsParams,
  PublicDeveloperDetailsParams,
  PublicProjectParams,
  PublicProjectTypeParams,
  PublicVerifierDetailsParams,
} from "../models";
import { PublicPage } from "../route/public";
import { PublicBadgeDetailsPage } from "../route/public/badge";
import { PublicCodeDetailsPage } from "../route/public/code";
import { PublicCodeComparisonPage } from "../route/public/code-comparison";
import { PublicDeveloperDetailsPage } from "../route/public/developer";
import { PublicError404, PublicError500 } from "../route/public/errors";
import {
  PublicProjectDetailsTab,
  PublicProjectDocumentsTab,
  PublicProjectIssuancesTab,
  PublicProjectOverviewTab,
  PublicProjectPageContainer,
} from "../route/public/project";
import { PublicProjectTypePage } from "../route/public/project-type";
import { PublicProjectTypesPage } from "../route/public/project-types";
import { PublicProjectsPage } from "../route/public/projects";
import { PublicVerifierDetailsPage } from "../route/public/verifier";

export const PublicRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<PublicPage />}>
        <Route path="404/page-not-found" element={<PublicError404 />} />
        <Route path="500/application-error" element={<PublicError500 />} />
        <Route path="projects" element={<PublicProjectsPage />} />
        <Route path={`project/:${PublicProjectParams.uuid}/*`} element={<PublicProjectPageContainer />}>
          <Route path="overview" element={<PublicProjectOverviewTab />} />
          <Route path="details" element={<PublicProjectDetailsTab />} />
          <Route path="documents" element={<PublicProjectDocumentsTab />} />
          <Route path="issuances" element={<PublicProjectIssuancesTab />} />
          <Route path="" element={<Navigate to="overview" replace />} />
        </Route>
        <Route path={`badge/:${PublicBadgeDetailsParams.badgeUuid}/details`} element={<PublicBadgeDetailsPage />} />
        <Route path={`code/:${PublicCodeDetailsParams.standardUuid}/details`} element={<PublicCodeDetailsPage />} />
        <Route
          path={`developer/:${PublicDeveloperDetailsParams.developerUuid}/details`}
          element={<PublicDeveloperDetailsPage />}
        />
        <Route
          path={`verifier/:${PublicVerifierDetailsParams.verifierUuid}/details`}
          element={<PublicVerifierDetailsPage />}
        />
        <Route path={`project-type/:${PublicProjectTypeParams.projectTypeUuid}`} element={<PublicProjectTypePage />} />
        <Route path="project-types" element={<PublicProjectTypesPage />} />
        <Route path="code-comparison" element={<PublicCodeComparisonPage />} />
        <Route path="*" element={<PublicError404 />} />
      </Route>
    </Routes>
  );
};
