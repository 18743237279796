import { logError } from "../../../service/error";
import { stringToNumber } from "../../../utils";
import { PillVariant, PillVariantMap } from "./Pill";

interface usePillData {
  computedVariant: string;
}

const allVariantsList: PillVariant[] = [
  "blue",
  "green",
  "coral",
  "cyan",
  "pink",
  "purple",
  "red",
  "yellow",
  "grey",
  "dark-green",
];

export const usePill = (label: string, variant?: PillVariant, variantMap?: PillVariantMap): usePillData => {
  if (variant) {
    return { computedVariant: variant };
  }

  if (variantMap) {
    if (variantMap[label]) {
      return { computedVariant: variantMap[label] };
    }
    logError({
      error: `Could not find the pill variant for the label: ${label} in the provided variantMap. Returning a default one instead!`,
    });
  }

  const variantNr = stringToNumber(label) % allVariantsList.length;

  return {
    computedVariant: allVariantsList[variantNr],
  };
};
