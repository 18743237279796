import "./DeveloperSingleCardTemplate.css";

import { ReactNode } from "react";

import { ArrowFullLeftDarkIcon } from "../../assets";
import { NavigationActiveElement } from "../../models";
import { Button, Column, FullPageCard, LeftNavigation, Row, UserHeader } from "../../widget";

interface DeveloperSingleCardTemplateProps {
  pageTitle: string | undefined;
  children: ReactNode;
  activeElement?: NavigationActiveElement;
  handleBackClick?: () => void;
  backButtonText?: string;
}

export const DeveloperSingleCardTemplate = ({
  pageTitle,
  children,
  activeElement,
  handleBackClick,
  backButtonText = "Back",
}: DeveloperSingleCardTemplateProps): JSX.Element => {
  return (
    <div className="DeveloperSingleCardTemplate">
      <div className="DeveloperSingleCardTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="DeveloperSingleCardTemplateContainer">
        {handleBackClick ? (
          <div className="HeaderContainer">
            <Button
              text={backButtonText}
              variant="secondary"
              icon={<ArrowFullLeftDarkIcon />}
              iconPosition="left"
              onClick={handleBackClick}
            />
            <UserHeader />
          </div>
        ) : (
          <UserHeader />
        )}
        <Row spacingV="ll">
          <Column span={12}>
            <h1>{pageTitle}</h1>
          </Column>
        </Row>
        <FullPageCard>{children}</FullPageCard>
      </div>
    </div>
  );
};
