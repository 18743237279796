import { useMemo } from "react";

import { useAuth } from "../../../../../useAuth";
import { MessageThread } from "../../models/Message";

export type UseDiscussionThreadProps = {
  thread?: MessageThread;
};

export type UseDiscussionThreadReturnData = {
  userUuid: string;
  firstMessage?: MessageThread["messages"][0];
  replies: MessageThread["messages"];
};

export const useDiscussionThread = ({ thread }: UseDiscussionThreadProps): UseDiscussionThreadReturnData => {
  const firstMessage = thread?.messages[0];
  const replies = thread?.messages.slice(1) ?? [];

  const { user } = useAuth();

  const userUuid = useMemo(() => user?.userUuid ?? "", [user]);

  return {
    userUuid,
    firstMessage,
    replies,
  };
};
