import { DeveloperSingleCardTemplate } from "../../../../templates";
import { BarChart, Column, DataGrid, Row, Select } from "../../../../widget";
import { useMarketingStats } from "./useMarketingStats";

export const MarketingStats = (): JSX.Element => {
  const {
    metrics,
    currentMetric,
    periodicities,
    currentPeriodicity,
    chartData,
    columns,
    gridData,
    isLoading,
    setCurrentMetric,
    setCurrentPeriodicity,
  } = useMarketingStats();

  return (
    <DeveloperSingleCardTemplate pageTitle="MARKETING STATS" activeElement="Stats">
      <Row spacingV="4xl">
        <Column span={4}>
          <Select label="Viewing" data={metrics} initialKey={currentMetric} onChange={setCurrentMetric} />
        </Column>
        <Column span={4}>
          <Select
            label="Time period"
            data={periodicities}
            initialKey={currentPeriodicity}
            onChange={setCurrentPeriodicity}
          />
        </Column>
      </Row>
      <Row spacingV="4xl">
        <Column span={12}>
          <BarChart data={chartData} />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <DataGrid
            clientSide
            columns={columns}
            data={gridData}
            dataIsLoading={isLoading}
            noDataMessage="No marketing statistics to show"
          />
        </Column>
      </Row>
    </DeveloperSingleCardTemplate>
  );
};
