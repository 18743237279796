import { NetworkError } from "@apollo/client/errors";

import { logError } from "../../../service/error";

export const logErrorWithDetail = (message: string, networkError: NetworkError): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errors = networkError?.result.errors;
  let path: string | null = null;
  if (errors?.length > 0 && errors[0].path?.length > 0) [path] = errors[0].path;
  logError({
    error: `${message}\nPath: ${path}\nMessage: ${networkError?.message}\nName: ${networkError?.name}\nStack: ${networkError?.stack}`,
  });
};
