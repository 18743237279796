import "./GroupProjects.css";

import { Link } from "react-router-dom";

import { getPublicProjectsRoute } from "../../../../utils/routes";
import { Column, Row } from "../../../../widget";
import { DeveloperProject } from "../../usePublicProjectTemplate";
import { GroupProject } from "./GroupProject/GroupProject";

interface GroupProjectsProps {
  groupUuid: string;
  groupName: string;
  groupCount: number;
  projects: DeveloperProject[] | undefined;
}

export const GroupProjects = ({ groupUuid, groupName, groupCount, projects }: GroupProjectsProps): JSX.Element => {
  return (
    <div className="GroupProjectsContainer">
      <Row spacingV="m">
        <Column span={12}>
          <h3>Group</h3>
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <p className="body2 GroupProjectsDescription">
            {groupCount > 1
              ? `This project is in the group ${groupName} alongside the following ${groupCount} other projects:`
              : `This project is in the group ${groupName} alongside the following project:`}
          </p>
        </Column>
      </Row>
      {projects && projects.length ? (
        <div>
          <Row spacingV="ml">
            <Column span={12}>
              <div className="GroupProjects">
                {projects.map((p) => (
                  <GroupProject key={p.uuid} project={p} />
                ))}
              </div>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <Link
                to={{
                  pathname: getPublicProjectsRoute(),
                  search: `?group=${groupUuid}`,
                }}
              >
                View all projects in group
              </Link>
            </Column>
          </Row>
        </div>
      ) : undefined}
    </div>
  );
};
