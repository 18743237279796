import { useCallback, useEffect, useState } from "react";

import { SelectData } from "../../../../../models";
import { lookUp } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getUserSettingsRoute } from "../../../../../utils/routes";

interface UseSendInvitationReturnData {
  roles: SelectData | undefined;
  rolesInfo: string[];
  userSettingsRoute: string;
}

export const useSendInvitation = (): UseSendInvitationReturnData => {
  const [roles, setRoles] = useState<SelectData | undefined>(undefined);

  const { currentUserType } = useAuth();

  const fetchRoles = useCallback(async () => {
    const res = await lookUp({ type: "Role" });
    if (res.status === Status.Success) {
      setRoles(
        res.data?.map((role) => {
          return {
            key: role.key,
            value: role.valueString,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchRoles().then((r) => r);
  }, [fetchRoles]);

  return {
    roles,
    rolesInfo: [
      "Owner: Can update all projects and organisation details. Owners can invite admin & staff members & edit all organisation users. There must always be one owner.",
      "Admin: Can update all projects and organisation details. Admin can invite staff members & edit staff users.",
      "Staff: Can update all projects but cannot update organisation details. Cannot invite other staff members",
    ],
    userSettingsRoute: getUserSettingsRoute(currentUserType),
  };
};
