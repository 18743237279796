import { PublicErrorTemplate } from "../../../templates/PublicErrorTemplate/PublicErrorTemplate";
import { PublicErrorMetaTags } from "./PublicErrorMetaTags";

export const PublicError404 = (): JSX.Element => {
  return (
    <>
      <PublicErrorMetaTags />
      <PublicErrorTemplate
        errorText="We can’t seem to find the page you were looking for. It may no longer exist, have changed location or be temporarily unavailable."
        errorCode="404"
      />
    </>
  );
};
