import "./HorizontalProjectCard.css";

import { useNavigate } from "react-router-dom";

import { getPlaceholderImage } from "../../../../../utils";
import { getPublicProjectOverviewRoute } from "../../../../../utils/routes";
import { LocationFlag } from "../../../../data";
import { DisplayHorizontalProject } from "../../models";

interface HorizontalProjectCardProps {
  project: DisplayHorizontalProject;
}

export const HorizontalProjectCard = ({ project }: HorizontalProjectCardProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div
      className="HorizontalProjectCard"
      onClick={() => {
        navigate(getPublicProjectOverviewRoute(project.uuid));
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      aria-hidden="true"
    >
      <div
        style={{
          backgroundImage: project.imageSource
            ? `url(${project.imageSource})`
            : `url(${getPlaceholderImage(project.codeName)})`,
        }}
        className="HorizontalProjectCardImage"
      />
      <div className="HorizontalProjectCardHeader">
        <h2>{project.displayName}</h2>
        {project.addressCountryCode && <LocationFlag addressCountryCode={project.addressCountryCode} />}
      </div>
      <div className="HorizontalProjectCardDeveloper">
        {project.developerSmallLogoFileUrl && <img src={project.developerSmallLogoFileUrl} alt="DeveloperLogo" />}
        <p className="body2">{project.developerDisplayName}</p>
      </div>
    </div>
  );
};
