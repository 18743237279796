/* eslint-disable @typescript-eslint/no-empty-function */
import "./HeaderNavigation.css";

import { Link, useNavigate } from "react-router-dom";

import { KanaLightLogo } from "../../../assets";
import { HeaderActiveElement } from "../../../models";
import { getLogonRoute } from "../../../utils/routes";
import { Button } from "../..";

const HeaderElements = [
  {
    route: "/p/projects",
    label: "Project list",
  },
  {
    route: "/p/code-comparison",
    label: "Codes and Standards",
  },
  {
    route: "/p/project-types",
    label: "Project types",
  },
];

interface HeaderNavigationProps {
  activeElement?: HeaderActiveElement;
}

export const HeaderNavigation = ({ activeElement }: HeaderNavigationProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <nav className="HeaderNavigation">
      <div className="HeaderContainer">
        <div className="HeaderLeftContent">
          <a href="https://www.kana.earth">
            <KanaLightLogo className="NavigationLogo" />
          </a>
          {HeaderElements.map((element) => (
            <Link
              key={element.label}
              to={element.route}
              className={`body2 NavigationElement ${activeElement === element.label && "NavigationElement_active"}`}
            >
              {element.label}
            </Link>
          ))}
        </div>
        <div className="HeaderRightContent">
          <Button
            variant="reversedPrimary"
            onClick={() => {
              navigate(getLogonRoute());
            }}
            text="Developer login"
          />
        </div>
      </div>
    </nav>
  );
};
