/* eslint-disable @typescript-eslint/no-explicit-any */
import "./FlyoutPanel.css";

import { ReactNode } from "react";

import { ClosePanelArrowRightBlueIcon } from "../../../assets";
import { Button } from "../../forms";
import { Backdrop } from "../Backdrop";
import { useFlyoutPanel } from "./useFlyoutPanel";

interface FlyoutPanelProps {
  /**
   * @param show should be part of a state variable (eg. [show, setShow]) set in the parent page class
   */
  show: boolean;
  onClose: () => void;
  size: "Small" | "Medium" | "Large";
  header: string;
  buttonsChildren: ReactNode;
  contentChildren: ReactNode;
}

export const FlyoutPanel = ({
  show,
  onClose,
  size,
  header,
  buttonsChildren,
  contentChildren,
}: FlyoutPanelProps): JSX.Element | null => {
  const { flyoutPanelRefCallBack, onFlyoutPanelKeyDownHandle, showTransition } = useFlyoutPanel({
    show,
    onClose,
  });

  return show ? (
    <>
      <div
        ref={flyoutPanelRefCallBack}
        className={`FlyoutPanel ${size} ${showTransition ? "Open" : ""}`}
        role="button"
        onKeyDown={onFlyoutPanelKeyDownHandle}
        tabIndex={0}
      >
        <div className="ClosePanelButtonWrapper">
          <Button text="Close panel" icon={<ClosePanelArrowRightBlueIcon />} variant="secondary" onClick={onClose} />
        </div>
        <div className="HeaderArea">
          <h1>{header}</h1>
          <div className="ButtonsArea">{buttonsChildren}</div>
        </div>
        <div className="ContentArea">{contentChildren}</div>
      </div>
      <Backdrop show={show} onClick={onClose} />
    </>
  ) : null;
};
