import { useCallback, useEffect, useState } from "react";

import { CodeCharacteristics, FileType } from "../../../../../../models";
import { getStandardDetails } from "../../../../../../service/publicquery";
import { Status } from "../../../../../../service/Shared";
import { isCodePrincipleMet } from "../../../../../../utils";

interface UseAboutTheCodeSectionReturnData {
  dataIsLoading: boolean;
  codeDetailsData: ProjectAboutTheCodeData | undefined;
}
export interface ProjectAboutTheCodeData {
  displayName: string | null;
  projectType: string | null;
  subtitle: string | null;
  logo: string | null;
  codeCharacteristics: CodeCharacteristics;
  ukEnvironmentalReporting: boolean;
}

export const useAboutTheCodeSection = (organisationUuid: string | undefined): UseAboutTheCodeSectionReturnData => {
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [codeDetailsData, setCodeDetailsData] = useState<ProjectAboutTheCodeData | undefined>(undefined);

  const fetchData = useCallback(async (): Promise<void> => {
    if (organisationUuid) {
      const res = await getStandardDetails({
        standardUuid: organisationUuid,
      });

      if (res.status === Status.Success && res.data) {
        const logo = res.data.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null;

        const characteristicsData = res.data.listing?.content?.characteristics;
        const formattedCharacteristics: CodeCharacteristics = {
          programGovernance: {
            codeUrl: characteristicsData?.programGovernance?.codeUrl,
            codePositions: characteristicsData?.programGovernance?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.programGovernance?.codePositions),
          },
          noDoubleCounting: {
            codeUrl: characteristicsData?.noDoubleCounting?.codeUrl,
            codePositions: characteristicsData?.noDoubleCounting?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.noDoubleCounting?.codePositions),
          },
          registry: {
            codeUrl: characteristicsData?.registry?.codeUrl,
            codePositions: characteristicsData?.registry?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.registry?.codePositions),
          },
          mitigationActivityInformation: {
            codeUrl: characteristicsData?.mitigationActivityInformation?.codeUrl,
            codePositions: characteristicsData?.mitigationActivityInformation?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.mitigationActivityInformation?.codePositions),
          },
          additionality: {
            codeUrl: characteristicsData?.additionality?.codeUrl,
            codePositions: characteristicsData?.additionality?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.additionality?.codePositions),
          },
          robustQuantificationOfEmissionsReductionsAndRemovals: {
            codeUrl: characteristicsData?.robustQuantificationOfEmissionsReductionsAndRemovals?.codeUrl,
            codePositions: characteristicsData?.robustQuantificationOfEmissionsReductionsAndRemovals?.codePositions,
            met: isCodePrincipleMet(
              characteristicsData?.robustQuantificationOfEmissionsReductionsAndRemovals?.codePositions
            ),
          },
          permanence: {
            codeUrl: characteristicsData?.permanence?.codeUrl,
            codePositions: characteristicsData?.permanence?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.permanence?.codePositions),
          },
          robustIndependentThirdPartyValidationAndVerification: {
            codeUrl: characteristicsData?.robustIndependentThirdPartyValidationAndVerification?.codeUrl,
            codePositions: characteristicsData?.robustIndependentThirdPartyValidationAndVerification?.codePositions,
            met: isCodePrincipleMet(
              characteristicsData?.robustIndependentThirdPartyValidationAndVerification?.codePositions
            ),
          },
          transitionToNetZero: {
            codeUrl: characteristicsData?.transitionToNetZero?.codeUrl,
            codePositions: characteristicsData?.transitionToNetZero?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.transitionToNetZero?.codePositions),
          },
          sustainableDevelopmentImpactsAndSafeguards: {
            codeUrl: characteristicsData?.sustainableDevelopmentImpactsAndSafeguards?.codeUrl,
            codePositions: characteristicsData?.sustainableDevelopmentImpactsAndSafeguards?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.sustainableDevelopmentImpactsAndSafeguards?.codePositions),
          },
        };

        setCodeDetailsData({
          displayName: res.data.displayName,
          projectType: res.data?.projectTypes[0].displayName.toLocaleLowerCase(),
          subtitle: res.data?.listing?.content?.atAGlance?.content,
          logo,
          codeCharacteristics: formattedCharacteristics,
          ukEnvironmentalReporting: res.data.listing?.content?.reportingStandards?.ukEnvironmentalReporting || false,
        });
      }
    }
  }, [organisationUuid]);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData, organisationUuid]);

  return {
    dataIsLoading,
    codeDetailsData,
  };
};
