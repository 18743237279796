import { useEffect, useState } from "react";

import { GetProjectDetailsResponse, getStandardSpecificSchemaDetails } from "../../../../service/publicquery";
import { Status } from "../../../../service/Shared";
import { StandardSpecificDefinition } from "../../../shared/projects/project/overview/components/StandardSpecificSection/types";
import { usePublicContext } from "../../usePublicContext";

interface UseDetailsReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  standardSpecificDefinition: StandardSpecificDefinition | undefined;
}

export const useDetails = (): UseDetailsReturnData => {
  const { projectDetails } = usePublicContext();

  const [standardSpecificDefinition, setStandardSpecificDefinition] = useState<StandardSpecificDefinition>();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (projectDetails?.standardSpecificSchemaUuid) {
        await getStandardSpecificSchemaDetails({
          standardSpecificSchemaUuid: projectDetails.standardSpecificSchemaUuid,
        }).then((response) => {
          if (response.status === Status.Success && response.data) {
            setStandardSpecificDefinition(response.data.definition);
          }
        });
      }
    };

    fetchData();
  }, [projectDetails?.standardSpecificSchemaUuid]);

  return {
    projectDetails,
    standardSpecificDefinition,
  };
};
