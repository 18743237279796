import "./DeleteProjectModal.css";

import { ActionButtons, Column, ErrorBox, Modal, Row } from "../../../../widget";
import { useDeleteProjectModal } from "./useDeleteProjectModal";

interface DeleteProjectModalProps {
  showDeleteProjectModal: boolean;
  closeModal: () => void;
}

export const DeleteProjectModal = ({ showDeleteProjectModal, closeModal }: DeleteProjectModalProps): JSX.Element => {
  const { errors, isHandleSubmitLoading, resetForm, handleSubmit } = useDeleteProjectModal(closeModal);

  return (
    <Modal
      show={showDeleteProjectModal}
      title="Delete project"
      onClose={() => {
        resetForm();
        closeModal();
      }}
    >
      <form onSubmit={(e) => handleSubmit(e)} className="DeleteProjectModalContent">
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <Row spacingV="2xl" justify="center">
          <Column span={10}>
            <p className="body2">
              If you delete this project, it will no longer be visible in the public directory and you will lose any of
              the details you entered for the project.
            </p>
            <p className="body2">Are you sure you want to delete this project?</p>
          </Column>
        </Row>
        <Row justify="center">
          <Column span={6}>
            <ActionButtons
              onCancel={closeModal}
              fullWidth
              submitButtonLabel="Delete Project"
              cancelButtonLabel="Cancel"
              isLoading={isHandleSubmitLoading}
            />
          </Column>
        </Row>
      </form>
    </Modal>
  );
};
