import { useCallback, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate } from "react-router-dom";

import { ArrowheadRightDarkIcon } from "../../../../../assets";
import { OrganisationTypeConstants } from "../../../../../constants";
import { CursorChangeProps, NavigationSearchParams, ResultType } from "../../../../../models";
import {
  SearchProjectOrganisationInvitations,
  SearchProjectOrganisationInvitationsResponse,
} from "../../../../../service/query";
import { ResultData } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { flattenObject } from "../../../../../utils";
import { getProjectAccessDetailsRoute, getProjectDetailsByUuid } from "../../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
} from "../../../../../widget";
import { IconCellFormatterData } from "../../../../../widget/data/DataGrid/models";

interface UseAccessTabReturnData {
  onNewInvitation: () => void;
  sentColumns: DataGridColumnDefinition[];
  defaultSortingCriteriaSent: SortColumn[];
  sentDataIsLoading: boolean;
  sentOnChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useAccessTabSentData = (): UseAccessTabReturnData => {
  const navigate = useNavigate();

  const { currentOrganisationUuid } = useAuth();

  const [sentDataIsLoading, setSentDataIsLoading] = useState(true);

  const onNewInvitation = (): void => navigate("invite");

  const defaultSortingCriteriaSent: SortColumn[] = [{ columnKey: "sentDate", direction: "DESC" }];

  const formatSentData = useCallback(
    (responseData: SearchProjectOrganisationInvitationsResponse | undefined): ResultData[] => {
      return (
        responseData?.results.map((d) => {
          const result = flattenObject({ ...d });

          result.targetOrganisationDisplayName = d.targetOrganisation.displayName;
          result.targetOrganisationUuid = d.targetOrganisation.uuid;
          result.projectUuid = d.project.uuid;
          result.projectDisplayName = d.project.displayName;
          result.projectLink = {
            to: getProjectDetailsByUuid(result.projectUuid, OrganisationTypeConstants.DEVELOPER),
            text: result.projectDisplayName,
          } as DataGridLinkCellFormatterData;
          result.developerLink = {
            action: () =>
              navigate(
                `${getProjectAccessDetailsRoute(d.uuid)}?${NavigationSearchParams.settingsProjectAccessTab}=true`
              ),
            icon: <ArrowheadRightDarkIcon width={12} height={12} />,
          } as IconCellFormatterData;

          return result;
        }) || []
      );
    },
    []
  );

  const sentColumns: DataGridColumnDefinition[] = [
    { key: "targetOrganisation.displayName", name: "Target developer", dataType: "string" },
    {
      key: "projectLink",
      name: "Project",
      dataType: "string",
      formatter: "link",
    },
    { key: "targetRole", name: "Access level", dataType: "string" },
    {
      key: "sentDate",
      name: "Date sent",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
    {
      key: "sentByUser.fullName",
      name: "Granted by",
      dataType: "string",
      filterable: true,
      sortable: true,
    },
    {
      name: "Status",
      key: "calculatedStatus",
      dataType: "string",
      formatter: "projectAccessStatusPill",
      alignment: "center",
      minWidth: 200,
      filterable: false,
      sortable: false,
    },
    {
      key: "developerLink",
      name: "More details",
      dataType: "string",
      formatter: "icon",
      filterable: false,
      sortable: false,
      alignment: "center",
    },
  ];

  const sentOnChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (filterCriteria.projectLink) {
      filterCriteria.project = {
        displayName: filterCriteria.projectLink,
      };
      filterCriteria.projectLink = undefined;
    }

    filterCriteria.targetOrganisation = {
      ...filterCriteria.targetOrganisation,
      uuid: {
        operator: "neq",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: currentOrganisationUuid!,
      },
    };

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await SearchProjectOrganisationInvitations({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || "",
        afterCursor: paging.afterCursor || "",
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key !== "projectLink" ? s.key : "project.displayName",
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    })
      .then((response) => {
        data = {
          resultData: formatSentData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setSentDataIsLoading(false);
      });

    return data;
  };

  return {
    onNewInvitation,
    sentColumns,
    defaultSortingCriteriaSent,
    sentDataIsLoading,
    sentOnChange,
  };
};
