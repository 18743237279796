import "./PublishProjectModal.css";

import { ActionButtons, Column, ErrorBox, Modal, Row } from "../../../../widget";
import { usePublishProjectModal } from "./usePublishProjectModal";

interface PublishProjectModalProps {
  showPublishProjectModal: boolean;
  closeModal: () => void;
}

export const PublishProjectModal = ({ showPublishProjectModal, closeModal }: PublishProjectModalProps): JSX.Element => {
  const { errors, isHandleSubmitLoading, resetForm, handleSubmit } = usePublishProjectModal(closeModal);

  return (
    <Modal
      show={showPublishProjectModal}
      title="Publish project"
      onClose={() => {
        resetForm();
        closeModal();
      }}
    >
      <form onSubmit={(e) => handleSubmit(e)} className="PublishProjectModalContent">
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <Row spacingV="2xl" justify="center">
          <Column span={10}>
            <p className="body2">If you publish this project, it will be visible in the public directory.</p>
            <p className="body2">Are you sure you want to publish this project?</p>
          </Column>
        </Row>
        <Row justify="center">
          <Column span={6}>
            <ActionButtons
              onCancel={closeModal}
              fullWidth
              submitButtonLabel="Publish"
              isLoading={isHandleSubmitLoading}
            />
          </Column>
        </Row>
      </form>
    </Modal>
  );
};
