// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepObjectSearch = (obj: any, searchKey: string): any[] => {
  let resArr: string[] = [];
  if (Array.isArray(obj)) {
    obj.forEach((item) => {
      resArr = resArr.concat(deepObjectSearch(item, searchKey));
    });
  } else if (typeof obj === "object" && obj !== null && obj !== undefined) {
    Object.entries(obj).forEach(([key, value]) => {
      if (key === "discussionThreads") {
        resArr.push(value as string);
      } else {
        resArr = resArr.concat(deepObjectSearch(value, searchKey));
      }
    });
  }

  return resArr;
};
