import "./QuickActionsMenu.css";

import { EllipseGroupBlueIcon } from "../../../assets";

interface QuickActionsMenuProps {
  label?: string;
  onClick: () => void;
}

export const QuickActionsMenu = ({ label = "Quick actions", onClick }: QuickActionsMenuProps): JSX.Element => {
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(e) => (e.key === "Enter" ? onClick() : null)}
      className="QuickActionsMenu"
    >
      <span>{label}</span>
      <div className="Divider" />
      <EllipseGroupBlueIcon />
    </div>
  );
};
