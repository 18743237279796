import { completeDraft } from "../../../../service/activity";
import { Status } from "../../../../service/Shared";

export const isSuccessfulCompleteActivityDraft = async (
  activityHistoryUuid: string,
  activityDataRowVersion: number
): Promise<boolean> => {
  if (activityHistoryUuid && activityDataRowVersion) {
    const completeActivityRes = await completeDraft({
      uuid: activityHistoryUuid,
      rowVersion: activityDataRowVersion,
    });

    return completeActivityRes.status === Status.Success;
  }

  return false;
};
