import { EmbeddingForm } from "./form/EmbeddingForm";
import { useEmbedding } from "./useEmbedding";

const Embedding = (): JSX.Element => {
  const { dataIsLoading, defaultValues } = useEmbedding();

  return <div>{!dataIsLoading && defaultValues && <EmbeddingForm defaultValues={defaultValues} />}</div>;
};

export default Embedding;
