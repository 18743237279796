import { Dispatch, FormEvent, SetStateAction, useCallback, useContext, useEffect, useState } from "react";

import { BadgeTypeConstants, ProjectPermissionConstants, ProjectStatusConstants } from "../../../../../constants";
import { BadgeData, BasicDetailsData, LocationData, SelectData } from "../../../../../models";
import { updateProjectDetails, UpdateProjectDetailsRequest } from "../../../../../service/project";
import {
  GetProjectDetailsResponse,
  getStandardSpecificSchemaDetails,
  searchVerifiers,
} from "../../../../../service/query";
import { ServiceError, Status } from "../../../../../service/Shared";
import { isFalsyExceptZero, useIsLoadingWrapper } from "../../../../../utils";
import { dataGridMapFilterCriteria, Toast } from "../../../../../widget";
import { ProjectContext } from "../ProjectContext";
import { StandardSpecificDefinition } from "./components/StandardSpecificSection/types";

interface UseOverviewReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  currentStatus: string | undefined;
  errors: ServiceError[];
  isReadOnly: boolean;
  isEditable: boolean;
  basicDetailsData: BasicDetailsData;
  setBasicDetailsData: Dispatch<SetStateAction<BasicDetailsData>>;
  locationData: LocationData;
  setLocationData: Dispatch<SetStateAction<LocationData>>;
  validatorData: SelectData;
  badgeData: BadgeData[];
  certificateData: BadgeData[];
  standardSpecificDefinition: StandardSpecificDefinition | undefined;
  onSubmit(e: FormEvent<HTMLFormElement> | undefined): Promise<void>;
  onCancel(): void;
  showVVBDropdown: boolean;
  setShowVVBDropdown: Dispatch<SetStateAction<boolean>>;
  showUpdateGroupVVBModal: boolean;
  setShowUpdateGroupVVBModal: Dispatch<SetStateAction<boolean>>;
  isOnSubmitLoading: boolean;
}

export const useOverview = (): UseOverviewReturnData => {
  const { projectDetails, setProjectDetails, hasProjectPermission, shouldRefreshProjectDetails } =
    useContext(ProjectContext);
  const [isOnSubmitLoading, setIsOnSubmitLoading] = useState(false);
  const [standardSpecificDefinition, setStandardSpecificDefinition] = useState<
    StandardSpecificDefinition | undefined
  >();
  const [basicDetailsData, setBasicDetailsData] = useState<BasicDetailsData>({
    displayName: projectDetails?.displayName,
    name: projectDetails?.name,
    registryId: projectDetails?.projectReference?.externalReference,
    totalArea: projectDetails?.areaNetHa?.toString(),
    validatorUuid: projectDetails?.validator?.uuid,
    projectType: projectDetails?.projectType.displayName,
    projectStatus: projectDetails?.status,
    projectTypeUuid: projectDetails?.projectType.uuid,
  });

  const [locationData, setLocationData] = useState<LocationData>({
    addressCountryCode: projectDetails?.addressCountryCode,
    gridReference: projectDetails?.locationGridReference,
    latitude: projectDetails?.locationCoordinates?.split(",")[0] || null,
    longitude: projectDetails?.locationCoordinates?.split(",")[1] || null,
  });

  const [submittedValues, setSubmittedValues] = useState<{
    basicDetailsData: BasicDetailsData;
    locationData: LocationData;
  }>({
    basicDetailsData: {
      displayName: projectDetails?.displayName,
      name: projectDetails?.name,
      registryId: projectDetails?.projectReference?.externalReference,
      totalArea: projectDetails?.areaNetHa?.toString(),
      validatorUuid: projectDetails?.validator?.uuid,
      projectType: projectDetails?.projectType.displayName,
      projectStatus: projectDetails?.status,
      projectTypeUuid: projectDetails?.projectType.uuid,
    },
    locationData: {
      addressCountryCode: projectDetails?.addressCountryCode,
      gridReference: projectDetails?.locationGridReference,
      latitude: projectDetails?.locationCoordinates?.split(",")[0],
      longitude: projectDetails?.locationCoordinates?.split(",")[1],
    },
  });

  const [validatorData, setValidatorData] = useState<SelectData>([]);
  const [showVVBDropdown, setShowVVBDropdown] = useState(false);
  const [showUpdateGroupVVBModal, setShowUpdateGroupVVBModal] = useState(false);

  const [currentRowVersion, setCurrentRowVersion] = useState<number>(projectDetails?.rowVersion || 1);
  const [errors, setErrors] = useState<ServiceError[]>([]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (projectDetails?.standardSpecificSchemaUuid) {
        await getStandardSpecificSchemaDetails({
          standardSpecificSchemaUuid: projectDetails.standardSpecificSchemaUuid,
        }).then((response) => {
          if (response.status === Status.Success && response.data) {
            setStandardSpecificDefinition(response.data.definition);
          }
        });
      }
    };
    fetchData();
  }, [projectDetails?.standardSpecificSchemaUuid]);

  useEffect(() => {
    setBasicDetailsData({
      displayName: projectDetails?.displayName,
      name: projectDetails?.name,
      registryId: projectDetails?.projectReference?.externalReference,
      totalArea: projectDetails?.areaNetHa?.toString(),
      validatorUuid: projectDetails?.validator?.uuid,
      projectType: projectDetails?.projectType.displayName,
      projectStatus: projectDetails?.status,
      projectTypeUuid: projectDetails?.projectType.uuid,
    });
    setLocationData({
      addressCountryCode: projectDetails?.addressCountryCode,
      gridReference: projectDetails?.locationGridReference,
      latitude: projectDetails?.locationCoordinates?.split(",")[0] || null,
      longitude: projectDetails?.locationCoordinates?.split(",")[1] || null,
    });
    setCurrentRowVersion(projectDetails?.rowVersion || 1);
  }, [projectDetails]);

  const fetchData = useCallback(async () => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.projectTypes = {
      some: {
        uuid: {
          operator: "eq",
          value: basicDetailsData.projectTypeUuid,
        },
      },
    };

    await searchVerifiers({
      filter: {
        results: filterCriteria,
      },
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [],
    }).then((response) => {
      const valData =
        response.data?.results?.map((x) => {
          return { key: x.uuid, value: x.displayName };
        }) ?? [];
      setValidatorData(valData);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateProjectDetailsContext = (updatedRowVersion: number): void => {
    if (projectDetails) {
      let locationCoordinates: string | null;

      if (!locationData.latitude && !locationData.longitude) {
        locationCoordinates = null;
      } else {
        locationCoordinates = `${locationData.latitude ? locationData.latitude : ""},${
          locationData.longitude ? locationData.longitude : ""
        }`;
      }

      setProjectDetails({
        ...(projectDetails as GetProjectDetailsResponse),
        areaNetHa: isFalsyExceptZero(basicDetailsData.totalArea) ? null : Number(basicDetailsData.totalArea),
        displayName: basicDetailsData.displayName || "",
        addressCountryCode: locationData.addressCountryCode || null,
        locationCoordinates,
        locationGridReference: locationData.gridReference || null,
        validator: {
          ...projectDetails.validator,
          uuid: basicDetailsData.validatorUuid || "",
        },
        rowVersion: updatedRowVersion,
      });
    }
  };

  const onSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    if (e) e.preventDefault();
    setErrors([]);
    if (projectDetails) {
      if (
        projectDetails.validator?.uuid !== basicDetailsData.validatorUuid &&
        projectDetails.group != null &&
        showUpdateGroupVVBModal === false
      ) {
        setShowUpdateGroupVVBModal(true);
        return;
      }
      let locationCoordinates: string | null;

      if (!locationData.latitude && !locationData.longitude) {
        locationCoordinates = null;
      } else {
        locationCoordinates = `${locationData.latitude ? locationData.latitude : ""},${
          locationData.longitude ? locationData.longitude : ""
        }`;
      }

      const request: UpdateProjectDetailsRequest = {
        rowVersion: currentRowVersion,
        displayName: basicDetailsData.displayName || "",
        projectUuid: projectDetails.uuid,
        areaNetHa: isFalsyExceptZero(basicDetailsData.totalArea) ? null : Number(basicDetailsData.totalArea),
        validatorUuid: basicDetailsData.validatorUuid || null,
        addressCountryCode: locationData.addressCountryCode || null,
        locationCoordinates,
        locationGridReference: locationData.gridReference || "",
      };

      const updateRes = await updateProjectDetails(request);

      if (updateRes.status === Status.Success && updateRes.data) {
        Toast.success({ message: "Project details changed successfully" });
        setCurrentRowVersion(updateRes.data.rowVersion);
        setErrors([]);
        updateProjectDetailsContext(updateRes.data.rowVersion);
        setSubmittedValues({
          basicDetailsData: basicDetailsData as BasicDetailsData,
          locationData,
        });
        shouldRefreshProjectDetails();
        setShowVVBDropdown(false);
        setShowUpdateGroupVVBModal(false);
      }

      if (updateRes.status === Status.Error) {
        setErrors(updateRes.errors || []);
        setShowUpdateGroupVVBModal(false);
      }
    }
  }, setIsOnSubmitLoading);

  const onCancel = (): void => {
    setBasicDetailsData(submittedValues.basicDetailsData);
    setLocationData(submittedValues.locationData);
  };

  return {
    projectDetails,
    currentStatus: projectDetails?.status,
    isReadOnly: !hasProjectPermission(ProjectPermissionConstants.WRITE_PROJECT_DETAILS),
    isEditable:
      hasProjectPermission(ProjectPermissionConstants.WRITE_PROJECT_DETAILS) &&
      (projectDetails?.status === ProjectStatusConstants.DRAFT ||
        projectDetails?.status === ProjectStatusConstants.PRE_DEVELOPMENT),
    errors,
    basicDetailsData,
    setBasicDetailsData,
    locationData,
    setLocationData,
    onSubmit,
    onCancel,
    validatorData,
    badgeData: projectDetails?.badges.filter((b) => b.type === BadgeTypeConstants.BADGE_TYPE_BADGE) || [],
    certificateData: projectDetails?.badges.filter((b) => b.type === BadgeTypeConstants.BADGE_TYPE_CERTIFICATION) || [],
    standardSpecificDefinition,
    isOnSubmitLoading,
    showVVBDropdown,
    setShowVVBDropdown,
    showUpdateGroupVVBModal,
    setShowUpdateGroupVVBModal,
  };
};
