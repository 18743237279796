import { Helmet } from "react-helmet-async";

export const MetaTags = (): JSX.Element | null => {
  return (
    <Helmet>
      <title>Kana | The Home of UK Nature Carbon</title>
      <meta name="title" content="Kana | The Home of UK Nature Carbon" />
      <meta
        name="description"
        content="Browse every British nature-based carbon project, all in one place. A showcase of the UK carbon market offering transparency, integrity and quality."
      />
    </Helmet>
  );
};
