import kanaExpressionInterpreter from "../../../../../../../../lib/KanaExpressionInterpreter";
import { DatePicker, ReadonlyText, Select, TextInput } from "../../../../../../../../widget";
import { Component, DataType } from "../types";
import { convertValueToText, maybeCreateTooltip } from "../utils";

interface RenderComponentProps {
  component: Component;
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateData?: (dataPath: string, value: any, dataType: DataType) => void;
  displayOnlyReadonly: boolean;
}

export const RenderComponent = ({
  component,
  key,
  expressionContext,
  updateData,
  displayOnlyReadonly,
}: RenderComponentProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: any = kanaExpressionInterpreter(`@standardSpecific.${key}` as string, expressionContext);

  const { dataType } = component;

  if (
    (component.editableConditionExpression !== undefined &&
      !kanaExpressionInterpreter(component.editableConditionExpression, expressionContext)) ||
    displayOnlyReadonly
  ) {
    let readOnlyValue = convertValueToText(
      dataType,
      component.component === "Select" ? component.componentProperties.data[value] : value
    );

    if (readOnlyValue && component.componentProperties.suffix) {
      readOnlyValue = `${readOnlyValue} ${component.componentProperties.suffix}`;
    }

    switch (component.component) {
      // We will be rendering our own html content, so there should be no risk of xss
      case "Paragraph":
        return (
          // eslint-disable-next-line react/no-danger
          <p dangerouslySetInnerHTML={{ __html: component.componentProperties.label }} />
        );
      default:
        return (
          <ReadonlyText
            key={component.key}
            label={component.componentProperties.label}
            value={readOnlyValue || "No data available"}
            noDataAvailable={!readOnlyValue}
            tooltipHeader=""
            tooltipText={component.componentProperties.tooltip}
            allowTooltipDangerousHtml
            isExternalLink={
              component.component === "TextInput" && component.componentProperties.type === "url" && !!readOnlyValue
            }
          />
        );
    }
  }

  switch (component.component) {
    case "Chart":
      return (
        <TextInput
          key={component.key}
          name={component.componentProperties.label}
          label={component.componentProperties.label}
          type="number"
          value={value}
          onChange={(newValue) => updateData && updateData(key, newValue, dataType)}
          tooltip={maybeCreateTooltip(component.componentProperties?.tooltip)}
        />
      );
    case "TextInput":
      return (
        <TextInput
          key={component.key}
          name={component.componentProperties.label}
          label={component.componentProperties.label}
          type={component.componentProperties.type}
          value={value}
          onChange={(newValue) => updateData && updateData(key, newValue, dataType)}
          tooltip={maybeCreateTooltip(component.componentProperties?.tooltip)}
        />
      );
    case "DateInput":
      return (
        <DatePicker
          key={component.key}
          label={component.componentProperties.label}
          value={value}
          onChange={(newValue) => updateData && updateData(key, newValue, dataType)}
          tooltip={maybeCreateTooltip(component.componentProperties?.tooltip)}
        />
      );
    case "Select":
      return (
        <Select
          key={component.key}
          label={component.componentProperties.label}
          initialKey={component.componentProperties.data}
          value={component.componentProperties.data[value]}
          data={Object.keys(component.componentProperties.data).map((k) => ({
            key: k,
            value: component.componentProperties.data[k],
          }))}
          onChange={(newValue) => updateData && updateData(key, newValue, dataType)}
        />
      );
    case "Paragraph":
      return (
        // eslint-disable-next-line react/no-danger
        <p dangerouslySetInnerHTML={{ __html: component.componentProperties.label }} />
      );
    default:
      return <p key={component.key}>{component.componentProperties.label}</p>;
  }
};
