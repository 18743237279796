import React from "react";

import { GetDiscussionThreadsResponse } from "../../../../service/query";

export const findDiscussionsInChild = (
  repeaterDiscussions: GetDiscussionThreadsResponse[] | undefined,
  childKey: React.Key | null,
  index: number
): GetDiscussionThreadsResponse[] | undefined => {
  return repeaterDiscussions?.filter((d) => d.location.includes(`${childKey}[${index}]`));
};
