import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { updateStandardSpecificData } from "../../../../../../../service/project";
import { GetProjectDetailsResponse } from "../../../../../../../service/query";
import { ServiceError, Status } from "../../../../../../../service/Shared";
import { jsonReviver, useIsLoadingWrapper } from "../../../../../../../utils";
import { Toast } from "../../../../../../../widget";
import { convertToCorrectType } from "../../../../../../developer/activities";
import { ProjectContext } from "../../../ProjectContext";
import { DataType, HasKey, StandardSpecificData, StandardSpecificStepData } from "./types";
import { createExpressionContext } from "./utils";

interface useStandardSpecificSectionReturnData {
  data: StandardSpecificData;
  errors: { [key: string]: ServiceError[] };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any;
  onSave: () => void;
  onCancel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateData: (dataPath: string, value: any, dataType: DataType) => void;
  isOnSaveLoading: boolean;
}

export const useStandardSpecificSection = (): useStandardSpecificSectionReturnData => {
  const { projectDetails, setProjectDetails } = useContext(ProjectContext);
  const [data, setData] = useState<StandardSpecificData>(projectDetails?.standardSpecificData);
  const [submittedData, setSubmittedData] = useState<StandardSpecificData>(projectDetails?.standardSpecificData);
  const [errors, setErrors] = useState<{ [key: string]: ServiceError[] }>({});
  const [isOnSaveLoading, setIsOnSaveLoading] = useState(false);

  const updateData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (dataPath: string, value: any, dataType: DataType): void => {
      const keys = dataPath.split(".");
      // make a copy of the data to avoid mutating the state directly
      const newData = JSON.parse(JSON.stringify(data), jsonReviver);

      let currentStep: StandardSpecificData | StandardSpecificStepData | undefined = newData;

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (currentStep !== undefined) {
          if (i === keys.length - 1) {
            // if it's the last key, update the value
            if (!currentStep.data) {
              currentStep.data = {}; // Initialize data if it doesn't exist
            }
            currentStep.data[key] = convertToCorrectType(value, dataType);
          } else {
            // navigate to the next step
            let nextStep = (currentStep.steps || []).find((step: HasKey) => step.key === key);

            // if the step doesn't exist, create it
            if (!nextStep) {
              nextStep = {
                key,
                steps: i < keys.length - 2 ? [] : undefined,
                data: i === keys.length - 2 ? {} : undefined,
              };
              currentStep.steps = currentStep.steps || [];
              currentStep.steps.push(nextStep);
            }

            currentStep = nextStep;
          }
        }
      }

      setData(newData);
    },
    [data]
  );

  useEffect(() => {
    setData(projectDetails?.standardSpecificData);
  }, [projectDetails]);

  const processErrors = (serviceErrors?: ServiceError[]): void => {
    data.steps?.forEach((step) => {
      const stepErrors = serviceErrors?.filter((error) => error.path?.split(".")[0] === step.key) ?? [];
      if (stepErrors.length > 0) {
        setErrors((prevErrors) => ({ ...prevErrors, [step.key]: stepErrors }));
      }
    });

    const firstStepKey = data.steps?.[0].key;
    // for errors without a path, set the error on the first step without overriding existing errors
    const otherErrors = serviceErrors?.filter((error) => !error.path) ?? [];
    if (otherErrors.length > 0 && firstStepKey) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [firstStepKey]: [...(prevErrors[firstStepKey] ?? []), ...otherErrors],
      }));
    }
  };

  const onSave = useIsLoadingWrapper(async (): Promise<void> => {
    setErrors({});

    await updateStandardSpecificData({
      projectUuid: projectDetails?.uuid ?? "",
      rowVersion: projectDetails?.rowVersion ?? 0,
      standardSpecificData: data,
      standardSpecificSchemaUuid: projectDetails?.standardSpecificSchemaUuid ?? null,
    }).then((res) => {
      if (res.status === Status.Success && res.data) {
        Toast.success({ message: "Standard specific details updated successfully" });
        setSubmittedData(data);
        setProjectDetails({
          ...(projectDetails as GetProjectDetailsResponse),
          standardSpecificData: data,
          rowVersion: res.data.rowVersion,
        });
      } else if (res.status === Status.Error) {
        processErrors(res.errors);
      }
    });
  }, setIsOnSaveLoading);

  const onCancel = (): void => {
    setData(submittedData);
  };

  const refreshExpressionContext = data && projectDetails !== undefined;
  const expressionContext = useMemo(() => createExpressionContext(data, projectDetails), [refreshExpressionContext]);

  return {
    data,
    errors,
    expressionContext,
    onSave,
    onCancel,
    updateData,
    isOnSaveLoading,
  };
};
