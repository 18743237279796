export class OrganisationTypeConstants {
  public static readonly DEVELOPER = "Developer";

  public static readonly STANDARD = "Standard";

  public static readonly VERIFIER = "Verifier";

  public static readonly DEVELOPMENT_MANAGER = "DevelopmentManager";

  public static readonly ASSET_MANAGER = "AssetManager";

  public static readonly BADGE = "Badge";
}

export type UserType =
  | typeof OrganisationTypeConstants.DEVELOPER
  | typeof OrganisationTypeConstants.VERIFIER
  | typeof OrganisationTypeConstants.DEVELOPMENT_MANAGER
  | typeof OrganisationTypeConstants.ASSET_MANAGER;
