import { Dispatch, ForwardedRef, SetStateAction, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../constants";
import { Origin, OurExpertiseData, PublicVerifierDetailsPreviewParams } from "../../../../models";
import { logError } from "../../../../service/error";
import {
  getOrganisationListingPreview,
  saveOrganisationListingPreview,
  updateVerifierListing,
} from "../../../../service/organisation";
import { getVerifierDetails } from "../../../../service/query";
import { ServiceError, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../utils";
import { recursivelyRemoveEmptyStrings } from "../../../../utils/rest";
import { getPublicVerifierDetailsPreviewRoute } from "../../../../utils/routes";
import {
  FormAboutUsData,
  FormAtAGlanceData,
  FormHeroData,
  FormOurServicesData,
  UpdateListingType,
} from "../../../shared";
import {
  CodesAndStandardsData,
  VerifierFormNames,
  VerifierMarketingAssetsFormChangedAction,
  VerifierMarketingAssetsSubmitHandler,
} from "./models";
import { setDefaultValues, setOldDefaultValues } from "./utils";

interface UseMarketingAssetsReturnData {
  heroFormRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>;
  ourExpertiseFormRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>;
  codesAndStandardsFormRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>;
  atAGlanceFormRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>;
  ourServicesFormRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>;
  aboutUsFormRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>;
  dataIsLoading: boolean;
  hasUnsavedChanges: MarketingAssetsHasUnsavedChanges;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<VerifierMarketingAssetsFormChangedAction>;
  heroDefaultValues: FormHeroData | undefined;
  ourExpertiseDefaultValues: OurExpertiseData | undefined;
  codesAndStandardsDefaultValues: CodesAndStandardsData | undefined;
  atAGlanceDefaultValues: FormAtAGlanceData | undefined;
  ourServicesDefaultValues: FormOurServicesData | undefined;
  aboutUsDefaultValues: FormAboutUsData | undefined;
  heroOldDefaultValues: FormHeroData | undefined;
  ourExpertiseOldDefaultValues: OurExpertiseData | undefined;
  codesAndStandardsOldDefaultValues: CodesAndStandardsData | undefined;
  atAGlanceOldDefaultValues: FormAtAGlanceData | undefined;
  ourServicesOldDefaultValues: FormOurServicesData | undefined;
  aboutUsOldDefaultValues: FormAboutUsData | undefined;
  verifierDisplayName: string;
  isHandlePreviewLoading: boolean;
  handlePreview: () => void;
  previewMode: boolean;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
  hasPermission: (permission: string) => boolean;
  objectUuid: string;
  objectType: string;
  objectKey: string;
  updateListing: UpdateListingType;
}

interface MarketingAssetsHasUnsavedChanges {
  hero: boolean;
  ourExpertise: boolean;
  codesAndStandards: boolean;
  atAGlance: boolean;
  ourServices: boolean;
  aboutUs: boolean;
}

const reducer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prevState: any,
  action: VerifierMarketingAssetsFormChangedAction
): MarketingAssetsHasUnsavedChanges => {
  switch (action.type) {
    case "SET_HERO_FORM_CHANGED": {
      return {
        ...prevState,
        hero: action.value,
      };
    }
    case "SET_OUR_EXPERTISE_FORM_CHANGED": {
      return {
        ...prevState,
        ourExpertise: action.value,
      };
    }
    case "SET_CODES_AND_STANDARDS_FORM_CHANGED": {
      return {
        ...prevState,
        codesAndStandards: action.value,
      };
    }
    case "SET_AT_A_GLANCE_FORM_CHANGED": {
      return {
        ...prevState,
        atAGlance: action.value,
      };
    }
    case "SET_OUR_SERVICES_FORM_CHANGED": {
      return {
        ...prevState,
        ourServices: action.value,
      };
    }
    case "SET_ABOUT_US_FORM_CHANGED": {
      return {
        ...prevState,
        aboutUs: action.value,
      };
    }

    default: {
      logError({ error: `Could not determine an action type for action: ${action}` });
      return {
        // unrecognized dispatch action
        ...prevState,
      };
    }
  }
};

export const useMarketingAssets = (): UseMarketingAssetsReturnData => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { previewUuid } = useParams<PublicVerifierDetailsPreviewParams>();
  const { currentVerifierUuid, hasPermission } = useAuth();
  const [verifierDisplayName, setVerifierDisplayName] = useState<string>("");

  const [hasUnsavedChanges, dispatch] = useReducer(reducer, {
    hero: false,
    ourExpertise: false,
    codesAndStandards: false,
    atAGlance: false,
    ourServices: false,
    aboutUs: false,
  });

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [listingRowVersion, setListingRowVersion] = useState<number>(1);

  const [heroDefaultValues, setHeroDefaultValues] = useState<FormHeroData | undefined>(undefined);
  const [ourExpertiseDefaultValues, setOurExpertiseDefaultValues] = useState<OurExpertiseData | undefined>(undefined);
  const [codesAndStandardsDefaultValues, setCodesAndStandardsDefaultValues] = useState<
    CodesAndStandardsData | undefined
  >(undefined);
  const [atAGlanceDefaultValues, setAtAGlanceDefaultValues] = useState<FormAtAGlanceData | undefined>(undefined);
  const [ourServicesDefaultValues, setOurServicesDefaultValues] = useState<FormOurServicesData | undefined>(undefined);
  const [aboutUsDefaultValues, setAboutUsDefaultValues] = useState<FormAboutUsData | undefined>(undefined);

  const [heroOldDefaultValues, setHeroOldDefaultValues] = useState<FormHeroData | undefined>(undefined);
  const [ourExpertiseOldDefaultValues, setOurExpertiseOldDefaultValues] = useState<OurExpertiseData | undefined>(
    undefined
  );
  const [codesAndStandardsOldDefaultValues, setCodesAndStandardsOldDefaultValues] = useState<
    CodesAndStandardsData | undefined
  >(undefined);
  const [atAGlanceOldDefaultValues, setAtAGlanceOldDefaultValues] = useState<FormAtAGlanceData | undefined>(undefined);
  const [ourServicesOldDefaultValues, setOurServicesOldDefaultValues] = useState<FormOurServicesData | undefined>(
    undefined
  );
  const [aboutUsOldDefaultValues, setAboutUsOldDefaultValues] = useState<FormAboutUsData | undefined>(undefined);
  const [isHandlePreviewLoading, setIsHandlePreviewLoading] = useState(false);

  // This hook needs to call child methods in order to get the latest values from each child form
  // This approach should not be replicated in other pages, an exception was made here because of the large number of forms and fields
  // More information in the project standards https://www.notion.so/kana-earth/TypeScript-React-9c0cde0bbf07429ca4d84d5ca9f388b5#0582203770284f33b013224f58b46675
  const heroFormRef = useRef<VerifierMarketingAssetsSubmitHandler>(null);
  const ourExpertiseFormRef = useRef<VerifierMarketingAssetsSubmitHandler>(null);
  const codesAndStandardsFormRef = useRef<VerifierMarketingAssetsSubmitHandler>(null);
  const atAGlanceFormRef = useRef<VerifierMarketingAssetsSubmitHandler>(null);
  const ourServicesFormRef = useRef<VerifierMarketingAssetsSubmitHandler>(null);
  const aboutUsFormRef = useRef<VerifierMarketingAssetsSubmitHandler>(null);

  const fetchListingPreviewData = useCallback(async () => {
    if (currentVerifierUuid) {
      const organisationRes = await getVerifierDetails({
        verifierUuid: currentVerifierUuid,
      });

      if (organisationRes.status === Status.Success && organisationRes.data) {
        if (organisationRes.data.listing?.rowVersion) {
          setListingRowVersion(organisationRes.data.listing.rowVersion);
        }
        if (organisationRes.data.displayName) {
          setVerifierDisplayName(organisationRes.data.displayName);
        }

        setOldDefaultValues(
          organisationRes.data.listing?.content,
          organisationRes.data.listingFiles,
          setHeroOldDefaultValues,
          setOurExpertiseOldDefaultValues,
          setCodesAndStandardsOldDefaultValues,
          setAtAGlanceOldDefaultValues,
          setOurServicesOldDefaultValues,
          setAboutUsOldDefaultValues
        );
      }

      if (previewUuid) {
        const res = await getOrganisationListingPreview({ previewUuid });
        if (res.status === Status.Success && res.data) {
          setDefaultValues(
            res.data?.content,
            res.data?.listingFiles,
            setHeroDefaultValues,
            setOurExpertiseDefaultValues,
            setCodesAndStandardsDefaultValues,
            setAtAGlanceDefaultValues,
            setOurServicesDefaultValues,
            setAboutUsDefaultValues
          );
        }
      }
    }
  }, []);

  const fetchOrganisationData = useCallback(async () => {
    if (currentVerifierUuid) {
      const res = await getVerifierDetails({
        verifierUuid: currentVerifierUuid,
      });

      if (res.status === Status.Success && res.data) {
        if (res.data.listing?.rowVersion) {
          setListingRowVersion(res.data.listing.rowVersion);
        }
        if (res.data.displayName) {
          setVerifierDisplayName(res.data.displayName);
        }

        setOldDefaultValues(
          res.data.listing?.content,
          res.data.listingFiles,
          setHeroOldDefaultValues,
          setOurExpertiseOldDefaultValues,
          setCodesAndStandardsOldDefaultValues,
          setAtAGlanceOldDefaultValues,
          setOurServicesOldDefaultValues,
          setAboutUsOldDefaultValues
        );
        setDefaultValues(
          res.data.listing?.content,
          res.data.listingFiles,
          setHeroDefaultValues,
          setOurExpertiseDefaultValues,
          setCodesAndStandardsDefaultValues,
          setAtAGlanceDefaultValues,
          setOurServicesDefaultValues,
          setAboutUsDefaultValues
        );
      }
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("origin") === Origin.VerifierDetailsPreview) {
      fetchListingPreviewData().then(() => setDataIsLoading(false));
    } else {
      fetchOrganisationData().then(() => setDataIsLoading(false));
    }
  }, [fetchListingPreviewData, fetchOrganisationData]);

  const getCurrentFormsData = (formWhichWasSaved: string): string => {
    let hero;
    let ourMission;
    let standards;
    let atAGlance;
    let aboutUs;
    let ourServices;

    if (heroFormRef.current?.getHeroSubmittedData) {
      hero = heroFormRef.current.getHeroSubmittedData();
    }

    if (ourExpertiseFormRef.current?.getOurExpertiseSubmittedData) {
      ourMission = ourExpertiseFormRef.current.getOurExpertiseSubmittedData();
    }

    if (codesAndStandardsFormRef.current?.getCodesAndStandardsSubmittedData) {
      standards = codesAndStandardsFormRef.current.getCodesAndStandardsSubmittedData();
    }

    if (atAGlanceFormRef.current?.getAtAGlanceSubmittedData) {
      atAGlance = atAGlanceFormRef.current.getAtAGlanceSubmittedData();
    }

    if (ourServicesFormRef.current?.getOurServicesSubmittedData) {
      ourServices = ourServicesFormRef.current.getOurServicesSubmittedData();
    }

    if (aboutUsFormRef.current?.getAboutUsSubmittedData) {
      aboutUs = aboutUsFormRef.current.getAboutUsSubmittedData();
    }

    switch (formWhichWasSaved) {
      case VerifierFormNames.Hero: {
        if (heroFormRef.current?.getHeroData) {
          hero = heroFormRef.current.getHeroData();
        }
        break;
      }
      case VerifierFormNames.OurExpertise: {
        if (ourExpertiseFormRef.current?.getOurExpertiseData) {
          ourMission = ourExpertiseFormRef.current.getOurExpertiseData();
        }
        break;
      }
      case VerifierFormNames.CodesAndStandards: {
        if (codesAndStandardsFormRef.current?.getCodesAndStandardsData) {
          standards = codesAndStandardsFormRef.current.getCodesAndStandardsData();
        }
        break;
      }
      case VerifierFormNames.AtAGlance: {
        if (atAGlanceFormRef.current?.getAtAGlanceData) {
          atAGlance = atAGlanceFormRef.current.getAtAGlanceData();
        }
        break;
      }
      case VerifierFormNames.OurServices: {
        if (ourServicesFormRef.current?.getOurServicesData) {
          ourServices = ourServicesFormRef.current.getOurServicesData();
        }
        break;
      }
      case VerifierFormNames.AboutUs: {
        if (aboutUsFormRef.current?.getAboutUsData) {
          aboutUs = aboutUsFormRef.current.getAboutUsData();
        }
        break;
      }
      default: {
        logError({ error: `${formWhichWasSaved} is not a valid form` });
      }
    }

    return JSON.stringify(
      recursivelyRemoveEmptyStrings({
        hero,
        ourMission,
        standards,
        atAGlance,
        ourServices,
        aboutUs,
      })
    );
  };

  const handlePreview = useIsLoadingWrapper(async (): Promise<void> => {
    if (currentVerifierUuid) {
      setPreviewMode(true);

      let hero;
      let ourMission;
      let standards;
      let atAGlance;
      let aboutUs;
      let ourServices;

      if (heroFormRef.current?.getHeroData) {
        hero = heroFormRef.current.getHeroData();
      }

      if (ourExpertiseFormRef.current?.getOurExpertiseData) {
        ourMission = ourExpertiseFormRef.current.getOurExpertiseData();
      }

      if (codesAndStandardsFormRef.current?.getCodesAndStandardsData) {
        standards = codesAndStandardsFormRef.current.getCodesAndStandardsData();
      }

      if (atAGlanceFormRef.current?.getAtAGlanceData) {
        atAGlance = atAGlanceFormRef.current.getAtAGlanceData();
      }

      if (ourServicesFormRef.current?.getOurServicesData) {
        ourServices = ourServicesFormRef.current.getOurServicesData();
      }

      if (aboutUsFormRef.current?.getAboutUsData) {
        aboutUs = aboutUsFormRef.current.getAboutUsData();
      }

      const res = await saveOrganisationListingPreview({
        objectUuid: currentVerifierUuid,
        objectType: "Verifier",
        content: JSON.stringify(
          recursivelyRemoveEmptyStrings({
            hero,
            ourMission,
            standards,
            atAGlance,
            ourServices,
            aboutUs,
          })
        ),
      });

      if (res.status === Status.Success && res.data?.previewUuid) {
        navigate(getPublicVerifierDetailsPreviewRoute(res.data.previewUuid));
      }
      if (res.status === Status.Error && res.errors) {
        const previewErrors: {
          hero: ServiceError[];
          ourExpertise: ServiceError[];
          codesAndStandards: ServiceError[];
          ourServices: ServiceError[];
          aboutUs: ServiceError[];
        } = {
          hero: [],
          ourExpertise: [],
          codesAndStandards: [],
          ourServices: [],
          aboutUs: [],
        };

        res.errors.forEach((error) => {
          switch (true) {
            case error.path?.includes(VerifierFormNames.Hero): {
              previewErrors.hero.push(error);
              break;
            }
            case error.path?.includes(VerifierFormNames.OurExpertise): {
              previewErrors.ourExpertise.push(error);
              break;
            }
            case error.path?.includes(VerifierFormNames.CodesAndStandards): {
              previewErrors.codesAndStandards.push(error);
              break;
            }
            case error.path?.includes(VerifierFormNames.OurServices): {
              previewErrors.ourServices.push(error);
              break;
            }
            case error.path?.includes(VerifierFormNames.AboutUs): {
              previewErrors.aboutUs.push(error);
              break;
            }
            default: {
              logError({ error: `No form could be found for ${error.path}` });
            }
          }
        });

        if (heroFormRef.current?.setHeroErrors) {
          heroFormRef.current.setHeroErrors(previewErrors.hero);
        }
        if (ourExpertiseFormRef.current?.setOurExpertiseErrors) {
          ourExpertiseFormRef.current.setOurExpertiseErrors(previewErrors.ourExpertise);
        }
        if (codesAndStandardsFormRef.current?.setCodesAndStandardsErrors) {
          codesAndStandardsFormRef.current.setCodesAndStandardsErrors(previewErrors.codesAndStandards);
        }
        if (ourServicesFormRef.current?.setOurServicesErrors) {
          ourServicesFormRef.current?.setOurServicesErrors(previewErrors.ourServices);
        }
        if (aboutUsFormRef.current?.setAboutUsErrors) {
          aboutUsFormRef.current?.setAboutUsErrors(previewErrors.aboutUs);
        }
      }
    }
  }, setIsHandlePreviewLoading);

  return {
    heroFormRef,
    ourExpertiseFormRef,
    codesAndStandardsFormRef,
    ourServicesFormRef,
    atAGlanceFormRef,
    aboutUsFormRef,
    dataIsLoading,
    hasUnsavedChanges,
    listingRowVersion,
    setListingRowVersion,
    dispatch,
    heroDefaultValues,
    ourExpertiseDefaultValues,
    codesAndStandardsDefaultValues,
    atAGlanceDefaultValues,
    ourServicesDefaultValues,
    aboutUsDefaultValues,
    heroOldDefaultValues,
    ourExpertiseOldDefaultValues,
    codesAndStandardsOldDefaultValues,
    atAGlanceOldDefaultValues,
    ourServicesOldDefaultValues,
    aboutUsOldDefaultValues,
    verifierDisplayName,
    handlePreview,
    previewMode,
    isHandlePreviewLoading,
    getCurrentFormsData,
    hasPermission,
    objectUuid: currentVerifierUuid || "",
    objectType: OrganisationTypeConstants.VERIFIER,
    objectKey: "verifierUuid",
    updateListing: updateVerifierListing,
  };
};
