import { Dispatch, useCallback, useEffect, useReducer, useState } from "react";

import { OrganisationTypeConstants } from "../../../../constants";
import { AddressType, CountriesData, FileType } from "../../../../models";
import { updateAssetManagerDetails } from "../../../../service/organisation";
import { getAssetManagerDetails, getOrganisationDetails, lookUp } from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import {
  OrganisationAddressData,
  OrganisationDetailsData,
  OrganisationFormChangedAction,
  OrganisationFormChangedEnum,
  UpdateDetailsType,
} from "../../../shared";

interface UseOrganisationReturnData {
  organisationDefaultDetails: OrganisationDetailsData | undefined;
  organisationDefaultAddress: OrganisationAddressData | undefined;
  organisationDefaultIcon: string | null;
  organisationDefaultLogo: string | null;
  countries: CountriesData | undefined;
  dataIsLoading: boolean;
  dispatch: Dispatch<OrganisationFormChangedAction>;
  objectUuid: string;
  objectType: string;
  objectKey: string;
  updateDetails: UpdateDetailsType;
}

interface OrganisationHasUnsavedChanges {
  address: boolean;
  details: boolean;
  icon: boolean;
  logo: boolean;
}

const reducer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prevState: any,
  action: OrganisationFormChangedAction
): OrganisationHasUnsavedChanges => {
  switch (action.type) {
    case OrganisationFormChangedEnum.SET_ADDRESS_FORM_CHANGED: {
      return {
        ...prevState,
        address: action.value,
      };
    }
    case OrganisationFormChangedEnum.SET_DETAILS_FORM_CHANGED: {
      return {
        ...prevState,
        details: action.value,
      };
    }
    case OrganisationFormChangedEnum.SET_ICON_FORM_CHANGED: {
      return {
        ...prevState,
        icon: action.value,
      };
    }
    case OrganisationFormChangedEnum.SET_LOGO_FORM_CHANGED: {
      return {
        ...prevState,
        logo: action.value,
      };
    }
    default: {
      return {
        // unrecognized dispatch action
        ...prevState,
      };
    }
  }
};

export const useOrganisation = (): UseOrganisationReturnData => {
  const { currentAssetManagerUuid, currentOrganisationUuid } = useAuth();

  // TODO - to be uncommented when we re-use the unsaved changes modal
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [hasUnsavedChanges, dispatch] = useReducer(reducer, {
    address: false,
    details: false,
    icon: false,
    logo: false,
  });
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const [organisationDefaultDetails, setOrganisationDefaultDetails] = useState<OrganisationDetailsData>();
  const [organisationDefaultAddress, setOrganisationDefaultAddress] = useState<OrganisationAddressData>({
    countryCode: "",
    line1: "",
    line2: "",
    region: "",
    postcode: "",
    city: "",
    rowVersion: 1,
  });
  const [organisationDefaultIcon, setOrganisationDefaultIcon] = useState<string | null>(null);
  const [organisationDefaultLogo, setOrganisationDefaultLogo] = useState<string | null>(null);
  const [countries, setCountries] = useState<CountriesData | undefined>(undefined);

  const fetchData = useCallback(async () => {
    if (currentAssetManagerUuid && currentOrganisationUuid) {
      const assetManagerDetails = await getAssetManagerDetails({
        assetManagerUuid: currentAssetManagerUuid,
      });

      const organisationDetails = await getOrganisationDetails({
        organisationUuid: currentOrganisationUuid,
      });

      if (organisationDetails.status === Status.Success && organisationDetails.data) {
        if (organisationDetails.data.addresses.find((a) => a.type === AddressType.Correspondance)) {
          setOrganisationDefaultAddress({
            countryCode: organisationDetails.data.addresses[0].countryCode,
            line1: organisationDetails.data.addresses[0].line1,
            line2: organisationDetails.data.addresses[0].line2,
            region: organisationDetails.data.addresses[0].region,
            postcode: organisationDetails.data.addresses[0].postcode,
            city: organisationDetails.data.addresses[0].city,
            rowVersion: organisationDetails.data.addresses[0].rowVersion,
          });
        }
      }

      const countriesRes = await lookUp({ type: "Country", sort: [{ key: "valueString", direction: "asc" }] });

      if (countriesRes.status === Status.Success && countriesRes.data) {
        setCountries(
          countriesRes.data.map((country) => ({
            key: country.key,
            value: country.valueString,
          }))
        );
      }

      if (assetManagerDetails.status === Status.Success && assetManagerDetails.data) {
        setOrganisationDefaultDetails({
          displayName: assetManagerDetails.data?.displayName,
          phoneNumber: assetManagerDetails.data?.contactPhone,
          contactEmail: assetManagerDetails.data?.contactEmail,
          website: assetManagerDetails.data.websiteUrl,
          twitterUsername: assetManagerDetails.data.socialMediaTwitterUsername,
          linkedinURL: assetManagerDetails.data.socialMediaLinkedInUrl,
          instagramUsername: assetManagerDetails.data.socialMediaInstagramUsername,
          youtubeChannel: assetManagerDetails.data.socialMediaYoutubeChannel,
          facebookURL: assetManagerDetails.data.socialMediaFacebookUrl,
          rowVersion: assetManagerDetails.data.rowVersion,
        });

        setOrganisationDefaultIcon(
          assetManagerDetails.data?.files.find((f) => f.type === FileType.SmallLogo)?.file.url || null
        );

        setOrganisationDefaultLogo(
          assetManagerDetails.data?.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null
        );
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    organisationDefaultDetails,
    organisationDefaultAddress,
    organisationDefaultIcon,
    organisationDefaultLogo,
    countries,
    dataIsLoading,
    dispatch,
    objectUuid: currentAssetManagerUuid || "",
    objectType: OrganisationTypeConstants.ASSET_MANAGER,
    objectKey: "assetManagerUuid",
    updateDetails: updateAssetManagerDetails,
  };
};
