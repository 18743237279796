import "../styles/DataGrid.css";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Column as RdgColumn, SortColumn as RdgSortColumn } from "react-data-grid";

import { FilterCriteria, FilterOperator, SortCriteria } from "../../../../models";
import { ResultData } from "../../../../service/Shared";
import { rdgClientSideFilter } from "../clientSide/ClientSideFilter";
import { rdgClientSideSort } from "../clientSide/ClientSideSort";
import { onFilterChangedEvent } from "../components/HeaderRenderer";
import { getFilterCriteriaWithCastedValues, mapColumnDefinitionToRdgColumn } from "../utils";
import { DataGridProps } from "./DataGrid";

export interface UseDataGridReturnData {
  filterCriteria: FilterCriteria[];
  resultData: readonly ResultData[];
  rdgColumns: RdgColumn<ResultData>[];
  hasFilters: boolean;
  sortColumns: readonly RdgSortColumn[];
  setSortColumns: Dispatch<SetStateAction<readonly RdgSortColumn[]>>;
  dgCurrentPage: number;
  setDgCurrentPage: Dispatch<SetStateAction<number>>;
  siblingCount: number;
  pageSize: number;
  dgTotalCount: number;
}

export const useDataGrid = ({
  data,
  columns,
  filterable = false,
  sortable = true,
  clientSide = false,
  onChange,
  pagination = { currentPage: 1, pageSize: 10, siblingCount: 2 },
}: DataGridProps): UseDataGridReturnData => {
  const [resultData, setResultData] = useState<readonly ResultData[]>(data || []);
  const [dgTotalCount, setDgTotalCount] = useState<number>(data?.length || 0);
  const [sortColumns, setSortColumns] = useState<readonly RdgSortColumn[]>([]);
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria[]>([]);
  const [dgCurrentPage, setDgCurrentPage] = useState<number>(pagination.currentPage || 1);

  const pageSize = pagination.pageSize || 10;
  const siblingCount = pagination.siblingCount || 2;

  const onFilterChanged: onFilterChangedEvent = (
    columnKey: string,
    operator: FilterOperator,
    value: string,
    filterId: number | undefined
  ): void => {
    const hasFilterValue = value !== null && value !== undefined && value !== "";
    let starterFilters = filterCriteria.filter((f) => f.key !== columnKey);
    if (hasFilterValue)
      starterFilters = starterFilters.concat({
        key: columnKey,
        operator,
        value,
        filterId,
      });
    setFilterCriteria(starterFilters);
  };

  // TODO this is a dummy state added so that CursorDataGrid can work
  // To be fixed when further work os done on this component / When we decide to implement client side sorting/fitlering
  const [testState, setTestState] = useState<FilterCriteria[]>([]);
  const [testState2, setTestState2] = useState<readonly RdgSortColumn[]>([]);

  const rdgColumns: RdgColumn<ResultData>[] = mapColumnDefinitionToRdgColumn(
    columns,
    sortable,
    filterable,
    testState2,
    setTestState2,
    false,
    testState,
    setTestState,
    onFilterChanged
  );

  if (clientSide) {
    useEffect(() => {
      // client-side filtering
      const filteredData = rdgClientSideFilter(data, columns, filterCriteria);
      setDgTotalCount(filteredData.length);
      // client-side sorting
      const sortedData = rdgClientSideSort(filteredData, columns, sortColumns);
      // client-side paging
      setResultData(sortedData.slice((dgCurrentPage - 1) * pageSize, dgCurrentPage * pageSize));
    }, [data, sortColumns, filterCriteria, dgCurrentPage]);
  } else if (onChange) {
    useEffect(() => {
      const sortCriteria = sortColumns.map(
        (sc): SortCriteria => ({
          key: sc.columnKey,
          direction: sc.direction === "DESC" ? "desc" : "asc",
        })
      );

      onChange({
        filtering: getFilterCriteriaWithCastedValues(filterCriteria, columns),
        sorting: sortCriteria,
        paging: {
          currentPage: dgCurrentPage,
          pageSize,
        },
      }).then((update) => {
        setResultData(update.resultData);
        if (update.paging?.currentPage !== undefined) setDgCurrentPage(update.paging.currentPage);
        if (update.paging?.totalCount !== undefined) setDgTotalCount(update.paging.totalCount);
      });
    }, [sortColumns, filterCriteria, dgCurrentPage, dgTotalCount]);
  }

  const hasFilters = filterable === true && columns.some((cd) => cd.filterable || true);
  return {
    resultData,
    filterCriteria,
    dgCurrentPage,
    hasFilters,
    rdgColumns,
    setDgCurrentPage,
    pageSize,
    setSortColumns,
    siblingCount,
    sortColumns,
    dgTotalCount,
  };
};
