import "./ProjectsSection.css";

import { Button, Column, CursorDataGrid, Row } from "../../../../../../../../widget";
import { AddGroupProjectModal } from "../AddGroupProjectModal";
import { RemoveGroupProjectModal } from "../RemoveGroupProjectModal";
import { useProjectsSection } from "./useProjectsSection";

export const ProjectsSection = (): JSX.Element => {
  const {
    columns,
    onChange,
    dataIsLoading,
    defaultSortingCriteria,
    refreshGroup,
    removeProjectUuid,
    showAddProjectModal,
    setShowAddProjectModal,
    showRemoveProjectModal,
    setShowRemoveProjectModal,
  } = useProjectsSection();

  return (
    <div className="ProjectsSection">
      <Row spacingV="ml">
        <Column span={6}>
          <h2>Projects</h2>
        </Column>
        <Column span={6} justify="end">
          <Button text="Add project" onClick={() => setShowAddProjectModal(true)} size="small" variant="tertiary" />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <CursorDataGrid
            columns={columns}
            filterable
            clientSide={false}
            onChange={onChange}
            data={[]}
            dataIsLoading={dataIsLoading}
            noDataMessage="No group projects to display"
            refresh={refreshGroup}
            showTotalCount={false}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </Column>
      </Row>
      <RemoveGroupProjectModal
        projectUuid={removeProjectUuid}
        showRemoveProjectModal={showRemoveProjectModal}
        closeModal={() => {
          setShowRemoveProjectModal(false);
        }}
      />
      <AddGroupProjectModal
        showAddProjectModal={showAddProjectModal}
        closeModal={() => setShowAddProjectModal(false)}
      />
    </div>
  );
};
