import "./UnitsAveragePriceKPI.css";

import { TotalMarketValueTotalReturnsData } from "../../../../models";
import { getFormattedDate } from "../../../../utils";
import { ChartData, PieChart } from "../../../charts";
import { KanaTooltip, Toggle } from "../../../forms";
import { PercentageChangeTrend } from "../components";
import { useUnitsAveragePriceKPI } from "./useUnitsAveragePriceKPI";

interface UnitsAveragePriceKPIProps {
  unitsData: ChartData;
  averagePrice: TotalMarketValueTotalReturnsData;
}

const unitsComponent = (unitsData: ChartData): JSX.Element => (
  <>
    <div className="TitleSection">
      <p className="body01">Units</p>
      <KanaTooltip
        tooltipHeader="Buffer Units"
        tooltipText="Please note that buffer units are not shown in this chart"
      />
    </div>
    <PieChart size={114} data={unitsData} isGradient variant="Secondary" />
  </>
);

const averagePriceComponent = (
  averagePrice: TotalMarketValueTotalReturnsData,
  formattedValue: (string | undefined)[]
): JSX.Element => (
  <>
    <div className="TitleSection">
      <p className="body01">Avg price</p>
      <KanaTooltip
        tooltipHeader="Average price"
        tooltipText="Average price is based on sales of units over the past 28 days"
      />
    </div>
    <div className="InfoSection">
      <div className="PercentageChange">
        <PercentageChangeTrend percentageChange={averagePrice.percentageChange} />
      </div>
      <div className="Value">
        <h1 className="Number">£{formattedValue[0]}</h1>
        <h1 className="Magnitude">{formattedValue[1]}</h1>
      </div>
      <p className="body2">{getFormattedDate(averagePrice.lastUpdated)}</p>
    </div>
  </>
);

export const UnitsAveragePriceKPI = ({ unitsData, averagePrice }: UnitsAveragePriceKPIProps): JSX.Element => {
  const { toggleUnitsAveragePrice, setToggleUnitsAveragePrice, formattedValue } = useUnitsAveragePriceKPI({
    averagePrice,
  });

  return (
    <div className="UnitsAveragePriceContainer">
      {toggleUnitsAveragePrice ? averagePriceComponent(averagePrice, formattedValue) : unitsComponent(unitsData)}
      <div className="ToggleSection">
        <p className={`body3 ${toggleUnitsAveragePrice ? "Inactive" : "ActiveUnits"}`}>Units</p>
        <Toggle value={toggleUnitsAveragePrice} onChange={(e) => setToggleUnitsAveragePrice(e)} variant="tertiary" />
        <p className={`body3 ${toggleUnitsAveragePrice ? "ActiveAvgPrice" : "Inactive"}`}>Avg price</p>
      </div>
    </div>
  );
};
