import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../../constants";
import { getSettingsProjectAccessRoute } from "../../../../../utils/routes";

interface UseInviteReturnData {
  handleBackClick: () => void;
}

export const useInvite = (): UseInviteReturnData => {
  const navigate = useNavigate();

  const handleBackClick = (): void => {
    navigate(getSettingsProjectAccessRoute(OrganisationTypeConstants.DEVELOPER));
  };

  return {
    handleBackClick,
  };
};
