import { FocusEventHandler, useEffect, useState } from "react";

interface UseTextInputReturnData {
  currentValue: string;
  onInputChange: FocusEventHandler<HTMLInputElement> | undefined;
  onInputBlur: FocusEventHandler<HTMLInputElement> | undefined;
}

export const useTextInput = (
  name: string,
  value?: string | null,
  type?: string,
  decimalPlaces?: number,
  onChange?: (value: string) => void,
  debounceMilliseconds?: number
): UseTextInputReturnData => {
  const [currentValue, setCurrentValue] = useState<string>(value ?? "");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeoutId, setTimeoutId] = useState<any | undefined>(undefined);

  const formatNumberValue = (targetValue: string): string => {
    const formattedValue = targetValue !== "" ? parseFloat(targetValue) : NaN;
    if (Number.isNaN(formattedValue)) return "";
    return decimalPlaces ? formattedValue.toFixed(decimalPlaces) : formattedValue.toString();
  };

  useEffect(() => {
    if (value !== undefined && value !== null && value !== "") {
      if (type === "number") {
        setCurrentValue(formatNumberValue(value));
      } else {
        setCurrentValue(value);
      }
    } else {
      setCurrentValue("");
    }
  }, [value]);

  useEffect((): void | (() => void) => {
    if (debounceMilliseconds === undefined || onChange === undefined) return;
    if (timeoutId !== undefined) clearTimeout(timeoutId);
    const to = setTimeout(() => {
      onChange(currentValue);
    }, debounceMilliseconds);
    setTimeoutId(to);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(to);
  }, [currentValue]);

  const onInputChange: FocusEventHandler<HTMLInputElement> = (e) => {
    setCurrentValue(e.currentTarget.value);

    if (onChange && debounceMilliseconds === undefined) onChange(e.currentTarget.value);
  };

  const onInputBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (type === "number" && currentValue) {
      setCurrentValue(formatNumberValue(currentValue));
    }
  };

  return {
    currentValue,
    onInputChange,
    onInputBlur,
  };
};
