import "../../css/ActivityWizard.css";
import "./Edit.css";

import { ArrowRoundLeftDarkIcon, ArrowRoundRightLightIcon, DownwardArrowLightIcon } from "../../../../../../assets";
import { GroupConstants } from "../../../../../../constants";
import { VersionConflictModal } from "../../../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { hasEnoughSpace } from "../../../../../../utils";
import {
  Button,
  Column,
  InformationBox,
  ProfileMenu,
  ProfileMenuItem,
  Row,
  Stepper,
  UserHeader,
  Wizard,
} from "../../../../../../widget";
import { GenericModal } from "../../../../components";
import { ActivityWizardContent, ReviewContent } from "../../components";
import { IntroductionContent } from "../../components/IntroductionContent";
import { useEdit } from "./useEdit";

export const Edit = (): JSX.Element => {
  const {
    steps,
    l3Steps,
    clickedL3Step,
    setClickedL3Step,
    stepFields,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    isReview,
    isIntroduction,
    showReviewPage,
    activityDefinition,
    activityDefinitionInfo,
    activityCompletionPercentage,
    errors,
    currentUserType,
    projectDetails,
    groupDetails,
    isExternalActivity,
    movePrevious,
    moveNext,
    moveTo,
    moveToLastStep,
    moveToReview,
    moveToIntroduction,
    startOrResumeActivity,
    navigateBackToProjectActivities,
    isMoveNextLoading,
    isMovePreviousLoading,
    isMoveToLastStepLoading,
    isMoveToReviewLoading,
    isStartOrResumeActivityLoading,
    isSaveOrPublishDraftLoading,
    showVersionConflictModal,
    setShowVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    setNewVersionActivityHistory,
    setDraftActivityHistoryUuid,
    showSaveAndCloseDropdown,
    setShowSaveAndCloseDropdown,
    saveAndCloseOptions,
    showSaveDraftModal,
    onConfirmSaveDraftModal,
    onCloseSaveDraftModal,
    saveAndCloseBtnRef,
  } = useEdit();

  const saveAndCloseBtn = (): JSX.Element => {
    return (
      <div>
        <div ref={saveAndCloseBtnRef}>
          <Button
            text="Save & Close"
            onClick={() => {
              setShowSaveAndCloseDropdown(true);
            }}
            icon={<DownwardArrowLightIcon className="SaveCloseArrow" />}
            variant="secondary"
          />
        </div>
        <ProfileMenu
          show={showSaveAndCloseDropdown && !isSaveOrPublishDraftLoading}
          onClose={() => setShowSaveAndCloseDropdown(false)}
          position={
            saveAndCloseBtnRef.current &&
            hasEnoughSpace(saveAndCloseBtnRef.current.getBoundingClientRect().top, 150, document.body.clientHeight)
              ? { top: "0" }
              : { top: "-80px", left: "160px" }
          }
        >
          {saveAndCloseOptions.map((el) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <ProfileMenuItem key={el.id} profileMenuItem={el} onClick={el.action!} />
          ))}
        </ProfileMenu>
      </div>
    );
  };

  const showIntroductionOrReviewPage = (): JSX.Element => {
    return isIntroduction ? (
      <IntroductionContent activityDefinitionUuid={activityData?.activity.activityDefinition.uuid} />
    ) : (
      <ReviewContent
        isEdit={!isExternalActivity}
        groupUuid={groupDetails?.groupGuid}
        projectUuid={projectDetails?.uuid}
        setShowVersionConflictModal={setShowVersionConflictModal}
        setDraftActivityHistoryUuid={setDraftActivityHistoryUuid}
        setNewVersionActivityHistory={setNewVersionActivityHistory}
        activityDefinition={activityDefinition}
        handleMoveToStep={moveTo}
      />
    );
  };

  return isLoading ? (
    <div />
  ) : (
    <div className={`${currentUserType} ActivityWizardContainer ActivityWizardContainer_Edit`}>
      <div className="ActivityWizardContainerWizard">
        <Wizard
          projectName={activityData?.activity?.project?.displayName || ""}
          activityName={activityDefinitionInfo?.displayName || ""}
          activityVersion={`v${activityDefinitionInfo?.versionNumber || ""}`}
          currentUserType={currentUserType}
          steps={steps}
          isReview={isReview}
          isIntroduction={isIntroduction}
          completionPercentage={activityCompletionPercentage}
          handleMoveToStep={moveTo}
          handleMoveToReview={showReviewPage ? moveToReview : undefined}
          handleMoveToIntroduction={moveToIntroduction}
        />
      </div>
      <div className="ActivityWizardContainerContent">
        <Row spacingV="m" className="ActivityWizardContainerContentUserHeader">
          <Column span={12}>
            <UserHeader />
          </Column>
        </Row>
        {/* eslint-disable-next-line no-nested-ternary */}
        {(showReviewPage && isReview) || isIntroduction ? (
          showIntroductionOrReviewPage()
        ) : (
          <>
            {currentStepName && (
              <Row spacingV={l3Steps && l3Steps.length > 0 ? "ml" : "ll"} className="ActivityWizardContainerStepName">
                <Column span={12}>
                  <h1>{currentStepName}</h1>
                </Column>
              </Row>
            )}
            {groupDetails?.registryMatch === GroupConstants.REGISTRY_MISMATCH && (
              <Row spacingV="ml">
                <Column span={12}>
                  <InformationBox variant="info">
                    <p>
                      We have detected that this group does not match what is recorded on the external registry. Please
                      update the project membership in order to match the registry.
                    </p>
                  </InformationBox>
                </Column>
              </Row>
            )}
            {l3Steps && l3Steps.length > 0 && (
              <Row spacingV="ll" className="ActivityWizardContainerStepper">
                <Column span={12}>
                  <Stepper
                    steps={l3Steps}
                    currentSubStepKey={currentStepKeys.length === 3 ? currentStepKeys[2] : undefined}
                    onClick={(stepKey) => {
                      const isCurrentStep = currentStepKeys.length === 3 && currentStepKeys[2] === stepKey;
                      const l3StepKeys = [...currentStepKeys.slice(0, -1), stepKey];

                      if (stepKey === clickedL3Step) {
                        return;
                      }

                      if (!isCurrentStep) {
                        setClickedL3Step(l3StepKeys.at(-1));
                        moveTo(l3StepKeys);
                      }
                    }}
                    currentUserType={currentUserType}
                  />
                </Column>
              </Row>
            )}
            <Row spacingV="ll">
              <Column span={12}>
                <ActivityWizardContent fields={stepFields} errors={errors} />
              </Column>
            </Row>
          </>
        )}
        <Row spacingV="ll">
          <Column span={12}>
            <div className="ActivityWizardContainerContentActions">
              {hasPrevious && (
                <Button
                  text="Previous"
                  onClick={movePrevious}
                  variant="tertiary"
                  icon={<ArrowRoundLeftDarkIcon />}
                  iconPosition="left"
                  isLoading={isMovePreviousLoading}
                />
              )}
              {isReview && (
                <Button
                  text="Previous"
                  onClick={moveToLastStep}
                  variant="tertiary"
                  icon={<ArrowRoundLeftDarkIcon />}
                  iconPosition="left"
                  isLoading={isMoveToLastStepLoading}
                />
              )}
              {isIntroduction && (
                <Button
                  text={activityCompletionPercentage > 0 ? "Resume activity" : "Start activity"}
                  onClick={startOrResumeActivity}
                  isLoading={isStartOrResumeActivityLoading}
                />
              )}
              {isIntroduction && (
                <Button text="Close activity" onClick={navigateBackToProjectActivities} variant="secondary" />
              )}
              {hasNext && (
                <Button
                  text="Next"
                  onClick={moveNext}
                  variant="primary"
                  icon={<ArrowRoundRightLightIcon />}
                  isLoading={isMoveNextLoading}
                />
              )}
              {!hasNext && showReviewPage && !isReview && !isIntroduction && (
                <Button
                  text="Next"
                  onClick={moveToReview}
                  variant="primary"
                  icon={<ArrowRoundRightLightIcon />}
                  isLoading={isMoveToReviewLoading}
                />
              )}
              {!isIntroduction && saveAndCloseBtn()}
            </div>
          </Column>
        </Row>
      </div>
      <VersionConflictModal
        isForActivitiesEditPage
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
      />
      <GenericModal
        show={showSaveDraftModal && !isSaveOrPublishDraftLoading}
        onConfirm={() => onConfirmSaveDraftModal(false)}
        onConfirmOptional={() => onConfirmSaveDraftModal(true)}
        closeModal={onCloseSaveDraftModal}
        data={{
          title: "Save draft",
          text: [
            "By not publishing these updates, your work will remain in draft and is at risk of being lost if another user with access to the activity publishes their work ahead of you. Comments will also not be visible to other users at your organisation until the draft is published.",
            "How would you like to proceed?",
          ],
          confirmText: "Save draft",
          confirmOptionalText: "Publish draft",
          cancelText: "Continue editing",
        }}
      />
    </div>
  );
};
