import { FetchResult, Observable } from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";

import { getErrorMessageFromCode } from "../../../service/ValidationErrorFormatter";
import { Toast } from "../../../widget";
import { ERROR_APPLICATION } from "../../errorConstants";
import { logErrorWithDetail } from "./errorHandlerUtils";

// for HTTP 500 status codes, show an error message
// and log an error
export const errorHandler500 = (networkError: NetworkError): Observable<FetchResult> | undefined => {
  logErrorWithDetail("GraphQL status code 500 received", networkError);
  Toast.error({
    message: getErrorMessageFromCode(ERROR_APPLICATION),
  });
  return undefined;
};
