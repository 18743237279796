import { OrganisationTypeConstants } from "../../../constants";
import { Column, Row } from "../../../widget";
import { ChartsSection } from "../ChartsSection";
import { useAtAGlanceSection } from "./useAtAGlanceSection";

interface AtAGlanceSectionProps {
  organisationUuid: string | null | undefined;
  organisationType: OrganisationTypeConstants;
}

export const AtAGlanceSection = ({ organisationUuid, organisationType }: AtAGlanceSectionProps): JSX.Element => {
  const { projectsChartData, unitsChartData, locationsChartData, isLoading } = useAtAGlanceSection(
    organisationUuid,
    organisationType
  );

  return (
    <div>
      {!isLoading &&
      (Object.keys(projectsChartData).length > 0 ||
        unitsChartData.PIUs > 0 ||
        unitsChartData.VCUs > 0 ||
        Object.keys(locationsChartData).length > 0) ? (
        <ChartsSection
          title="At a glance"
          firstChartData={projectsChartData}
          firstChartTitle="Projects"
          secondChartData={unitsChartData}
          secondChartTitle="Units"
          secondChartNoDataMessage="No units issued"
          thirdChartData={locationsChartData}
          thirdChartTitle="Locations"
        />
      ) : (
        <div className="ChartsSection">
          <Row spacingV="ll">
            <Column span={12}>
              <h2>At a glance</h2>
            </Column>
          </Row>
        </div>
      )}
    </div>
  );
};
