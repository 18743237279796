import "./PublicTemplate.css";

import React from "react";

import { HeaderActiveElement } from "../../models";

export interface PublicPreviewTemplateProps {
  children: React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  activeElement?: HeaderActiveElement;
}

export const PublicPreviewTemplate = ({ children }: PublicPreviewTemplateProps): JSX.Element => {
  return (
    <div className="PublicTemplate">
      <div className="PublicTemplateContent">{children}</div>
    </div>
  );
};
