import { FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProjectContext } from "../../../../route/shared/projects/project/ProjectContext";
import { deleteProject } from "../../../../service/project";
import { ServiceError, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../utils";
import { getProjectsRoute } from "../../../../utils/routes";
import { Toast } from "../../../../widget";

interface UseDeleteProjectModalReturnData {
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  resetForm: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const useDeleteProjectModal = (closeModal: () => void): UseDeleteProjectModalReturnData => {
  const navigate = useNavigate();

  const { currentUserType } = useAuth();
  const { projectDetails } = useContext(ProjectContext);

  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const resetForm = (): void => {
    setErrors([]);
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!projectDetails?.uuid) return;

    const res = await deleteProject({
      projectUuid: projectDetails?.uuid,
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Project deleted successfully" });

      resetForm();
      closeModal();
      navigate(getProjectsRoute(currentUserType));
    }

    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  }, setIsHandleSubmitLoading);

  return {
    errors,
    resetForm,
    handleSubmit,
    isHandleSubmitLoading,
  };
};
