import "./ProjectStatusKPI.css";

import { useMemo } from "react";

import { ProjectStatusConstants } from "../../../../constants";
import { logError } from "../../../../service/error";
import { KPITooltip } from "../components";

interface ProjectStatusProps {
  status: string;
  graphBarFlexSize?: number;
}

const getClassName = (projectStatus: string | null): string | null => {
  switch (projectStatus) {
    case ProjectStatusConstants.DRAFT:
    case ProjectStatusConstants.NOT_DELIVERED:
    case ProjectStatusConstants.CANCELLED:
      return null;
    case ProjectStatusConstants.PRE_DEVELOPMENT:
      return "GraphBar1Filled";
    case ProjectStatusConstants.UNDER_DEVELOPMENT:
      return "GraphBar2Filled";
    case ProjectStatusConstants.VALIDATED:
      return "GraphBar3Filled";
    case ProjectStatusConstants.VERIFIED:
      return "GraphBar4Filled";
    default:
      logError({
        error: `No statusKPI graph bar has been found for this project status: ${projectStatus}`,
      });
      return null;
  }
};

const getProjectStatusDescription = (status: string | null): string | null => {
  switch (status) {
    case ProjectStatusConstants.DRAFT:
    case ProjectStatusConstants.NOT_DELIVERED:
    case ProjectStatusConstants.CANCELLED:
      return null;
    case ProjectStatusConstants.PRE_DEVELOPMENT:
      return "This project is in pre development";
    case ProjectStatusConstants.UNDER_DEVELOPMENT:
      return "The project has not yet issued units";
    case ProjectStatusConstants.VALIDATED:
      return "The project has issued PIUs";
    case ProjectStatusConstants.VERIFIED:
      return "The project has issued VCUs";
    default:
      logError({
        error: `Unknown status ${status}`,
      });
      return null;
  }
};

export const ProjectStatusKPI = ({ status, graphBarFlexSize = 2 }: ProjectStatusProps): JSX.Element => {
  const steps = useMemo(
    () => [
      {
        tooltipText: "Pre-development",
        tooltipOffsetWidth: 85,
        flexSize: 1,
      },
      {
        tooltipText: "Under development",
        tooltipOffsetWidth: 65,
        flexSize: 1,
      },
      {
        tooltipText: "Validated",
        tooltipOffsetWidth: 35,
        flexSize: 1,
      },
      {
        tooltipText: "Verified",
        tooltipOffsetWidth: 30,
        flexSize: graphBarFlexSize,
      },
    ],
    []
  );
  return (
    <div className="ProjectStatusContainer">
      <div className={`ProjectStatusGraph ${getClassName(status)}`}>
        {steps.map((step) => {
          return (
            <KPITooltip key={step.tooltipText} text={step.tooltipText} offsetWidth={step.tooltipOffsetWidth}>
              <div className="GraphBarEmpty" style={{ flex: step.flexSize }} />
            </KPITooltip>
          );
        })}
      </div>
      <div className="ProjectStatusHeaderArea">
        <h2>{status}</h2>
        <p className="body2">Project Status</p>
        <p className="body3">{getProjectStatusDescription(status)}</p>
      </div>
    </div>
  );
};
