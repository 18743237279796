import { PublicProjectTypesTemplate, PublicTemplate } from "../../../templates";

const ProjectTypes = (): JSX.Element => {
  return (
    <PublicTemplate activeElement="Project types" isContentUnwrapped>
      <PublicProjectTypesTemplate />
    </PublicTemplate>
  );
};

export default ProjectTypes;
