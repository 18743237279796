import { OrganisationPermissionConstants, ProjectPermissionConstants } from "../../../constants";
import { DeveloperSingleCardWithTabsTemplate } from "../../../templates";
import { useAuth } from "../../../useAuth";

const Settings = (): JSX.Element => {
  const { hasPermission } = useAuth();

  let tabsList = [
    { route: "/d/settings/organisation", label: "Organisation" },
    {
      route: "/d/settings/marketing-assets",
      label: "Marketing Assets",
    },
    {
      route: "/d/settings/project-access",
      label: "Project Access",
    },
    {
      route: "/d/settings/document-types",
      label: "Document types",
    },
    // { route: "/d/settings/embedding", label: "Embedding" },
  ];

  if (hasPermission(OrganisationPermissionConstants.READ_ORGANISATION_USERS))
    tabsList = [
      {
        route: "/d/settings/users",
        label: "Users",
      },
      ...tabsList,
    ];

  if (hasPermission(ProjectPermissionConstants.READ_PROJECT_AUDIT))
    tabsList = [
      ...tabsList,
      {
        route: "/d/settings/audit-history",
        label: "Audit history",
      },
    ];

  return (
    <div>
      <DeveloperSingleCardWithTabsTemplate activeElement="Settings" pageTitle="Settings" tabsList={tabsList} />
    </div>
  );
};

export default Settings;
