import { DeveloperSingleCardTemplate } from "../../../../templates";
import { ChangePersonalDetailsForm, useChangePersonalDetails } from "../../../shared";

const ChangePersonalDetails = (): JSX.Element => {
  const { isLoading, data } = useChangePersonalDetails();

  return (
    <div>
      {!isLoading && (
        <DeveloperSingleCardTemplate pageTitle="Change Personal Details">
          {data && <ChangePersonalDetailsForm defaultValues={data} />}
        </DeveloperSingleCardTemplate>
      )}
    </div>
  );
};

export default ChangePersonalDetails;
