import "./VcuPiuKPI.css";

import { formatNumber } from "../../../../utils";
import { KPITooltip } from "../components/KPITooltip";

interface VcuPiuProps {
  vcus: number;
  pius: number;
}

export const VcuPiuKPI = ({ vcus, pius }: VcuPiuProps): JSX.Element => {
  const total = vcus + pius;
  const vcusRatio = (vcus / total) * 100;
  const piusRatio = (pius / total) * 100;

  return (
    <div className="VcuPiuContainer">
      {vcus === 0 ? (
        <>
          <div className="VcuPiuGraph">
            <KPITooltip text={`${pius} PIUs`} offsetHeight={-29} offsetWidth={65}>
              <div className="PiuGraphRowFilled" />
            </KPITooltip>
            <KPITooltip text={`${vcus} VCUs`} offsetHeight={-29} offsetWidth={65}>
              <div className="VcuGraphRowEmpty" />
            </KPITooltip>
          </div>
          <div className="VcuPiuHeaderArea">
            <h2>{formatNumber(pius)}</h2>
            <p className="body2">Carbon Units</p>
            <p className="body3">PIUs only</p>
          </div>
        </>
      ) : (
        <>
          <div className="VcuPiuGraph">
            <KPITooltip text={`${pius} PIUs`} offsetHeight={-29} offsetWidth={65}>
              <div className="PiuGraphRowFilled" style={{ flex: piusRatio }} />
            </KPITooltip>
            <KPITooltip text={`${vcus} VCUs`} offsetHeight={-29} offsetWidth={65}>
              <div className="VcuGraphRowFilled" style={{ flex: vcusRatio }} />
            </KPITooltip>
          </div>
          <div className="VcuPiuHeaderArea">
            <h2>{formatNumber(total)}</h2>
            <p className="body2">Carbon Units</p>
            <p className="body3">PIUs and VCUs</p>
          </div>
        </>
      )}
    </div>
  );
};
