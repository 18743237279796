import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  AboutUsData,
  CodeCharacteristics,
  CoreCarbonPrinciplesData,
  FileType,
  GetInTouchData,
  HeroData,
  PublicCodeDetailsParams,
  SocialMediaData,
  UkEnvironmentalReportingData,
} from "../../../../../models";
import { getStandardDetails } from "../../../../../service/publicquery";
import { Status } from "../../../../../service/Shared";
import { isCodePrincipleMet } from "../../../../../utils";
import {
  CodeStatusData,
  KeyFactsData,
  OurMissionData,
  ProjectProcessData,
  ProjectTypesData,
  RegistryData,
} from "../../models";

interface UseDetailsReturnData {
  dataIsLoading: boolean;
  codeDetailsData: CodeDetailsData | undefined;
}

export interface CodeDetailsData {
  displayName: string | null;
  logo: string | null;
  websiteUrl: string | null;
  registryData: RegistryData;
  contactPhone?: string | null;
  calculatedContactable: boolean;
  socialMediaData: SocialMediaData;
  hero: HeroData;
  ourMission: OurMissionData;
  keyFacts: KeyFactsData;
  projectProcess: ProjectProcessData;
  aboutUs: AboutUsData;
  getInTouch: GetInTouchData;
  codeCharacteristics: CodeCharacteristics;
  ukEnvironmentalReporting: UkEnvironmentalReportingData;
  coreCarbonPrinciples: CoreCarbonPrinciplesData;
  projectTypes: ProjectTypesData;
  status: CodeStatusData;
}

export const useDetails = (): UseDetailsReturnData => {
  const { standardUuid } = useParams<PublicCodeDetailsParams>();
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [codeDetailsData, setCodeDetailsData] = useState<CodeDetailsData | undefined>(undefined);

  const fetchData = useCallback(async (): Promise<void> => {
    if (standardUuid) {
      const res = await getStandardDetails({
        standardUuid,
      });
      if (res.status === Status.Success && res.data) {
        const logo = res.data.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null;
        const heroValues: HeroData = {
          enabled: res.data.listing?.content?.hero ? res.data.listing?.content?.hero?.enabled : false,
          title: res.data.listing?.content?.hero?.title || null,
          subtitle: res.data.listing?.content?.hero?.subtitle || null,
          imageUrl:
            res.data.listingFiles.find((file) => file.uuid === res.data?.listing?.content?.hero?.imageFileUuid)?.file
              .url || null,
        };

        const ourMissionValues: OurMissionData = {
          enabled: res.data.listing?.content?.ourMission ? res.data.listing?.content?.ourMission?.enabled : false,
          content: res.data?.listing?.content?.ourMission?.content,
        };

        const socialMediaValues: SocialMediaData = {
          twitterUsername: res.data.socialMediaTwitterUsername,
          linkedinURL: res.data.socialMediaLinkedInUrl,
          instagramUsername: res.data.socialMediaInstagramUsername,
          youtubeChannel: res.data.socialMediaYoutubeChannel,
          facebookURL: res.data.socialMediaFacebookUrl,
        };

        const keyFactsValues: KeyFactsData = {
          enabled: res.data.listing?.content?.atAGlance ? res.data.listing?.content?.atAGlance?.enabled : false,
          content: res.data?.listing?.content?.atAGlance?.content,
        };

        const projectProcessValues: ProjectProcessData = {
          enabled: res.data.listing?.content?.process ? res.data.listing?.content?.process?.enabled : false,
          content: res.data?.listing?.content?.process?.content,
          imageUrl:
            res.data.listingFiles.find((file) => file.uuid === res.data?.listing?.content?.process?.imageFileUuid)?.file
              .url || null,
          codeUrl: res.data?.listing?.content?.process?.codeUrl,
        };

        const aboutUsValues: AboutUsData = {
          enabled: res.data.listing?.content?.aboutUs ? res.data.listing?.content?.aboutUs?.enabled : false,
          content: res.data?.listing?.content?.aboutUs?.content,
          imageUrl:
            res.data.listingFiles.find((file) => file.uuid === res.data?.listing?.content?.aboutUs?.imageFileUuid)?.file
              .url || null,
          imageLayout: res.data?.listing?.content?.aboutUs?.imageLayout,
        };

        const getInTouchValues: GetInTouchData = {
          enabled: res.data.listing?.content?.getInTouch ? res.data.listing?.content?.getInTouch?.enabled : false,
        };

        const registryValues: RegistryData = {
          name: res.data?.listing?.content?.registry?.name,
          url: res.data?.listing?.content?.registry?.url,
        };

        const characteristicsData = res.data.listing?.content?.characteristics;
        const formattedCharacteristics: CodeCharacteristics = {
          enabled: characteristicsData?.enabled ? characteristicsData?.enabled : false,
          programGovernance: {
            codeUrl: characteristicsData?.programGovernance?.codeUrl,
            codePositions: characteristicsData?.programGovernance?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.programGovernance?.codePositions),
          },
          noDoubleCounting: {
            codeUrl: characteristicsData?.noDoubleCounting?.codeUrl,
            codePositions: characteristicsData?.noDoubleCounting?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.noDoubleCounting?.codePositions),
          },
          registry: {
            codeUrl: characteristicsData?.registry?.codeUrl,
            codePositions: characteristicsData?.registry?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.registry?.codePositions),
          },
          mitigationActivityInformation: {
            codeUrl: characteristicsData?.mitigationActivityInformation?.codeUrl,
            codePositions: characteristicsData?.mitigationActivityInformation?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.mitigationActivityInformation?.codePositions),
          },
          additionality: {
            codeUrl: characteristicsData?.additionality?.codeUrl,
            codePositions: characteristicsData?.additionality?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.additionality?.codePositions),
          },
          robustQuantificationOfEmissionsReductionsAndRemovals: {
            codeUrl: characteristicsData?.robustQuantificationOfEmissionsReductionsAndRemovals?.codeUrl,
            codePositions: characteristicsData?.robustQuantificationOfEmissionsReductionsAndRemovals?.codePositions,
            met: isCodePrincipleMet(
              characteristicsData?.robustQuantificationOfEmissionsReductionsAndRemovals?.codePositions
            ),
          },
          permanence: {
            codeUrl: characteristicsData?.permanence?.codeUrl,
            codePositions: characteristicsData?.permanence?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.permanence?.codePositions),
          },
          robustIndependentThirdPartyValidationAndVerification: {
            codeUrl: characteristicsData?.robustIndependentThirdPartyValidationAndVerification?.codeUrl,
            codePositions: characteristicsData?.robustIndependentThirdPartyValidationAndVerification?.codePositions,
            met: isCodePrincipleMet(
              characteristicsData?.robustIndependentThirdPartyValidationAndVerification?.codePositions
            ),
          },
          transitionToNetZero: {
            codeUrl: characteristicsData?.transitionToNetZero?.codeUrl,
            codePositions: characteristicsData?.transitionToNetZero?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.transitionToNetZero?.codePositions),
          },
          sustainableDevelopmentImpactsAndSafeguards: {
            codeUrl: characteristicsData?.sustainableDevelopmentImpactsAndSafeguards?.codeUrl,
            codePositions: characteristicsData?.sustainableDevelopmentImpactsAndSafeguards?.codePositions,
            met: isCodePrincipleMet(characteristicsData?.sustainableDevelopmentImpactsAndSafeguards?.codePositions),
          },
        };
        const coreCarbonPrinciplesValues: CoreCarbonPrinciplesData = {
          characteristics: formattedCharacteristics,
          codeUrl: res.data.listing?.content?.codeUrl,
          status: res.data.listing?.content?.status?.value,
        };

        const projectTypesValues: ProjectTypesData = {
          projectTypes: res.data.projectTypes,
        };

        const codeStatusData: CodeStatusData = {
          value: res.data.listing?.content?.status?.value,
          enabled: res.data.listing?.content?.status ? res.data.listing?.content?.status?.enabled : false,
        };

        const ukEnvironmentalReportingData: UkEnvironmentalReportingData = {
          enabled: res.data.listing?.content?.reportingStandards
            ? res.data.listing?.content?.reportingStandards?.enabled
            : false,
          ukEnvironmentalReporting: res.data.listing?.content?.reportingStandards?.ukEnvironmentalReporting || false,
        };

        setCodeDetailsData({
          displayName: res.data.displayName,
          logo,
          websiteUrl: res.data.websiteUrl,
          registryData: registryValues,
          contactPhone: res.data.contactPhone,
          calculatedContactable: res.data.calculatedContactable,
          getInTouch: getInTouchValues,
          socialMediaData: socialMediaValues,
          hero: heroValues,
          ourMission: ourMissionValues,
          keyFacts: keyFactsValues,
          projectProcess: projectProcessValues,
          aboutUs: aboutUsValues,
          codeCharacteristics: formattedCharacteristics,
          ukEnvironmentalReporting: ukEnvironmentalReportingData,
          coreCarbonPrinciples: coreCarbonPrinciplesValues,
          projectTypes: projectTypesValues,
          status: codeStatusData,
        });
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, []);

  return {
    dataIsLoading,
    codeDetailsData,
  };
};
