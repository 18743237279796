import { useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate } from "react-router-dom";

import {
  OrganisationTypeConstants,
  ProjectActivitiesConstants,
  ProjectPermissionConstants,
  UserType,
} from "../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../models";
import { getPublicGroupDetails } from "../../../../service/publicquery";
import {
  getCurrentUserProjectPermissions,
  GetCurrentUserProjectPermissionsResponse,
  searchActivityHistory,
  SearchActivityHistoryResponse,
} from "../../../../service/query";
import { ResultData, Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../useAuth";
import { checkCanManageActivity, flattenObject } from "../../../../utils";
import {
  getActivityReviewRoute,
  getActivityReviewViewRoute,
  getActivityViewRoute,
  getDashboardRoute,
} from "../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
  Toast,
} from "../../../../widget";
import { resumeActivity } from "../../utils";
import { useActivityDashboardContextSwitcher } from "../../utils/activities/useActivityDashboardContextSwitcher";
import { VersionHistoryDetailsCellFormatter, VersionHistoryOpenActivityCellFormatter } from "./components";

interface UseActivityVersionHistoryTabReturnData {
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  activityReviewUuid?: string;
  dataIsLoading: boolean;
  selectedActivityHistoryUuid?: string;
  currentActivityReviewUuid?: string;
  showDetailsPanel: boolean;
  isActivityReview: boolean;
  currentUserType: UserType;
  onCloseDetailsPanel: () => void;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  canEdit: boolean;
}

export const useActivityVersionHistoryTab = (): UseActivityVersionHistoryTabReturnData => {
  const navigate = useNavigate();

  const {
    activityUuid,
    activityDetails,
    isActivityReview,
    currentActivityReviewUuid,
    queryParams,
    setShowVersionConflictModal,
    setNewVersionActivityHistory,
    setDraftActivityHistoryUuid,
    setShowDetailsPanel,
    onCloseDetailsPanel,
    showDetailsPanel,
    hasManageProjectActivityPermission,
    canReviewActivity,
  } = useActivityDashboardContextSwitcher();

  const { currentUserType } = useAuth();

  const [dataIsLoading, setDataIsLoading] = useState(true);

  const [selectedActivityHistoryUuid, setSelectedActivityHistoryUuid] = useState<string>();

  const [canEdit, setCanEdit] = useState(false);

  const checkEditPermission = (
    permissions: GetCurrentUserProjectPermissionsResponse[],
    permission: string
  ): boolean => {
    return !!permissions.length && permissions.every((p) => p.currentUserPermissions.includes(permission));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] =
    currentUserType !== OrganisationTypeConstants.VERIFIER
      ? [
          { key: "versionNumber", name: "Version", dataType: "number" },
          { key: "changeSummary", name: "Change", dataType: "string" },
          {
            name: "Completion %",
            key: "completionPercentage",
            dataType: "number",
            formatter: "progress",
            alignment: "center",
          },
          { key: "createdByUser.fullName", name: "Author", dataType: "string" },
          {
            key: "createdAt",
            name: "Created at",
            dataType: "Date",
            formatter: "dateOnly",
            minWidth: 150,
            filterable: false,
          },
          {
            key: "detailsLink",
            name: "Details",
            dataType: "string",
            formatter: "custom",
            alignment: "center",
            filterable: false,
            sortable: false,
            customCellFormatter: VersionHistoryDetailsCellFormatter,
          },
          {
            key: "viewOrEditLink",
            name: "Open activity",
            dataType: "string",
            formatter: "custom",
            alignment: "center",
            filterable: false,
            sortable: false,
            customCellFormatter: VersionHistoryOpenActivityCellFormatter,
          },
        ]
      : [
          { key: "versionNumber", name: "Version", dataType: "number" },
          { key: "createdByUser.fullName", name: "Author", dataType: "string" },
          {
            key: "createdAt",
            name: "Created at",
            dataType: "Date",
            formatter: "dateOnly",
            minWidth: 150,
            filterable: false,
          },
          {
            key: "updatedAt",
            name: "Submitted at",
            dataType: "Date",
            formatter: "dateOnly",
            minWidth: 150,
            filterable: false,
          },
          {
            key: "detailsLink",
            name: "Details",
            dataType: "string",
            formatter: "custom",
            alignment: "center",
            filterable: false,
            sortable: false,
            customCellFormatter: VersionHistoryDetailsCellFormatter,
          },
          {
            key: "viewOrEditLink",
            name: "Open activity",
            dataType: "string",
            formatter: "custom",
            alignment: "center",
            filterable: false,
            sortable: false,
            customCellFormatter: VersionHistoryOpenActivityCellFormatter,
          },
        ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "versionNumber", direction: "DESC" }];

  const formatData = useCallback(
    (
      responseData: SearchActivityHistoryResponse | undefined,
      permissions: GetCurrentUserProjectPermissionsResponse[]
    ): ResultData[] => {
      return (
        responseData?.results?.map((d) => {
          const result = flattenObject(d);

          result.detailsLink = {
            action: () => {
              setSelectedActivityHistoryUuid(d.uuid);
              setShowDetailsPanel(true);
            },
          } as DataGridButtonLinkCellFormatterData;

          switch (currentUserType) {
            case OrganisationTypeConstants.DEVELOPER: {
              const hasEditPermission = checkEditPermission(
                permissions,
                ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY
              );

              result.viewOrEditLink =
                d.isCurrent &&
                !d.isUnderReview &&
                (activityDetails?.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ||
                  activityDetails?.status === ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED) &&
                hasEditPermission
                  ? ({
                      text: "Edit",
                      action: () =>
                        setDraftActivityHistoryUuid && setNewVersionActivityHistory && setShowVersionConflictModal
                          ? resumeActivity(
                              navigate,
                              setDraftActivityHistoryUuid,
                              setNewVersionActivityHistory,
                              setShowVersionConflictModal,
                              d.activity.uuid,
                              d.isDraft ? d.uuid : undefined,
                              d.isDraft ? d.versionNumber : undefined
                            )
                          : undefined,
                    } as DataGridButtonLinkCellFormatterData)
                  : ({
                      action: () => navigate(getActivityViewRoute(d.uuid, currentUserType, queryParams)),
                      text: "View",
                    } as DataGridButtonLinkCellFormatterData);
              break;
            }
            case OrganisationTypeConstants.VERIFIER: {
              const hasEditPermission = checkEditPermission(
                permissions,
                ProjectPermissionConstants.WRITE_PROJECT_ACTIVITY_REVIEW
              );

              result.viewOrEditLink =
                canReviewActivity && currentActivityReviewUuid && hasEditPermission && d.isUnderReview
                  ? ({
                      text: "Edit",
                      action: () => navigate(getActivityReviewRoute(currentActivityReviewUuid, queryParams)),
                    } as DataGridButtonLinkCellFormatterData)
                  : ({
                      action: () =>
                        navigate(
                          getActivityReviewViewRoute(
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            d.uuid,
                            queryParams
                          )
                        ),
                      text: "View",
                    } as DataGridButtonLinkCellFormatterData);
              break;
            }
            // All other user types can only view
            default:
              result.viewOrEditLink = {
                action: () => navigate(getActivityViewRoute(d.uuid, currentUserType, queryParams)),
                text: "View",
              } as DataGridButtonLinkCellFormatterData;
          }

          return result;
        }) || []
      );
    },
    [activityDetails, canReviewActivity]
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!activityUuid) throw new Error("Uuid must not be empty!");

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.activity = {
      uuid: {
        operator: "eq",
        value: activityUuid,
      },
    };

    filterCriteria.isDraft = {
      operator: "eq",
      value: false,
    };

    if (currentUserType === OrganisationTypeConstants.VERIFIER) {
      filterCriteria.changeSummary = {
        operator: "eq",
        value: ProjectActivitiesConstants.STATUS_SUBMITTED,
      };
    }

    if (filterCriteria.completionPercentage) {
      filterCriteria.completionPercentage = {
        ...filterCriteria.completionPercentage,
        value: filterCriteria.completionPercentage.value / 100,
      };
    }

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await searchActivityHistory({
      activityUuid,
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    })
      .then(async (response) => {
        let projectUuids = response.data?.results?.flatMap((el) => el.activity.project?.uuid || []) || [];

        if (response.data?.results?.length && response.data?.results[0]?.activity?.group?.uuid) {
          const groupDetailsRes = await getPublicGroupDetails({
            projectGroupUuid: response.data?.results[0]?.activity?.group?.uuid,
          });

          if (groupDetailsRes.status === Status.Error && groupDetailsRes.errors) {
            if (groupDetailsRes.errors.find((e) => e.code === "VALIDATION_INVALID_USER_PERMISSION")) {
              if (window?.history?.state?.idx === 0) {
                navigate(getDashboardRoute(currentUserType), { replace: true });
              } else {
                navigate(-1);
              }

              Toast.error({ message: getErrorMessageFromCode("VALIDATION_INVALID_USER_PERMISSION") });
            }
          }

          if (groupDetailsRes.status === Status.Success && groupDetailsRes.data) {
            projectUuids = groupDetailsRes.data?.projects?.map((p: any) => p.uuid);
          }
        }

        const uniqueProjectUuids = Array.from(new Set(projectUuids));

        await getCurrentUserProjectPermissions({ projectUuids: uniqueProjectUuids, groupUuids: null }).then(
          (permissions) => {
            data = {
              resultData: formatData(response.data, permissions.data || []),
              paging: {
                startCursor: response.data?.paging?.startCursor || "",
                endCursor: response.data?.paging?.endCursor || "",
                pageSize: paging.pageSize || 10,
                totalCount: response.data?.paging?.total || 0,
                hasNextPage: response.data?.paging?.hasNextPage || false,
                hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
              },
            };
          }
        );
      })
      .finally(() => {
        setDataIsLoading(false);
      });
    return data;
  };

  useEffect(() => {
    switch (currentUserType) {
      case OrganisationTypeConstants.DEVELOPER:
        setCanEdit(
          hasManageProjectActivityPermission === undefined
            ? false
            : checkCanManageActivity(hasManageProjectActivityPermission, activityDetails?.status) &&
                (activityDetails?.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ||
                  activityDetails?.status === ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED)
        );
        break;
      case OrganisationTypeConstants.VERIFIER:
        setCanEdit(canReviewActivity ?? false);
        break;
      default:
        break;
    }
  }, [currentUserType, canReviewActivity, activityDetails, hasManageProjectActivityPermission]);

  return {
    showDetailsPanel,
    onCloseDetailsPanel,
    currentActivityReviewUuid,
    selectedActivityHistoryUuid,
    columns,
    defaultSortingCriteria,
    currentUserType,
    dataIsLoading,
    isActivityReview,
    onChange,
    canEdit,
  };
};
