import { ServiceError } from "../../service/Shared";
import { getErrorMessageFromCode } from "../../service/ValidationErrorFormatter";
import { convertDateToStringYYYYMMDD, INVALID_DATE_OBJECT_STRING_SUFFIX } from "../date";
import { isDate, isObject } from "../isType";

/** *
 * Parses the received object and transform all properties of `Date` type to `string` in format `YYYY-MM-DD`
 * If the Date inputs are not valid returns a ServiceError array
 * @param data
 */
export const recursivelyValidateAndTransformDateToString = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { errors: ServiceError[]; data: any } => {
  const errors: ServiceError[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const recurse = (input: any = {}): any => {
    if (input === null || input === undefined) return input;
    if (typeof input === "string" && input.includes(INVALID_DATE_OBJECT_STRING_SUFFIX)) {
      const code = "VALIDATION_DATE_ONLY_VALID";
      errors.push({
        message: getErrorMessageFromCode(code),
        code,
      });
      return input;
    }

    if (isDate(input)) {
      if (Number.isNaN((input as Date).getTime())) {
        const code = "VALIDATION_DATE_ONLY_VALID";
        errors.push({
          message: getErrorMessageFromCode(code),
          code,
        });
        return input;
      }
      return convertDateToStringYYYYMMDD(input);
    }

    if (isObject(input)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ret: any = {};
      const entries = Object.entries(input);

      for (let i = 0; i < entries.length; i++) {
        const [objectKey, objectValue] = entries[i];
        if (objectValue === "") ret[objectKey] = null;
        else if (typeof objectValue === "string" && objectValue.includes(INVALID_DATE_OBJECT_STRING_SUFFIX)) {
          const code = "VALIDATION_DATE_ONLY_VALID";
          errors.push({
            message: getErrorMessageFromCode(code, { property: objectKey }),
            code,
          } as ServiceError);
          ret[objectKey] = objectValue;
        } else if (isObject(objectValue)) {
          if (isDate(objectValue)) {
            if (Number.isNaN((objectValue as Date).getTime())) {
              const code = "VALIDATION_DATE_ONLY_VALID";
              errors.push({
                message: getErrorMessageFromCode(code, { property: objectKey }),
                code,
              } as ServiceError);

              ret[objectKey] = objectValue;
            } else {
              ret[objectKey] = convertDateToStringYYYYMMDD(objectValue as Date);
            }
          } else {
            ret[objectKey] = recurse(objectValue);
          }
        } else if (Array.isArray(objectValue)) {
          ret[objectKey] = recurse(objectValue);
        } else {
          ret[objectKey] = objectValue;
        }
      }
      return ret;
    }

    if (Array.isArray(input)) {
      return input.map((i) => recurse(i));
    }

    return input;
  };

  const formattedData = recurse(data);

  if (errors.length) {
    return { data: null, errors };
  }

  return { data: formattedData, errors };
};
