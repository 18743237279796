import { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../../../constants";
import { inviteOrganisationToProject } from "../../../../../../service/organisation";
import { searchDevelopers, searchProjects } from "../../../../../../service/query";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { VALIDATION_DEVELOPER_ALREADY_HAS_ACCESS } from "../../../../../../utils";
import { getSettingsProjectAccessRoute } from "../../../../../../utils/routes";
import { Toast } from "../../../../../../widget";

interface useInviteFormReturnData {
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onCancel: () => void;
  projectUuid?: string;
  projectList?: { key: string; value: string }[];
  onProjectSelect: (value: string) => void;
  onProjectTypeSearch: (value: string) => void;
  developerUuid?: string;
  developerList?: { key: string; value: string }[];
  onDeveloperTypeSearch: (value: string) => void;
  onDeveloperSelect: (value: string) => void;
  accessLevelKey?: string;
  onAccessLevelChange: (value: string) => void;
  onSendInvitation: () => void;
  showSendInvitationModal: boolean;
  onCloseSendInvitationModal: () => void;
  errors: ServiceError[] | undefined;
}

export const useInviteForm = (): useInviteFormReturnData => {
  const navigate = useNavigate();

  const { currentDeveloperUuid } = useAuth();

  const [projectUuid, setProjectUuid] = useState<string>();
  const [projectList, setProjectList] = useState<{ key: string; value: string }[]>([]);
  const [developerUuid, setDeveloperUuid] = useState<string>();
  const [accessLevelKey, setAccessLevelKey] = useState<string>();
  const [developerList, setDeveloperList] = useState<{ key: string; value: string }[]>([]);
  const [showSendInvitationModal, setShowSendInvitationModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const onCloseSendInvitationModal = (): void => {
    setShowSendInvitationModal(false);
  };

  const onOpenSendInvitationModal = (): void => {
    setShowSendInvitationModal(true);
  };

  const onSendInvitation = async (): Promise<void> => {
    setShowSendInvitationModal(false);
    if (projectUuid && developerUuid && accessLevelKey) {
      const response = await inviteOrganisationToProject({
        projectUuid,
        targetOrganisationUuid: developerUuid,
        targetRole: accessLevelKey,
      });

      if (response.status === Status.Success) {
        Toast.success({ message: "Access invitation sent" });
        navigate(getSettingsProjectAccessRoute(OrganisationTypeConstants.DEVELOPER));
      }

      if (response.status === Status.Error) {
        if (!response.errors?.some((error) => error.code === VALIDATION_DEVELOPER_ALREADY_HAS_ACCESS)) {
          Toast.error({ message: "Access invitation failed to sent" });
        }
        setErrors(response?.errors);
      }
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const errorList = [];

    if (!projectUuid) {
      errorList.push({
        code: "NO_PROJECT",
        message: "A project must be selected",
      });
    }

    if (!developerUuid) {
      errorList.push({
        code: "NO_DEVELOPER",
        message: "A developer must be selected",
      });
    }

    if (!accessLevelKey) {
      errorList.push({
        code: "NO_ACCESS_LEVEL",
        message: "An access level must be selected",
      });
    }
    if (errorList.length) {
      setErrors(errorList);
    } else {
      onOpenSendInvitationModal();
    }
  };

  const getDevelopers = useCallback(
    async (search: string) => {
      const response = await searchDevelopers({
        paging: {
          limit: 10,
          beforeCursor: "",
          afterCursor: "",
        },
        filter: {
          results: {
            displayName: {
              operator: "startsWith",
              value: search,
            },
          },
        },
        excludedProjectUuid: projectUuid || null,
      });
      if (response.status === Status.Success && response.data?.results) {
        const list = response.data.results.flatMap((el) => {
          if (el.parentOrganisation && el.parentOrganisation.uuid && el.displayName) {
            return { key: el.parentOrganisation.uuid, value: el.displayName };
          }
          return [];
        });

        setDeveloperList(list);
      }
    },
    [projectUuid]
  );

  const getProjects = useCallback(async (search: string) => {
    if (currentDeveloperUuid) {
      const response = await searchProjects({
        paging: {
          limit: 10,
          beforeCursor: "",
          afterCursor: "",
        },
        sort: [],
        filter: {
          results: {
            developer: {
              uuid: {
                operator: "eq",
                value: currentDeveloperUuid,
              },
            },
            displayName: {
              operator: "startsWith",
              value: search,
            },
          },
        },
      });

      if (response.status === Status.Success && response.data?.results) {
        const list = response.data.results.flatMap((el) => {
          if (el.uuid && el.displayName) {
            return { key: el.uuid, value: el.displayName };
          }
          return [];
        });

        setProjectList(list);
      }
    }
  }, []);

  useEffect(() => {
    getProjects("");
    getDevelopers("");
  }, []);

  useEffect(() => {
    getDevelopers("");
  }, [projectUuid]);

  const onCancel = (): void => {
    setProjectUuid(undefined);
    setDeveloperUuid(undefined);
    setAccessLevelKey(undefined);
  };

  const onProjectSelect = (value: string): void => {
    setProjectUuid(value);
  };

  const onAccessLevelChange = (value: string): void => {
    setAccessLevelKey(value);
  };

  const onDeveloperTypeSearch = (value: string): void => {
    getDevelopers(value);
  };

  const onProjectTypeSearch = (value: string): void => {
    getProjects(value);
  };

  const onDeveloperSelect = (value: string): void => {
    setDeveloperUuid(value);
  };

  return {
    onSubmit,
    onCancel,
    projectUuid,
    projectList,
    onProjectSelect,
    onProjectTypeSearch,
    developerUuid,
    developerList,
    onDeveloperSelect,
    onDeveloperTypeSearch,
    accessLevelKey,
    onAccessLevelChange,
    onSendInvitation,
    showSendInvitationModal,
    onCloseSendInvitationModal,
    errors,
  };
};
