import {
  Dispatch,
  FormEvent,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PreviewFile, RadioButtonGroupData } from "../../../../../../models";
import {
  getProjectListingPreview,
  saveProjectListingPreview,
  updateProjectListing,
  UpdateProjectListingRequest,
  uploadListingFile,
} from "../../../../../../service/project";
import { GetProjectDetailsResponse } from "../../../../../../service/query";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual, useIsLoadingWrapper } from "../../../../../../utils";
import { recursivelyRemoveEmptyStrings } from "../../../../../../utils/rest";
import { getProjectMarketingSettingsRoute, getPublicProjectOverviewPreviewRoute } from "../../../../../../utils/routes";
import { Toast } from "../../../../../../widget";
import { RadioChangeEvent } from "../../../../../../widget/forms/Input/RadioButtonGroup/components";
import { ProjectContext } from "../../../../../shared/projects/project/ProjectContext";
import { MarketingSettingsData } from "../models";

interface useMarketingSettingsFormReturnData {
  radioButtonGroupData: RadioButtonGroupData;
  hideProjectChecked: boolean;
  setHideProjectChecked: Dispatch<SetStateAction<boolean>>;
  pilotProjectChecked: boolean;
  setPilotProjectChecked: Dispatch<SetStateAction<boolean>>;

  shortDescription: string | null;
  longDescription: string | null;
  videoUrl: string | null;
  images: PreviewFile[] | undefined;
  embedCode: string;
  hasUnsavedChanges: boolean;
  setShortDescription: Dispatch<SetStateAction<string | null>>;
  setLongDescription: Dispatch<SetStateAction<string | null>>;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
  setImages: Dispatch<SetStateAction<PreviewFile[] | undefined>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handlePreview: () => void;
  onCancel: () => void;
  imageUploadRef: RefObject<ImagesUploadHandle>;
  isPreviewMode: boolean;

  listUnitsChecked: boolean;
  seekingFundingChecked: boolean;
  setSeekingFundingChecked: Dispatch<SetStateAction<boolean>>;
  setListUnitsChecked: Dispatch<SetStateAction<boolean>>;
  unitPriceChecked: boolean;
  setUnitPriceChecked: Dispatch<SetStateAction<boolean>>;
  disablePublicInterestChecked: boolean;
  setDisablePublicInterestChecked: Dispatch<SetStateAction<boolean>>;
  redirectPublicInterestChecked: boolean | undefined;
  onRedirectPublicInterestChange: (e: RadioChangeEvent) => void;
  redirectPublicInterestEmail: string | null | undefined;
  setRedirectPublicInterestEmail: Dispatch<SetStateAction<string | null | undefined>>;
  hasPermission: (permission: string) => boolean;

  isHandleSubmitLoading: boolean;
  isHandlePreviewLoading: boolean;
  errors: ServiceError[] | undefined;
}

type ImagesUploadHandle = {
  clearInput: () => void;
  save: (images: PreviewFile[]) => void;
  setNewPreviewFiles: (images: PreviewFile[]) => void;
};

export const useMarketingSettingsForm = (
  defaultValues: MarketingSettingsData,
  projectDetails: GetProjectDetailsResponse
): useMarketingSettingsFormReturnData => {
  const { setProjectDetails, isExternalProject, hasProjectPermission } = useContext(ProjectContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const imageUploadRef = useRef<ImagesUploadHandle>(null);

  const { hasPermission } = useAuth();

  const [submittedValues, setSubmittedValues] = useState<MarketingSettingsData>(defaultValues);
  const [hideProjectChecked, setHideProjectChecked] = useState<boolean>(defaultValues.hidden);
  const [shortDescription, setShortDescription] = useState(defaultValues.shortDescription);
  const [longDescription, setLongDescription] = useState(defaultValues.longDescription);
  const [videoUrl, setVideoUrl] = useState(defaultValues.videoUrl);
  const [images, setImages] = useState(defaultValues.images);
  const [listUnitsChecked, setListUnitsChecked] = useState<boolean>(defaultValues.availableForSale);
  const [unitPriceChecked, setUnitPriceChecked] = useState<boolean>(defaultValues.showPriceInDirectory);
  const [seekingFundingChecked, setSeekingFundingChecked] = useState<boolean>(defaultValues.seekingFunding);
  const [pilotProjectChecked, setPilotProjectChecked] = useState<boolean>(defaultValues.pilotProject);
  const [disablePublicInterestChecked, setDisablePublicInterestChecked] = useState<boolean>(
    defaultValues.contactOptOut
  );
  const [redirectPublicInterestEmail, setRedirectPublicInterestEmail] = useState<string | null | undefined>(
    defaultValues.contactAlternativeEmail
  );
  const [redirectPublicInterestChecked, setRedirectPublicInterestChecked] = useState<boolean | undefined>(
    !!defaultValues.contactAlternativeEmail
  );
  const [embedCode, setEmbedCode] = useState("");
  const [rowVersion, setRowVersion] = useState(projectDetails.listing?.rowVersion || 1);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);
  const [isHandlePreviewLoading, setIsHandlePreviewLoading] = useState(false);

  const radioButtonGroupData = [
    { key: true, value: "Yes" },
    { key: false, value: "No" },
  ];

  const onRedirectPublicInterestChange = (e: RadioChangeEvent): void => {
    setRedirectPublicInterestChecked(e.target.value);
    if (!e.target.value) {
      setRedirectPublicInterestEmail(null);
    }
  };

  const clearPreviewQueryParams = (): void => {
    navigate(
      {
        pathname: getProjectMarketingSettingsRoute(projectDetails?.uuid),
        search: "",
      },
      { replace: true }
    );
  };

  const initializePreviewData = useCallback(
    async (previewUuid: string) => {
      const previewResponse = await getProjectListingPreview({
        previewUuid,
      });

      if (previewResponse?.status === Status.Success && previewResponse?.data && previewResponse?.data?.content) {
        const previewImages = previewResponse?.data?.content?.images?.flatMap((imgUuid: string) => {
          const listingFile = projectDetails?.listingFiles?.find((lf) => lf.uuid === imgUuid);
          if (listingFile) {
            return {
              mimeType: listingFile.file.mimeType,
              uuid: listingFile.uuid,
              url: listingFile.file.url,
              filename: listingFile.file.filename,
            };
          }
          return [];
        });

        setHideProjectChecked(previewResponse.data?.content.hideProjectChecked);
        setShortDescription(previewResponse.data?.content.shortDescription);
        setLongDescription(previewResponse.data?.content.longDescription);
        setVideoUrl(previewResponse.data?.content.videoUrl);
        setImages(previewImages);
        if (imageUploadRef.current) {
          imageUploadRef.current.setNewPreviewFiles(previewImages);
        }
        setListUnitsChecked(previewResponse.data?.content.listUnitsChecked);
        setUnitPriceChecked(previewResponse.data?.content.unitPriceChecked);
        setSeekingFundingChecked(previewResponse.data?.content.seekingFundingChecked);
        setPilotProjectChecked(previewResponse.data?.content.pilotProjectChecked);
        setDisablePublicInterestChecked(previewResponse.data?.content.disablePublicInterestChecked);
        setRedirectPublicInterestChecked(previewResponse.data?.content.redirectPublicInterestChecked);
        setRedirectPublicInterestEmail(previewResponse.data?.content.redirectPublicInterestEmail);
      }
    },
    [projectDetails]
  );

  // After defaults have been set we override with the preview data if there's a previewUuid in the query params
  useEffect(() => {
    const previewUuid = searchParams.get("previewUuid");
    if (projectDetails && previewUuid && defaultValues) {
      initializePreviewData(previewUuid);
    }
  }, [searchParams, projectDetails, defaultValues, initializePreviewData]);

  // eslint-disable-next-line unused-imports/no-unused-vars
  const computeEmbeddingScript = (values: MarketingSettingsData): string => {
    return "<script> let x = {} </script>";
  };

  const getProcessedImages = async (): Promise<PreviewFile[]> => {
    const processedImages: PreviewFile[] = [];
    if (images) {
      for (let index = 0; index < images.length; index++) {
        const el = images[index];
        if (el.file) {
          // eslint-disable-next-line no-await-in-loop
          const res = await uploadListingFile({
            file: el.file,
            projectUuid: projectDetails.uuid,
          });
          if (res.status === Status.Success && res.data) {
            processedImages.push({
              mimeType: res.data.file.mimeType,
              uuid: res.data.projectListingFileUuid,
              url: res.data.file.url,
              filename: res.data.file.filename,
            });
          }
          if (res.status === Status.Error && res.errors && res.errors.length > 0) {
            Toast.error({ message: getErrorMessageFromCode(res.errors[0].message) });
          }
        } else {
          processedImages.push(el);
        }
      }
    }
    return processedImages;
  };

  const resetForm = (): void => {
    setHideProjectChecked(submittedValues.hidden);

    setShortDescription(submittedValues.shortDescription);
    setLongDescription(submittedValues.longDescription);
    setVideoUrl(submittedValues.videoUrl);
    setImages(submittedValues.images);
    if (imageUploadRef.current) {
      imageUploadRef.current.clearInput();
    }

    setListUnitsChecked(submittedValues.availableForSale);
    setUnitPriceChecked(submittedValues.showPriceInDirectory);
    setSeekingFundingChecked(submittedValues.seekingFunding);
    setPilotProjectChecked(submittedValues.pilotProject);
    setDisablePublicInterestChecked(submittedValues.contactOptOut);
    setRedirectPublicInterestChecked(!!submittedValues.contactAlternativeEmail);
    setRedirectPublicInterestEmail(submittedValues.contactAlternativeEmail);
    clearPreviewQueryParams();
    setErrors([]);
  };

  const getCurrentFormData = useCallback((): MarketingSettingsData => {
    return {
      shortDescription: shortDescription || null,
      longDescription: longDescription || null,
      videoUrl: videoUrl || null,
      images,

      availableForSale: listUnitsChecked,
      showPriceInDirectory: unitPriceChecked,
      seekingFunding: seekingFundingChecked,
      pilotProject: pilotProjectChecked,
      contactOptOut: disablePublicInterestChecked,
      contactAlternativeEmail: redirectPublicInterestEmail,
      hidden: hideProjectChecked,
    };
  }, [
    shortDescription,
    longDescription,
    videoUrl,
    images,
    listUnitsChecked,
    unitPriceChecked,
    seekingFundingChecked,
    pilotProjectChecked,
    disablePublicInterestChecked,
    redirectPublicInterestEmail,
    redirectPublicInterestChecked,
    hideProjectChecked,
  ]);

  useEffect(() => {
    setHasUnsavedChanges(
      !areObjectsDeepEqual(submittedValues, getCurrentFormData()) ||
        !!submittedValues.contactAlternativeEmail !== redirectPublicInterestChecked
    );
  }, [
    shortDescription,
    longDescription,
    videoUrl,
    images,
    listUnitsChecked,
    unitPriceChecked,
    seekingFundingChecked,
    pilotProjectChecked,
    disablePublicInterestChecked,
    redirectPublicInterestEmail,
    redirectPublicInterestChecked,
    hideProjectChecked,
    submittedValues,
  ]);

  const updateProjectDetailsContext = (
    updatedData: UpdateProjectListingRequest,
    processedImages: PreviewFile[]
  ): void => {
    /* 
    TODO Needs refactoring.
    We are adding the new uploaded images to the ListingFile with partial fake data 
    to correctly update the projectDetails in the ProjectContext.
    This whole method would disappear if we could refetch the projectDetails after updating it on this page. 
    We currently cannot do that because of the synchronization issue between rest and graphQL queries. 
    */
    const newImages = processedImages.filter(
      (el) => projectDetails.listingFiles.find((lf) => lf.uuid === el.uuid) == null
    );

    const updatedListingFiles = newImages.map((el) => ({
      file: {
        filename: el.filename,
        mimeType: el.mimeType,
        sizeBytes: 900000, // Placeholder size, won't create any issues
        url: el.url,
        uuid: el.uuid, // Placeholder, won't create any issues
      },
      uuid: el.uuid,
    }));

    setProjectDetails({
      ...projectDetails,
      listing: { ...updatedData, content: JSON.parse(updatedData.content) },
      listingFiles: [...projectDetails.listingFiles, ...updatedListingFiles],
    });
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (redirectPublicInterestChecked && (redirectPublicInterestEmail === null || redirectPublicInterestEmail === "")) {
      setErrors([
        {
          code: "PUBLIC_INTEREST_EMAIL_IS_EMPTY",
          message: "The alternative contact email address must not be empty",
        },
      ]);
      return;
    }

    const processedImages = await getProcessedImages();

    const updatedData = recursivelyRemoveEmptyStrings({
      content: JSON.stringify(
        recursivelyRemoveEmptyStrings({
          shortDescription,
          longDescription,
          videoUrl,
          images: processedImages.map((el) => el.uuid),
        })
      ),
      projectUuid: projectDetails?.uuid,
      hidden: hideProjectChecked,
      availableForSale:
        (projectDetails?.cachedPiuQuantity > 0 || projectDetails?.cachedVcuQuantity > 0) && listUnitsChecked,
      showPriceInDirectory:
        (projectDetails?.cachedPiuQuantity > 0 || projectDetails?.cachedVcuQuantity > 0) && unitPriceChecked,
      contactOptOut: disablePublicInterestChecked,
      seekingFunding: seekingFundingChecked,
      pilotProject: pilotProjectChecked,
      contactAlternativeEmail: redirectPublicInterestEmail || null,
      rowVersion,
    });

    const res = await updateProjectListing(updatedData);

    if (res.status === Status.Success && res.data) {
      Toast.success({
        message: "Marketing settings details changed successfully",
      });
      setSubmittedValues({
        availableForSale: listUnitsChecked,
        seekingFunding: seekingFundingChecked,
        pilotProject: pilotProjectChecked,
        showPriceInDirectory: unitPriceChecked,
        contactOptOut: disablePublicInterestChecked,
        contactAlternativeEmail: redirectPublicInterestEmail,
        hidden: hideProjectChecked,

        shortDescription,
        longDescription,
        videoUrl,
        images: processedImages,
      });
      setRowVersion(res.data.rowVersion);
      updatedData.rowVersion = res.data.rowVersion;

      if (imageUploadRef.current) {
        imageUploadRef.current.save(processedImages);
      }
      clearPreviewQueryParams();
      updateProjectDetailsContext(updatedData, processedImages);
    }
    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  }, setIsHandleSubmitLoading);

  const handlePreview = useIsLoadingWrapper(async (): Promise<void> => {
    setErrors([]);
    setIsPreviewMode(true);
    const processedImages = await getProcessedImages();
    const saveResponse = await saveProjectListingPreview({
      content: JSON.stringify(
        recursivelyRemoveEmptyStrings({
          hideProjectChecked,
          shortDescription,
          longDescription,
          videoUrl,
          images: processedImages.map((el) => el.uuid),
          listUnitsChecked,
          unitPriceChecked,
          seekingFundingChecked,
          pilotProjectChecked,
          disablePublicInterestChecked,
          redirectPublicInterestEmail,
          redirectPublicInterestChecked,
        })
      ),
      projectUuid: projectDetails.uuid,
    });

    if (saveResponse.status === Status.Success && saveResponse.data) {
      navigate(getPublicProjectOverviewPreviewRoute(projectDetails?.uuid, saveResponse.data.previewUuid));
    }

    if (saveResponse.status === Status.Error) {
      setErrors(saveResponse.errors);
    }
  }, setIsHandlePreviewLoading);

  const onCancel = (): void => {
    resetForm();
  };

  useEffect(() => {
    setEmbedCode(computeEmbeddingScript(defaultValues));
  }, []);

  return {
    radioButtonGroupData,
    hideProjectChecked,
    setHideProjectChecked,
    pilotProjectChecked,
    setPilotProjectChecked,

    shortDescription,
    longDescription,
    videoUrl,
    images,
    embedCode,
    hasUnsavedChanges,
    setShortDescription,
    setLongDescription,
    setVideoUrl,
    setImages,
    handleSubmit,
    handlePreview,
    onCancel,
    imageUploadRef,
    isPreviewMode,

    listUnitsChecked,
    setListUnitsChecked,
    unitPriceChecked,
    setUnitPriceChecked,
    seekingFundingChecked,
    setSeekingFundingChecked,
    disablePublicInterestChecked,
    setDisablePublicInterestChecked,
    redirectPublicInterestChecked,
    onRedirectPublicInterestChange,
    redirectPublicInterestEmail,
    setRedirectPublicInterestEmail,
    hasPermission: isExternalProject() ? hasProjectPermission : hasPermission,

    isHandleSubmitLoading,
    isHandlePreviewLoading,
    errors,
  };
};
