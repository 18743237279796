import { useMemo } from "react";

import { GetProjectDetailsResponse } from "../../../../../../../service/query";

interface UseBasicDetailsSectionReturnData {
  mapCoordinates: string[] | null;
  totalProjectArea: string;
}

export const useBasicDetailsSection = (projectDetails: GetProjectDetailsResponse): UseBasicDetailsSectionReturnData => {
  const totalProjectArea = useMemo(
    () => (projectDetails?.areaNetHa !== null ? `${projectDetails?.areaNetHa} ha` : ""),
    [projectDetails]
  );

  return {
    mapCoordinates: projectDetails?.locationCoordinates ? [projectDetails?.locationCoordinates] : null,
    totalProjectArea,
  };
};
