import { FocusEventHandler, useEffect, useState } from "react";

interface UseTextAreaReturnData {
  currentValue: string;
  onTextAreaChange: FocusEventHandler<HTMLTextAreaElement> | undefined;
}

export const useTextArea = (
  value?: string | null,
  debounceMilliseconds?: number,
  onChange?: (value: string) => void
): UseTextAreaReturnData => {
  const [currentValue, setCurrentValue] = useState<string>(value || "");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeoutId, setTimeoutId] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    } else {
      setCurrentValue("");
    }
  }, [value]);

  useEffect((): void | (() => void) => {
    if (debounceMilliseconds === undefined || onChange === undefined) return;

    if (timeoutId !== undefined) clearTimeout(timeoutId);

    const to = setTimeout(() => {
      onChange(currentValue);
    }, debounceMilliseconds);

    setTimeoutId(to);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(to);
  }, [currentValue]);

  const onTextAreaChange: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    setCurrentValue(e.currentTarget.value);

    if (onChange && debounceMilliseconds === undefined) onChange(e.currentTarget.value);
  };

  return {
    currentValue,
    onTextAreaChange,
  };
};
