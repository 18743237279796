import { Dispatch, SetStateAction, useState } from "react";

interface UseForgottenPasswordReturnData {
  passwordReset: boolean;
  setPasswordReset: Dispatch<SetStateAction<boolean>>;
}

export const useForgottenPassword = (): UseForgottenPasswordReturnData => {
  const [passwordReset, setPasswordReset] = useState(false);

  return {
    passwordReset,
    setPasswordReset,
  };
};
