/**
 * Temp functions for AM portal demos 07/05/2024
 */

export const getProjectTotalMarketValue = (projectName: string): number => {
  switch (projectName) {
    case "2faf1de0-0956-11ef-9262-0242ac120002":
      return 13812825.0;
    case "b7564118-0958-11ef-9262-0242ac120002":
      return 12013732.5;
    case "4ff165be-dfd3-40c9-9718-b328e56bc779":
      return 9970422.0;
    case "2faf2010-0956-11ef-9262-0242ac120002":
      return 9428896.0;
    case "2faf2146-0956-11ef-9262-0242ac120002":
      return 9935932.5;
    case "2faf2254-0956-11ef-9262-0242ac120002":
      return 11795975.0;
    case "2faf252e-0956-11ef-9262-0242ac120002":
      return 8896301.0;
    case "2faf263c-0956-11ef-9262-0242ac120002":
      return 7403816.0;
    case "2faf274a-0956-11ef-9262-0242ac120002":
      return 9047239.5;
    case "4b68b9ea-8f0c-4a64-983d-40e9e1c8286d":
      return 9029221.0;
    case "ede94615-a663-45f1-9445-75c6eeb5a192":
      return 8162903.85;
    case "9888848e-f199-11ee-a951-0242ac120002":
      return 8551224.0;
    case "988889f2-f199-11ee-a951-0242ac120002":
      return 8659221.0;
    case "053b4d44-f061-11ee-a951-0242ac120002":
      return 8601588.0;
    case "2d8ee89c-0956-11ef-9262-0242ac120002":
      return 8382312.0;
    case "9888881c-f199-11ee-a951-0242ac120002":
      return 7566122.0;
    case "6c99e71a-e5f4-11ee-bd3d-0242ac120002":
      return 8991407.0;
    case "2faf2844-0956-11ef-9262-0242ac120002":
      return 7855474.0;
    case "0d6b01c9-18b8-4940-a9ae-6b871225537b":
      return 2436375.0;
    case "171c26f0-d5ce-11ec-9d64-0242ac120002":
      return 553280.0;
    default:
      return 8551224.0; // Plausable price in middle of data
  }
};

export const getProjectUnitPrice = (projectName: string): number => {
  switch (projectName) {
    case "2faf1de0-0956-11ef-9262-0242ac120002":
      return 39.0;
    case "b7564118-0958-11ef-9262-0242ac120002":
      return 38.5;
    case "4ff165be-dfd3-40c9-9718-b328e56bc779":
      return 33.0;
    case "2faf2010-0956-11ef-9262-0242ac120002":
      return 32.0;
    case "2faf2146-0956-11ef-9262-0242ac120002":
      return 32.5;
    case "2faf2254-0956-11ef-9262-0242ac120002":
      return 43.0;
    case "2faf252e-0956-11ef-9262-0242ac120002":
      return 29.0;
    case "2faf263c-0956-11ef-9262-0242ac120002":
      return 29.0;
    case "2faf274a-0956-11ef-9262-0242ac120002":
      return 28.5;
    case "4b68b9ea-8f0c-4a64-983d-40e9e1c8286d":
      return 37.0;
    case "ede94615-a663-45f1-9445-75c6eeb5a192":
      return 33.45;
    case "9888848e-f199-11ee-a951-0242ac120002":
      return 36.0;
    case "988889f2-f199-11ee-a951-0242ac120002":
      return 37.0;
    case "053b4d44-f061-11ee-a951-0242ac120002":
      return 36.0;
    case "2d8ee89c-0956-11ef-9262-0242ac120002":
      return 36.0;
    case "9888881c-f199-11ee-a951-0242ac120002":
      return 34.0;
    case "6c99e71a-e5f4-11ee-bd3d-0242ac120002":
      return 37.0;
    case "2faf2844-0956-11ef-9262-0242ac120002":
      return 38.0;
    case "0d6b01c9-18b8-4940-a9ae-6b871225537b":
      return 75.0;
    case "171c26f0-d5ce-11ec-9d64-0242ac120002":
      return 28.0;
    default:
      return 36.0;
  }
};

export const getProjectAmountInvested = (projectName: string): number => {
  switch (projectName) {
    case "2faf1de0-0956-11ef-9262-0242ac120002":
      return 10271075.0;
    case "b7564118-0958-11ef-9262-0242ac120002":
      return 9049305.0;
    case "4ff165be-dfd3-40c9-9718-b328e56bc779":
      return 8761886.0;
    case "2faf2010-0956-11ef-9262-0242ac120002":
      return 8544937.0;
    case "2faf2146-0956-11ef-9262-0242ac120002":
      return 8865909.0;
    case "2faf2254-0956-11ef-9262-0242ac120002":
      return 7955425.0;
    case "2faf252e-0956-11ef-9262-0242ac120002":
      return 8896301.0;
    case "2faf263c-0956-11ef-9262-0242ac120002":
      return 7403816.0;
    case "2faf274a-0956-11ef-9262-0242ac120002":
      return 9205963.0;
    case "4b68b9ea-8f0c-4a64-983d-40e9e1c8286d":
      return 7076957.0;
    case "ede94615-a663-45f1-9445-75c6eeb5a192":
      return 7076957.0;
    case "9888848e-f199-11ee-a951-0242ac120002":
      return 6888486.0;
    case "988889f2-f199-11ee-a951-0242ac120002":
      return 6786957.0;
    case "053b4d44-f061-11ee-a951-0242ac120002":
      return 6929057.0;
    case "2d8ee89c-0956-11ef-9262-0242ac120002":
      return 6752418.0;
    case "9888881c-f199-11ee-a951-0242ac120002":
      return 6453457.0;
    case "6c99e71a-e5f4-11ee-bd3d-0242ac120002":
      return 7047319.0;
    case "2faf2844-0956-11ef-9262-0242ac120002":
      return 5994967.0;
    case "0d6b01c9-18b8-4940-a9ae-6b871225537b":
      return 2338920.0;
    case "171c26f0-d5ce-11ec-9d64-0242ac120002":
      return 503880.0;
    default:
      return 6888486.0;
  }
};

export const getProjectPendingUnits = (projectName: string): number => {
  switch (projectName) {
    case "2faf1de0-0956-11ef-9262-0242ac120002":
      return 321521;
    case "b7564118-0958-11ef-9262-0242ac120002":
      return 312045;
    case "4ff165be-dfd3-40c9-9718-b328e56bc779":
      return 302134;
    case "2faf2010-0956-11ef-9262-0242ac120002":
      return 294653;
    case "2faf2146-0956-11ef-9262-0242ac120002":
      return 283054;
    case "2faf2254-0956-11ef-9262-0242ac120002":
      return 274325;
    case "2faf252e-0956-11ef-9262-0242ac120002":
      return 263456;
    case "2faf263c-0956-11ef-9262-0242ac120002":
      return 255304;
    case "2faf274a-0956-11ef-9262-0242ac120002":
      return 252134;
    case "4b68b9ea-8f0c-4a64-983d-40e9e1c8286d":
      return 244033;
    case "ede94615-a663-45f1-9445-75c6eeb5a192":
      return 244033;
    case "9888848e-f199-11ee-a951-0242ac120002":
      return 237534;
    case "988889f2-f199-11ee-a951-0242ac120002":
      return 234033;
    case "053b4d44-f061-11ee-a951-0242ac120002":
      return 233933;
    case "2d8ee89c-0956-11ef-9262-0242ac120002":
      return 232842;
    case "9888881c-f199-11ee-a951-0242ac120002":
      return 222533;
    case "6c99e71a-e5f4-11ee-bd3d-0242ac120002":
      return 208011;
    case "2faf2844-0956-11ef-9262-0242ac120002":
      return 206723;
    case "0d6b01c9-18b8-4940-a9ae-6b871225537b":
      return 32485;
    case "171c26f0-d5ce-11ec-9d64-0242ac120002":
      return 19760;
    default:
      return 237534;
  }
};

export const getProjectVerifiedUnits = (projectName: string): number => {
  switch (projectName) {
    case "2faf1de0-0956-11ef-9262-0242ac120002":
      return 32654;
    case "b7564118-0958-11ef-9262-0242ac120002":
      return 0;
    case "4ff165be-dfd3-40c9-9718-b328e56bc779":
      return 0;
    case "2faf2010-0956-11ef-9262-0242ac120002":
      return 0;
    case "2faf2146-0956-11ef-9262-0242ac120002":
      return 22667;
    case "2faf2254-0956-11ef-9262-0242ac120002":
      return 0;
    case "2faf252e-0956-11ef-9262-0242ac120002":
      return 43313;
    case "2faf263c-0956-11ef-9262-0242ac120002":
      return 0;
    case "2faf274a-0956-11ef-9262-0242ac120002":
      return 65313;
    case "4b68b9ea-8f0c-4a64-983d-40e9e1c8286d":
      return 0;
    case "ede94615-a663-45f1-9445-75c6eeb5a192":
      return 0;
    case "9888848e-f199-11ee-a951-0242ac120002":
      return 0;
    case "988889f2-f199-11ee-a951-0242ac120002":
      return 0;
    case "053b4d44-f061-11ee-a951-0242ac120002":
      return 5000;
    case "2d8ee89c-0956-11ef-9262-0242ac120002":
      return 0;
    case "9888881c-f199-11ee-a951-0242ac120002":
      return 0;
    case "6c99e71a-e5f4-11ee-bd3d-0242ac120002":
      return 35000;
    case "2faf2844-0956-11ef-9262-0242ac120002":
      return 0;
    case "0d6b01c9-18b8-4940-a9ae-6b871225537b":
      return 0;
    case "171c26f0-d5ce-11ec-9d64-0242ac120002":
      return 0;
    default:
      return 0;
  }
};
