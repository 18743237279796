import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { SelectData } from "../../../../../../../../models";
import { updateProjectDocumentVersion } from "../../../../../../../../service/project/ProjectService.full";
import {
  getProjectDocumentVersion,
  searchProjectDocumentHistory,
  searchProjectOrganisations,
} from "../../../../../../../../service/query/QueryService.full";
import { ServiceError, Status } from "../../../../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../../../../../utils";
import { dataGridMapFilterCriteria, Toast } from "../../../../../../../../widget";
import { ProjectContext } from "../../../../ProjectContext";
import { DocumentContext } from "../../DocumentContext";

interface UseEditDocumentVersionModalProps {
  show: boolean;
  closeModal: () => void;
  editDocumentUuid?: string;
}

interface UseEditDocumentVersionModalReturnData {
  editDocument: () => void;
  onCancel: () => void;
  visibility?: boolean;
  setVisibility: Dispatch<SetStateAction<boolean | undefined>>;
  selectedOrganisations?: string[];
  setSelectedOrganisations: Dispatch<SetStateAction<string[] | undefined>>;
  isEditDocumentLoading: boolean;
  showPublicWarning: boolean;
  showExternalRegistryWarning: boolean;
  relatedOrganisations?: SelectData;
  errors: ServiceError[];
}

export const useEditDocumentVersionModal = ({
  show,
  closeModal,
  editDocumentUuid,
}: UseEditDocumentVersionModalProps): UseEditDocumentVersionModalReturnData => {
  const [errors, setErrors] = useState<ServiceError[]>([]);
  const { projectDetails } = useContext(ProjectContext);
  const { projectDocumentUuid, refreshDocuments, setRefreshDocuments } = useContext(DocumentContext);

  const [visibility, setVisibility] = useState<boolean>();
  const [initialVisibility, setInitialVisibility] = useState<boolean>();
  const [rowVersion, setRowVersion] = useState<number>(0);
  const [developer, setDeveloper] = useState<string>();
  const [relatedOrganisations, setRelatedOrganisations] = useState<SelectData>();
  const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>();
  const [isEditDocumentLoading, setIsEditDocumentLoading] = useState(false);
  const [showPublicWarning, setShowPublicWarning] = useState(false);
  const [showExternalRegistryWarning, setShowExternalRegistryWarning] = useState(false);

  const onCancel = (): void => {
    setErrors([]);

    setDeveloper(undefined);
    setRelatedOrganisations(undefined);
    setSelectedOrganisations(undefined);

    setShowPublicWarning(false);
    setShowExternalRegistryWarning(false);

    closeModal();
  };

  const getDocumentDetails = async (): Promise<void> => {
    let currentOrganisations: string[] | undefined;
    if (editDocumentUuid)
      await getProjectDocumentVersion({ projectDocumentHistoryUuid: editDocumentUuid }).then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentOrganisations = response.data?.relatedOrganisations.map((r) => r.uuid!);
        setRowVersion(response.data?.rowVersion || 0);
        setVisibility(response.data?.visibility === "Private");
        setInitialVisibility(response.data?.visibility === "Private");
      });
    if (projectDetails?.uuid) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.results = {
        role: {
          operator: "in",
          value: "Developer, Guest - Write, Guest - Read",
        },
      };

      await searchProjectOrganisations({
        projectUuid: projectDetails.uuid,
        paging: { beforeCursor: null, afterCursor: null, limit: 50 },
        filter: filterCriteria,
      }).then((response) => {
        const developerUuid = response.data?.results.find((r) => r.role === "Developer")?.organisationUuid;
        setDeveloper(developerUuid);
        // If set remove developer
        if (developerUuid) {
          setSelectedOrganisations(currentOrganisations?.filter((f) => f !== developerUuid));
        } else {
          setSelectedOrganisations(currentOrganisations);
        }
        const guestOrganistions = response.data?.results.filter((f) => f.role !== "Developer");
        setRelatedOrganisations(
          guestOrganistions?.map((r) => ({ key: r.organisationUuid, value: r.displayName })) as SelectData
        );
      });
    }
  };

  // Check for ExternalRegistry document
  const checkExternalRegistry = async (isPublic: boolean): Promise<boolean> => {
    if (projectDocumentUuid)
      return searchProjectDocumentHistory({
        projectDocumentUuid,
        paging: { beforeCursor: null, afterCursor: null, limit: 50 },
      }).then((response) => {
        if (
          response.data?.results.find(
            (r) =>
              r.projectDocument.standardApproved && r.projectDocument.visibility === (isPublic ? "Public" : "Private")
          )
        ) {
          return true;
        }
        return false;
      });
    return false;
  };

  const editDocument = useIsLoadingWrapper(async (): Promise<void> => {
    if (initialVisibility && !visibility && !showExternalRegistryWarning) {
      if (await checkExternalRegistry(true)) {
        setShowExternalRegistryWarning(true);
        return;
      }
    }

    if (!initialVisibility && visibility && !showPublicWarning) {
      if (await checkExternalRegistry(false)) {
        setShowPublicWarning(true);
        return;
      }
    }

    if (!editDocumentUuid) {
      setErrors([
        {
          code: "NO_DOCUMENT",
          message: "A document must be selected",
        },
      ]);
    } else {
      const targetOrganisations =
        selectedOrganisations != null ? selectedOrganisations.concat(developer || "") : [developer || ""];

      await updateProjectDocumentVersion({
        projectDocumentHistoryUuid: editDocumentUuid,
        visibility: visibility ? "Private" : "Public",
        targetOrganisationUuids: visibility ? targetOrganisations || [] : null,
        rowVersion,
      }).then((response) => {
        if (response.status === Status.Success) {
          Toast.success({ message: "Document version successfully updated" });

          setRefreshDocuments(!refreshDocuments);
          onCancel();
        }
        if (response.status === Status.Error && response.errors) {
          setErrors(response.errors.map((error) => ({ ...error, message: getErrorMessageFromCode(error.code) })));
        }
      });
    }
  }, setIsEditDocumentLoading);

  useEffect(() => {
    getDocumentDetails();
  }, [editDocumentUuid, show === true]);

  return {
    editDocument,
    onCancel,
    visibility,
    setVisibility,
    isEditDocumentLoading,
    relatedOrganisations,
    selectedOrganisations,
    setSelectedOrganisations,
    showPublicWarning,
    showExternalRegistryWarning,
    errors,
  };
};
