import { useCallback, useEffect, useState } from "react";

import { SelectData } from "../../../../../models";
import { lookUp } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";

interface UseEditableBasicReturnData {
  countries: SelectData | undefined;
}

export const useEditableBasic = (): UseEditableBasicReturnData => {
  const [countries, setCountries] = useState<SelectData | undefined>();

  const fetchData = useCallback(async () => {
    const countriesRes = await lookUp({ type: "Country", sort: [{ key: "valueString", direction: "asc" }] });

    if (countriesRes.status === Status.Success && countriesRes.data) {
      const filteredCountries = countriesRes.data.filter((country) => country.key.includes("GB-"));

      setCountries(
        filteredCountries.map((country) => ({
          key: country.key,
          value: country.valueString,
        }))
      );
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    countries,
  };
};
