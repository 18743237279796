import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { searchProjectDocumentTypes, searchProjectDocumentTypeVariants } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getSettingsDocumentTypesRoute } from "../../../../../utils/routes";

interface VariantInfo {
  name: string;
  description: string;
}

interface UseViewReturnData {
  name?: string;
  description?: string;
  supportedMimeTypes?: string;
  variants?: VariantInfo[];
  dataIsLoading: boolean;
  handleBackClick: () => void;
}

type ViewPageRouteParams = "typeUuid";

export const useView = (): UseViewReturnData => {
  const navigate = useNavigate();

  const { typeUuid } = useParams<ViewPageRouteParams>();

  const { currentUserType } = useAuth();
  const [dataIsLoading, setDataIsLoading] = useState(true);

  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [supportedMimeTypes, setSupportedMimeTypes] = useState<string>();
  const [variants, setVariants] = useState<VariantInfo[]>();

  const fetchData = useCallback(async () => {
    const documentTypesRes = await searchProjectDocumentTypes({
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 10,
      },
      filter: { results: { uuid: { operator: "eq", value: typeUuid || "" } } },
    });

    if (documentTypesRes.status === Status.Success && documentTypesRes.data) {
      setName(documentTypesRes.data?.results[0].name);
      setDescription(documentTypesRes.data?.results[0].description || undefined);
      setSupportedMimeTypes(documentTypesRes.data?.results[0].supportedMimeTypes.join(" / "));
    }

    const documentVariantsRes = await searchProjectDocumentTypeVariants({
      paging: { beforeCursor: null, afterCursor: null, limit: 50 },
      filter: {
        results: {
          typeUuid: {
            operator: "eq",
            value: typeUuid || "",
          },
        },
      },
    });

    if (documentVariantsRes.status === Status.Success && documentVariantsRes.data) {
      setVariants(
        documentVariantsRes.data.results.map((v) => ({ name: v.name, description: v.description }) as VariantInfo)
      );
    }

    setDataIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    name,
    description,
    supportedMimeTypes,
    variants,
    dataIsLoading,
    handleBackClick: () => navigate(getSettingsDocumentTypesRoute(currentUserType)),
  };
};
