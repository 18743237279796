import "./Modal.css";

import React, { ReactNode } from "react";

import { CloseDarkIcon } from "../../../assets";
import { Backdrop } from "../Backdrop";
import { useModal } from "./useModal";

export interface ModalProps {
  show: boolean;
  title: string;
  onClose?: () => void;
  onBackdrop?: () => void;
  children: ReactNode[] | ReactNode;
  hasFullWidthTitle?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  show,
  title,
  children,
  onClose,
  onBackdrop,
  hasFullWidthTitle = false,
}): JSX.Element | null => {
  const { modalRefCallBack } = useModal({ show });

  return show ? (
    <>
      <div ref={modalRefCallBack} className="Modal">
        <button className="ModalCloseIcon" onClick={onClose}>
          <CloseDarkIcon width={16} height={18} />
        </button>
        <h1 className={`${hasFullWidthTitle ? "FullWidthTitle" : ""}`}>{title}</h1>
        <div className="ModalContent">{children}</div>
      </div>
      <Backdrop show={show} onClick={onBackdrop} />
    </>
  ) : null;
};
