import "./Divider.css";

interface DividerProps {
  type: "horizontal" | "vertical";
  className?: string;
  thickness?: number;
}

export const Divider = ({ className, type, thickness = type === "vertical" ? 4 : 1 }: DividerProps): JSX.Element => {
  return (
    <div
      className={`${className || ""} Divider_${type}`}
      style={{
        width: `${type === "vertical" ? `${thickness}px` : "none"}`,
        height: `${type === "horizontal" ? `${thickness}px` : "none"}`,
      }}
    />
  );
};
