import { PillVariant } from "../../widget";

export class ProjectAccessConstants {
  public static readonly STATUS_SENT = "Sent";

  public static readonly STATUS_ACCEPTED = "Accepted";

  public static readonly STATUS_REJECTED = "Rejected";

  public static readonly STATUS_EXPIRED = "Expired";

  public static readonly STATUS_REVOKED = "Revoked";

  public static readonly STATUS_PENDING = "Pending";

  public static readonly ACCESS_LEVEL_ROLE_OPTIONS = [
    { key: "Guest - Read", value: "Guest - Read" },
    { key: "Guest - Write", value: "Guest - Write" },
  ];

  public static readonly INVITATION_RESPONSE = {
    ACCEPT: "Accept",
    REJECT: "Reject",
  };

  public static readonly PROJECT_ACCESS_STATUS_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
    Sent: "cyan",
    Accepted: "green",
    Rejected: "red",
    Expired: "coral",
    Revoked: "grey",
    Pending: "yellow",
  };
}
