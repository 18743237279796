import "./DocumentHistory.css";

import { DeveloperSingleCardTemplate } from "../../../../../templates";
import { Button, CursorDataGrid, ReadonlyText } from "../../../../../widget";
import { useDocumentHistory } from "./useDocumentHistory";

export const DocumentHistory = (): JSX.Element => {
  const {
    activityDisplayName,
    documentType,
    documentVariant,
    onDownloadLatest,
    handleBackClick,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    isOnDownloadLatestLoading,
    onChange,
  } = useDocumentHistory();
  return (
    <div className="ProjectDocumentHistoryDetails">
      {activityDisplayName && documentType && (
        <DeveloperSingleCardTemplate
          pageTitle={activityDisplayName}
          handleBackClick={handleBackClick}
          backButtonText="Back to activity"
          activeElement="Activities"
        >
          <div className="ProjectDocumentHistoryHeader">
            <div>
              <h2>Document details</h2>
            </div>
            <Button
              text="Download Latest"
              variant="tertiary"
              onClick={onDownloadLatest}
              isLoading={isOnDownloadLatestLoading}
              loadingText="Downloading"
            />
          </div>
          <div className="ProjectDocumentHistoryDetails">
            <ReadonlyText label="Document type" value={documentType} />
            <ReadonlyText
              label="Document variant"
              value={documentVariant}
              tooltipHeader="Document variant"
              tooltipText="The document variant distinguishes documents that have been uploaded to the project of the same type."
            />
          </div>
          <h2>Version history</h2>
          <CursorDataGrid
            columns={columns}
            clientSide={false}
            filterable
            onChange={onChange}
            data={[]}
            noDataMessage="No documents to display"
            dataIsLoading={dataIsLoading}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </DeveloperSingleCardTemplate>
      )}
    </div>
  );
};
