import "./PublicTemplate.css";

import React from "react";

import { HeaderActiveElement } from "../../models";
import { HeaderNavigation, PublicFooter } from "../../widget";

export interface PublicTemplateProps {
  children: React.ReactNode;
  activeElement?: HeaderActiveElement;
  isContentUnwrapped?: boolean;
}

export const PublicTemplate = ({ children, activeElement, isContentUnwrapped }: PublicTemplateProps): JSX.Element => {
  return (
    <>
      <HeaderNavigation activeElement={activeElement} />
      <div className="PublicTemplate">
        <div className={isContentUnwrapped ? "PublicTemplateUnwrappedContent" : "PublicTemplateContent"}>
          {children}
        </div>
      </div>
      <PublicFooter />
    </>
  );
};
