import { Dispatch, FormEvent, ForwardedRef, SetStateAction, useContext, useEffect, useRef, useState } from "react";

import { FileUploadHandle, LayeredSelectData, SelectData } from "../../../../../../../models";
import { uploadProjectDocument } from "../../../../../../../service/project";
import {
  GetProjectDetailsResponse,
  getProjectDocumentVersion,
  GetProjectDocumentVersionResponse,
  searchProjectDocumentHistory,
  searchProjectDocuments,
  searchProjectDocumentTypes,
  SearchProjectDocumentTypesResponse,
  searchProjectOrganisations,
} from "../../../../../../../service/query";
import { searchProjectDocumentTypeVariants } from "../../../../../../../service/query/QueryService.full";
import { ServiceError, Status } from "../../../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../../../../utils";
import { dataGridMapFilterCriteria, Toast } from "../../../../../../../widget";
import { ProjectContext } from "../../../ProjectContext";

interface UseUploadDocumentModalProps {
  closeModal: () => void;
  refreshDocuments?: boolean;
  setRefreshDocuments: Dispatch<SetStateAction<boolean>>;
  uploadDocumentUuid?: string;
  uploadDocumentLatestVersionUuid?: string;
}

interface UseUploadDocumentModalReturnData {
  fileUploadRef: ForwardedRef<FileUploadHandle>;
  projectDetails: GetProjectDetailsResponse | undefined;
  latestDocumentDetails: GetProjectDocumentVersionResponse | undefined;
  visibility: boolean | undefined;
  isGroup: boolean | undefined;
  maxFileSize?: number;
  supportsMultipleFiles: boolean;
  documentTypes?: LayeredSelectData;
  documentVariants?: LayeredSelectData;
  selectedDocumentVariant?: string;
  relatedOrganisations?: SelectData;
  isHandleSubmitLoading: boolean;
  handleVisibilityChange: (v: boolean) => void;
  setIsGroup: Dispatch<SetStateAction<boolean | undefined>>;
  setFiles: Dispatch<SetStateAction<File[] | undefined>>;
  setSelectedOrganisations: Dispatch<SetStateAction<string[] | undefined>>;
  handleDocumentTypeChange: (typeUuid: string) => Promise<void>;
  setSelectedDocumentVariant: Dispatch<SetStateAction<string | undefined>>;
  setCustomVariant: Dispatch<SetStateAction<string | undefined>>;
  handleCancel: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  showExistsWarning: boolean;
  showExternalRegistryWarning: boolean;
  errors: ServiceError[];
}

export const useUploadDocumentModal = ({
  closeModal,
  refreshDocuments,
  setRefreshDocuments,
  uploadDocumentUuid,
  uploadDocumentLatestVersionUuid,
}: UseUploadDocumentModalProps): UseUploadDocumentModalReturnData => {
  const fileUploadRef = useRef<FileUploadHandle>(null);

  const { projectDetails } = useContext(ProjectContext);
  const [errors, setErrors] = useState<ServiceError[]>([]);
  const [latestDocumentDetails, setLatestDocumentDetails] = useState<GetProjectDocumentVersionResponse>();
  const [searchDocumentTypesResponse, setSearchDocumentTypesResponse] = useState<SearchProjectDocumentTypesResponse>();
  const [documentTypes, setDocumentTypes] = useState<LayeredSelectData>();
  const [documentVariants, setDocumentVariants] = useState<LayeredSelectData>();
  const [relatedOrganisations, setRelatedOrganisations] = useState<SelectData>();
  const [developer, setDeveloper] = useState<string>();
  const [maxFileSize, setMaxFileSize] = useState<number>();

  const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>();
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>();
  const [selectedDocumentVariant, setSelectedDocumentVariant] = useState<string>();
  const [customVariant, setCustomVariant] = useState<string>();
  const [visibility, setVisibility] = useState<boolean>();
  const [supportsMultipleFiles, setSupportsMultipleFiles] = useState<boolean>(false);
  const [supportedMimeTypes, setSupportedMimeTypes] = useState<string[]>();
  const [isGroup, setIsGroup] = useState<boolean>();
  const [files, setFiles] = useState<File[]>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);
  const [showExistsWarning, setShowExistsWarning] = useState(false);
  const [showExternalRegistryWarning, setShowExternalRegistryWarning] = useState(false);

  const handleCancel = (): void => {
    if (fileUploadRef.current) fileUploadRef.current.clearInput();
    setDocumentTypes(undefined);
    setDocumentVariants(undefined);
    setSelectedOrganisations(undefined);
    setSelectedDocumentType(undefined);
    setSelectedDocumentVariant(undefined);
    setCustomVariant(undefined);
    setVisibility(undefined);
    setIsGroup(undefined);
    setFiles(undefined);
    setSupportsMultipleFiles(false);
    setShowExistsWarning(false);
    setShowExternalRegistryWarning(false);

    setErrors([]);
    closeModal();
  };

  const handleVisibilityChange = (v: boolean): void => {
    setVisibility(v);
    setSelectedOrganisations(undefined);
  };

  const handleDocumentTypeChange = async (type: string): Promise<void> => {
    const typeDetails = searchDocumentTypesResponse?.results.find((d) => d.name === type);
    setMaxFileSize(typeDetails?.maxFileSize != null ? typeDetails.maxFileSize * 0.000001 : 1);
    setSelectedDocumentType(typeDetails?.uuid);
    setSupportedMimeTypes(typeDetails?.supportedMimeTypes);
    setSupportsMultipleFiles(typeDetails?.supportsMultipleFiles || false);
    setDocumentVariants(undefined);
    setSelectedDocumentVariant(undefined);

    if (typeDetails?.supportsVariants) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.results = {
        typeUuid: {
          operator: "eq",
          value: typeDetails.uuid,
        },
      };

      await searchProjectDocumentTypeVariants({
        paging: { beforeCursor: null, afterCursor: null, limit: 50 },
        filter: filterCriteria,
      }).then((response) => {
        if (!typeDetails?.supportsMultipleFiles) {
          setDocumentVariants(
            response.data?.results && response.data?.results.length > 0
              ? [
                  {
                    optionsHeader: typeDetails.name || "",
                    options: response.data?.results.map((v) => ({ key: v.uuid, value: v.name })) as SelectData,
                  },
                  {
                    optionsHeader: { key: "Custom", value: "Custom" },
                    options: [],
                  },
                  {
                    optionsHeader: { key: "N/A", value: "N/A" },
                    options: [],
                  },
                ]
              : [
                  {
                    optionsHeader: { key: "Custom", value: "Custom" },
                    options: [],
                  },
                  {
                    optionsHeader: { key: "N/A", value: "N/A" },
                    options: [],
                  },
                ]
          );
        }
      });
    } else {
      setDocumentVariants(undefined);
    }
  };

  const getDocumentDetails = async (): Promise<void> => {
    if (uploadDocumentLatestVersionUuid) {
      await getProjectDocumentVersion({ projectDocumentHistoryUuid: uploadDocumentLatestVersionUuid }).then(
        (response) => {
          if (response.data?.documentType.maxFileSize != null)
            // eslint-disable-next-line no-unsafe-optional-chaining
            setMaxFileSize(response.data?.documentType.maxFileSize * 0.000001);
          setIsGroup(response.data?.groupId !== null);
          setLatestDocumentDetails(response.data);
          setSupportedMimeTypes(response.data?.documentType.supportedMimeTypes);
        }
      );
    } else {
      setLatestDocumentDetails(undefined);
      setIsGroup(projectDetails?.group !== null ? undefined : false);

      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.results = {
        standard: {
          standardUuid: {
            operator: "in",
            value:
              projectDetails !== undefined && projectDetails?.standard !== undefined
                ? `${projectDetails?.standard.uuid}, null`
                : null,
          },
        },
        isGenerated: {
          operator: "eq",
          value: false,
        },
      };

      await searchProjectDocumentTypes({
        paging: { beforeCursor: null, afterCursor: null, limit: 50 },
        filter: filterCriteria,
      }).then((response) => {
        const generalTypes = response.data?.results
          .filter((r) => r.standard === null)
          .map((r) => ({ key: r.uuid, value: r.name }));
        const standardTypes = response.data?.results
          .filter((r) => r.standard?.standardUuid === projectDetails?.standard.uuid)
          .map((r) => ({ key: r.uuid, value: r.name }));
        setDocumentTypes([
          { optionsHeader: projectDetails?.standard.displayName || "", options: standardTypes as SelectData },
          { optionsHeader: "General", options: generalTypes as SelectData },
        ]);
        setSearchDocumentTypesResponse(response.data);
      });
    }
    if (projectDetails?.uuid) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.results = {
        role: {
          operator: "in",
          value: "Developer, Guest - Write, Guest - Read",
        },
      };

      await searchProjectOrganisations({
        projectUuid: projectDetails.uuid,
        paging: { beforeCursor: null, afterCursor: null, limit: 50 },
        filter: filterCriteria,
      }).then((response) => {
        setDeveloper(response.data?.results.find((r) => r.role === "Developer")?.organisationUuid);
        const guestOrganistions = response.data?.results.filter((f) => f.role !== "Developer");
        setRelatedOrganisations(
          guestOrganistions?.map((r) => ({ key: r.organisationUuid, value: r.displayName })) as SelectData
        );
      });
    }
  };

  const checkExists = async (): Promise<boolean> => {
    const type = searchDocumentTypesResponse?.results.find((r) => r.uuid === selectedDocumentType);

    // selectedDocumentType is undefined, Therefore it's a document version modal, so don't warn user
    if (type === undefined) return false;

    const filterCriteria = dataGridMapFilterCriteria([]);

    if (customVariant) {
      filterCriteria.results = {
        customVariant: {
          operator: "eq",
          value: customVariant,
        },
        type: {
          operator: "eq",
          value: type?.name || "",
        },
      };
    } else if (selectedDocumentVariant) {
      filterCriteria.results = {
        variant: {
          operator: "eq",
          value: selectedDocumentVariant,
        },
        type: {
          operator: "eq",
          value: type?.name || "",
        },
      };
    } else {
      filterCriteria.results = {
        type: {
          operator: "eq",
          value: type?.name || "",
        },
      };
    }

    return searchProjectDocuments({
      groupUuid: isGroup ? projectDetails?.group?.groupUuid || null : null,
      projectUuid: projectDetails?.uuid || "",
      paging: { beforeCursor: null, afterCursor: null, limit: 50 },
      filter: filterCriteria,
    }).then((response) => {
      if (
        (response.data?.results && isGroup && response.data?.results.filter((r) => r.groupId != null).length > 0) ||
        (response.data?.results && !isGroup && response.data?.results.filter((r) => r.groupId == null).length > 0)
      ) {
        return true;
      }
      return false;
    });
  };

  // Check for PublicExternalRegistry document
  const checkPublicExternalRegistry = async (projectDocumentUuid: string): Promise<boolean> => {
    return searchProjectDocumentHistory({
      projectDocumentUuid,
      paging: { beforeCursor: null, afterCursor: null, limit: 50 },
    }).then((response) => {
      if (
        response.data?.results.find(
          (r) => r.projectDocument.standardApproved && r.projectDocument.visibility === "Public"
        )
      ) {
        return true;
      }
      return false;
    });
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    const variantUuid =
      documentVariants && documentVariants[0].options.find((v) => v.value === selectedDocumentVariant)?.key;

    if (
      visibility === undefined ||
      isGroup === undefined ||
      files === undefined ||
      (documentVariants &&
        (selectedDocumentVariant === undefined ||
          (selectedDocumentVariant === "Custom" && customVariant === undefined))) ||
      (uploadDocumentUuid === undefined && selectedDocumentType === undefined)
    ) {
      setErrors([
        {
          code: "MANDATORY_FIELDS",
          message: `Please complete the mandatory fields: Document type, Upload document${
            supportsMultipleFiles ? "'s" : ""
          }${documentVariants ? ", Variant type" : ""}, ${
            documentVariants && selectedDocumentVariant === "Custom" && customVariant === undefined
              ? "Description, "
              : ""
          } Public visibility, Group document`,
        },
      ]);
      return;
    }

    if (!showExistsWarning) {
      if (await checkExists()) {
        setShowExistsWarning(true);
        return;
      }
    }

    if (uploadDocumentUuid && !visibility && !showExternalRegistryWarning) {
      if (await checkPublicExternalRegistry(uploadDocumentUuid)) {
        setShowExternalRegistryWarning(true);
        return;
      }
    }

    const targetOrganisations =
      selectedOrganisations != null ? selectedOrganisations.concat(developer || "") : [developer || ""];

    // if latestDocumentDetails === undefined, then new document, else new version
    await uploadProjectDocument({
      files: files?.map((f) => ({ file: f })) || [],
      visibility: visibility ? "Private" : "Public",
      groupUuid: isGroup ? projectDetails?.group?.groupUuid || null : null,
      projectUuid: isGroup ? null : projectDetails?.uuid || null,
      documentTypeUuid: uploadDocumentUuid ? null : selectedDocumentType || null,
      targetOrganisationUuids: visibility ? targetOrganisations || [] : null,
      // eslint-disable-next-line no-nested-ternary
      documentTypeVariantUuid: uploadDocumentUuid
        ? null
        : customVariant === undefined && supportsMultipleFiles === false
        ? variantUuid?.toString() || null
        : null,
      documentCustomVariant: uploadDocumentUuid ? null : customVariant || null,
      projectDocumentUuid: uploadDocumentUuid || null,
      externalReference: null,
      externalParty: null,
      standardApproved: null,
      isETLRequest: null,
    }).then((response) => {
      if (response.status === Status.Success) {
        Toast.success({ message: "Document uploaded successfully" });

        setRefreshDocuments(!refreshDocuments);
        handleCancel();
      }
      if (response.status === Status.Error && response.errors) {
        setErrors(
          response.errors.map((error) =>
            error.code === "VALIDATION_MIME_TYPE_NOT_SUPPORTED"
              ? { ...error, message: `Only ${supportedMimeTypes?.join(", ")} are supported for this document type` }
              : { ...error, message: getErrorMessageFromCode(error.code) }
          )
        );
      }
    });
  }, setIsHandleSubmitLoading);

  useEffect(() => {
    getDocumentDetails();
  }, [uploadDocumentLatestVersionUuid, closeModal]);

  return {
    fileUploadRef,
    projectDetails,
    latestDocumentDetails,
    visibility,
    isGroup,
    maxFileSize,
    supportsMultipleFiles,
    relatedOrganisations,
    selectedDocumentVariant,
    documentTypes,
    documentVariants,
    isHandleSubmitLoading,
    handleVisibilityChange,
    setIsGroup,
    setFiles,
    setSelectedOrganisations,
    setSelectedDocumentVariant,
    setCustomVariant,
    handleCancel,
    handleDocumentTypeChange,
    handleSubmit,
    showExistsWarning,
    showExternalRegistryWarning,
    errors,
  };
};
