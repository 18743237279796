// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (value: any): boolean => {
  return !!(value && typeof value === "object" && !Array.isArray(value));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDate = (value: any): boolean => {
  return Object.prototype.toString.call(value) === "[object Date]";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPlainObject = (value: any): boolean => {
  if (value === null || typeof value !== "object") {
    return false;
  }
  const proto = Object.getPrototypeOf(value);
  return !proto || proto === Object.prototype;
};
