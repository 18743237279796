import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { PublicProjectTypeParams, RichContentSectionData } from "../../../models";
import { getProjectType } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";

interface UseProjectTypeReturnData {
  dataIsLoading: boolean;
  projectTypeData: ProjectTypeData | undefined;
}

// TODO move these interfaces to another place as it is used in both /route and /templates, more details in the refactor hotlist
export interface ProjectTypeRatings {
  carbonImpact: string | null | undefined;
  nationalPotential: string | null | undefined;
  speedOfImpact: string | null | undefined;
}

export interface ProjectTypeData {
  category: string | null | undefined;
  hero: {
    title: string | null | undefined;
    imageUrl: string | null | undefined;
  };
  aboutTheType: {
    title: string | null | undefined;
    content: string | null | undefined;
  };
  typeRatings: ProjectTypeRatings;
  typesOfProjects: RichContentSectionData;
}

export const useProjectType = (): UseProjectTypeReturnData => {
  const { projectTypeUuid } = useParams<PublicProjectTypeParams>();
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [projectTypeData, setProjectTypeData] = useState<ProjectTypeData | undefined>(undefined);

  const fetchData = useCallback(async (): Promise<void> => {
    if (projectTypeUuid) {
      const res = await getProjectType({
        projectTypeUuid,
      });

      if (res.status === Status.Success && res.data) {
        const mappedProjectTypeData: ProjectTypeData = {
          category: res.data.category,
          hero: {
            title: res.data.displayName,
            imageUrl:
              res.data.listingFiles?.find((file) => file.uuid === res.data?.listing?.content?.hero?.imageFileUuid)?.file
                ?.url || null,
          },
          aboutTheType: {
            title: res.data.listing?.content?.aboutTheType?.title,
            content: res.data.listing?.content?.aboutTheType?.content,
          },
          typeRatings: {
            carbonImpact: res.data.listing?.content?.typeRatings?.carbonImpact,
            nationalPotential: res.data.listing?.content?.typeRatings?.nationalPotential,
            speedOfImpact: res.data.listing?.content?.typeRatings?.speedOfImpact,
          },
          typesOfProjects: {
            enabled: res.data.listing?.content?.typesOfProject?.enabled,
            title: res.data.listing?.content?.typesOfProject?.title,
            part1: res.data.listing?.content?.typesOfProject?.part1
              ? {
                  content: res.data.listing?.content?.typesOfProject?.part1?.content,
                  title: res.data.listing?.content?.typesOfProject?.part1?.title,
                  imageUrl:
                    res.data.listingFiles?.find(
                      (file) => file.uuid === res.data?.listing?.content?.typesOfProject?.part1?.imageFileUuid
                    )?.file?.url || null,
                }
              : null,
            part2: res.data.listing?.content?.typesOfProject?.part2
              ? {
                  content: res.data.listing?.content?.typesOfProject?.part2?.content,
                  title: res.data.listing?.content?.typesOfProject?.part2?.title,
                  imageUrl:
                    res.data.listingFiles?.find(
                      (file) => file.uuid === res.data?.listing?.content?.typesOfProject?.part2?.imageFileUuid
                    )?.file?.url || null,
                }
              : null,
            part3: res.data.listing?.content?.typesOfProject?.part3
              ? {
                  content: res.data.listing?.content?.typesOfProject?.part3?.content,
                  title: res.data.listing?.content?.typesOfProject?.part3?.title,
                  imageUrl:
                    res.data.listingFiles?.find(
                      (file) => file.uuid === res.data?.listing?.content?.typesOfProject?.part3?.imageFileUuid
                    )?.file?.url || null,
                }
              : null,
            part4: res.data.listing?.content?.typesOfProject?.part4
              ? {
                  content: res.data.listing?.content?.typesOfProject?.part4?.content,
                  title: res.data.listing?.content?.typesOfProject?.part4?.title,
                  imageUrl:
                    res.data.listingFiles?.find(
                      (file) => file.uuid === res.data?.listing?.content?.typesOfProject?.part4?.imageFileUuid
                    )?.file?.url || null,
                }
              : null,
            part5: res.data.listing?.content?.typesOfProject?.part5
              ? {
                  content: res.data.listing?.content?.typesOfProject?.part5?.content,
                  title: res.data.listing?.content?.typesOfProject?.part5?.title,
                  imageUrl:
                    res.data.listingFiles?.find(
                      (file) => file.uuid === res.data?.listing?.content?.typesOfProject?.part5?.imageFileUuid
                    )?.file?.url || null,
                }
              : null,
          },
        };
        setProjectTypeData(mappedProjectTypeData);
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    dataIsLoading,
    projectTypeData,
  };
};
