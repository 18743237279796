export interface HasKey {
  key: string;
}

export interface HasSteps {
  steps?: Step[];
  parent?: HasKey & HasSteps;
}

export interface HasComponents {
  components?: Component[];
}

export type ActivityDefinition = HasSteps & HasComponents;

export type Step = HasKey &
  HasSteps &
  HasComponents & {
    label: string;
    conditionExpression?: string;
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComponentProperties = { [index: string]: any };

export type Component = HasKey & {
  component: ComponentType;
  dataType: DataType;
  validation: ComponentValidation[];
  conditionExpression?: string;
  defaultValueExpression?: string;
  componentProperties: ComponentProperties;
  children?: Component[];
};

export type ComponentValidation = {
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  regex?: string;
  validationExpression?: string;
  validationExpressionErrorMessage?: string;
};

export interface HasDataSteps {
  steps?: (HasKey & HasDataSteps & HasData & HasReviews)[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActivityStepDataItem = { [index: string]: any };

export type HasData = {
  data?: ActivityStepDataItem;
  valid: boolean | undefined;
};

export type HasDocuments = {
  documents?: { [index: string]: string | null }[];
};

export type HasDiscussions = {
  discussions?: {
    [index: string]: {
      discussionThreads: string[];
    };
  };
};

export type HasReviews = {
  activityReviews?: {
    assessment?: Review;
    review?: Review;
  };
};

export type ActivityData = HasDataSteps & HasData & HasDocuments;

export type ActivityStepData = HasKey & HasDataSteps & HasData;

export type DataType = "Date" | "File" | "Boolean" | "Number" | "String";

export type TextInputType = "email" | "number" | "text" | "url";

export type ComponentType =
  | "CheckBox"
  | "DateInput"
  | "InformationBox"
  | "MultiFileUpload"
  | "Paragraph"
  | "RadioButtonGroup"
  | "ReadonlyText"
  | "Repeater"
  | "Select"
  | "SingleFileUpload"
  | "Slider"
  | "TableRepeater"
  | "TextArea"
  | "TextInput"
  | "Toggle";

export type Review = {
  createdAt: Date | null;
  createdByUserFullname: string | null;
  createdByUserUuid: string | null;
  isValid?: boolean | null;
};

export type Field = {
  // same as Component above
  key: string;
  component: ComponentType;
  dataType: DataType;
  validation: ComponentValidation[];
  conditionExpression: string;
  defaultValueExpression: string;
  // different
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any;
};

export type ActivityDefinitionInfo = {
  displayName: string;
  versionNumber: string;
};

export type RepeaterComponentRequiredProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any;
};

export type UploadedDocument = {
  activityDefinitionVersionDocumentTypeUuidAndVariant: string;
  // KAN-2918: remove uploaded files
  activityDocumentHistoryUuid: string | null;
};

export type UploadedDocumentEnriched = UploadedDocument & {
  filename: string;
  mimeType: string;
};

export type DocumentVariantSourceType = "Hardcoded" | "Filename" | "Expression" | "HardcodedAndIndex";

export const DocumentVariantSourceConstants: Record<string, DocumentVariantSourceType> = {
  Hardcoded: "Hardcoded",
  Filename: "Filename",
  Expression: "Expression",
  HardcodedAndIndex: "HardcodedAndIndex",
};

// corresponds to the return value within ValidateAndSaveDraftResponse and GetActivityHistoryDetailsResponse
export type ResponseDocumentData = {
  activityDefinitionDocumentType: {
    displayName: string;
    uuid: string;
  };
  activityDocumentHistory: {
    file: {
      filename: string | null;
      mimeType: string | null;
    };
    uuid: string;
    variant: string | null;
    versionNumber: number;
  };
  uuid: string;
};

export type StaticStepType = "Review" | "Introduction";
