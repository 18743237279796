import { PublicBadgeDetailsPageTemplate, PublicTemplate } from "../../../../../templates";
import { useDetails } from "./useDetails";

const Details = (): JSX.Element => {
  const { dataIsLoading, badgeDetailsData } = useDetails();

  return (
    <PublicTemplate activeElement="Project list" isContentUnwrapped>
      {!dataIsLoading && badgeDetailsData && <PublicBadgeDetailsPageTemplate badgeDetailsData={badgeDetailsData} />}
    </PublicTemplate>
  );
};

export default Details;
