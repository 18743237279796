import { Helmet } from "react-helmet-async";

import { CodeDetailsData } from "../../../../route/public/code/details/page/useDetails";

interface PublicCodeDetailsMetaTagsProps {
  codeDetailsData?: CodeDetailsData;
}

export const PublicCodeDetailsMetaTags = ({ codeDetailsData }: PublicCodeDetailsMetaTagsProps): JSX.Element | null => {
  return (
    <Helmet>
      <title>{codeDetailsData?.displayName}</title>
      <meta name="title" content={codeDetailsData?.displayName || undefined} />
      <meta name="description" content={codeDetailsData?.hero?.subtitle || undefined} />
      <script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "Dataset",
            name: codeDetailsData?.displayName,
            description: codeDetailsData?.hero?.subtitle || undefined,
          },
          null,
          "\t"
        )}
      </script>
    </Helmet>
  );
};
