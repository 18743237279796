export type OrganisationFormChangedAction =
  | {
      type: OrganisationFormChangedEnum.SET_ADDRESS_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: OrganisationFormChangedEnum.SET_DETAILS_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: OrganisationFormChangedEnum.SET_ICON_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: OrganisationFormChangedEnum.SET_LOGO_FORM_CHANGED;
      value: boolean;
    };

export enum OrganisationFormChangedEnum {
  SET_ADDRESS_FORM_CHANGED,
  SET_DETAILS_FORM_CHANGED,
  SET_ICON_FORM_CHANGED,
  SET_LOGO_FORM_CHANGED,
}
