/* eslint-disable unused-imports/no-unused-vars,no-console */
import { axios } from "../axios";
import { BaseResponse, BaseResponseWithSingle, Status } from "../Shared";
import { LogErrorRequest, LogErrorResponse } from "./Types";

export const logError = async (req: LogErrorRequest): Promise<BaseResponse> => {
  const data: { message: string; stacktrace?: string; currentUrl: string } = {
    message: "",
    stacktrace: undefined,
    currentUrl: window.location.href,
  };

  if (typeof req.error !== "string") {
    data.message = req.error.message;
    data.stacktrace = req.error.stack;
  } else {
    data.message = req.error;
  }
  return axios
    .request<BaseResponseWithSingle<LogErrorResponse>>({
      method: "post",
      url: "frontenderror",
      data,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};
