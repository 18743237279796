import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { SortColumn } from "react-data-grid";

import { OrganisationTypeConstants } from "../../../../../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../../../../../models";
import { searchProjects, SearchProjectsRequest, SearchProjectsResponse } from "../../../../../../../../service/query";
import { FilterGroups, ResultData } from "../../../../../../../../service/Shared";
import { flattenObject } from "../../../../../../../../utils";
import { getProjectDetailsByUuid } from "../../../../../../../../utils/routes";
import {
  DataGridButtonCellFormatterData,
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
} from "../../../../../../../../widget";
import { GroupContext } from "../../GroupContext";

interface useProjectsSectionReturnData {
  columns: DataGridColumnDefinition[];
  dataIsLoading: boolean;
  defaultSortingCriteria: SortColumn[];
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  refreshGroup: boolean;
  removeProjectUuid: string | undefined;
  showAddProjectModal: boolean;
  setShowAddProjectModal: Dispatch<SetStateAction<boolean>>;
  showRemoveProjectModal: boolean;
  setShowRemoveProjectModal: Dispatch<SetStateAction<boolean>>;
}

export const useProjectsSection = (): useProjectsSectionReturnData => {
  const { groupDetails, refreshGroup } = useContext(GroupContext);

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [showRemoveProjectModal, setShowRemoveProjectModal] = useState(false);
  const [removeProjectUuid, setRemoveProjectUuid] = useState<string | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "link",
      name: "Project",
      dataType: "string",
      formatter: "link",
      alignment: "left",
      filterable: true,
      sortable: true,
      minWidth: 100,
    },
    {
      key: "locationGridReference",
      name: "Grid reference",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: true,
      sortable: true,
      minWidth: 150,
    },
    { key: "areaNetHa", name: "Net area (ha)", dataType: "number", filterable: true, sortable: true },
    {
      key: "startDate",
      name: "Start date",
      dataType: "Date",
      formatter: "dateOnly",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
    { key: "duration", name: "Duration (years)", dataType: "number", filterable: false, sortable: false },
    { key: "cachedPiuQuantity", name: "PIUs", dataType: "number", filterable: true, sortable: true },
    { key: "cachedVcuQuantity", name: "VCUs", dataType: "number", filterable: true, sortable: true },
    {
      key: "removeButton",
      name: "",
      dataType: "string",
      formatter: "button",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "link", direction: "ASC" }];

  const formatData = useCallback(
    (responseData: SearchProjectsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = {
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid, OrganisationTypeConstants.DEVELOPER),
        } as unknown as DataGridButtonLinkCellFormatterData;

        result.removeButton = <DataGridButtonCellFormatterData>{
          text: "Remove",
          variant: "secondary",
          onClick: () => {
            setShowRemoveProjectModal(true);
            setRemoveProjectUuid(el.uuid);
          },
        };
        return result;
      }) || [],
    [refreshGroup]
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 10,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const request: SearchProjectsRequest & FilterGroups<SearchProjectsRequest["filter"]> = {
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key !== "link" ? s.key : "displayName",
        direction: s.direction,
      })),
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.group = {
      groupUuid: {
        operator: "eq",
        value: groupDetails?.groupGuid,
      },
    };

    request.filter = { results: filterCriteria };

    if (filtering.find((f) => f.key === "link")) {
      const operator = filtering.find((f) => f.key === "link")?.operator;
      const filterOperatorWithNot = ["neq", "ncontains"];

      request.filterGroups = [
        {
          // operator is changed to "and" because the operators above do not return correctly with "or"
          operator: filterOperatorWithNot.find((fo) => fo === operator) ? "and" : "or",
          filter: {
            results: {
              displayName: {
                operator: operator || "contains",
                value: filterCriteria.link.value,
              },
              name: {
                operator: operator || "contains",
                value: filterCriteria.link.value,
              },
            },
          },
        },
      ];
    }

    delete filterCriteria.link;

    await searchProjects(request)
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  return {
    columns,
    dataIsLoading,
    defaultSortingCriteria,
    onChange,
    refreshGroup,
    removeProjectUuid,
    showAddProjectModal,
    setShowAddProjectModal,
    showRemoveProjectModal,
    setShowRemoveProjectModal,
  };
};
