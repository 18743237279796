import "./AuthenticationTemplate.css";

import React from "react";

import { KanaDarkLogo } from "../../assets";
import { Column, Footer, Row } from "../../widget";

export interface AuthenticationTemplateProps {
  imageSrc: string;
  children: React.ReactNode;
}

export const AuthenticationTemplate: React.FC<AuthenticationTemplateProps> = ({ imageSrc, children }): JSX.Element => {
  return (
    <div className="AuthenticationScreen">
      <div className="AuthenticationContainer">
        <div className="AuthenticationLogo">
          <KanaDarkLogo width={104} height={24} />
        </div>
        <div className="AuthenticationContent">
          <Row>
            <Column span={8}>{children}</Column>
          </Row>
        </div>
        <div className="AuthenticationFooter">
          <Footer />
        </div>
      </div>
      <div className="AuthenticationImage" style={{ backgroundImage: `url(${imageSrc})` }} />
    </div>
  );
};
