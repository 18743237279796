export class MarketingAssetsConstants {
  public static readonly ABOUT_US_CONTENT_MAX_LENGTH = 1000;

  public static readonly HERO_SUBTITLE_MAX_LENGTH = 1000;

  public static readonly HERO_TITLE_MAX_LENGTH = 90;

  public static readonly OUR_VALUES_CONTENT_MAX_LENGTH = 1000;

  /* 'Our Expertise' and 'Codes and Standards' forms used only in VVB Marketing Assets */
  public static readonly OUR_EXPERTISE_CONTENT_MAX_LENGTH = 1000;

  public static readonly CODES_AND_STANDARDS_SUMMARY_MAX_LENGTH = 1000;

  /* Section component used in 'Our Services' and 'Our Approach' forms */
  public static readonly SECTION_CONTENT_MAX_LENGTH = 1000;

  public static readonly SECTION_TITLE_MAX_LENGTH = 90;
}
