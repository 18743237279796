import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";

import { MapMarkerSVG } from "../../../../assets";
import { MapConstants } from "../constants";
import { useKanaMap } from "./useKanaMap";

interface KanaMapProps {
  coordinates: string[];
  fullWidth: boolean;
  mode: "terrain" | "satellite" | "hybrid" | "roadmap";
  height: number;
}

export const KanaMap = ({ coordinates, fullWidth, mode, height }: KanaMapProps): JSX.Element => {
  const { coordinatesList, center } = useKanaMap(coordinates, fullWidth);

  return (
    <Map
      mapId="58aaea6d244cb261"
      minZoom={MapConstants.MIN_ZOOM_LEVEL}
      maxZoom={MapConstants.MAX_ZOOM_LEVEL}
      defaultZoom={MapConstants.DEFAULT_ZOOM_LEVEL}
      fullscreenControl={false}
      streetViewControl={false}
      zoomControlOptions={{ position: MapConstants.ZOOM_CONTROL_POSITION }}
      defaultCenter={center}
      style={{ height }}
      mapTypeId={mode}
    >
      {coordinatesList.map((coordinate) => (
        <AdvancedMarker
          key={coordinate[0] + coordinate[1]}
          position={{
            lat: coordinate[0],
            lng: coordinate[1],
          }}
        >
          <MapMarkerSVG />
        </AdvancedMarker>
      ))}
    </Map>
  );
};
