import "./LeftNavigation.css";

import { Link } from "react-router-dom";

import { NavigationActiveElement } from "../../../models";
import { NavigationTooltip } from "./components";
import { NavigationAction } from "./models";
import { useLeftNavigation } from "./useLeftNavigation";

interface LeftNavigationProps {
  activeElement?: NavigationActiveElement;
}

export const LeftNavigation = ({ activeElement }: LeftNavigationProps): JSX.Element => {
  const { navigationLogo, navigationActions, currentUserType, navigationFooter } = useLeftNavigation();

  const navigationElement = (action: NavigationAction): JSX.Element => {
    return action?.isDisabled ? (
      <div
        key={action?.tooltipText}
        className={`NavigationElement${action?.tooltipText === activeElement ? " NavigationElement_active" : ""}
        `}
      >
        <NavigationTooltip text={action?.tooltipText}>{action?.icon}</NavigationTooltip>
      </div>
    ) : (
      <Link
        key={action?.tooltipText}
        to={action?.route}
        className={`NavigationElement${action?.tooltipText === activeElement ? " NavigationElement_active" : ""}
        `}
      >
        <NavigationTooltip text={action?.tooltipText}>{action?.icon}</NavigationTooltip>
      </Link>
    );
  };

  return (
    <nav className={`Navigation ${currentUserType}Navigation`}>
      <Link to={navigationActions[0].route}>{navigationLogo}</Link>
      <div className="NavigationContainer">{navigationActions.map((action) => navigationElement(action))}</div>
      {navigationFooter}
    </nav>
  );
};
