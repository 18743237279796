import { useState } from "react";

interface UseTooltipReturnData {
  isVisible: boolean;
  handleVisibleChange: (visible: boolean) => void;
}

export const useTooltip = (): UseTooltipReturnData => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibleChange = (visible: boolean): void => {
    setIsVisible(visible);
  };

  return { isVisible, handleVisibleChange };
};
