import "./SingleFileUploadV2.css";

import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { ReactNode } from "react";

import { DownloadDarkIcon, EyeBlueIcon } from "../../../../assets";
import { PreviewFile } from "../../../../models";
import { canPreviewFile } from "../../../../utils";
import { Button, FormLabel, LoadingSpinner, Logo } from "../../..";
import { useSingleFileUploadV2 } from "./useSingleFileUploadV2";

export interface SingleFileUploadPropsV2 {
  value?: PreviewFile;
  label?: string;
  allowLabelDangerousHtml?: boolean;
  btnLabel?: string;
  maxFileSize?: number;
  informationLabel?: string;
  disabled?: boolean;
  hasPreview: boolean;
  previewLabel?: string;
  tooltip?: ReactNode;
  accept?: string;
  onChange: (e: PreviewFile | undefined) => void;
  onFileUpload?: (e: RcFile, repeaterIndex?: number) => Promise<PreviewFile>;
  onPreviewFile?: (previewFile: PreviewFile) => void;
}

export const SingleFileUploadV2 = ({
  value,
  label = "Single file upload",
  allowLabelDangerousHtml,
  btnLabel = "Choose a file",
  maxFileSize = 1,
  informationLabel,
  disabled = false,
  hasPreview = false,
  previewLabel = "Image preview",
  tooltip,
  accept = "*",
  onChange,
  onFileUpload,
  onPreviewFile,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line react/prop-types
  repeaterIndex,
}: SingleFileUploadPropsV2): JSX.Element => {
  const { id, isFileUploading, selectedFile, onAction } = useSingleFileUploadV2({
    value,
    onChange,
    onFileUpload,
    maxFileSize,
    repeaterIndex,
  });

  return (
    <div className="SingleFileUpload">
      <div
        className={`form_field SingleFileUploadInputWrapper ${
          disabled || isFileUploading ? "SingleFileUploadInputWrapper_disabled" : ""
        }`}
      >
        <FormLabel label={label} tooltip={tooltip} allowDangerousHtml={allowLabelDangerousHtml} />
        <Upload
          action={onAction}
          multiple
          accept={accept}
          disabled={isFileUploading || disabled}
          id={id}
          customRequest={() => null} // Removing default component request by providing an empty one
        >
          <div className="SingleFileUploadInput">
            <span className="SingleFileUploadInputFileBox body2">{selectedFile && selectedFile.filename}</span>
            {isFileUploading ? (
              <div className="SingleFileUploadInputBtnV2 TextWithLoadingSpinner">
                <p className="body2">Uploading</p>
                <LoadingSpinner />
              </div>
            ) : (
              <span className="SingleFileUploadInputBtnV2 body2">{btnLabel}</span>
            )}
          </div>
        </Upload>
        <span className="body2">{informationLabel ?? `Max file size ${maxFileSize}MB`}</span>
      </div>
      {onPreviewFile && selectedFile && !isFileUploading && (
        <div className="SingleFileUploadPreviewBtn">
          <Button
            variant="tertiary"
            icon={canPreviewFile(selectedFile.mimeType) ? <EyeBlueIcon /> : <DownloadDarkIcon />}
            iconPosition="left"
            text={canPreviewFile(selectedFile.mimeType) ? "Preview file" : "Download file"}
            onClick={() => onPreviewFile(selectedFile)}
          />
        </div>
      )}
      {hasPreview && selectedFile?.url && (
        <div className="SingleFileUploadPreview">
          <Logo displayedLogo={selectedFile.url} displayedLabel={previewLabel} />
        </div>
      )}
    </div>
  );
};
