import "./Discussion.css";

import { Dispatch, SetStateAction } from "react";

import { Thread } from "../models";
import { DiscussionThread, NewDiscussion } from "./components";
import { NewThreadInformation } from "./components/NewDiscussion/useNewDiscussion";
import { useDiscussion } from "./useDiscussion";

interface DiscussionProps {
  internalThreads: Thread[];
  externalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  setExternalThreads: Dispatch<SetStateAction<Thread[]>>;
  closeDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
  isForViewMode?: boolean;
}

export const Discussion = ({
  internalThreads,
  externalThreads,
  setInternalThreads,
  setExternalThreads,
  closeDiscussion,
  newThreadInformation,
  isForViewMode = false,
}: DiscussionProps): JSX.Element => {
  const {
    discussionRef,
    showNewDiscussion,
    internalTeamSelected,
    externalSelected,
    externalUserType,
    canWriteExternalDiscussion,
    handleInternalTeamSelect,
    handleExternalSelect,
  } = useDiscussion({ internalThreads, externalThreads, closeDiscussion });

  return (
    <div ref={discussionRef} className="DiscussionContainer">
      <div className={`DiscussionCard ${showNewDiscussion ? "VisibleOverflow" : ""}`}>
        {showNewDiscussion ? (
          <NewDiscussion
            threads={internalTeamSelected ? internalThreads : externalThreads}
            setThreads={internalTeamSelected ? setInternalThreads : setExternalThreads}
            internalTeamSelected={internalTeamSelected}
            externalSelected={externalSelected}
            handleInternalTeamSelect={handleInternalTeamSelect}
            handleExternalSelect={handleExternalSelect}
            closeDiscussion={closeDiscussion}
            newThreadInformation={newThreadInformation}
            externalUserType={externalUserType}
            canWriteExternal={canWriteExternalDiscussion}
          />
        ) : (
          <DiscussionThread
            threads={internalTeamSelected ? internalThreads : externalThreads}
            setThreads={internalTeamSelected ? setInternalThreads : setExternalThreads}
            internalTeamSelected={internalTeamSelected}
            externalSelected={externalSelected}
            handleInternalTeamSelect={handleInternalTeamSelect}
            handleExternalSelect={handleExternalSelect}
            closeDiscussion={closeDiscussion}
            newThreadInformation={newThreadInformation}
            isForViewMode={isForViewMode}
            externalUserType={externalUserType}
            canWriteExternal={canWriteExternalDiscussion}
          />
        )}
      </div>
    </div>
  );
};
