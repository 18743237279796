import { CheckCircleDarkIcon, CheckCircleGreenIcon, EllipsisDarkIcon, TrashDark } from "../../../../../../assets";
import { CellFormatter } from "../../../../../../widget/data/DataGrid/models";
import { DropdownButton, DropdownItem } from "../../../../../../widget/forms/DropdownButton";

export type DiscussionThreadActionCellFormatterData = {
  onResolve: () => void;
  onDelete: () => void;
  isResolved: string;
  showResolveOption: boolean;
  showDeleteOption: boolean;
};

const resolvedIcon = (): JSX.Element => (
  <div title="Resolved" className="ResolvedIcon">
    <CheckCircleGreenIcon />
  </div>
);

const threadActionsDropdown = (
  showResolveOption: boolean,
  showDeleteOption: boolean,
  handleMarkResolved?: () => void,
  handleDelete?: () => void
): JSX.Element => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {(showResolveOption || showDeleteOption) && (
      <DropdownButton component={<EllipsisDarkIcon />}>
        {showResolveOption && (
          <DropdownItem onClick={handleMarkResolved}>
            <CheckCircleDarkIcon />
            <span>Mark as resolved</span>
          </DropdownItem>
        )}
        {showDeleteOption && (
          <DropdownItem onClick={handleDelete}>
            <TrashDark />
            <span>Delete thread</span>
          </DropdownItem>
        )}
      </DropdownButton>
    )}
  </>
);

export const DiscussionThreadActionCellFormatter: CellFormatter<
  DiscussionThreadActionCellFormatterData | null | undefined
> = () => {
  return (value) => {
    if (value?.isResolved) {
      return resolvedIcon();
    }
    return threadActionsDropdown(
      value?.showResolveOption ?? false,
      value?.showDeleteOption ?? false,
      value?.onResolve,
      value?.onDelete
    );
  };
};
