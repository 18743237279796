import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { downloadProjectDocument } from "../../../../service/project";
import { getProjectDocuments, GetProjectDocumentsResponse } from "../../../../service/publicquery";
import { Status } from "../../../../service/Shared";
import { initiateDocumentDownloadViaBrowser } from "../../../../utils";
import { usePublicContext } from "../../usePublicContext";

interface useDocumentsReturnData {
  documents: GetProjectDocumentsResponse[] | undefined;
  isLoading: boolean;
  downloadDocument: (projectDocumentHistoryUuid: string) => Promise<void>;
}

export const useDocuments = (): useDocumentsReturnData => {
  const { uuid } = useParams();
  const { projectDetails } = usePublicContext();
  const [documents, setDocuments] = useState<GetProjectDocumentsResponse[]>();
  const [isLoading, setIsLoading] = useState(true);

  const downloadDocument = async (projectDocumentHistoryUuid: string): Promise<void> => {
    const res = await downloadProjectDocument({ projectDocumentHistoryUuid });

    initiateDocumentDownloadViaBrowser(res);
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (projectDetails) {
        await getProjectDocuments({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          projectUuid: uuid!,
          groupUuid: projectDetails?.group?.groupUuid || null,
        })
          .then((response) => {
            if (response.status === Status.Success) {
              setDocuments(response.data);
            }
          })
          .finally(() => setIsLoading(false));
      }
    };

    fetchData();
  }, [projectDetails]);

  return {
    documents,
    isLoading,
    downloadDocument,
  };
};
