import "./VerifierDetails.css";

import { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";

import { ArrowFullLeftDarkIcon } from "../../../../assets";
import { OrganisationTypeConstants } from "../../../../constants";
import { VerifierDetailsData } from "../../../../route/public/verifier/details/page/useDetails";
import { getHostFromURL } from "../../../../utils";
import { Button, Column, Logo, Pill, Row } from "../../../../widget";
import {
  AboutUsSection,
  AtAGlanceSection,
  ContactUsModal,
  ContentSection,
  ModalButton,
  RichContentSection,
  RichListContentSection,
  SocialMediaSection,
} from "../../../components";

interface VerifierDetailsProps {
  data?: VerifierDetailsData;
  verifierUuid: string | undefined | null;
  isPreview: boolean;
  navigateBackToProject?: () => void;
  showContactUsModal?: boolean;
  setShowContactUsModal?: Dispatch<SetStateAction<boolean>>;
}

export const VerifierDetails = ({
  data,
  verifierUuid,
  isPreview,
  navigateBackToProject,
  showContactUsModal,
  setShowContactUsModal,
}: VerifierDetailsProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get("projectUuid");

  return (
    <div className="VerifierDetails">
      {data?.hero.enabled && (
        <div
          className="HeroSectionOrganisationImage"
          style={{
            backgroundImage: data?.hero?.imageUrl !== undefined ? `url(${data?.hero?.imageUrl})` : undefined,
          }}
        />
      )}
      <div className="VerifierDetailsPageTemplate">
        {data?.hero.enabled ? (
          <>
            {data?.logo && (
              <div className="HeroSectionOrganisationLogo">
                <Logo displayedLogo={data?.logo} />
              </div>
            )}
            {!isPreview && setShowContactUsModal && (
              <div className="PreviewButtonsContainer">
                <div className={projectUuid ? "" : "HideBackToProjectButton"}>
                  <Button
                    text="Back to project"
                    variant="secondary"
                    icon={<ArrowFullLeftDarkIcon />}
                    iconPosition="left"
                    onClick={navigateBackToProject}
                  />
                </div>
                {data.calculatedContactable && (
                  <ModalButton overrideText="Contact us" onClick={() => setShowContactUsModal(true)} />
                )}
              </div>
            )}
            <Row spacingV="ml" justify="center">
              <Column span={12}>
                <h1>{data?.displayName}</h1>
              </Column>
            </Row>
            <Row spacingV="m" justify="center">
              <Column span={10}>
                <h2>{data?.hero?.title}</h2>
              </Column>
            </Row>
            <Row spacingV="ml" justify="center">
              <Column span={12}>
                <span className="body1">{data?.hero?.subtitle}</span>
              </Column>
            </Row>
            {data?.websiteUrl && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <Pill variant="blue" label={getHostFromURL(data?.websiteUrl)} link={data?.websiteUrl} />
                </Column>
              </Row>
            )}
            <SocialMediaSection isPreview={isPreview} socialMedia={data.socialMediaData} />
          </>
        ) : (
          <>
            {!isPreview && (
              <div
                className={` ${
                  data?.logo ? "NoHeroSectionPreviewButtonsContainer" : "NoHeroSectionNoCodeLogoPreviewButtonsContainer"
                }`}
              >
                <div className={projectUuid ? "" : "HideBackToProjectButton"}>
                  <Button
                    text="Back to project"
                    variant="secondary"
                    icon={<ArrowFullLeftDarkIcon />}
                    iconPosition="left"
                    onClick={navigateBackToProject}
                  />
                </div>
              </div>
            )}
            {data?.logo && (
              <div className="NoHeroSectionOrganisationLogo">
                <Logo displayedLogo={data?.logo} />
              </div>
            )}
            <Row spacingV="ml" justify="center">
              <Column span={12}>
                <h1>{data?.displayName}</h1>
              </Column>
            </Row>
            {data?.websiteUrl && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <Pill variant="blue" label={getHostFromURL(data?.websiteUrl)} link={data?.websiteUrl} />
                </Column>
              </Row>
            )}
            {data?.socialMediaData && <SocialMediaSection isPreview={isPreview} socialMedia={data.socialMediaData} />}
          </>
        )}
        {data?.ourExpertise?.enabled && data?.ourExpertise?.content && (
          <Row spacingV="ml">
            <Column span={12}>
              <ContentSection title="Our expertise" content={data.ourExpertise.content} />
            </Column>
          </Row>
        )}
        {data?.standards?.enabled && !!data?.standards?.sectionDataList.length && (
          <Row spacingV="ml">
            <Column span={12}>
              <RichListContentSection
                title="Codes and Standards"
                content={data.standards.content}
                sectionDataList={data.standards.sectionDataList}
              />
            </Column>
          </Row>
        )}
        {data?.atAGlance?.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <AtAGlanceSection organisationUuid={verifierUuid} organisationType={OrganisationTypeConstants.VERIFIER} />
            </Column>
          </Row>
        )}
        {data?.ourServices?.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <RichContentSection title="Our services" sectionData={data.ourServices} />
            </Column>
          </Row>
        )}
        {data?.aboutUs?.content && data?.aboutUs?.imageUrl && (
          <Row>
            <Column span={12}>
              <AboutUsSection content={data.aboutUs.content} imageUrl={data.aboutUs.imageUrl} />
            </Column>
          </Row>
        )}
      </div>
      {data?.displayName && showContactUsModal !== undefined && setShowContactUsModal && (
        <ContactUsModal
          organisationType={OrganisationTypeConstants.VERIFIER}
          developerName={data?.displayName}
          developerPhoneNumber={data?.contactPhone}
          show={showContactUsModal}
          closeModal={() => {
            setShowContactUsModal(false);
          }}
        />
      )}
    </div>
  );
};
