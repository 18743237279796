import "./ProjectProcessSection.css";

import { ProjectProcessData } from "../../../../route/public/code/models";

interface ProjectProcessSectionProps {
  projectProcess: Omit<ProjectProcessData, "enabled">;
}

export const ProjectProcessSection = ({ projectProcess }: ProjectProcessSectionProps): JSX.Element => {
  return (
    <div className="ProjectProcessSection">
      <h2>Project process</h2>
      <p className="body1">{projectProcess.content}</p>
      {projectProcess.codeUrl && (
        <a href={projectProcess.codeUrl} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      )}
      {projectProcess.imageUrl && <img src={projectProcess.imageUrl} alt="Project process" />}
    </div>
  );
};
