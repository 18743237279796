import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getPublicCodeDetailsRoute, getPublicProjectOverviewRoute } from "../../utils/routes";

interface UsePublicProjectTypeTemplateReturnData {
  codeUuid: string | null;
  projectUuid: string | null;
  navigateBackToCode: () => void;
  navigateBackToProject: () => void;
}

export const usePublicProjectTypeTemplate = (): UsePublicProjectTypeTemplateReturnData => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const codeUuid = useMemo(() => {
    return searchParams.get("codeUuid");
  }, [searchParams]);

  const projectUuid = useMemo(() => {
    return searchParams.get("projectUuid");
  }, [searchParams]);

  const navigateBackToCode = (): void => {
    if (codeUuid) {
      navigate(getPublicCodeDetailsRoute(codeUuid));
    }
  };

  const navigateBackToProject = (): void => {
    if (projectUuid) {
      navigate(getPublicProjectOverviewRoute(projectUuid));
    }
  };

  return {
    codeUuid,
    projectUuid,
    navigateBackToCode,
    navigateBackToProject,
  };
};
