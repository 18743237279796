import { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../../constants";
import { ProjectContext } from "../../../../../route/shared/projects/project/ProjectContext";
import { mergeProjects } from "../../../../../service/project";
import { getProjectDetails } from "../../../../../service/query";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../../utils";
import { getProjectDetailsByUuid } from "../../../../../utils/routes";
import { Toast } from "../../../../../widget";

const PROJECT_EXTERNAL_REFERENCE_TYPE = "Registry Project ID";
const MODAL_MATCH_TITLE = "Match with another project";
const MODAL_CONFIRM_TITLE = "Confirm match";

interface UseMergeProjectsModalFormReturnData {
  errors: ServiceError[] | undefined;
  projectExternalReference: string;
  setProjectExternalReference: Dispatch<SetStateAction<string>>;
  sourceProjectName: string | undefined;
  targetProjectName: string | undefined;
  setShowConfirmModal: Dispatch<SetStateAction<boolean>>;
  showConfirmModal: boolean;
  isHandleSubmitLoading: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleConfirmSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const useMergeProjectsModalForm = (
  closeModal: () => void,
  setModalTitle: Dispatch<SetStateAction<string>>
): UseMergeProjectsModalFormReturnData => {
  const navigate = useNavigate();

  const { projectDetails } = useContext(ProjectContext);

  const [projectExternalReference, setProjectExternalReference] = useState<string>("");
  const [targetProjectName, setTargetProjectName] = useState<string>("");
  const [targetProjectExternalParty, setTargetProjectExternalParty] = useState<string>("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const resetForm = (): void => {
    setProjectExternalReference("");

    setErrors([]);
  };

  useEffect(() => {
    setModalTitle(MODAL_MATCH_TITLE);
  }, []);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (projectExternalReference) {
      const res = await getProjectDetails({
        projectUuid: null,
        projectType: PROJECT_EXTERNAL_REFERENCE_TYPE,
        projectExternalReference,
      });

      if (res.status === Status.Success && res.data) {
        setTargetProjectName(res.data.displayName);
        setTargetProjectExternalParty(res.data.projectReference.externalParty);
        setShowConfirmModal(true);
        setModalTitle(MODAL_CONFIRM_TITLE);
      }

      if (res.status === Status.Error) {
        setErrors(res.errors);
      }
    }
  }, setIsHandleSubmitLoading);

  const handleConfirmSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!projectDetails?.uuid) return;

    const res = await mergeProjects({
      sourceProjectUuid: projectDetails?.uuid,
      targetProjectExternalParty,
      targetProjectExternalReference: projectExternalReference,
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Projects merged successfully" });

      resetForm();
      closeModal();
      if (res.data?.targetProjectUuid) {
        navigate(getProjectDetailsByUuid(res.data?.targetProjectUuid, OrganisationTypeConstants.DEVELOPER), {
          replace: true,
        });
      }
    }

    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  };

  return {
    errors,
    projectExternalReference,
    setProjectExternalReference,
    sourceProjectName: projectDetails?.displayName,
    targetProjectName,
    setShowConfirmModal,
    showConfirmModal,
    handleSubmit,
    isHandleSubmitLoading,
    handleConfirmSubmit,
  };
};
