import "./OverviewSection.css";

import { ServiceError } from "../../../../../../../../service/Shared";
import { Button, Column, ErrorBox, ReadonlyText, Row, TextArea } from "../../../../../../../../widget";
import { useOverviewSection } from "./useOverviewSection";

export const OverviewSection = (): JSX.Element => {
  const { projectGroupData, projectGroupDescription, errors, setProjectGroupDescription, onSubmit, isOnSubmitLoading } =
    useOverviewSection();

  return (
    <div className="OverviewSection">
      <Row spacingV="ml">
        <Column span={6}>
          <h2>Overview</h2>
        </Column>
        <Column span={6} justify="end">
          <Button text="Save" onClick={onSubmit} size="small" isLoading={isOnSubmitLoading} />
        </Column>
      </Row>
      {errors && errors.length > 0 && (
        <Row spacingV="ml" justify="start">
          <Column span={10}>
            <ErrorBox
              title="Please solve the below issues before saving"
              text={errors.map((error: ServiceError) => error.message)}
            />
          </Column>
        </Row>
      )}
      <Row>
        <Column span={4}>
          <Row spacingV="xl">
            <Column span={12}>
              <ReadonlyText
                label="Group name"
                value={projectGroupData?.groupName || "Not available"}
                noDataAvailable={!projectGroupData?.groupName}
              />
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <ReadonlyText
                label="Group ID"
                value={projectGroupData?.groupId || "Not available"}
                noDataAvailable={!projectGroupData?.groupId}
              />
            </Column>
          </Row>
        </Column>
        <Column span={5} offset={1}>
          <TextArea
            name="Description"
            label="Group Description"
            value={projectGroupDescription || ""}
            onChange={(value) => {
              setProjectGroupDescription(value);
            }}
            displayCharacterCount
            maxLength={4000}
          />
        </Column>
      </Row>
    </div>
  );
};
