import "./Tag.css";

import { CloseDarkIcon } from "../../../assets";

export interface TagProps {
  label: string;
  hasCloseIcon?: boolean;
  onClose?: () => void;
}

export const Tag = ({ label, hasCloseIcon = false, onClose }: TagProps): JSX.Element => {
  return (
    <div className={`Tag ${!hasCloseIcon ? "TagWithoutIcon" : ""}`}>
      <span className="body4">{label}</span>
      {hasCloseIcon && <CloseDarkIcon onClick={onClose} />}
    </div>
  );
};
