import "./IntroductionContent.css";

import { Column, InformationBox, Name, OrganisationIcon, Row } from "../../../../../../widget";
import { useIntroductionContent } from "./useIntroductionContent";

interface IntroductionContentProps {
  activityDefinitionUuid?: string;
}

export const IntroductionContent = ({ activityDefinitionUuid }: IntroductionContentProps): JSX.Element => {
  const { keyDocuments, verifierDetails } = useIntroductionContent({ activityDefinitionUuid });
  return (
    <div className="ActivityIntroduction">
      <Row spacingV="xl">
        <Column span={12}>
          <h1>Introduction</h1>
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span={12}>
          <p className="body1s">
            Below you can find information that will help you complete your activity. You can return to read this
            information at any time by navigating to the ‘Introduction’ section in the left navigation bar.
          </p>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Name header="Getting started with the activity wizard">
            <div>
              <p className="body2">
                In the activity wizard you can complete the answers at your own pace, saving and closing the wizard at
                any point. Once the activity is published and closed, it will become accessible to other users in your
                organisation.
              </p>
              <p className="body2">
                <b>Navigating the activity wizard:</b>
              </p>
              <ul>
                <li>
                  <p className="body2">
                    <b>‘Next’ and ‘Previous’:</b> Clicking these buttons will move you through the activity and check
                    your data is in the correct format. If there are issues with the data, an error message will prompt
                    you to correct them.
                  </p>
                </li>
                <li>
                  <p className="body2">
                    <b>‘Save & close’:</b> This button will give you two options, ‘Save draft’ and ‘Publish draft’.
                    Selecting ‘Save draft’ will preserve your progress without making it available to other members of
                    your organisation. ‘Publish draft’ saves the activity and creates a version history, which other
                    members will be able to pick up and create their own version on top of.
                  </p>
                </li>
                <li>
                  <p className="body2">
                    <b>Left-hand menu navigation:</b> Clicking through the left hand menu will automatically save your
                    data as you switch between sections.
                  </p>
                </li>
              </ul>
            </div>
          </Name>
        </Column>
      </Row>
      {keyDocuments.length > 0 && (
        <Row>
          <Column span={12}>
            <Name header="Key evidence">
              <div>
                <p className="body2">
                  You may be required to submit additional evidence and documentation within this activity. The key
                  pieces of evidence for this activity are:
                </p>
                <ul>
                  {keyDocuments.map((x) => (
                    <li key={x}>
                      <p className="body2">{x}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </Name>
          </Column>
        </Row>
      )}
      {verifierDetails.length > 0 && (
        <Row>
          <Column span={12}>
            <Name header="VVBs and the digital verification process">
              <div>
                <Row spacingV="ml">
                  <Column span={12}>
                    <p className="body2">
                      If your project’s Verification and Validation Body (VVB) is a digital verification partner, you
                      can submit your activity via Kana for review. The VVB will assess the activity and either return
                      it to you with feedback and required changes, or approve it and submit it to the code. You’ll
                      receive notifications for any status updates or required changes throughout the process, and you
                      can also use the discussions feature to communicate with the VVB.
                    </p>
                  </Column>
                </Row>
                <Row spacingV="m">
                  <Column span={12}>
                    <p className="body2">
                      <b>Kana Digital Verification Partners:</b>
                    </p>
                  </Column>
                </Row>
                <Row spacingV="ml">
                  <Column span={12}>
                    <div className="Verifiers">
                      {verifierDetails?.map((x) => (
                        <div key={x.displayName} className="VerifierDetails">
                          <OrganisationIcon organisationIcon={x.logoUrl} corners="Square" />
                          <p className="body2">{x.displayName}</p>
                        </div>
                      ))}
                    </div>
                  </Column>
                </Row>
                <InformationBox title="Don't see your VVB?" variant="info">
                  Even if your project’s VVB isn’t using Kana, you can still complete your activity in full. At the end
                  of the process, you’ll be able to export the activity output and associated documents. The activity’s
                  status will also be updated when your project is updated in the registry.
                </InformationBox>
              </div>
            </Name>
          </Column>
        </Row>
      )}
      <Row>
        <Column span={12}>
          <Name header="Collaborate with discussions">
            <div>
              <Row spacingV="ml">
                <Column span={12}>
                  <p className="body2">
                    Hover over any input field on the page to reveal the ‘discussions’ icon on the right side of the
                    page. This feature allows you to add questions relating to the field, or additional commentary to
                    your answers. There are two types of discussions message, Internal and External;
                  </p>
                </Column>
              </Row>
              <ul>
                <li>
                  <p className="body2">
                    <b>Internal Discussion:</b> Start or contribute to a thread with internal team members. Once
                    published, your team can see, comment on or resolve discussions.
                  </p>
                </li>
                <li>
                  <p className="body2">
                    <b>External Discussion:</b> If the project’s VVB is a Kana Digital Verification Partner, you can
                    exchange messages with the assessors reviewing your activity. Verifiers may also initiate
                    discussions if they need clarification on any answers, which you can address when the activity is
                    returned to you for review. In the future, this feature will expand to include communication with
                    the standard/code.
                  </p>
                </li>
              </ul>
            </div>
          </Name>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Name header="Review and submission">
            <div>
              <Row spacingV="ml">
                <Column span={12}>
                  <p className="body2">
                    On the review page, you can view all uploaded documents and download the activity output along with
                    any associated files.
                  </p>
                </Column>
              </Row>
              <p className="body2">
                If the project’s VVB is a Kana Digital Verification Partner, you can submit the activity directly to
                them once all sections are validated. If changes are needed, they will return the activity back to you
                for review. If the project’s VVB is not a Kana Digital Verification Partner, you can manually submit the
                activity by downloading the output and associated documents and sending them to the verifier, then
                marking the activity as submitted.
              </p>
            </div>
          </Name>
        </Column>
      </Row>
      <Row spacingV="xl">
        <Column span={12}>
          <Name header="Help and support">
            <p className="body2">
              For additional help on how to use the activity wizard, including step-by-step guides, please visit the{" "}
              <a href="https://www.kana.earth/help">Kana Help Centre</a>.
            </p>
          </Name>
        </Column>
      </Row>
    </div>
  );
};
