import React, { ForwardedRef, RefObject, useEffect, useId, useImperativeHandle, useRef, useState } from "react";

import { PreviewFile } from "../../../../models";
import { Toast } from "../../../general";

interface useSingleFileUploadReturnData {
  id: string;
  isFileUploading: boolean;
  selectedFile: File | undefined;
  inputRef: RefObject<HTMLInputElement>;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

interface useSingleFileUploadProps {
  onChange: (e: File) => void;
  onFileUpload?: (e: File) => Promise<PreviewFile>;
  forwardedRef: ForwardedRef<unknown>;
  maxFileSize: number;
  value?: string;
}

export const useSingleFileUpload = ({
  onChange,
  onFileUpload,
  forwardedRef,
  maxFileSize,
  value,
}: useSingleFileUploadProps): useSingleFileUploadReturnData => {
  const id = useId();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const emptyInputValue = (): void => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (value) {
      // Workaround to show the fileName of the already selected file
      setSelectedFile(new File([], value));
    } else {
      setSelectedFile(undefined);
    }
  }, [value]);

  useImperativeHandle(forwardedRef, () => ({
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    clearInput() {
      setSelectedFile(undefined);
      emptyInputValue();
    },
  }));

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setIsFileUploading(true);

    const { files } = event.target;
    if (files && !!files.length) {
      const fileSize = Number((files[0].size / 1024 / 1024).toFixed(4)); // MB
      if (fileSize < maxFileSize) {
        // if on onFileUpload method is passed we await for the files to be uploaded.
        if (onFileUpload) {
          await onFileUpload(files[0]);
        }
        setSelectedFile(files[0]);
        onChange(files[0]);
      } else {
        Toast.error({
          message: `Please select a file which is less than ${maxFileSize}MB`,
        });
        emptyInputValue();
      }
    }

    setIsFileUploading(false);
  };

  return {
    id,
    isFileUploading,
    selectedFile,
    inputRef,
    onFileChange,
  };
};
