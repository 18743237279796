import { CodeDetailsData } from "../../../route/public/code/details/page/useDetails";
import { PublicCodeDetails, PublicCodeDetailsMetaTags } from "../components";
import { usePublicCodeDetailsPageTemplate } from "./usePublicCodeDetailsPageTemplate";

interface PublicCodeDetailsPageTemplateProps {
  codeDetailsData?: CodeDetailsData;
}

export const PublicCodeDetailsPageTemplate = ({ codeDetailsData }: PublicCodeDetailsPageTemplateProps): JSX.Element => {
  const {
    standardUuid,
    organisationProjects,
    navigateBackToProject,
    navigateBackToCodeComparison,
    showContactUsModal,
    setShowContactUsModal,
  } = usePublicCodeDetailsPageTemplate();
  return (
    <div>
      <PublicCodeDetailsMetaTags codeDetailsData={codeDetailsData} />
      <PublicCodeDetails
        codeDetailsData={codeDetailsData}
        standardUuid={standardUuid}
        organisationProjects={organisationProjects}
        isPreview={false}
        navigateBackToProject={navigateBackToProject}
        navigateBackToCodeComparison={navigateBackToCodeComparison}
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      />
    </div>
  );
};
