import { useContext, useState } from "react";

import { deleteProjectDocumentVersion } from "../../../../../../../../service/project/ProjectService.full";
import { ServiceError, Status } from "../../../../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../../../../../utils";
import { Toast } from "../../../../../../../../widget";
import { DocumentContext } from "../../DocumentContext";

interface UseDeleteDocumentVersionModalProps {
  closeModal: () => void;
  deleteDocumentUuid?: string;
}

interface UseDeleteDocumentVersionModalReturnData {
  deleteDocument: () => void;
  isDeleteDocumentLoading: boolean;
  onCancel: () => void;
  errors: ServiceError[];
}

export const useDeleteDocumentVersionModal = ({
  closeModal,
  deleteDocumentUuid,
}: UseDeleteDocumentVersionModalProps): UseDeleteDocumentVersionModalReturnData => {
  const { refreshDocuments, setRefreshDocuments } = useContext(DocumentContext);
  const [errors, setErrors] = useState<ServiceError[]>([]);
  const [isDeleteDocumentLoading, setIsDeleteDocumentLoading] = useState(false);
  const onCancel = (): void => {
    setErrors([]);
    closeModal();
  };

  const deleteDocument = useIsLoadingWrapper(async (): Promise<void> => {
    if (!deleteDocumentUuid) {
      setErrors([
        {
          code: "NO_DOCUMENT",
          message: "A document must be selected",
        },
      ]);
    } else {
      await deleteProjectDocumentVersion({ projectDocumentHistoryUuid: deleteDocumentUuid }).then((response) => {
        if (response.status === Status.Success) {
          Toast.success({ message: "Document version successfully deleted" });

          setRefreshDocuments(!refreshDocuments);
          onCancel();
        }
        if (response.status === Status.Error && response.errors) {
          setErrors(response.errors.map((error) => ({ ...error, message: getErrorMessageFromCode(error.code) })));
        }
      });
    }
  }, setIsDeleteDocumentLoading);

  return {
    deleteDocument,
    isDeleteDocumentLoading,
    onCancel,
    errors,
  };
};
