import { Dispatch, FormEvent, SetStateAction, useState } from "react";

import { changePassword } from "../../../../../service/identity";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../../../widget";

interface UseChangePasswordFormReturnData {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  setCurrentPassword: Dispatch<SetStateAction<string>>;
  setNewPassword: Dispatch<SetStateAction<string>>;
  setNewPasswordConfirmation: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onCancel: () => void;
}

export const useChangePasswordForm = (): UseChangePasswordFormReturnData => {
  const { user, setCurrentUserRowVersion } = useAuth();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const clearForm = (): void => {
    setCurrentPassword("");
    setNewPassword("");
    setNewPasswordConfirmation("");
    setErrors([]);
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (newPassword !== newPasswordConfirmation) {
      setErrors([
        {
          code: "NOT_MATCHING_PASSWORDS",
          message: "The passwords do not match",
        },
      ]);
      return;
    }

    if (user) {
      const res = await changePassword({
        userUuid: user.userUuid,
        currentPassword,
        newPassword,
        rowVersion: user.rowVersion,
      });

      if (res.status === Status.Success && res.data) {
        Toast.success({ message: "Password changed successfully" });
        setCurrentUserRowVersion(res.data.rowVersion);
        clearForm();
      } else {
        setErrors(res.errors);
      }
    }
  }, setIsHandleSubmitLoading);

  const onCancel = (): void => {
    clearForm();
  };

  return {
    currentPassword,
    newPassword,
    newPasswordConfirmation,
    errors,
    isHandleSubmitLoading,
    setCurrentPassword,
    setNewPassword,
    setNewPasswordConfirmation,
    handleSubmit,
    onCancel,
  };
};
