import "./PreviewButton.css";

import { useId } from "react";

interface PreviewButtonProps {
  bgColour: string | null;
  textColour: string | null;
}

export const PreviewButton = ({ bgColour, textColour }: PreviewButtonProps): JSX.Element => {
  const id = useId();

  return (
    <div className="PreviewButton">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label id={id}>Preview</label>
      <div className="PreviewButtonContainer">
        <div aria-labelledby={id} style={{ backgroundColor: `${bgColour}` }}>
          <span style={{ color: `${textColour}` }}>Preview button</span>
        </div>
      </div>
    </div>
  );
};
