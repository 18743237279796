import "./DiscussionTable.css";

import { ResultData } from "../../../../../service/Shared";
import { CursorDataGrid, DataGridColumnDefinition } from "../../../../../widget";
import { useDiscussionTable } from "./useDiscussionTable";

export type DiscussionTableProps = {
  selectRow: (data: ResultData) => void;
  columns: DataGridColumnDefinition[];
  visible?: boolean;
};

export const DiscussionTable = ({ selectRow, columns, visible }: DiscussionTableProps): JSX.Element => {
  const { refreshActivities, onRequestFetchDiscussions, isDataLoading, triggerPagePrevious } = useDiscussionTable();
  const className = `DiscussionTable ${!visible && "hidden"}`;
  return (
    <div className={className}>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        data={[]}
        showTotalCount={false}
        onRowClick={selectRow}
        onChange={onRequestFetchDiscussions}
        defaultSortingCriteria={[]}
        dataIsLoading={isDataLoading}
        filterable
        pageable
        pagination={{ totalCount: 10 }}
        refresh={refreshActivities}
        triggerPagePrevious={triggerPagePrevious}
        noDataMessage="No discussions to display"
      />
    </div>
  );
};
