/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  Dispatch,
  FormEvent,
  ForwardedRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { GetInTouchData } from "../../../../../../models";
import { updateDeveloperListing } from "../../../../../../service/organisation";
import { Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual, useIsLoadingWrapper } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import {
  DeveloperFormNames,
  DeveloperMarketingAssetsFormChangedAction,
  DeveloperMarketingAssetsSubmitHandler,
} from "../../models";

interface UseGetInTouchFormReturnData {
  enabled: boolean;
  isHandleSubmitLoading: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
  hasPermission: (permission: string) => boolean;
}

export const useGetInTouchForm = (
  getInTouchDefaultValues: GetInTouchData,
  getInTouchOldDefaultValues: GetInTouchData,
  listingRowVersion: number,
  setListingRowVersion: Dispatch<SetStateAction<number>>,
  dispatch: Dispatch<DeveloperMarketingAssetsFormChangedAction>,
  forwardedRef: ForwardedRef<DeveloperMarketingAssetsSubmitHandler>,
  getCurrentFormsData: (formWhichWasSaved: string) => string
): UseGetInTouchFormReturnData => {
  const { currentDeveloperUuid, hasPermission } = useAuth();

  const [submittedValues, setSubmittedValues] = useState<GetInTouchData>(getInTouchOldDefaultValues);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);
  const [enabled, setEnabled] = useState(getInTouchDefaultValues.enabled);

  useImperativeHandle(forwardedRef, () => ({
    getInTouchData() {
      return {
        enabled,
      };
    },
    getInTouchSubmittedData() {
      return {
        ...submittedValues,
      };
    },
  }));

  const getCurrentFormData = useCallback((): GetInTouchData => {
    return {
      enabled,
    };
  }, [enabled]);

  useEffect(() => {
    dispatch({
      type: "SET_GET_IN_TOUCH_FORM_CHANGED",
      value: !areObjectsDeepEqual(submittedValues, getCurrentFormData()),
    });
  }, [enabled, submittedValues]);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (currentDeveloperUuid) {
      const res = await updateDeveloperListing({
        developerUuid: currentDeveloperUuid,
        content: getCurrentFormsData(DeveloperFormNames.GetInTouch),
        rowVersion: listingRowVersion,
      });

      if (res.status === Status.Success && res.data) {
        Toast.success({ message: "Get in touch details changed successfully" });
        setSubmittedValues({
          enabled,
        });
        setListingRowVersion(res.data.rowVersion);
      }
    }
  }, setIsHandleSubmitLoading);

  const handleCancel = (): void => {
    setEnabled(submittedValues.enabled);
  };

  return {
    enabled,
    setEnabled,
    handleSubmit,
    isHandleSubmitLoading,
    handleCancel,
    hasPermission,
  };
};
