import "./Slider.css";

import RcSlider from "rc-slider";
import { useEffect, useState } from "react";

import { FormLabel } from "../../FormLabel";
import { HandleTooltipRender } from "./components";

export interface SliderProps {
  value: number[] | undefined;
  min: number;
  max: number;
  label?: string;
  allowLabelDangerousHtml?: boolean;
  range?: boolean;
  onChange: (value: number | number[]) => void;
  formatValue?: (val: number | undefined) => string | undefined;
}

export const Slider = ({
  value,
  min,
  max,
  label,
  allowLabelDangerousHtml,
  range = false,
  onChange,
  formatValue,
}: SliderProps): JSX.Element => {
  // value using useState to avoid the slider not updating when the value is changed from outside
  const [sliderValue, setSliderValue] = useState(value);
  const onChangeInternal = (newValue: number | number[]): void => {
    setSliderValue(newValue as number[]);
    onChange(newValue);
  };

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  return (
    <FormLabel label={label} allowDangerousHtml={allowLabelDangerousHtml}>
      <div className="SliderContainer">
        <RcSlider
          range={range}
          min={min}
          max={max}
          value={sliderValue}
          onChange={(newValue) => onChangeInternal(newValue)}
          handleRender={(origin, props) => HandleTooltipRender(origin, props, min, max, formatValue)}
          className="Slider"
        />
      </div>
    </FormLabel>
  );
};
