import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PublicBadgeDetailsParams } from "../../../models";
import { searchProjects } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";
import { useRouterContext } from "../../../useRouterContext";
import { getProjectPathnameForBackNavigation } from "../../../utils/routes";
import { DisplayHorizontalProject } from "../../../widget/cards/HorizontalProjectCards/models";

interface UsePublicBadgeDetailsPageTemplateReturnData {
  dataIsLoading: boolean;
  badgeUuid: string | undefined;
  badgeProjects: DisplayHorizontalProject[] | undefined;
  navigateBackToProject: () => void;
  showContactUsModal: boolean;
  setShowContactUsModal: Dispatch<SetStateAction<boolean>>;
}

export const usePublicBadgeDetailsPageTemplate = (): UsePublicBadgeDetailsPageTemplateReturnData => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { previousLocation } = useRouterContext();
  const { badgeUuid } = useParams<PublicBadgeDetailsParams>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [badgeProjects, setBadgeProjects] = useState<DisplayHorizontalProject[] | undefined>();

  const fetchBadgeProjects = useCallback(async (uuid: string) => {
    const response = await searchProjects({
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 4,
      },
      filter: {
        results: {
          badges: {
            uuid: { operator: "eq", value: uuid },
          },
        },
      },
    });

    if (response.status === Status.Success) {
      const projects = response.data?.results.map((el) => ({
        uuid: el.uuid,
        imageSource: el.listingFiles?.length > 0 ? el.listingFiles[0]?.file?.url : null,
        displayName: el.displayName,
        addressCountryCode: el.addressCountryCode || "",
        developerSmallLogoFileUrl: el.developer.files.find((file) => file.type === "SmallLogo")?.file.url || null,
        developerDisplayName: el.developer?.displayName || undefined,
        codeName: el.standard.displayName || undefined,
      }));

      if (projects) {
        setBadgeProjects(projects);
      }
    }
  }, []);

  useEffect(() => {
    if (badgeUuid) {
      fetchBadgeProjects(badgeUuid).then(() => setDataIsLoading(false));
    }
  }, [fetchBadgeProjects, badgeUuid]);

  const navigateBackToProject = (): void => {
    const projectUuid = searchParams.get("projectUuid");
    if (projectUuid) {
      navigate(getProjectPathnameForBackNavigation(projectUuid, previousLocation));
    }
  };

  return {
    dataIsLoading,
    badgeUuid,
    badgeProjects,
    navigateBackToProject,
    showContactUsModal,
    setShowContactUsModal,
  };
};
