import "./CodeStatusKPI.css";

import { CodeCharacteristics } from "../../../../models";
import { Divider } from "../../Divider";
import { KPITooltip } from "../components";
import { useCodeStatusKPI } from "./useCodeStatusKPI";

interface CodeStatusKPIProps {
  codeCharacteristics: CodeCharacteristics;
  isProjectPage?: boolean;
}

const MAX_COUNT = 10;
export const CodeStatusKPI = ({ codeCharacteristics, isProjectPage = false }: CodeStatusKPIProps): JSX.Element => {
  const { codeCharacteristicsArray, codeCharacteristicsCount } = useCodeStatusKPI(codeCharacteristics);
  return (
    <div className={`CodeStatusContainer ${isProjectPage ? "ProjectPage" : ""}`}>
      <h2>Status</h2>
      <div className="CodeStatusTextArea">
        <h3>{codeCharacteristicsCount === MAX_COUNT ? "Established" : "Emerging"}</h3>
        <p className="body3">{codeCharacteristicsCount}/10 core carbon principles published</p>
      </div>
      <div className="CodeStatusGraphArea">
        <div className={`CodeStatusGraph ${codeCharacteristicsCount === MAX_COUNT ? "Established" : "Emerging"}`}>
          {codeCharacteristicsArray?.map((codeCharacteristic) => {
            return (
              <KPITooltip
                key={codeCharacteristic.characteristicType}
                text={codeCharacteristic.characteristicType}
                offsetHeight={-42}
                offsetWidth={42 + (codeCharacteristic.characteristicType.length - 10) * 2.25}
              >
                <div
                  className="GraphBar"
                  style={
                    codeCharacteristic.characteristicValue?.met && codeCharacteristicsCount < MAX_COUNT
                      ? {
                          backgroundColor: "var(--color-grey-60)",
                        }
                      : {}
                  }
                />
              </KPITooltip>
            );
          })}
        </div>
        <Divider type="horizontal" />
        <div className="CodeStatusGraphLabelArea">
          <p className="body3">Emerging</p>
          <p className="body3">Established</p>
        </div>
      </div>
    </div>
  );
};
