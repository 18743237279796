import "../../../../wizards/Discussion/components/DiscussionThread/DiscussionThread.css";

import { Divider } from "../../../Divider";
import { MessageThread } from "../../models/Message";
import { DiscussionMessage } from "../DiscussionMessage";
import { useDiscussionThread } from "./useDiscussionThread";

export type DiscussionThreadProps = {
  thread?: MessageThread;
  onDeleteMessage: (uuid: string) => void;
  onDeleteThread: () => void;
  onResolveThread: () => void;
  setThreadData: (data: MessageThread) => void;
};

export const DiscussionThread = ({
  thread,
  onDeleteMessage,
  onDeleteThread,
  onResolveThread,
  setThreadData,
}: DiscussionThreadProps): JSX.Element => {
  const { userUuid, firstMessage, replies } = useDiscussionThread({ thread });

  const firstDiscussionMessage = (): JSX.Element => (
    <div className="DiscussionFirstMessageContainer">
      <DiscussionMessage
        userUuid={userUuid}
        message={firstMessage}
        onDeleteMessage={onDeleteThread}
        onResolveThread={onResolveThread}
        setThreadData={setThreadData}
        threadData={thread}
        isThreadMessage
      />
    </div>
  );

  const threadReplies = (): JSX.Element => (
    <div className="ReplyContainer">
      <Divider type="vertical" thickness={2} />
      <div className="DiscussionMessageReplies">
        {replies.map((message) => (
          <DiscussionMessage
            userUuid={userUuid}
            key={message.uuid}
            message={message}
            onDeleteMessage={() => onDeleteMessage(message.uuid)}
            setThreadData={setThreadData}
            threadData={thread}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="DiscussionThreadBody">
      {firstDiscussionMessage()}
      {replies && threadReplies()}
    </div>
  );
};
