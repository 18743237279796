import React, { useEffect, useMemo, useState } from "react";

import { Column, Row } from "../../../layout";
import { Checkbox, CheckboxProps } from "../Checkbox";

interface UseCheckboxGroupProps {
  checkboxesData: { [key: string]: string };
  value: string[];
  onChange: (e: string[]) => void;
  order?: string[];
}

interface UseCheckboxGroupReturnData {
  checkboxes: JSX.Element[];
}

export const useCheckboxGroup = ({
  value,
  checkboxesData,
  onChange,
  order,
}: UseCheckboxGroupProps): UseCheckboxGroupReturnData => {
  const [checkboxValues, setCheckboxValues] = useState(value);

  useEffect(() => {
    if (value.length !== checkboxValues.length || !value.every((v) => checkboxValues.includes(v))) {
      setCheckboxValues(value);
    }
  }, [value]);

  let checkboxEntries = Object.entries(checkboxesData);

  if (order && checkboxEntries.length === order.length) {
    const orderedCheckboxEntries: [string, string][] = [];

    order.forEach((ord: string) => {
      const option = checkboxEntries.find((d) => d[0] === ord);

      if (option) {
        orderedCheckboxEntries.push(option);
      }
    });

    checkboxEntries = orderedCheckboxEntries;
  }

  const checkboxes = useMemo(
    () =>
      checkboxEntries.map((checkbox) => {
        const [checkboxValue, checkboxLabel] = checkbox;

        const onCheckboxChange = (): void => {
          const updatedCheckboxValues: string[] | undefined = checkboxValues.includes(checkboxValue)
            ? checkboxValues.filter((x: string) => x !== checkboxValue)
            : [...(checkboxValues ?? []), checkboxValue];

          setCheckboxValues(updatedCheckboxValues);

          onChange(updatedCheckboxValues);
        };

        const checkboxProps = {
          label: checkboxLabel,
          checked: checkboxValues.includes(checkboxValue),
          onChange: onCheckboxChange,
        } as CheckboxProps;

        return (
          <Row key={checkboxValue as string} spacingV="s">
            <Column span={6}>{React.createElement(Checkbox, checkboxProps)}</Column>
          </Row>
        );
      }),
    [checkboxValues, value, onChange]
  );

  return {
    checkboxes,
  };
};
