import "./Config";
import "./Globals";

import { ApolloProvider } from "@apollo/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { AppRouter } from "./AppRouter";
import { MetaTags } from "./MetaTags";
import { apolloPrivate } from "./service/apolloPrivate";
import { AuthProvider } from "./useAuth";

const App = (): JSX.Element | null => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ApolloProvider client={apolloPrivate}>
          <HelmetProvider>
            <MetaTags />
            <AppRouter />
          </HelmetProvider>
        </ApolloProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
