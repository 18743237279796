import { useCallback } from "react";

import { getDiscussionThreads, GetDiscussionThreadsResponse } from "../../../../../service/query";

export const useFetchDiscussions = (
  activityHistoryUuid: string | undefined
): ((discussionUuids: string[]) => Promise<GetDiscussionThreadsResponse[] | undefined>) => {
  return useCallback(async (discussionUuids: string[]): Promise<GetDiscussionThreadsResponse[] | undefined> => {
    if (discussionUuids !== undefined) {
      const res = await getDiscussionThreads({
        messageLookBack: true,
        objectType: "Activity History",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        objectUuid: activityHistoryUuid!,
        threadUuids: discussionUuids,
      });

      return res.data;
    }

    return undefined;
  }, []);
};
