import "./HeaderColumnSelect.css";

import { forwardRef } from "react";
// eslint-disable-next-line import/named
import { components, StylesConfig } from "react-select";

import { HeaderColumnSelectOption } from "../../models";
import { HeaderColumnSelect } from "./HeaderColumnSelect";

const styles: StylesConfig<HeaderColumnSelectOption> = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: 16,
    height: 16,
    minHeight: 0,
    cursor: "pointer",
    left: 15,
    opacity: 0,
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    width: 16,
    height: 16,
    minHeight: 0,
    cursor: "pointer",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    display: "none",
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    width: 16,
    height: 16,
    cursor: "pointer",
    position: "absolute",
    left: -16,
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    width: 16,
    height: 16,
    cursor: "pointer",
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    display: "none",
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    zIndex: 300,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    width: 250,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    display: "flex",
    cursor: "pointer",
    backgroundColor: state.isSelected ? "unset" : "unset",
    color: "inherit",
    ":active": {
      backgroundColor: "unset",
    },
    ":hover": {
      backgroundColor: "var(--color-light-grey)",
      color: "var(--color-grey)",
    },
  }),
};

interface SortingSelectProps {
  sortingMenuOptionsList: HeaderColumnSelectOption[];
  onOptionClick: (selectedOption: HeaderColumnSelectOption) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortingSelect = forwardRef<any, SortingSelectProps>(
  ({ sortingMenuOptionsList, onOptionClick }: SortingSelectProps, ref) => {
    const { Option } = components;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,react/no-unstable-nested-components
    const IconOption = (props: any): JSX.Element | null =>
      // eslint-disable-next-line react/destructuring-assignment
      !props.data.isHidden ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Option className="HeaderSelectOption" {...props}>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {props.data.icon}
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {props.data.value}
        </Option>
      ) : null;

    return (
      <HeaderColumnSelect
        ref={ref}
        optionsList={sortingMenuOptionsList}
        onOptionClick={onOptionClick}
        styles={styles}
        IconOption={IconOption}
      />
    );
  }
);
