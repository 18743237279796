import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GroupConstants } from "../../../../../../../../constants";
import { Origin } from "../../../../../../../../models";
import { addOrRemoveProjectsFromGroup } from "../../../../../../../../service/project";
import { ServiceError, Status } from "../../../../../../../../service/Shared";
import { useAuth } from "../../../../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../../../../utils";
import { getProjectAuditHistoryRoute, getProjectDetailsByUuid } from "../../../../../../../../utils/routes";
import { Toast } from "../../../../../../../../widget";
import { GroupContext } from "../../GroupContext";

interface UseRemoveGroupProjectModalProps {
  closeModal: () => void;
  projectUuid?: string;
}

interface UseRemoveGroupProjectModalReturnData {
  removeProject: () => void;
  onCancel: () => void;
  errors: ServiceError[];
  isRemoveProjectLoading: boolean;
}

export const useRemoveGroupProjectModal = ({
  closeModal,
  projectUuid,
}: UseRemoveGroupProjectModalProps): UseRemoveGroupProjectModalReturnData => {
  const navigate = useNavigate();

  const { currentUserType } = useAuth();
  const { groupDetails, currentProjectUuid, origin, refreshGroup, setRefreshGroup } = useContext(GroupContext);
  const [isRemoveProjectLoading, setIsRemoveProjectLoading] = useState(false);

  const [errors, setErrors] = useState<ServiceError[]>([]);

  const onCancel = (): void => {
    setErrors([]);
    closeModal();
  };

  const removeProject = useIsLoadingWrapper(async (): Promise<void> => {
    if (!projectUuid) {
      setErrors([
        {
          code: "NO_PROJECT",
          message: "A project must be selected",
        },
      ]);
    } else if (!groupDetails?.groupGuid) {
      setErrors([
        {
          code: "NO_GROUP",
          message: "No group provided",
        },
      ]);
    } else {
      await addOrRemoveProjectsFromGroup({
        projectUuid,
        groupUuid: groupDetails?.groupGuid,
        operation: GroupConstants.REMOVE_PROJECT_OPERATION,
      }).then((response) => {
        if (response.status === Status.Success) {
          Toast.success({ message: "Project removed from group successfully" });

          if (origin === Origin.ProjectAuditPage && currentProjectUuid === projectUuid) {
            navigate(getProjectAuditHistoryRoute(currentProjectUuid, currentUserType));
          } else if (currentProjectUuid === projectUuid) {
            navigate(getProjectDetailsByUuid(currentProjectUuid, currentUserType));
          }

          setRefreshGroup(!refreshGroup);
          onCancel();
        }
        if (response.status === Status.Error && response.errors) {
          setErrors(response.errors);
        }
      });
    }
  }, setIsRemoveProjectLoading);

  return {
    removeProject,
    onCancel,
    errors,
    isRemoveProjectLoading,
  };
};
