import { useCallback, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate, useParams } from "react-router-dom";

import { CursorChangeProps, NavigationSearchParams, ResultType } from "../../../../../../models";
import {
  SearchProjectOrganisationInvitations,
  SearchProjectOrganisationInvitationsResponse,
} from "../../../../../../service/query";
import { ResultData } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { flattenObject } from "../../../../../../utils";
import { getProjectAccessDetailsRoute } from "../../../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
} from "../../../../../../widget";

interface UseAccessTabReturnData {
  onNewInvitation: () => void;
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  hasPermission: (permission: string) => boolean;
}

export const useAccessTab = (): UseAccessTabReturnData => {
  const navigate = useNavigate();

  const { projectUuid } = useParams<"projectUuid">();

  const { currentOrganisationUuid, hasPermission } = useAuth();

  const [dataIsLoading, setDataIsLoading] = useState(true);

  const onNewInvitation = (): void => navigate("invite");

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "sentDate", direction: "DESC" }];

  const formatData = useCallback(
    (responseData: SearchProjectOrganisationInvitationsResponse | undefined): ResultData[] => {
      return (
        responseData?.results.map((d) => {
          const result = flattenObject({ ...d });

          result.targetOrganisationDisplayName = d.targetOrganisation.displayName;
          result.targetOrganisationUuid = d.targetOrganisation.uuid;
          result.projectUuid = d.project.uuid;
          result.projectDisplayName = d.project.displayName;
          result.developerLink = <DataGridLinkCellFormatterData>{
            to: `${getProjectAccessDetailsRoute(d.uuid)}?${NavigationSearchParams.projectAccessTab}=true`,
            text: "View",
          };

          return result;
        }) || []
      );
    },
    []
  );

  const columns: DataGridColumnDefinition[] = [
    {
      key: "targetOrganisation.displayName",
      name: "Target developer",
      dataType: "string",
    },
    { key: "targetRole", name: "Access level", dataType: "string" },
    {
      key: "sentDate",
      name: "Date sent",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
    {
      key: "sentByUser.fullName",
      name: "Granted by",
      dataType: "string",
      filterable: true,
      sortable: true,
    },
    {
      name: "Status",
      key: "calculatedStatus",
      dataType: "string",
      formatter: "projectAccessStatusPill",
      alignment: "center",
      minWidth: 200,
      filterable: false,
      sortable: false,
    },
    {
      key: "developerLink",
      name: "",
      dataType: "string",
      formatter: "link",
      filterable: false,
      sortable: false,
      alignment: "center",
    },
  ];

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.project = {
      uuid: {
        operator: "eq",
        value: projectUuid,
      },
    };

    filterCriteria.sourceOrganisation = {
      uuid: {
        operator: "eq",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: currentOrganisationUuid!,
      },
    };

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await SearchProjectOrganisationInvitations({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || "",
        afterCursor: paging.afterCursor || "",
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key !== "projectLink" ? s.key : "project.displayName",
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  return {
    onNewInvitation,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
    hasPermission,
  };
};
