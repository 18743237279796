import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { BadgeTypeConstants } from "../../../../../constants";
import { BadgeData, Metric, ObjectType } from "../../../../../models";
import {
  GetProjectDetailsResponse,
  getProjectIssuances,
  GetProjectIssuancesResponse,
  getStandardSpecificSchemaDetails,
} from "../../../../../service/publicquery";
import { Status } from "../../../../../service/Shared";
import { saveEvent } from "../../../../../service/stats";
import { ChartData } from "../../../../../widget";
import { StandardSpecificDefinition } from "../../../../shared/projects/project/overview/components/StandardSpecificSection/types";
import { usePublicContext } from "../../../usePublicContext";

interface UseOverviewReturnData {
  isLoading: boolean;
  projectDetails: GetProjectDetailsResponse | undefined;
  tags: string[] | null;
  badges: BadgeData[];
  certifications: BadgeData[];
  chartData: ChartData | undefined;
  standardSpecificDefinition: StandardSpecificDefinition | undefined;
}

export const useOverview = (): UseOverviewReturnData => {
  const { uuid } = useParams();
  const { projectDetails } = usePublicContext();
  const tags = !!projectDetails?.tags && !!projectDetails?.tags.length ? projectDetails?.tags : null;
  const certifications =
    projectDetails?.badges.filter((b) => b.type === BadgeTypeConstants.BADGE_TYPE_CERTIFICATION) || [];
  const badges = projectDetails?.badges.filter((b) => b.type === BadgeTypeConstants.BADGE_TYPE_BADGE) || [];

  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);
  const [standardSpecificDefinition, setStandardSpecificDefinition] = useState<
    StandardSpecificDefinition | undefined
  >();

  const fetchData = useCallback(async (projectUuid: string, standardSpecificSchemaUuid: string) => {
    const [projectIssuancesResponse, standardSpecificDetailsResponse] = await Promise.all([
      getProjectIssuances({ projectUuid }),
      getStandardSpecificSchemaDetails({
        standardSpecificSchemaUuid,
      }),
    ]);

    if (projectIssuancesResponse.status === Status.Success && projectIssuancesResponse.data) {
      const projectIssuancesChartData = projectIssuancesResponse.data.reduce(
        (tmp: { [key: string]: number }, value) => {
          const endYear = value.vintageEndDate.getFullYear();
          const period = endYear.toString();

          if (projectIssuancesResponse.data !== null && projectIssuancesResponse.data !== undefined)
            // eslint-disable-next-line no-param-reassign
            tmp[period] = projectIssuancesResponse.data
              .filter((d) => d.vintageEndDate.getFullYear() <= endYear)
              .reduce((acc: number, k: GetProjectIssuancesResponse) => acc + k.quantity, 0);
          return tmp;
        },
        {}
      );

      setChartData(projectIssuancesChartData);
    }

    if (standardSpecificDetailsResponse.status === Status.Success && standardSpecificDetailsResponse.data) {
      setStandardSpecificDefinition(standardSpecificDetailsResponse.data.definition);
    }
  }, []);

  useEffect(() => {
    if (uuid && projectDetails?.standardSpecificSchemaUuid) {
      fetchData(uuid, projectDetails?.standardSpecificSchemaUuid).finally(async () => {
        setIsLoading(false);
        saveEvent({
          metric: Metric.View,
          objectType: ObjectType.Project,
          objectUuid: uuid,
          timestamp: null,
        });
      });
    }
  }, [fetchData, uuid, projectDetails]);

  return {
    isLoading,
    chartData,
    tags,
    badges,
    certifications,
    projectDetails,
    standardSpecificDefinition,
  };
};
