import { DeveloperSingleCardTemplate } from "../../../../../templates";
import { Column, Divider, Row } from "../../../../../widget";
import { useView } from "./useView";

export const View = (): JSX.Element => {
  const { name, description, supportedMimeTypes, variants, dataIsLoading, handleBackClick } = useView();

  return (
    <div>
      {!dataIsLoading && (
        <DeveloperSingleCardTemplate
          activeElement="Settings"
          pageTitle={name}
          handleBackClick={handleBackClick}
          backButtonText="Back to document types"
        >
          <Row spacingV="l">
            <Column span={3}>
              <h3>Description:</h3>
            </Column>
            <Column span={9}>
              <p className="body1">{description}</p>
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={3}>
              <h3>Extensions supported:</h3>
            </Column>
            <Column span={9}>
              <p className="body1">{supportedMimeTypes}</p>
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          {variants && variants?.length > 0 ? (
            <>
              <Row spacingV="l">
                <Column span={12}>
                  <h2>Preset variants</h2>
                </Column>
              </Row>
              <Row spacingV="m">
                <Column span={3}>
                  <h3>Variant</h3>
                </Column>
                <Column span={9}>
                  <h3>Description</h3>
                </Column>
              </Row>
              <Row spacingV="m">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              {variants?.map((variant) => (
                <div key={variant.name}>
                  <Row spacingV="m">
                    <Column span={3}>
                      <p className="body1">{variant.name}</p>
                    </Column>
                    <Column span={9}>
                      <p className="body1">{variant.description}</p>
                    </Column>
                  </Row>
                  <Row spacingV="m">
                    <Column span={12}>
                      <Divider type="horizontal" />
                    </Column>
                  </Row>
                </div>
              ))}
            </>
          ) : (
            <p className="body1">No related document variants</p>
          )}
        </DeveloperSingleCardTemplate>
      )}
    </div>
  );
};
