import { Route, Routes } from "react-router-dom";

import { DownloadActivityZipPageParams } from "../models";
import { DownloadZipPage } from "../route/developer/download";
import { Error403Page, Error404Page, Error500Page } from "../route/shared/errors";
import { useRouteGuards } from "./guards";

export const AuthenticatedRoutes = (): JSX.Element => {
  const { RequireAuth } = useRouteGuards();

  return (
    <Routes>
      <Route
        path={`:${DownloadActivityZipPageParams.ownerType}/:${DownloadActivityZipPageParams.ownerUuid}/download-zip/:${DownloadActivityZipPageParams.activityZipUuid}`}
        element={
          <RequireAuth>
            <DownloadZipPage />
          </RequireAuth>
        }
      />
      <Route
        path="403/access-denied"
        element={
          <RequireAuth>
            <Error403Page />
          </RequireAuth>
        }
      />
      <Route
        path="500/application-error"
        element={
          <RequireAuth>
            <Error500Page />
          </RequireAuth>
        }
      />
      <Route
        path="*"
        element={
          <RequireAuth>
            <Error404Page />
          </RequireAuth>
        }
      />
    </Routes>
  );
};
