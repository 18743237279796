import "./MarketingAssets.css";

import { OrganisationPermissionConstants } from "../../../../constants";
import { Button, Column, Divider, Row } from "../../../../widget";
import { AboutUsForm, AtAGlanceForm, HeroForm, OurServicesForm } from "../../../shared";
import { CodesAndStandardsForm, OurExpertiseForm } from "./forms";
import { useMarketingAssets } from "./useMarketingAssets";

const MarketingAssets = (): JSX.Element => {
  const {
    heroFormRef,
    ourExpertiseFormRef,
    codesAndStandardsFormRef,
    ourServicesFormRef,
    atAGlanceFormRef,
    aboutUsFormRef,
    dataIsLoading,
    listingRowVersion,
    setListingRowVersion,
    dispatch,
    heroDefaultValues,
    ourExpertiseDefaultValues,
    codesAndStandardsDefaultValues,
    atAGlanceDefaultValues,
    ourServicesDefaultValues,
    aboutUsDefaultValues,
    heroOldDefaultValues,
    ourExpertiseOldDefaultValues,
    codesAndStandardsOldDefaultValues,
    atAGlanceOldDefaultValues,
    ourServicesOldDefaultValues,
    aboutUsOldDefaultValues,
    verifierDisplayName,
    isHandlePreviewLoading,
    handlePreview,
    getCurrentFormsData,
    hasPermission,
    objectUuid,
    objectType,
    objectKey,
    updateListing,
  } = useMarketingAssets();

  return (
    <div>
      {!dataIsLoading && (
        <>
          <Row spacingV="sl" justify="between">
            <Column span={12}>
              <div className="MarketingAssetsContainer">
                <h2>Marketing assets</h2>
                {hasPermission(OrganisationPermissionConstants.WRITE) && (
                  <Button text="Preview" onClick={handlePreview} isLoading={isHandlePreviewLoading} />
                )}
              </div>
            </Column>
          </Row>
          {heroDefaultValues && heroOldDefaultValues && (
            <HeroForm
              ref={heroFormRef}
              objectUuid={objectUuid}
              objectType={objectType}
              objectKey={objectKey}
              updateListing={updateListing}
              heroDefaultValues={heroDefaultValues}
              heroOldDefaultValues={heroOldDefaultValues}
              listingRowVersion={listingRowVersion}
              setListingRowVersion={setListingRowVersion}
              dispatch={dispatch}
              getCurrentFormsData={getCurrentFormsData}
            />
          )}

          {ourExpertiseDefaultValues && ourExpertiseOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <OurExpertiseForm
                ref={ourExpertiseFormRef}
                ourExpertiseDefaultValues={ourExpertiseDefaultValues}
                ourExpertiseOldDefaultValues={ourExpertiseOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {codesAndStandardsDefaultValues && codesAndStandardsOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <CodesAndStandardsForm
                ref={codesAndStandardsFormRef}
                verifierDisplayName={verifierDisplayName}
                codesAndStandardsDefaultValues={codesAndStandardsDefaultValues}
                codesAndStandardsOldDefaultValues={codesAndStandardsOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {atAGlanceDefaultValues && atAGlanceOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <AtAGlanceForm
                ref={atAGlanceFormRef}
                objectUuid={objectUuid}
                objectKey={objectKey}
                updateListing={updateListing}
                atAGlanceDefaultValues={atAGlanceDefaultValues}
                atAGlanceOldDefaultValues={atAGlanceOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {ourServicesDefaultValues && ourServicesOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <OurServicesForm
                ref={ourServicesFormRef}
                objectUuid={objectUuid}
                objectType={objectType}
                objectKey={objectKey}
                updateListing={updateListing}
                ourServicesDefaultValues={ourServicesDefaultValues}
                ourServicesOldDefaultValues={ourServicesOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {aboutUsDefaultValues && aboutUsOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <AboutUsForm
                ref={aboutUsFormRef}
                objectUuid={objectUuid}
                objectType={objectType}
                objectKey={objectKey}
                updateListing={updateListing}
                aboutUsDefaultValues={aboutUsDefaultValues}
                aboutUsOldDefaultValues={aboutUsOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}
          {/* Temporarily disabling UnsavedChangesModal ENG-664 - by adding false check */}
          {/* <UnsavedChangesModal
            hasUnsavedChanges={
              false && !previewMode && Object.values(hasUnsavedChanges).some((value) => value === true)
            }
          /> */}
        </>
      )}
    </div>
  );
};

export default MarketingAssets;
