import { Origin } from "../../../../../models";
import { PublicDeveloperDetailsPreviewTemplate } from "../../../../../templates";
import { getDeveloperSettingsMarketingAssetsRoute } from "../../../../../utils/routes";
import { PreviewBanner } from "../../../../../widget";
import { usePreview } from "./usePreview";

const Preview = (): JSX.Element => {
  const { dataIsLoading, previewUuid, previewData, navigate } = usePreview();

  return (
    <>
      <PreviewBanner
        description="You are currently viewing a preview version of the page. These changes will not be published until you close the preview and save your changes"
        onClose={() => {
          navigate(
            getDeveloperSettingsMarketingAssetsRoute(
              {
                origin: Origin.DeveloperDetailsPreview,
              },
              previewUuid
            )
          );
        }}
      />
      {!dataIsLoading && previewData && <PublicDeveloperDetailsPreviewTemplate previewData={previewData} />}
    </>
  );
};

export default Preview;
