import { Column, ColumnSpanOrOffset, Row } from "../../../../../../../../widget";
import { Component, DataType } from "../types";
import { reduceComponents } from "../utils";
import { RenderComponent } from ".";

interface ProcessComponentsProps {
  components: Component[];
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateData?: (dataPath: string, value: any, dataType: DataType) => void;
  displayOnlyReadonly?: boolean;
  defaultSpan?: ColumnSpanOrOffset;
}

export const ProcessComponents = ({
  components,
  key,
  expressionContext,
  updateData,
  defaultSpan = 4,
  displayOnlyReadonly = false,
}: ProcessComponentsProps): JSX.Element => {
  const componentsReduced = reduceComponents(components, expressionContext);

  return (
    <>
      {componentsReduced.map((componentRow: Component[], rowIdx: number) => {
        return (
          <Row
            key={`${componentRow.map((c) => c.key).join(",")}`}
            spacingV={rowIdx !== componentsReduced.length - 1 ? "ml" : undefined}
          >
            {componentRow.map((component: Component, componentIdx: number) => {
              return (
                <Column key={component.key} span={defaultSpan} offset={componentIdx !== 0 ? 1 : 0}>
                  {RenderComponent({
                    component,
                    key: `${key}.${component.key}`,
                    expressionContext,
                    updateData,
                    displayOnlyReadonly,
                  })}
                </Column>
              );
            })}
          </Row>
        );
      })}
    </>
  );
};
