import { useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate } from "react-router-dom";

import { ProjectPermissionConstants } from "../../../../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../../../../models";
import {
  getCurrentUserProjectPermissions,
  GetCurrentUserProjectPermissionsResponse,
  searchProjects,
  SearchProjectsRequest,
  SearchProjectsResponse,
} from "../../../../../../../service/query";
import { BaseResponseWithMultiple, FilterGroups, ResultData, Status } from "../../../../../../../service/Shared";
import { useAuth } from "../../../../../../../useAuth";
import { flattenObject } from "../../../../../../../utils";
import { getProjectDetailsByUuid, getProjectManageProjectGroupRoute } from "../../../../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
} from "../../../../../../../widget";

interface useGroupSectionReturnData {
  columns: DataGridColumnDefinition[];
  dataIsLoading: boolean;
  defaultSortingCriteria: SortColumn[];
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  refreshProjects?: boolean;
  hasWriteProjectDetailsPermission: boolean;
  editGroupOnClick?: () => void;
}

export const useGroupSection = (groupUuid: string, currentProjectUuid: string): useGroupSectionReturnData => {
  const { currentUserType } = useAuth();
  const [refreshProjects, setRefreshProjects] = useState<boolean>(false);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [hasWriteProjectDetailsPermission, setHasWriteProjectDetailsPermission] = useState(false);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "link",
      name: "Project",
      dataType: "string",
      formatter: "link",
      alignment: "left",
      filterable: true,
      sortable: true,
      minWidth: 100,
    },
    {
      key: "locationGridReference",
      name: "Grid reference",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: true,
      sortable: true,
      minWidth: 150,
    },
    { key: "areaNetHa", name: "Net area (ha)", dataType: "number", filterable: true, sortable: true },
    {
      key: "startDate",
      name: "Start date",
      dataType: "Date",
      formatter: "dateOnly",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
    { key: "duration", name: "Duration (years)", dataType: "number", filterable: false, sortable: false },
    { key: "cachedPiuQuantity", name: "PIUs", dataType: "number", filterable: true, sortable: true },
    { key: "cachedVcuQuantity", name: "VCUs", dataType: "number", filterable: true, sortable: true },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "link", direction: "ASC" }];

  useEffect(() => {
    setRefreshProjects(!refreshProjects);
  }, [currentProjectUuid]);

  const formatData = useCallback(
    (responseData: SearchProjectsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = {
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid, currentUserType),
        } as unknown as DataGridButtonLinkCellFormatterData;

        if (el.uuid === currentProjectUuid) {
          result.link = {
            text: el.displayName,
          } as unknown as DataGridButtonLinkCellFormatterData;
        }
        return result;
      }) || [],
    [refreshProjects]
  );

  const getAndSetHasWriteProjectDetailsPermission = useCallback(
    async (responseData: SearchProjectsResponse | undefined) => {
      const projectUuids = responseData?.results?.map((el) => el.uuid) || [];

      await getCurrentUserProjectPermissions({ projectUuids, groupUuids: [groupUuid] }).then(
        (permissions: BaseResponseWithMultiple<GetCurrentUserProjectPermissionsResponse>) => {
          if (permissions.status === Status.Success && permissions.data) {
            setHasWriteProjectDetailsPermission(
              permissions.data.every((el) =>
                el.currentUserPermissions.includes(ProjectPermissionConstants.WRITE_PROJECT_DETAILS)
              )
            );
          }
        }
      );
    },
    [refreshProjects]
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 10,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const request: SearchProjectsRequest & FilterGroups<SearchProjectsRequest["filter"]> = {
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key !== "link" ? s.key : "displayName",
        direction: s.direction,
      })),
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.group = {
      groupUuid: {
        operator: "eq",
        value: groupUuid,
      },
    };

    request.filter = { results: filterCriteria };

    if (filtering.find((f) => f.key === "link")) {
      const operator = filtering.find((f) => f.key === "link")?.operator;
      const filterOperatorWithNot = ["neq", "ncontains"];

      request.filterGroups = [
        {
          // operator is changed to "and" because the operators above do not return correctly with "or"
          operator: filterOperatorWithNot.find((fo) => fo === operator) ? "and" : "or",
          filter: {
            results: {
              displayName: {
                operator: operator || "contains",
                value: filterCriteria.link.value,
              },
              name: {
                operator: operator || "contains",
                value: filterCriteria.link.value,
              },
            },
          },
        },
      ];
    }

    delete filterCriteria.link;

    await searchProjects(request)
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };

        getAndSetHasWriteProjectDetailsPermission(response.data);
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  const editGroupOnClick = (): void => {
    navigate(getProjectManageProjectGroupRoute(groupUuid, `projectUuid=${currentProjectUuid}`));
  };

  return {
    columns,
    dataIsLoading,
    defaultSortingCriteria,
    onChange,
    refreshProjects,
    hasWriteProjectDetailsPermission,
    editGroupOnClick,
  };
};
