import { ApolloLink, DefaultOptions } from "@apollo/client";

import { recursivelyParseJsonDates } from "./recursivelyParseJsonDates";

export const apolloParseJsonDatesLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    response.data = recursivelyParseJsonDates(response.data);
    return response;
  });
});

export const apolloDefaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const apolloAuthHeaderLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from memory
  const token = window.ACCESS_TOKEN;

  // Use the setContext method to set the HTTP headers.
  if (token)
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

  // Call the next link in the middleware chain.
  return forward(operation);
});
