import "./PublicFooter.css";

import { Divider } from "../../general";
import { Footer } from "../Footer";

export const PublicFooter = (): JSX.Element => {
  return (
    <div className="PublicFooter">
      <Divider type="horizontal" />
      <div className="FooterWrapper">
        <Footer />
      </div>
    </div>
  );
};
