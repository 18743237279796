import { Dispatch, SetStateAction } from "react";

import { ActivityReviewTypeConstants } from "../../../../../constants";
import { convertDateToStringDDMMYYYY } from "../../../../../utils";
import { Column, Divider, InformationBox, RadioButtonGroup, Row } from "../../../../../widget";
import { RadioChangeEvent } from "../../../../../widget/forms/Input/RadioButtonGroup/components";
import { computeComponentKey, HasReviews } from "../../../../developer/activities";
import { EditableStepReview } from "../components";

export const createStepReview = (
  isAssignedAuditor: boolean,
  reviews: HasReviews,
  dataPath: string,
  setIsValidAssessment: Dispatch<SetStateAction<boolean | undefined>>,
  setIsValidReview: Dispatch<SetStateAction<boolean | undefined>>,
  reviewType?: ActivityReviewTypeConstants
): (JSX.Element | undefined)[] => {
  const reviewSteps = [];

  reviewSteps.push(
    <div key={computeComponentKey(dataPath, "divider")}>
      <Row spacingV="l" />
      <Row spacingV="l">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
    </div>
  );

  // Assessment object defined, if assigned auditor show edit version
  if (reviews.activityReviews?.assessment !== undefined && reviews.activityReviews?.assessment.isValid !== null)
    reviewSteps.push(
      <Row key={computeComponentKey(dataPath, "assessment")} spacingV="m">
        <Column span={12}>
          {isAssignedAuditor && reviewType === ActivityReviewTypeConstants.ASSESSMENT ? (
            <EditableStepReview
              review={reviews.activityReviews.assessment}
              handleIsValidChange={setIsValidAssessment}
            />
          ) : (
            <InformationBox
              variant={reviews?.activityReviews.assessment.isValid ? "success" : "alert"}
              direction="row"
              corners="rounded"
            >
              {reviews?.activityReviews.assessment.createdAt &&
              reviews?.activityReviews.assessment.createdByUserFullname &&
              reviews?.activityReviews.assessment.createdByUserUuid ? (
                <p>
                  This section was marked as {reviews?.activityReviews.assessment.isValid ? "valid" : "invalid"} by
                  <b> {reviews?.activityReviews.assessment.createdByUserFullname} </b>
                  on <b>{convertDateToStringDDMMYYYY(reviews?.activityReviews.assessment.createdAt)}</b>
                </p>
              ) : (
                <p>This section has changed since the last review.</p>
              )}
            </InformationBox>
          )}
        </Column>
      </Row>
    );

  // Assessment object undefined, if assigned auditor show radio button
  if (
    (reviews.activityReviews?.assessment?.isValid === null || reviews.activityReviews?.assessment === undefined) &&
    isAssignedAuditor &&
    reviewType === ActivityReviewTypeConstants.ASSESSMENT
  )
    reviewSteps.push(
      <Row key={computeComponentKey(dataPath, "assessment")} spacingV="m">
        <Column span={12}>
          <div className="IsReviewValidSection">
            <p>Can you confirm this section is valid?</p>
            <RadioButtonGroup
              data={[
                { key: true, value: "Yes" },
                { key: false, value: "No" },
              ]}
              direction="horizontal"
              value={undefined}
              onChange={(e: RadioChangeEvent) => setIsValidAssessment(e.target.value)}
            />
          </div>
        </Column>
      </Row>
    );

  // Review object defined, if assigned auditor show edit version
  if (reviews.activityReviews?.review !== undefined && reviews.activityReviews?.review?.isValid !== null)
    reviewSteps.push(
      <Row key={computeComponentKey(dataPath, "review")} className="Review" spacingV="m">
        <Column span={12}>
          {isAssignedAuditor && reviewType === ActivityReviewTypeConstants.REVIEW ? (
            <EditableStepReview
              key={computeComponentKey(dataPath, "review")}
              review={reviews.activityReviews.review}
              handleIsValidChange={setIsValidReview}
            />
          ) : (
            <InformationBox
              variant={reviews?.activityReviews.review?.isValid ? "success" : "alert"}
              direction="row"
              corners="rounded"
            >
              {reviews?.activityReviews.review.createdAt &&
              reviews?.activityReviews.review.createdByUserFullname &&
              reviews?.activityReviews.review.createdByUserUuid ? (
                <p>
                  This section was marked as {reviews?.activityReviews.review?.isValid ? "valid" : "invalid"} by
                  <b> {reviews?.activityReviews.review.createdByUserFullname} </b>
                  on <b>{convertDateToStringDDMMYYYY(reviews?.activityReviews.review.createdAt)}</b>
                </p>
              ) : (
                <p>This section has changed since the last review.</p>
              )}
            </InformationBox>
          )}
        </Column>
      </Row>
    );

  // Review object undefined, if assigned auditor show radio button
  if (
    (reviews.activityReviews?.review?.isValid === null || reviews.activityReviews?.review === undefined) &&
    reviewType === ActivityReviewTypeConstants.REVIEW &&
    isAssignedAuditor
  )
    reviewSteps.push(
      <Row key={computeComponentKey(dataPath, "review")} className="Review" spacingV="m">
        <Column span={12}>
          <div className="IsReviewValidSection">
            <p>Can you confirm this section is valid?</p>
            <RadioButtonGroup
              data={[
                { key: true, value: "Yes" },
                { key: false, value: "No" },
              ]}
              direction="horizontal"
              value={undefined}
              onChange={(e: RadioChangeEvent) => setIsValidReview(e.target.value)}
            />
          </div>
        </Column>
      </Row>
    );

  return reviewSteps;
};
