import { GetProjectDetailsResponse } from "../../../../../../service/publicquery";
import { Column, Divider, Row } from "../../../../../../widget";
import {
  ProcessComponents,
  ProcessSteps,
} from "../../../../../shared/projects/project/overview/components/StandardSpecificSection/components";
import {
  StandardSpecificData,
  StandardSpecificDefinition,
  Step,
} from "../../../../../shared/projects/project/overview/components/StandardSpecificSection/types";
import { stepHasDisplayableComponents } from "../../../../../shared/projects/project/overview/components/StandardSpecificSection/utils";
import { usePublicStandardSpecificSection } from "./usePublicStandardSpecificSection";

interface PublicStandardSpecificSectionProps {
  standardSpecificDefinition: StandardSpecificDefinition;
  standardSpecificData: StandardSpecificData;
  projectData?: GetProjectDetailsResponse;
}

export const PublicStandardSpecificSection = ({
  standardSpecificDefinition,
  standardSpecificData,
  projectData,
}: PublicStandardSpecificSectionProps): JSX.Element => {
  const { expressionContext } = usePublicStandardSpecificSection(standardSpecificData, projectData);

  const filteredSteps = standardSpecificDefinition?.steps?.filter((step: Step) =>
    stepHasDisplayableComponents(step, expressionContext)
  );

  return (
    <div>
      {filteredSteps &&
        filteredSteps.map((step: Step, stepIdx: number) => {
          const filteredChildSteps =
            step.steps &&
            step.steps.filter((childStep: Step) => stepHasDisplayableComponents(childStep, expressionContext));

          return (
            <div key={step.key}>
              <Row spacingV="2xl">
                <Column span={12}>
                  <h2>{step.label}</h2>
                </Column>
              </Row>
              {filteredChildSteps &&
                filteredChildSteps.length > 0 &&
                ProcessSteps({
                  steps: filteredChildSteps,
                  key: step.key,
                  data: standardSpecificData,
                  expressionContext,
                  displayOnlyReadonly: true,
                })}
              {step.components &&
                ProcessComponents({
                  components: step.components,
                  key: step.key,
                  expressionContext,
                  displayOnlyReadonly: true,
                })}
              {filteredSteps && stepIdx !== filteredSteps.length - 1 && (
                <Divider type="horizontal" className="StandardSpecificSectionDivider" />
              )}
            </div>
          );
        })}
    </div>
  );
};
