import { useCallback } from "react";

import { EnglandFlagIcon, NorthernIrelandFlagIcon, ScotlandFlagIcon, WalesFlagIcon } from "../../../assets";
import { logError } from "../../../service/error";

export interface LocationFlagProps {
  addressCountryCode: string;
}

export const LocationFlag = ({ addressCountryCode }: LocationFlagProps): JSX.Element | null => {
  const renderContent = useCallback(() => {
    switch (addressCountryCode) {
      case "GB-ENG":
        return <EnglandFlagIcon />;
      case "GB-NIR":
        return <NorthernIrelandFlagIcon />;
      case "GB-SCT":
        return <ScotlandFlagIcon />;
      case "GB-WLS":
        return <WalesFlagIcon />;
      default:
        logError({ error: `No valid location flag could be found for addressCountryCode: ${addressCountryCode}` });
        return null;
    }
  }, [addressCountryCode]);

  return renderContent();
};
