import { OrganisationType } from "../../../models";
import { Button } from "../../../widget";

interface ModalButtonProps {
  organisationType?: OrganisationType;
  overrideText?: string;
  onClick?: () => void;
}

export const ModalButton = ({ organisationType, overrideText, onClick }: ModalButtonProps): JSX.Element => {
  if (overrideText) return <Button text={overrideText} onClick={onClick} />;

  switch (organisationType) {
    case OrganisationType.Developer:
      return <Button text="Contact the developer" onClick={onClick} />;
    case OrganisationType.Standard:
      return <Button text="Contact the code" onClick={onClick} />;
    case OrganisationType.Verifier:
      return <Button text="Contact the verifier" onClick={onClick} />;
    case OrganisationType.Badge:
      return <Button text="Contact the badge" onClick={onClick} />;
    default:
      return <Button text="Contact us" onClick={onClick} />;
  }
};
