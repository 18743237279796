import { Column, Row } from "../../../../../../../../widget";
import { Component, DataType, StandardSpecificData, Step } from "../types";
import { ProcessComponents } from "./ProcessComponents";
import { RenderChart } from "./RenderChart";

interface ProcessChartProps {
  step: Step; // step.components
  key: string;
  data: StandardSpecificData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateData?: (dataPath: string, value: any, dataType: DataType) => void;
  displayOnlyReadonly?: boolean;
}

export const ProcessChart = ({
  step,
  key,
  data,
  expressionContext,
  updateData,
  displayOnlyReadonly = false,
}: ProcessChartProps): JSX.Element => {
  const filteredComponents = step.components?.filter((component: Component) => component.component !== "Chart");
  const filteredChart = step.components?.filter((component: Component) => component.component === "Chart");

  const chartStep = { ...step, components: filteredChart } as Step;

  return (
    <>
      {filteredComponents && (
        <Row spacingV="ml">
          <Column span={12}>
            {ProcessComponents({
              components: filteredComponents,
              key: `${key}.${step.key}`,
              expressionContext,
              updateData,
              displayOnlyReadonly,
              defaultSpan: 12,
            })}
          </Column>
        </Row>
      )}
      {filteredChart && RenderChart({ step: chartStep, key, data, expressionContext })}
    </>
  );
};
