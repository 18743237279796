import { useMemo } from "react";

interface UseComputeDataGridHeightReturnData {
  computedHeight: number;
}

export const useComputeDataGridHeight = (dataLength: number, rowHeight: number): UseComputeDataGridHeightReturnData => {
  const computedHeight = useMemo(() => {
    return dataLength * rowHeight + 2 * rowHeight;
  }, [dataLength, rowHeight]);

  return {
    computedHeight,
  };
};
