import { VerifierPreviewData } from "../../../route/public/verifier/details/preview/usePreview";
import { VerifierDetails } from "../components";
import { usePublicVerifierDetailsPreviewTemplate } from "./usePublicVerifierDetailsPreviewTemplate";

interface PublicVerifierDetailsPreviewTemplateProps {
  previewData: VerifierPreviewData;
}

export const PublicVerifierDetailsPreviewTemplate = ({
  previewData,
}: PublicVerifierDetailsPreviewTemplateProps): JSX.Element => {
  const { dataIsLoading, verifierUuid, verifierDetails, verifierSocialMedia } =
    usePublicVerifierDetailsPreviewTemplate();

  return (
    <div>
      {!dataIsLoading && verifierSocialMedia && previewData && verifierDetails && (
        <VerifierDetails
          verifierUuid={verifierUuid}
          isPreview
          data={{
            ...previewData,
            ...verifierDetails,
            calculatedContactable: false,
            socialMediaData: verifierSocialMedia,
          }}
        />
      )}
    </div>
  );
};
