import "./DatePicker.css";
import "rc-calendar/dist/rc-calendar.css";

// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import Calendar from "rc-calendar";
import { ReactNode } from "react";

import { CalendarDarkIcon } from "../../../../assets";
import { FormLabel } from "../../FormLabel";
import { useDatePicker } from "./useDatePicker";

export interface DatePickerProps {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
  isShow?: boolean;
  label?: string;
  allowLabelDangerousHtml?: boolean;
  tooltip?: ReactNode;
}

export const DatePicker = ({
  value,
  onChange,
  isShow = false,
  label,
  allowLabelDangerousHtml,
  tooltip,
}: DatePickerProps): JSX.Element => {
  const {
    showCalendar,
    selectedDate,
    datePickerRef,
    handleSelect,
    handleOnChange,
    handleCalendarInputClick,
    handleCalendarInputKeyDown,
  } = useDatePicker({
    isShow,
    value,
    onChange,
  });

  return (
    <div ref={datePickerRef} className="DatePicker">
      <FormLabel label={label} tooltip={tooltip} allowDangerousHtml={allowLabelDangerousHtml} />
      <div
        aria-label="Open calendar"
        role="button"
        tabIndex={-1}
        onClick={handleCalendarInputClick}
        onKeyDown={handleCalendarInputKeyDown}
      >
        <CalendarDarkIcon className="CalendarIcon" />
        <Calendar
          className={showCalendar ? "" : "hidden"}
          showToday={false}
          value={selectedDate ? moment(selectedDate) : undefined}
          selectedValue={selectedDate ? moment(selectedDate) : undefined}
          showDateInput
          onSelect={handleSelect}
          onChange={handleOnChange}
          dateInputPlaceholder="Select date"
          format={["DD/MM/YYYY", "D/M/YYYY"]}
        />
      </div>
      <p className="DateFormatText body2">DD/MM/YYYY</p>
    </div>
  );
};
