import { Option } from "rc-select";
import { useEffect, useState } from "react";

import { SelectData } from "../../../models";

interface useAutocompleteProps {
  value?: string | null;
  initialKey?: string | null;
  data: SelectData;
  onSelect: (value: string) => void;
  onChange?: (value: string) => void;
  onTypeSearch?: (value: string) => void;
}

interface useAutocompleteReturnData {
  currentValue: string | null | undefined;
  currentKey: string | null | undefined;
  handleChange: (newVal: string) => void;
  options: JSX.Element[];
  handleSearch: (newVal: string) => void;
  handleSelect: (newKey: string) => void;
}

const TYPE_SEARCH_WAITING_TIME = 400;

export const useAutocomplete = ({
  value,
  initialKey,
  data,
  onSelect,
  onChange,
  onTypeSearch,
}: useAutocompleteProps): useAutocompleteReturnData => {
  const [currentValue, setCurrentValue] = useState(value);
  const [currentKey, setCurrentKey] = useState(initialKey);
  const [options, setOptions] = useState<JSX.Element[]>(
    data.map((kvp) => (
      <Option key={kvp.key} value={kvp.value}>
        {kvp.value}
      </Option>
    ))
  );

  // Regenerate options if data map or initial key changes
  useEffect(() => {
    setOptions(
      data.map((kvp) => (
        <Option key={kvp.key} value={kvp.value}>
          {kvp.value}
        </Option>
      ))
    );
  }, [data]);

  const handleChange = (newVal: string): void => {
    setCurrentValue(newVal);
    setCurrentKey("");
    onSelect("");
    if (onChange) {
      onChange(newVal);
    }
  };

  const handleSearch = (newVal: string): void => {
    setOptions(
      data
        .filter((kvp) => kvp.value?.toString().toLowerCase().includes(newVal.toLowerCase()))
        .map((kvp) => (
          <Option key={kvp.key} value={kvp.value}>
            {kvp.value}
          </Option>
        ))
    );
  };

  const handleSelect = (newVal: string): void => {
    const el = data.filter((kvp) => (kvp.value ? kvp.value : "") === newVal)[0];
    if (el) {
      onSelect(el.key?.toString());
      setCurrentKey(el.key?.toString());
      handleSearch(newVal);
    }
  };

  // If initialKey changes outside the component interaction (eg. onClear) change the value and search for options
  useEffect(() => {
    if (initialKey !== currentKey) {
      const newValue = data?.find((el) => el.key === initialKey)?.value?.toString() || "";
      setCurrentValue(newValue);
      handleSearch(newValue);
    }
  }, [initialKey]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      const selectedValue = data?.find((el) => el.key === currentKey)?.value;
      if (currentValue !== undefined && currentValue !== null && currentValue !== selectedValue) {
        if (onTypeSearch) {
          onTypeSearch(currentValue);
        }
      }
    }, TYPE_SEARCH_WAITING_TIME);

    return () => clearTimeout(debounce);
  }, [currentValue, currentKey]);

  return { currentValue, currentKey, handleChange, options, handleSearch, handleSelect };
};
