import "./UnreadDiscussionCellFormatter.css";

import { CellFormatter, CellFormatterData } from "../../../../../widget/data/DataGrid/models";
import { TabNotificationIndicator } from "../../../../../widget/wayfinding/Tabs/components";

export type UnreadDiscussionCellFormatterData = CellFormatterData & {
  isUnread: boolean;
};

export const UnreadDiscussionCellFormatter: CellFormatter<
  UnreadDiscussionCellFormatterData | null | undefined
> = () => {
  return (value) => {
    if (value === undefined || value === null) return <div />;
    return <div className="DiscussionReadIndicatorCell">{value && <TabNotificationIndicator />}</div>;
  };
};
