import React from "react";

import kanaExpressionInterpreter from "../../../../../../../../lib/KanaExpressionInterpreter";
import { GetProjectDetailsResponse as GetPublicProjectDetailsResponse } from "../../../../../../../../service/publicquery";
import { GetProjectDetailsResponse as GetPrivateProjectDetailsResponse } from "../../../../../../../../service/query";
import { convertDateToStringDDMMYYYY } from "../../../../../../../../utils";
import { ChartData, ChartDataWithSuffix, KanaTooltip, TooltipProps } from "../../../../../../../../widget";
import { Component, DataType, StandardSpecificData, Step } from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stepHasEditableComponents = (step: Step, expressionContext: any): boolean => {
  if (step.steps) {
    return step.steps.some((childStep: Step) => stepHasEditableComponents(childStep, expressionContext));
  }
  if (step.components) {
    return step.components.some((component: Component) => {
      return (
        component.editableConditionExpression !== undefined &&
        kanaExpressionInterpreter(component.editableConditionExpression, expressionContext) &&
        !(
          component.conditionExpression !== undefined &&
          !kanaExpressionInterpreter(component.conditionExpression, expressionContext)
        )
      );
    });
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stepHasDisplayableComponents = (step: Step, expressionContext: any): boolean => {
  if (step.steps) {
    return step.steps.some((childStep: Step) => stepHasDisplayableComponents(childStep, expressionContext));
  }
  if (step.components) {
    return step.components.some((component: Component) => {
      return !(
        component.conditionExpression !== undefined &&
        !kanaExpressionInterpreter(component.conditionExpression, expressionContext)
      );
    });
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reduceComponents = (components: Component[], expressionContext: any): Component[][] => {
  return components
    .filter(
      (component: Component) =>
        !(
          component.conditionExpression !== undefined &&
          !kanaExpressionInterpreter(component.conditionExpression, expressionContext)
        )
    )
    .reduce((acc: Component[][], component: Component, componentIdx: number) => {
      if (componentIdx % 2 === 0) {
        acc.push([component]);
      } else {
        acc[acc.length - 1].push(component);
      }
      return acc;
    }, []);
};

export const reduceChartComponents = (
  components: Component[],
  data: StandardSpecificData,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any
): ChartData => {
  return components.reduce((acc: ChartData, component: Component) => {
    const combinedKey = `${key}.${component.key}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = kanaExpressionInterpreter(`@standardSpecific.${combinedKey}` as string, expressionContext);

    if (value !== undefined) {
      acc[`${component.componentProperties.label}`] = value as number;
    }
    return acc;
  }, {});
};

export const reduceChartComponentsWithSuffix = (
  components: Component[],
  data: StandardSpecificData,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any
): ChartDataWithSuffix => {
  return components.reduce((acc: ChartDataWithSuffix, component: Component) => {
    const combinedKey = `${key}.${component.key}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = kanaExpressionInterpreter(`@standardSpecific.${combinedKey}` as string, expressionContext);

    if (value !== undefined) {
      acc[`${component.componentProperties.label}`] = {
        value: value as number,
        suffix: component.componentProperties.suffix,
      };
    }
    return acc;
  }, {});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const constructStandardSpecificContext = (standardSpecificData: StandardSpecificData): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = {};
  if (standardSpecificData.data !== undefined) {
    Object.keys(standardSpecificData.data).forEach((key) => {
      if (!standardSpecificData.data || standardSpecificData.data[key] === undefined) return;
      const val = standardSpecificData.data[key];
      if (val?.children) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const repeaterValues: any[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        val.children.forEach((repeaterItem: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const repeaterValue: any = {};
          Object.keys(repeaterItem).forEach((rk) => {
            repeaterValue[rk] = repeaterItem[rk];
          });
          repeaterValues.push(repeaterValue);
        });
        res[key] = repeaterValues;
      } else {
        res[key] = val;
      }
    });
  } else if (standardSpecificData.steps && standardSpecificData.steps.length) {
    standardSpecificData.steps.forEach((item) => {
      res[item.key] = constructStandardSpecificContext(item);
    });
  }
  return res;
};

export const createExpressionContext = (
  standardSpecificData: StandardSpecificData,
  projectDetails: GetPrivateProjectDetailsResponse | GetPublicProjectDetailsResponse | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  return { standardSpecific: constructStandardSpecificContext(standardSpecificData), project: projectDetails };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertValueToText = (dataType: DataType, value: any): string => {
  if (value === null || value === undefined) return "";
  switch (dataType) {
    case "Date":
      return convertDateToStringDDMMYYYY(new Date(value));
    case "Boolean":
      if (value === true) return "Yes";
      return "No";
    case "String":
    default:
      return value.toString();
  }
};

export const maybeCreateTooltip = (tooltip?: string): JSX.Element | undefined => {
  if (tooltip) {
    return React.createElement(KanaTooltip, {
      tooltipText: tooltip,
      allowDangerousHtml: true,
    } as TooltipProps);
  }

  return undefined;
};
