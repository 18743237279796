import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PublishBlueIcon, SaveDisketteBlueIcon } from "../../../../../../assets";
import { OrganisationTypeConstants } from "../../../../../../constants";
import { StepItem } from "../../../../../../models";
import { completeDraft, validateAndSaveDraft, ValidateAndSaveDraftResponse } from "../../../../../../service/activity";
import { logError } from "../../../../../../service/error";
import {
  getActivityHistoryDetails,
  GetActivityHistoryDetailsResponse,
  getDiscussionThreads,
  GetDiscussionThreadsResponse,
  GetGroupDetailsResponse,
  GetProjectDetailsResponse,
  SearchActivityHistoryResponse,
} from "../../../../../../service/query";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { deepObjectSearch, useIsLoadingWrapper } from "../../../../../../utils";
import {
  getActivityDashboardTabRoute,
  getActivityViewRoute,
  getProjectActivitiesRoute,
} from "../../../../../../utils/routes";
import { ProfileMenuItemType, StepProps, Toast } from "../../../../../../widget";
import { getNewVersion } from "../../../../../shared/utils";
import { useFetchActivityHistory } from "../../hooks";
import {
  ActivityData,
  ActivityDefinition,
  ActivityDefinitionInfo,
  HasComponents,
  HasData,
  HasDataSteps,
  HasKey,
  HasSteps,
  StaticStepType,
  UploadedDocument,
  UploadedDocumentEnriched,
} from "../../types";
import {
  checkTargetStepkeysAppended,
  createEditComponent,
  createExpressionContext,
  enrichUploadedDocuments,
  filterConditionalSteps,
  findCurrentStepName,
  getActivityDefinitionFields,
  getDataPath,
  getFirstStepKeys,
  getLastStepKeys,
  getNextStepKeys,
  getNextUnvalidatedStepKeys,
  getPreviousStepKeys,
  getStep,
  getStepDocuments,
  hasChangedDocuments,
  hasNextStep,
  hasPreviousStep,
  isActivityComponentChangeableFromUI,
  isNestedStepInvalid,
  mapToStepItem,
  mapToStepProps,
  multiDocumentSetter,
  setStepData,
  singleDocumentSetter,
} from "../../utils";
import { getDiscussionsForComponent } from "../../utils/DiscussionUtils";
import { ActivityWizardContext } from "../context";

interface UseActivityReturnData {
  steps: StepProps[];
  l3Steps: StepItem[] | undefined;
  clickedL3Step: string | undefined;
  setClickedL3Step: Dispatch<SetStateAction<string | undefined>>;
  stepFields: JSX.Element[];
  currentStepName: string | null;
  currentStepKeys: string[];
  hasNext: boolean;
  hasPrevious: boolean;
  activityData: GetActivityHistoryDetailsResponse | undefined;
  isLoading: boolean;
  isIntroduction: boolean;
  isReview: boolean;
  showReviewPage: boolean;
  activityDefinition: ActivityDefinition;
  activityDefinitionInfo: ActivityDefinitionInfo | undefined;
  activityCompletionPercentage: number;
  groupDetails: GetGroupDetailsResponse | undefined;
  projectDetails: GetProjectDetailsResponse | undefined;
  errors: ServiceError[] | undefined;
  currentUserType: OrganisationTypeConstants;
  isExternalActivity: boolean;
  movePrevious: () => void;
  moveNext: () => void;
  moveTo: (targetStepKeys: string[]) => void;
  moveToLastStep: () => void;
  moveToReview: () => void;
  moveToIntroduction: () => void;
  navigateBackToProjectActivities: () => void;
  startOrResumeActivity: () => void;
  isMoveNextLoading: boolean;
  isMovePreviousLoading: boolean;
  isMoveToLastStepLoading: boolean;
  isMoveToReviewLoading: boolean;
  isStartOrResumeActivityLoading: boolean;
  isSaveOrPublishDraftLoading: boolean;
  showVersionConflictModal: boolean;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  newVersionActivityHistory: SearchActivityHistoryResponse | undefined;
  draftActivityHistoryUuid: string | undefined;
  setDraftActivityHistoryUuid: Dispatch<SetStateAction<string | undefined>>;
  setNewVersionActivityHistory: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>;
  showSaveAndCloseDropdown: boolean;
  setShowSaveAndCloseDropdown: Dispatch<SetStateAction<boolean>>;
  saveAndCloseOptions: ProfileMenuItemType[];
  showSaveDraftModal: boolean;
  onConfirmSaveDraftModal: (shouldValidate: boolean) => void;
  onCloseSaveDraftModal: () => void;
  saveAndCloseBtnRef: RefObject<HTMLDivElement>;
}

export const useEdit = (): UseActivityReturnData => {
  const { isExternalActivity, activityHistoryUuid, activityHistoryRowVersion, setActivityHistoryRowVersion } =
    useContext(ActivityWizardContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { currentUserType } = useAuth();

  const saveAndCloseBtnRef = useRef<HTMLDivElement>(null);

  const [currentStepKeys, setCurrentStepKeys] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [currentData, setCurrentData] = useState<any>({});
  const [currentDocuments, setCurrentDocuments] = useState<UploadedDocument[]>([]);
  const [currentStepName, setCurrentStepName] = useState<string | null>(null);

  const [activityDocuments, setActivityDocuments] = useState<UploadedDocumentEnriched[]>([]);
  const [activityCompletionPercentage, setActivityCompletionPercentage] = useState(0);
  const [activityData, setActivityData] = useState<GetActivityHistoryDetailsResponse | undefined>(undefined);
  const [activityDefinitionInfo, setActivityDefinitionInfo] = useState<ActivityDefinitionInfo | undefined>(undefined);
  const [steps, setSteps] = useState<StepProps[]>([]);
  const [l3Steps, setL3Steps] = useState<StepItem[] | undefined>([]);
  const [clickedL3Step, setClickedL3Step] = useState<string | undefined>();
  const [stepFields, setStepFields] = useState<JSX.Element[]>([]);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [activityDefinition, setActivityDefinition] = useState<ActivityDefinition>({
    components: undefined,
    parent: undefined,
    steps: [],
  });
  const [originalActivityDefinition, setOriginalActivityDefinition] = useState<ActivityDefinition>({
    components: undefined,
    parent: undefined,
    steps: [],
  });
  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse | undefined>(undefined);
  const [groupDetails, setGroupDetails] = useState<GetGroupDetailsResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const [isReview, setIsReview] = useState(false);
  const [isIntroduction, setIsIntroduction] = useState(false);

  const [showReviewPage, setShowReviewPage] = useState(false);

  const [activityUuid, setActivityUuid] = useState<string>();
  const [hasChanged, setHasChanged] = useState(false);
  const [showSaveAndCloseDropdown, setShowSaveAndCloseDropdown] = useState(false);

  const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<
    SearchActivityHistoryResponse | undefined
  >();
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string | undefined>();

  const [isDiscussionInitialFetch, setIsDiscussionInitialFetch] = useState(true);

  const [isMoveNextLoading, setIsMoveNextLoading] = useState(false);
  const [isMovePreviousLoading, setIsMovePreviousLoading] = useState(false);
  const [isMoveToLastStepLoading, setIsMoveToLastStepLoading] = useState(false);
  const [isMoveToReviewLoading, setIsMoveToReviewLoading] = useState(false);
  const [isStartOrResumeActivityLoading, setIsStartOrResumeActivityLoading] = useState(false);
  const [isSaveOrPublishDraftLoading, setIsSaveOrPublishDraftLoading] = useState(false);

  const fetchData = useFetchActivityHistory(
    setActivityCompletionPercentage,
    () => undefined, // We don't need to know the activityStatus here
    setActivityData,
    setOriginalActivityDefinition,
    setActivityDefinitionInfo,
    setProjectDetails,
    setGroupDetails,
    activityHistoryUuid
  );

  const filterAndUpdateActivityDefinition = (
    activityData2: ActivityData,
    originalActivityDefinition2?: ActivityDefinition,
    projectDetails2?: GetProjectDetailsResponse,
    groupDetails2?: GetGroupDetailsResponse
  ): ActivityDefinition => {
    // eslint-disable-next-line no-param-reassign
    originalActivityDefinition2 = originalActivityDefinition2 || originalActivityDefinition;
    const expressionContext = createExpressionContext(
      activityData2,
      projectDetails2 || projectDetails,
      groupDetails2 || groupDetails
    );
    const filteredActivityDefinition = filterConditionalSteps(originalActivityDefinition2, expressionContext);
    setActivityDefinition(filteredActivityDefinition);
    return filteredActivityDefinition;
  };

  const updateSteps: (
    discussions: GetDiscussionThreadsResponse[],
    stepKeys: string[],
    activityDefinition2: ActivityDefinition,
    activityData2: ActivityData,
    activityDocuments2: UploadedDocumentEnriched[],
    activityUuid2?: string,
    projectDetails2?: GetProjectDetailsResponse,
    groupDetails2?: GetGroupDetailsResponse
  ) => void = (
    discussions,
    stepKeys,
    activityDefinition2,
    activityData2,
    activityDocuments2,
    activityUuid2 = undefined,
    projectDetails2 = projectDetails,
    groupDetails2 = groupDetails
  ) => {
    const stepComponents = getActivityDefinitionFields(stepKeys, activityDefinition2);
    const { stepDocuments, updatedActivityData } = getStepDocuments(
      stepKeys,
      activityData2,
      stepComponents,
      activityDocuments2
    );
    // eslint-disable-next-line no-param-reassign
    activityData2 = updatedActivityData;
    setCurrentDocuments(stepDocuments);
    const dataPath = getDataPath(stepKeys);

    const stepData = getStep(stepKeys, activityData2);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any = stepData?.data || {};

    setCurrentData(values);

    const hasConditionalFieldsInStep =
      stepComponents.findIndex(
        (c) =>
          // components with conditional expressions
          (c.conditionExpression !== undefined && c.conditionExpression.indexOf(stepKeys[stepKeys.length - 1]) > -1) ||
          // components with document variants of type 'expression'
          (c.componentProperties?.documentVariant !== undefined &&
            c.componentProperties.documentVariant.indexOf(stepKeys[stepKeys.length - 1]) > -1) ||
          // components with children which have conditional expressions (1 level depth)
          (c.children !== undefined &&
            c.children.findIndex(
              (cp) =>
                cp.conditionExpression !== undefined &&
                cp.conditionExpression.indexOf(stepKeys[stepKeys.length - 1]) > -1
            )) ||
          // components with children which have document variants of type 'expression' (1 level depth)
          (c.children !== undefined &&
            c.children.findIndex(
              (cp) =>
                cp.componentProperties?.documentVariant !== undefined &&
                cp.componentProperties.documentVariant.indexOf(stepKeys[stepKeys.length - 1]) > -1
            ))
      ) > -1;

    const expressionContext = createExpressionContext(activityData2, projectDetails2, groupDetails2);

    try {
      setStepFields(
        stepComponents
          .map((c) =>
            createEditComponent(
              c,
              values,
              getDiscussionsForComponent(c.key, c.component, c.children, discussions, stepKeys),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (value: any, key: string) => {
                const hasChangedDocumentsFlag = hasChangedDocuments(activityData2, stepDocuments);

                if (!hasChangedDocumentsFlag) {
                  // skip updates if the value hasn't changed (value types)
                  if (values[key] === value) return;

                  // skip updates if the value hasn't changed (dates)
                  if (
                    value?.toDateString &&
                    values[key]?.toDateString &&
                    value.toDateString() === values[key]?.toDateString()
                  )
                    return;

                  // skip updates if the values are a combination of null and undefined
                  if (
                    (values[key] === undefined && value === null) ||
                    (values[key] === null && value === undefined) ||
                    (values[key] === "" && value === undefined)
                  )
                    return;
                }

                // update data
                values[key] = value;
                setCurrentData(values);
                const updatedActivityDataFromStep = setStepData(stepKeys, activityData2, values);
                updatedActivityDataFromStep.documents = stepDocuments;

                if (isActivityComponentChangeableFromUI(c.component)) {
                  setHasChanged(true);
                }

                // force re-render all components on the page
                if (hasConditionalFieldsInStep || hasChangedDocumentsFlag) {
                  updateSteps(
                    discussions,
                    stepKeys,
                    activityDefinition2,
                    updatedActivityDataFromStep,
                    activityDocuments2,
                    activityUuid2 || activityUuid,
                    projectDetails2,
                    groupDetails2
                  );
                }
              },
              expressionContext,
              // KAN-2918: remove uploaded files
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (document?: UploadedDocumentEnriched, deletedDocumentActivityDocumentTypeUuidAndVariant?: string) => {
                singleDocumentSetter(
                  document,
                  stepDocuments,
                  activityDocuments2,
                  deletedDocumentActivityDocumentTypeUuidAndVariant
                );
                setCurrentDocuments(stepDocuments);
              },
              (key: string, documents: UploadedDocumentEnriched[]) => {
                multiDocumentSetter(key, documents, stepDocuments, activityDocuments2, values);
                setCurrentDocuments(stepDocuments);
              },
              activityDocuments2,
              stepDocuments,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              activityUuid2 || activityUuid!,
              dataPath
            )
          )
          .filter((c) => c !== null) as JSX.Element[]
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logError({ error: e.message });
      throw e;
    }

    // Always validate when data objects are present and step isn't already valid (eg. prepopulated document field)
    if (Object.keys(values).length !== 0 && stepData?.valid !== true) {
      setHasChanged(true);
    }

    setSteps(mapToStepProps(stepKeys, activityData2, currentUserType, activityDefinition2.steps));
    setL3Steps(mapToStepItem(stepKeys, activityData2, currentUserType, activityDefinition2.steps));
    setHasNext(hasNextStep(stepKeys, activityDefinition2));
    setHasPrevious(hasPreviousStep(stepKeys, activityDefinition2));
  };

  useEffect(() => {
    if (!activityHistoryUuid) return;
    if (!isDiscussionInitialFetch) return;

    fetchData().then(async (data) => {
      const filteredActivityDefinition = filterAndUpdateActivityDefinition(
        data.activityData,
        data.activityDefinition,
        data.projectDetails,
        data.groupDetails
      );
      setIsLoading(false);
      // setFormattedActivityData(data.activityData);
      setActivityUuid(data.activityUuid);
      setActivityHistoryRowVersion(data.rowVersion);

      let stepKeys = getNextUnvalidatedStepKeys(
        [],
        filteredActivityDefinition as HasSteps & HasKey & HasComponents,
        data.activityData as HasKey & HasDataSteps & HasData
      );

      const threadUuids = deepObjectSearch(activityData, "discussionThreads").flatMap((value) => value);

      const discussionThreadsRes = await getDiscussionThreads({
        messageLookBack: true,
        objectType: "Activity History",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        objectUuid: activityHistoryUuid!,
        threadUuids,
      });

      // if the activity has been 100% completed hence no more unvalidated steps then move the user to the first activity page
      if (!stepKeys.length) {
        stepKeys = getFirstStepKeys(filteredActivityDefinition);
      }

      // if the activity hasn't been started start on intro page
      if (data.activityCompletionPercentage === 0) {
        setSteps(mapToStepProps([], data.activityData, currentUserType, filteredActivityDefinition.steps, true));
        setL3Steps(mapToStepItem([], data.activityData, currentUserType, filteredActivityDefinition.steps));
        setIsIntroduction(true);
        setHasNext(false);
        setHasPrevious(false);
      } else {
        const enrichedActivityDocuments = enrichUploadedDocuments(data.activityData, data.activityDocuments);
        setActivityDocuments(enrichedActivityDocuments);
        updateSteps(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          discussionThreadsRes.data!,
          stepKeys,
          filteredActivityDefinition,
          data.activityData,
          enrichedActivityDocuments,
          data.activityUuid,
          data.projectDetails,
          data.groupDetails
        );
      }
      setCurrentStepKeys(stepKeys);

      setShowReviewPage(true);
      setIsDiscussionInitialFetch(!isDiscussionInitialFetch);
    });
  }, [activityData, isDiscussionInitialFetch]);

  const checkIfNewVersion = async (): Promise<boolean> => {
    if (!activityHistoryUuid) throw new Error("Missing 'activityHistoryUuid' parameter");
    // TODO check this when adding buttons to the review page
    /*
      TODO NCU: discuss with Luke & Serban. This adds around 1.2s roundtrip when clicking 'Next' or 'Previous';
       can we handle this in any other way?
     */
    // BR-0958
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const activityHistoryDetailsRes = await getActivityHistoryDetails({ activityHistoryUuid: activityHistoryUuid! });
    if (activityUuid && activityHistoryDetailsRes.status !== Status.Error) {
      const newVersion = await getNewVersion(activityUuid, activityHistoryDetailsRes.data?.versionNumber);

      if (newVersion) {
        setShowVersionConflictModal(true);
        setDraftActivityHistoryUuid(activityHistoryUuid);
        setNewVersionActivityHistory(newVersion);

        setErrors([]);
        return true;
      }
    }

    return false;
  };

  const submit = async (shouldValidate = true): Promise<ValidateAndSaveDraftResponse | null> => {
    const hasNewVersion = await checkIfNewVersion();
    if (hasNewVersion) return null;

    const formattedDocuments = currentDocuments.map((d) => ({
      activityDefinitionVersionDocumentTypeUuidAndVariant: d.activityDefinitionVersionDocumentTypeUuidAndVariant,
      activityDocumentHistoryUuid: d.activityDocumentHistoryUuid,
    }));

    const res = await validateAndSaveDraft({
      data: currentData,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      uuid: activityHistoryUuid!,
      dataPath: getDataPath(currentStepKeys),
      documents: formattedDocuments,
      rowVersion: activityHistoryRowVersion,
      validate: shouldValidate,
    });

    if (res.status === Status.Success) {
      if (res.data) {
        setActivityHistoryRowVersion(res.data.rowVersion);
        setErrors([]);
        return res.data;
      }
      logError({ error: "No data received for call to ActivityService.validateAndSaveDraft" });
    }

    if (res.status === Status.Error) {
      setErrors(res?.errors);
    }

    return null;
  };

  const moveToStep = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStepsFn: (data: any, activityDefinition2: ActivityDefinition) => string[],
    shouldValidate?: boolean
  ): Promise<void> => {
    let data = activityData?.data;
    let documents = activityDocuments;

    if (shouldValidate !== undefined || isNestedStepInvalid(currentStepKeys, data)) {
      const res = await submit(shouldValidate);
      if (res == null) return;
      setActivityCompletionPercentage(res.completionPercentage || 0);
      data = res.data;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setActivityData({ ...activityData!, data });
      const enrichedActivityDocuments = enrichUploadedDocuments(data, res.documents);
      setActivityDocuments(enrichedActivityDocuments);
      documents = enrichedActivityDocuments;
    } else {
      setErrors([]);
    }

    // Re-fetch the activity history data, because it contains `discussions`. Necessary when using the left stepper to navigate
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await getActivityHistoryDetails({ activityHistoryUuid: activityHistoryUuid! });

    if (res.status === Status.Success && res.data) {
      data = res.data.data;
    }

    const filteredActivityDefinition = filterAndUpdateActivityDefinition(data);
    const stepKeys = getStepsFn(currentStepKeys, filteredActivityDefinition);
    setCurrentStepKeys(stepKeys);
    setHasChanged(false);
    setIsReview(false);
    setIsIntroduction(false);

    const threadUuids = deepObjectSearch(data, "discussionThreads").flatMap((value) => value);

    const discussionThreadsRes = await getDiscussionThreads({
      messageLookBack: true,
      objectType: "Activity History",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectUuid: activityHistoryUuid!,
      threadUuids,
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    updateSteps(discussionThreadsRes.data!, stepKeys, filteredActivityDefinition, data, documents);
    window.scrollTo(0, 0);
  };

  const moveNext = useIsLoadingWrapper(async (): Promise<void> => {
    await moveToStep(getNextStepKeys, hasChanged ? true : undefined);
  }, setIsMoveNextLoading);

  const movePrevious = useIsLoadingWrapper(async (): Promise<void> => {
    await moveToStep(getPreviousStepKeys, hasChanged ? true : undefined);
  }, setIsMovePreviousLoading);

  const moveTo = async (targetStepKeys: string[]): Promise<void> => {
    await moveToStep(
      (data, activityDefinition2) => checkTargetStepkeysAppended(targetStepKeys, activityDefinition2),
      false
    );
  };

  // This function is used to navigate back to the project activities page
  const navigateBackToProjectActivities = (): void => {
    if (activityData?.activity?.project?.uuid && searchParams.get("location") === "Project") {
      navigate(getProjectActivitiesRoute(activityData?.activity.project.uuid, currentUserType));
    } else if (activityData?.activity.uuid) {
      navigate(getActivityDashboardTabRoute(activityData?.activity.uuid, "history", currentUserType));
    } else {
      navigate(-1);
    }
  };

  const close = async (rowVersionAfterDraftUpdate?: number | Event): Promise<void> => {
    let currentRowVersion = activityHistoryRowVersion;
    if (typeof rowVersionAfterDraftUpdate === "number") currentRowVersion = rowVersionAfterDraftUpdate as number;
    const completeActivityRes = await completeDraft({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      uuid: activityHistoryUuid!,
      rowVersion: currentRowVersion,
    });

    if (completeActivityRes.status === Status.Success) {
      Toast.success({ message: "The activity has been saved successfully" });
    } else {
      setErrors(completeActivityRes.errors);
      return;
    }

    navigateBackToProjectActivities();
  };

  const saveAndClose = async (): Promise<void> => {
    const res = await submit(false);
    if (res == null) return;
    await close(res.rowVersion);
  };

  const onConfirmSaveDraftModal = useIsLoadingWrapper(async (shouldValidate: boolean): Promise<void> => {
    setShowSaveDraftModal(false);

    if (shouldValidate) {
      saveAndClose();
    } else {
      submit(false);

      // We await 0.3 seconds, in order to be sure that activityHistory.data in query schema had time to update
      // This is necessary because -> When we saveDraft in Wizard, we recalculate completion percentage
      // -> In Activity Workflow we get that percentage from query schema, it needs to have been updated already in order to display the correct resume/submit/complete buttons
      await new Promise((r) => {
        setTimeout(r, 300);
      });

      navigateBackToProjectActivities();
    }
  }, setIsSaveOrPublishDraftLoading);

  const onCloseSaveDraftModal = (): void => {
    setShowSaveDraftModal(false);
  };

  const saveAndCloseOptions: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "Save draft",
      action: () => setShowSaveDraftModal(true),
      icon: <SaveDisketteBlueIcon />,
    },
    {
      id: 2,
      value: "Publish draft",
      action: useIsLoadingWrapper(() => saveAndClose(), setIsSaveOrPublishDraftLoading),
      icon: <PublishBlueIcon />,
    },
  ];

  const moveToStaticStep = useIsLoadingWrapper(async (staticStep: StaticStepType): Promise<void> => {
    let data = activityData?.data;
    const documents = activityDocuments;

    if (hasChanged) {
      const res = await submit();
      if (res == null) return;
      data = res.data;
      setActivityCompletionPercentage(res.completionPercentage || 0);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setActivityData({ ...activityData!, data });
      const enrichedActivityDocuments = enrichUploadedDocuments(data, res.documents);
      setActivityDocuments(enrichedActivityDocuments);
      setHasChanged(false);
    }

    const filteredActivityDefinition = filterAndUpdateActivityDefinition(data);

    const threadUuids = deepObjectSearch(activityData, "discussionThreads").flatMap((value) => value);

    const discussionThreadsRes = await getDiscussionThreads({
      messageLookBack: true,
      objectType: "Activity History",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectUuid: activityHistoryUuid!,
      threadUuids,
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    updateSteps(discussionThreadsRes.data!, currentStepKeys, filteredActivityDefinition, data, documents);

    setSteps(mapToStepProps([], data, currentUserType, filteredActivityDefinition.steps, true));
    setL3Steps(mapToStepItem([], data, currentUserType, filteredActivityDefinition.steps));

    if (staticStep === "Review") {
      setIsReview(true);
      setIsIntroduction(false);
    } else if (staticStep === "Introduction") {
      setIsIntroduction(true);
      setIsReview(false);
    }
    setHasNext(false);
    setHasPrevious(false);
  }, setIsMoveToReviewLoading);

  const moveToLastStep = useIsLoadingWrapper(async (): Promise<void> => {
    const lastStepKeys = getLastStepKeys(activityDefinition);
    setCurrentStepKeys(lastStepKeys);
    setIsReview(false);
    setIsIntroduction(false);

    const threadUuids = deepObjectSearch(activityData, "discussionThreads").flatMap((value) => value);

    const discussionThreadsRes = await getDiscussionThreads({
      messageLookBack: true,
      objectType: "Activity History",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectUuid: activityHistoryUuid!,
      threadUuids,
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    updateSteps(discussionThreadsRes.data!, lastStepKeys, activityDefinition, activityData?.data, activityDocuments);
    window.scrollTo(0, 0);
  }, setIsMoveToLastStepLoading);

  const startOrResumeActivity = useIsLoadingWrapper(async (): Promise<void> => {
    const data = activityData?.data;
    const filteredActivityDefinition = filterAndUpdateActivityDefinition(data);

    let stepKeys = getNextUnvalidatedStepKeys(
      [],
      filteredActivityDefinition as HasSteps & HasKey & HasComponents,
      data as HasKey & HasDataSteps & HasData
    );
    // if the activity has been 100% completed hence no more unvalidated steps then move the user to the first activity page
    if (!stepKeys.length) {
      stepKeys = getFirstStepKeys(filteredActivityDefinition);
    }

    setCurrentStepKeys(stepKeys);
    setIsReview(false);
    setIsIntroduction(false);

    const threadUuids = deepObjectSearch(activityData, "discussionThreads").flatMap((value) => value);

    const discussionThreadsRes = await getDiscussionThreads({
      messageLookBack: true,
      objectType: "Activity History",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectUuid: activityHistoryUuid!,
      threadUuids,
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    updateSteps(discussionThreadsRes.data!, stepKeys, activityDefinition, activityData?.data, activityDocuments);
    window.scrollTo(0, 0);
  }, setIsStartOrResumeActivityLoading);

  useEffect(() => {
    setCurrentStepName(findCurrentStepName(steps));
  }, [steps]);

  useEffect(() => {
    if (activityData?.isUnderReview) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      navigate(getActivityViewRoute(activityHistoryUuid!, currentUserType));
    }
  }, [activityData]);

  return {
    steps,
    l3Steps,
    clickedL3Step,
    setClickedL3Step,
    stepFields,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    isReview,
    isIntroduction,
    showReviewPage,
    activityDefinition,
    activityDefinitionInfo,
    activityCompletionPercentage,
    errors,
    currentUserType,
    projectDetails,
    groupDetails,
    isExternalActivity,
    movePrevious,
    moveNext,
    moveTo,
    moveToReview: () => moveToStaticStep("Review"),
    moveToIntroduction: () => moveToStaticStep("Introduction"),
    navigateBackToProjectActivities,
    startOrResumeActivity,
    moveToLastStep,
    isMoveNextLoading,
    isMovePreviousLoading,
    isMoveToLastStepLoading,
    isMoveToReviewLoading,
    isStartOrResumeActivityLoading,
    isSaveOrPublishDraftLoading,
    showVersionConflictModal,
    setShowVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    setDraftActivityHistoryUuid,
    setNewVersionActivityHistory,
    showSaveAndCloseDropdown,
    setShowSaveAndCloseDropdown,
    saveAndCloseOptions,
    showSaveDraftModal,
    onConfirmSaveDraftModal,
    onCloseSaveDraftModal,
    saveAndCloseBtnRef,
  };
};
