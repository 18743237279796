import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";

import { EmbeddingPreferences } from "../../../../../models";
import { updateOrganisationEmbeddingPreferences } from "../../../../../service/organisation";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { areObjectsDeepEqual } from "../../../../../utils";
import { Toast } from "../../../../../widget";

interface UseEmbeddingFormReturnData {
  embedCode: string;
  primaryBgColour: string | null;
  primaryTextColour: string | null;
  secondaryBgColour: string | null;
  secondaryTextColour: string | null;
  hasUnsavedChanges: boolean;
  setPrimaryBgColour: Dispatch<SetStateAction<string | null>>;
  setPrimaryTextColour: Dispatch<SetStateAction<string | null>>;
  setSecondaryBgColour: Dispatch<SetStateAction<string | null>>;
  setSecondaryTextColour: Dispatch<SetStateAction<string | null>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
}

export enum EmbeddingDataKeys {
  primaryBgColour = "primaryBgColour",
  primaryFgColour = "primaryFgColour",
  secondaryBgColour = "secondaryBgColour",
  secondaryFgColour = "secondaryFgColour",
}

export const useEmbeddingForm = (defaultValues: EmbeddingPreferences[]): UseEmbeddingFormReturnData => {
  const { currentOrganisationUuid } = useAuth();
  const [submittedValues, setSubmittedValues] = useState<EmbeddingPreferences[]>(defaultValues);

  const [embedCode, setEmbedCode] = useState("");
  const [primaryBgColour, setPrimaryBgColour] = useState(
    defaultValues.find((element) => element.key === EmbeddingDataKeys.primaryBgColour)?.value || null
  );
  const [primaryTextColour, setPrimaryTextColour] = useState(
    defaultValues.find((element) => element.key === EmbeddingDataKeys.primaryFgColour)?.value || null
  );
  const [secondaryBgColour, setSecondaryBgColour] = useState(
    defaultValues.find((element) => element.key === EmbeddingDataKeys.secondaryBgColour)?.value || null
  );
  const [secondaryTextColour, setSecondaryTextColour] = useState(
    defaultValues.find((element) => element.key === EmbeddingDataKeys.secondaryFgColour)?.value || null
  );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const primaryBgRowVersion: number =
    defaultValues.find((element) => element.key === EmbeddingDataKeys.primaryBgColour)?.rowVersion || 0;
  const primaryFgRowVersion: number =
    defaultValues.find((element) => element.key === EmbeddingDataKeys.primaryFgColour)?.rowVersion || 0;
  const secondaryBgRowVersion: number =
    defaultValues.find((element) => element.key === EmbeddingDataKeys.secondaryBgColour)?.rowVersion || 0;
  const secondaryFgRowVersion: number =
    defaultValues.find((element) => element.key === EmbeddingDataKeys.secondaryFgColour)?.rowVersion || 0;

  // eslint-disable-next-line unused-imports/no-unused-vars
  const computeEmbeddingScript = (colours: EmbeddingPreferences[]): string => {
    return "<script> let x = {} </script>";
  };

  useEffect(() => {
    setEmbedCode(computeEmbeddingScript(defaultValues));
  }, []);

  const resetForm = (): void => {
    setEmbedCode(computeEmbeddingScript(submittedValues));
    setPrimaryBgColour(
      submittedValues.find((element) => element.key === EmbeddingDataKeys.primaryBgColour)?.value || null
    );
    setPrimaryTextColour(
      submittedValues.find((element) => element.key === EmbeddingDataKeys.primaryFgColour)?.value || null
    );
    setSecondaryBgColour(
      submittedValues.find((element) => element.key === EmbeddingDataKeys.secondaryBgColour)?.value || null
    );
    setSecondaryTextColour(
      submittedValues.find((element) => element.key === EmbeddingDataKeys.secondaryFgColour)?.value || null
    );
  };

  const getCurrentFormData = useCallback((): EmbeddingPreferences[] => {
    return [
      {
        key: EmbeddingDataKeys.primaryBgColour,
        value: primaryBgColour,
        rowVersion: primaryBgRowVersion,
      },
      {
        key: EmbeddingDataKeys.primaryFgColour,
        value: primaryTextColour,
        rowVersion: primaryFgRowVersion,
      },
      {
        key: EmbeddingDataKeys.secondaryBgColour,
        value: secondaryBgColour,
        rowVersion: secondaryBgRowVersion,
      },
      {
        key: EmbeddingDataKeys.secondaryFgColour,
        value: secondaryTextColour,
        rowVersion: secondaryFgRowVersion,
      },
    ];
  }, [primaryBgColour, primaryTextColour, secondaryBgColour, secondaryTextColour]);

  useEffect(() => {
    setHasUnsavedChanges(!areObjectsDeepEqual(submittedValues, getCurrentFormData()));
  }, [primaryBgColour, primaryTextColour, secondaryBgColour, secondaryTextColour, submittedValues]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const preferences: EmbeddingPreferences[] = [];
    preferences.push({
      key: EmbeddingDataKeys.primaryBgColour,
      value: primaryBgColour,
      rowVersion: submittedValues.find((element) => element.key === EmbeddingDataKeys.primaryBgColour)?.rowVersion || 1,
    });
    preferences.push({
      key: EmbeddingDataKeys.primaryFgColour,
      value: primaryTextColour,
      rowVersion: submittedValues.find((element) => element.key === EmbeddingDataKeys.primaryFgColour)?.rowVersion || 1,
    });
    preferences.push({
      key: EmbeddingDataKeys.secondaryBgColour,
      value: secondaryBgColour,
      rowVersion:
        submittedValues.find((element) => element.key === EmbeddingDataKeys.secondaryBgColour)?.rowVersion || 1,
    });
    preferences.push({
      key: EmbeddingDataKeys.secondaryFgColour,
      value: secondaryTextColour,
      rowVersion:
        submittedValues.find((element) => element.key === EmbeddingDataKeys.secondaryFgColour)?.rowVersion || 1,
    });

    const res = await updateOrganisationEmbeddingPreferences({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organisationUuid: currentOrganisationUuid!,
      preferences,
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Embedding details changed successfully" });
      setSubmittedValues(preferences);
    }
  };

  const handleCancel = (): void => {
    resetForm();
  };

  return {
    embedCode,
    primaryBgColour,
    primaryTextColour,
    secondaryBgColour,
    secondaryTextColour,
    hasUnsavedChanges,
    setPrimaryBgColour,
    setPrimaryTextColour,
    setSecondaryBgColour,
    setSecondaryTextColour,
    handleSubmit,
    handleCancel,
  };
};
