import "./ReadonlyText.css";

import { useId } from "react";

import { getClickableLink } from "../../../../utils";
import { FormLabel, KanaTooltip } from "../../../forms";

export interface ReadonlyTextProps {
  label?: string;
  allowLabelDangerousHtml?: boolean;
  value: string | number | null | undefined;
  tooltipHeader?: string;
  tooltipText?: string;
  allowTooltipDangerousHtml?: boolean;
  textClass?: "body1" | "body2" | "body3" | "body4" | "link";
  noDataAvailable?: boolean;
  isExternalLink?: boolean;
}

export const ReadonlyText = ({
  label,
  allowLabelDangerousHtml = false,
  value,
  tooltipHeader,
  tooltipText,
  textClass = "body1",
  allowTooltipDangerousHtml = false,
  noDataAvailable = false,
  isExternalLink,
}: ReadonlyTextProps): JSX.Element => {
  const id = useId();

  return (
    <div className="ReadonlyText">
      {label ? (
        <div className="LabelContainer">
          <FormLabel
            className="ReadonlyTextLabel"
            htmlFor={id}
            label={label}
            allowDangerousHtml={allowLabelDangerousHtml}
          />
          {tooltipHeader !== undefined && tooltipText && (
            <KanaTooltip
              tooltipHeader={tooltipHeader}
              tooltipText={tooltipText}
              allowDangerousHtml={allowTooltipDangerousHtml}
            />
          )}
        </div>
      ) : null}
      {isExternalLink && value && typeof value === "string" ? (
        <a
          className="ReadonlyTextExternalLink"
          href={getClickableLink(value)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      ) : (
        <span id={id} className={`${(noDataAvailable && "ReadonlyNoDataAvailable ") || ""}${textClass}`}>
          {value}
        </span>
      )}
    </div>
  );
};
