import { Navigate, Route, Routes } from "react-router-dom";

import { OrganisationPermissionConstants } from "../constants";
import { ActivityPageParams, PrivateActivityDocumentsParams, PrivateProjectDocumentsParams } from "../models";
import {
  AssetManagerDashboardPage,
  AssetManagerProjectsPage,
  AssetManagerSettingsPage,
  AssetManagerUsersEditPage,
  AssetManagerUsersListPage,
  AssetManagerUsersSendInvitationPage,
  ProjectPage,
} from "../route/asset-manager";
import { AssetManagerActivitiesPage } from "../route/asset-manager/activities";
import { AssetManagerChangePasswordPage, AssetManagerChangePersonalDetailsPage } from "../route/asset-manager/profile";
import { AssetManagerOrganisationTab } from "../route/asset-manager/settings/organisation";
import { ActivityViewPage, ActivityWizardProvider } from "../route/developer/activities";
import {
  ActivityDashboardProvider,
  ActivityDiscussionProvider,
  ActivityDiscussionTab,
  ActivityPage,
  DocumentsTab as ActivityDocumentsTab,
  VersionHistoryTab,
} from "../route/shared/activities-dashboard";
import { ActivityDocumentHistoryPage } from "../route/shared/activities-dashboard/documents";
import { Error403Page, Error404Page, Error500Page } from "../route/shared/errors";
import {
  DocumentProvider,
  DocumentsTab,
  IssuancesTab,
  OverviewTab,
  ProjectActivitiesTab,
  ProjectDocumentHistoryPage,
  ProjectProvider,
} from "../route/shared/projects";
import { useRouteGuards } from "./guards";

export const AssetManagerRoutes = (): JSX.Element => {
  const { RequireUserPermission, RequireAssetManagerAuth } = useRouteGuards();

  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <RequireAssetManagerAuth>
            <AssetManagerDashboardPage />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path="activities"
        element={
          <RequireAssetManagerAuth>
            <AssetManagerActivitiesPage />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path={`activities/:${ActivityPageParams.activityHistoryUuid}/view`}
        element={
          <RequireAssetManagerAuth>
            <ActivityWizardProvider>
              <ActivityViewPage />
            </ActivityWizardProvider>
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path={`activities/:${ActivityPageParams.activityHistoryUuid}/view?:location`}
        element={
          <RequireAssetManagerAuth>
            <ActivityViewPage />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path={`activity/:${ActivityPageParams.activityUuid}/document/:${PrivateActivityDocumentsParams.activityDocumentUuid}`}
        element={
          <RequireAssetManagerAuth>
            <ActivityDashboardProvider>
              <ActivityDocumentHistoryPage />
            </ActivityDashboardProvider>
          </RequireAssetManagerAuth>
        }
      />

      <Route
        path={`activity/:${ActivityPageParams.activityUuid}/*`}
        element={
          <ActivityDashboardProvider>
            <ActivityPage />
          </ActivityDashboardProvider>
        }
      >
        <Route
          path="discussions"
          element={
            <ActivityWizardProvider>
              <RequireAssetManagerAuth>
                <ActivityDiscussionProvider>
                  <ActivityDiscussionTab />
                </ActivityDiscussionProvider>
              </RequireAssetManagerAuth>
            </ActivityWizardProvider>
          }
        />
        <Route
          path="documents"
          element={
            <RequireAssetManagerAuth>
              <ActivityDocumentsTab />
            </RequireAssetManagerAuth>
          }
        />
        <Route
          path="history"
          element={
            <ActivityWizardProvider>
              <RequireAssetManagerAuth>
                <VersionHistoryTab />
              </RequireAssetManagerAuth>
            </ActivityWizardProvider>
          }
        />
      </Route>
      <Route
        path="projects"
        element={
          <RequireAssetManagerAuth>
            <AssetManagerProjectsPage />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path="projects/:projectUuid/*"
        element={
          <ProjectProvider>
            <ProjectPage />
          </ProjectProvider>
        }
      >
        <Route
          path="overview"
          element={
            <RequireAssetManagerAuth>
              <OverviewTab />
            </RequireAssetManagerAuth>
          }
        />
        <Route
          path="issuances"
          element={
            <RequireAssetManagerAuth>
              <IssuancesTab />
            </RequireAssetManagerAuth>
          }
        />
        <Route
          path="activities"
          element={
            <RequireAssetManagerAuth>
              <ProjectActivitiesTab />
            </RequireAssetManagerAuth>
          }
        />
        <Route
          path="documents"
          element={
            <RequireAssetManagerAuth>
              <DocumentsTab />
            </RequireAssetManagerAuth>
          }
        />
      </Route>
      <Route
        path={`projects/:${PrivateProjectDocumentsParams.projectUuid}/documents/:${PrivateProjectDocumentsParams.projectDocumentUuid}`}
      >
        <Route
          path=""
          element={
            <RequireAssetManagerAuth>
              <ProjectProvider>
                <DocumentProvider>
                  <ProjectDocumentHistoryPage />
                </DocumentProvider>
              </ProjectProvider>
            </RequireAssetManagerAuth>
          }
        />
      </Route>
      <Route
        path="settings/*"
        element={
          <RequireAssetManagerAuth>
            <AssetManagerSettingsPage />
          </RequireAssetManagerAuth>
        }
      >
        <Route
          path="users"
          element={
            <RequireUserPermission
              userPermission={OrganisationPermissionConstants.READ_ORGANISATION_USERS}
              redirectRoute="/am/settings/organisation"
            >
              <AssetManagerUsersListPage />
            </RequireUserPermission>
          }
        />
        <Route path="organisation" element={<AssetManagerOrganisationTab />} />
        <Route path="" element={<Navigate to="users" replace />} />
      </Route>
      <Route
        path="settings/users/send-invitation"
        element={
          <RequireAssetManagerAuth>
            <AssetManagerUsersSendInvitationPage />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path="settings/users/:userUuid/edit"
        element={
          <RequireAssetManagerAuth>
            <AssetManagerUsersEditPage />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path="profile/change-password"
        element={
          <RequireAssetManagerAuth>
            <AssetManagerChangePasswordPage />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path="profile/change-personal-details"
        element={
          <RequireAssetManagerAuth>
            <AssetManagerChangePersonalDetailsPage />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path="403/access-denied"
        element={
          <RequireAssetManagerAuth>
            <Error403Page />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path="500/application-error"
        element={
          <RequireAssetManagerAuth>
            <Error500Page />
          </RequireAssetManagerAuth>
        }
      />
      <Route
        path="*"
        element={
          <RequireAssetManagerAuth>
            <Error404Page />
          </RequireAssetManagerAuth>
        }
      />
    </Routes>
  );
};
