/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { RcFile } from "rc-upload/lib/interface";
import React, { Dispatch, ForwardedRef, SetStateAction, useEffect, useId, useImperativeHandle, useState } from "react";

import { PreviewFile } from "../../../../models";
import { arrayMove, arraySwap } from "../../../../utils";
import { Toast } from "../../../general";

interface useMultipleFileUploadReturnData {
  id: string;
  previewFiles: PreviewFile[];
  onDragOverDropZone: boolean;
  uploadingFilesCount: number;
  setOnDragOverDropZone: Dispatch<SetStateAction<boolean>>;
  onAction: (file: RcFile) => Promise<string>;
  onRemoveFile: (file: PreviewFile) => void;
  onFileDrop: (droppedOnFileId: string, draggedFileId: string) => void;
  handleOnPreviewFile: (e: React.KeyboardEvent<HTMLDivElement>, pf: PreviewFile) => void;
}

export type DragAndDropOrderBehavior = "swap" | "move";

interface useMultipleFileUploadProps {
  onChange: (previewFiles: PreviewFile[]) => void;
  onFileUpload?: (file: RcFile) => Promise<PreviewFile>;
  onPreviewFile?: (previewFile: PreviewFile) => void;
  value?: PreviewFile[];
  orderBehavior: DragAndDropOrderBehavior;
  maxFileCount: number;
  maxFileSize: number;
  forwardedRef: ForwardedRef<unknown>;
}

export const useMultipleFileUpload = ({
  onChange,
  onFileUpload,
  onPreviewFile,
  value,
  orderBehavior,
  maxFileCount,
  maxFileSize,
  forwardedRef,
}: useMultipleFileUploadProps): useMultipleFileUploadReturnData => {
  const id = useId();
  const [originalFiles, setOriginalFiles] = useState<PreviewFile[]>(value || []);
  const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>(value || []);
  const [onDragOverDropZone, setOnDragOverDropZone] = useState(false);
  const [uploadingFilesCount, setUploadingFilesCount] = useState(0);
  const [actionFiles, setActionFiles] = useState<RcFile[]>([]);

  useImperativeHandle(forwardedRef, () => ({
    clearInput() {
      setPreviewFiles(originalFiles || []);
    },
    save(images: PreviewFile[]) {
      setOriginalFiles(images);
      setPreviewFiles(images);
    },
    setNewPreviewFiles(images: PreviewFile[]) {
      setPreviewFiles(images);
    },
  }));

  // always update the preview files(internal) when the external files change
  useEffect(() => {
    setPreviewFiles(value || []);
  }, [value]);

  // updating external files when the preview files(internal) have changed if they are not in sync
  useEffect(() => {
    const externalValue = value || [];
    if (
      previewFiles.length !== externalValue.length ||
      (previewFiles.length && previewFiles.every((pf) => !externalValue.some((el) => el.uuid === pf.uuid)))
    ) {
      onChange(previewFiles);
    }
  }, [previewFiles]);

  useEffect(() => {
    const processActionFiles = async (files: RcFile[]): Promise<void> => {
      setUploadingFilesCount((count) => count + 1);
      const newPreviewFiles = [...previewFiles];

      for (let i = 0; i < files.length; i++) {
        const fileSize = Number((files[i].size / 1024 / 1024).toFixed(4)); // MB
        if (fileSize > maxFileSize) {
          Toast.error({
            message: `Please select a file which is less than ${maxFileSize}MB`,
          });

          // eslint-disable-next-line no-continue
          continue;
        }

        if (newPreviewFiles.length >= maxFileCount) {
          Toast.error({
            message: `You cannot upload more than ${maxFileCount} files`,
          });

          break;
        }

        if (onFileUpload) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const uploadedPreview = await onFileUpload(files[i]);
            newPreviewFiles.push(uploadedPreview);
          } catch (error) {
            Toast.error({
              message: (error as string) || `Failed to upload ${files[i].name}`,
            });
          }
        } else {
          newPreviewFiles.push({
            file: files[i],
            mimeType: files[i].type,
            uuid: files[i].uid,
            url: URL.createObjectURL(files[i]),
            filename: files[i].name,
          });
        }
      }

      if (newPreviewFiles.length !== previewFiles.length) {
        if (onFileUpload) {
          setOriginalFiles(newPreviewFiles);
        }
        setPreviewFiles(newPreviewFiles);
      }

      setActionFiles([]);
      setUploadingFilesCount((count) => count - 1);
    };

    if (actionFiles.length) {
      processActionFiles(actionFiles);
    }
  }, [actionFiles]);

  const onAction = async (file: RcFile): Promise<string> => {
    setActionFiles((files) => [...files, file]);
    return "";
  };

  const onRemoveFile = (file: PreviewFile): void => {
    const updatedFiles = [...previewFiles.filter((f) => f.uuid !== file.uuid)];
    setPreviewFiles(updatedFiles);
    onChange(updatedFiles);
    URL.revokeObjectURL(file.url);
  };

  const onFileDrop = (droppedOnFileId: string, draggedFileId: string): void => {
    const droppedOnFileIdx = previewFiles.findIndex((el) => el.uuid === droppedOnFileId);
    const draggedFileIdx = previewFiles.findIndex((el) => el.uuid === draggedFileId);
    let updatedFiles;
    if (orderBehavior === "swap") {
      updatedFiles = arraySwap([...previewFiles], droppedOnFileIdx, draggedFileIdx);
    } else {
      updatedFiles = arrayMove([...previewFiles], draggedFileIdx, droppedOnFileIdx);
    }
    setPreviewFiles(updatedFiles);
    onChange(updatedFiles);
  };

  const handleOnPreviewFile = (e: React.KeyboardEvent<HTMLDivElement>, pf: PreviewFile): void => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        if (onPreviewFile) {
          onPreviewFile(pf);
        }
        break;
      default:
        break;
    }
  };

  // Cleanup the created object Urls
  useEffect(() => {
    return () => {
      previewFiles.map((el) => URL.revokeObjectURL(el.url));
    };
  }, []);

  return {
    id,
    previewFiles,
    onDragOverDropZone,
    uploadingFilesCount,
    setOnDragOverDropZone,
    onAction,
    onRemoveFile,
    onFileDrop,
    handleOnPreviewFile,
  };
};
