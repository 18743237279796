import "./Checkbox.css";

import RcCheckbox from "rc-checkbox";
import { useEffect, useState } from "react";

import { FormLabel } from "../../FormLabel";

export interface CheckboxProps {
  label?: string;
  checked?: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
}

export interface CheckboxChangeEventTarget extends CheckboxProps {
  checked: boolean;
}

export interface CheckboxChangeEvent {
  target: CheckboxChangeEventTarget;
  stopPropagation: () => void;
  preventDefault: () => void;
  nativeEvent: MouseEvent;
}

export const Checkbox = ({ label, checked = false, onChange }: CheckboxProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(checked);
  // NCU: The RcCheckbox `onChange` type definition doesn't match the actual type, hence the cast
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const typedOnChange = (e: any): void => {
    setIsChecked(e.target.checked);
    onChange(e);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className="CheckboxWrapper">
      {/* NCU: the input for the label is nested within the Checkbox control */}
      <FormLabel label={label} labelAlignment="right" className="body1">
        <RcCheckbox prefixCls="Checkbox" checked={isChecked} onChange={typedOnChange} />
      </FormLabel>
    </div>
  );
};
