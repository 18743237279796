import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from "react";

import { MarketingAssetsConstants, OrganisationPermissionConstants } from "../../../../../../constants";
import { OurExpertiseData } from "../../../../../../models";
import { ActionButtons, Column, ErrorBox, KanaTooltip, Row, TextArea, Toggle } from "../../../../../../widget";
import { VerifierMarketingAssetsFormChangedAction, VerifierMarketingAssetsSubmitHandler } from "../../models";
import { useOurExpertiseForm } from "./useOurExpertiseForm";

interface ExpertiseFormProps {
  ourExpertiseDefaultValues: OurExpertiseData;
  ourExpertiseOldDefaultValues: OurExpertiseData;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<VerifierMarketingAssetsFormChangedAction>;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

export const OurExpertiseForm = forwardRef(
  (
    {
      ourExpertiseDefaultValues,
      ourExpertiseOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      getCurrentFormsData,
    }: ExpertiseFormProps,
    forwardedRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>
  ): JSX.Element => {
    const {
      enabled,
      content,
      errors,
      isHandleSubmitLoading,
      setEnabled,
      setContent,
      handleSubmit,
      handleCancel,
      hasPermission,
    } = useOurExpertiseForm(
      ourExpertiseDefaultValues,
      ourExpertiseOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      forwardedRef,
      getCurrentFormsData
    );

    return (
      <>
        <Row spacingV="ll">
          <Column span={4}>
            <h2>Our expertise</h2>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={4}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row spacingV="ll">
            <Column span={12}>
              <Toggle label="Show this section on organisation profile" value={enabled} onChange={setEnabled} />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={10}>
              <TextArea
                name="OurExpertise-content"
                label="Content"
                disabled={!enabled}
                tooltip={
                  <KanaTooltip
                    tooltipHeader="What is the expertise section content used for?"
                    tooltipText="This is the content which will be displayed in the ‘expertise’ section on your public profile. You can use this as an opportunity to describe what sets you apart as a business and the expertise you possess. If left blank, this section will not be displayed."
                  />
                }
                value={content || ""}
                onChange={(v) => {
                  setContent(v);
                }}
                displayCharacterCount
                maxLength={MarketingAssetsConstants.OUR_EXPERTISE_CONTENT_MAX_LENGTH}
              />
            </Column>
          </Row>
          {hasPermission(OrganisationPermissionConstants.WRITE) && (
            <Row spacingV="2xl">
              <Column span={4}>
                <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
              </Column>
            </Row>
          )}
        </form>
      </>
    );
  }
);
