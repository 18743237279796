import { VerifierSingleCardTemplate } from "../../../../../templates";
import { SendInvitationForm } from "../../../../shared";
import { useSendInvitation } from "./useSendInvitation";

export const SendInvitation = (): JSX.Element => {
  const { roles, rolesInfo, userSettingsRoute } = useSendInvitation();
  return (
    <VerifierSingleCardTemplate activeElement="Settings" pageTitle="Invite new user">
      <SendInvitationForm roles={roles} rolesInfo={rolesInfo} userSettingsRoute={userSettingsRoute} />
    </VerifierSingleCardTemplate>
  );
};
