// from https://github.com/ant-design/ant-design/blob/master/components/radio/radio.tsx
// with updates
/* eslint react/jsx-props-no-spreading: "off" */
import RcCheckbox, { CheckboxChangeEvent } from "rc-checkbox";
import { composeRef } from "rc-util/lib/ref";
import * as React from "react";

// import { LegacyRef } from "react";
import { CheckboxInterface } from "./Checkbox.Interface";
import RadioGroupContext, { RadioOptionTypeContext } from "./Context";
import type { RadioChangeEvent, RadioProps } from "./Interface";

const InternalRadio: React.ForwardRefRenderFunction<HTMLElement, RadioProps> = (
  { onChange, value, disabled, onMouseEnter, onMouseLeave, className, children, style, ...restProps },
  ref
) => {
  const groupContext = React.useContext(RadioGroupContext);
  const radioOptionTypeContext = React.useContext(RadioOptionTypeContext);

  const innerRef = React.useRef<HTMLElement>();
  const mergedRef = composeRef(ref, innerRef) as React.ForwardRefExoticComponent<unknown>;

  const onChange2 = (e: CheckboxChangeEvent): void => {
    const radioChangeEvent: RadioChangeEvent = {
      nativeEvent: e.nativeEvent as MouseEvent,
      target: {
        checked: true,
        value,
      },
      stopPropagation: () => null,
      preventDefault: () => null,
    };
    onChange?.(radioChangeEvent);
    groupContext?.onChange?.(radioChangeEvent);
  };

  const radioPrefixCls = "Radio";
  const prefixCls =
    (groupContext?.optionType || radioOptionTypeContext) === "button" ? `${radioPrefixCls}-button` : radioPrefixCls;

  const radioProps: CheckboxInterface<CheckboxChangeEvent> = { ...restProps };
  if (groupContext) {
    radioProps.name = groupContext.name;
    radioProps.onChange = onChange2;
    radioProps.checked = value === groupContext.value;
    radioProps.disabled = disabled || groupContext.disabled;
  }
  let wrapperClassString = `${prefixCls}-wrapper`;
  if (radioProps.checked) wrapperClassString += ` ${prefixCls}-wrapper-checked`;
  if (radioProps.disabled) wrapperClassString += ` ${prefixCls}-wrapper-disabled`;
  if (className) wrapperClassString += ` ${className}`;

  return (
    // NCU: the control associated with the label is within the RcCheckbox
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={wrapperClassString} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <RcCheckbox {...radioProps} type="radio" prefixCls={prefixCls} ref={mergedRef} />
      {children !== undefined ? <span>{children}</span> : null}
    </label>
  );
};

const Radio = React.forwardRef<HTMLElement, RadioProps>(InternalRadio);

Radio.displayName = "Radio";

export default Radio;
