import { Dispatch, SetStateAction, useState } from "react";

import { downloadGeneratedDocument } from "../../../route/developer/activities";
import { downloadActivityDocumentsZip } from "../../../service/activity";
import { Status } from "../../../service/Shared";
import toast from "../../general/Toast/Toast";
import { ProfileMenuItemType } from "../../navigation";

interface useDownloadDropdownProps {
  activityHistoryUuid: string;
  currentOnly: boolean;
  setShowDownloadActivityFilesModal: Dispatch<SetStateAction<boolean>>;
}
interface useDownloadDropdownReturnData {
  setShowDownloadDropdown: Dispatch<SetStateAction<boolean>>;
  showDownloadDropdown: boolean;
  isDocumentDownloading: boolean;
  downloadDropdownOptions: ProfileMenuItemType[];
}

export const useDownloadDropdown = ({
  activityHistoryUuid,
  currentOnly,
  setShowDownloadActivityFilesModal,
}: useDownloadDropdownProps): useDownloadDropdownReturnData => {
  const [showDownloadDropdown, setShowDownloadDropdown] = useState(false);
  const [isDocumentDownloading, setIsDocumentDownloading] = useState(false);
  const [downloadDropdownOptions] = useState<ProfileMenuItemType[]>([
    {
      id: 1,
      value: "Output",
      action: async () => {
        setIsDocumentDownloading(true);
        setShowDownloadDropdown(false);

        await downloadGeneratedDocument({ activityHistoryUuid, currentOnly });

        setIsDocumentDownloading(false);
      },
    },
    {
      id: 2,
      value: "Output + Key documents",
      action: async () => {
        const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: true });

        if (res.status === Status.Error) {
          toast.error({ message: "Could not download documents for this activity!" });

          return;
        }

        setShowDownloadDropdown(false);
        setShowDownloadActivityFilesModal(true);
      },
    },
    {
      id: 3,
      value: "Output + All documents",
      action: async () => {
        const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: false });

        if (res.status === Status.Error) {
          toast.error({ message: "Could not download documents for this activity!" });

          return;
        }

        setShowDownloadDropdown(false);
        setShowDownloadActivityFilesModal(true);
      },
    },
  ]);

  return {
    setShowDownloadDropdown,
    showDownloadDropdown,
    isDocumentDownloading,
    downloadDropdownOptions,
  };
};
