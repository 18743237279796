import "./DocumentHistory.css";

import { UploadLightIcon } from "../../../../../../assets";
import { DeveloperSingleCardTemplate } from "../../../../../../templates";
import {
  Button,
  Column,
  CursorDataGrid,
  LayeredSelect,
  Modal,
  ReadonlyText,
  Row,
  TextInput,
} from "../../../../../../widget";
import { UploadDocumentModal } from "../components/UploadDocumentModal";
import { EditDocumentVersionModal } from "./components";
import { DeleteDocumentVersionModal } from "./components/DeleteDocumentVersionModal";
import { useDocumentHistory } from "./useDocumentHistory";

export const DocumentHistory = (): JSX.Element => {
  const {
    projectDisplayName,
    customVariant,
    selectedDocumentVariant,
    documentVariants,
    hasManageProjectDocumentPermission,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    selectedDocumentUuid,
    latestDocumentVersion,
    projectDocumentUuid,
    refreshDocuments,
    isUpdateDocumentLoading,
    setRefreshDocuments,
    showDeletePublicModal,
    setShowDeletePublicModal,
    showDeleteModal,
    setShowDeleteModal,
    showEditModal,
    setShowEditModal,
    showUploadModal,
    onChangeDocumentVariant,
    setCustomVariant,
    setShowUploadModal,
    handleBackClick,
    updateDocument,
    onChange,
    backBtnText,
  } = useDocumentHistory();
  return (
    <div className="ProjectDocumentHistoryDetails">
      {projectDisplayName && (
        <DeveloperSingleCardTemplate
          pageTitle={projectDisplayName}
          handleBackClick={handleBackClick}
          backButtonText={backBtnText}
          activeElement="Projects"
        >
          <Row spacingV="ll">
            <Column span={9}>
              <h2>Document details</h2>
            </Column>
            {hasManageProjectDocumentPermission && (
              <Column span={3}>
                <Row justify="end">
                  <Button
                    variant="primary"
                    // eslint-disable-next-line no-console
                    onClick={() => setShowUploadModal(true)}
                    text="Upload new version"
                    iconPosition="left"
                    icon={<UploadLightIcon />}
                  />
                </Row>
              </Column>
            )}
          </Row>
          <Row spacingV="ll">
            <Column span={12}>
              <p>The document versions of this document type and variant are shown here.</p>
            </Column>
          </Row>
          <Row spacingV="ll" justify="between">
            <Column span={3}>
              <ReadonlyText label="Document type" value={latestDocumentVersion?.documentType.name} />
            </Column>
            {latestDocumentVersion?.documentType.supportsVariants && hasManageProjectDocumentPermission ? (
              <>
                <Column span={3}>
                  <LayeredSelect
                    data={documentVariants || []}
                    value={selectedDocumentVariant}
                    label="Document variant"
                    onChange={(value) => onChangeDocumentVariant(value)}
                  />
                </Column>
                <Column span={3}>
                  <div className="CustomVariant">
                    <TextInput
                      disabled={selectedDocumentVariant !== "Custom"}
                      value={customVariant}
                      name="Custom variant"
                      label="Custom variant"
                      onChange={(value) => setCustomVariant(value)}
                    />
                  </div>
                </Column>
                <Column span={2}>
                  <Row spacingV="ll" justify="end">
                    <div className="SubmitButton">
                      <Button
                        isLoading={isUpdateDocumentLoading}
                        variant="primary"
                        // eslint-disable-next-line no-console
                        onClick={updateDocument}
                        hasNoPadding
                        text="Submit"
                      />
                    </div>
                  </Row>
                </Column>
              </>
            ) : (
              latestDocumentVersion?.documentVariant && (
                <Column span={6}>
                  <ReadonlyText
                    label="Document variant"
                    value={latestDocumentVersion.documentVariant.name}
                    tooltipHeader="Document variant"
                    tooltipText="The document variant distinguishes documents that have been uploaded to the project of the same type."
                  />
                </Column>
              )
            )}
          </Row>
          <Row spacingV="ll">
            <Column span={6}>
              <h2>Version history</h2>
            </Column>
          </Row>
          <CursorDataGrid
            columns={columns}
            clientSide={false}
            filterable
            onChange={onChange}
            data={[]}
            refresh={refreshDocuments}
            noDataMessage="No documents to display"
            dataIsLoading={dataIsLoading}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </DeveloperSingleCardTemplate>
      )}
      <Modal
        show={showDeletePublicModal}
        title="Cannot delete document"
        onClose={() => setShowDeletePublicModal(false)}
      >
        <Row spacingV="ll" justify="center">
          <Column span={12}>
            <p className="body1">
              As this is a document from the registry, a public version must be available before this version can be
              deleted.
            </p>
          </Column>
        </Row>
        <Row justify="center">
          <Column span={6}>
            <Button text="Close" onClick={() => setShowDeletePublicModal(false)} />
          </Column>
        </Row>
      </Modal>
      <DeleteDocumentVersionModal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        deleteDocumentUuid={selectedDocumentUuid}
      />
      <EditDocumentVersionModal
        show={showEditModal}
        closeModal={() => setShowEditModal(false)}
        editDocumentUuid={selectedDocumentUuid}
      />
      <UploadDocumentModal
        show={showUploadModal}
        closeModal={() => setShowUploadModal(false)}
        refreshDocuments={refreshDocuments}
        setRefreshDocuments={setRefreshDocuments}
        uploadDocumentUuid={projectDocumentUuid}
        uploadDocumentLatestVersionUuid={latestDocumentVersion?.uuid}
      />
    </div>
  );
};
