import "./UploadDocumentModal.css";

import { Dispatch, SetStateAction } from "react";

import { ServiceError } from "../../../../../../../service/Shared";
import {
  ActionButtons,
  Column,
  ErrorBox,
  FormLabel,
  InformationBox,
  KanaTooltip,
  LayeredSelect,
  Modal,
  MultipleFileUpload,
  MultiSelect,
  RadioButtonGroup,
  Row,
  SingleFileUpload,
  TextInput,
} from "../../../../../../../widget";
import { RadioChangeEvent } from "../../../../../../../widget/forms/Input/RadioButtonGroup/components";
import { useUploadDocumentModal } from "./useUploadDocumentModal";

interface UploadDocumentModalProps {
  show: boolean;
  closeModal: () => void;
  refreshDocuments: boolean;
  setRefreshDocuments: Dispatch<SetStateAction<boolean>>;
  uploadDocumentLatestVersionUuid?: string;
  uploadDocumentUuid?: string;
}

export const UploadDocumentModal = ({
  show,
  closeModal,
  refreshDocuments,
  setRefreshDocuments,
  uploadDocumentLatestVersionUuid,
  uploadDocumentUuid,
}: UploadDocumentModalProps): JSX.Element => {
  const {
    fileUploadRef,
    projectDetails,
    latestDocumentDetails,
    visibility,
    isGroup,
    maxFileSize,
    supportsMultipleFiles,
    relatedOrganisations,
    selectedDocumentVariant,
    documentTypes,
    documentVariants,
    isHandleSubmitLoading,
    handleVisibilityChange,
    setIsGroup,
    setFiles,
    setSelectedOrganisations,
    setSelectedDocumentVariant,
    setCustomVariant,
    handleDocumentTypeChange,
    handleCancel,
    handleSubmit,
    showExistsWarning,
    showExternalRegistryWarning,
    errors,
  } = useUploadDocumentModal({
    closeModal,
    refreshDocuments,
    setRefreshDocuments,
    uploadDocumentUuid,
    uploadDocumentLatestVersionUuid,
  });

  return (
    <div className="UploadDocumentModal">
      <Modal show={show} title="Upload document" onClose={handleCancel}>
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error: ServiceError) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row spacingV="l" justify="center">
            <Column span={6} hasNoPadding>
              {latestDocumentDetails?.documentType ? (
                <TextInput
                  name="Document type"
                  label="Document type"
                  disabled
                  value={latestDocumentDetails.documentType.name}
                />
              ) : (
                <div className="ModalLayeredDropdown">
                  <LayeredSelect
                    data={documentTypes || []}
                    label="Document type"
                    onChange={(value) => handleDocumentTypeChange(value)}
                    placeholder="Select document type"
                  />
                </div>
              )}
            </Column>
          </Row>
          {((latestDocumentDetails?.documentType.supportsVariants || documentVariants) &&
            latestDocumentDetails?.documentVariant) ||
          latestDocumentDetails?.customVariant ? (
            <Row spacingV="l" justify="center">
              <Column span={6} hasNoPadding>
                <TextInput
                  name="Variant"
                  label="Variant"
                  disabled
                  value={
                    latestDocumentDetails.customVariant
                      ? latestDocumentDetails.customVariant
                      : latestDocumentDetails.documentVariant?.name
                  }
                />
              </Column>
            </Row>
          ) : (
            documentVariants && (
              <Row spacingV="l" justify="center">
                <Column span={6} hasNoPadding>
                  <div className="ModalLayeredDropdown">
                    <LayeredSelect
                      data={documentVariants || []}
                      label="Variant"
                      onChange={(value) => setSelectedDocumentVariant(value)}
                      placeholder="Select variant"
                    />
                  </div>
                </Column>
              </Row>
            )
          )}
          {selectedDocumentVariant === "Custom" && (
            <Row spacingV="l" justify="center">
              <Column span={6} hasNoPadding>
                <TextInput name="Description" label="Description" onChange={(value) => setCustomVariant(value)} />
              </Column>
            </Row>
          )}
          <Row spacingV="l" justify="center">
            {latestDocumentDetails?.documentType.supportsMultipleFiles || supportsMultipleFiles ? (
              <Column span={6} hasNoPadding>
                <MultipleFileUpload
                  ref={fileUploadRef}
                  title="Upload documents"
                  accept="*"
                  maxFileSize={maxFileSize}
                  onChange={(e) => setFiles(e.map((f) => f.file as File))}
                />
              </Column>
            ) : (
              <Column span={6} hasNoPadding>
                <div className="ModalFileUpload">
                  <SingleFileUpload
                    ref={fileUploadRef}
                    label="Upload document"
                    maxFileSize={maxFileSize}
                    onChange={(e) => setFiles([e])}
                  />
                </div>
              </Column>
            )}
          </Row>
          <Row spacingV="l" justify="center">
            <Column span={6} hasNoPadding>
              <div className="ModalRadioButtons">
                <RadioButtonGroup
                  label="Public visibility"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What is public visibility?"
                      tooltipText="Choose whether this document is visible in the public directory or not"
                    />
                  }
                  data={[
                    { key: false, value: "Public" },
                    { key: true, value: "Private" },
                  ]}
                  direction="vertical"
                  value={visibility}
                  onChange={(e: RadioChangeEvent) => handleVisibilityChange(e.target.value)}
                />
              </div>
            </Column>
          </Row>
          {relatedOrganisations && relatedOrganisations?.length !== 0 && visibility && (
            <Row spacingV="l" justify="center">
              <Column span={6} hasNoPadding>
                <FormLabel
                  label="Organisation access"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What is organisation access?"
                      tooltipText="If the document is public, all organisations with project access can view the document"
                    />
                  }
                  tooltipJustify="left"
                />
                <div className="ModalDropdown">
                  <MultiSelect data={relatedOrganisations} onChange={(values) => setSelectedOrganisations(values)} />
                </div>
              </Column>
            </Row>
          )}
          <Row spacingV="l" justify="center">
            <Column span={6} hasNoPadding>
              <div className="ModalRadioButtons">
                <RadioButtonGroup
                  disabled={latestDocumentDetails != null || projectDetails?.group == null}
                  label="Group document"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What is a group document?"
                      tooltipText="A group document will be shared across all projects in a group with this project"
                    />
                  }
                  data={[
                    { key: true, value: "Yes" },
                    { key: false, value: "No" },
                  ]}
                  direction="vertical"
                  value={isGroup}
                  onChange={(e: RadioChangeEvent) => setIsGroup(e.target.value)}
                />
              </div>
            </Column>
          </Row>
          {showExistsWarning && (
            <Row spacingV="ll" justify="center">
              <Column span={6} hasNoPadding>
                <InformationBox title="This document type already exists" variant="alert">
                  <p className="body2">
                    If you need to replace an existing document, locate the document and click the ‘upload new version’
                    icon. If you’re creating a new document listing, proceed with this upload.
                  </p>
                </InformationBox>
              </Column>
            </Row>
          )}
          {showExternalRegistryWarning && (
            <Row spacingV="ll" justify="center">
              <Column span={6} hasNoPadding>
                <InformationBox title="Diverging from registry" variant="alert">
                  <p className="body2">
                    By updating this document, you will be replacing a public document that was taken from your external
                    registry. The project’s public documents should continue to align with the external registry to
                    ensure accuracy. If a new version is uploaded to the external registry, it will be loaded into Kana
                    and become a public version.
                  </p>
                </InformationBox>
              </Column>
            </Row>
          )}
          <Row justify="center">
            <Column span={6} hasNoPadding>
              <ActionButtons
                submitButtonLabel="Upload document"
                isLoading={isHandleSubmitLoading}
                onCancel={handleCancel}
                fullWidth
              />
            </Column>
          </Row>
        </form>
      </Modal>
    </div>
  );
};
