import { ComponentProperties } from "../../../../route/shared/projects/project/overview/components/StandardSpecificSection/types";
import { Column, Row } from "../../../layout";
import { ProjectStatusKPI } from "../ProjectStatusKPI";
import { TotalCarbonImpactKPI } from "../TotalCarbonImpactKPI";
import { VcuPiuKPI } from "../VcuPiuKPI";

interface KPIGroupProps {
  projectStatus: string;
  carbonImpactType: ComponentProperties | undefined;
  carbonImpactValue: number;
  hasVcuPiuKPI?: boolean;
  vcus: number;
  pius: number;
}

export const KPIGroup = ({
  projectStatus,
  carbonImpactType,
  carbonImpactValue,
  hasVcuPiuKPI = false,
  vcus,
  pius,
}: KPIGroupProps): JSX.Element => {
  return (
    <div className="KPIGroup">
      {pius === 0 && vcus === 0 ? (
        <Row>
          <Column span={carbonImpactType ? 6 : 12}>
            <ProjectStatusKPI status={projectStatus} />
          </Column>
          {carbonImpactType && (
            <Column span={6}>
              <TotalCarbonImpactKPI carbonImpactValue={carbonImpactValue} carbonImpactType={carbonImpactType} />
            </Column>
          )}
        </Row>
      ) : (
        <Row>
          <Column span={4}>
            <ProjectStatusKPI status={projectStatus} graphBarFlexSize={3} />
          </Column>
          <Column span={4}>
            <TotalCarbonImpactKPI carbonImpactValue={carbonImpactValue} carbonImpactType={carbonImpactType} />
          </Column>
          {hasVcuPiuKPI && (
            <Column span={4}>
              <VcuPiuKPI vcus={vcus} pius={pius} />
            </Column>
          )}
        </Row>
      )}
    </div>
  );
};
