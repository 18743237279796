import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { EditFileBlueIcon } from "../../../../../assets";
import { CursorChangeProps, ResultType, SortCriteria } from "../../../../../models";
import { searchOrganisationUsers, SearchOrganisationUsersResponse } from "../../../../../service/query";
import { ResultData } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { flattenObject } from "../../../../../utils";
import { getUserEditRouteByUuid, getUserSettingsSendInvitationRoute } from "../../../../../utils/routes";
import { DataGridColumnDefinition, dataGridMapFilterCriteria } from "../../../../../widget";
import { IconCellFormatterData } from "../../../../../widget/data/DataGrid/models";

interface useListReturnData {
  dataGridColumns: DataGridColumnDefinition[];
  isLoading: boolean;
  onDataGridChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  onNewUserClick: () => void;
}

export const useList = (): useListReturnData => {
  const navigate = useNavigate();

  const { currentOrganisationUuid, currentUserType } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const dataGridColumns: DataGridColumnDefinition[] = [
    { key: "user.firstName", name: "First Name", dataType: "string" },
    { key: "user.lastName", name: "Last Name", dataType: "string" },
    { key: "user.email", name: "Email", dataType: "string" },
    {
      key: "status",
      name: "Status",
      formatter: "userStatusPill",
      dataType: "string",
      filterable: false,
      alignment: "center",
    },
    { key: "role", name: "Role", dataType: "string" },
    {
      key: "editLink",
      name: "Edit",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
  ];
  const pageSize = 10;

  const formatSorting = useCallback(
    (sorting: SortCriteria[]): SortCriteria[] =>
      sorting.map((el) => {
        if (el.key === "status") {
          return { ...el, key: "active" };
        }
        return el;
      }),
    []
  );

  const formatData = useCallback(
    (responseData: SearchOrganisationUsersResponse | undefined): ResultData[] =>
      responseData?.results?.map((d) => {
        const result = flattenObject({
          ...d,
          status: d.active ? "Active" : "Disabled",
        });

        result.editLink = {
          action: () => navigate(getUserEditRouteByUuid(d.user.uuid, currentUserType)),
          icon: <EditFileBlueIcon width={20} height={20} />,
        } as IconCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onDataGridChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await searchOrganisationUsers({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organisationUuid: currentOrganisationUuid!,
      paging: {
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
        limit: pageSize,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: formatSorting(sorting).map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      /* eslint-enable */
      filter: { results: dataGridMapFilterCriteria(filtering) },
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging.startCursor || "",
            endCursor: response.data?.paging.endCursor || "",
            pageSize,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setIsLoading(false);
      });

    return data;
  };

  const onNewUserClick = (): void => navigate(getUserSettingsSendInvitationRoute(currentUserType));

  return {
    dataGridColumns,
    isLoading,
    onDataGridChange,
    onNewUserClick,
  };
};
