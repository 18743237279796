import "./MultipleFileDownload.css";

import { useId } from "react";

import { EyeBlueIcon, FileCornerBlueIcon } from "../../../../assets";
import { PreviewFile } from "../../../../models";
import { canPreviewFile } from "../../../../utils";
import { Button } from "../../Button";
import { FormLabel } from "../../FormLabel";

export interface MultipleFileDownloadProps {
  inputFiles: PreviewFile[];
  title?: string;
  allowLabelDangerousHtml?: boolean;
  maxFileCount?: number;
  downloadHandler?: (previewFile: PreviewFile) => void;
  onPreviewFile?: (previewFile: PreviewFile) => void;
}

const defaultDownloadHandler = (el: PreviewFile): void => window.open(el.url, "_blank")?.focus();

export const MultipleFileDownload = ({
  title = "Files",
  allowLabelDangerousHtml = false,
  inputFiles,
  maxFileCount = 10,
  downloadHandler = undefined,
  onPreviewFile,
}: MultipleFileDownloadProps): JSX.Element => {
  const id = useId();

  return (
    <div className="MultipleFileDownloadWrapper">
      <FormLabel htmlFor={id} label={title} allowDangerousHtml={allowLabelDangerousHtml} />
      {inputFiles.map(
        (el, index) =>
          index + 1 <= maxFileCount && (
            <div key={el.uuid}>
              <div
                className="MultipleFileDownload"
                role="button"
                onClick={() => (downloadHandler ? downloadHandler(el) : defaultDownloadHandler(el))}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (downloadHandler) downloadHandler(el);
                    else defaultDownloadHandler(el);
                  }
                }}
                tabIndex={0}
              >
                <FileCornerBlueIcon />
                <h3>{el.filename}</h3>
              </div>
              {onPreviewFile && canPreviewFile(el.mimeType) && (
                <div className="MultipleFileDownloadPreviewBtn">
                  <Button
                    variant="tertiary"
                    icon={<EyeBlueIcon />}
                    iconPosition="left"
                    text="Preview file"
                    size="small"
                    onClick={() => onPreviewFile(el)}
                  />
                </div>
              )}
            </div>
          )
      )}
    </div>
  );
};
