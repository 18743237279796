import "./ActivityWizardContent.css";

import { ServiceError } from "../../../../../../service/Shared";
import { Column, ErrorBox, Row } from "../../../../../../widget";

export interface ActivityWizardContentProps {
  fields: JSX.Element[];
  errors: ServiceError[] | undefined;
}

export const ActivityWizardContent = ({ fields, errors }: ActivityWizardContentProps): JSX.Element => {
  return (
    <div>
      {errors && errors.length > 0 && (
        <Row spacingV="ml" className="ActivityWizardContentErrorBox">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      {fields.map((f) => f)}
    </div>
  );
};
