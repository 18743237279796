// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint @typescript-eslint/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// StatsService
import { axios } from "../axios";
import { BaseResponse, Status } from "../Shared";
import { SaveEventRequest } from "./Types";

export const saveEvent = async (req: SaveEventRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "post",
      url: "stats/event",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};
