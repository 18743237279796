import { OrganisationPermissionConstants } from "../../../constants";
import { DeveloperSingleCardWithTabsTemplate } from "../../../templates";
import { useAuth } from "../../../useAuth";
import { getUserSettingsOrganisationRoute, getUserSettingsRoute } from "../../../utils/routes";

const Settings = (): JSX.Element => {
  const { hasPermission, currentUserType } = useAuth();

  return (
    <div>
      <DeveloperSingleCardWithTabsTemplate
        activeElement="Settings"
        pageTitle="Settings"
        tabsList={
          hasPermission(OrganisationPermissionConstants.READ_ORGANISATION_USERS)
            ? [
                { route: getUserSettingsRoute(currentUserType), label: "Users" },
                { route: getUserSettingsOrganisationRoute(currentUserType), label: "Organisation" },
              ]
            : [{ route: getUserSettingsOrganisationRoute(currentUserType), label: "Organisation" }]
        }
      />
    </div>
  );
};

export default Settings;
