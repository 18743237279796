import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";

import { GetProjectDetailsResponse } from "../../service/publicquery";

interface IPublicContext {
  projectDetails?: GetProjectDetailsResponse;
  setProjectDetails: Dispatch<SetStateAction<GetProjectDetailsResponse | undefined>>;
}

const defaultState = {
  projectDetails: undefined,
};

const PublicContext = createContext<IPublicContext | undefined>(undefined);

export const PublicProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse | undefined>(
    defaultState.projectDetails
  );

  const memoedValue = useMemo(
    () => ({
      projectDetails,
      setProjectDetails,
    }),
    [projectDetails]
  );
  return <PublicContext.Provider value={memoedValue}>{children}</PublicContext.Provider>;
};

export const usePublicContext = (): IPublicContext => {
  const context = useContext(PublicContext);
  if (context === undefined) {
    throw new Error("usePublicContext must be used within a PublicProvider");
  }
  return context;
};
