import { Button, TextArea } from "../../../../forms";
import { MessageThread } from "../../models/Message";
import { DiscussionThread } from "../DiscussionThread";
import { useDiscussionThreadWithReply } from "./useDiscussionThreadWithReply";

export type DiscussionThreadWithReplyProps = {
  thread: MessageThread | undefined;
  setThreadData: (data: MessageThread) => void;
  canReply?: boolean;
};

export const DiscussionThreadWithReply = ({
  thread,
  setThreadData,
  canReply,
}: DiscussionThreadWithReplyProps): JSX.Element => {
  const {
    replyTextAreaShown,
    showReplyTextArea,
    hideReplyTextArea,
    handleSendReply,
    setReplyText,
    textAreaRef,
    deleteMessage,
    deleteThread,
    resolveThread,
    isHandleSendReplyLoading,
  } = useDiscussionThreadWithReply({ thread, setThreadData });

  const replyControls = (): JSX.Element =>
    replyTextAreaShown ? (
      <div className="DiscussionNewReplyContainer">
        <TextArea
          name="DiscussionNewReply"
          forwardRef={textAreaRef}
          displayCharacterCount
          maxLength={500}
          value=""
          onChange={setReplyText}
        />
        <div className="DiscussionNewMessageActions">
          <Button text="Send reply" onClick={handleSendReply} isLoading={isHandleSendReplyLoading} />
          <Button text="Cancel" variant="secondary" onClick={hideReplyTextArea} />
        </div>
      </div>
    ) : (
      <div>
        <Button text="Reply" onClick={showReplyTextArea} />
      </div>
    );

  return (
    <>
      <DiscussionThread
        thread={thread}
        onDeleteMessage={deleteMessage}
        onDeleteThread={deleteThread}
        onResolveThread={resolveThread}
        setThreadData={setThreadData}
      />
      {canReply && replyControls()}
    </>
  );
};
