import "./VisualMediaModal.css";

import { Dispatch, SetStateAction } from "react";

import { YoutubePlayer } from "../../YoutubePlayer";
import { VisualMediaModalBackdrop } from "./VisualMediaModalBackdrop";

interface VisualMediaModalProps {
  show: boolean;
  currentDisplayedImage: string;
  onClose: Dispatch<SetStateAction<boolean>>;
  onVisualMediaChange: Dispatch<SetStateAction<number>>;
  currentVisualMediaNumber: number;
  maxVisualMediaNumber: number;
  videoUrl: string | null | undefined;
}

export const VisualMediaModal = ({
  show,
  currentDisplayedImage,
  onClose,
  onVisualMediaChange,
  currentVisualMediaNumber,
  maxVisualMediaNumber,
  videoUrl,
}: VisualMediaModalProps): JSX.Element | null => {
  return show ? (
    <>
      <div className="VisualMediaModal">
        {!videoUrl ? (
          <img src={currentDisplayedImage} alt="currentImage" />
        ) : (
          (currentVisualMediaNumber === maxVisualMediaNumber && <YoutubePlayer videoUrl={videoUrl} />) ||
          (!(currentVisualMediaNumber === maxVisualMediaNumber) && (
            <img src={currentDisplayedImage} alt="currentImage" />
          ))
        )}
      </div>
      ;
      <VisualMediaModalBackdrop
        show={show}
        onClick={onClose}
        onVisualMediaChange={onVisualMediaChange}
        currentVisualMediaNumber={currentVisualMediaNumber}
        maxVisualMediaNumber={maxVisualMediaNumber}
      />
    </>
  ) : null;
};
