import "./AccessTab.css";

import { ProjectPermissionConstants } from "../../../../../constants";
import { Button, Column, CursorDataGrid, Row } from "../../../../../widget";
import { useAccessTabReceivedData } from "./useAccessTabReceivedData";
import { useAccessTabSentData } from "./useAccessTabSentData";

export const AccessTab = (): JSX.Element => {
  const { receivedColumns, defaultSortingCriteriaReceived, receivedDataIsLoading, receivedOnChange, hasPermission } =
    useAccessTabReceivedData();

  const { onNewInvitation, sentColumns, defaultSortingCriteriaSent, sentDataIsLoading, sentOnChange } =
    useAccessTabSentData();

  return (
    <div className="SettingsProjectAccessTab">
      <Row spacingV="ll">
        <Column span={12}>
          <div className="AccessHeader">
            <h2>Invites sent</h2>
            {hasPermission(ProjectPermissionConstants.MANAGE_PROJECT_ORGANISATION_INVITATION) && (
              <Button text="New invitation" onClick={onNewInvitation} />
            )}
          </div>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <CursorDataGrid
            columns={sentColumns}
            clientSide={false}
            filterable
            onChange={sentOnChange}
            data={[]}
            noDataMessage="No invitations to display"
            dataIsLoading={sentDataIsLoading}
            defaultSortingCriteria={defaultSortingCriteriaSent}
          />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Invites received</h2>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <CursorDataGrid
            columns={receivedColumns}
            clientSide={false}
            filterable
            onChange={receivedOnChange}
            data={[]}
            noDataMessage="No invitations to display"
            dataIsLoading={receivedDataIsLoading}
            defaultSortingCriteria={defaultSortingCriteriaReceived}
          />
        </Column>
      </Row>
    </div>
  );
};
