import React from "react";

export type DropdownItemProps = {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  onClick?: () => void;
  parentOnClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const DropdownItem = ({ children, className = "", onClick, parentOnClick }: DropdownItemProps): JSX.Element => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (parentOnClick) parentOnClick(e);
    else e.stopPropagation();
    if (onClick) onClick();
  };

  return (
    <div
      className={`DropdownItem body1 ${className}`}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={() => true}
    >
      {children}
    </div>
  );
};
