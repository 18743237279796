import { Button, Column, Modal, Row } from "../../../../widget";

interface SendAccessInvitationModalProps {
  show: boolean;
  onSendAccessInvitation: () => void;
  closeModal: () => void;
}

export const SendAccessInvitationModal = ({
  show,
  onSendAccessInvitation,
  closeModal,
}: SendAccessInvitationModalProps): JSX.Element => {
  return (
    <Modal show={show} title="Send user access?" onClose={closeModal}>
      <Row justify="center" spacingV="2xl">
        <Column span={12}>
          <p className="body1">
            Giving people access to this project will allow them to view/edit this project and any of the associated
            documents or workflows.
          </p>
          <p className="body1">Are you sure you want to send this invitation?</p>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={12}>
          <Button text="Yes" onClick={onSendAccessInvitation} />
          <Button variant="secondary" text="No, take me back" onClick={closeModal} />
        </Column>
      </Row>
    </Modal>
  );
};
