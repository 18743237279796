import "./OverviewComponent.css";

import { Link } from "react-router-dom";

import { ProjectStatusConstants, ProjectTypeConstants } from "../../../../../../constants";
import { BadgeData } from "../../../../../../models";
import { GetProjectDetailsResponse } from "../../../../../../service/publicquery";
import { getPublicProjectTypeRoute } from "../../../../../../utils/routes";
import { ChartData, Column, InformationBox, KPIGroup, Pill, Row, StackedBarChart, Tag } from "../../../../../../widget";
import { StandardSpecificDefinition } from "../../../../../shared/projects/project/overview/components/StandardSpecificSection/types";
import { AboutTheCodeSection } from "../AboutTheCodeSection";
import { PublicCertificationsSection } from "../PublicCertificationsSection";
import { useOverviewComponent } from "./useOverviewComponent";

interface OverviewComponentProps {
  projectDetails: GetProjectDetailsResponse | undefined;
  chartData: ChartData | undefined;
  tags: string[] | null;
  badges: BadgeData[];
  certifications: BadgeData[];
  isPreview?: boolean;
  standardSpecificDefinition: StandardSpecificDefinition;
}
export const OverviewComponent = ({
  projectDetails,
  chartData,
  tags,
  badges,
  certifications,
  isPreview = false,
  standardSpecificDefinition,
}: OverviewComponentProps): JSX.Element => {
  const { carbonImpactType, carbonImpactValue } = useOverviewComponent(projectDetails, standardSpecificDefinition);

  return (
    <>
      <div className="PublicProjectTabSection">
        <Row spacingV="ml">
          <Column span={12}>
            <h2>About the project</h2>
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            {projectDetails?.listing?.content?.longDescription ? (
              <p className="body1" style={{ whiteSpace: "pre-line" }}>
                {projectDetails?.listing.content.longDescription}
              </p>
            ) : (
              <p className="ReadonlyNoDataAvailable body1" style={{ whiteSpace: "pre-line" }}>
                {projectDetails?.developer?.displayName || "The developer"} has not yet provided a description for this
                project
              </p>
            )}
          </Column>
        </Row>
        <Row spacingV={tags || projectDetails?.listing?.pilotProject ? "ll" : undefined}>
          <Column span={12}>
            <div className="ProjectTypeTagsContainer">
              <h3>Project type:</h3>
              {!isPreview && projectDetails?.projectType.uuid ? (
                <Link
                  to={{
                    pathname: getPublicProjectTypeRoute(projectDetails?.projectType?.uuid),
                    search: `?projectUuid=${projectDetails?.uuid}`,
                  }}
                >
                  <Pill
                    label={projectDetails?.projectType.displayName || ""}
                    variantMap={ProjectTypeConstants.PROJECT_TYPE_VARIANT_MAP}
                  />
                </Link>
              ) : (
                <Pill
                  label={projectDetails?.projectType.displayName || ""}
                  variantMap={ProjectTypeConstants.PROJECT_TYPE_VARIANT_MAP}
                />
              )}
            </div>
          </Column>
        </Row>
        {tags && (
          <Row spacingV={projectDetails?.listing?.pilotProject ? "ll" : undefined}>
            <Column span={12}>{projectDetails?.tags.map((el) => <Tag key={el} label={el} />)}</Column>
          </Row>
        )}
        {projectDetails?.listing?.pilotProject && (
          <Row>
            <Column span={12}>
              <InformationBox variant="pilotProject" title="This is a pilot project">
                A pilot project is a small-scale trial to test the feasibility of a methodology or approach, typically
                associated with an emerging code or standard which is still formulating its rules and requirements.
              </InformationBox>
            </Column>
          </Row>
        )}
      </div>
      {projectDetails?.status && (
        <Row spacingV="ml">
          <Column span={12}>
            <KPIGroup
              projectStatus={projectDetails.status}
              carbonImpactType={carbonImpactType}
              carbonImpactValue={carbonImpactValue}
              hasVcuPiuKPI={
                ProjectStatusConstants.DRAFT && projectDetails.status !== ProjectStatusConstants.PRE_DEVELOPMENT
              }
              vcus={projectDetails.cachedVcuQuantity}
              pius={projectDetails.cachedPiuQuantity}
            />
          </Column>
        </Row>
      )}
      {projectDetails &&
        projectDetails.status !== ProjectStatusConstants.DRAFT &&
        projectDetails.status !== ProjectStatusConstants.PRE_DEVELOPMENT &&
        chartData &&
        Object.keys(chartData).length > 0 && (
          <div className="PublicProjectTabSection">
            <Row spacingV="xl">
              <Column span={12}>
                <h2>Carbon offset profile</h2>
              </Column>
            </Row>
            <Row>
              <Column span={12}>
                <StackedBarChart
                  data={chartData}
                  mainLabel="Verified carbon units (VCUs)"
                  secondaryChart={{
                    label: "Pending issuance units (PIUs)",
                    data: Object.keys(chartData).map(
                      (label) => projectDetails.cachedVcuQuantity + projectDetails.cachedPiuQuantity - chartData[label]
                    ),
                  }}
                />
              </Column>
            </Row>
          </div>
        )}
      {projectDetails && !!certifications.length && (
        <div className="PublicProjectTabSection">
          <PublicCertificationsSection
            projectUuid={projectDetails.uuid}
            badges={certifications}
            isPreview={isPreview}
            certification
          />
        </div>
      )}
      {projectDetails && !!badges.length && (
        <div className="PublicProjectTabSection">
          <PublicCertificationsSection projectUuid={projectDetails.uuid} badges={badges} isPreview={isPreview} />
        </div>
      )}
      {projectDetails && (
        <div className="PublicProjectTabSection">
          <AboutTheCodeSection
            organisationUuid={projectDetails.standard.uuid}
            projectUuid={projectDetails.uuid}
            isPreview={isPreview}
            isCodeCompliant={
              projectDetails.status !== ProjectStatusConstants.DRAFT &&
              projectDetails.status !== ProjectStatusConstants.PRE_DEVELOPMENT
            }
          />
        </div>
      )}
    </>
  );
};
