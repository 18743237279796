import { OrganisationTypeConstants } from "../../../constants";
import { Modal } from "../../../widget";
import { ContactUsModalForm } from "./form";

interface ContactUsModalProps {
  organisationType: OrganisationTypeConstants;
  developerName: string;
  developerPhoneNumber: string | null | undefined;
  show: boolean;
  closeModal: () => void;
}

export const ContactUsModal = ({
  organisationType,
  developerName,
  developerPhoneNumber,
  show,
  closeModal,
}: ContactUsModalProps): JSX.Element => {
  return (
    <Modal show={show} title="Contact Us" onClose={closeModal}>
      <ContactUsModalForm
        organisationType={organisationType}
        developerName={developerName}
        developerPhoneNumber={developerPhoneNumber}
        closeModal={closeModal}
      />
    </Modal>
  );
};
