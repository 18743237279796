import { MutableRefObject, useEffect, useRef, useState } from "react";

interface useWorkflowReturnData {
  hasNoCompleteButtonPadding: boolean;
  hasNoSubmitButtonPadding: boolean;
  areRightActivitiesHidden: boolean;
  onRightArrowClick: () => void;
  onLeftArrowClick: () => void;
  workflowRef: MutableRefObject<null | HTMLDivElement>;
  activitiesWrapperWidth: string;
}

const LEFT_ARROW_SPACING = 56;

export const useWorkflow = (): useWorkflowReturnData => {
  const workflowRef = useRef<HTMLDivElement>(null);
  const [hasNoSubmitButtonPadding, setHasNoSubmitButtonPadding] = useState(false);
  const [hasNoCompleteButtonPadding, setHasNoCompleteButtonPadding] = useState(false);

  const [areRightActivitiesHidden, setAreRightActivitiesHidden] = useState(true);
  const [activitiesWrapperWidth, setActivitiesWrapperWidth] = useState("");

  const onLeftArrowClick = (): void => {
    if (workflowRef.current) {
      workflowRef.current.scrollLeft = -(workflowRef.current.scrollWidth - workflowRef.current.clientWidth);
    }
    setAreRightActivitiesHidden(true);
  };

  const onRightArrowClick = (): void => {
    if (workflowRef.current) {
      workflowRef.current.scrollLeft = workflowRef.current.scrollWidth - workflowRef.current.clientWidth;
    }
    setAreRightActivitiesHidden(false);
  };

  useEffect(() => {
    if (workflowRef.current) {
      setActivitiesWrapperWidth(`${(workflowRef.current.clientWidth - LEFT_ARROW_SPACING) / 2}px`);
    }
  }, []);

  useEffect(
    () =>
      workflowRef.current?.offsetWidth && workflowRef.current?.offsetWidth > 786
        ? setHasNoCompleteButtonPadding(false)
        : setHasNoCompleteButtonPadding(true),
    [workflowRef.current?.offsetWidth]
  );

  useEffect(
    () =>
      workflowRef.current?.offsetWidth && workflowRef.current?.offsetWidth > 751
        ? setHasNoSubmitButtonPadding(false)
        : setHasNoSubmitButtonPadding(true),
    [workflowRef.current?.offsetWidth]
  );

  return {
    hasNoSubmitButtonPadding,
    hasNoCompleteButtonPadding,
    areRightActivitiesHidden,
    onRightArrowClick,
    onLeftArrowClick,
    workflowRef,
    activitiesWrapperWidth,
  };
};
