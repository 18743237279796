import "./TotalMarketValueTotalReturns.css";

import { TotalMarketValueTotalReturnsData } from "../../../../models";
import { getFormattedDate } from "../../../../utils";
import { Toggle } from "../../../forms";
import { PercentageChangeTrend } from "../components";
import { useTotalMarketValueTotalReturnsKPI } from "./useTotalMarketValueTotalReturnsKPI";

interface TotalMarketValueTotalReturnsKPIProps {
  totalMarketValue: TotalMarketValueTotalReturnsData;
  totalReturns: TotalMarketValueTotalReturnsData;
}

export const TotalMarketValueTotalReturnsKPI = ({
  totalMarketValue,
  totalReturns,
}: TotalMarketValueTotalReturnsKPIProps): JSX.Element => {
  const { toggleTotalMarketValueTotalReturns, setToggleTotalMarketValueTotalReturns, formattedValue } =
    useTotalMarketValueTotalReturnsKPI({ totalMarketValue, totalReturns });

  return (
    <div className="TotalMarketValueTotalReturnsContainer">
      <p className="body01">{toggleTotalMarketValueTotalReturns ? "Total returns" : "Total market value"}</p>
      <div className="InfoSection">
        <div className="PercentageChange">
          <PercentageChangeTrend
            percentageChange={
              toggleTotalMarketValueTotalReturns ? totalReturns.percentageChange : totalMarketValue.percentageChange
            }
          />
        </div>
        <div className="Value">
          <h1 className="Number">£{formattedValue[0]}</h1>
          <h1 className="Magnitude">{formattedValue[1]}</h1>
        </div>
        <p className="body2">
          {getFormattedDate(
            toggleTotalMarketValueTotalReturns ? totalReturns.lastUpdated : totalMarketValue.lastUpdated
          )}
        </p>
      </div>
      <div className="ToggleSection">
        <p className={`body3 ${toggleTotalMarketValueTotalReturns ? "Inactive" : ""}`}>Market value</p>
        <Toggle
          value={toggleTotalMarketValueTotalReturns}
          variant="secondary"
          onChange={(e) => setToggleTotalMarketValueTotalReturns(e)}
        />
        <p className={`body3 ${toggleTotalMarketValueTotalReturns ? "" : "Inactive"}`}>Returns</p>
      </div>
    </div>
  );
};
