import "./ContentSection.css";

interface ContentSectionProps {
  title: string;
  content: string;
}

export const ContentSection = ({ title, content }: ContentSectionProps): JSX.Element => {
  return (
    <div className="ContentSection">
      <h2>{title}</h2>
      <p className="body1">{content}</p>
    </div>
  );
};
