import "./Card.css";

import { CardProps } from "./CardProps";

export const Card = ({ children, height }: CardProps): JSX.Element => {
  return (
    <div className="Card" style={{ height: height ?? "auto" }}>
      {children}
    </div>
  );
};
