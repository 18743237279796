import { useContext, useEffect, useState } from "react";

import { GetProjectDetailsResponse } from "../../../../../service/query";
import { ProjectContext } from "../../../../shared/projects/project/ProjectContext";
import { MarketingSettingsData } from "./models";

interface useMarketingSettingsReturnData {
  defaultValues: MarketingSettingsData | undefined;
  projectDetails: GetProjectDetailsResponse | undefined;
}

export const useMarketingSettings = (): useMarketingSettingsReturnData => {
  const { projectDetails } = useContext(ProjectContext);
  const [defaultValues, setDefaultValues] = useState<MarketingSettingsData | undefined>(undefined);

  const initializeData = (): void => {
    const images = projectDetails?.listing?.content?.images?.flatMap((imgUuid: string) => {
      const listingFile = projectDetails?.listingFiles?.find((lf) => lf.uuid === imgUuid);
      if (listingFile) {
        return {
          mimeType: listingFile.file.mimeType,
          uuid: listingFile.uuid,
          url: listingFile.file.url,
          filename: listingFile.file.filename,
        };
      }
      return [];
    });

    setDefaultValues({
      availableForSale: !!projectDetails?.listing?.availableForSale,
      showPriceInDirectory: !!projectDetails?.listing?.showPriceInDirectory,
      seekingFunding: !!projectDetails?.listing?.seekingFunding,
      pilotProject: !!projectDetails?.listing?.pilotProject,
      contactOptOut: !!projectDetails?.listing?.contactOptOut,
      contactAlternativeEmail: projectDetails?.listing?.contactAlternativeEmail,
      hidden: !!projectDetails?.listing?.hidden,

      shortDescription: projectDetails?.listing?.content?.shortDescription,
      longDescription: projectDetails?.listing?.content?.longDescription,
      videoUrl: projectDetails?.listing?.content?.videoUrl,
      images,
    });
  };

  useEffect(() => {
    if (projectDetails) {
      initializeData();
    }
  }, [projectDetails]);

  return {
    defaultValues,
    projectDetails,
  };
};
