import "./Footer.css";

export const Footer = (): JSX.Element => {
  return (
    <div className="Footer">
      <div className="Links">
        <a
          href="https://www.kana.earth/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="body2"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.kana.earth/legal/cookie-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="body2"
        >
          Cookie Policy
        </a>
        <a
          href="https://www.kana.earth/legal/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
          className="body2"
        >
          Terms of Service
        </a>
      </div>
    </div>
  );
};
