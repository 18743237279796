import "./RiskKPI.css";

import { RiskGuage1Icon, RiskGuage2Icon, RiskGuage3Icon, RiskGuage4Icon, RiskGuage5Icon } from "../../../../assets";

interface RiskKPIProps {
  risk: number;
}

export const RiskKPI = ({ risk }: RiskKPIProps): JSX.Element => {
  let riskRate = "";
  let riskGuage;
  switch (risk) {
    case 1:
      riskRate = "low risk";
      riskGuage = <RiskGuage1Icon />;
      break;
    case 2:
      riskRate = "low risk";
      riskGuage = <RiskGuage2Icon />;
      break;
    case 3:
      riskRate = "medium risk";
      riskGuage = <RiskGuage3Icon />;
      break;
    case 4:
      riskRate = "high risk";
      riskGuage = <RiskGuage4Icon />;
      break;
    case 5:
      riskRate = "high risk";
      riskGuage = <RiskGuage5Icon />;
      break;
    default:
  }

  return (
    <div className="RiskContainer">
      <p className="body01">Risk</p>
      <div className="GaugeSection">{riskGuage}</div>
      <div className="RateSection">
        <h3>{risk}</h3>
        <p className="body2">{riskRate}</p>
      </div>
    </div>
  );
};
