import "./LogoForm.css";

import { Dispatch } from "react";

import { OrganisationPermissionConstants, OrganisationTypeConstants } from "../../../../../../constants";
import { ActionButtons, Column, ErrorBox, KanaTooltip, Logo, Row, SingleFileUpload } from "../../../../../../widget";
import { OrganisationFormChangedAction } from "../../models";
import { useLogoForm } from "./useLogoForm";

interface LogoFormProps {
  objectUuid: string;
  objectType: string;
  organisationDefaultLogo: string | null;
  dispatch: Dispatch<OrganisationFormChangedAction>;
}

export const LogoForm = ({ objectUuid, objectType, organisationDefaultLogo, dispatch }: LogoFormProps): JSX.Element => {
  const {
    logoUploadRef,
    displayedLogo,
    errors,
    isHandleSubmitLoading,
    currentUserType,
    setLogo,
    handleCancel,
    handleSubmit,
    hasPermission,
  } = useLogoForm(objectUuid, objectType, organisationDefaultLogo, dispatch);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="2xl">
          <Column span={5}>
            <SingleFileUpload
              label="New logo"
              informationLabel="The image should be maximum 450px x 450px and for best results use a square image"
              maxFileSize={0.5}
              ref={logoUploadRef}
              tooltip={
                currentUserType === OrganisationTypeConstants.DEVELOPER && (
                  <KanaTooltip
                    tooltipHeader="Where will the logo show?"
                    tooltipText="This is the logo which will be displayed on your public developer profile"
                  />
                )
              }
              onChange={(f) => {
                setLogo(f);
              }}
            />
          </Column>
          <Column span={2} offset={1}>
            <div className="CurrentLogoContainer">
              <Logo displayedLogo={displayedLogo} displayedLabel="Current logo" />
            </div>
          </Column>
        </Row>
        {hasPermission(OrganisationPermissionConstants.WRITE) && (
          <Row>
            <Column span={5}>
              <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
            </Column>
          </Row>
        )}
      </form>
    </>
  );
};
