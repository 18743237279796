import { FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { inviteOrganisationToProject } from "../../../../../../../service/organisation";
import { searchDevelopers } from "../../../../../../../service/query";
import { ServiceError, Status } from "../../../../../../../service/Shared";
import { getProjectAccessRoute } from "../../../../../../../utils/routes";
import { Toast } from "../../../../../../../widget";
import { ProjectContext } from "../../../../../../shared/projects/project/ProjectContext";

interface useInviteFormReturnData {
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onCancel: () => void;
  developerUuid?: string;
  developerList?: { key: string; value: string }[];
  onDeveloperTypeSearch: (value: string) => void;
  onDeveloperSelect: (value: string) => void;
  accessLevelKey?: string;
  onAccessLevelChange: (value: string) => void;
  onSendInvitation: () => void;
  showSendInvitationModal: boolean;
  onCloseSendInvitationModal: () => void;
  errors: ServiceError[] | undefined;
}

export const useInviteForm = (): useInviteFormReturnData => {
  const navigate = useNavigate();

  const { projectDetails } = useContext(ProjectContext);

  const [accessLevelKey, setAccessLevelKey] = useState<string>();
  const [developerUuid, setDeveloperUuid] = useState<string>();
  const [developerList, setDeveloperList] = useState<{ key: string; value: string }[]>([]);
  const [showSendInvitationModal, setShowSendInvitationModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const onCloseSendInvitationModal = (): void => {
    setShowSendInvitationModal(false);
  };

  const onOpenSendInvitationModal = (): void => {
    setShowSendInvitationModal(true);
  };

  const onSendInvitation = async (): Promise<void> => {
    setShowSendInvitationModal(false);
    if (projectDetails?.uuid && accessLevelKey && developerUuid) {
      const response = await inviteOrganisationToProject({
        projectUuid: projectDetails?.uuid,
        targetOrganisationUuid: developerUuid,
        targetRole: accessLevelKey,
      });

      if (response.status === Status.Success) {
        Toast.success({ message: "Access invitation sent" });
        navigate(getProjectAccessRoute(projectDetails.uuid));
      }

      if (response.status === Status.Error) {
        Toast.error({ message: "Access invitation failed to sent" });
        setErrors(response?.errors);
      }
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const errorList = [];

    if (!projectDetails?.uuid) {
      errorList.push({
        code: "NO_PROJECT",
        message: "A project must be selected",
      });
    }

    if (!developerUuid) {
      errorList.push({
        code: "NO_DEVELOPER",
        message: "A developer must be selected",
      });
    }

    if (!accessLevelKey) {
      errorList.push({
        code: "NO_ACCESS_LEVEL",
        message: "An access level must be selected",
      });
    }
    if (errorList.length) {
      setErrors(errorList);
    } else {
      onOpenSendInvitationModal();
    }
  };

  const getDevelopers = useCallback(
    async (search: string) => {
      const response = await searchDevelopers({
        paging: {
          limit: 10,
          beforeCursor: "",
          afterCursor: "",
        },
        filter: {
          results: {
            displayName: {
              operator: "startsWith",
              value: search,
            },
          },
        },
        excludedProjectUuid: projectDetails?.uuid || null,
      });
      if (response.status === Status.Success && response.data?.results) {
        const list = response.data.results.flatMap((el) => {
          if (el.parentOrganisation && el.parentOrganisation.uuid && el.displayName) {
            return { key: el.parentOrganisation.uuid, value: el.displayName };
          }
          return [];
        });

        setDeveloperList(list);
      }
    },
    [projectDetails]
  );

  useEffect(() => {
    getDevelopers("");
  }, []);

  const onCancel = (): void => {
    setAccessLevelKey(undefined);
    setDeveloperUuid(undefined);
  };

  const onAccessLevelChange = (value: string): void => {
    setAccessLevelKey(value);
  };

  const onDeveloperTypeSearch = (value: string): void => {
    getDevelopers(value);
  };

  const onDeveloperSelect = (value: string): void => {
    setDeveloperUuid(value);
  };

  return {
    onSubmit,
    onCancel,
    developerUuid,
    developerList,
    onDeveloperSelect,
    onDeveloperTypeSearch,
    accessLevelKey,
    onAccessLevelChange,
    onSendInvitation,
    showSendInvitationModal,
    onCloseSendInvitationModal,
    errors,
  };
};
