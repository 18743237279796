import "../Repeater.css";

import { ReactElement, ReactNode } from "react";
import { createPortal } from "react-dom";

import { TrashDark } from "../../../../../assets";
import { CreateChildrenRecursiveArgs, RepeaterData } from "../../../../../models";
import { GetDiscussionThreadsResponse } from "../../../../../service/query";
import { Divider } from "../../../../general";
import { Column, Row } from "../../../../layout";
import { Button } from "../../../Button";
import { useRepeaterRowContent } from "./useRepeaterRowContent";

interface RepeaterRowContentProps {
  createChildrenRecursive: (args: CreateChildrenRecursiveArgs) => ReactNode[] | null | undefined;
  template: ReactNode[];
  isFixedSize: boolean;
  currentData: RepeaterData[];
  removeButtonText: string | undefined;
  handleRemoveRow: (removeIdx: number) => void;
  idx: number;
  discussions: GetDiscussionThreadsResponse[] | undefined;
}

export const RepeaterRowContent = ({
  createChildrenRecursive,
  template,
  isFixedSize,
  currentData,
  removeButtonText,
  handleRemoveRow,
  idx,
  discussions,
}: RepeaterRowContentProps): JSX.Element => {
  const { repeaterRef, deleteButtonPosition } = useRepeaterRowContent();

  return (
    <>
      <div ref={repeaterRef} className="RepeaterRowContent">
        {createChildrenRecursive({
          children: template,
          fn: (child: ReactElement) => child,
          currentData,
          idx,
          currentDiscussions: discussions,
        })}
        {isFixedSize && idx < currentData.length - 1 && (
          <div className="RepeaterRowDividerContainer">
            <Row>
              <Column span={12}>
                <Divider type="horizontal" className="RepeaterRowDivider" thickness={3} />
              </Column>
            </Row>
          </div>
        )}
      </div>

      {createPortal(
        !isFixedSize && (
          <div
            className="RepeaterRowRemoveButton"
            style={{
              visibility: currentData.length === 1 ? "hidden" : "visible",
              top: deleteButtonPosition.top,
              left: deleteButtonPosition.left,
              position: "absolute",
            }}
          >
            <div>
              <Button
                text={removeButtonText || "Remove"}
                variant="secondary"
                size="small"
                iconPosition="left"
                icon={<TrashDark />}
                onClick={() => handleRemoveRow(idx)}
              />
            </div>
          </div>
        ),
        document.getElementById("root") as HTMLElement
      )}
    </>
  );
};
