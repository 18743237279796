import {
  Dispatch,
  FormEvent,
  ForwardedRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { StandardConstants } from "../../../../../../constants";
import { RadioButtonGroupData } from "../../../../../../models";
import { updateVerifierListing } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual, useIsLoadingWrapper } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import {
  CodesAndStandardsData,
  VerifierFormNames,
  VerifierMarketingAssetsFormChangedAction,
  VerifierMarketingAssetsSubmitHandler,
} from "../../models";

interface UseCodesAndStandardsFormReturnData {
  enabled: boolean;
  content: string | null;
  woodlandChecked: boolean;
  peatlandChecked: boolean;
  woodlandDescription: string | undefined;
  peatlandDescription: string | undefined;
  radioButtonGroupData: RadioButtonGroupData;
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  setContent: Dispatch<SetStateAction<string | null>>;
  setWoodlandChecked: Dispatch<SetStateAction<boolean>>;
  setPeatlandChecked: Dispatch<SetStateAction<boolean>>;
  setWoodlandDescription: Dispatch<SetStateAction<string>>;
  setPeatlandDescription: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
  hasPermission: (permission: string) => boolean;
}

export const useCodesAndStandardsForm = (
  verifierDisplayName: string,
  codesAndStandardsDefaultValues: CodesAndStandardsData,
  codesAndStandardsOldDefaultValues: CodesAndStandardsData,
  listingRowVersion: number,
  setListingRowVersion: Dispatch<SetStateAction<number>>,
  dispatch: Dispatch<VerifierMarketingAssetsFormChangedAction>,
  forwardedRef: ForwardedRef<VerifierMarketingAssetsSubmitHandler>,
  getCurrentFormsData: (formWhichWasSaved: string) => string
): UseCodesAndStandardsFormReturnData => {
  const { currentVerifierUuid, hasPermission } = useAuth();

  const [submittedValues, setSubmittedValues] = useState<CodesAndStandardsData>(codesAndStandardsOldDefaultValues);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const [enabled, setEnabled] = useState(codesAndStandardsDefaultValues.enabled);
  const [content, setContent] = useState(codesAndStandardsDefaultValues.content);
  const [standards, setStandards] = useState(codesAndStandardsDefaultValues.standards);
  const [woodlandChecked, setWoodlandChecked] = useState(
    standards?.some((standard) => standard.uuid === StandardConstants.WOODLAND_CARBON_CODE_UUID)
  );
  const [peatlandChecked, setPeatlandChecked] = useState(
    standards?.some((standard) => standard.uuid === StandardConstants.PEATLAND_CODE_UUID)
  );
  const [woodlandDescription, setWoodlandDescription] = useState(
    standards?.find((standard) => standard.uuid === StandardConstants.WOODLAND_CARBON_CODE_UUID)?.content ||
      `${verifierDisplayName} are accredited by the UK Accreditation Service to validate Woodland Carbon Code projects.`
  );
  const [peatlandDescription, setPeatlandDescription] = useState(
    standards?.find((standard) => standard.uuid === StandardConstants.PEATLAND_CODE_UUID)?.content ||
      `${verifierDisplayName} have been authorised by the International Union for the Conservation of Nature (IUCN) UK Peatland Programme to validate Peatland Code projects.`
  );
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const radioButtonGroupData = [
    { key: true, value: "Yes" },
    { key: false, value: "No" },
  ];

  useImperativeHandle(forwardedRef, () => ({
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    getCodesAndStandardsData() {
      return {
        enabled,
        content,
        standards,
      };
    },
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    getCodesAndStandardsSubmittedData() {
      return {
        ...submittedValues,
      };
    },
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    setCodesAndStandardsErrors(previewErrors: ServiceError[]) {
      setErrors(previewErrors);
    },
  }));

  const getCurrentFormData = useCallback((): CodesAndStandardsData => {
    return {
      enabled,
      content: content || null,
      standards,
    };
  }, [enabled, content, standards]);

  useEffect(() => {
    dispatch({
      type: "SET_CODES_AND_STANDARDS_FORM_CHANGED",
      value: !areObjectsDeepEqual(submittedValues, getCurrentFormData()),
    });
  }, [enabled, content, standards, submittedValues]);

  useEffect(() => {
    setStandards([
      ...(woodlandChecked
        ? [
            {
              uuid: StandardConstants.WOODLAND_CARBON_CODE_UUID,
              content: woodlandDescription,
            },
          ]
        : []),
      ...(peatlandChecked
        ? [
            {
              uuid: StandardConstants.PEATLAND_CODE_UUID,
              content: peatlandDescription,
            },
          ]
        : []),
    ]);
  }, [woodlandChecked, peatlandChecked, woodlandDescription, peatlandDescription]);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);
    if (currentVerifierUuid) {
      const newCodesAndStandardsValues = {
        enabled,
        content,
        standards,
      };

      const res = await updateVerifierListing({
        verifierUuid: currentVerifierUuid,
        content: getCurrentFormsData(VerifierFormNames.CodesAndStandards),
        rowVersion: listingRowVersion,
      });

      if (res.status === Status.Success && res.data) {
        Toast.success({ message: "Codes and standards details changed successfully" });
        setSubmittedValues({
          ...newCodesAndStandardsValues,
        });
        setListingRowVersion(res.data.rowVersion);
      }

      if (res.status === Status.Error) {
        setErrors(res.errors);
      }
    }
  }, setIsHandleSubmitLoading);

  const handleCancel = (): void => {
    setEnabled(submittedValues.enabled);
    setContent(submittedValues.content);
    setStandards(submittedValues.standards);
    setErrors([]);
  };

  return {
    enabled,
    content,
    woodlandChecked,
    peatlandChecked,
    woodlandDescription,
    peatlandDescription,
    radioButtonGroupData,
    errors,
    isHandleSubmitLoading,
    setEnabled,
    setContent,
    setWoodlandChecked,
    setPeatlandChecked,
    setWoodlandDescription,
    setPeatlandDescription,
    handleSubmit,
    handleCancel,
    hasPermission,
  };
};
