import "./PublicCodeDetails.css";

import { Dispatch, SetStateAction, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { ArrowFullLeftDarkIcon } from "../../../../assets";
import { OrganisationTypeConstants, ProjectTypeConstants } from "../../../../constants";
import { OrganisationType } from "../../../../models";
import { CodeDetailsData } from "../../../../route/public/code/details/page/useDetails";
import { CodeStatus } from "../../../../route/public/code/models";
import { getHostFromURL } from "../../../../utils";
import { getPublicProjectTypeRoute } from "../../../../utils/routes";
import { Button, CodeStatusKPI, Column, HorizontalProjectCards, Logo, Pill, Row } from "../../../../widget";
import { DisplayHorizontalProject } from "../../../../widget/cards/HorizontalProjectCards/models";
import { CoreCarbonPrinciples } from "../../../../widget/general/CoreCarbonPrinciples";
import { AboutUsSection, ContactUsModal, ContentSection, ModalButton, SocialMediaSection } from "../../../components";
import { KeyFactsSection } from "../KeyFactsSection";
import { ProjectProcessSection } from "../ProjectProcessSection";
import { ReportingStandardsSection } from "../ReportingStandardsSection";

interface PublicCodeDetailsProps {
  codeDetailsData?: CodeDetailsData;
  standardUuid: string | undefined;
  organisationProjects: DisplayHorizontalProject[] | undefined;
  isPreview: boolean;
  showContactUsModal?: boolean;
  setShowContactUsModal?: Dispatch<SetStateAction<boolean>>;
  navigateBackToProject?: () => void;
  navigateBackToCodeComparison?: () => void;
}

export const PublicCodeDetails = ({
  codeDetailsData,
  standardUuid,
  organisationProjects,
  isPreview,
  showContactUsModal,
  setShowContactUsModal,
  navigateBackToProject,
  navigateBackToCodeComparison,
}: PublicCodeDetailsProps): JSX.Element => {
  const [searchParams] = useSearchParams();

  const projectUuid = useMemo(() => {
    return searchParams.get("projectUuid");
  }, [searchParams]);

  return (
    <div className="PublicCodeDetails">
      {codeDetailsData?.hero.enabled && (
        <div
          className="HeroSectionOrganisationImage"
          style={{
            backgroundImage:
              codeDetailsData?.hero?.imageUrl !== undefined ? `url(${codeDetailsData?.hero?.imageUrl})` : undefined,
          }}
        />
      )}
      <div className="PublicCodeDetailsPageTemplate">
        {codeDetailsData?.hero.enabled ? (
          <>
            {codeDetailsData?.logo && (
              <div className="HeroSectionOrganisationLogo">
                <Logo displayedLogo={codeDetailsData?.logo} />
              </div>
            )}
            {!isPreview &&
              setShowContactUsModal &&
              // TODO treat the case when navigating to a Established code from the code-comparison page
              (codeDetailsData.status.value === CodeStatus.Emerging ? (
                <div className="PreviewButtonsContainer">
                  <Button
                    text="Back"
                    variant="secondary"
                    icon={<ArrowFullLeftDarkIcon />}
                    iconPosition="left"
                    onClick={navigateBackToCodeComparison}
                  />
                  {codeDetailsData.calculatedContactable && codeDetailsData?.getInTouch?.enabled && (
                    <ModalButton
                      organisationType={OrganisationType.Standard}
                      onClick={() => setShowContactUsModal(true)}
                    />
                  )}
                </div>
              ) : (
                <div className="PreviewButtonsContainer">
                  <div className={projectUuid ? "" : "HideBackToProjectButton"}>
                    <Button
                      text="Back to project"
                      variant="secondary"
                      icon={<ArrowFullLeftDarkIcon />}
                      iconPosition="left"
                      onClick={navigateBackToProject}
                    />
                  </div>
                  {codeDetailsData.calculatedContactable && codeDetailsData?.getInTouch?.enabled && (
                    <ModalButton
                      organisationType={OrganisationType.Standard}
                      onClick={() => setShowContactUsModal(true)}
                    />
                  )}
                </div>
              ))}
            <Row spacingV="m" justify="center">
              <Column span={12}>
                <h1>{codeDetailsData?.displayName}</h1>
              </Column>
            </Row>
            {codeDetailsData?.projectTypes?.projectTypes && codeDetailsData?.projectTypes?.projectTypes.length > 0 && (
              <Row spacingV="ml" justify="center">
                <Column span={12} className="ProjectTypesContainer">
                  {codeDetailsData.projectTypes.projectTypes.map(
                    (projectType) =>
                      projectType.uuid && (
                        <Pill
                          key={projectType.uuid}
                          label={projectType.displayName}
                          variantMap={ProjectTypeConstants.PROJECT_TYPE_VARIANT_MAP}
                          link={getPublicProjectTypeRoute(projectType.uuid)}
                        />
                      )
                  )}
                </Column>
              </Row>
            )}
            {codeDetailsData?.hero?.subtitle && (
              <Row spacingV="m" justify="center">
                <Column span={12}>
                  <div className="HeroSectionSubtitle">
                    <p className="body1">{codeDetailsData?.hero?.subtitle}</p>
                  </div>
                </Column>
              </Row>
            )}
            {codeDetailsData?.registryData?.name && codeDetailsData?.registryData?.url && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <div className="HeroSectionRegistry">
                    <a href={codeDetailsData?.registryData?.url} target="_blank" rel="noopener noreferrer">
                      {codeDetailsData?.registryData?.name}
                    </a>
                  </div>
                </Column>
              </Row>
            )}
            {codeDetailsData?.websiteUrl && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <Pill
                    variant="blue"
                    label={getHostFromURL(codeDetailsData?.websiteUrl)}
                    link={codeDetailsData?.websiteUrl}
                  />
                </Column>
              </Row>
            )}
            <SocialMediaSection isPreview={isPreview} socialMedia={codeDetailsData.socialMediaData} />
          </>
        ) : (
          <>
            {!isPreview && setShowContactUsModal && (
              <div
                className={` ${
                  codeDetailsData?.logo
                    ? "NoHeroSectionPreviewButtonsContainer"
                    : "NoHeroSectionNoCodeLogoPreviewButtonsContainer"
                }`}
              >
                {codeDetailsData?.status.value === CodeStatus.Emerging ? (
                  // TODO treat the case when navigating to a Established code from the code-comparison page
                  <>
                    <Button
                      text="Back"
                      variant="secondary"
                      icon={<ArrowFullLeftDarkIcon />}
                      iconPosition="left"
                      onClick={navigateBackToCodeComparison}
                    />
                    {codeDetailsData.calculatedContactable && codeDetailsData?.getInTouch?.enabled && (
                      <ModalButton
                        organisationType={OrganisationType.Standard}
                        onClick={() => setShowContactUsModal(true)}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <div className={projectUuid ? "" : "HideBackToProjectButton"}>
                      <Button
                        text="Back to project"
                        variant="secondary"
                        icon={<ArrowFullLeftDarkIcon />}
                        iconPosition="left"
                        onClick={navigateBackToProject}
                      />
                    </div>
                    {codeDetailsData?.calculatedContactable && codeDetailsData?.getInTouch?.enabled && (
                      <ModalButton
                        organisationType={OrganisationType.Standard}
                        onClick={() => setShowContactUsModal(true)}
                      />
                    )}
                  </>
                )}
              </div>
            )}
            {codeDetailsData?.logo && (
              <div className="NoHeroSectionOrganisationLogo">
                <Logo displayedLogo={codeDetailsData?.logo} />
              </div>
            )}
            <Row spacingV="m" justify="center">
              <Column span={12}>
                <h1>{codeDetailsData?.displayName}</h1>
              </Column>
            </Row>
            {codeDetailsData?.projectTypes?.projectTypes && codeDetailsData?.projectTypes?.projectTypes.length > 0 && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  {codeDetailsData.projectTypes.projectTypes.map(
                    (projectType) =>
                      projectType.uuid && (
                        <Pill
                          label={projectType.displayName}
                          variantMap={ProjectTypeConstants.PROJECT_TYPE_VARIANT_MAP}
                          link={getPublicProjectTypeRoute(projectType.uuid)}
                        />
                      )
                  )}
                </Column>
              </Row>
            )}
            {codeDetailsData?.hero?.subtitle && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <div className="HeroSectionSubtitle">
                    <p className="body1">{codeDetailsData?.hero?.subtitle}</p>
                  </div>
                </Column>
              </Row>
            )}
            {codeDetailsData?.registryData?.name && codeDetailsData?.registryData?.url && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <div className="HeroSectionRegistry">
                    <a href={codeDetailsData?.registryData?.url} target="_blank" rel="noopener noreferrer">
                      {codeDetailsData?.registryData?.name}
                    </a>
                  </div>
                </Column>
              </Row>
            )}
            {codeDetailsData?.websiteUrl && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <Pill
                    variant="blue"
                    label={getHostFromURL(codeDetailsData?.websiteUrl)}
                    link={codeDetailsData?.websiteUrl}
                  />
                </Column>
              </Row>
            )}
            {codeDetailsData?.socialMediaData && (
              <SocialMediaSection isPreview={isPreview} socialMedia={codeDetailsData.socialMediaData} />
            )}
          </>
        )}
        {codeDetailsData?.ukEnvironmentalReporting.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <ReportingStandardsSection
                reportingStandards={codeDetailsData?.ukEnvironmentalReporting.ukEnvironmentalReporting}
              />
            </Column>
          </Row>
        )}
        {codeDetailsData?.codeCharacteristics && codeDetailsData?.status.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <div className="CodeStatusKPIContainer">
                <CodeStatusKPI codeCharacteristics={codeDetailsData?.codeCharacteristics} />
              </div>
            </Column>
          </Row>
        )}
        {codeDetailsData?.ourMission?.enabled && codeDetailsData?.ourMission?.content && (
          <Row spacingV="ml">
            <Column span={12}>
              <ContentSection title="Our mission" content={codeDetailsData.ourMission.content} />
            </Column>
          </Row>
        )}
        {codeDetailsData?.keyFacts.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <KeyFactsSection keyFactsText={codeDetailsData?.keyFacts} organisationUuid={standardUuid} />
            </Column>
          </Row>
        )}
        {codeDetailsData?.codeCharacteristics && codeDetailsData?.codeCharacteristics.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <CoreCarbonPrinciples
                characteristics={codeDetailsData.coreCarbonPrinciples.characteristics}
                codeUrl={codeDetailsData.coreCarbonPrinciples.codeUrl}
              />
            </Column>
          </Row>
        )}
        {codeDetailsData?.projectProcess?.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <ProjectProcessSection projectProcess={codeDetailsData?.projectProcess} />
            </Column>
          </Row>
        )}
        {organisationProjects && !!organisationProjects.length && codeDetailsData?.displayName && (
          <Row spacingV="ml">
            <Column span={12}>
              <HorizontalProjectCards
                codeUuid={standardUuid}
                codeName={codeDetailsData?.displayName}
                projects={organisationProjects}
              />
            </Column>
          </Row>
        )}
        {codeDetailsData?.aboutUs?.enabled &&
          codeDetailsData?.aboutUs?.content &&
          codeDetailsData?.aboutUs?.imageUrl && (
            <Row>
              <Column span={12}>
                <AboutUsSection
                  content={codeDetailsData.aboutUs.content}
                  imageUrl={codeDetailsData.aboutUs.imageUrl}
                  imageLayout={codeDetailsData.aboutUs.imageLayout}
                />
              </Column>
            </Row>
          )}
      </div>
      {codeDetailsData?.displayName && setShowContactUsModal && showContactUsModal && (
        <ContactUsModal
          organisationType={OrganisationTypeConstants.STANDARD}
          developerName={codeDetailsData?.displayName}
          developerPhoneNumber={codeDetailsData?.contactPhone}
          show={showContactUsModal}
          closeModal={() => {
            setShowContactUsModal(false);
          }}
        />
      )}
    </div>
  );
};
