import { Link, useNavigate } from "react-router-dom";

import bgImage from "../../../assets/images/Peatland.jpg";
import { AuthenticationTemplate } from "../../../templates";
import { getLogonRoute } from "../../../utils/routes";
import { Button, Column, InformationBox, Row } from "../../../widget";
import { RegisterForm } from "./form";
import { useRegister } from "./useRegister";

/**
 * TODO
 * Navigation to this page has been removed as part of https://kanaearth.atlassian.net/browse/KAN-3672
 */
export const Register = (): JSX.Element => {
  const { registrationSuccessful, setRegistrationSuccessful } = useRegister();
  const navigate = useNavigate();

  return (
    <AuthenticationTemplate imageSrc={bgImage}>
      <div>
        <Row spacingV="ll">
          <Column span={12}>
            <h1>Register an account</h1>
          </Column>
        </Row>
        {registrationSuccessful ? (
          <>
            <Row spacingV="ll">
              <Column span={12}>
                <InformationBox variant="success" title="Account created">
                  Thank you for registering. <br /> <br /> Kana is currently in closed beta; you have been placed on the
                  waitlist to get early access to new features as they become available.
                </InformationBox>
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <Button
                  fullWidth
                  text="Back to homepage"
                  onClick={() => {
                    navigate(getLogonRoute());
                  }}
                />
              </Column>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Column span={12}>
                <RegisterForm onSuccess={setRegistrationSuccessful} />
              </Column>
            </Row>
            <span>
              <Link to={getLogonRoute()}>Back to log in</Link>
            </span>
          </>
        )}
      </div>
    </AuthenticationTemplate>
  );
};
