import "./Backdrop.css";

import React from "react";

export interface BackdropProps {
  show: boolean;
  onClick?: () => void;
}

export const Backdrop: React.FC<BackdropProps> = ({ show, onClick }): JSX.Element | null =>
  show ? (
    <div
      className="Backdrop"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={-1}
      aria-label="Modal action"
    />
  ) : null;
