import { DeveloperPreviewData } from "../../../route/public/developer/details/preview/usePreview";
import { PublicDeveloperDetails } from "../components/PublicDeveloperDetails";
import { usePublicDeveloperDetailsPreviewTemplate } from "./usePublicDeveloperDetailsPreviewTemplate";

interface PublicDeveloperDetailsPreviewTemplateProps {
  previewData: DeveloperPreviewData;
}

export const PublicDeveloperDetailsPreviewTemplate = ({
  previewData,
}: PublicDeveloperDetailsPreviewTemplateProps): JSX.Element => {
  const { dataIsLoading, developerUuid, developerDetails, developerSocialMedia, projectsCoordinates } =
    usePublicDeveloperDetailsPreviewTemplate();

  return (
    <div>
      {!dataIsLoading && developerSocialMedia && previewData && developerDetails && (
        <PublicDeveloperDetails
          projectsCoordinates={projectsCoordinates}
          developerUuid={developerUuid}
          isPreview
          developerDetailsData={{
            ...previewData,
            ...developerDetails,
            calculatedContactable: false,
            socialMediaData: developerSocialMedia,
          }}
        />
      )}
    </div>
  );
};
