import "./ProfileMenu.css";

import React, { useRef } from "react";

import { useClickOutside } from "../../../utils";
import { useScroll } from "../../../utils/hooks/useScroll";

interface ProfileMenuProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position?: { top?: string; right?: string; bottom?: string; left?: string };
  closeOnScroll?: boolean;
}

export const ProfileMenu = ({
  show,
  onClose,
  position,
  children,
  closeOnScroll = false,
}: ProfileMenuProps): JSX.Element | null => {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    onClose();
  });

  if (closeOnScroll)
    useScroll(() => {
      onClose();
    });

  return show ? (
    <div ref={wrapperRef} className="ProfileMenuWrapper">
      <div className="ProfileMenuContainer" style={{ ...position }}>
        {children}
      </div>
    </div>
  ) : null;
};
