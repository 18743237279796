import PlaceholderImage from "../assets/images/Forest-Illustration.jpg";
import PeatlandImage from "../assets/images/Peatland-Illustration.jpg";
import WoodlandImage from "../assets/images/Woodland-Illustration.jpg";

export const getPlaceholderImage = (codeName: string | undefined): string => {
  switch (codeName) {
    case "Peatland Code":
      return PeatlandImage;
    case "Woodland Carbon Code":
      return WoodlandImage;
    default:
      return PlaceholderImage;
  }
};
