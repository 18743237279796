import "./PercentageChangeTrend.css";

import { DowntrendArrowOrangeIcon, MinusSignBlueIcon, UptrendArrowGreenIcon } from "../../../../../assets";

interface PercentageChangeTrendIconProps {
  percentageChange: number;
}

export const PercentageChangeTrend = ({ percentageChange }: PercentageChangeTrendIconProps): JSX.Element => {
  if (percentageChange > 0) {
    return (
      <div className="PercentageChange">
        <UptrendArrowGreenIcon />
        <p className="body2">{percentageChange}%</p>
      </div>
    );
  }
  if (percentageChange < 0) {
    return (
      <div className="PercentageChange">
        <DowntrendArrowOrangeIcon />
        <p className="body2">{percentageChange}%</p>
      </div>
    );
  }
  return (
    <div className="PercentageChange">
      <MinusSignBlueIcon />
      <p className="body2">{percentageChange}%</p>
    </div>
  );
};
