import { ActivityData, ResponseDocumentData, UploadedDocumentEnriched } from "..";

export const enrichUploadedDocuments = (
  activityData2: ActivityData,
  documentResponseData: ResponseDocumentData[]
): UploadedDocumentEnriched[] => {
  return (
    activityData2.documents?.map((d) => {
      const dActivityDefinitionVersionDocumentTypeUuidAndVariant = Object.keys(d)[0];
      const dActivityDocumentHistoryUuid = d[dActivityDefinitionVersionDocumentTypeUuidAndVariant];
      const details = documentResponseData.find(
        (drd) => dActivityDocumentHistoryUuid === drd.activityDocumentHistory.uuid
      );
      if (details === undefined)
        throw new Error(
          `Couldn't find an activityDocumentHistory with UUID '${dActivityDocumentHistoryUuid}' in response data`
        );
      return {
        activityDefinitionVersionDocumentTypeUuidAndVariant: dActivityDefinitionVersionDocumentTypeUuidAndVariant,
        activityDocumentHistoryUuid: dActivityDocumentHistoryUuid,
        activityDefinitionDocumentTypeDisplayName: details.activityDefinitionDocumentType.displayName,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filename: details.activityDocumentHistory.file.filename!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mimeType: details.activityDocumentHistory.file.mimeType!,
      };
    }) || []
  );
};

const addOrUpdateDocument = (document: UploadedDocumentEnriched, state: UploadedDocumentEnriched[]): void => {
  const idx = state.findIndex(
    (sd) =>
      sd.activityDefinitionVersionDocumentTypeUuidAndVariant ===
      document.activityDefinitionVersionDocumentTypeUuidAndVariant
  );
  if (idx === -1) {
    // document added
    state.push(document);
  } else {
    // document updated
    // eslint-disable-next-line no-param-reassign
    state[idx] = document;
  }
};

const deleteDocument = (
  previousActivityDefinitionVersionDocumentTypeUuidAndVariant: string,
  state: UploadedDocumentEnriched[]
): void => {
  const idx = state.findIndex(
    (d) =>
      d.activityDefinitionVersionDocumentTypeUuidAndVariant ===
      previousActivityDefinitionVersionDocumentTypeUuidAndVariant
  );
  // KAN-2918: 'special' format which the API interprets as a deletion
  // eslint-disable-next-line no-param-reassign
  if (idx > -1) state[idx].activityDocumentHistoryUuid = null;
};

/*
 This function mutates its inputs (namely the `stepDocuments` and `activityDocuments` parameters)
 */
export const singleDocumentSetter = (
  document: UploadedDocumentEnriched | undefined,
  stepDocuments: UploadedDocumentEnriched[],
  activityDocuments: UploadedDocumentEnriched[],
  previousActivityDefinitionVersionDocumentTypeUuidAndVariant?: string
): void => {
  // if `document` == undefined, then the document has been deleted through the UI.
  if (document !== undefined) {
    addOrUpdateDocument(document, stepDocuments);
    addOrUpdateDocument(document, activityDocuments);
  } else if (previousActivityDefinitionVersionDocumentTypeUuidAndVariant !== undefined) {
    // document removed
    deleteDocument(previousActivityDefinitionVersionDocumentTypeUuidAndVariant, stepDocuments);
    deleteDocument(previousActivityDefinitionVersionDocumentTypeUuidAndVariant, activityDocuments);
  }
};

/*
 This function mutates its inputs (namely `stepDocuments` and `activityDocuments` parameters)
 The key string can be a composite one (chained by '|') in the case of a MFU inside a repeater
 */
export const multiDocumentSetter = (
  key: string,
  documents: UploadedDocumentEnriched[],
  stepDocuments: UploadedDocumentEnriched[],
  activityDocuments: UploadedDocumentEnriched[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
): void => {
  let previousDocuments: string[] = [];
  const splitKey = key.split("|");

  if (splitKey.length === 3) {
    const repeaterKey = splitKey[0];
    const repeaterIdx = splitKey[1];
    const multiUploadKey = splitKey[2];
    previousDocuments = (values?.[repeaterKey]?.[repeaterIdx]?.[multiUploadKey] as string[]) || [];
  } else {
    previousDocuments = (values[key] as string[]) || [];
  }

  const addedDocuments = documents.filter(
    (d) => previousDocuments.findIndex((k) => d.activityDefinitionVersionDocumentTypeUuidAndVariant === k) === -1
  );
  const updatedDocuments = documents.filter(
    (d) => previousDocuments.findIndex((k) => d.activityDefinitionVersionDocumentTypeUuidAndVariant === k) > -1
  );
  const removedDocuments = previousDocuments.filter(
    (k) => documents.findIndex((d) => d.activityDefinitionVersionDocumentTypeUuidAndVariant === k) === -1
  );
  addedDocuments.forEach((ad) => {
    addOrUpdateDocument(ad, stepDocuments);
    addOrUpdateDocument(ad, activityDocuments);
  });
  updatedDocuments.forEach((ad) => {
    addOrUpdateDocument(ad, stepDocuments);
    addOrUpdateDocument(ad, activityDocuments);
  });
  removedDocuments.forEach((rd) => {
    deleteDocument(rd, stepDocuments);
    deleteDocument(rd, activityDocuments);
  });
};

/*
 This function check whether the user has made a change to the documents
 */
export const hasChangedDocuments = (activityData: ActivityData, activityDocs: UploadedDocumentEnriched[]): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activityDataDocsArr = activityData.documents?.map((el: any) => Object.values(el)[0]) as (string | null)[];
  const updatedDocsArr = activityDocs?.map((el) => el.activityDocumentHistoryUuid);
  return !updatedDocsArr.every((uuid) => activityDataDocsArr?.includes(uuid));
};
