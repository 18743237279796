import { useMemo } from "react";

import { GetProjectDetailsResponse } from "../../../../../../service/publicquery";
import { getComponentPropertiesByDataPath, getValueByDataPath } from "../../../../../../utils";
import { ComponentProperties } from "../../../../../developer/activities";
import { StandardSpecificDefinition } from "../../../../../shared/projects/project/overview/components/StandardSpecificSection/types";

interface UseOverviewComponentReturnData {
  carbonImpactType: ComponentProperties | undefined;
  carbonImpactValue: number;
}

export const useOverviewComponent = (
  projectDetails: GetProjectDetailsResponse | undefined,
  standardSpecificDefinition: StandardSpecificDefinition
): UseOverviewComponentReturnData => {
  const carbonImpactType = useMemo(
    () => getComponentPropertiesByDataPath(standardSpecificDefinition, standardSpecificDefinition.carbonImpactPath),
    [standardSpecificDefinition]
  );

  const carbonImpactValue = useMemo(
    () =>
      projectDetails?.standardSpecificData
        ? getValueByDataPath(projectDetails?.standardSpecificData, standardSpecificDefinition.carbonImpactPath)
        : undefined,
    [projectDetails, standardSpecificDefinition]
  );

  return {
    carbonImpactType,
    carbonImpactValue,
  };
};
