import { useCallback, useEffect, useState } from "react";

import { SelectData } from "../../../../../models";
import { lookUp } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getUserSettingsRoute } from "../../../../../utils/routes";

interface UseSendInvitationReturnData {
  roles: SelectData | undefined;
  rolesInfo: string[];
  userSettingsRoute: string;
}

export const useSendInvitation = (): UseSendInvitationReturnData => {
  const [roles, setRoles] = useState<SelectData | undefined>(undefined);

  const { currentUserType } = useAuth();

  // TODO use the correct roles here
  const rolesInfo = [
    "DM Lead: Can update organisation details and read all projects. Leads can invite team members and edit all organisation users. There must always be one Lead.",
    "DM Team Member: Can read all projects but cannot update organisation details or invite other users to the the organisation.",
  ];

  const fetchRoles = useCallback(async () => {
    const res = await lookUp({ type: "DM_Role" });
    if (res.status === Status.Success) {
      setRoles(
        res.data?.map((role) => {
          return {
            key: role.key,
            value: role.valueString,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchRoles().then((r) => r);
  }, [fetchRoles]);

  return {
    roles,
    rolesInfo,
    userSettingsRoute: getUserSettingsRoute(currentUserType),
  };
};
