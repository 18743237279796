import "./Autocomplete.css";

import RcSelect from "rc-select";

import { DropdownLightIcon } from "../../../assets";
import { SelectData } from "../../../models";
import { FormLabel } from "../FormLabel";
import { useAutocomplete } from "./useAutocomplete";

export interface AutocompleteProps {
  label?: string;
  initialKey?: string | null;
  placeholder?: string;
  className?: string;
  dropdownMatchSelectWidth?: boolean;
  data: SelectData;
  onSelect: (value: string) => void;
  onChange?: (value: string) => void;
  onTypeSearch?: (value: string) => void;
}

export const Autocomplete = ({
  label,
  initialKey,
  placeholder = "Please search",
  className,
  dropdownMatchSelectWidth,
  data,
  onChange,
  onSelect,
  onTypeSearch,
}: AutocompleteProps): JSX.Element => {
  const { currentValue, currentKey, handleChange, handleSearch, handleSelect, options } = useAutocomplete({
    data,
    initialKey,
    onSelect,
    onChange,
    onTypeSearch,
  });

  return (
    <FormLabel label={label}>
      <div className={`${className || ""} form_field`}>
        <RcSelect
          mode="combobox"
          showSearch
          defaultValue={data?.find((el) => el.key === currentKey)?.value?.toString()}
          value={currentValue}
          placeholder={placeholder || undefined}
          suffixIcon={<DropdownLightIcon />}
          filterOption={false}
          onSearch={handleSearch}
          onSelect={handleSelect}
          onChange={handleChange}
          notFoundContent={null}
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        >
          {options}
        </RcSelect>
      </div>
    </FormLabel>
  );
};
