import { useCallback, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

import {
  AboutUsData,
  AtAGlanceData,
  FileType,
  HeroData,
  OurExpertiseData,
  PublicVerifierDetailsPreviewParams,
  RichContentSectionData,
  SectionPart,
  StandardData,
} from "../../../../../models";
import {
  getOrganisationListingPreview,
  GetOrganisationListingPreviewResponse,
} from "../../../../../service/organisation";
import { getStandardDetails } from "../../../../../service/query";
import { BaseResponseWithSingle, Status } from "../../../../../service/Shared";
import { getPublicCodeDetailsRoute } from "../../../../../utils/routes";
import { StandardsData } from "../../models";

type CustomNavigateFunction = NavigateFunction;

interface UsePreviewReturnData {
  navigate: CustomNavigateFunction;
  previewUuid: string | undefined;
  dataIsLoading: boolean;
  previewData: VerifierPreviewData | undefined;
}

export interface VerifierPreviewData {
  hero: HeroData;
  ourExpertise: OurExpertiseData;
  standards: StandardsData;
  atAGlance: AtAGlanceData;
  ourServices: RichContentSectionData;
  aboutUs: AboutUsData;
}

export const usePreview = (): UsePreviewReturnData => {
  const navigate = useNavigate();
  const { previewUuid } = useParams<PublicVerifierDetailsPreviewParams>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState<VerifierPreviewData>();
  const [organisationPreviewRes, setOrganisationPreviewRes] =
    useState<BaseResponseWithSingle<GetOrganisationListingPreviewResponse>>();
  const [cache, setCache] = useState<{ [standardUuid: string]: SectionPart }>({});

  const fetchData = useCallback(async (): Promise<void> => {
    if (previewUuid) {
      await getOrganisationListingPreview({ previewUuid }).then((res) => setOrganisationPreviewRes(res));
    }
  }, [previewUuid]);

  const fetchSectionData = useCallback(async (): Promise<void> => {
    if (organisationPreviewRes && organisationPreviewRes.status === Status.Success && organisationPreviewRes.data) {
      const standards = organisationPreviewRes.data.content?.standards?.standards;

      if (standards) {
        const promises = standards.map(async (standard: StandardData) => {
          let sectionPart = cache[standard.uuid];

          // If the result isn't already in the cache, fetch it
          if (!sectionPart) {
            const res = await getStandardDetails({ standardUuid: standard.uuid });

            if (res.status === Status.Success && res.data) {
              sectionPart = {
                title: res.data.displayName || null,
                content: standard.content,
                imageUrl: res.data.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null,
                imageLink: getPublicCodeDetailsRoute(standard.uuid),
              };

              setCache((prev) => ({ ...prev, [standard.uuid]: sectionPart }));
            }
          }
        });

        await Promise.all(promises);
      }
    }
  }, [organisationPreviewRes]);

  useEffect(() => {
    if (organisationPreviewRes && organisationPreviewRes.status === Status.Success && organisationPreviewRes.data) {
      setPreviewData({
        hero: {
          enabled: organisationPreviewRes.data.content?.hero?.enabled,
          title: organisationPreviewRes.data.content?.hero?.title,
          subtitle: organisationPreviewRes.data.content?.hero?.subtitle,
          imageUrl:
            organisationPreviewRes.data.listingFiles?.find(
              (file) => file.uuid === organisationPreviewRes.data?.content?.hero?.imageFileUuid
            )?.file?.url || null,
        },
        ourExpertise: {
          enabled: organisationPreviewRes.data.content?.ourMission?.enabled,
          content: organisationPreviewRes.data.content?.ourMission?.content,
        },
        standards: {
          enabled: organisationPreviewRes.data.content?.standards?.enabled,
          content: organisationPreviewRes.data.content?.standards?.content,
          sectionDataList: Object.values(cache),
        },
        atAGlance: {
          enabled: organisationPreviewRes.data.content?.atAGlance?.enabled,
        },
        ourServices: {
          enabled: organisationPreviewRes.data.content?.ourServices?.enabled,
          part1: {
            title: organisationPreviewRes.data.content?.ourServices?.part1?.title,
            content: organisationPreviewRes.data.content?.ourServices?.part1?.content,
            imageUrl:
              organisationPreviewRes.data.listingFiles?.find(
                (file) => file.uuid === organisationPreviewRes.data?.content?.ourServices?.part1?.imageFileUuid
              )?.file?.url || null,
          },
          part2: {
            title: organisationPreviewRes.data.content?.ourServices?.part2?.title,
            content: organisationPreviewRes.data.content?.ourServices?.part2?.content,
            imageUrl:
              organisationPreviewRes.data.listingFiles?.find(
                (file) => file.uuid === organisationPreviewRes.data?.content?.ourServices?.part2?.imageFileUuid
              )?.file?.url || null,
          },
          part3: {
            title: organisationPreviewRes.data.content?.ourServices?.part3?.title,
            content: organisationPreviewRes.data.content?.ourServices?.part3?.content,
            imageUrl:
              organisationPreviewRes.data.listingFiles?.find(
                (file) => file.uuid === organisationPreviewRes.data?.content?.ourServices?.part3?.imageFileUuid
              )?.file?.url || null,
          },
        },
        aboutUs: {
          enabled: organisationPreviewRes.data.content?.aboutUs?.enabled,
          content: organisationPreviewRes.data.content?.aboutUs?.content,
          imageUrl:
            organisationPreviewRes.data.listingFiles?.find(
              (file) => file.uuid === organisationPreviewRes.data?.content?.aboutUs?.imageFileUuid
            )?.file?.url || null,
        },
      });
    }
  }, [organisationPreviewRes, cache]);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  useEffect(() => {
    fetchSectionData();
  }, [fetchSectionData]);

  return {
    navigate,
    previewUuid,
    dataIsLoading,
    previewData,
  };
};
