import "./DownloadZip.css";

import { DownloadFailedDarkIcon, DownloadSuccessDarkIcon } from "../../../../assets";
import { DeveloperTemplate } from "../../../../templates";
import { Button, Column, Row, UserHeader } from "../../../../widget";
import { useDownloadZip } from "./useDownloadZip";

export const DownloadZip = (): JSX.Element => {
  const { downloadFailed, isProjectOwner, navigateToDashboard, navigateToProjectActivities } = useDownloadZip();

  return (
    <DeveloperTemplate>
      <UserHeader />
      <div className="DownloadZipContent">
        <Row spacingV="ll">
          <Column span={12}>
            <h1>Download Request</h1>
          </Column>
        </Row>
        {downloadFailed ? (
          <>
            <Row spacingV="ll">
              <Column span={12}>
                <DownloadFailedDarkIcon />
              </Column>
            </Row>
            <Row spacingV="s">
              <Column span={12}>
                <h2>Unable to download file</h2>
              </Column>
            </Row>
            <Row spacingV="ll" justify="center">
              <Column span={8}>
                <span>It looks like the download link may have expired or the file has been removed.</span>
              </Column>
            </Row>
            <div>
              {isProjectOwner ? (
                <Button text="Try again" onClick={() => navigateToProjectActivities()} />
              ) : (
                <Button text="Back to dashboard" onClick={() => navigateToDashboard()} />
              )}
            </div>
          </>
        ) : (
          <>
            <Row spacingV="ll">
              <Column span={12}>
                <DownloadSuccessDarkIcon />
              </Column>
            </Row>
            <Row spacingV="s">
              <Column span={12}>
                <h2>Your download will begin soon</h2>
              </Column>
            </Row>
            <Row spacingV="ll" justify="center">
              <Column span={8}>
                <span>
                  This could take a moment. Once it’s done you can return to your dashboard by clicking the link below:
                </span>
              </Column>
            </Row>
            <div>
              <Button text="Back to dashboard" onClick={() => navigateToDashboard()} />
            </div>
          </>
        )}
      </div>
    </DeveloperTemplate>
  );
};
