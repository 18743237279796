import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { BadgeTypeConstants } from "../../../constants";
import { BadgeData, FileType, PublicProjectOverviewPreviewParams } from "../../../models";
import { getProjectListingPreview } from "../../../service/project";
import { GetProjectDetailsResponse as GetProjectDetailsResponsePublic } from "../../../service/publicquery";
import { getProjectDetails as getProjectDetailsPrivate } from "../../../service/query";
import { Status } from "../../../service/Shared";
import { usePublicContext } from "../usePublicContext";

interface useProjectPreviewReturnData {
  isLoading: boolean;
  projectDetails: GetProjectDetailsResponsePublic | undefined;
  developerIcon: string | undefined;
  projectBadges: BadgeData[];
  images: string[] | undefined;
}

export const useProjectPreview = (): useProjectPreviewReturnData => {
  const { uuid, previewUuid } = useParams<PublicProjectOverviewPreviewParams>();
  const { projectDetails, setProjectDetails } = usePublicContext();
  const [developerIcon, setDeveloperIcon] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [projectBadges, setProjectBadges] = useState<BadgeData[]>([]);

  const fetchData = useCallback(async () => {
    if (uuid) {
      let previewResponse;

      if (previewUuid) {
        previewResponse = await getProjectListingPreview({
          previewUuid,
        });
      }

      const projectDetailsResponse = await getProjectDetailsPrivate({
        projectUuid: uuid,
        projectExternalReference: null,
        projectType: null,
      });

      if (projectDetailsResponse.status === Status.Success && projectDetailsResponse.data) {
        let projData: GetProjectDetailsResponsePublic | undefined;
        if (previewResponse && previewResponse.status === Status.Success && previewResponse.data) {
          projData = {
            ...projectDetailsResponse?.data,
            calculatedContactable: false,
            developer: {
              ...projectDetailsResponse?.data.developer,
            },
            listing: {
              ...projectDetailsResponse.data.listing,
              pilotProject: previewResponse.data.content.pilotProjectChecked,
              content: previewResponse.data.content,
            },
            listingFiles: projectDetailsResponse?.data.listingFiles.map((listingFile) => {
              return {
                file: {
                  url: listingFile.file.url,
                  uuid: listingFile.file.uuid,
                },
                uuid: listingFile.uuid,
              };
            }),
            badges: [...projectDetailsResponse.data.badges],
          };
        }

        setProjectDetails(projData);

        setDeveloperIcon(
          projectDetailsResponse.data?.developer.files.find((f) => f.type === FileType.SmallLogo)?.file.url
        );

        setProjectBadges(
          projectDetailsResponse.data?.badges.filter((b) => b.type === BadgeTypeConstants.BADGE_TYPE_BADGE) || []
        );
      }
    }
  }, [previewUuid, uuid]);

  useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, [fetchData, previewUuid, uuid]);

  const images = projectDetails?.listing?.content?.images?.flatMap((imgUuid: string) => {
    const listingFile = projectDetails?.listingFiles?.find((lf) => lf.uuid === imgUuid);
    if (listingFile) {
      return listingFile.file.url;
    }
    return [];
  });

  return {
    isLoading,
    images,
    developerIcon,
    projectBadges,
    projectDetails,
  };
};
