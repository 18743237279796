import { ServiceError } from "../../../../../../../../service/Shared";
import { Button, Column, ErrorBox, Modal, Row } from "../../../../../../../../widget";
import { useDeleteDocumentVersionModal } from "./useDeleteDocumentVersionModal";

interface DeleteDocumentVersionModalProps {
  show: boolean;
  closeModal: () => void;
  deleteDocumentUuid?: string;
}

export const DeleteDocumentVersionModal = ({
  show,
  closeModal,
  deleteDocumentUuid,
}: DeleteDocumentVersionModalProps): JSX.Element => {
  const { deleteDocument, isDeleteDocumentLoading, onCancel, errors } = useDeleteDocumentVersionModal({
    closeModal,
    deleteDocumentUuid,
  });

  return (
    <Modal show={show} title="Delete document version?" onClose={onCancel}>
      {errors && errors.length > 0 && (
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <ErrorBox
              title="Please solve the below issues before saving"
              text={errors.map((error: ServiceError) => error.message)}
            />
          </Column>
        </Row>
      )}
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <p className="body1">Are you sure you want to delete this document version?</p>
        </Column>
      </Row>
      <Row justify="center">
        <div className="DeleteDocumentVersionModalButtons">
          <Button onClick={deleteDocument} isLoading={isDeleteDocumentLoading} text="Delete" />
          <Button onClick={onCancel} text="Cancel" variant="secondary" />
        </div>
      </Row>
    </Modal>
  );
};
