import "./ProgressIndicator.css";

import { useMemo } from "react";

import {
  AdditionalActivity1CoralIcon,
  AdditionalActivity2CoralIcon,
  CheckCircleGreenIcon,
  CrossRedIcon,
  DownloadDarkIcon,
  TrashDark,
} from "../../../../../assets";
import { ProjectActivitiesConstants } from "../../../../../constants";
import { WorkflowActivity } from "../../../../../models/shared/workflowActivityType";
import {
  CompleteActivityModal,
  DownloadActivityFilesModal,
  ReturnToAssessorModal,
  SubmitActivityModal,
} from "../../../../../route/developer/components";
import { InformationBox } from "../../../../cards";
import { Button } from "../../../../forms";
import { ProfileMenu, ProfileMenuItem } from "../../../../navigation";
import { DeleteActivityModal } from "../DeleteActivityModal";
import { lockActivityIcon } from "../LockActivityIcon";
import { supportsDigitalVerificationIcon } from "../SupportsDigitalVerificationIcon";
import { useProgressIndicator } from "./useProgressIndicator";

type ProgressIndicatorLocation = "Project" | "Activity";

interface ProgressIndicatorProps {
  activity: WorkflowActivity;
  hasNoSubmitButtonPadding: boolean;
  hasNoCompleteButtonPadding: boolean;
  canManageActivity: boolean;
  isExternal: boolean;
  location: ProgressIndicatorLocation;
  nrOfActivities?: number;
  isDisabled?: boolean;
  shouldRefreshActivities: () => void;
  shouldRefreshProjectDetails?: () => void;
}

export const ProgressIndicator = ({
  activity,
  hasNoSubmitButtonPadding,
  hasNoCompleteButtonPadding,
  canManageActivity,
  isExternal,
  isDisabled = false,
  nrOfActivities,
  location,
  shouldRefreshActivities,
  shouldRefreshProjectDetails,
}: ProgressIndicatorProps): JSX.Element => {
  const {
    showSubmitActivityModal,
    setShowSubmitActivityModal,
    showReturnToAssessorModal,
    setShowReturnToAssessorModal,
    showCompleteActivityModal,
    setShowCompleteActivityModal,
    showDeleteActivityModal,
    setShowDeleteActivityModal,
    showDownloadDropdown,
    setShowDownloadDropdown,
    dropdownOffsetX,
    setDropdownOffsetX,
    dropdownOffsetY,
    setDropdownOffsetY,
    isDocumentDownloading,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    downloadDropdownOptions,
    setShowSubmitModalSwitcher,
    submitActivity,
    completeActivity,
  } = useProgressIndicator({
    activityHistoryUuid: activity?.activityHistoryUuid,
    currentOnly: activity?.isCurrentVersion || activity?.isUnderReview,
    isVersionConflict: activity?.isVersionConflict,
  });

  const renderAdditionalActivityIcon = useMemo(() => {
    if (isDisabled) {
      if (nrOfActivities === 3) {
        return <AdditionalActivity2CoralIcon className="AdditionalActivityCoralIcon Enabled" />;
      }
      return <AdditionalActivity1CoralIcon className="AdditionalActivityCoralIcon Enabled" />;
    }
    if (nrOfActivities === 3) {
      return <AdditionalActivity2CoralIcon className="AdditionalActivityCoralIcon Disabled" />;
    }
    return <AdditionalActivity1CoralIcon className="AdditionalActivityCoralIcon Disabled" />;
  }, [nrOfActivities, isDisabled]);

  const displayWithOrWithoutSaveForSubmitButton = (): JSX.Element => {
    return activity.isDraftVersion ? (
      <Button
        hasNoPadding={hasNoSubmitButtonPadding}
        size="small"
        text="Save and Submit"
        onClick={() => submitActivity(activity.status)}
      />
    ) : (
      <Button size="small" text="Submit" onClick={() => setShowSubmitModalSwitcher(activity.status)} />
    );
  };

  const displayWithOrWithoutSaveForCompleteButton = (): JSX.Element => {
    return activity.isDraftVersion ? (
      <Button
        hasNoPadding={hasNoCompleteButtonPadding}
        size="small"
        text="Save and Complete"
        onClick={() => completeActivity()}
      />
    ) : (
      <Button size="small" text="Complete" onClick={() => setShowCompleteActivityModal(true)} />
    );
  };

  const displayCompleteOrSubmitButton = (): JSX.Element => {
    return activity.isManuallyApproved
      ? displayWithOrWithoutSaveForCompleteButton()
      : displayWithOrWithoutSaveForSubmitButton();
  };

  const displayButtonsForActivityStatus = (): JSX.Element => {
    return activity.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ||
      activity.status === ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED ||
      activity.status === ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED ? (
      <>
        {displayCompleteOrSubmitButton()}
        <Button size="small" text="Edit" onClick={activity.onEdit} variant="tertiary" />
      </>
    ) : (
      <Button size="small" text="View" onClick={activity.onView} />
    );
  };

  const displayButtonsForUsersWithManageAccess = (): JSX.Element => {
    return activity.completionPercentage === 100 && activity.isActivityDataValid && !isExternal ? (
      <>
        {displayButtonsForActivityStatus()}
        <div
          ref={(el) => {
            if (!el) return;
            setDropdownOffsetX(el.getBoundingClientRect().left);
            setDropdownOffsetY(el.getBoundingClientRect().top);
          }}
        >
          {location === "Project" ? (
            <DownloadDarkIcon className="DownloadDarkIcon" onClick={() => setShowDownloadDropdown(true)} />
          ) : (
            <Button
              className="ActivityButton"
              size="small"
              text="Download"
              variant="secondary"
              loadingText="Downloading"
              isLoading={isDocumentDownloading}
              icon={<DownloadDarkIcon />}
              onClick={() => setShowDownloadDropdown(true)}
            />
          )}
        </div>
      </>
    ) : (
      <Button size="small" text="Resume" onClick={activity.onResume} />
    );
  };

  const displayButtonsForUsersWithReadAccess = (): JSX.Element | undefined => {
    return activity.isCurrentVersion || activity.isDraftVersion || activity.isUnderReview ? (
      <>
        <Button size="small" text="View" onClick={activity.onView} />
        <div
          ref={(el) => {
            if (!el) return;
            setDropdownOffsetX(el.getBoundingClientRect().left);
            setDropdownOffsetY(el.getBoundingClientRect().top);
          }}
        >
          {location === "Project" ? (
            <DownloadDarkIcon className="DownloadDarkIcon" onClick={() => setShowDownloadDropdown(true)} />
          ) : (
            <Button
              size="small"
              text="Download"
              variant="secondary"
              loadingText="Downloading"
              isLoading={isDocumentDownloading}
              icon={<DownloadDarkIcon />}
              onClick={() => setShowDownloadDropdown(true)}
            />
          )}
        </div>
      </>
    ) : undefined;
  };

  const progressIndicatorLogic = (): JSX.Element | undefined => {
    switch (activity.status) {
      case ProjectActivitiesConstants.STATUS_SUBMITTED:
        return (
          <>
            <div className="ActivityStatusContainer">
              <div className="ActivityDisplayName">
                <p>{`${activity.displayName} ${location === "Project" ? `| ${activity.status}` : ""}`}</p>
                {lockActivityIcon(activity.status)}
              </div>
              <div className="CompleteActivityMessage">
                <CheckCircleGreenIcon />
                <p className="body2">Manually submitted</p>
              </div>
            </div>
            <div className="ButtonContainer">{displayButtonsForUsersWithReadAccess()}</div>
          </>
        );
      case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_VVB:
      case ProjectActivitiesConstants.STATUS_ASSESSMENT_ASSIGNED:
      case ProjectActivitiesConstants.STATUS_ASSESSMENT_IN_PROGRESS:
      case ProjectActivitiesConstants.STATUS_ASSESSMENT_AWAITING_REVIEW:
      case ProjectActivitiesConstants.STATUS_REVIEW_ASSIGNED:
      case ProjectActivitiesConstants.STATUS_REVIEW_IN_PROGRESS:
      case ProjectActivitiesConstants.STATUS_REVIEW_COMPLETE:
        return (
          <>
            <div className="ActivityStatusContainer">
              <div className="ActivityDisplayName">
                <p>{`${activity.displayName} ${location === "Project" ? `| ${activity.status}` : ""}`}</p>
                {lockActivityIcon(activity.status)}
              </div>
              <div className="CompleteActivityMessage">
                <CheckCircleGreenIcon />
                <p className="body2">With {activity.verifierDisplayName ? activity.verifierDisplayName : "Verifier"}</p>
              </div>
            </div>
            <div className="ButtonContainer">{displayButtonsForUsersWithReadAccess()}</div>
          </>
        );
      case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE:
        return (
          <>
            <div className="ActivityStatusContainer">
              <div className="ActivityDisplayName">
                <p>{`${activity.displayName} ${location === "Project" ? `| ${activity.status}` : ""}`}</p>
                {lockActivityIcon(activity.status)}
              </div>
              <div className="CompleteActivityMessage">
                <CheckCircleGreenIcon />
                <p className="body2">With Code</p>
              </div>
            </div>
            <div className="ButtonContainer">{displayButtonsForUsersWithReadAccess()}</div>
          </>
        );
      case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_REGISTRY:
        return (
          <>
            <div className="ActivityStatusContainer">
              <div className="ActivityDisplayName">
                <p>{`${activity.displayName} ${location === "Project" ? `| ${activity.status}` : ""}`}</p>
                {lockActivityIcon(activity.status)}
              </div>
              <div className="CompleteActivityMessage">
                <CheckCircleGreenIcon />
                <p className="body2">With Registry</p>
              </div>
            </div>
            <div className="ButtonContainer">{displayButtonsForUsersWithReadAccess()}</div>
          </>
        );
      case ProjectActivitiesConstants.STATUS_APPROVED:
        return (
          <>
            <div className="ActivityStatusContainer">
              <div className="ActivityDisplayName">
                <p>{`${activity.displayName} ${location === "Project" ? `| ${activity.status}` : ""}`}</p>
                {lockActivityIcon(activity.status)}
              </div>
              <div className="CompleteActivityMessage">
                <CheckCircleGreenIcon />
                <p className="body2">Completed</p>
              </div>
            </div>
            <div className="ButtonContainer">{displayButtonsForUsersWithReadAccess()}</div>
          </>
        );
      case ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED:
        return (
          <>
            <div className="ActivityStatusContainer">
              <div className="ActivityDisplayName">
                <p>{`${activity.displayName} ${location === "Project" ? `| ${activity.status}` : ""}`}</p>
                {lockActivityIcon(activity.status)}
              </div>
              <div className="ChangesRequiredMessage">
                <InformationBox variant="alert" direction="row">
                  <p className="body3">
                    {activity.verifierDisplayName ? activity.verifierDisplayName : "The verifier"} has requested some
                    changes to this activity
                  </p>
                </InformationBox>
              </div>
            </div>
            <div className="ButtonContainer">
              {canManageActivity ? displayButtonsForUsersWithManageAccess() : displayButtonsForUsersWithReadAccess()}
            </div>
          </>
        );
      case ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED:
        return (
          <>
            <div className="ActivityStatusContainer">
              <div className="ActivityDisplayName">
                <p>{`${activity.displayName} ${location === "Project" ? `| ${activity.status}` : ""}`}</p>
                {lockActivityIcon(activity.status)}
              </div>
              <div className="CancelledActivityMessage">
                <CrossRedIcon />
                <p className="body2">
                  Review cancelled by {activity.verifierDisplayName ? activity.verifierDisplayName : "the verifier"}
                </p>
              </div>
            </div>
            <div className="ButtonContainer">
              {canManageActivity ? displayButtonsForUsersWithManageAccess() : displayButtonsForUsersWithReadAccess()}
            </div>
          </>
        );
      case ProjectActivitiesConstants.STATUS_IN_PROGRESS:
      default:
        return (
          <>
            <div className="ActivityStatusContainer">
              <div className="ActivityDisplayName">
                <p>{activity.displayName}</p>
                {lockActivityIcon(activity.status)}
              </div>
              <div className="ActivityStatusBar">
                <div className="ActivityStatusBarFilled" style={{ flex: activity.completionPercentage }} />
                <div className="ActivityStatusBarUnfilled" style={{ flex: 100 - activity.completionPercentage }} />
              </div>
              <p className="body2">{Math.round(activity.completionPercentage)}% complete</p>
            </div>
            <div className="ButtonContainer">
              {canManageActivity ? displayButtonsForUsersWithManageAccess() : displayButtonsForUsersWithReadAccess()}
            </div>
          </>
        );
    }
  };

  return activity ? (
    <>
      <div className={`ProgressIndicator ${location}`}>
        {renderAdditionalActivityIcon}
        <div className={isDisabled ? "Disabled" : "Enabled"}>{progressIndicatorLogic()}</div>
        <div className="ProgressIndicatorTopRightContainer">
          {location === "Activity" &&
            canManageActivity &&
            activity?.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS && (
              <Button
                className="DeleteButton"
                onClick={() => setShowDeleteActivityModal(true)}
                text="Delete activity"
                icon={<TrashDark />}
                iconPosition="left"
                variant="reversedPrimary"
                textUnderline
                textBold
                hasNoPadding
                size="small"
              />
            )}
          {supportsDigitalVerificationIcon(activity.supportsDigitalVerification, activity.isManuallyApproved)}
        </div>
      </div>
      {showDownloadDropdown && (
        <div style={{ position: "fixed", left: dropdownOffsetX + 250, top: dropdownOffsetY + 22 }}>
          <ProfileMenu show={showDownloadDropdown} onClose={() => setShowDownloadDropdown(false)}>
            {downloadDropdownOptions.map((el) => (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <ProfileMenuItem key={el.id} profileMenuItem={el} onClick={el.action!} />
            ))}
          </ProfileMenu>
        </div>
      )}
      {showCompleteActivityModal && activity?.activityHistoryUuid && (
        <CompleteActivityModal
          show={showCompleteActivityModal}
          onClose={() => setShowCompleteActivityModal(false)}
          activityUuid={activity.uuid}
          activityHistoryUuid={activity?.activityHistoryUuid}
          activityHistoryRowVersion={activity?.activityHistoryRowVersion}
          activityProjectUuid={activity.projectUuid}
          isDraft={activity.isDraftVersion}
        />
      )}
      {showSubmitActivityModal && activity?.activityHistoryUuid && (
        <SubmitActivityModal
          show={showSubmitActivityModal}
          onClose={() => setShowSubmitActivityModal(false)}
          activityUuid={activity.uuid}
          activityStatus={activity.status}
          activityHistoryUuid={activity?.activityHistoryUuid}
          activityHistoryRowVersion={activity?.activityHistoryRowVersion}
          isDraft={activity.isDraftVersion}
          shouldRefreshActivities={shouldRefreshActivities}
          shouldRefreshProjectDetails={shouldRefreshProjectDetails}
          projectUuid={activity.projectUuid}
          groupUuid={activity.groupUuid}
        />
      )}
      {showReturnToAssessorModal && activity?.activityHistoryUuid && (
        <ReturnToAssessorModal
          show={showReturnToAssessorModal}
          onClose={() => setShowReturnToAssessorModal(false)}
          activityUuid={activity.uuid}
          activityHistoryUuid={activity?.activityHistoryUuid}
          activityHistoryRowVersion={activity?.activityHistoryRowVersion}
          isDraft={activity.isDraftVersion}
          shouldRefreshActivities={shouldRefreshActivities}
        />
      )}
      {showDownloadActivityFilesModal && (
        <DownloadActivityFilesModal
          show={showDownloadActivityFilesModal}
          onClose={() => {
            setShowDownloadActivityFilesModal(false);
          }}
        />
      )}
      {location === "Activity" && showDeleteActivityModal && activity.uuid && (
        <DeleteActivityModal show={showDeleteActivityModal} onClose={() => setShowDeleteActivityModal(false)} />
      )}
    </>
  ) : (
    <div className="Empty ProgressIndicator">
      <p>There are currently no activities associated with this project</p>
    </div>
  );
};
