import "./ActionButtons.css";

import { Button } from "../Button";

interface ActionButtonsProps {
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  loadingText?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  onCancel: () => void;
}

export const ActionButtons = ({
  submitButtonLabel = "Save",
  cancelButtonLabel = "Cancel",
  loadingText = "Loading",
  fullWidth = false,
  isLoading = false,
  onCancel,
}: ActionButtonsProps): JSX.Element => {
  return (
    <div className="ActionButtons">
      <Button
        text={submitButtonLabel}
        type="submit"
        fullWidth={fullWidth}
        isLoading={isLoading}
        loadingText={loadingText}
      />
      <Button variant="secondary" text={cancelButtonLabel} onClick={onCancel} fullWidth={fullWidth} />
    </div>
  );
};
