import { Helmet } from "react-helmet-async";

import { DeveloperDetailsData } from "../../../../route/public/developer/details/page/useDetails";

interface PublicDeveloperDetailsMetaTagsProps {
  developerDetailsData?: DeveloperDetailsData;
}

export const PublicDeveloperDetailsMetaTags = ({
  developerDetailsData,
}: PublicDeveloperDetailsMetaTagsProps): JSX.Element | null => {
  return (
    <Helmet>
      <title>{developerDetailsData?.displayName}</title>
      <meta name="title" content={developerDetailsData?.displayName || undefined} />
      <meta name="description" content={developerDetailsData?.hero?.subtitle || undefined} />
      <script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "Dataset",
            name: developerDetailsData?.displayName,
            description: developerDetailsData?.hero?.subtitle || undefined,
          },
          null,
          "\t"
        )}
      </script>
    </Helmet>
  );
};
