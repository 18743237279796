import { HeaderActiveElement, TabsList } from "../../models";
import { PublicTemplate } from "../PublicTemplate";
import { PublicProjectTemplateComponent, PublicProjectTemplateMetaTags, RegisterInterestModal } from "./components";
import { usePublicProjectTemplate } from "./usePublicProjectTemplate";

interface PublicProjectTemplateProps {
  activeElement?: HeaderActiveElement;
  tabsList: TabsList;
}

export const PublicProjectTemplate = ({ activeElement, tabsList }: PublicProjectTemplateProps): JSX.Element => {
  const {
    project,
    images,
    otherDeveloperProjects,
    groupData,
    developerIcon,
    projectBadges,
    searchParams,
    showRegisterInterestModal,
    onBackToSearchResults,
    onRegisterInterest,
    onCloseRegisterInterestModal,
  } = usePublicProjectTemplate();

  return (
    <PublicTemplate activeElement={activeElement}>
      <PublicProjectTemplateMetaTags project={project} />
      <PublicProjectTemplateComponent
        project={project}
        tabsList={tabsList}
        searchParams={searchParams}
        developerIcon={developerIcon}
        projectBadges={projectBadges}
        images={images}
        otherDeveloperProjects={otherDeveloperProjects}
        groupData={groupData}
        onBackToSearchResults={onBackToSearchResults}
        onRegisterInterest={onRegisterInterest}
      />
      {project?.developer.displayName && (
        <RegisterInterestModal
          developerName={project?.developer.displayName}
          show={showRegisterInterestModal}
          closeModal={onCloseRegisterInterestModal}
        />
      )}
    </PublicTemplate>
  );
};
