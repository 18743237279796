import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { updateProjectGroup } from "../../../../../../../../service/project";
import { ServiceError, Status } from "../../../../../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../../../../../utils";
import { Toast } from "../../../../../../../../widget";
import { GroupContext } from "../../GroupContext";

export type ProjectGroupData = {
  groupId: string;
  groupName: string;
  rowVersion: number;
};

interface UseOverviewSectionReturnData {
  projectGroupData?: ProjectGroupData;
  projectGroupDescription: string | null;
  errors: ServiceError[];
  setProjectGroupDescription: Dispatch<SetStateAction<string | null>>;
  onSubmit: () => void;
  isOnSubmitLoading: boolean;
}

export const useOverviewSection = (): UseOverviewSectionReturnData => {
  const { groupDetails } = useContext(GroupContext);

  const [projectGroupData, setProjectGroupData] = useState<ProjectGroupData | undefined>();
  const [projectGroupDescription, setProjectGroupDescription] = useState<string | null>(null);
  const [isOnSubmitLoading, setIsOnSubmitLoading] = useState(false);
  const [errors, setErrors] = useState<ServiceError[]>([]);

  const onSubmit = useIsLoadingWrapper(async (): Promise<void> => {
    if (groupDetails?.groupGuid && projectGroupData?.rowVersion) {
      const response = await updateProjectGroup({
        description: projectGroupDescription,
        groupUuid: groupDetails?.groupGuid,
        rowVersion: projectGroupData?.rowVersion,
      });

      setErrors([]);

      if (response.status === Status.Success && response.data) {
        Toast.success({ message: "Group updated successfully" });
        setProjectGroupData({
          ...projectGroupData,
          rowVersion: response.data.rowVersion,
        });
      }
      if (response.status === Status.Error && response.errors) {
        setErrors(response.errors);
      }
    }
  }, setIsOnSubmitLoading);

  useEffect(() => {
    if (groupDetails) {
      setProjectGroupData({
        groupId: groupDetails.externalReference,
        groupName: groupDetails.groupName,
        rowVersion: groupDetails.rowVersion,
      });
      setProjectGroupDescription(groupDetails.groupDescription);
    }
  }, [groupDetails]);

  return {
    projectGroupData,
    projectGroupDescription,
    errors,
    setProjectGroupDescription,
    onSubmit,
    isOnSubmitLoading,
  };
};
