import "./DownloadActivityFilesModal.css";

import { Button, Column, Modal, Row } from "../../../../widget";

interface DownloadActivityFilesModalProps {
  show: boolean;
  onClose: () => void;
}

export const DownloadActivityFilesModal = ({ show, onClose }: DownloadActivityFilesModalProps): JSX.Element => {
  return (
    <div className="DownloadActivityFilesModal">
      <Modal show={show} title="Downloading Files" onClose={onClose}>
        <Row spacingV="2xl" justify="center">
          <Column span={10}>
            <>
              <p>Due to the size of these files they may take a couple of minutes to prepare.</p>

              <p>
                We will send you an email with a link to access them once they are ready for you to download, so please
                keep an eye on your inbox.
              </p>
            </>
          </Column>
        </Row>
        <Row justify="center">
          <Column span={6}>
            <Button text="Close" fullWidth onClick={onClose} />
          </Column>
        </Row>
      </Modal>
    </div>
  );
};
