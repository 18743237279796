import { BadgeData, FileType } from "../../../../../../models";
import { getPublicBadgeDetailsRoute } from "../../../../../../utils/routes";
import { Badge, Column, Row } from "../../../../../../widget";

interface PublicCertificationsSectionProps {
  projectUuid: string;
  badges: BadgeData[];
  isPreview?: boolean;
  certification?: boolean;
}

export const PublicCertificationsSection = ({
  projectUuid,
  badges,
  isPreview = false,
  certification = false,
}: PublicCertificationsSectionProps): JSX.Element => {
  return (
    <div className="PublicCertificationsSection">
      <Row spacingV="ml">
        <Column span={12}>
          <div className="PublicCertificationsHeader">
            <h2>{certification ? "Certifications" : "Badges"}</h2>
          </div>
        </Column>
      </Row>
      <Row spacingV="s">
        <Column span={12}>
          {certification ? (
            <p className="body1">
              This project has received one or more independent certifications to demonstrate the work or approach
              undertaken has been subject to an additional level of quality assurance. This certification is granted
              separately from the Code or Standard that this project is registered with. Choose a certification below to
              learn more.
            </p>
          ) : (
            <p className="body1">
              This project is part of one or more marketing associations, partnerships or schemes. Choose a badge below
              to learn more.
            </p>
          )}
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          {badges.map((b) => (
            <Badge
              key={b.uuid}
              link={!isPreview ? `${getPublicBadgeDetailsRoute(b.uuid)}?projectUuid=${projectUuid}` : undefined}
              label={b.displayName}
              imageSrc={b.files.find((f) => f.type === FileType.SmallLogo)?.file.url}
            />
          ))}
        </Column>
      </Row>
    </div>
  );
};
