import { DeveloperSingleCardTemplate } from "../../../templates";
import { CursorDataGrid } from "../../../widget";
import { useProjects } from "./useProjects";

const Projects = (): JSX.Element => {
  const { columns, isLoading, defaultSortingCriteria, onChange } = useProjects();
  return (
    <DeveloperSingleCardTemplate activeElement="Projects" pageTitle="Projects">
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        filterable
        onChange={onChange}
        data={[]}
        dataIsLoading={isLoading}
        noDataMessage="No projects to display"
        defaultSortingCriteria={defaultSortingCriteria}
      />
    </DeveloperSingleCardTemplate>
  );
};

export default Projects;
