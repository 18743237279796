import { DeveloperTemplate, ErrorTemplate } from "../../../templates";

const Error403 = (): JSX.Element => {
  return (
    <DeveloperTemplate>
      <ErrorTemplate errorText="The page or resource you are trying to view has restricted access." errorCode="403" />
    </DeveloperTemplate>
  );
};

export default Error403;
