import "./KPITooltip.css";

import Tooltip from "rc-tooltip";
import { ReactElement } from "react";

interface KPITooltipProps {
  text: string;
  children: ReactElement;
  offsetWidth?: number;
  offsetHeight?: number;
}

// default x offset 67 determined by tooltip width, default y offset -67 determined by height of graph
export const KPITooltip = ({ text, children, offsetWidth = 67, offsetHeight = -67 }: KPITooltipProps): JSX.Element => {
  return (
    <Tooltip
      placement="bottom"
      align={{ offset: [offsetWidth, offsetHeight] }}
      overlayClassName="KPITooltip"
      overlay={<div className="body4 KPITooltipContainer">{text}</div>}
    >
      {children}
    </Tooltip>
  );
};
