// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint @typescript-eslint/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// OrganisationService
import { jsonReviver } from "../../utils";
import { axios } from "../axios";
import { BaseResponse, BaseResponseWithSingle, Status } from "../Shared";
import {
  AcceptOrRejectProjectOrganisationInvitationRequest,
  DeleteOrganisationUserRequest,
  GetOrganisationListingPreviewRequest,
  GetOrganisationListingPreviewResponse,
  InviteOrganisationToProjectRequest,
  InviteOrganisationToProjectResponse,
  ResendProjectOrganisationInvitationRequest,
  ResendProjectOrganisationInvitationResponse,
  RevokeProjectOrganisationInvitationRequest,
  RevokeProjectOrganisationInvitationResponse,
  SaveOrganisationListingPreviewRequest,
  SaveOrganisationListingPreviewResponse,
  UpdateAssetManagerDetailsRequest,
  UpdateAssetManagerDetailsResponse,
  UpdateDeveloperDetailsRequest,
  UpdateDeveloperDetailsResponse,
  UpdateDeveloperListingRequest,
  UpdateDeveloperListingResponse,
  UpdateDevelopmentManagerDetailsRequest,
  UpdateDevelopmentManagerDetailsResponse,
  UpdateOrganisationAddressRequest,
  UpdateOrganisationAddressResponse,
  UpdateOrganisationDetailsRequest,
  UpdateOrganisationDetailsResponse,
  UpdateOrganisationEmbeddingPreferencesRequest,
  UpdateOrganisationEmbeddingPreferencesResponse,
  UpdateOrganisationUserRequest,
  UpdateOrganisationUserResponse,
  UpdateProjectOrganisationInvitationRequest,
  UpdateProjectOrganisationInvitationResponse,
  UpdateVerifierDetailsRequest,
  UpdateVerifierDetailsResponse,
  UpdateVerifierListingRequest,
  UpdateVerifierListingResponse,
  UploadListingFileRequest,
  UploadListingFileResponse,
  UploadOrganisationFileRequest,
  UploadOrganisationFileResponse,
} from "./Types";

export const uploadListingFile = async (
  req: UploadListingFileRequest
): Promise<BaseResponseWithSingle<UploadListingFileResponse>> => {
  const formData = new FormData();

  formData.append("ObjectUuid", req.objectUuid);
  formData.append("ObjectType", req.objectType);
  formData.append("File", req.file);

  return axios
    .request<BaseResponseWithSingle<UploadListingFileResponse>>({
      method: "post",
      url: "organisation/listing/file",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const uploadOrganisationFile = async (
  req: UploadOrganisationFileRequest
): Promise<BaseResponseWithSingle<UploadOrganisationFileResponse>> => {
  const formData = new FormData();

  formData.append("ObjectUuid", req.objectUuid);
  formData.append("ObjectType", req.objectType);
  formData.append("File", req.file);
  formData.append("Type", req.type);

  return axios
    .request<BaseResponseWithSingle<UploadOrganisationFileResponse>>({
      method: "post",
      url: "organisation/file",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateOrganisationDetails = async (
  req: UpdateOrganisationDetailsRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationDetailsResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateOrganisationDetailsResponse>>({
      method: "patch",
      url: "organisation/details",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateDeveloperDetails = async (
  req: UpdateDeveloperDetailsRequest
): Promise<BaseResponseWithSingle<UpdateDeveloperDetailsResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateDeveloperDetailsResponse>>({
      method: "patch",
      url: "developer/details",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateVerifierDetails = async (
  req: UpdateVerifierDetailsRequest
): Promise<BaseResponseWithSingle<UpdateVerifierDetailsResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateVerifierDetailsResponse>>({
      method: "patch",
      url: "verifier/details",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateOrganisationAddress = async (
  req: UpdateOrganisationAddressRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationAddressResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateOrganisationAddressResponse>>({
      method: "put",
      url: "organisation/address",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateOrganisationEmbeddingPreferences = async (
  req: UpdateOrganisationEmbeddingPreferencesRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationEmbeddingPreferencesResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateOrganisationEmbeddingPreferencesResponse>>({
      method: "patch",
      url: "organisation/embedding-preferences",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateDeveloperListing = async (
  req: UpdateDeveloperListingRequest
): Promise<BaseResponseWithSingle<UpdateDeveloperListingResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateDeveloperListingResponse>>({
      method: "put",
      url: "developer/listing",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateVerifierListing = async (
  req: UpdateVerifierListingRequest
): Promise<BaseResponseWithSingle<UpdateVerifierListingResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateVerifierListingResponse>>({
      method: "put",
      url: "verifier/listing",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateOrganisationUser = async (
  req: UpdateOrganisationUserRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationUserResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateOrganisationUserResponse>>({
      method: "put",
      url: "organisation/organisation-user",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const deleteOrganisationUser = async (req: DeleteOrganisationUserRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "delete",
      url: "organisation/organisation-user",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const getOrganisationListingPreview = async (
  req: GetOrganisationListingPreviewRequest
): Promise<BaseResponseWithSingle<GetOrganisationListingPreviewResponse>> => {
  return axios
    .request<BaseResponseWithSingle<GetOrganisationListingPreviewResponse>>({
      method: "get",
      url: `organisation/listing/preview?PreviewUuid=${req.previewUuid}`,
    })
    .then((response) => {
      if (response.data.data?.content) {
        response.data.data.content = JSON.parse(response.data.data.content, jsonReviver);
      }
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const saveOrganisationListingPreview = async (
  req: SaveOrganisationListingPreviewRequest
): Promise<BaseResponseWithSingle<SaveOrganisationListingPreviewResponse>> => {
  return axios
    .request<BaseResponseWithSingle<SaveOrganisationListingPreviewResponse>>({
      method: "post",
      url: "organisation/listing/preview",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const revokeProjectOrganisationInvitation = async (
  req: RevokeProjectOrganisationInvitationRequest
): Promise<BaseResponseWithSingle<RevokeProjectOrganisationInvitationResponse>> => {
  return axios
    .request<BaseResponseWithSingle<RevokeProjectOrganisationInvitationResponse>>({
      method: "post",
      url: "organisation/organisation-project-invitation/revoke",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const resendProjectOrganisationInvitation = async (
  req: ResendProjectOrganisationInvitationRequest
): Promise<BaseResponseWithSingle<ResendProjectOrganisationInvitationResponse>> => {
  return axios
    .request<BaseResponseWithSingle<ResendProjectOrganisationInvitationResponse>>({
      method: "post",
      url: "organisation/organisation-project-invitation/resend",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateProjectOrganisationInvitation = async (
  req: UpdateProjectOrganisationInvitationRequest
): Promise<BaseResponseWithSingle<UpdateProjectOrganisationInvitationResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateProjectOrganisationInvitationResponse>>({
      method: "post",
      url: "organisation/organisation-project-invitation/update",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const acceptOrRejectProjectOrganisationInvitation = async (
  req: AcceptOrRejectProjectOrganisationInvitationRequest
): Promise<BaseResponseWithSingle<void>> => {
  return axios
    .request<BaseResponseWithSingle<void>>({
      method: "post",
      url: "organisation/organisation-project-invitation/accept",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const inviteOrganisationToProject = async (
  req: InviteOrganisationToProjectRequest
): Promise<BaseResponseWithSingle<InviteOrganisationToProjectResponse>> => {
  return axios
    .request<BaseResponseWithSingle<InviteOrganisationToProjectResponse>>({
      method: "post",
      url: "organisation/organisation-project-invitation/send",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateDevelopmentManagerDetails = async (
  req: UpdateDevelopmentManagerDetailsRequest
): Promise<BaseResponseWithSingle<UpdateDevelopmentManagerDetailsResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateDevelopmentManagerDetailsResponse>>({
      method: "patch",
      url: "development-manager/details",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateAssetManagerDetails = async (
  req: UpdateAssetManagerDetailsRequest
): Promise<BaseResponseWithSingle<UpdateAssetManagerDetailsResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateAssetManagerDetailsResponse>>({
      method: "patch",
      url: "asset-manager/details",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};
