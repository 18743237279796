import "./MultiDraftModal.css";

import { SearchActivityHistoryResponse } from "../../../../service/query";
import { Column, CursorDataGrid, Modal, Row } from "../../../../widget";
import { useMultiDraftModal } from "./useMultiDraftModal";

interface MultiDraftModalProps {
  draftActivityHistory?: SearchActivityHistoryResponse;
  showMultiDraftModal: boolean;
  closeModal: () => void;
  resume: (uuid: string, draftActivityHistory: SearchActivityHistoryResponse) => void;
}

export const MultiDraftModal = ({
  showMultiDraftModal,
  closeModal,
  resume,
  draftActivityHistory,
}: MultiDraftModalProps): JSX.Element => {
  const { tableColumnsTopProjects, data } = useMultiDraftModal(resume, draftActivityHistory);

  return (
    <Modal show={showMultiDraftModal} title="Draft activities" onClose={closeModal}>
      <form className="MultiDraftModalContent">
        <Row spacingV="2xl" justify="center">
          <Column span={10}>
            <p className="body2">
              The activities listed below are currently in draft and will need to be resumed and saved in order to be
              retained. In order to prevent losing data, complete an activity by choosing &apos;Save and Close&apos; to
              create an audited version of the activity.
            </p>
            <p className="body2">Choose an activity to resume:</p>
          </Column>
        </Row>
        <Row justify="center">
          <Column span={10}>
            <CursorDataGrid
              data={data ?? []}
              onChange={undefined}
              columns={tableColumnsTopProjects}
              clientSide={false}
              pageable={false}
              sortable={false}
              pagination={undefined}
              dataIsLoading={false}
              showTotalCount={false}
              noDataMessage="No activities to display"
            />
          </Column>
        </Row>
      </form>
    </Modal>
  );
};
