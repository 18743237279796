/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  Dispatch,
  FormEvent,
  ForwardedRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { FileUploadHandle } from "../../../../../../models";
import { uploadListingFile } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual, useIsLoadingWrapper } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import {
  FormHeroData,
  FormNames,
  MarketingAssetsFormChangedAction,
  MarketingAssetsSubmitHandler,
  UpdateListingType,
} from "../../models";

interface UseHeroFormReturnData {
  bannerImageUploadRef: ForwardedRef<FileUploadHandle>;
  enabled: boolean;
  title: string | null | undefined;
  subtitle: string | null | undefined;
  bannerImageUrl: string | null | undefined;
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  setTitle: Dispatch<SetStateAction<string | null | undefined>>;
  handleSetBannerImage: (f: File) => Promise<void>;
  setSubtitle: Dispatch<SetStateAction<string | null | undefined>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
  hasPermission: (permission: string) => boolean;
}

export const useHeroForm = (
  objectUuid: string,
  objectType: string,
  objectKey: string,
  updateListing: UpdateListingType,
  heroDefaultValues: FormHeroData,
  heroOldDefaultValues: FormHeroData,
  listingRowVersion: number,
  setListingRowVersion: Dispatch<SetStateAction<number>>,
  dispatch: Dispatch<MarketingAssetsFormChangedAction>,
  forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>,
  getCurrentFormsData: (formWhichWasSaved: string) => string
): UseHeroFormReturnData => {
  const { hasPermission } = useAuth();

  const [submittedValues, setSubmittedValues] = useState<FormHeroData>(heroOldDefaultValues);

  const bannerImageUploadRef = useRef<FileUploadHandle>(null);

  const [enabled, setEnabled] = useState(heroDefaultValues.enabled);
  const [title, setTitle] = useState(heroDefaultValues.title);
  const [subtitle, setSubtitle] = useState(heroDefaultValues.subtitle);
  const [bannerImageUuid, setBannerImageUuid] = useState<string | null | undefined>(heroDefaultValues.imageFileUuid);
  const [bannerImageUrl, setBannerImageUrl] = useState(heroDefaultValues.imageFileUrl ?? null);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  useImperativeHandle(forwardedRef, () => ({
    getHeroData() {
      return {
        enabled,
        title,
        subtitle,
        imageFileUuid: bannerImageUuid,
        imageFileUrl: bannerImageUrl,
      };
    },
    getHeroSubmittedData() {
      return {
        ...submittedValues,
      };
    },
    setHeroErrors(previewErrors: ServiceError[]) {
      setErrors(previewErrors);
    },
  }));

  const handleSetBannerImage = async (f: File): Promise<void> => {
    if (objectUuid) {
      setErrors([]);

      const fileRes = await uploadListingFile({
        objectUuid,
        objectType,
        file: f,
      });

      if (fileRes.status === Status.Success && fileRes.data?.organisationListingFileUuid) {
        Toast.success({ message: "File uploaded successfully" });
        setBannerImageUuid(fileRes.data?.organisationListingFileUuid);
        setBannerImageUrl(fileRes.data?.file.url);
      }

      if (fileRes.status === Status.Error && fileRes.errors) {
        setErrors(fileRes.errors);
      }
    }
  };

  const getCurrentFormData = useCallback((): FormHeroData => {
    return {
      enabled,
      title,
      subtitle,
      imageFileUuid: bannerImageUuid,
      imageFileUrl: bannerImageUrl,
    };
  }, [enabled, title, subtitle, bannerImageUuid]);

  useEffect(() => {
    dispatch({
      type: "SET_HERO_FORM_CHANGED",
      value: !areObjectsDeepEqual(submittedValues, getCurrentFormData()),
    });
  }, [enabled, title, subtitle, bannerImageUuid, submittedValues]);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);
    if (objectUuid) {
      const newHeroValues = {
        enabled,
        title,
        subtitle,
        imageFileUuid: bannerImageUuid,
        imageFileUrl: bannerImageUrl,
      };

      const detailsRes = await updateListing({
        [objectKey]: objectUuid,
        content: getCurrentFormsData(FormNames.Hero),
        rowVersion: listingRowVersion,
      });

      if (detailsRes.status === Status.Success && detailsRes.data) {
        Toast.success({ message: "Hero details changed successfully" });
        setSubmittedValues({
          ...newHeroValues,
        });
        setListingRowVersion(detailsRes.data?.rowVersion);
      }

      if (detailsRes.status === Status.Error) {
        setErrors(detailsRes.errors);
      }
    }
  }, setIsHandleSubmitLoading);

  const handleCancel = (): void => {
    if (bannerImageUploadRef.current) {
      bannerImageUploadRef.current.clearInput();
      setBannerImageUuid(submittedValues.imageFileUuid);
      setBannerImageUrl(submittedValues.imageFileUrl ?? null);
    }
    setEnabled(submittedValues.enabled);
    setTitle(submittedValues.title);
    setSubtitle(submittedValues.subtitle);
    setErrors([]);
  };

  return {
    bannerImageUploadRef,
    enabled,
    title,
    subtitle,
    bannerImageUrl,
    errors,
    isHandleSubmitLoading,
    setEnabled,
    setTitle,
    handleSetBannerImage,
    setSubtitle,
    handleSubmit,
    handleCancel,
    hasPermission,
  };
};
