import { TabsList } from "../../../models";
import { PublicProjectPreviewTemplate } from "../../../templates";
import { useProjectPreview } from "./useProjectPreview";

const ProjectPreview = (): JSX.Element => {
  const tabsList: TabsList = [
    {
      label: "Overview",
      route: "overview",
    },
    {
      label: "Details",
      route: "#",
    },
    {
      label: "Documents",
      route: "#",
    },
    {
      label: "Issuances",
      route: "#",
    },
  ];

  const { isLoading, projectDetails, developerIcon, projectBadges, images } = useProjectPreview();

  return (
    <div>
      {!isLoading && (
        <PublicProjectPreviewTemplate
          project={projectDetails}
          tabsList={tabsList}
          developerIcon={developerIcon}
          projectBadges={projectBadges}
          images={images}
        />
      )}
    </div>
  );
};

export default ProjectPreview;
