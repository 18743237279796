import "./Details.css";

import {
  OrganisationPermissionConstants,
  ProjectAccessConstants,
  ProjectPermissionConstants,
} from "../../../../../../constants";
import { DeveloperSingleCardTemplate } from "../../../../../../templates";
import { getFormattedDate } from "../../../../../../utils";
import { ActionButtons, Button, Column, ReadonlyText, Row, Select } from "../../../../../../widget";
import { ResendAccessInvitationModal, RevokeUserAccessModal } from "./components";
import { useDetails } from "./useDetails";

export const Details = (): JSX.Element => {
  const {
    handleBackClick,
    backLinkText,
    invitationDetails,
    accessLevelKey,
    onResendInvitation,
    showResendInvitationModal,
    onOpenResendInvitationModal,
    onCloseResendInvitationModal,
    onSubmit,
    onCancel,
    onAccessLevelChange,
    onRevokeAccess,
    showRevokeModal,
    onOpenRevokeAccessModal,
    onCloseRevokeModal,
    hasPermission,
    isOnSubmitLoading,
  } = useDetails();

  return (
    <div>
      {invitationDetails && (
        <DeveloperSingleCardTemplate
          pageTitle={invitationDetails.project.displayName}
          handleBackClick={backLinkText ? handleBackClick : undefined}
          activeElement="Projects"
          backButtonText={backLinkText}
        >
          <form className="ProjectAccessDetails" onSubmit={onSubmit}>
            <div className="ProjectAccessDetailsHeader">
              <h2>Project Access</h2>
              {hasPermission(ProjectPermissionConstants.MANAGE_PROJECT_ORGANISATION_INVITATION) && (
                <div>
                  {(invitationDetails.calculatedStatus === ProjectAccessConstants.STATUS_ACCEPTED ||
                    invitationDetails.calculatedStatus === ProjectAccessConstants.STATUS_SENT) && (
                    <Button text="Revoke access" variant="secondary" onClick={onOpenRevokeAccessModal} />
                  )}
                  {(invitationDetails.calculatedStatus === ProjectAccessConstants.STATUS_EXPIRED ||
                    invitationDetails.calculatedStatus === ProjectAccessConstants.STATUS_REVOKED ||
                    invitationDetails.calculatedStatus === ProjectAccessConstants.STATUS_SENT) && (
                    <Button text="Resend invitation" variant="tertiary" onClick={onOpenResendInvitationModal} />
                  )}
                </div>
              )}
            </div>
            <Row spacingV="ll">
              <Column span={4}>
                <ReadonlyText label="Developer" value={invitationDetails.targetOrganisation?.displayName} />
              </Column>
              <Column span={4} offset={1}>
                <ReadonlyText label="Status" value={invitationDetails.calculatedStatus} />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={4}>
                <ReadonlyText label="Sent to" value={invitationDetails.targetOrganisation?.contactEmail} />
              </Column>
              <Column span={4} offset={1}>
                <ReadonlyText label="Granted by" value={invitationDetails.sentByUser?.fullName} />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={4}>
                <ReadonlyText label="Phone number" value={invitationDetails.targetOrganisation?.contactPhone} />
              </Column>
              <Column span={4} offset={1}>
                <ReadonlyText label="Date sent" value={getFormattedDate(invitationDetails.sentDate)} />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={4}>
                {hasPermission(OrganisationPermissionConstants.WRITE) ? (
                  <Select
                    label="Access level"
                    data={ProjectAccessConstants.ACCESS_LEVEL_ROLE_OPTIONS}
                    initialKey={invitationDetails.targetRole}
                    value={accessLevelKey}
                    onChange={onAccessLevelChange}
                  />
                ) : (
                  <ReadonlyText label="Access level" value={invitationDetails.targetRole} />
                )}
              </Column>
              <Column span={4} offset={1}>
                {invitationDetails.calculatedStatus === ProjectAccessConstants.STATUS_REJECTED ? (
                  <ReadonlyText label="Rejected by" value={invitationDetails.rejectedByUser?.fullName} />
                ) : (
                  <ReadonlyText label="Accepted by" value={invitationDetails.acceptedByUser?.fullName} />
                )}
              </Column>
            </Row>
            {hasPermission(ProjectPermissionConstants.MANAGE_PROJECT_ORGANISATION_INVITATION) && (
              <ActionButtons onCancel={onCancel} isLoading={isOnSubmitLoading} />
            )}
          </form>
          <RevokeUserAccessModal
            show={showRevokeModal}
            onRevokeAccess={onRevokeAccess}
            closeModal={onCloseRevokeModal}
          />
          <ResendAccessInvitationModal
            show={showResendInvitationModal}
            onResendAccessInvitation={onResendInvitation}
            closeModal={onCloseResendInvitationModal}
          />
        </DeveloperSingleCardTemplate>
      )}
    </div>
  );
};
