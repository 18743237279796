import "./FullPageCard.css";

import { ReactNode } from "react";

interface FullPageCardProps {
  children: ReactNode;
}

export const FullPageCard = ({ children }: FullPageCardProps): JSX.Element => {
  return <div className="FullPageCard">{children}</div>;
};
