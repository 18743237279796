import "./ChartsSection.css";

import { ChartData, Column, PieChart, Row } from "../../../widget";

interface ChartsSectionProps {
  title: string;
  content?: string | null;
  firstChartData: ChartData;
  firstChartTitle: string;
  firstChartNoDataMessage?: string;
  secondChartData: ChartData;
  secondChartTitle: string;
  secondChartNoDataMessage?: string;
  thirdChartData: ChartData;
  thirdChartTitle: string;
  thirdChartNoDataMessage?: string;
  fourthChartData?: ChartData;
  fourthChartTitle?: string;
  fourthChartNoDataMessage?: string;
  direction?: "Vertical" | "Horizontal";
  isGradient?: boolean;
}

export const ChartsSection = ({
  title,
  content,
  firstChartData,
  firstChartTitle,
  firstChartNoDataMessage,
  secondChartData,
  secondChartTitle,
  secondChartNoDataMessage,
  thirdChartData,
  thirdChartTitle,
  thirdChartNoDataMessage,
  fourthChartData,
  fourthChartTitle,
  fourthChartNoDataMessage,
  direction = "Horizontal",
  isGradient = false,
}: ChartsSectionProps): JSX.Element => {
  return (
    <div className="ChartsSection">
      <Row spacingV="ll">
        <Column span={12}>
          <h2>{title}</h2>
        </Column>
      </Row>
      {content && (
        <Row spacingV="ll">
          <Column span={12}>
            <p className="body1">{content}</p>
          </Column>
        </Row>
      )}
      <Row justify="between">
        <Column span={3}>
          <div>
            <h3>{firstChartTitle}</h3>
            <PieChart
              size={120}
              data={firstChartData}
              noDataMessage={firstChartNoDataMessage}
              direction={direction}
              isGradient={isGradient}
            />
          </div>
        </Column>
        <Column span={3}>
          <div>
            <h3>{secondChartTitle}</h3>
            <PieChart
              size={120}
              data={secondChartData}
              noDataMessage={secondChartNoDataMessage}
              direction={direction}
              isGradient={isGradient}
            />
          </div>
        </Column>
        <Column span={3}>
          <div>
            <h3>{thirdChartTitle}</h3>
            <PieChart
              size={120}
              data={thirdChartData}
              noDataMessage={thirdChartNoDataMessage}
              direction={direction}
              isGradient={isGradient}
            />
          </div>
        </Column>
        {fourthChartData && fourthChartTitle && (
          <Column span={3}>
            <div>
              <h3>{fourthChartTitle}</h3>
              <PieChart
                size={120}
                data={fourthChartData}
                noDataMessage={fourthChartNoDataMessage}
                direction={direction}
                isGradient={isGradient}
              />
            </div>
          </Column>
        )}
      </Row>
    </div>
  );
};
