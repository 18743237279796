import "./Gallery.css";

import { GridRoundDarkIcon, PlayButtonDarkIcon, YoutubePlayButtonIcon } from "../../../assets";
import { getYoutubeId } from "../../../utils";
import { Button, Column, Row } from "../..";
import { useGallery } from "./useGallery";
import { VisualMediaModal } from "./VisualMediaModal";

interface GalleryProps {
  imageSources: string[];
  videoUrl?: string | null;
}

export const Gallery = ({ imageSources, videoUrl = null }: GalleryProps): JSX.Element => {
  const { showVisualMediaModal, displayedVisualMediaNumber, setShowVisualMediaModal, setDisplayedVisualMediaNumber } =
    useGallery();

  const visualMediaCount = (videoUrl ? 1 : 0) + imageSources.length;

  switch (visualMediaCount) {
    case 0:
      return <div />;
    case 1:
      return (
        <>
          {!videoUrl ? (
            <div
              style={{
                backgroundImage: `url(${imageSources[0]})`,
              }}
              role="button"
              onKeyDown={() => setShowVisualMediaModal(true)}
              onClick={() => setShowVisualMediaModal(true)}
              tabIndex={-1}
              aria-label="View Image"
              className="Gallery"
            />
          ) : (
            <div className="GalleryYoutubeThumbnailWrapper">
              <div
                role="button"
                onKeyDown={() => setShowVisualMediaModal(true)}
                onClick={() => setShowVisualMediaModal(true)}
                tabIndex={-1}
                aria-label="View Video"
                className="YoutubeThumbnailOverlay"
              />
              <div
                style={{
                  backgroundImage: `url(https://img.youtube.com/vi/${getYoutubeId(videoUrl)}/sddefault.jpg)`,
                }}
                className="YoutubeThumbnail"
              />
              <div
                role="button"
                onKeyDown={() => setShowVisualMediaModal(true)}
                onClick={() => setShowVisualMediaModal(true)}
                tabIndex={-1}
                aria-label="View Video"
                className="YoutubePlayIcon"
              >
                <YoutubePlayButtonIcon />
              </div>
            </div>
          )}
          <VisualMediaModal
            show={showVisualMediaModal}
            currentDisplayedImage={imageSources[displayedVisualMediaNumber - 1]}
            currentVisualMediaNumber={displayedVisualMediaNumber}
            maxVisualMediaNumber={1}
            onClose={setShowVisualMediaModal}
            onVisualMediaChange={setDisplayedVisualMediaNumber}
            videoUrl={videoUrl}
          />
        </>
      );
    case 2:
      return (
        <>
          <div
            style={{
              backgroundImage: `url(${imageSources[0]})`,
            }}
            role="button"
            onKeyDown={() => setShowVisualMediaModal(true)}
            onClick={() => setShowVisualMediaModal(true)}
            tabIndex={-1}
            className="Gallery"
          >
            {videoUrl ? (
              <Button
                text="Video"
                variant="reversedPrimary"
                icon={<PlayButtonDarkIcon />}
                iconPosition="left"
                onClick={() => {
                  setShowVisualMediaModal(true);
                  setDisplayedVisualMediaNumber(visualMediaCount);
                }}
              />
            ) : (
              <Button
                text="All photos"
                variant="reversedPrimary"
                icon={<GridRoundDarkIcon />}
                iconPosition="left"
                onClick={() => {
                  setShowVisualMediaModal(true);
                  setDisplayedVisualMediaNumber(1);
                }}
              />
            )}
          </div>
          <VisualMediaModal
            show={showVisualMediaModal}
            currentDisplayedImage={imageSources[displayedVisualMediaNumber - 1]}
            currentVisualMediaNumber={displayedVisualMediaNumber}
            maxVisualMediaNumber={2}
            onClose={setShowVisualMediaModal}
            onVisualMediaChange={setDisplayedVisualMediaNumber}
            videoUrl={videoUrl}
          />
        </>
      );
    default:
      return (
        <>
          {imageSources.length >= 3 ? (
            <div className="GalleryMultiple">
              <Row>
                <Column span={8}>
                  <div
                    style={{
                      backgroundImage: `url(${imageSources[0]})`,
                    }}
                    role="button"
                    onKeyDown={() => setShowVisualMediaModal(true)}
                    onClick={() => {
                      setShowVisualMediaModal(true);
                      setDisplayedVisualMediaNumber(1);
                    }}
                    tabIndex={-1}
                    aria-label="View Image"
                    className="FirstImage"
                  />
                </Column>
                <Column span={4} className="SecondAndThirdImagesContainer">
                  <div
                    style={{
                      backgroundImage: `url(${imageSources[1]})`,
                    }}
                    role="button"
                    onKeyDown={() => setShowVisualMediaModal(true)}
                    onClick={() => {
                      setShowVisualMediaModal(true);
                      setDisplayedVisualMediaNumber(2);
                    }}
                    tabIndex={-1}
                    aria-label="View Image"
                  />
                  <div
                    style={{
                      backgroundImage: `url(${imageSources[2]})`,
                    }}
                    role="button"
                    onKeyDown={() => setShowVisualMediaModal(true)}
                    onClick={() => {
                      setShowVisualMediaModal(true);
                      setDisplayedVisualMediaNumber(3);
                    }}
                    tabIndex={-1}
                    aria-label="View Image"
                  />
                </Column>
              </Row>
              <Button
                text="All photos"
                variant="reversedPrimary"
                icon={<GridRoundDarkIcon />}
                iconPosition="left"
                onClick={() => {
                  setDisplayedVisualMediaNumber(1);
                  setShowVisualMediaModal(true);
                }}
              />
              {videoUrl && (
                <Button
                  text="Video"
                  variant="reversedPrimary"
                  icon={<PlayButtonDarkIcon />}
                  iconPosition="left"
                  onClick={() => {
                    setShowVisualMediaModal(true);
                    setDisplayedVisualMediaNumber(visualMediaCount);
                  }}
                />
              )}
            </div>
          ) : (
            <div
              style={{
                backgroundImage: `url(${imageSources[0]})`,
              }}
              role="button"
              onKeyDown={() => setShowVisualMediaModal(true)}
              onClick={() => setShowVisualMediaModal(true)}
              tabIndex={-1}
              className="Gallery"
            >
              <Button
                text="All photos"
                variant="reversedPrimary"
                icon={<GridRoundDarkIcon />}
                iconPosition="left"
                onClick={() => {
                  setShowVisualMediaModal(true);
                  setDisplayedVisualMediaNumber(1);
                }}
              />
              {visualMediaCount > imageSources.length && (
                <Button
                  text="Video"
                  variant="reversedPrimary"
                  icon={<PlayButtonDarkIcon />}
                  iconPosition="left"
                  onClick={() => {
                    setShowVisualMediaModal(true);
                    setDisplayedVisualMediaNumber(visualMediaCount);
                  }}
                />
              )}
            </div>
          )}
          <VisualMediaModal
            show={showVisualMediaModal}
            currentDisplayedImage={imageSources[displayedVisualMediaNumber - 1]}
            currentVisualMediaNumber={displayedVisualMediaNumber}
            maxVisualMediaNumber={visualMediaCount}
            onClose={setShowVisualMediaModal}
            onVisualMediaChange={setDisplayedVisualMediaNumber}
            videoUrl={videoUrl}
          />
        </>
      );
  }
};
