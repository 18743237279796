import { Button, Column, Modal, Row } from "../../../../../../../../widget";

interface RevokeUserAccessModalProps {
  show: boolean;
  onRevokeAccess: () => void;
  closeModal: () => void;
}

export const RevokeUserAccessModal = ({
  show,
  onRevokeAccess,
  closeModal,
}: RevokeUserAccessModalProps): JSX.Element => {
  return (
    <Modal show={show} title="Revoke user access?" onClose={closeModal}>
      <Row justify="center" spacingV="2xl">
        <Column span={12}>
          <p className="body1">
            If you revoke this developer&apos;s access, they will no longer be able to view/edit this project and any of
            the associated documents or workflows.
          </p>
          <p className="body1">Are you sure you want to revoke access?</p>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={12}>
          <Button text="Yes" onClick={onRevokeAccess} />
          <Button variant="secondary" text="No, keep their access" onClick={closeModal} />
        </Column>
      </Row>
    </Modal>
  );
};
