import { useMemo } from "react";

import { GetProjectDetailsResponse } from "../../../../../../service/publicquery";

interface UseBasicDetailsSectionReturnData {
  totalProjectArea: string;
}

export const useBasicDetailsSection = (projectDetails: GetProjectDetailsResponse): UseBasicDetailsSectionReturnData => {
  const totalProjectArea = useMemo(
    () => (projectDetails?.areaNetHa !== null ? `${projectDetails?.areaNetHa} ha` : ""),
    [projectDetails]
  );

  return { totalProjectArea };
};
