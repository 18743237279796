import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { OrganisationTypeConstants, ProjectAccessConstants } from "../../../../../../constants";
import { NavigationSearchParams, Origin, PrivateProjectAccessDetailsParams } from "../../../../../../models";
import {
  resendProjectOrganisationInvitation,
  revokeProjectOrganisationInvitation,
  updateProjectOrganisationInvitation,
} from "../../../../../../service/organisation";
import {
  getProjectOrganisationInvitationDetails,
  GetProjectOrganisationInvitationDetailsResponse,
} from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../../utils";
import {
  getProjectAccessDetailsRoute,
  getProjectAccessRoute,
  getProjectAuditHistoryRoute,
  getSettingsAuditHistoryRoute,
  getSettingsProjectAccessRoute,
} from "../../../../../../utils/routes";
import { Toast } from "../../../../../../widget";

interface UseDetailsReturnData {
  handleBackClick: () => void;
  backLinkText?: string;
  invitationDetails?: GetProjectOrganisationInvitationDetailsResponse;
  accessLevelKey?: string;
  onResendInvitation: () => void;
  showResendInvitationModal: boolean;
  onOpenResendInvitationModal: () => void;
  onCloseResendInvitationModal: () => void;
  onSubmit: (data: React.FormEvent<HTMLFormElement>) => void;
  onCancel: () => void;
  onAccessLevelChange: (value: string) => void;
  onRevokeAccess: () => void;
  showRevokeModal: boolean;
  onOpenRevokeAccessModal: () => void;
  onCloseRevokeModal: () => void;
  hasPermission: (permission: string) => boolean;
  isOnSubmitLoading: boolean;
}

export const useDetails = (): UseDetailsReturnData => {
  const navigate = useNavigate();

  const { hasPermission, currentUserType } = useAuth();

  const { projectOrganisationInvitationUuid } = useParams<PrivateProjectAccessDetailsParams>();
  const [searchParams] = useSearchParams();

  const [invitationDetails, setInvitationDetails] = useState<GetProjectOrganisationInvitationDetailsResponse>();
  const [rowVersion, setRowVersion] = useState<number>();
  const [accessLevelKey, setAccessLevelKey] = useState<string>();
  const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false);
  const [showResendInvitationModal, setShowResendInvitationModal] = useState<boolean>(false);
  const [backLinkText, setBackLinkText] = useState<string>();
  const [backLink, setBackLink] = useState<string>();
  const [projectUuid, setProjectUuid] = useState<string>();
  const [isOnSubmitLoading, setIsOnSubmitLoading] = useState(false);

  const getInvitationDetails = useCallback(
    async (invitationUuid: string) => {
      const response = await getProjectOrganisationInvitationDetails({
        projectOrganisationInvitationUuid: invitationUuid,
      });

      if (response.status === Status.Success && response.data) {
        setInvitationDetails(response.data);
        setAccessLevelKey(response.data.targetRole);
        setRowVersion(response.data.rowVersion);
        setProjectUuid(response.data.project.uuid);
      }
    },
    [projectOrganisationInvitationUuid]
  );

  useEffect(() => {
    if (projectOrganisationInvitationUuid) {
      getInvitationDetails(projectOrganisationInvitationUuid);
    }
  }, [projectOrganisationInvitationUuid]);

  useEffect(() => {
    // TODO: We could refactor the searchParams here to use the origin identifier
    const isBackToProjectAccessTab = !!searchParams.get(NavigationSearchParams.projectAccessTab);
    if (isBackToProjectAccessTab) {
      setBackLinkText("Back to full project");
      if (invitationDetails?.project) {
        setBackLink(getProjectAccessRoute(invitationDetails?.project.uuid));
      }
    }
    const isBackToSettingsProjectAccessTab = !!searchParams.get(NavigationSearchParams.settingsProjectAccessTab);
    if (isBackToSettingsProjectAccessTab) {
      setBackLinkText("Back to project access");
      setBackLink(getSettingsProjectAccessRoute(OrganisationTypeConstants.DEVELOPER));
    }
    const origin = searchParams.get("origin");
    if (origin === Origin.ProjectAuditPage && projectUuid) {
      setBackLinkText("Back to audit");
      setBackLink(getProjectAuditHistoryRoute(projectUuid, currentUserType));
    }
    if (origin === Origin.OrganisationAuditPage) {
      setBackLinkText("Back to audit");
      setBackLink(getSettingsAuditHistoryRoute(currentUserType));
    }
  }, [searchParams, invitationDetails]);

  const handleBackClick = (): void => {
    if (backLink) {
      navigate(backLink);
    }
  };

  const onCloseRevokeModal = (): void => {
    setShowRevokeModal(false);
  };

  const onOpenRevokeAccessModal = (): void => {
    setShowRevokeModal(true);
  };

  const onRevokeAccess = async (): Promise<void> => {
    setShowRevokeModal(false);
    if (invitationDetails && projectOrganisationInvitationUuid && rowVersion) {
      const response = await revokeProjectOrganisationInvitation({
        projectOrganisationInvitationUuid,
        rowVersion,
      });

      if (response.status === Status.Success && response.data) {
        Toast.success({ message: "You have successfully updated the project access invitation" });
        setInvitationDetails({ ...invitationDetails, calculatedStatus: ProjectAccessConstants.STATUS_REVOKED });
        setRowVersion(response.data.rowVersion);
      }

      if (response.status === Status.Error && response.errors && response.errors.length) {
        Toast.error({ message: getErrorMessageFromCode(response.errors[0].code) });
      }
    }
  };

  const onSubmit = useIsLoadingWrapper(async (data: React.FormEvent<HTMLFormElement>): Promise<void> => {
    data.preventDefault();
    if (invitationDetails && accessLevelKey && projectOrganisationInvitationUuid && rowVersion) {
      const response = await updateProjectOrganisationInvitation({
        projectOrganisationInvitationUuid,
        targetRole: accessLevelKey,
        rowVersion,
      });

      if (response.status === Status.Success && response.data) {
        Toast.success({ message: "You have successfully updated the project access invitation" });
        setInvitationDetails({ ...invitationDetails, targetRole: accessLevelKey });
        setRowVersion(response.data.rowVersion);
      }

      if (response.status === Status.Error && response.errors && response.errors.length) {
        Toast.error({ message: getErrorMessageFromCode(response.errors[0].code) });
      }
    }
  }, setIsOnSubmitLoading);

  const onCancel = (): void => {
    setAccessLevelKey(invitationDetails?.targetRole);
  };

  const onAccessLevelChange = (value: string): void => {
    setAccessLevelKey(value);
  };

  const onCloseResendInvitationModal = (): void => {
    setShowResendInvitationModal(false);
  };

  const onOpenResendInvitationModal = (): void => {
    setShowResendInvitationModal(true);
  };

  const onResendInvitation = async (): Promise<void> => {
    setShowResendInvitationModal(false);
    if (invitationDetails && projectOrganisationInvitationUuid && rowVersion) {
      const response = await resendProjectOrganisationInvitation({
        projectOrganisationInvitationUuid,
        rowVersion,
      });

      if (response.status === Status.Success && response.data) {
        Toast.success({ message: "You have successfully updated the project access invitation" });
        navigate(
          `${getProjectAccessDetailsRoute(response.data.projectOrganisationInvitationUuid)}?${
            NavigationSearchParams.settingsProjectAccessTab
          }=true`
        );
      }

      if (response.status === Status.Error && response.errors && response.errors.length) {
        Toast.error({ message: getErrorMessageFromCode(response.errors[0].code) });
      }
    }
  };

  return {
    handleBackClick,
    backLinkText,
    invitationDetails,
    accessLevelKey,
    onResendInvitation,
    showResendInvitationModal,
    onOpenResendInvitationModal,
    onCloseResendInvitationModal,
    onSubmit,
    onCancel,
    onAccessLevelChange,
    onRevokeAccess,
    showRevokeModal,
    onOpenRevokeAccessModal,
    onCloseRevokeModal,
    hasPermission,
    isOnSubmitLoading,
  };
};
