import React, { useEffect, useState } from "react";

import { StyleConstants } from "../../../constants";
import { useTrapFocusWithinDiv } from "../../../utils";

interface useFlyoutPanelProps {
  show: boolean;
  onClose: () => void;
}

interface useFlyoutPanelReturnData {
  flyoutPanelRefCallBack: (node: HTMLDivElement) => void;
  onFlyoutPanelKeyDownHandle: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  showTransition: boolean;
}

export const useFlyoutPanel = ({ show, onClose }: useFlyoutPanelProps): useFlyoutPanelReturnData => {
  const flyoutPanelRefCallBack = useTrapFocusWithinDiv();
  const [showTransition, setShowTransition] = useState(false);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setShowTransition(show);
    }, 100);

    return () => clearTimeout(debounce);
  }, [show]);

  const onFlyoutPanelKeyDownHandle = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (e.key) {
      case "Escape":
        onClose();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add(StyleConstants.OVERFLOW_HIDDEN_CLASS);
    } else {
      document.body.classList.remove(StyleConstants.OVERFLOW_HIDDEN_CLASS);
    }
    return () => document.body.classList.remove(StyleConstants.OVERFLOW_HIDDEN_CLASS);
  }, [show]);

  return {
    flyoutPanelRefCallBack,
    onFlyoutPanelKeyDownHandle,
    showTransition,
  };
};
