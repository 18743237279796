import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { BadgeTypeConstants } from "../../../../../constants";
import { BadgeData } from "../../../../../models";
import {
  GetProjectDetailsResponse,
  getProjectIssuances,
  GetProjectIssuancesResponse,
  getStandardSpecificSchemaDetails,
} from "../../../../../service/publicquery";
import { Status } from "../../../../../service/Shared";
import { StandardSpecificDefinition } from "../../../../shared/projects/project/overview/components/StandardSpecificSection/types";
import { usePublicContext } from "../../../usePublicContext";

interface UsePreviewReturnData {
  isLoading: boolean;
  projectDetails: GetProjectDetailsResponse | undefined;
  tags: string[] | null;
  badges: BadgeData[];
  certifications: BadgeData[];
  chartData: { [key: string]: number } | undefined;
  standardSpecificDefinition: StandardSpecificDefinition | undefined;
}

export const usePreview = (): UsePreviewReturnData => {
  const { uuid } = useParams();
  const { projectDetails } = usePublicContext();
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState<{ [key: string]: number } | undefined>(undefined);
  const [standardSpecificDefinition, setStandardSpecificDefinition] = useState<
    StandardSpecificDefinition | undefined
  >();

  const tags = !!projectDetails?.tags && !!projectDetails?.tags.length ? projectDetails?.tags : null;
  const certifications =
    projectDetails?.badges.filter((b) => b.type === BadgeTypeConstants.BADGE_TYPE_CERTIFICATION) || [];
  const badges = projectDetails?.badges.filter((b) => b.type === BadgeTypeConstants.BADGE_TYPE_BADGE) || [];

  const fetchData = useCallback(async (projectUuid: string) => {
    const response = await getProjectIssuances({ projectUuid });
    if (response.status === Status.Success && response.data) {
      const projectIssuancesChartData = response.data.reduce((tmp: { [key: string]: number }, value) => {
        const endYear = value.vintageEndDate.getFullYear();
        const period = endYear.toString();

        if (response.data !== null && response.data !== undefined)
          // eslint-disable-next-line no-param-reassign
          tmp[period] = response.data
            .filter((d) => d.vintageEndDate.getFullYear() <= endYear)
            .reduce((acc: number, k: GetProjectIssuancesResponse) => acc + k.quantity, 0);
        return tmp;
      }, {});

      setChartData(projectIssuancesChartData);
    }

    if (projectDetails && projectDetails.standardSpecificSchemaUuid) {
      const standardDefinitionResponse = await getStandardSpecificSchemaDetails({
        standardSpecificSchemaUuid: projectDetails.standardSpecificSchemaUuid,
      });

      if (standardDefinitionResponse.status === Status.Success && standardDefinitionResponse.data) {
        setStandardSpecificDefinition(standardDefinitionResponse.data.definition);
      }
    }
  }, []);

  useEffect(() => {
    if (uuid) {
      fetchData(uuid).then(() => setIsLoading(false));
    }
  }, [fetchData]);

  return {
    isLoading,
    chartData,
    tags,
    badges,
    certifications,
    projectDetails,
    standardSpecificDefinition,
  };
};
