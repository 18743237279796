import { useCallback, useEffect, useState } from "react";

import { OrganisationTypeConstants } from "../../../constants";
import { getProjectAggregate } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";
import { getCountryNameByCode } from "../../../utils";
import { ChartData } from "../../../widget";

interface UseAtAGlanceSectionReturnData {
  projectsChartData: ChartData;
  unitsChartData: ChartData;
  locationsChartData: ChartData;
  isLoading: boolean;
}

export const useAtAGlanceSection = (
  organisationUuid: string | null | undefined,
  organisationType: OrganisationTypeConstants
): UseAtAGlanceSectionReturnData => {
  const [projectsChartData, setProjectsChartData] = useState<ChartData>({});
  const [unitsChartData, setUnitsChartData] = useState<ChartData>({});
  const [locationsChartData, setLocationsChartData] = useState<ChartData>({});
  const [isLoading, setIsLoading] = useState(true);

  const filterBy = organisationType.toString().toLowerCase();

  const fetchData = useCallback(async (): Promise<void> => {
    if (organisationUuid) {
      const [totalProjectsRes, projectVcuRes, projectPiuRes, projectLocationsRes] = await Promise.all([
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue: organisationUuid,
          groupBy: "standard.displayName",
          aggregation: "count",
          aggregate: "id",
        }),
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue: organisationUuid,
          aggregation: "sum",
          aggregate: "cachedVcuQuantity",
          groupBy: null,
        }),
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue: organisationUuid,
          aggregation: "sum",
          aggregate: "cachedPiuQuantity",
          groupBy: null,
        }),
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue: organisationUuid,
          groupBy: "addressCountryCode",
          aggregation: "count",
          aggregate: "id",
        }),
      ]);

      if (totalProjectsRes.status === Status.Success && totalProjectsRes.data) {
        setProjectsChartData(
          totalProjectsRes.data.concat(totalProjectsRes.data).reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key.replace(" Carbon ", " ")] = current.value;

            return previous;
          }, {})
        );
      }

      if (
        projectVcuRes.status === Status.Success &&
        projectPiuRes.status === Status.Success &&
        projectVcuRes.data &&
        projectPiuRes.data
      ) {
        setUnitsChartData({
          PIUs: projectPiuRes.data[0].value,
          VCUs: projectVcuRes.data[0].value,
        });
      }

      if (projectLocationsRes.status === Status.Success && projectLocationsRes.data) {
        setLocationsChartData(
          projectLocationsRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[getCountryNameByCode(current.key) || ""] = current.value;

            return previous;
          }, {})
        );
      }
    }
  }, []);

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, [fetchData]);

  return {
    projectsChartData,
    unitsChartData,
    locationsChartData,
    isLoading,
  };
};
