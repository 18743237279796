import { useNavigate } from "react-router-dom";

import { VerifierSingleCardTemplate } from "../../../../../templates";
import { getUserSettingsRoute } from "../../../../../utils/routes";
import { Column, ReadonlyText, Row } from "../../../../../widget";
import { EditForm } from "../../../../shared";
import { useEdit } from "./useEdit";

export const Edit = (): JSX.Element => {
  const navigate = useNavigate();
  const { pageTitle, dataIsLoading, userDetails, userUuid, roles, rolesInfo, currentUserType } = useEdit();

  return (
    <div>
      {!dataIsLoading && (
        <VerifierSingleCardTemplate
          activeElement="Settings"
          pageTitle={pageTitle}
          handleBackClick={() => navigate(getUserSettingsRoute(currentUserType))}
          backButtonText="Back to users"
        >
          {userDetails && (
            <>
              <Row spacingV="ll">
                <Column span={2}>
                  <ReadonlyText label="First name" value={userDetails.user.firstName} />
                </Column>
              </Row>
              <Row spacingV="ll">
                <Column span={2}>
                  <ReadonlyText label="Last name" value={userDetails.user.lastName} />
                </Column>
              </Row>
              <Row spacingV="ll">
                <Column span={2}>
                  <ReadonlyText label="Email address" value={userDetails.user.email} />
                </Column>
              </Row>
              <EditForm
                userUuid={userUuid}
                defaultRoleValue={userDetails.role}
                defaultActiveValue={userDetails.active}
                roles={roles}
                rolesInfo={rolesInfo}
                rowVersion={userDetails.rowVersion}
                userSettingsRoute={getUserSettingsRoute(currentUserType)}
              />
            </>
          )}
        </VerifierSingleCardTemplate>
      )}
    </div>
  );
};
