export class MapConstants {
  public static readonly API_KEY = "AIzaSyC-qc4JdoulT9FmXc9AiKCPWydsBv--uhc";

  public static readonly LANGUAGE = "en-GB";

  public static readonly REGION = "GB";

  public static readonly MIN_ZOOM_LEVEL = 3;

  public static readonly MAX_ZOOM_LEVEL = 19;

  public static readonly DEFAULT_ZOOM_LEVEL = 10;

  public static readonly DEFAULT_ZOOM_LEVEL_FULL_WIDTH = 17;

  public static readonly ZOOM_CONTROL_POSITION = 7; // Top-right
}
