import { FetchResult, fromPromise, NextLink, Observable, Operation } from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";

import { memoizedRefreshAccessToken, RefreshTokenResponse } from "../../../service/identity";
import { BaseResponseWithSingle, Status } from "../../../service/Shared";
import { getLogonRoute } from "../../routes";
// import { getLogonRoute } from "../../routes";

let tokenRefreshPromise: Promise<BaseResponseWithSingle<RefreshTokenResponse>> = Promise.resolve<
  BaseResponseWithSingle<RefreshTokenResponse>
>({
  status: Status.Success,
});

// for HTTP 401 error codes, try refreshing the access token,
// then resubmitting the original operation with the new access
// token
export const errorHandler401 = (
  _: NetworkError,
  operation: Operation,
  forward: NextLink
): Observable<FetchResult> | undefined => {
  tokenRefreshPromise = memoizedRefreshAccessToken();

  const oldHeaders = operation.getContext().headers;
  return fromPromise(
    tokenRefreshPromise.then((res) => {
      // Redirect to login if not able to refresh token (e.g refresh token expired)
      if (res.status === Status.Error) {
        window.location.href = getLogonRoute();
      }
    })
  ).flatMap(() => {
    // modify the operation context with a new token
    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: `Bearer ${window.ACCESS_TOKEN}`,
      },
    });
    return forward(operation);
  });
};
