import "./BadgesSection.css";

import { BadgeData, FileType } from "../../../../../../../models";
import { Badge, Column, Divider, Row } from "../../../../../../../widget";

interface BadgesSectionProps {
  badges: BadgeData[];
}

export const BadgesSection = ({ badges }: BadgesSectionProps): JSX.Element => {
  return (
    <div className="BadgesSection">
      <Row spacingV="2xl" className="BadgesSectionDividerRow">
        <Column span={8}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <div className="BadgesHeader">
            <h2>Badges</h2>
          </div>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          {badges.map((b) => (
            <Badge
              key={b.uuid}
              label={b.displayName}
              imageSrc={b.files.find((f) => f.type === FileType.SmallLogo)?.file.url}
            />
          ))}
        </Column>
      </Row>
    </div>
  );
};
