import { useMemo } from "react";

import { CodeCharacteristics, CoreCarbonPrinciple } from "../../../../models";

interface useCodeStatusKPIReturnData {
  codeCharacteristicsArray?: {
    characteristicType: string;
    characteristicValue: CoreCarbonPrinciple | undefined;
  }[];
  codeCharacteristicsCount: number;
}

export const useCodeStatusKPI = (codeCharacteristics: CodeCharacteristics): useCodeStatusKPIReturnData => {
  const codeCharacteristicsArray = useMemo(
    () =>
      [
        {
          characteristicType: "Governance",
          characteristicValue: codeCharacteristics.programGovernance,
        },
        {
          characteristicType: "No double-counting",
          characteristicValue: codeCharacteristics.noDoubleCounting,
        },
        {
          characteristicType: "Registry",
          characteristicValue: codeCharacteristics.registry,
        },
        {
          characteristicType: "Mitigation activity information",
          characteristicValue: codeCharacteristics.mitigationActivityInformation,
        },
        {
          characteristicType: "Additionality",
          characteristicValue: codeCharacteristics.additionality,
        },
        {
          characteristicType: "Robust quantification",
          characteristicValue: codeCharacteristics.robustQuantificationOfEmissionsReductionsAndRemovals,
        },
        {
          characteristicType: "Permanence",
          characteristicValue: codeCharacteristics.permanence,
        },
        {
          characteristicType: "Robust Independent 3rd-party validation and verification",
          characteristicValue: codeCharacteristics.robustIndependentThirdPartyValidationAndVerification,
        },
        {
          characteristicType: "Transition to net zero",
          characteristicValue: codeCharacteristics.transitionToNetZero,
        },
        {
          characteristicType: "Sustainable Development Impacts and Safeguards",
          characteristicValue: codeCharacteristics.sustainableDevelopmentImpactsAndSafeguards,
        },
      ].sort(
        (a, b) =>
          (b.characteristicValue?.met ? Number(b.characteristicValue?.met) : 0) -
          (a.characteristicValue?.met ? Number(a.characteristicValue?.met) : 0)
      ),
    [codeCharacteristics]
  );

  const codeCharacteristicsCount = codeCharacteristicsArray.reduce(
    (a, b) => a + (b.characteristicValue?.met ? Number(b.characteristicValue?.met) : 0),
    0
  );

  return {
    codeCharacteristicsArray,
    codeCharacteristicsCount,
  };
};
