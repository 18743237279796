import "./Logo.css";

interface LogoProps {
  displayedLogo: string | null;
  displayedLabel?: string | undefined;
}

export const Logo = ({ displayedLogo, displayedLabel }: LogoProps): JSX.Element => {
  return (
    <div className="LogoContainerLabel">
      {displayedLogo && (
        <div>
          <label htmlFor={displayedLabel}>{displayedLabel}</label>
          <div className="LogoContainer">
            <img aria-labelledby="logo" src={displayedLogo} alt="LogoImage" />
          </div>
        </div>
      )}
    </div>
  );
};
