import "./RegisterInterestModalForm.css";

import { Button, Column, ErrorBox, Row, TextArea, TextInput } from "../../../../../widget";
import { useRegisterInterestModalForm } from "./useRegisterInterestModalForm";

interface RegisterInterestModalFormProps {
  developerName: string;
  closeModal: () => void;
}

export const RegisterInterestModalForm = ({
  developerName,
  closeModal,
}: RegisterInterestModalFormProps): JSX.Element => {
  const {
    firstName,
    lastName,
    email,
    message,
    // eslint-disable-next-line unused-imports/no-unused-vars
    password,
    errors,
    // eslint-disable-next-line unused-imports/no-unused-vars
    displaySecondStepSuccessBox,
    // eslint-disable-next-line unused-imports/no-unused-vars
    firstStepComplete,
    // eslint-disable-next-line unused-imports/no-unused-vars
    secondStepComplete,
    isHandleFirstStepSubmitLoading,
    setFirstName,
    setLastName,
    setEmail,
    setMessage,
    // eslint-disable-next-line unused-imports/no-unused-vars
    setPassword,
    handleFirstStepSubmit,
    // eslint-disable-next-line unused-imports/no-unused-vars
    handleSecondStepSubmit,
  } = useRegisterInterestModalForm({ closeModal });

  // eslint-disable-next-line no-lone-blocks
  {
    /*
  Removed as part of KAN-3909 https://kanaearth.atlassian.net/browse/KAN-3909 
  Because we hid the Register page in order to remove the possibility of creating new accounts, we need to also remove this modal
  if (secondStepComplete) {
    return (
      <div id="registerInterestThirdStep">
        <Row spacingV="ll" justify="center">
          <Column span={6}>
            <InformationBox variant="success" title="Account created" />
          </Column>
        </Row>
        <div className="RegisterInterestThirdStepSubheader">
          <Row justify="center">
            <Column span={8}>
              <p className="body1">Thank you for registering.</p>
              <p className="body1">
                Kana is currently in closed beta; you have been placed on the waitlist to get early access to new
                features as they become available.
              </p>
            </Column>
          </Row>
        </div>
        <Row spacingV="2xl" justify="center">
          <Column span={6}>
            <Button fullWidth text="Close" onClick={closeModal} />
          </Column>
        </Row>
      </div>
    );
  }

  if (firstStepComplete) {
    return (
      <div id="registerInterestSecondStep">
        {displaySecondStepSuccessBox && (
          <Row spacingV="ll" justify="center">
            <Column span={6}>
              <InformationBox variant="success" title="Message sent">
                Thanks for getting in touch. {developerName} will get back to you as soon as possible
              </InformationBox>
            </Column>
          </Row>
        )}
        <div className="RegisterInterestSecondStepSubheader">
          <h3>Would you like to create a Kana account?</h3>
          <span className="body1">Get early access to new features as they become available</span>
        </div>
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox title="Please amend your password" text={errors.map((error) => error.message)} />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSecondStepSubmit(e)}>
          <Row spacingV="2xl" justify="center">
            <Column span={6}>
              <Password
                label="Create password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </Column>
          </Row>
          <Row spacingV="2xl" justify="center">
            <Column span={6}>
              <Button fullWidth text="Create account" type="submit" />
            </Column>
          </Row>
        </form>
      </div>
    );
  }
*/
  }

  return (
    <>
      <div className="RegisterInterestFirstStepSubheader">
        <Row justify="center">
          <Column span={10}>
            <p className="body1">
              If you&apos;re interested in buying units, investing or if you have any other questions regarding this
              project please fill out the form beneath and {developerName} will get back to you as soon as possible.
            </p>
          </Column>
        </Row>
      </div>
      {errors && errors.length > 0 && (
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <ErrorBox title="Fields are required" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleFirstStepSubmit(e)}>
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <TextInput label="First name" name="firstName" value={firstName} onChange={(e) => setFirstName(e)} />
          </Column>
        </Row>
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <TextInput label="Last name" name="lastName" value={lastName} onChange={(e) => setLastName(e)} />
          </Column>
        </Row>
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <TextInput label="Email address" name="email" type="email" value={email} onChange={(e) => setEmail(e)} />
          </Column>
        </Row>
        <Row spacingV="2xl" justify="center">
          <Column span={6}>
            <TextArea label="Message" name="message" value={message} onChange={(v) => setMessage(v)} />
          </Column>
        </Row>
        <Row spacingV="2xl" justify="center">
          <Column span={6}>
            <Button fullWidth text="Send message" type="submit" isLoading={isHandleFirstStepSubmitLoading} />
          </Column>
        </Row>
      </form>
    </>
  );
};
