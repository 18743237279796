import { Navigate, Route, Routes } from "react-router-dom";

import {
  AcceptInvitationPage,
  ForgottenPasswordCompletePage,
  ForgottenPasswordPage,
  LogonPage,
  RegisterPage,
} from "../route/authentication";
import { PublicError404 } from "../route/public/errors";

export const AuthenticationRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="logon" />} />
      <Route path="logon" element={<LogonPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="accept-invitation/:token" element={<AcceptInvitationPage />} />
      <Route path="accept-invitation" element={<AcceptInvitationPage />} />
      <Route path="forgotten-password" element={<ForgottenPasswordPage />} />
      <Route path="forgotten-password-complete/:token" element={<ForgottenPasswordCompletePage />} />
      <Route path="forgotten-password-complete" element={<ForgottenPasswordCompletePage />} />
      <Route path="/*" element={<PublicError404 />} />
    </Routes>
  );
};
