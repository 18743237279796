import { Button, Column, Modal, Row } from "../../index";

interface DeleteDiscussionModalProps {
  isOnConfirmLoading?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const DeleteDiscussionModal = ({
  isOnConfirmLoading,
  onConfirm,
  onCancel,
}: DeleteDiscussionModalProps): JSX.Element => {
  return (
    <Modal show title="Delete Discussion" onClose={onCancel} hasFullWidthTitle>
      <Row spacingV="xl" justify="center">
        <Column span={12}>
          <p>Are you sure you want to delete this discussion?</p>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <div className="DeleteActivityModalButtons">
            <Button text="Delete" onClick={onConfirm} isLoading={isOnConfirmLoading} />
            <Button variant="secondary" text="Cancel" onClick={onCancel} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
