import "./CoreCarbonPrinciples.css";

import {
  AdditionCircleGreenIcon,
  BriefcaseGreenIcon,
  CalculatorGreenIcon,
  CopyGreenIcon,
  FileBlueIcon,
  InvoiceGreenIcon,
  LockGreenIcon,
  ProcessGreenIcon,
  RightArrowOrangeIcon,
  SearchGreenIcon,
  TickShieldGreenIcon,
  UsersAltGreenIcon,
} from "../../../assets";
import { CoreCarbonPrinciplesData } from "../../../models";
import { KanaTooltip } from "../../forms";
import { Column, Row } from "../../layout";
import { ImageButton } from "../../layout/ExpandableCard/components";

export const CoreCarbonPrinciples = ({ characteristics, codeUrl }: CoreCarbonPrinciplesData): JSX.Element => {
  return (
    <div className="CoreCarbonPrinciples">
      <div className="CoreCarbonPrinciplesHeader">
        <div>
          <h2>Core carbon principles</h2>
          <KanaTooltip
            tooltipHeader="What is the basis of the core carbon principles?"
            tooltipText="The core carbon principles (CCPs) below align with the Integrity Council for Voluntary Carbon Markets (ICVCM) principles for identifying high-quality carbon units. The CCPs form the basis of the Integrity Council's Assessment Framework, which elaborates criteria for evaluating whether carbon credits and carbon-crediting programs reach a high-quality threshold. Once established, Kana intends to leverage ICVCM's assessment of each Code and publish their findings.
Until this assessment framework has been implemented, Kana has assimilated 18 high-level comparison points to represent the Code's published positions against the 10 CCPs."
          />
        </div>
        {/* Temporarily disabling Code Comparison link by wrapping in false condition ENG-671  */}
        {false && (
          <ImageButton icon={<RightArrowOrangeIcon />} route="/LinkToCodeComparison" linkText="View code comparison" />
        )}
      </div>
      <div className="CoreCarbonPrinciplesContent">
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span className={!characteristics.programGovernance?.met ? "PrincipleNotMet" : ""}>
                <BriefcaseGreenIcon />
              </span>
              <h3>Governance</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The carbon-crediting program shall have effective program governance to ensure transparency,
                  accountability and the overall quality of carbon credits, environmental integrity, social safeguards
                  and positive net sustainable development impacts.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.programGovernance?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.programGovernance?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">Find out more about the code&apos;s governance here:</p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a href={characteristics.programGovernance?.codeUrl} target="_blank" rel="noopener noreferrer">
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span className={!characteristics.noDoubleCounting?.met ? "PrincipleNotMetAlt" : ""}>
                <CopyGreenIcon />
              </span>
              <h3>No double-counting</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The GHG emission reductions or removals from the mitigation activity shall not be double-counted,
                  i.e., they shall only be counted once towards achieving mitigation targets or goals. Double counting
                  covers double issuance, double claiming and double use.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.noDoubleCounting?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.noDoubleCounting?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">Find out more about the code&apos;s avoidance of double-counting here:</p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a href={characteristics.noDoubleCounting?.codeUrl} target="_blank" rel="noopener noreferrer">
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span className={!characteristics.registry?.met ? "PrincipleNotMet" : ""}>
                <InvoiceGreenIcon />
              </span>
              <h3>Registry</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The carbon-crediting program shall operate or make use of a registry to uniquely identify, record and
                  track mitigation activities and carbon credits issued to ensure credits can be identified securely and
                  unambiguously.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.registry?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.registry?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">Find out more about the code&apos;s registry here:</p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a href={characteristics.registry?.codeUrl} target="_blank" rel="noopener noreferrer">
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span className={!characteristics.mitigationActivityInformation?.met ? "PrincipleNotMet" : ""}>
                <SearchGreenIcon />
              </span>
              <h3>Mitigation activity information</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The carbon-crediting program shall provide comprehensive and transparent information on all credited
                  mitigation activities. The information shall be publicly available in electronic format, and scrutiny
                  of mitigation activities shall be accessible to non-specialised audiences.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.mitigationActivityInformation?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.mitigationActivityInformation?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">
                      Find out more about the code&apos;s requirements for mitigation activity information here:
                    </p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a
                        href={characteristics.mitigationActivityInformation?.codeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span className={!characteristics.additionality?.met ? "PrincipleNotMet" : ""}>
                <AdditionCircleGreenIcon />
              </span>
              <h3>Additionality</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The greenhouse gas (GHG) emission reductions or removals from the mitigation activity shall be
                  additional, i.e., they would not have occurred in the absence of the incentive created by carbon
                  credit revenues.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.additionality?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.additionality?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">Find out more about the code&apos;s requirements for additionality here:</p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a href={characteristics.additionality?.codeUrl} target="_blank" rel="noopener noreferrer">
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span
                className={
                  !characteristics.robustQuantificationOfEmissionsReductionsAndRemovals?.met ? "PrincipleNotMet" : ""
                }
              >
                <CalculatorGreenIcon />
              </span>
              <h3>Robust quantification</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The GHG emission reductions or removals from the mitigation activity shall be robustly quantified,
                  based on conservative approaches, completeness and sound scientific methods.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.robustQuantificationOfEmissionsReductionsAndRemovals?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.robustQuantificationOfEmissionsReductionsAndRemovals?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">Find out more about the code&apos;s requirements for quantification here</p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a
                        href={characteristics.robustQuantificationOfEmissionsReductionsAndRemovals?.codeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span className={!characteristics.permanence?.met ? "PrincipleNotMet" : ""}>
                <LockGreenIcon />
              </span>
              <h3>Permanence</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The GHG emission reductions or removals from the mitigation activity shall be permanent, or if they
                  have a risk of reversal, any reversals shall be fully compensated.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.permanence?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.permanence?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">Find out more about the code&apos;s requirements for permanence here:</p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a href={characteristics.permanence?.codeUrl} target="_blank" rel="noopener noreferrer">
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span
                className={
                  !characteristics.robustIndependentThirdPartyValidationAndVerification?.met ? "PrincipleNotMet" : ""
                }
              >
                <TickShieldGreenIcon />
              </span>
              <h3>Robust Independent 3rd-party validation and verification</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The carbon-crediting program shall have program-level requirements for robust independent third-party
                  validation and verification of mitigation activities.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.robustIndependentThirdPartyValidationAndVerification?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.robustIndependentThirdPartyValidationAndVerification?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">
                      Find out more about the code&apos;s requirements for verification and monitoring here:
                    </p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a
                        href={characteristics.robustIndependentThirdPartyValidationAndVerification?.codeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span className={!characteristics.transitionToNetZero?.met ? "PrincipleNotMet" : ""}>
                <ProcessGreenIcon />
              </span>
              <h3>Transition to net zero</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The mitigation activity shall avoid locking in levels of emissions, technologies or carbon-intensive
                  practices that are incompatible with achieving net zero emissions by mid-century.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.transitionToNetZero?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.transitionToNetZero?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">
                      Find out more about the code&apos;s requirements for supporting the transition to net zero here:
                    </p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a href={characteristics.transitionToNetZero?.codeUrl} target="_blank" rel="noopener noreferrer">
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <div className="CoreCarbonPrincipleTitle">
              <span
                className={!characteristics.sustainableDevelopmentImpactsAndSafeguards?.met ? "PrincipleNotMet" : ""}
              >
                <UsersAltGreenIcon />
              </span>
              <h3>Sustainable Development Impacts and Safeguards</h3>
            </div>
          </Column>
          <Column span={9}>
            <Row spacingV="ml">
              <Column span={12}>
                <p className="body1">
                  The carbon-crediting program shall have clear guidance, tools and compliance procedures to ensure
                  mitigation activities conform with or go beyond widely established industry best practices on social
                  and environmental safeguards while delivering on net positive sustainable development impacts.
                </p>
              </Column>
            </Row>
            <Row spacingV="m">
              <Column span={12}>
                <div className="CoreCarbonPositionsTable">
                  {characteristics.sustainableDevelopmentImpactsAndSafeguards?.codePositions?.map((el) => (
                    <div key={el.title} className="CoreCarbonPosition">
                      <div className="CoreCarbonPositionTitle">{el.title}</div>
                      <div className={`CoreCarbonPositionDetail ${!el.detail ? "CoreCarbonPositionNoDetail" : ""}`}>
                        {el.detail || "The code does not currently have a defined position against this characteristic"}
                      </div>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
            {characteristics.sustainableDevelopmentImpactsAndSafeguards?.codeUrl && (
              <>
                <Row>
                  <Column span={12}>
                    <p className="body1">
                      Find out more about the code&apos;s requirements for sustainable development here:
                    </p>
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    <p className="body1 CoreCarbonPrinciplesLink">
                      <a
                        href={characteristics.sustainableDevelopmentImpactsAndSafeguards?.codeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>
        {codeUrl && (
          <p className="body1 CoreCarbonPrinciplesLink">
            <FileBlueIcon />
            <a href={codeUrl} target="_blank" rel="noopener noreferrer">
              View the full code
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
