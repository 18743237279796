import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useLocation, useNavigate } from "react-router-dom";

import { ArrowheadRightDarkIcon } from "../../../../assets";
import { ProjectActivitiesConstants, ProjectPermissionConstants } from "../../../../constants";
import { CursorChangeProps, Origin, ResultType } from "../../../../models";
import { IStateOrigin } from "../../../../navigators/navigateStateObjects";
import {
  getCurrentUserProjectPermissions,
  GetCurrentUserProjectPermissionsResponse,
  searchActivities,
  SearchActivitiesResponse,
  SearchActivityHistoryResponse,
} from "../../../../service/query";
import { ResultData } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { flattenObject, hasActivityPermissionForProject } from "../../../../utils";
import {
  getActivityDashboardTabRoute,
  getActivityViewRoute,
  getProjectDetailsByUuid,
  getProjectManageProjectGroupRoute,
} from "../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
  Toast,
} from "../../../../widget";
import { IconCellFormatterData } from "../../../../widget/data/DataGrid/models";
import { resumeActivity } from "../../../shared/utils";

interface UseActivitiesTabReturnData {
  columns: DataGridColumnDefinition[];
  isLoading: boolean;
  defaultSortingCriteria: SortColumn[];
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  showVersionConflictModal: boolean;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  subTitle: string;
}
export const useActivitiesTab = (): UseActivitiesTabReturnData => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();

  const tableColumnsInProgressActivities: DataGridColumnDefinition[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
    },
    { key: "standardDisplayName", name: "Code", dataType: "string" },
    {
      key: "activityLink",
      name: "Activity",
      dataType: "string",
      formatter: "buttonLink",
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      minWidth: 240,
    },
    {
      name: "Progress",
      key: "latestVersion.completionPercentage",
      dataType: "number",
      formatter: "progress",
    },
    {
      name: "Last updated",
      key: "latestVersion.updatedAt",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
    {
      key: "activityDetailLink",
      name: "More details",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
  ];

  const tableColumnsInReviewActivities: DataGridColumnDefinition[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
    },
    { key: "standardDisplayName", name: "Code", dataType: "string" },
    {
      key: "activityLink",
      name: "Activity",
      dataType: "string",
      formatter: "buttonLink",
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      minWidth: 240,
    },
    {
      name: "Verifier",
      key: "project.verifier.displayName",
      dataType: "string",
    },
    {
      name: "Submission date",
      key: "firstSubmissionDate",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
    {
      name: "Last updated",
      key: "reviewVersion.updatedAt",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
    {
      key: "activityDetailLink",
      name: "More details",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
  ];
  const pageSize = 10;

  const { columns, statusFilter, subTitle } = ((): {
    columns: DataGridColumnDefinition[];
    statusFilter: unknown;
    subTitle: string;
  } => {
    let statusFilterCriteria = null;
    let columnsPerCategory: DataGridColumnDefinition[] = [];
    let smallTitle = "Activities";
    switch (location.pathname.split("/").pop()) {
      case "in_progress":
        statusFilterCriteria = {
          operator: "in",
          value: ProjectActivitiesConstants.INPROGESS_STATUSES,
        };
        columnsPerCategory = tableColumnsInProgressActivities;
        smallTitle = "Activities in progress";
        break;
      case "in_review":
        statusFilterCriteria = {
          operator: "in",
          value: ProjectActivitiesConstants.INREVIEW_STATUSES,
        };
        columnsPerCategory = tableColumnsInReviewActivities;
        smallTitle = "Activities in review";
        break;
      default: {
        statusFilterCriteria = {
          operator: "in",
          value: ProjectActivitiesConstants.COMPLETED_STATUSES,
        };
        columnsPerCategory = tableColumnsInProgressActivities;
        smallTitle = "Activities completed";
        break;
      }
    }
    return {
      columns: columnsPerCategory,
      statusFilter: statusFilterCriteria,
      subTitle: smallTitle,
    };
  })();

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "latestVersion.updatedAt", direction: "DESC" }];

  const formatData = useCallback(
    (
      responseData: SearchActivitiesResponse | undefined,
      permissions: GetCurrentUserProjectPermissionsResponse[]
    ): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.projectLink = {
          text: el.project ? el.project.displayName : el.group?.displayName,
          to: el.project
            ? getProjectDetailsByUuid(el.project.uuid, currentUserType)
            : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              getProjectManageProjectGroupRoute(el.group!.uuid!, `origin=${Origin.DeveloperActivitiesPage}`),
          state: { origin: { goBackText: "Back to activities", from: location.pathname } as IStateOrigin },
        } as DataGridLinkCellFormatterData;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, prettier/prettier
        result.standardDisplayName = el.project ? el.project.standard.displayName : el.group!.standard!.displayName!;

        if (
          (el.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ||
            el.status === ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED ||
            el.status === ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED) &&
          hasActivityPermissionForProject(
            permissions,
            el.project?.uuid,
            ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY,
            el.group?.uuid
          )
        ) {
          result.activityLink = {
            text: el.activityDefinition.displayName,
            action: () => {
              if (el.isUnderReview && el.draftVersion) {
                navigate(
                  getActivityViewRoute(
                    el.draftVersion?.uuid,
                    currentUserType,
                    el.project ? `projectUuid=${el.project.uuid}` : ""
                  )
                );
              } else if (el.isUnderReview && el.currentVersion) {
                navigate(
                  getActivityViewRoute(
                    el.currentVersion?.uuid,
                    currentUserType,
                    el.project ? `projectUuid=${el.project.uuid}` : ""
                  )
                );
              } else {
                resumeActivity(
                  navigate,
                  setDraftActivityHistoryUuid,
                  setNewVersionActivityHistory,
                  setShowVersionConflictModal,
                  el.uuid,
                  el.draftVersion?.uuid,
                  el.draftVersion?.versionNumber
                );
              }
            },
          } as DataGridButtonLinkCellFormatterData;
        } else {
          result.activityLink = {
            text: el.activityDefinition.displayName,
            action: () => {
              if (el.currentVersion) {
                navigate(
                  getActivityViewRoute(
                    el.currentVersion.uuid,
                    currentUserType,
                    el.project ? `projectUuid=${el.project.uuid}` : ""
                  ),
                  { state: { origin: { goBackText: "Back to activities", from: location.pathname } as IStateOrigin } }
                );
              } else if (el.reviewVersion) {
                navigate(
                  getActivityViewRoute(
                    el.reviewVersion.uuid,
                    currentUserType,
                    el.project ? `projectUuid=${el.project.uuid}` : ""
                  ),
                  { state: { origin: { goBackText: "Back to activities", from: location.pathname } as IStateOrigin } }
                );
              } else {
                Toast.error({
                  message: `This activity has not yet been published. Once ${el.createdByUser.fullName} has published the activity, you can view the activity.`,
                });
              }
            },
          } as DataGridButtonLinkCellFormatterData;
        }

        result.activityHistoryLink = {
          action: () =>
            navigate(
              getActivityDashboardTabRoute(el.uuid, "documents", currentUserType, `projectUuid=${el.project?.uuid}`)
            ),
          icon: <ArrowheadRightDarkIcon width={12} height={12} />,
        } as IconCellFormatterData;

        result.activityDetailLink = {
          action: () =>
            navigate(
              getActivityDashboardTabRoute(el.uuid, "documents", currentUserType, `projectUuid=${el.project?.uuid}`),
              { state: { origin: { goBackText: "Back to activities", from: location.pathname } as IStateOrigin } }
            ),
          icon: <ArrowheadRightDarkIcon width={12} height={12} />,
        } as IconCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);
    if (filterCriteria.projectLink) {
      filterCriteria.project = {
        displayName: filterCriteria.projectLink,
      };
      filterCriteria.projectLink = undefined;
    }
    if (filterCriteria.activityLink) {
      filterCriteria.activityDefinition = {
        displayName: filterCriteria.activityLink,
      };
      filterCriteria.activityLink = undefined;
    }

    if (statusFilter) {
      filterCriteria.status = statusFilter;
    }

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const sortKeyMap: Record<string, string> = {
      projectLink: "project.displayName",
      activityLink: "activityDefinition.displayName",
    };

    await searchActivities({
      paging: {
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
        limit: pageSize,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: sortKeyMap[s.key] || s.key,
        direction: s.direction,
      })),
      /* eslint-enable */
      filter: { results: filterCriteria },
    })
      .then(async (response) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, prettier/prettier
        const projectUuids = response.data?.results?.filter((x) => x.project).map((el) => el.project!.uuid) ?? null;
        const groupUuids =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          response.data?.results?.filter((x) => x.group?.uuid != null).map((el) => el.group!.uuid!) ?? null;
        await getCurrentUserProjectPermissions({ projectUuids, groupUuids }).then((permissions) => {
          data = {
            resultData: formatData(response.data, permissions.data || []),
            paging: {
              startCursor: response.data?.paging?.startCursor || "",
              endCursor: response.data?.paging?.endCursor || "",
              pageSize,
              totalCount: response.data?.paging?.total || 0,
              hasNextPage: response.data?.paging?.hasNextPage || false,
              hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
            },
          };
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

    return data;
  };

  return {
    columns,
    isLoading,
    defaultSortingCriteria,
    setShowVersionConflictModal,
    showVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    onChange,
    subTitle,
  };
};
