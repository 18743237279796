import { Status } from "../../service/Shared";
import { Toast } from "../../widget";
import { downloadDocument } from "../common";

const contentDispositionFilenameRegex = /filename\s*=\s*"?(?<fn>.[^"]+)"?;/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initiateDocumentDownloadViaBrowser = (response: any, isPreview = false): void => {
  if (response?.status !== Status.Success) {
    Toast.error({
      message: `Couldn't download file - please try again later.`,
    });
    return;
  }

  const filename = response.data.headers["content-disposition"]?.match(contentDispositionFilenameRegex).groups?.fn;
  const mimeType = response.data.request.getResponseHeader("Content-Type");
  const url = window.URL.createObjectURL(new Blob([response.data.data], { type: mimeType }));

  if (!filename)
    throw new Error(
      `Expected a 'filename' to be returned in the 'content-disposition' header, but got none (value: '${filename}')`
    );

  try {
    downloadDocument(filename, mimeType, url, isPreview);
  } finally {
    URL.revokeObjectURL(url);
  }
};
