import "./TypeRatingsSection.css";

import { CarbonImpactBlueIcon, NationalPotentialBlueIcon, SpeedOfImpactBlueIcon } from "../../../../assets";
import { ProjectTypeRatings } from "../../../../route/public/project-type/useProjectType";

interface TypeRatingsSectionProps {
  typeRatings: ProjectTypeRatings;
}

export const TypeRatingsSection = ({ typeRatings }: TypeRatingsSectionProps): JSX.Element => {
  return (
    <div className="TypeRatingsSection">
      <div className="TypeRating">
        <CarbonImpactBlueIcon />
        <div>
          <h3>Carbon impact</h3>
          <span className="body1">{typeRatings.carbonImpact}</span>
        </div>
      </div>
      <div className="TypeRating">
        <SpeedOfImpactBlueIcon />
        <div>
          <h3>Speed of impact</h3>
          <span className="body1">{typeRatings.speedOfImpact}</span>
        </div>
      </div>
      <div className="TypeRating">
        <NationalPotentialBlueIcon />
        <div>
          <h3>National potential</h3>
          <span className="body1">{typeRatings.nationalPotential}</span>
        </div>
      </div>
    </div>
  );
};
