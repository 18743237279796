import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  AboutUsData,
  AtAGlanceData,
  FileType,
  GetInTouchData,
  HeroData,
  OurValuesData,
  PublicDeveloperDetailsParams,
  RichContentSectionData,
  SocialMediaData,
} from "../../../../../models";
import { getDeveloperDetails } from "../../../../../service/publicquery";
import { Status } from "../../../../../service/Shared";

interface UseDetailsReturnData {
  dataIsLoading: boolean;
  developerDetailsData: DeveloperDetailsData | undefined;
}

export interface DeveloperDetailsData {
  displayName: string | null;
  logo: string | null;
  websiteUrl: string | null;
  contactPhone?: string | null;
  calculatedContactable: boolean;
  socialMediaData: SocialMediaData;
  hero: HeroData;
  ourValues: OurValuesData;
  ourApproach: RichContentSectionData;
  atAGlance: AtAGlanceData;
  aboutUs: AboutUsData;
  getInTouch: GetInTouchData;
  ourServices: RichContentSectionData;
}

export const useDetails = (): UseDetailsReturnData => {
  const { developerUuid } = useParams<PublicDeveloperDetailsParams>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [developerDetailsData, setDeveloperDetailsData] = useState<DeveloperDetailsData | undefined>(undefined);

  const fetchData = useCallback(async (): Promise<void> => {
    if (developerUuid) {
      const res = await getDeveloperDetails({
        developerUuid,
      });

      if (res.status === Status.Success && res.data) {
        const logo = res.data.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null;

        const heroValues: HeroData = {
          enabled: res.data.listing?.content?.hero ? res.data.listing?.content?.hero?.enabled : false,
          title: res.data.listing?.content?.hero?.title || null,
          subtitle: res.data.listing?.content?.hero?.subtitle || null,
          imageUrl:
            res.data.listingFiles.find((file) => file.uuid === res.data?.listing?.content?.hero?.imageFileUuid)?.file
              .url || null,
        };

        const ourValues: OurValuesData = {
          enabled: res.data.listing?.content?.ourValues ? res.data.listing?.content?.ourValues?.enabled : false,
          content: res.data?.listing?.content?.ourValues?.content || null,
        };

        const getInTouchValues: GetInTouchData = {
          enabled: res.data.listing?.content?.getInTouch ? res.data.listing?.content?.getInTouch?.enabled : false,
        };

        const atAGlanceValues: AtAGlanceData = {
          enabled: res.data.listing?.content?.atAGlance ? res.data.listing?.content?.atAGlance?.enabled : false,
        };

        const aboutUsValues: AboutUsData = {
          enabled: res.data.listing?.content?.aboutUs ? res.data.listing?.content?.aboutUs?.enabled : false,
          content: res.data?.listing?.content?.aboutUs?.content,
          imageUrl:
            res.data.listingFiles.find((file) => file.uuid === res.data?.listing?.content?.aboutUs?.imageFileUuid)?.file
              .url || null,
        };

        const socialMediaValues: SocialMediaData = {
          twitterUsername: res.data.socialMediaTwitterUsername,
          linkedinURL: res.data.socialMediaLinkedInUrl,
          instagramUsername: res.data.socialMediaInstagramUsername,
          youtubeChannel: res.data.socialMediaYoutubeChannel,
          facebookURL: res.data.socialMediaFacebookUrl,
        };

        setDeveloperDetailsData({
          displayName: res.data.displayName,
          logo,
          websiteUrl: res.data.websiteUrl,
          contactPhone: res.data.contactPhone,
          calculatedContactable: res.data.calculatedContactable,

          socialMediaData: socialMediaValues,
          hero: heroValues,
          ourValues,
          atAGlance: atAGlanceValues,
          getInTouch: getInTouchValues,
          aboutUs: aboutUsValues,
          ourApproach: {
            enabled: res.data.listing?.content?.ourApproach ? res.data.listing?.content?.ourApproach?.enabled : false,
            part1: res.data.listing?.content?.ourApproach?.part1
              ? {
                  title: res.data.listing?.content?.ourApproach?.part1?.title,
                  content: res.data.listing?.content?.ourApproach?.part1?.content,
                  imageUrl:
                    res.data.listingFiles.find(
                      (file) => file.uuid === res.data?.listing?.content?.ourApproach?.part1?.imageFileUuid
                    )?.file.url || null,
                }
              : null,
            part2: res.data.listing?.content?.ourApproach?.part2
              ? {
                  title: res.data.listing?.content?.ourApproach?.part2?.title,
                  content: res.data.listing?.content?.ourApproach?.part2?.content,
                  imageUrl:
                    res.data.listingFiles.find(
                      (file) => file.uuid === res.data?.listing?.content?.ourApproach?.part2?.imageFileUuid
                    )?.file.url || null,
                }
              : null,
            part3: res.data.listing?.content?.ourApproach?.part3
              ? {
                  title: res.data.listing?.content?.ourApproach?.part3?.title,
                  content: res.data.listing?.content?.ourApproach?.part3?.content,
                  imageUrl:
                    res.data.listingFiles.find(
                      (file) => file.uuid === res.data?.listing?.content?.ourApproach?.part3?.imageFileUuid
                    )?.file.url || null,
                }
              : null,
            part4: res.data.listing?.content?.ourApproach?.part4
              ? {
                  title: res.data.listing?.content?.ourApproach?.part4?.title,
                  content: res.data.listing?.content?.ourApproach?.part4?.content,
                  imageUrl:
                    res.data.listingFiles.find(
                      (file) => file.uuid === res.data?.listing?.content?.ourApproach?.part4?.imageFileUuid
                    )?.file.url || null,
                }
              : null,
          },
          ourServices: {
            enabled: res.data.listing?.content?.ourServices ? res.data.listing?.content?.ourServices?.enabled : false,
            part1: res.data.listing?.content?.ourServices?.part1
              ? {
                  title: res.data.listing?.content?.ourServices?.part1?.title,
                  content: res.data.listing?.content?.ourServices?.part1?.content,
                  imageUrl:
                    res.data.listingFiles.find(
                      (file) => file.uuid === res.data?.listing?.content?.ourServices?.part1?.imageFileUuid
                    )?.file.url || null,
                }
              : null,
            part2: res.data.listing?.content?.ourServices?.part2
              ? {
                  title: res.data.listing?.content?.ourServices?.part2?.title,
                  content: res.data.listing?.content?.ourServices?.part2?.content,
                  imageUrl:
                    res.data.listingFiles.find(
                      (file) => file.uuid === res.data?.listing?.content?.ourServices?.part2?.imageFileUuid
                    )?.file.url || null,
                }
              : null,
            part3: res.data.listing?.content?.ourServices?.part3
              ? {
                  title: res.data.listing?.content?.ourServices?.part3?.title,
                  content: res.data.listing?.content?.ourServices?.part3?.content,
                  imageUrl:
                    res.data.listingFiles.find(
                      (file) => file.uuid === res.data?.listing?.content?.ourServices?.part3?.imageFileUuid
                    )?.file.url || null,
                }
              : null,
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    dataIsLoading,
    developerDetailsData,
  };
};
