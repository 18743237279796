import { useCallback, useEffect, useState } from "react";

import { FileType, SocialMediaData } from "../../../models";
import { getVerifierDetails } from "../../../service/query";
import { Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";

interface UsePublicVerifierDetailsPreviewTemplateReturnData {
  dataIsLoading: boolean;
  verifierUuid: string | undefined | null;
  verifierDetails: VerifierDetails | undefined;
  verifierSocialMedia: SocialMediaData | undefined;
}

export interface VerifierDetails {
  displayName: string | null;
  logo: string | null;
  websiteUrl: string | null;
}

export const usePublicVerifierDetailsPreviewTemplate = (): UsePublicVerifierDetailsPreviewTemplateReturnData => {
  const { currentVerifierUuid } = useAuth();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [verifierDetails, setVerifierDetails] = useState<VerifierDetails | undefined>();
  const [verifierSocialMedia, setVerifierSocialMedia] = useState<SocialMediaData | undefined>();

  const fetchData = useCallback(async (): Promise<void> => {
    if (currentVerifierUuid) {
      const res = await getVerifierDetails({ verifierUuid: currentVerifierUuid });

      if (res.status === Status.Success && res.data) {
        const logo = res.data.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null;

        setVerifierDetails({
          displayName: res.data.displayName,
          logo,
          websiteUrl: res.data.websiteUrl,
        });

        setVerifierSocialMedia({
          twitterUsername: res.data.socialMediaTwitterUsername,
          linkedinURL: res.data.socialMediaLinkedInUrl,
          instagramUsername: res.data.socialMediaInstagramUsername,
          youtubeChannel: res.data.socialMediaYoutubeChannel,
          facebookURL: res.data.socialMediaFacebookUrl,
        });
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    dataIsLoading,
    verifierUuid: currentVerifierUuid,
    verifierDetails,
    verifierSocialMedia,
  };
};
