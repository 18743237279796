import "./ProfileMenuItem.css";

import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

export type ProfileMenuItemType = {
  id: number;
  value: string;
  icon?: ReactNode;
  route?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: (...args: [{ [index: string]: any }?]) => void;
};

interface ProfileMenuItemProps {
  profileMenuItem: ProfileMenuItemType;
  onClick: () => void;
}

export const ProfileMenuItem = ({ profileMenuItem, onClick }: ProfileMenuItemProps): JSX.Element | null => {
  const location = useLocation();
  return (
    <button
      type="button"
      tabIndex={0}
      className={`ProfileMenuItem ${
        profileMenuItem.route && profileMenuItem.route === location.pathname ? "ProfileMenuItemSelected" : ""
      }`}
      onClick={onClick}
    >
      {profileMenuItem.icon}
      <p className="body1">{profileMenuItem.value}</p>
    </button>
  );
};
