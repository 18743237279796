import { PublicDeveloperDetailsPageTemplate, PublicTemplate } from "../../../../../templates";
import { useDetails } from "./useDetails";

const Details = (): JSX.Element => {
  const { dataIsLoading, developerDetailsData } = useDetails();

  return (
    <PublicTemplate activeElement="Project list" isContentUnwrapped>
      {!dataIsLoading && developerDetailsData && (
        <PublicDeveloperDetailsPageTemplate developerDetailsData={developerDetailsData} />
      )}
    </PublicTemplate>
  );
};

export default Details;
