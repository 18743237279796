import { useEffect, useId, useState } from "react";

interface UseToggleReturnData {
  toggleId: string;
  checked: boolean;
  handleToggleClick: () => void;
}

export const useToggle = (value: boolean, onChangeCallback: (value: boolean) => void): UseToggleReturnData => {
  const toggleId = useId();
  const [checked, setChecked] = useState<boolean>(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleToggleClick = (): void => {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    onChangeCallback(newCheckedValue);
  };

  return {
    toggleId,
    checked,
    handleToggleClick,
  };
};
