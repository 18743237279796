import { PillVariant } from "../../widget";

export class ProjectTypeConstants {
  public static readonly PROJECT_TYPE_VARIANT_MAP: { [key: string]: PillVariant } = {
    // Green projects
    "Forestry and Woodland Creation": "green",
    "Hedgerow and Agroforestry": "green",
    Rewilding: "green",

    // Brown projects
    Peatland: "coral",
    "Soil Carbon": "coral",
    "Bio Energy": "coral",

    // Blue projects
    "Seagrass Restoration": "blue",
    "Constructed Wetland": "blue",
    "Saltmarsh Restoration": "blue",

    // Grey projects
    "Enhanced Weathering": "grey",
    Biochar: "grey",
    "Livestock Feed": "grey",
  };
}
