import "./TextLink.css";

import { ReactNode } from "react";

interface TextLinkProps {
  onAction: () => void;
  children: ReactNode;
  unstyled?: boolean;
}

export const TextLink = ({ children, onAction, unstyled = false }: TextLinkProps): JSX.Element => {
  return (
    <span
      tabIndex={0}
      onKeyDown={(e) => (e.key === "Enter" ? onAction() : null)}
      role="button"
      className={unstyled ? "TextLinkUnstyled" : "TextLink"}
      onClick={() => onAction()}
    >
      {children}
    </span>
  );
};
