import "./AboutDeveloperProject.css";

import { useNavigate, useSearchParams } from "react-router-dom";

import { getPlaceholderImage } from "../../../../../utils";
import { getPublicProjectOverviewRoute } from "../../../../../utils/routes";
import { DeveloperProject } from "../../../usePublicProjectTemplate";

interface AboutDeveloperProjectProps {
  project: DeveloperProject;
}

export const AboutDeveloperProject = ({ project }: AboutDeveloperProjectProps): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <button
      className="AboutDeveloperProject"
      onClick={() => {
        navigate(`${getPublicProjectOverviewRoute(project.uuid)}?${searchParams}`);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <img src={project.image ?? getPlaceholderImage(project.codeName)} alt="DeveloperImage" />
      <div className="AboutDeveloperProjectDetails">
        <h4>{project.displayName}</h4>
        {project.description && <p className="body3 AboutDeveloperOtherProjectDescription">{project.description}</p>}
      </div>
    </button>
  );
};
