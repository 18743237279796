/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useParams, useSearchParams } from "react-router-dom";

import { TabsList } from "../../../models";
import { PublicProjectTemplate } from "../../../templates";
import {
  getPublicProjectDetailsRoute,
  getPublicProjectDocumentsRoute,
  getPublicProjectIssuancesRoute,
  getPublicProjectOverviewRoute,
} from "../../../utils/routes";

const Project = (): JSX.Element => {
  const { uuid } = useParams();
  const [searchParams] = useSearchParams();
  const tabsList: TabsList = [
    {
      label: "Overview",
      route: `${getPublicProjectOverviewRoute(uuid!)}?${searchParams}`,
    },
    {
      label: "Details",
      route: `${getPublicProjectDetailsRoute(uuid!)}?${searchParams}`,
    },
    {
      label: "Documents",
      route: `${getPublicProjectDocumentsRoute(uuid!)}?${searchParams}`,
    },
    {
      label: "Issuances",
      route: `${getPublicProjectIssuancesRoute(uuid!)}?${searchParams}`,
    },
  ];
  return <PublicProjectTemplate activeElement="Project list" tabsList={tabsList} />;
};

export default Project;
