import "./NavigationTooltip.css";

import Tooltip from "rc-tooltip";
import { ReactElement } from "react";

interface NavigationTooltipProps {
  text: string;
  children: ReactElement;
}

export const NavigationTooltip = ({ text, children }: NavigationTooltipProps): JSX.Element => {
  return (
    <Tooltip
      placement="bottom"
      overlayClassName="NavigationTooltip"
      overlay={<div className="body4 NavigationTooltipContainer">{text}</div>}
    >
      {children}
    </Tooltip>
  );
};
