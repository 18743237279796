import "./UserHeader.css";

import { ArrowFullLeftDarkIcon, BellFourIcon } from "../../../assets";
import { OrganisationTypeConstants } from "../../../constants";
import { Avatar, Button, Divider } from "../..";
import { ProfileMenu } from "../ProfileMenu";
import { ProfileMenuItem } from "../ProfileMenuItem";
import { useUserHeader } from "./useUserHeader";

interface UserHeaderProps {
  backButtonText?: string;
  handleBackClick?: () => void;
}

export const UserHeader = ({ backButtonText, handleBackClick }: UserHeaderProps): JSX.Element => {
  const { showProfileMenu, setShowProfileMenu, currentUserData, optionList, currentUserType, onOptionSelect } =
    useUserHeader();

  return currentUserData ? (
    <header className="UserHeader">
      {backButtonText ? (
        <Button
          variant="secondary"
          text={backButtonText}
          icon={<ArrowFullLeftDarkIcon />}
          iconPosition="left"
          onClick={handleBackClick}
        />
      ) : (
        <div />
      )}
      {/* TODO: Change this when we implement notifications component */}
      {currentUserType === OrganisationTypeConstants.ASSET_MANAGER && <BellFourIcon className="NotificationIcon" />}
      <div>
        <Avatar
          userUuid={currentUserData?.userUuid}
          imageSource={currentUserData?.avatar?.url}
          name={`${currentUserData?.firstName} ${currentUserData?.lastName}`}
          withName
          onClick={() => setShowProfileMenu(true)}
        />
        <ProfileMenu show={showProfileMenu} onClose={() => setShowProfileMenu(false)}>
          {optionList.map((option, index) => (
            <div key={option.id} className="ProfileMenuItemWrapper">
              <ProfileMenuItem profileMenuItem={option} onClick={() => onOptionSelect(option)} />
              {(index === 1 || index === 2) && <Divider className="ProfileMenuItemDivider" type="horizontal" />}
            </div>
          ))}
        </ProfileMenu>
      </div>
    </header>
  ) : (
    <div />
  );
};
