import "./Badge.css";

import { Link } from "react-router-dom";

interface BadgeProps {
  label?: string;
  imageSrc?: string;
  link?: string;
}

export const Badge = ({ label, imageSrc, link }: BadgeProps): JSX.Element => {
  return link ? (
    <Link className="BadgeWrapper" to={link}>
      <img className="BadgeLogo" src={imageSrc} alt={`${label} logo`} />
      <p className="BadgeLabel">{label}</p>
    </Link>
  ) : (
    <div className="BadgeWrapper">
      <img className="BadgeLogo" src={imageSrc} alt={`${label} logo`} />
      <p className="BadgeLabel">{label}</p>
    </div>
  );
};
