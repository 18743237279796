import "./DropdownButton.css";

import React, { useRef, useState } from "react";

import { useClickOutside } from "../../../utils";
import { DropdownItemProps } from "./DropdownItem";

export type DropdownButtonProps = {
  children: JSX.Element | (JSX.Element | boolean)[];
  component: JSX.Element | string;
  className?: string;
  onClick?: () => void;
};

export const DropdownButton = ({ children, component, className = "", onClick }: DropdownButtonProps): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownWrapperRef = useRef(null);

  // This doesn't trigger for a dropdown item, so that we can handle
  // the click events in the DropdownItem component ourselves
  useClickOutside(dropdownWrapperRef, () => {
    setShowDropdown(false);
  });

  const toggleDropdown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
    if (onClick) onClick();
  };

  const button = (): JSX.Element => (
    <div
      className={`${className} DropdownButton`}
      role="button"
      tabIndex={0}
      onClick={toggleDropdown}
      onKeyDown={() => true}
    >
      {component}
    </div>
  );

  const renderChildren = (): JSX.Element[] => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return child;

      return React.cloneElement(child as React.ReactElement<DropdownItemProps>, {
        parentOnClick: toggleDropdown,
      });
    });
  };

  return (
    <div className="DropdownButtonWrapper" ref={dropdownWrapperRef}>
      {button()}
      {showDropdown && <div className="DropdownWrapper">{renderChildren()}</div>}
    </div>
  );
};
