import "./Issuances.css";

import { BarChart, Column, DataGrid, InformationBox, Row } from "../../../../widget";
import { useIssuances } from "./useIssuances";

const Issuances = (): JSX.Element => {
  const { isLoading, showPrice, dataGridColumns, dataGridData, chartData } = useIssuances();
  return (
    <div className="PublicProjectTabSection">
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Issuances</h2>
        </Column>
      </Row>
      {(!chartData || chartData.length === 0) && !isLoading ? (
        <p className="body2 NoIssuances">No issuance data is available for this project</p>
      ) : (
        <div className="IssuancesContainer">
          {chartData && !!Object.keys(chartData).length && (
            <Row spacingV="ll">
              <Column span={12}>
                <BarChart data={chartData} />
              </Column>
            </Row>
          )}
          {!!dataGridData.length && showPrice && (
            <InformationBox variant="info">
              These prices are subject to confirmation by the developer, please contact them for more information.
            </InformationBox>
          )}
          <Row spacingV="ll">
            <Column span={12}>
              {dataGridColumns && (
                <DataGrid
                  clientSide
                  columns={dataGridColumns}
                  data={dataGridData}
                  filterable={false}
                  sortable={false}
                  pageable={false}
                  noDataMessage="Issuance data is not available yet"
                  dataIsLoading={isLoading}
                />
              )}
            </Column>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Issuances;
