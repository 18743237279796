import "./LoadingSpinner.css";

type LoadingSpinnerTypes = "light" | "dark" | "blue";

interface Props {
  type?: LoadingSpinnerTypes;
  size?: "small" | "medium";
}

const getClassnameByType = (type: LoadingSpinnerTypes): string => {
  switch (type) {
    case "light":
      return "Light";
    case "dark":
      return "Dark";
    case "blue":
      return "Blue";
    default:
      return "";
  }
};

export const LoadingSpinner = ({ type = "light", size = "small" }: Props): JSX.Element => {
  return <span className={`LoadingSpinner ${getClassnameByType(type)} ${size === "small" ? "Small" : "Medium"}`} />;
};
