import { Outlet } from "react-router-dom";

import { PublicProvider } from "./usePublicContext";

const Public = (): JSX.Element => {
  return (
    <PublicProvider>
      <Outlet />
    </PublicProvider>
  );
};

export default Public;
