import "./EditDocumentVersionModal.css";

import { ServiceError } from "../../../../../../../../service/Shared";
import {
  Button,
  Column,
  ErrorBox,
  FormLabel,
  InformationBox,
  KanaTooltip,
  Modal,
  MultiSelect,
  RadioButtonGroup,
  Row,
} from "../../../../../../../../widget";
import { RadioChangeEvent } from "../../../../../../../../widget/forms/Input/RadioButtonGroup/components";
import { useEditDocumentVersionModal } from "./useEditDocumentVersionModal";

interface EditDocumentVersionModalProps {
  show: boolean;
  closeModal: () => void;
  editDocumentUuid?: string;
}

export const EditDocumentVersionModal = ({
  show,
  closeModal,
  editDocumentUuid,
}: EditDocumentVersionModalProps): JSX.Element => {
  const {
    editDocument,
    onCancel,
    visibility,
    setVisibility,
    selectedOrganisations,
    setSelectedOrganisations,
    relatedOrganisations,
    isEditDocumentLoading,
    showPublicWarning,
    showExternalRegistryWarning,
    errors,
  } = useEditDocumentVersionModal({
    show,
    closeModal,
    editDocumentUuid,
  });

  return (
    <div className="EditDocumentModal">
      <Modal show={show} title="Edit document" onClose={onCancel}>
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error: ServiceError) => error.message)}
              />
            </Column>
          </Row>
        )}
        <Row spacingV="l" justify="center">
          <Column span={6} hasNoPadding>
            <div className="ModalRadioButtons">
              <RadioButtonGroup
                label="Public visibility"
                tooltip={
                  <KanaTooltip
                    tooltipHeader="What is public visibility"
                    tooltipText="Choose whether this document is visible in the public directory or not"
                  />
                }
                data={[
                  { key: false, value: "Public" },
                  { key: true, value: "Private" },
                ]}
                direction="vertical"
                value={visibility}
                onChange={(e: RadioChangeEvent) => setVisibility(e.target.value)}
              />
            </div>
          </Column>
        </Row>
        {relatedOrganisations && relatedOrganisations?.length !== 0 && visibility && (
          <Row spacingV="l" justify="center">
            <Column span={6} hasNoPadding>
              <FormLabel
                label="Organisation access"
                tooltip={
                  <KanaTooltip
                    tooltipHeader="What is organisation access"
                    tooltipText="If the document is public, all organisations with project access can view the document"
                  />
                }
                tooltipJustify="left"
              />
              <div className="ModalDropdown">
                <MultiSelect
                  values={selectedOrganisations}
                  data={relatedOrganisations || []}
                  onChange={(values) => setSelectedOrganisations(values)}
                />
              </div>
            </Column>
          </Row>
        )}
        {showExternalRegistryWarning && (
          <Row spacingV="ll" justify="center">
            <Column span={6} hasNoPadding>
              <InformationBox title="Diverging from registry" variant="alert">
                <p className="body2">
                  By updating this document, you will be replacing a public document that was taken from your external
                  registry. The project’s public documents should continue to align with the external registry to ensure
                  accuracy. If a new version is uploaded to the external registry, it will be loaded into Kana and
                  become a public version.
                </p>
              </InformationBox>
            </Column>
          </Row>
        )}
        {showPublicWarning && (
          <Row spacingV="ll" justify="center">
            <Column span={6} hasNoPadding>
              <InformationBox title="Public version required" variant="alert">
                <p className="body2">
                  As this document is available on the external registry, there must be a public version within Kana. If
                  this version is updated to private, the most recent version taken from your external registry will be
                  made public, to ensure consistency between platforms.
                </p>
              </InformationBox>
            </Column>
          </Row>
        )}
        <Row justify="center">
          <Column span={6}>
            <div className="ModalButtons">
              <Button onClick={editDocument} text="Save changes" isLoading={isEditDocumentLoading} fullWidth />
              <Button onClick={onCancel} text="Cancel" variant="secondary" fullWidth />
            </div>
          </Column>
        </Row>
      </Modal>
    </div>
  );
};
