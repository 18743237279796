import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from "react";

import { MarketingAssetsConstants, OrganisationPermissionConstants } from "../../../../../../constants";
import {
  ActionButtons,
  Column,
  ErrorBox,
  KanaTooltip,
  Row,
  SingleFileUpload,
  TextArea,
  TextInput,
  Toggle,
} from "../../../../../../widget";
import {
  FormHeroData,
  MarketingAssetsFormChangedAction,
  MarketingAssetsSubmitHandler,
  UpdateListingType,
} from "../../models";
import { useHeroForm } from "./useHeroForm";

interface HeroFormProps {
  objectUuid: string;
  objectType: string;
  objectKey: string;
  updateListing: UpdateListingType;
  heroDefaultValues: FormHeroData;
  heroOldDefaultValues: FormHeroData;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<MarketingAssetsFormChangedAction>;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

export const HeroForm = forwardRef(
  (
    {
      objectUuid,
      objectType,
      objectKey,
      updateListing,
      heroDefaultValues,
      heroOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      getCurrentFormsData,
    }: HeroFormProps,
    forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>
  ): JSX.Element => {
    const {
      bannerImageUploadRef,
      title,
      enabled,
      subtitle,
      bannerImageUrl,
      errors,
      isHandleSubmitLoading,
      setEnabled,
      setTitle,
      handleSetBannerImage,
      setSubtitle,
      handleSubmit,
      handleCancel,
      hasPermission,
    } = useHeroForm(
      objectUuid,
      objectType,
      objectKey,
      updateListing,
      heroDefaultValues,
      heroOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      forwardedRef,
      getCurrentFormsData
    );

    return (
      <>
        <Row spacingV="ll">
          <Column span={4}>
            <h2>Hero</h2>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={4}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row spacingV="ll">
            <Column span={12}>
              <Toggle label="Show this section on organisation profile" value={enabled} onChange={setEnabled} />
            </Column>
          </Row>
          <Row spacingV="sl">
            <Column span={6}>
              <SingleFileUpload
                ref={bannerImageUploadRef}
                label="Banner image"
                disabled={!enabled}
                informationLabel="The image should be 1760px x 360px"
                previewUrl={bannerImageUrl}
                onChange={async (f) => {
                  await handleSetBannerImage(f);
                }}
              />
            </Column>
          </Row>
          <Row spacingV="sl">
            <Column span={10}>
              <TextInput
                name="hero-title"
                label="Title"
                disabled={!enabled}
                tooltip={
                  <KanaTooltip
                    tooltipHeader="What is the hero title used for? "
                    tooltipText="This is the headline which will be displayed on your public developer profile."
                  />
                }
                value={title}
                onChange={(value) => {
                  setTitle(value);
                }}
                maxLength={MarketingAssetsConstants.HERO_TITLE_MAX_LENGTH}
                displayCharacterCount
              />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={10}>
              <TextArea
                label="Subtitle"
                name="hero-subtitle"
                disabled={!enabled}
                tooltip={
                  <KanaTooltip
                    tooltipHeader="What is the hero subtitle used for? "
                    tooltipText="This is the brief overview of your business that will be displayed beneath the headline on your developer profile."
                  />
                }
                value={subtitle || ""}
                onChange={(v) => setSubtitle(v)}
                displayCharacterCount
                maxLength={MarketingAssetsConstants.HERO_SUBTITLE_MAX_LENGTH}
              />
            </Column>
          </Row>
          {hasPermission(OrganisationPermissionConstants.WRITE) && (
            <Row spacingV="2xl">
              <Column span={2}>
                <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
              </Column>
            </Row>
          )}
        </form>
      </>
    );
  }
);
