import "./AcceptInvitation.css";

import { ProjectAccessConstants, ProjectPermissionConstants } from "../../../../../../constants";
import { DeveloperSingleCardTemplate } from "../../../../../../templates";
import { getFormattedDate } from "../../../../../../utils";
import { Button, Column, InformationBox, ReadonlyText, Row } from "../../../../../../widget";
import { useAcceptInvitation } from "./useAcceptInvitation";

export const AcceptInvitation = (): JSX.Element => {
  const {
    invitationDetails,
    handleBackClick,
    onAcceptInvitation,
    onRejectInvitation,
    hasPermission,
    isOnAcceptInvitationLoading,
    isOnRejectInvitationLoading,
  } = useAcceptInvitation();
  return (
    <div>
      {invitationDetails && (
        <DeveloperSingleCardTemplate
          pageTitle={invitationDetails.project.displayName}
          handleBackClick={handleBackClick}
          backButtonText="Back to project access"
        >
          <Row spacingV="ll">
            <Column span={4}>
              <div>
                <h2>Accept project access</h2>
              </div>
            </Column>
          </Row>
          {invitationDetails?.calculatedStatus === ProjectAccessConstants.STATUS_SENT && (
            <Row spacingV="ll">
              <Column span={12}>
                <InformationBox variant="info">
                  <div className="ProjectAccessAcceptInvitationInfo">
                    <p className="body2">
                      <span>{invitationDetails.sentByUser?.fullName}</span> of{" "}
                      <span>{invitationDetails.sourceOrganisation?.displayName}</span> would like to grant your
                      organisation access to their project, <span>{invitationDetails.project?.displayName}</span>. If
                      not accepted or declined, this access invite will expire after 10 days.
                    </p>
                  </div>
                </InformationBox>
              </Column>
            </Row>
          )}
          <Row spacingV="ll">
            <Column span={4}>
              <ReadonlyText label="Project name" value={invitationDetails.project?.displayName} />
            </Column>
            <Column span={4} offset={1}>
              <ReadonlyText label="Source developer" value={invitationDetails.sourceOrganisation?.displayName} />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={4}>
              <ReadonlyText label="Code" value={invitationDetails.project?.standard?.displayName} />
            </Column>
            <Column span={4} offset={1}>
              <ReadonlyText label="Access" value={invitationDetails.targetRole} />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={4}>
              <ReadonlyText label="Project status" value={invitationDetails.project?.status} />
            </Column>
            <Column span={4} offset={1}>
              <ReadonlyText label="Granted by" value={invitationDetails.sentByUser?.fullName} />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={4}>
              <ReadonlyText label="Sent at" value={getFormattedDate(invitationDetails.sentDate)} />
            </Column>
            {invitationDetails?.calculatedStatus !== ProjectAccessConstants.STATUS_ACCEPTED && (
              <Column span={4} offset={1}>
                <ReadonlyText label="Expires" value={getFormattedDate(invitationDetails.expiresDate)} />
              </Column>
            )}
          </Row>
          {invitationDetails?.calculatedStatus === ProjectAccessConstants.STATUS_SENT &&
            hasPermission(ProjectPermissionConstants.ACCEPT_REJECT_PROJECT_ORGANISATION_INVITATION) && (
              <div>
                <Button text="Accept invitation" onClick={onAcceptInvitation} isLoading={isOnAcceptInvitationLoading} />
                <Button
                  text="Reject invitation"
                  variant="secondary"
                  onClick={onRejectInvitation}
                  isLoading={isOnRejectInvitationLoading}
                />
              </div>
            )}
        </DeveloperSingleCardTemplate>
      )}
    </div>
  );
};
