import { KeyFactsData } from "../../../../route/public/code/models";
import { Column, Row } from "../../../../widget";
import { ChartsSection } from "../../../components";
import { UseKeyFactsSection } from "./useKeyFactsSection";

interface KeyFactsSectionProps {
  keyFactsText: Omit<KeyFactsData, "enabled">;
  organisationUuid: string | undefined;
}

export const KeyFactsSection = ({ keyFactsText, organisationUuid }: KeyFactsSectionProps): JSX.Element => {
  const { totalActiveProjectsChartData, unitsCreatedChartData, totalPlannedCarbonSequestrationChartData, isLoading } =
    UseKeyFactsSection(organisationUuid);
  return (
    <div>
      {!isLoading &&
      (Object.keys(totalActiveProjectsChartData).length > 0 ||
        unitsCreatedChartData.PIUs > 0 ||
        unitsCreatedChartData.VCUs > 0 ||
        Object.keys(totalPlannedCarbonSequestrationChartData).length > 0) ? (
        <ChartsSection
          title="Key facts"
          content={keyFactsText?.content}
          firstChartData={totalActiveProjectsChartData}
          firstChartTitle="Projects"
          secondChartData={unitsCreatedChartData}
          secondChartTitle="Units"
          secondChartNoDataMessage="No units issued"
          thirdChartData={totalPlannedCarbonSequestrationChartData}
          thirdChartTitle="Locations"
        />
      ) : (
        <div className="ChartsSection">
          <Row spacingV="ll">
            <Column span={12}>
              <h2>At a glance</h2>
            </Column>
          </Row>
          {keyFactsText?.content && (
            <Row spacingV="ll">
              <Column span={12}>
                <p className="body1">{keyFactsText?.content}</p>
              </Column>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};
