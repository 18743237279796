import "./AcceptInvitation.css";

import bgImage from "../../../assets/images/Landscape.jpg";
import { AuthenticationTemplate } from "../../../templates";
import { getLogonRoute } from "../../../utils/routes";
import { Button, Column, ErrorBox, InformationBox, Row } from "../../../widget";
import { AcceptInvitationForm } from "./form";
import { useAcceptInvitation } from "./useAcceptInvitation";

export const AcceptInvitation = (): JSX.Element => {
  const { urlToken, developerName, accountCreated, email, navigation, setAccountCreated } = useAcceptInvitation();

  return (
    <AuthenticationTemplate imageSrc={bgImage}>
      <Row spacingV={accountCreated ? "ll" : "m"}>
        <Column span={12}>
          <h1>Welcome</h1>
        </Column>
      </Row>
      {/* eslint-disable-next-line no-nested-ternary */}
      {accountCreated ? (
        <>
          <Row spacingV="ll">
            <Column span={12}>
              <InformationBox variant="success" title="Account created">
                <p>Thank you for accepting your invitation.</p>
                <p>
                  You can now log in with your new password to access&nbsp;
                  {developerName}.
                </p>
              </InformationBox>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <Button
                fullWidth
                text="Log in"
                onClick={() => {
                  navigation(getLogonRoute());
                }}
              />
            </Column>
          </Row>
        </>
      ) : urlToken ? (
        <>
          <Row spacingV="ml">
            <Column className="body2" span={12}>
              <p>You have been invited to join {developerName} on Kana.</p>
              <p>
                Please enter a password to create a new account. If you already have an account, please enter your
                existing password.
              </p>
            </Column>
          </Row>
          <AcceptInvitationForm urlToken={urlToken} email={email} onSuccess={setAccountCreated} />
        </>
      ) : (
        <Row className="AcceptInvitationTokenNotFound">
          <Column span={12}>
            <ErrorBox text="Copy the link from the invitation and paste it directly into the browser" />
          </Column>
        </Row>
      )}
    </AuthenticationTemplate>
  );
};
