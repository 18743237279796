import { FormEvent, useContext, useState } from "react";

import { ProjectContext } from "../../../../route/shared/projects/project/ProjectContext";
import { updateProjectStatus } from "../../../../service/project";
import { ServiceError, Status } from "../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../utils";
import { Toast } from "../../../../widget";

const NEW_PROJECT_STATUS = "Pre-development";

interface UsePublishProjectModalReturnData {
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  resetForm: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const usePublishProjectModal = (closeModal: () => void): UsePublishProjectModalReturnData => {
  const { projectDetails, setProjectDetails } = useContext(ProjectContext);

  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const resetForm = (): void => {
    setErrors([]);
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!projectDetails?.uuid && !projectDetails?.rowVersion) return;

    const res = await updateProjectStatus({
      projectUuid: projectDetails?.uuid,
      rowVersion: projectDetails?.rowVersion,
      status: NEW_PROJECT_STATUS,
    });

    if (res.status === Status.Success && res.data) {
      Toast.success({ message: "Project published successfully" });

      if (projectDetails) {
        setProjectDetails({
          ...projectDetails,
          status: NEW_PROJECT_STATUS,
          rowVersion: res.data.rowVersion,
        });
      }

      resetForm();
      closeModal();
    }

    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  }, setIsHandleSubmitLoading);

  return {
    errors,
    isHandleSubmitLoading,
    resetForm,
    handleSubmit,
  };
};
