import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { getProjectAccessRoute } from "../../../../../../utils/routes";
import { ProjectContext } from "../../../../../shared/projects/project/ProjectContext";

interface UseInviteReturnData {
  pageTitle?: string;
  handleBackClick: () => void;
}

export const useInvite = (): UseInviteReturnData => {
  const navigate = useNavigate();
  const { projectDetails } = useContext(ProjectContext);

  const handleBackClick = (): void => {
    if (projectDetails?.uuid) {
      navigate(getProjectAccessRoute(projectDetails.uuid));
    }
  };

  return {
    pageTitle: projectDetails?.displayName,
    handleBackClick,
  };
};
