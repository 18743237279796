import { RefObject, useEffect } from "react";

export const useClickOutside = (ref: RefObject<HTMLDivElement>, onClickOutside: () => void): void => {
  useEffect(() => {
    // Read this if we get null problems when clicking outside https://stackoverflow.com/questions/71193818/react-onclick-argument-of-type-eventtarget-is-not-assignable-to-parameter-of-t
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};
