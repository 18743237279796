import "./PreviewBanner.css";

import { Button } from "../../forms";

interface PreviewBannerProps {
  description: string;
  onClose?: () => void;
}

export const PreviewBanner = ({ description, onClose }: PreviewBannerProps): JSX.Element => {
  return (
    <div className="PreviewBanner">
      <div className="PreviewBannerInfo">
        <h2>Preview</h2>
        <span className="body1">{description}</span>
      </div>
      <Button text="Close Preview" variant="reversedPrimary" onClick={onClose} />
    </div>
  );
};
