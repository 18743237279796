import { useMemo } from "react";

import { GetProjectDetailsResponse } from "../../../../../../service/publicquery";
import { StandardSpecificData } from "../../../../../shared/projects/project/overview/components/StandardSpecificSection/types";
import { createExpressionContext } from "../../../../../shared/projects/project/overview/components/StandardSpecificSection/utils";

interface UsePublicStandardSpecificSectionReturnData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any;
}

export const usePublicStandardSpecificSection = (
  standardSpecificData: StandardSpecificData,
  projectData?: GetProjectDetailsResponse
): UsePublicStandardSpecificSectionReturnData => {
  const expressionContext = useMemo(
    () => createExpressionContext(standardSpecificData, projectData),
    [standardSpecificData, projectData]
  );

  return { expressionContext };
};
