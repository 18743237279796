import { axios } from "../axios";
import { BaseResponse, BaseResponseWithSingle, Status } from "../Shared";
import {
  CreateDiscussionMessageRequest,
  CreateDiscussionMessageResponse,
  CreateDiscussionThreadRequest,
  CreateDiscussionThreadResponse,
  DeleteDiscussionMessageRequest,
  DeleteDiscussionThreadRequest,
  UpdateDiscussionMessageRequest,
  UpdateDiscussionMessageResponse,
  UpdateDiscussionThreadRequest,
  UpdateDiscussionThreadResponse,
} from "./Types";

export const createDiscussionThread = async (
  req: CreateDiscussionThreadRequest
): Promise<BaseResponseWithSingle<CreateDiscussionThreadResponse>> => {
  return axios
    .request<BaseResponseWithSingle<CreateDiscussionThreadResponse>>({
      method: "post",
      url: "discussion/thread/create",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const createDiscussionMessage = async (
  req: CreateDiscussionMessageRequest
): Promise<BaseResponseWithSingle<CreateDiscussionMessageResponse>> => {
  return axios
    .request<BaseResponseWithSingle<CreateDiscussionMessageResponse>>({
      method: "post",
      url: "discussion/message/create",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const deleteDiscussionMessage = async (req: DeleteDiscussionMessageRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "delete",
      url: "discussion/message/delete",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const deleteDiscussionThread = async (req: DeleteDiscussionThreadRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "DELETE",
      url: "discussion/thread/delete",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateDiscussionMessage = async (
  req: UpdateDiscussionMessageRequest
): Promise<BaseResponseWithSingle<UpdateDiscussionMessageResponse>> => {
  return axios
    .request<BaseResponse>({
      method: "PATCH",
      url: "discussion/message/update",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateDiscussionThread = async (
  req: UpdateDiscussionThreadRequest
): Promise<BaseResponseWithSingle<UpdateDiscussionThreadResponse>> => {
  return axios
    .request<BaseResponse>({
      method: "PATCH",
      url: "discussion/thread/update",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};
