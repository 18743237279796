import "./DocumentCard.css";

import { FileBlueIcon } from "../../../../../../assets";

interface DocumentCardProps {
  name: string;
  variant?: string;
  uploadedDate: string;
  onClick?: () => void;
}

export const DocumentCard = ({ name, variant, uploadedDate, onClick }: DocumentCardProps): JSX.Element => {
  return (
    <button className="DocumentCard" onClick={onClick}>
      <div className="DocumentCardTitle">
        <FileBlueIcon />
        <h3>{name}</h3>
        {variant && <p className="body1">{variant}</p>}
      </div>
      <p className="body2 DocumentCardUploadDateText">{`Uploaded on ${uploadedDate}`}</p>
    </button>
  );
};
