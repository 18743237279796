import { FetchResult, Observable } from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";

import { getErrorMessageFromCode } from "../../../service/ValidationErrorFormatter";
import { Toast } from "../../../widget";
import { ERROR_ACCESS_DENIED } from "../../errorConstants";
import { logErrorWithDetail } from "./errorHandlerUtils";

// for HTTP 403 status codes, show an error message
// and log an error (as this should never occur in the normal
// course of events)
export const errorHandler403 = (networkError: NetworkError): Observable<FetchResult> | undefined => {
  logErrorWithDetail("GraphQL status code 403 received", networkError);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorExtensionCode = networkError.result.errors[0].extensions.code;

  if (errorExtensionCode && errorExtensionCode !== ERROR_ACCESS_DENIED) {
    Toast.error({
      message: getErrorMessageFromCode(errorExtensionCode),
    });
  } else {
    Toast.error({
      message: getErrorMessageFromCode(ERROR_ACCESS_DENIED),
    });
  }
  return undefined;
};
