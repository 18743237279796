import "./VerifierSingleCardTemplate.css";

import { ReactNode } from "react";

import { ArrowFullLeftDarkIcon } from "../../../assets";
import { NavigationActiveElement } from "../../../models";
import { Button, Column, FullPageCard, LeftNavigation, Row, UserHeader } from "../../../widget";

interface VerifierSingleCardTemplateProps {
  pageTitle: string | undefined;
  children: ReactNode;
  activeElement?: NavigationActiveElement;
  handleBackClick?: () => void;
  backButtonText?: string;
}

export const VerifierSingleCardTemplate = ({
  pageTitle,
  children,
  activeElement,
  handleBackClick,
  backButtonText = "Back",
}: VerifierSingleCardTemplateProps): JSX.Element => {
  return (
    <div className="VerifierSingleCardTemplate">
      <div className="VerifierSingleCardTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="VerifierSingleCardTemplateContainer">
        {handleBackClick ? (
          <div className="HeaderContainer">
            <Button
              text={backButtonText}
              variant="secondary"
              icon={<ArrowFullLeftDarkIcon />}
              iconPosition="left"
              onClick={handleBackClick}
            />
            <UserHeader />
          </div>
        ) : (
          <UserHeader />
        )}
        <Row spacingV="ll">
          <Column span={12}>
            <h1>{pageTitle}</h1>
          </Column>
        </Row>
        <FullPageCard>{children}</FullPageCard>
      </div>
    </div>
  );
};
