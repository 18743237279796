import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../constants";
import { DownloadActivityZipPageParams } from "../../../../models";
import { downloadStoredZip } from "../../../../service/activity";
import { Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { initiateDocumentDownloadViaBrowser } from "../../../../utils";
import { getDashboardRoute, getProjectActivitiesRoute } from "../../../../utils/routes";

interface UseSuccessReturnData {
  downloadFailed: boolean;
  isProjectOwner: boolean;
  navigateToDashboard: () => void;
  navigateToProjectActivities: () => void;
}

export const useDownloadZip = (): UseSuccessReturnData => {
  const navigate = useNavigate();
  const { activityZipUuid, ownerUuid, ownerType } = useParams<DownloadActivityZipPageParams>();

  const { currentUserType } = useAuth();

  const [downloadFailed, setDownloadFailed] = useState(false);

  const fetchData = useCallback(async () => {
    if (activityZipUuid) {
      const res = await downloadStoredZip({ uuid: activityZipUuid });

      if (res.status === Status.Error) {
        setDownloadFailed(true);

        return;
      }

      initiateDocumentDownloadViaBrowser(res);
    }
  }, [activityZipUuid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const navigateToDashboard = (): void => {
    navigate(getDashboardRoute(currentUserType), { replace: true });
  };

  const navigateToProjectActivities = (): void => {
    if (ownerUuid) {
      navigate(getProjectActivitiesRoute(ownerUuid, currentUserType));
    }
  };

  return {
    downloadFailed,
    isProjectOwner: currentUserType !== OrganisationTypeConstants.VERIFIER && ownerType === "project",
    navigateToDashboard,
    navigateToProjectActivities,
  };
};
