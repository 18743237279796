import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { getGroupDetails, GetGroupDetailsResponse } from "../../../../../../service/query";
import { ServiceError, Status } from "../../../../../../service/Shared";

type GroupPageRouteParams = "groupUuid";

interface GroupProviderProps {
  children?: JSX.Element;
}

interface GroupContextType {
  groupDetails: GetGroupDetailsResponse | undefined;
  origin: string | null;
  currentProjectUuid: string | null;
  errors: ServiceError[] | undefined;
  refreshGroup: boolean;
  setRefreshGroup: Dispatch<SetStateAction<boolean>>;
}

export const GroupContext = createContext<GroupContextType>({} as GroupContextType);

export const GroupProvider = ({ children }: GroupProviderProps): JSX.Element => {
  const { groupUuid } = useParams<GroupPageRouteParams>();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);
  const [refreshGroup, setRefreshGroup] = useState<boolean>(false);
  const [groupDetails, setGroupDetails] = useState<GetGroupDetailsResponse | undefined>(undefined);

  const origin = searchParams.get("origin");
  const currentProjectUuid = searchParams.get("projectUuid");

  const fetchData = useCallback(async () => {
    if (groupUuid) {
      const response = await getGroupDetails({ projectGroupUuid: groupUuid });

      if (response.status === Status.Success && response.data) {
        setGroupDetails(response.data);
      } else {
        setErrors(response.errors);
      }
    }
  }, [groupUuid]);

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, [fetchData, groupUuid, refreshGroup]);

  const memoisedValue = useMemo(
    () => ({
      groupDetails,
      origin,
      currentProjectUuid,
      errors,
      refreshGroup,
      setRefreshGroup,
    }),
    [groupDetails, errors, fetchData, refreshGroup]
  );

  return <GroupContext.Provider value={memoisedValue}>{!isLoading && children}</GroupContext.Provider>;
};
