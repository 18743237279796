import { Dispatch, FormEvent, SetStateAction, useState } from "react";

import { createForgottenPasswordRequest } from "../../../../service/identity";
import { ServiceError, Status } from "../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../utils";

interface UseForgottenPasswordFormReturnData {
  email: string;
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  setEmail: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const useForgottenPasswordForm = (onSuccess: (e: boolean) => void): UseForgottenPasswordFormReturnData => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);
    const res = await createForgottenPasswordRequest({ email });

    if (res.status === Status.Success) {
      onSuccess(true);
    } else {
      setErrors(res.errors);
    }
  }, setIsHandleSubmitLoading);

  return {
    email,
    errors,
    isHandleSubmitLoading,
    setEmail,
    handleSubmit,
  };
};
