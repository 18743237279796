import "./CodeSnippetTextArea.css";

import { Toast } from "../../general/Toast";

interface CodeSnippetTextAreaProps {
  name: string;
  value: string;
}

const copyToClipboard = (text: string): void => {
  navigator.clipboard
    .writeText(text)
    .then(() => Toast.success({ message: "Copied to clipboard" }))
    .catch(() => Toast.error({ message: "Could not copy to clipboard" }));
};

export const CodeSnippetTextArea = ({ name, value }: CodeSnippetTextAreaProps): JSX.Element => {
  return (
    <div className="form_field CodeSnippetTextArea">
      <div className="CodeSnippetTextAreaHeader">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={name}>Embed Code</label>
        <button type="button" onClick={() => copyToClipboard(value)}>
          Copy
        </button>
      </div>
      <textarea disabled id={name} value={value} />
    </div>
  );
};
