import { useEffect } from "react";

import { StyleConstants } from "../../../constants";
import { useTrapFocusWithinDiv } from "../../../utils";

interface useModalProps {
  show: boolean;
}

interface useModalReturnData {
  modalRefCallBack: (node: HTMLDivElement) => void;
}

export const useModal = ({ show }: useModalProps): useModalReturnData => {
  const modalRefCallBack = useTrapFocusWithinDiv();

  useEffect(() => {
    if (show) {
      document.body.classList.add(StyleConstants.OVERFLOW_HIDDEN_CLASS);
    } else {
      document.body.classList.remove(StyleConstants.OVERFLOW_HIDDEN_CLASS);
    }
    return () => document.body.classList.remove(StyleConstants.OVERFLOW_HIDDEN_CLASS);
  }, [show]);

  return {
    modalRefCallBack,
  };
};
