import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from "react";

import { OrganisationPermissionConstants } from "../../../../../../constants";
import { ActionButtons, Column, Row, Toggle } from "../../../../../../widget";
import {
  FormAtAGlanceData,
  MarketingAssetsFormChangedAction,
  MarketingAssetsSubmitHandler,
  UpdateListingType,
} from "../../models";
import { useAtAGlanceForm } from "./useAtAGlanceForm";

interface AtAGlanceFormProps {
  objectUuid: string;
  objectKey: string;
  updateListing: UpdateListingType;
  atAGlanceDefaultValues: FormAtAGlanceData;
  atAGlanceOldDefaultValues: FormAtAGlanceData;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<MarketingAssetsFormChangedAction>;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

export const AtAGlanceForm = forwardRef(
  (
    {
      objectUuid,
      objectKey,
      updateListing,
      atAGlanceDefaultValues,
      atAGlanceOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      getCurrentFormsData,
    }: AtAGlanceFormProps,
    forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>
  ): JSX.Element => {
    const { enabled, isHandleSubmitLoading, setEnabled, handleCancel, handleSubmit, hasPermission } = useAtAGlanceForm(
      objectUuid,
      objectKey,
      updateListing,
      atAGlanceDefaultValues,
      atAGlanceOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      forwardedRef,
      getCurrentFormsData
    );

    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="m">
          <Column span={4}>
            <h2>At a glance</h2>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <span className="body1">
              This section provides chart overviews of the number of your projects, units and locations.
            </span>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Toggle label="Show this section on organisation profile" value={enabled} onChange={setEnabled} />
          </Column>
        </Row>
        {hasPermission(OrganisationPermissionConstants.WRITE) && (
          <Row spacingV="2xl">
            <Column span={2}>
              <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
            </Column>
          </Row>
        )}
      </form>
    );
  }
);
