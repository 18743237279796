import { useCallback, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

import {
  AboutUsData,
  AtAGlanceData,
  GetInTouchData,
  HeroData,
  OurValuesData,
  PublicDeveloperDetailsPreviewParams,
  RichContentSectionData,
} from "../../../../../models";
import { getOrganisationListingPreview } from "../../../../../service/organisation";
import { Status } from "../../../../../service/Shared";

type CustomNavigateFunction = NavigateFunction;

interface UsePreviewReturnData {
  navigate: CustomNavigateFunction;
  previewUuid: string | undefined;
  dataIsLoading: boolean;
  previewData: DeveloperPreviewData | undefined;
}

export interface DeveloperPreviewData {
  hero: HeroData;
  ourValues: OurValuesData;
  ourApproach: RichContentSectionData;
  atAGlance: AtAGlanceData;
  aboutUs: AboutUsData;
  getInTouch: GetInTouchData;
  ourServices: RichContentSectionData;
}

export const usePreview = (): UsePreviewReturnData => {
  const navigate = useNavigate();
  const { previewUuid } = useParams<PublicDeveloperDetailsPreviewParams>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState<DeveloperPreviewData>();

  const fetchData = useCallback(async (): Promise<void> => {
    if (previewUuid) {
      const organisationPreviewRes = await getOrganisationListingPreview({
        previewUuid,
      });

      if (organisationPreviewRes.status === Status.Success && organisationPreviewRes.data) {
        setPreviewData({
          hero: {
            enabled: organisationPreviewRes.data.content?.hero?.enabled,
            title: organisationPreviewRes.data.content?.hero?.title,
            subtitle: organisationPreviewRes.data.content?.hero?.subtitle,
            imageUrl:
              organisationPreviewRes.data.listingFiles?.find(
                (file) => file.uuid === organisationPreviewRes.data?.content?.hero?.imageFileUuid
              )?.file?.url || null,
          },
          ourValues: {
            enabled: organisationPreviewRes.data.content?.ourValues?.enabled,
            content: organisationPreviewRes.data.content?.ourValues?.content,
          },
          atAGlance: {
            enabled: organisationPreviewRes.data.content?.atAGlance?.enabled,
          },
          aboutUs: {
            enabled: organisationPreviewRes.data.content?.aboutUs?.enabled,
            content: organisationPreviewRes.data.content?.aboutUs?.content,
            imageUrl:
              organisationPreviewRes.data.listingFiles?.find(
                (file) => file.uuid === organisationPreviewRes.data?.content?.aboutUs?.imageFileUuid
              )?.file?.url || null,
          },
          getInTouch: {
            enabled: organisationPreviewRes.data.content?.getInTouch?.enabled,
          },
          ourApproach: {
            enabled: organisationPreviewRes.data.content?.ourApproach?.enabled,
            part1: {
              title: organisationPreviewRes.data.content?.ourApproach?.part1?.title,
              content: organisationPreviewRes.data.content?.ourApproach?.part1?.content,
              imageUrl:
                organisationPreviewRes.data.listingFiles?.find(
                  (file) => file.uuid === organisationPreviewRes.data?.content?.ourApproach?.part1?.imageFileUuid
                )?.file?.url || null,
            },
            part2: {
              title: organisationPreviewRes.data.content?.ourApproach?.part2?.title,
              content: organisationPreviewRes.data.content?.ourApproach?.part2?.content,
              imageUrl:
                organisationPreviewRes.data.listingFiles?.find(
                  (file) => file.uuid === organisationPreviewRes.data?.content?.ourApproach?.part2?.imageFileUuid
                )?.file?.url || null,
            },
            part3: {
              title: organisationPreviewRes.data.content?.ourApproach?.part3?.title,
              content: organisationPreviewRes.data.content?.ourApproach?.part3?.content,
              imageUrl:
                organisationPreviewRes.data?.listingFiles?.find(
                  (file) => file.uuid === organisationPreviewRes.data?.content?.ourApproach?.part3?.imageFileUuid
                )?.file?.url || null,
            },
            part4: {
              title: organisationPreviewRes.data.content?.ourApproach?.part4?.title,
              content: organisationPreviewRes.data.content?.ourApproach?.part4?.content,
              imageUrl:
                organisationPreviewRes.data.listingFiles?.find(
                  (file) => file.uuid === organisationPreviewRes.data?.content?.ourApproach?.part4?.imageFileUuid
                )?.file?.url || null,
            },
          },
          ourServices: {
            enabled: organisationPreviewRes.data.content?.ourServices?.enabled,
            part1: {
              title: organisationPreviewRes.data.content?.ourServices?.part1?.title,
              content: organisationPreviewRes.data.content?.ourServices?.part1?.content,
              imageUrl:
                organisationPreviewRes.data.listingFiles?.find(
                  (file) => file.uuid === organisationPreviewRes.data?.content?.ourServices?.part1?.imageFileUuid
                )?.file?.url || null,
            },
            part2: {
              title: organisationPreviewRes.data.content?.ourServices?.part2?.title,
              content: organisationPreviewRes.data.content?.ourServices?.part2?.content,
              imageUrl:
                organisationPreviewRes.data.listingFiles?.find(
                  (file) => file.uuid === organisationPreviewRes.data?.content?.ourServices?.part2?.imageFileUuid
                )?.file?.url || null,
            },
            part3: {
              title: organisationPreviewRes.data.content?.ourServices?.part3?.title,
              content: organisationPreviewRes.data.content?.ourServices?.part3?.content,
              imageUrl:
                organisationPreviewRes.data.listingFiles?.find(
                  (file) => file.uuid === organisationPreviewRes.data?.content?.ourServices?.part3?.imageFileUuid
                )?.file?.url || null,
            },
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    navigate,
    previewUuid,
    dataIsLoading,
    previewData,
  };
};
