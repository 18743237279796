import "./BarChart.css";

import { ChartData, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";

import { ColorConstants } from "../../../constants";
import { ChartProps } from "../models";
import { maybeSetGlobalChartJsConfiguration } from "../utils";

export interface BarChartProps extends ChartProps {
  suffix?: string;
}

maybeSetGlobalChartJsConfiguration();

export const BarChart = ({ data, emptyPlaceholder = "No data to display", suffix }: BarChartProps): JSX.Element => {
  if (!data || Object.keys(data).length === 0)
    return (
      <div className="Bar Bar_empty">
        <p className="body3">{emptyPlaceholder}</p>
      </div>
    );

  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: () => "",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          padding: 6,
        },
        grid: {
          display: false,
          drawTicks: false,
        },
        border: {
          color: ColorConstants.GREY_20,
        },
      },
      y: {
        grid: {
          color: ColorConstants.LIGHT_GREY,
          tickColor: ColorConstants.WHITE,
        },
        border: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            return suffix ? `${value} ${suffix}` : value;
          },
        },
      },
    },
  };

  const xLabels = Object.keys(data);
  const dataPoints = xLabels.map((label) => data[label]);
  const chartData: ChartData<"bar", number[], string> = {
    labels: xLabels,
    datasets: [
      {
        data: dataPoints,
        barPercentage: 0.46,
        hoverBackgroundColor: ColorConstants.BLUE_80,
      },
    ],
  };
  return (
    <div className="Bar">
      <Bar data={chartData} options={options} />
    </div>
  );
};
