import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { PublicTemplate } from "../../../../../templates";
import { PublicCodeDetailsPageTemplate } from "../../../../../templates/PublicCodeDetailsPageTemplate";
import { useDetails } from "./useDetails";

const Details = (): JSX.Element => {
  const { dataIsLoading, codeDetailsData } = useDetails();
  const [searchParams] = useSearchParams();
  const projectUuid = useMemo(() => {
    return searchParams.get("projectUuid");
  }, [searchParams]);

  return (
    <PublicTemplate activeElement={projectUuid ? "Project list" : "Codes and Standards"} isContentUnwrapped>
      {!dataIsLoading && codeDetailsData && <PublicCodeDetailsPageTemplate codeDetailsData={codeDetailsData} />}
    </PublicTemplate>
  );
};

export default Details;
