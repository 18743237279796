import "./LayeredSelect.css";

import RcSelect, { OptGroup, Option } from "rc-select";
import { useEffect, useState } from "react";

import { DropdownLightIcon } from "../../../assets";
import { LayeredSelectData } from "../../../models";
import { FormLabel } from "../FormLabel";

export interface LayeredSelectProps {
  label?: string;
  allowLabelDangerousHtml?: boolean;
  initialKey?: string | null;
  value?: string | null;
  placeholder?: string;
  className?: string;
  dropdownMatchSelectWidth?: boolean;
  data: LayeredSelectData;
  onChange: (value: string) => void;
}

export const LayeredSelect = ({
  label,
  allowLabelDangerousHtml,
  initialKey,
  value,
  placeholder = "Please select",
  className,
  dropdownMatchSelectWidth,
  data,
  onChange,
}: LayeredSelectProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onChangeInternal = (val: string): void => {
    setSelectedValue(val);
    onChange(val);
  };

  const options = data?.map((_options) =>
    typeof _options.optionsHeader === "number" || typeof _options.optionsHeader === "string" ? (
      <OptGroup key={_options.optionsHeader}>
        {_options.optionsHeader}
        {_options.options.map((kvp) => (
          <Option key={`${_options.optionsHeader}_${kvp.key}`} value={kvp.value}>
            {kvp.value}
          </Option>
        ))}
      </OptGroup>
    ) : (
      <Option
        className="LayeredSelect-item LayeredSelect-item-group"
        key={`${_options.optionsHeader.key}`}
        value={_options.optionsHeader.value}
      >
        {_options.optionsHeader.value}
      </Option>
    )
  );

  return (
    <FormLabel label={label} allowDangerousHtml={allowLabelDangerousHtml}>
      <div className={`${className || ""} form_field`}>
        <RcSelect
          defaultValue={initialKey}
          value={selectedValue}
          suffixIcon={<DropdownLightIcon />}
          menuItemSelectedIcon={undefined}
          placeholder={placeholder || undefined}
          onChange={onChangeInternal}
          prefixCls="LayeredSelect"
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          dropdownAlign={{ overflow: { adjustY: 0 } }}
        >
          {options}
        </RcSelect>
      </div>
    </FormLabel>
  );
};
