import { GetDiscussionThreadsResponse } from "../../../../../service/query";
import { Component, ComponentType, HasData, HasDataSteps, HasDiscussions, HasKey } from "../types";

export const getDiscussionUuids = async (
  stepData: (HasKey & HasDataSteps & HasData & HasDiscussions) | undefined,
  fetchDiscussionsCallback: (discussionUuids: string[]) => Promise<GetDiscussionThreadsResponse[] | undefined>
): Promise<GetDiscussionThreadsResponse[] | undefined> => {
  const stepDiscussions = stepData?.discussions;

  if (stepDiscussions) {
    const discussionUuids: string[] = [];
    Object.values(stepDiscussions).forEach((d) => discussionUuids.push(...d.discussionThreads));

    return fetchDiscussionsCallback(discussionUuids);
  }

  return undefined;
};

export const getDiscussionsForComponent = (
  componentKey: string,
  componentType: ComponentType,
  children: Component[] | undefined,
  discussionUuids: GetDiscussionThreadsResponse[] | undefined,
  stepKeys: string[]
): GetDiscussionThreadsResponse[] | undefined => {
  if (componentType === "Repeater" && children) {
    return discussionUuids?.filter(
      (d) =>
        d.location.includes(stepKeys.join(".")) &&
        children.find((c) => c.key === d.location.split(".").pop()?.split("[")[0])
    );
  }

  return discussionUuids?.filter(
    (d) => d.location.includes(stepKeys.join(".")) && d.location.split(".").pop() === componentKey
  );
};
