import {
  ComponentProperties,
  HasComponents,
  HasKey,
  HasSteps,
  StandardSpecificData,
  StandardSpecificDefinition,
  StandardSpecificStepDataItem,
} from "../../route/shared/projects/project/overview/components/StandardSpecificSection/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueByDataPath = (data: StandardSpecificData, dataPath: string): any => {
  const keys = dataPath.split(".");
  let value: StandardSpecificData | StandardSpecificStepDataItem | undefined = data;
  keys.forEach((key: string) => {
    if (value !== undefined) {
      if (value.steps) {
        value = value.steps.find((step: HasKey) => step.key === key);
      } else if (value.data) {
        value = value.data[key];
      }
    }
  });
  return value;
};

export const getComponentPropertiesByDataPath = (
  data: StandardSpecificDefinition,
  dataPath: string
): ComponentProperties | undefined => {
  const keys = dataPath.split(".");
  let value: StandardSpecificDefinition | (HasSteps & HasComponents) | undefined = data;
  let res;

  keys.forEach((key: string) => {
    if (value !== undefined) {
      if (value.steps) {
        value = value.steps.find((step: HasKey) => step.key === key);
      } else if (value.components) {
        res = value.components.find((component: HasKey) => component.key === key)?.componentProperties;
      }
    }
  });
  return res;
};
