import "./HorizontalLifecycle.css";

import { useMemo } from "react";

import { CompletedCircleGreenIcon, InProgressCircleGreenIcon, NotStartedCircleGreyIcon } from "../../../../assets";
import { ProjectStatusConstants } from "../../../../constants";

interface HorizontalLifecycleProps {
  currentStatus?: string;
}

export const HorizontalLifecycle = ({ currentStatus }: HorizontalLifecycleProps): JSX.Element => {
  const steps = useMemo(() => {
    return currentStatus === ProjectStatusConstants.DRAFT || currentStatus === ProjectStatusConstants.PRE_DEVELOPMENT
      ? [
          ProjectStatusConstants.DRAFT,
          ProjectStatusConstants.PRE_DEVELOPMENT,
          ProjectStatusConstants.UNDER_DEVELOPMENT,
          ProjectStatusConstants.VALIDATED,
          ProjectStatusConstants.VERIFIED,
        ]
      : [ProjectStatusConstants.UNDER_DEVELOPMENT, ProjectStatusConstants.VALIDATED, ProjectStatusConstants.VERIFIED];
  }, []);
  const currentStepIndex = currentStatus ? steps.indexOf(currentStatus) : 0;

  return (
    <div className="HorizontalLifecycle">
      {steps.map((step, index) => (
        <div key={step} className="LifecycleStep">
          {currentStatus === ProjectStatusConstants.VALIDATED || currentStatus === ProjectStatusConstants.VERIFIED
            ? (index <= currentStepIndex && <CompletedCircleGreenIcon />) ||
              (index === currentStepIndex + 1 && <InProgressCircleGreenIcon />) ||
              (index > currentStepIndex && <NotStartedCircleGreyIcon />)
            : (index < currentStepIndex && <CompletedCircleGreenIcon />) ||
              (index === currentStepIndex && <InProgressCircleGreenIcon />) ||
              (index > currentStepIndex && <NotStartedCircleGreyIcon />)}
          <div className="Label">
            <span className="body2">{step}</span>
          </div>
          {index !== steps.length - 1 && (
            <div className={`Divider ${index < currentStepIndex ? "Divider_completed" : "Divider_not-started"}`} />
          )}
        </div>
      ))}
    </div>
  );
};
