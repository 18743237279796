// adapted from https://github.com/ant-design/ant-design/blob/master/components/notification/hooks/useNotification.tsx
import type {
  HolderReadyCallback as RCHolderReadyCallback,
  NoticeContent as RCNoticeContent,
  NotificationInstance as RCNotificationInstance,
} from "rc-notification/lib/Notification";
import useRCNotification from "rc-notification/lib/useNotification";
import * as React from "react";

import type { ArgsProps, NotificationInstance } from "./Toast";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export default function createUseNotification(
  getNotificationInstance: (
    args: ArgsProps,
    callback: (info: { prefixCls: string; instance: RCNotificationInstance }) => void
  ) => void,
  getRCNoticeProps: (args: ArgsProps, prefixCls: string) => RCNoticeContent
): () => NotificationInstance {
  return (): NotificationInstance => {
    // We create a proxy to handle delay created instance
    let innerInstance: RCNotificationInstance | null = null;
    const proxy = {
      add: (noticeProps: RCNoticeContent, holderCallback?: RCHolderReadyCallback) => {
        innerInstance?.component.add(noticeProps, holderCallback);
      },
      // NCU: adapted from Ant Design
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    const [hookNotify] = useRCNotification(proxy);

    const notify = (args: ArgsProps): void => {
      getNotificationInstance(
        {
          ...args,
        },
        ({ prefixCls, instance }) => {
          innerInstance = instance;
          hookNotify(getRCNoticeProps(args, prefixCls));
        }
      );
    };

    // Fill functions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hookApiRef = React.useRef<any>({});

    hookApiRef.current.open = notify;

    ["success", "info", "warning", "error"].forEach((type) => {
      hookApiRef.current[type] = (args: ArgsProps) =>
        hookApiRef.current.open({
          ...args,
          type,
        });
    });

    return hookApiRef.current;
  };
}
