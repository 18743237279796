import { useCallback, useEffect, useState } from "react";

const FOCUSABLE_ELEMENTS =
  'button, [href], input:not([style*="display: none"]), select, textarea, [tabindex]:not([tabindex="-1"]):not(.rc-upload)';

export const useTrapFocusWithinDiv = (): ((node: HTMLDivElement) => void) => {
  const [divEl, setDivEl] = useState<HTMLDivElement>();
  const divElRefCallBack = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setDivEl(node);
    }
  }, []);

  const handleKeyDown = (e: KeyboardEvent): void => {
    const firstFocusableElement = divEl?.querySelectorAll(FOCUSABLE_ELEMENTS)[0]; // get first element to be focused inside divEl
    const focusableContent = divEl?.querySelectorAll(FOCUSABLE_ELEMENTS);
    const lastFocusableElement = focusableContent && focusableContent[focusableContent.length - 1]; // get last element to be focused inside divEl

    const isTabPressed = e.key === "Tab" || e.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement)?.focus(); // add focus for the last focusable element
        e.preventDefault();
      }
    } else if (document.activeElement === lastFocusableElement) {
      // if focused has reached to last focusable element then focus first focusable element after pressing tab
      (firstFocusableElement as HTMLElement)?.focus(); // add focus for the first focusable element
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (divEl) {
      document.addEventListener("keydown", handleKeyDown);
      (divEl?.querySelectorAll(FOCUSABLE_ELEMENTS)[0] as HTMLElement)?.focus();
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [divEl]);

  return divElRefCallBack;
};
