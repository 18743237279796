import { FormEvent, useCallback, useContext, useEffect, useState } from "react";

import { ProjectPermissionConstants } from "../../../../../../constants";
import { DataGridColumnCurrency } from "../../../../../../models";
import { logError } from "../../../../../../service/error";
import { setProjectIssuances, updateIssuancePrices } from "../../../../../../service/project";
import { getProjectIssuances } from "../../../../../../service/query";
import { ResultData, ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { flattenObject, parseDate, useIsLoadingWrapper } from "../../../../../../utils";
import {
  DataGridButtonCellFormatterData,
  DataGridColumnDefinition,
  DataGridInputCellFormatterData,
  DataGridSelectCellFormatterData,
  Toast,
} from "../../../../../../widget";
import { ProjectContext } from "../../ProjectContext";

type Issuance = {
  uuid: string;
  vintageStartDate: Date | null;
  vintageEndDate: Date | null;
  quantity: number | null;
  quantityType: string | null;
  price: string | null;
  verifier?: string;
  rowVersion: number;
};

interface UseIssuancesFormReturnData {
  chartData: { [key: string]: number };
  dataGridColumns: DataGridColumnDefinition[];
  dataGridData: ResultData[];
  isLoading: boolean;
  writeIssuancePermission: boolean;
  errors?: ServiceError[];
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  hasPermission: (permission: string) => boolean;
  isOnSubmitLoading: boolean;
}

export const useIssuancesForm = (): UseIssuancesFormReturnData => {
  const { hasPermission } = useAuth();
  const { projectDetails, isExternalProject, hasProjectPermission } = useContext(ProjectContext);

  const writeIssuancePermission = isExternalProject()
    ? hasProjectPermission(ProjectPermissionConstants.WRITE_PROJECT_ISSUANCES)
    : hasPermission(ProjectPermissionConstants.WRITE_PROJECT_ISSUANCES);

  const readOnly = true;
  // TODO replace "readOnly = true" with commented code below in future
  // !projectDetails?.standardSpecificData?.missingPDD &&
  // projectDetails?.status !== "Under Development" &&
  // projectDetails?.status !== "Draft";

  const dataGridColumns: DataGridColumnDefinition[] = readOnly
    ? [
        {
          name: "Vintage",
          key: "vintage",
          dataType: "string",
          formatter: "align",
          alignment: "left",
        },
        {
          name: "Units",
          key: "quantity",
          dataType: "string",
          formatter: "align",
          alignment: "left",
        },
        {
          name: "Unit type",
          key: "quantityType",
          dataType: "string",
          formatter: "align",
          alignment: "left",
        },
        {
          name: "Verifier",
          key: "verifier",
          dataType: "string",
          alignment: "left",
        },
        {
          name: "Price",
          key: "priceInput",
          currency: DataGridColumnCurrency.Gbp,
          dataType: "string",
          formatter: writeIssuancePermission ? "input" : "currency",
          alignment: "left",
        },
      ]
    : [
        {
          name: "Vintage start",
          key: "vintageStartDateInput",
          dataType: "string",
          formatter: "input",
          alignment: "center",
        },
        {
          name: "Vintage end",
          key: "vintageEndDateInput",
          dataType: "string",
          formatter: "input",
          alignment: "center",
        },
        {
          name: "Type",
          key: "quantityTypeSelect",
          dataType: "string",
          formatter: "select",
          alignment: "center",
        },
        {
          name: "Quantity",
          key: "quantityInput",
          dataType: "string",
          formatter: "input",
          alignment: "center",
        },
        {
          name: "Price",
          key: "price",
          currency: DataGridColumnCurrency.Gbp,
          dataType: "number",
          formatter: "currency",
          alignment: "center",
        },
        {
          name: "",
          key: "clearButton",
          dataType: "string",
          formatter: "button",
          alignment: "center",
        },
      ];

  const [chartData, setChartData] = useState<{ [key: string]: number }>({});
  const [dataGridData, setDataGridData] = useState<ResultData[]>([]);
  const [issuances, setIssuances] = useState<Issuance[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnSubmitLoading, setIsOnSubmitLoading] = useState(false);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  useEffect(() => {
    let projectIssuancesGridData: ResultData[] = [];
    if (readOnly) {
      projectIssuancesGridData = issuances.map((d, index) => {
        const result = flattenObject({
          ...d,
          vintage: `${d.vintageStartDate?.getFullYear()}-${d.vintageEndDate?.getFullYear()}`,
        });

        if (writeIssuancePermission) {
          const priceInput = <DataGridInputCellFormatterData>{
            name: `price_${d.uuid}`,
            type: "number",
            value: d.price?.toString(),
            decimalPlaces: 2,
            onChange: (value) => {
              issuances[index].price = parseFloat(value).toFixed(2);
              priceInput.value = parseFloat(value).toFixed(2);
            },
          };

          result.priceInput = priceInput;
        } else {
          result.priceInput = d.price?.toString();
        }

        return result;
      });
    } else {
      projectIssuancesGridData = issuances.map((i, index) => ({
        vintageStartDate: i.vintageStartDate,
        vintageEndDate: i.vintageEndDate,
        vintageStartDateInput: <DataGridInputCellFormatterData>{
          name: `vintageStartDate_${index}`,
          type: "text",
          value: i.vintageStartDate?.toLocaleDateString("en-GB"),
          placeholder: "dd/mm/yyyy",
          onChange: (value) => {
            issuances[index].vintageStartDate = parseDate(value);
          },
        },
        vintageEndDateInput: <DataGridInputCellFormatterData>{
          name: `vintageEndDate_${index}`,
          type: "text",
          value: i.vintageEndDate?.toLocaleDateString("en-GB"),
          placeholder: "dd/mm/yyyy",
          onChange: (value) => {
            issuances[index].vintageEndDate = parseDate(value);
          },
        },
        quantityTypeSelect: <DataGridSelectCellFormatterData>{
          data: [
            { key: "PIU", value: "PIU" },
            { key: "VCU", value: "VCU" },
          ],
          initialKey: i.quantityType,
          onChange: (value) => {
            issuances[index].quantityType = value;
          },
        },
        quantityInput: <DataGridInputCellFormatterData>{
          name: `quantity_${index}`,
          type: "number",
          value: i.quantity?.toString(),
          onChange: (value) => {
            issuances[index].quantity = parseFloat(value);
          },
        },
        priceInput: <DataGridInputCellFormatterData>{
          name: `price_${index}`,
          type: "number",
          value: i.price?.toString(),
          decimalPlaces: 2,
          onChange: (value) => {
            issuances[index].price = parseFloat(value).toFixed(2);
          },
        },
        clearButton: <DataGridButtonCellFormatterData>{
          text: "Clear",
          variant: "secondary",
          onClick: () => {
            issuances[index] = {
              uuid: "",
              vintageStartDate: null,
              vintageEndDate: null,
              quantity: null,
              quantityType: null,
              price: null,
              rowVersion: 1,
            };
            setIssuances([...issuances]);
          },
        },
      }));
    }

    setDataGridData(projectIssuancesGridData);

    const projectIssuancesChartData = issuances
      .filter((i) => i.vintageStartDate && i.vintageEndDate)
      .reduce((tmp: { [key: string]: number }, value) => {
        const vintageStartDate = value.vintageStartDate as Date;
        const vintageEndDate = value.vintageEndDate as Date;

        const period = `${vintageStartDate.getFullYear()}-${vintageEndDate.getFullYear()}`;

        if (!tmp[period]) {
          // eslint-disable-next-line no-param-reassign
          tmp[period] = 0;
        }

        // eslint-disable-next-line no-param-reassign
        tmp[period] += value.quantity as number;

        return tmp;
      }, {});

    setChartData(projectIssuancesChartData);
  }, [issuances]);

  const fetchData = useCallback(async () => {
    if (!projectDetails) return;

    const res = await getProjectIssuances({
      projectUuid: projectDetails.uuid,
    });

    if (res.status !== Status.Success) throw new Error("getProjectIssuances returned not success status");

    let projectIssuances: Issuance[] = [];

    if (res.data) {
      projectIssuances = res.data.map((d) => ({
        uuid: d.uuid,
        vintageStartDate: d.vintageStartDate,
        vintageEndDate: d.vintageEndDate,
        quantity: d.quantity,
        quantityType: d.quantityType,
        price: d.price?.toFixed(2) ?? null,
        verifier: d.verifier?.displayName || undefined,
        rowVersion: d.rowVersion,
      }));
    }

    if (!readOnly) {
      const newIssuancesLength = 16 - projectIssuances.length;

      for (let i = 0; i < newIssuancesLength; i++) {
        projectIssuances.push({
          uuid: "",
          vintageStartDate: null,
          vintageEndDate: null,
          quantity: null,
          quantityType: null,
          price: null,
          rowVersion: 1,
        });
      }
    }

    setIssuances(projectIssuances);
  }, [projectDetails]);

  useEffect(() => {
    fetchData()
      .catch(async (error) => {
        Toast.error({
          message: "No project issuances could be found for this Uuid",
        });
        await logError({ error });
      })
      .finally(() => setIsLoading(false));
  }, [fetchData]);

  const onSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!projectDetails) return;

    if (readOnly) {
      const issuancePrices = issuances.map((i) => ({
        uuid: i.uuid,
        price: parseFloat(i.price || ""),
        rowVersion: i.rowVersion,
      }));

      const response = await updateIssuancePrices(issuancePrices);

      if (response.status === Status.Success && response.data) {
        Toast.success({ message: "Issuances changed successfully" });
        const updatedIssuances = issuances.map((i) => ({
          ...i,
          rowVersion: response.data?.data?.find((el) => el.uuid === i.uuid)?.rowVersion || i.rowVersion,
        }));
        setIssuances(updatedIssuances);
        setErrors([]);
      }

      if (response.status === Status.Error) {
        const updatedIssuances = issuances.map((i) => ({
          ...i,
          price: issuancePrices.find((el) => el.uuid === i.uuid)?.price.toFixed(2) || i.price,
        }));
        setIssuances(updatedIssuances);
        setErrors(response.errors);
      }
    }

    if (!readOnly) {
      const response = await setProjectIssuances({
        projectUuid: projectDetails.uuid,
        data: issuances
          .filter((i) => Object.values(i).some((value) => !!value))
          .map((i) => ({
            vintageStartDate: i.vintageStartDate,
            vintageEndDate: i.vintageEndDate,
            quantity: i.quantity,
            quantityType: i.quantityType,
            price: parseFloat(i.price || ""),
          })),
      });

      if (response.status === Status.Success && response.data) {
        Toast.success({ message: "Issuances changed successfully" });
        setIssuances([...issuances]);
        setErrors([]);
      }

      if (response.status === Status.Error) {
        setIssuances([...issuances]);
        setErrors(response.errors);
      }
    }
  }, setIsOnSubmitLoading);

  return {
    onSubmit,
    chartData,
    dataGridColumns,
    dataGridData,
    isLoading,
    errors,
    writeIssuancePermission,
    hasPermission: isExternalProject() ? hasProjectPermission : hasPermission,
    isOnSubmitLoading,
  };
};
