import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { OrganisationTypeConstants, ProjectAccessConstants } from "../../../../../../constants";
import { PrivateProjectAccessInviteAcceptParams } from "../../../../../../models";
import { acceptOrRejectProjectOrganisationInvitation } from "../../../../../../service/organisation";
import {
  getProjectOrganisationInvitationDetails,
  GetProjectOrganisationInvitationDetailsResponse,
} from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../../utils";
import { getSettingsProjectAccessRoute } from "../../../../../../utils/routes";
import { Toast } from "../../../../../../widget";

interface UseAcceptInvitationReturnData {
  invitationDetails?: GetProjectOrganisationInvitationDetailsResponse;
  handleBackClick: () => void;
  onAcceptInvitation: () => void;
  onRejectInvitation: () => void;
  hasPermission: (permission: string) => boolean;
  isOnAcceptInvitationLoading: boolean;
  isOnRejectInvitationLoading: boolean;
}

export const useAcceptInvitation = (): UseAcceptInvitationReturnData => {
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const { projectOrganisationInvitationUuid } = useParams<PrivateProjectAccessInviteAcceptParams>();

  const [invitationDetails, setInvitationDetails] = useState<GetProjectOrganisationInvitationDetailsResponse>();

  const [isOnAcceptInvitationLoading, setOnAcceptInvitationLoading] = useState(false);
  const [isOnRejectInvitationLoading, setOnRejectInvitationLoading] = useState(false);

  const getInvitationDetails = useCallback(
    async (invitationUuid: string) => {
      const response = await getProjectOrganisationInvitationDetails({
        projectOrganisationInvitationUuid: invitationUuid,
      });

      if (response.status === Status.Success && response.data) {
        setInvitationDetails(response.data);
      }
    },
    [projectOrganisationInvitationUuid]
  );

  useEffect(() => {
    if (projectOrganisationInvitationUuid) {
      getInvitationDetails(projectOrganisationInvitationUuid);
    }
  }, [projectOrganisationInvitationUuid]);

  const handleBackClick = (): void => {
    navigate(getSettingsProjectAccessRoute(OrganisationTypeConstants.DEVELOPER));
  };

  const onAcceptInvitation = useIsLoadingWrapper(async (): Promise<void> => {
    if (projectOrganisationInvitationUuid && invitationDetails?.rowVersion) {
      const response = await acceptOrRejectProjectOrganisationInvitation({
        projectOrganisationInvitationUuid,
        rowVersion: invitationDetails.rowVersion,
        acceptOrReject: ProjectAccessConstants.INVITATION_RESPONSE.ACCEPT,
      });
      if (response.status === Status.Success) {
        Toast.success({
          message: "The project access invitation has been accepted",
        });
        setInvitationDetails({ ...invitationDetails, calculatedStatus: ProjectAccessConstants.STATUS_ACCEPTED });
      }
      if (response.status === Status.Error && response.errors && response.errors.length) {
        Toast.error({ message: getErrorMessageFromCode(response.errors[0].code) });
      }
    }
  }, setOnAcceptInvitationLoading);

  const onRejectInvitation = useIsLoadingWrapper(async (): Promise<void> => {
    if (projectOrganisationInvitationUuid && invitationDetails?.rowVersion) {
      const response = await acceptOrRejectProjectOrganisationInvitation({
        projectOrganisationInvitationUuid,
        rowVersion: invitationDetails.rowVersion,
        acceptOrReject: ProjectAccessConstants.INVITATION_RESPONSE.REJECT,
      });
      if (response.status === Status.Success) {
        Toast.success({ message: "The project access invitation has been rejected" });
        setInvitationDetails({ ...invitationDetails, calculatedStatus: ProjectAccessConstants.STATUS_REJECTED });
      }
      if (response.status === Status.Error && response.errors && response.errors.length) {
        Toast.error({ message: getErrorMessageFromCode(response.errors[0].code) });
      }
    }
  }, setOnRejectInvitationLoading);

  return {
    invitationDetails,
    handleBackClick,
    onAcceptInvitation,
    onRejectInvitation,
    hasPermission,
    isOnAcceptInvitationLoading,
    isOnRejectInvitationLoading,
  };
};
