import { forwardRef } from "react";
// eslint-disable-next-line import/named
import Select, { StylesConfig } from "react-select";

import { HeaderColumnSelectOption } from "../../models";

interface HeaderColumnSelectProps {
  optionsList: HeaderColumnSelectOption[];
  onOptionClick: (selectedOption: HeaderColumnSelectOption) => void;
  styles: StylesConfig<HeaderColumnSelectOption>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IconOption: (props: any) => JSX.Element | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HeaderColumnSelect = forwardRef<any, HeaderColumnSelectProps>(
  ({ optionsList, onOptionClick, styles, IconOption }: HeaderColumnSelectProps, ref) => {
    return (
      <Select<HeaderColumnSelectOption>
        openMenuOnFocus
        ref={ref}
        isSearchable={false}
        styles={styles}
        menuPortalTarget={document.body}
        options={optionsList}
        components={{ Option: IconOption }}
        onChange={(selectedFilter: HeaderColumnSelectOption | null) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onOptionClick(selectedFilter!);
        }}
      />
    );
  }
);
