import { useCallback, useEffect, useState } from "react";

import { getAuditDataDetails } from "../../service/query/QueryService.full";
import { jsonReviver } from "../../utils";
import { AuditMetaDataFieldType, AuditMetaDataNotesType } from "./types";

interface UseAuditChangesPanelTemplateProps {
  auditDataUuid: string;
}

interface UseAuditChangesPanelTemplateReturnData {
  auditMetaDataNotes?: AuditMetaDataNotesType;
  auditMetaDataFields?: AuditMetaDataFieldType[];
}

const stripExternalQuotationMarks = (value: string): string => {
  return value.charAt(0) === '"' && value.charAt(value.length - 1) === '"' ? value.slice(1, -1) : value;
};

export const useAuditChangesPanelTemplate = ({
  auditDataUuid,
}: UseAuditChangesPanelTemplateProps): UseAuditChangesPanelTemplateReturnData => {
  const [auditMetaDataNotes, setAuditMetaDataNotes] = useState<AuditMetaDataNotesType>();
  const [auditMetaDataFields, setAuditMetaDataFields] = useState<AuditMetaDataFieldType[]>();

  const getAuditMetaData = useCallback(async (): Promise<void> => {
    await getAuditDataDetails({ auditDataUuid }).then((response) => {
      const auditMetaData = JSON.parse(response.data?.metaData, jsonReviver);
      setAuditMetaDataNotes(auditMetaData.notes);
      setAuditMetaDataFields(
        auditMetaData.changedFields.map((changedField: AuditMetaDataFieldType) => ({
          ...changedField,
          afterValue: changedField.afterValue ? stripExternalQuotationMarks(changedField.afterValue) : undefined,
          beforeValue: changedField.beforeValue ? stripExternalQuotationMarks(changedField.beforeValue) : undefined,
        }))
      );
    });
  }, [auditDataUuid]);

  useEffect(() => {
    getAuditMetaData();
  }, [auditDataUuid]);

  return {
    auditMetaDataNotes,
    auditMetaDataFields,
  };
};
