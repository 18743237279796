import "./DashboardPieCard.css";

import { ReactNode } from "react";

import { Card, PieChart } from "../../../../../widget";

export interface DashboardPieCardProps {
  data: { [key: string]: number };
  title: string;
  noDataMessage?: string;
  tooltip?: ReactNode;
}

export const DashboardPieCard = ({ data, title, noDataMessage, tooltip }: DashboardPieCardProps): JSX.Element => {
  return (
    <Card height="100%">
      <div className="AssetManagerDashboardPieCardContainer">
        <div className="AssetManagerDashboardPieCardHeader">
          <p className="body01">{title}</p>
          <div>{tooltip}</div>
        </div>
        <div className="AssetManagerDashboardPieCardContent">
          <PieChart size={120} data={data} noDataMessage={noDataMessage} isGradient />
        </div>
      </div>
    </Card>
  );
};
