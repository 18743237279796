import { Dispatch, SetStateAction, useContext } from "react";
import { useParams } from "react-router-dom";

import { ActivityPageParams } from "../../../../models";
import { ActivityWizardContext } from "../../../developer/activities";
import { ActivityReviewWizardContext } from "../../../verifier/activities";

interface useActivityWizardContextSwitcherReturnData {
  activityUuid: string | undefined;
  activityHistoryUuid: string | undefined;
  activityReviewUuid?: string | undefined;
  currentOrganisationUuid: string | undefined;
  canWriteExternalDiscussion: boolean;

  activityHistoryRowVersion?: number;
  setActivityHistoryRowVersion?: Dispatch<SetStateAction<number>>;
  activityReviewRowVersion?: number;
  setActivityReviewRowVersion?: Dispatch<SetStateAction<number>>;
  refreshActivityWizard: boolean;
  setRefreshActivityWizard: Dispatch<SetStateAction<boolean>>;
}
/**
 * Required for discussions and activityInfo in the review and edit wizards
 */
export const useActivityWizardContextSwitcher = (): useActivityWizardContextSwitcherReturnData => {
  const { activityHistoryUuid: routeActivityHistoryUuid } = useParams<ActivityPageParams.activityHistoryUuid>();
  if (routeActivityHistoryUuid !== undefined) {
    const {
      activityUuid,
      activityHistoryUuid,
      activityDetails,
      isExternalActivity,
      currentOrganisationUuid,

      activityHistoryRowVersion,
      setActivityHistoryRowVersion,
      refreshActivityWizard,
      setRefreshActivityWizard,
    } = useContext(ActivityWizardContext);
    return {
      activityUuid,
      activityHistoryUuid,
      currentOrganisationUuid,
      canWriteExternalDiscussion:
        (!isExternalActivity &&
          (activityDetails === undefined ||
            (activityDetails?.project.verifier?.supportsDigitalVerification &&
              !activityDetails?.activityDefinitionVersion.isManuallyApproved))) ||
        false,
      activityHistoryRowVersion,
      setActivityHistoryRowVersion,
      refreshActivityWizard,
      setRefreshActivityWizard,
    };
  }

  const {
    activityUuid,
    activityReviewUuid,
    activityHistoryUuid,
    currentOrganisationUuid,

    activityReviewRowVersion,
    setActivityReviewRowVersion,
    refreshActivityWizard,
    setRefreshActivityWizard,
  } = useContext(ActivityReviewWizardContext);
  return {
    activityUuid,
    activityReviewUuid,
    activityHistoryUuid,
    currentOrganisationUuid,
    canWriteExternalDiscussion: true,
    activityReviewRowVersion,
    setActivityReviewRowVersion,
    refreshActivityWizard,
    setRefreshActivityWizard,
  };
};
