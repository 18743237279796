import "./RemoveGroupProjectModal.css";

import { ServiceError } from "../../../../../../../../service/Shared";
import { Button, Column, ErrorBox, Modal, Row } from "../../../../../../../../widget";
import { useRemoveGroupProjectModal } from "./useRemoveProjectModal";

interface RemoveGroupProjectModalProps {
  showRemoveProjectModal: boolean;
  closeModal: () => void;
  projectUuid?: string;
}

export const RemoveGroupProjectModal = ({
  showRemoveProjectModal,
  closeModal,
  projectUuid,
}: RemoveGroupProjectModalProps): JSX.Element => {
  const { removeProject, onCancel, errors, isRemoveProjectLoading } = useRemoveGroupProjectModal({
    projectUuid,
    closeModal,
  });

  return (
    <Modal show={showRemoveProjectModal} title="Remove project" onClose={onCancel}>
      {errors && errors.length > 0 && (
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <ErrorBox
              title="Please solve the below issues before saving"
              text={errors.map((error: ServiceError) => error.message)}
            />
          </Column>
        </Row>
      )}
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <p className="body1">
            By editing this group, you agree to oversee the group&apos;s membership manually from this point forward.
            The group&apos;s project membership should continue to align with external registry to guarantee the
            successful completion of activities.
          </p>
        </Column>
      </Row>
      <Row justify="center">
        <div className="RemoveGroupProjectModalButtons">
          <Button onClick={removeProject} text="Remove project" isLoading={isRemoveProjectLoading} />
          <Button onClick={onCancel} text="Cancel" variant="secondary" />
        </div>
      </Row>
    </Modal>
  );
};
