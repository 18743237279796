import { DataGridCellFormatterData } from "../widget";

export enum Status {
  Success = "success",
  Error = "error",
}

export interface BaseResponse {
  errors?: ServiceError[];
  status: Status;
}

export interface BaseResponseWithSingle<T> {
  errors?: ServiceError[];
  status: Status;
  data?: T;
}

export interface BaseResponseWithMultiple<T> {
  errors?: ServiceError[];
  status: Status;
  data?: Array<T>;
}

export interface ServiceError {
  code: string;
  property?: string;
  path?: string;
  parameters?: { [key: string]: string };
  message: string;
}

export interface ResultData {
  [key: string]: string | number | boolean | Date | DataGridCellFormatterData | null | undefined;
}

export interface FilterCriteria<T extends string | number | Date | boolean | string[] | null> {
  operator:
    | "eq"
    | "neq"
    | "gt"
    | "lt"
    | "gte"
    | "lte"
    | "empty"
    | "notEmpty"
    | "startsWith"
    | "endsWith"
    | "contains"
    | "ncontains"
    | "in"
    | "nin";
  value: T;
}

export type SortDirection = "asc" | "desc";

export const errorCodeFormatRegex = /^[A-Z]+(?:_{0,1}[A-Z]+)*$/;

type FilterGroupOperator = "or" | "and";

export type FilterGroups<T> = { filterGroups?: { operator: FilterGroupOperator; filter: T }[] };
