import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { TotalMarketValueTotalReturnsData } from "../../../../models";
import { thousandsFormatter } from "../../../../utils";

interface useTotalMarketValueTotalReturnsKPIReturnData {
  toggleTotalMarketValueTotalReturns: boolean;
  setToggleTotalMarketValueTotalReturns: Dispatch<SetStateAction<boolean>>;
  formattedValue: (string | undefined)[];
}

interface useTotalMarketValueTotalReturnsKPIProps {
  totalMarketValue: TotalMarketValueTotalReturnsData;
  totalReturns: TotalMarketValueTotalReturnsData;
}

export const useTotalMarketValueTotalReturnsKPI = ({
  totalMarketValue,
  totalReturns,
}: useTotalMarketValueTotalReturnsKPIProps): useTotalMarketValueTotalReturnsKPIReturnData => {
  const [toggleTotalMarketValueTotalReturns, setToggleTotalMarketValueTotalReturns] = useState(false);

  const formattedValue = useMemo(() => {
    const valueString = thousandsFormatter.format(
      toggleTotalMarketValueTotalReturns ? totalReturns.value : totalMarketValue.value
    );

    const lastChar = valueString.at(-1);

    // Handle formatting for numbers < 1000
    if (lastChar && lastChar >= "0" && lastChar <= "9") {
      return [valueString];
    }
    return [valueString.slice(0, -1), lastChar];
  }, [toggleTotalMarketValueTotalReturns, totalMarketValue, totalReturns]);

  return {
    toggleTotalMarketValueTotalReturns,
    setToggleTotalMarketValueTotalReturns,
    formattedValue,
  };
};
