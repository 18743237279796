import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../../constants";
import { SelectData } from "../../../../../models";
import { getOrganisationUserDetails, GetOrganisationUserDetailsResponse, lookUp } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { getUserSettingsRoute } from "../../../../../utils/routes";

interface UseEditReturnData {
  pageTitle: string | undefined;
  dataIsLoading: boolean;
  userDetails: GetOrganisationUserDetailsResponse | undefined;
  userUuid: string | undefined;
  roles: SelectData | undefined;
  rolesInfo: string[];
  userSettingsRoute: string;
}

type EditPageRouteParams = "userUuid";

export const useEdit = (): UseEditReturnData => {
  const { userUuid } = useParams<EditPageRouteParams>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<GetOrganisationUserDetailsResponse | undefined>(undefined);
  const [roles, setRoles] = useState<SelectData | undefined>(undefined);

  const userSettingsRoute = getUserSettingsRoute(OrganisationTypeConstants.ASSET_MANAGER);
  const rolesInfo = [
    "AM Lead: Can update organisation details and read all projects. Leads can invite team members and edit all organisation users. There must always be one Lead.",
    "AM Team Member: Can read all projects but cannot update organisation details or invite other users to the the organisation.",
  ];

  const fetchData = useCallback(async () => {
    if (userUuid) {
      const organisationRes = await getOrganisationUserDetails({ userUuid });
      if (organisationRes.status === Status.Success) {
        setUserDetails(organisationRes.data);
      }
    }
    const rolesRes = await lookUp({ type: "AM_Role" });
    if (rolesRes.status === Status.Success) {
      setRoles(
        rolesRes.data?.map((role) => {
          return {
            key: role.key,
            value: role.valueString,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    pageTitle:
      userDetails?.user.firstName && userDetails?.user.lastName
        ? `${userDetails?.user.firstName} ${userDetails?.user.lastName}`
        : undefined,
    dataIsLoading,
    userDetails,
    userUuid,
    roles,
    rolesInfo,
    userSettingsRoute,
  };
};
