import "./StandardSpecificSection.css";

import { Button, Column, Divider, ErrorBox, Row } from "../../../../../../../widget";
import { ProcessComponents, ProcessSteps } from "./components";
import { StandardSpecificDefinition, Step } from "./types";
import { useStandardSpecificSection } from "./useStandardSpecificSection";
import { stepHasDisplayableComponents, stepHasEditableComponents } from "./utils";

interface StandardSpecificSectionProps {
  standardSpecificDefinition: StandardSpecificDefinition;
  isReadOnly: boolean;
}

export const StandardSpecificSection = ({
  standardSpecificDefinition,
  isReadOnly,
}: StandardSpecificSectionProps): JSX.Element => {
  const { data, errors, expressionContext, onSave, onCancel, updateData, isOnSaveLoading } =
    useStandardSpecificSection();
  const filteredSteps = standardSpecificDefinition?.steps?.filter((step: Step) =>
    stepHasDisplayableComponents(step, expressionContext)
  );
  return (
    <>
      {filteredSteps &&
        filteredSteps.map((step: Step, stepIdx: number) => {
          const filteredChildSteps =
            step.steps &&
            step.steps.filter((childStep: Step) => stepHasDisplayableComponents(childStep, expressionContext));
          return (
            <div key={step.key}>
              <Row spacingV="2xl">
                <Column span={12}>
                  <h2>{step.label}</h2>
                </Column>
              </Row>
              {!!errors[step.key]?.length && (
                <Row spacingV="ml">
                  <Column span={4}>
                    <ErrorBox
                      title="Please solve the below issues before saving"
                      text={errors[step.key].map((error) => error.message)}
                      animation={Object.keys(errors)[0] === step.key}
                    />
                  </Column>
                </Row>
              )}
              {filteredChildSteps &&
                filteredChildSteps.length > 0 &&
                ProcessSteps({
                  steps: filteredChildSteps,
                  key: step.key,
                  data,
                  expressionContext,
                  updateData,
                  displayOnlyReadonly: isReadOnly,
                })}
              {step.components &&
                ProcessComponents({
                  components: step.components,
                  key: step.key,
                  expressionContext,
                  updateData,
                  displayOnlyReadonly: isReadOnly,
                })}
              {filteredSteps && stepIdx !== filteredSteps.length - 1 && (
                <Divider type="horizontal" className="StandardSpecificSectionDivider" />
              )}
            </div>
          );
        })}
      {filteredSteps?.some((step: Step) => stepHasEditableComponents(step, expressionContext)) && !isReadOnly && (
        <div className="StandardSpecificButtonContainer">
          <Button text="Save" onClick={onSave} isLoading={isOnSaveLoading} />
          <Button text="Cancel" onClick={onCancel} variant="secondary" />
        </div>
      )}
    </>
  );
};
