import "./Button.css";

import { ReactNode } from "react";

import { LoadingSpinner } from "../..";

type ButtonVariant = "primary" | "reversedPrimary" | "secondary" | "tertiary";
type ButtonSize = "small" | "medium";
type ButtonType = "button" | "submit";
type IconPosition = "left" | "right";

export interface ButtonProps {
  /**
   * The 'type' attribute on the HTML &lt;input&gt; element used to render the button.
   */
  type?: ButtonType;
  /**
   * The button variant
   */
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  /**
   * Should the button take the full width of the container
   */
  fullWidth?: boolean;
  icon?: ReactNode;
  /**
   * The icon position, by default it is set to right
   */
  iconPosition?: IconPosition;
  isLoading?: boolean;
  loadingText?: string;
  onClick?: () => void | Promise<void> | (() => Promise<void>);
  text: string;
  textUnderline?: boolean;
  textBold?: boolean;
  hasNoPadding?: boolean;
  className?: string;
}

export const Button = ({
  type = "button",
  variant = "primary",
  size = "medium",
  disabled = false,
  fullWidth = false,
  icon,
  iconPosition = "right",
  isLoading = false,
  loadingText = "Loading",
  onClick,
  text,
  textUnderline = false,
  textBold = false,
  hasNoPadding = false,
  className = "",
}: ButtonProps): JSX.Element => {
  return (
    <button
      className={`Button Button_${variant} Button_${size} Button_content_${size} ${className}
      ${fullWidth ? "Button_fullWidth" : ""} ${icon ? `Button_${iconPosition}Icon` : ""} ${
        hasNoPadding ? `ButtonNoPadding` : ""
      }${isLoading ? `ButtonLoading` : ""} ${textUnderline ? `ButtonUnderline` : ""} ${textBold ? `ButtonBold` : ""}`}
      type={type}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <div>
          {loadingText}
          <LoadingSpinner type={variant === "primary" ? "light" : "blue"} />
        </div>
      ) : (
        <>
          {icon}
          <span>{text}</span>
        </>
      )}
    </button>
  );
};
