import "./SingleFileDropdownUpload.css";

import Upload from "rc-upload";

import {
  DownloadDarkIcon,
  DropdownLightIcon,
  EyeBlueIcon,
  FileLinesBlueIcon,
  TrashDark,
  UploadBlueIcon,
} from "../../../../assets";
import { PreviewFile } from "../../../../models";
import { GenericModal, GenericModalData } from "../../../../route/developer/components";
import { canPreviewFile } from "../../../../utils";
import { Logo } from "../../../data";
import { Divider, LoadingSpinner } from "../../../general";
import { Button } from "../../Button";
import { FormLabel } from "../../FormLabel";
import { SingleFileUploadPropsV2 } from "../SingleFileUploadV2";
import { useSingleFileDropdownUpload } from "./useSingleFileDropdownUpload";

export interface SingleFileDropdownUploadProps extends SingleFileUploadPropsV2 {
  inputFiles: PreviewFile[];
  maxFileCount: number;
  uploadButtonText?: string;
  deleteButtonText?: string;
  shouldConfirmDeletion?: boolean;
  deleteFileModalData?: GenericModalData;
}

export const SingleFileDropdownUpload = ({
  value,
  label = "Single file dropdown upload",
  allowLabelDangerousHtml,
  btnLabel = "Choose a file",
  maxFileSize = 1,
  informationLabel,
  disabled = false,
  hasPreview = false,
  previewLabel = "Image preview",
  tooltip,
  accept = "*",
  inputFiles,
  maxFileCount = 5,
  uploadButtonText = "Upload a new version",
  deleteButtonText = "Delete upload",
  onFileUpload,
  onChange,
  shouldConfirmDeletion,
  deleteFileModalData,
  onPreviewFile,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  repeaterIndex,
}: SingleFileDropdownUploadProps): JSX.Element => {
  const {
    id,
    selectedFile,
    reusableFiles,
    showDropdown,
    showDeleteFileModal,
    isFileUploading,
    dropdownRef,
    trapFocus,
    handleClick,
    handleKeyDown,
    handleKeyDownFile,
    handleKeyDownOnUploader,
    handleOnDeleteUpload,
    handleKeyDownOnDeleteUpload,
    handleOnPreviewFile,
    onAction,
    reuseFile,
    onConfirmFileDeleteModal,
    onCloseFileDeleteModal,
  } = useSingleFileDropdownUpload({
    value,
    inputFiles,
    onChange,
    onFileUpload,
    onPreviewFile,
    maxFileSize,
    shouldConfirmDeletion,
    repeaterIndex,
  });

  const dropdownContent = (): JSX.Element => (
    <div ref={trapFocus} className="DropdownWrapper">
      <div className="DropdownGroup">
        <p className="body3">Reuse a previously uploaded file</p>
        {reusableFiles.map(
          (el, index) =>
            index + 1 <= maxFileCount && (
              <div key={el.filename} className="DropdownItemWrapper">
                <div
                  className="DropdownItem"
                  role="button"
                  onClick={() => reuseFile(el)}
                  onKeyDown={(e) => handleKeyDownFile(e, el)}
                  tabIndex={0}
                >
                  <FileLinesBlueIcon />
                  <span className="body1">{el.filename}</span>
                </div>
                {onPreviewFile && (
                  <div className="SingleFileDropdownUploadItemPreview">
                    <Divider type="vertical" thickness={1} />
                    <div
                      role="button"
                      className="SingleFileDropdownUploadItemPreviewBtn"
                      onClick={() => onPreviewFile(el)}
                      onKeyDown={(e) => handleOnPreviewFile(e, el)}
                      tabIndex={0}
                    >
                      {canPreviewFile(el.mimeType) ? (
                        <EyeBlueIcon className="EyeBlueIcon" />
                      ) : (
                        <DownloadDarkIcon className="DownloadDarkIcon" />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )
        )}
      </div>
      <Divider type="horizontal" />
      <div className="DropdownGroup">
        <Upload
          className="Upload"
          action={onAction}
          multiple={false}
          accept={accept}
          customRequest={() => null} // Removing default component request by providing an empty one
        >
          <div id={id} tabIndex={0} role="button" className="body1 DropdownItem" onKeyDown={handleKeyDownOnUploader}>
            <UploadBlueIcon />
            {uploadButtonText}
          </div>
        </Upload>

        {selectedFile && (
          <div
            role="button"
            className="DropdownItem"
            onClick={handleOnDeleteUpload}
            onKeyDown={handleKeyDownOnDeleteUpload}
            tabIndex={0}
          >
            <TrashDark className="TrashDarkIcon" />
            <span className="body1">{deleteButtonText}</span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div ref={dropdownRef} className="SingleFileDropdownUpload">
      <div className="SingleFileDropdownUploadContent">
        <FormLabel label={label} tooltip={tooltip} allowDangerousHtml={allowLabelDangerousHtml} />
        <div className="SingleFileDropdownUploadContentInput">
          <div
            className={`form_field SingleFileDropdownUploadInputWrapper ${
              disabled || isFileUploading ? "SingleFileDropdownUploadInputWrapper_disabled" : ""
            }`}
          >
            <div
              className="SingleFileDropdownUploadInput"
              role="button"
              onKeyDown={handleKeyDown}
              onClick={isFileUploading ? undefined : handleClick}
              tabIndex={0}
            >
              <div className="SingleFileDropdownUploadInputFileBox body2">{selectedFile && selectedFile.filename}</div>
              {isFileUploading ? (
                <div className="SingleFileDropdownUploadInputBtn TextWithLoadingSpinner">
                  <p className="body2">Uploading</p>
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="SingleFileDropdownUploadInputBtn">
                  <p className="body2">{btnLabel}</p>
                  <DropdownLightIcon />
                </div>
              )}
            </div>
            <span className="FileSize body2">{informationLabel ?? `Max file size ${maxFileSize}MB`}</span>
          </div>
          {onPreviewFile && selectedFile && !isFileUploading && (
            <div className="SingleFileDropdownUploadPreviewBtn">
              <Button
                variant="tertiary"
                icon={canPreviewFile(selectedFile.mimeType) ? <EyeBlueIcon /> : <DownloadDarkIcon />}
                iconPosition="left"
                text={canPreviewFile(selectedFile.mimeType) ? "Preview file" : "Download file"}
                onClick={() => onPreviewFile(selectedFile)}
              />
            </div>
          )}
        </div>
      </div>
      {showDropdown && dropdownContent()}
      {hasPreview && selectedFile?.url && (
        <div className="SingleFileDropdownUploadPreview">
          <Logo displayedLogo={selectedFile?.url} displayedLabel={previewLabel} />
        </div>
      )}
      {shouldConfirmDeletion && (
        <GenericModal
          show={showDeleteFileModal}
          onConfirm={onConfirmFileDeleteModal}
          closeModal={onCloseFileDeleteModal}
          data={deleteFileModalData}
        />
      )}
    </div>
  );
};
