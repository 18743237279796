import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PublicVerifierDetailsParams } from "../../../models";
import { getPublicProjectOverviewRoute } from "../../../utils/routes";

interface UsePublicVerifierDetailsPageTemplateReturnData {
  verifierUuid: string | undefined;
  navigateBackToProject: () => void;
  showContactUsModal: boolean;
  setShowContactUsModal: Dispatch<SetStateAction<boolean>>;
}

export const usePublicVerifierDetailsPageTemplate = (): UsePublicVerifierDetailsPageTemplateReturnData => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { verifierUuid } = useParams<PublicVerifierDetailsParams>();

  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const navigateBackToProject = (): void => {
    const projectUuid = searchParams.get("projectUuid");
    if (projectUuid) {
      navigate(getPublicProjectOverviewRoute(projectUuid));
    }
  };

  return {
    verifierUuid,
    navigateBackToProject,
    showContactUsModal,
    setShowContactUsModal,
  };
};
