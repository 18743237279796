import "./Repeater.css";

import { ReactNode, useId } from "react";

import { PlusIcon } from "../../../../assets";
import { CreateRowArgs, RepeaterData } from "../../../../models";
import { GenericModal, GenericModalData } from "../../../../route/developer/components";
import { GetDiscussionThreadsResponse } from "../../../../service/query";
import { Button } from "../../Button";
import { useRepeater } from "../hooks";
import { RepeaterRowContent } from "./components";

export interface RepeaterProps {
  maxRows: number;
  initialValue?: RepeaterData[];
  onChange: (value: RepeaterData[]) => void;
  children: ReactNode[];
  addButtonText?: string;
  removeButtonText?: string;
  type?: "single" | "multiple";
  isFixedSize?: boolean;
  shouldConfirmDeletion?: boolean;
  conditionalConfirmDeletionFields?: string[];
  deleteModalData?: GenericModalData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext?: any;
  discussions: GetDiscussionThreadsResponse[] | undefined;
}

export const Repeater = ({
  maxRows,
  initialValue,
  onChange,
  children,
  addButtonText,
  removeButtonText,
  type,
  isFixedSize = false,
  shouldConfirmDeletion,
  conditionalConfirmDeletionFields,
  deleteModalData,
  expressionContext,
  discussions,
}: RepeaterProps): JSX.Element => {
  const id = useId();

  const createRow = ({
    template,
    currentData,
    idx,
    handleRemoveRow,
    createChildrenRecursive,
    currentDiscussions,
  }: CreateRowArgs): ReactNode => {
    return (
      <div key={`RepeaterRowWrapper_${id}_${idx}`}>
        {type === "multiple" ? <hr className="RepeaterRowHr" /> : ""}
        <div className="RepeaterRow" key={`RepeaterRow_${id}_${idx}`}>
          <RepeaterRowContent
            createChildrenRecursive={createChildrenRecursive}
            template={template}
            isFixedSize={isFixedSize}
            currentData={currentData}
            removeButtonText={removeButtonText}
            handleRemoveRow={handleRemoveRow}
            idx={idx}
            discussions={currentDiscussions}
          />
        </div>
      </div>
    );
  };

  const { rows, showDeleteModal, handleAddRow, onConfirmDeleteModal, onCloseDeleteModal } = useRepeater({
    initialValue: initialValue || [],
    template: children,
    maxRows,
    shouldConfirmDeletion,
    conditionalConfirmDeletionFields,
    expressionContext,
    createRow,
    onChange,
    discussions,
  });

  return (
    <div className={`Repeater Repeater_${type || "multiple"}`}>
      {rows}{" "}
      {!isFixedSize && (
        <div className="RepeaterFooter" style={{ visibility: rows.length === maxRows + 1 ? "hidden" : "visible" }}>
          <Button
            text={addButtonText || "Add another"}
            variant="secondary"
            size="small"
            iconPosition="left"
            icon={<PlusIcon />}
            onClick={handleAddRow}
          />
        </div>
      )}
      {shouldConfirmDeletion && (
        <GenericModal
          show={showDeleteModal}
          onConfirm={onConfirmDeleteModal}
          closeModal={onCloseDeleteModal}
          data={deleteModalData}
        />
      )}
    </div>
  );
};
