import { Column, CursorDataGrid, Divider, Row, Select } from "../../../../widget";
import { useDocumentTypes } from "./useDocumentTypes";

const DocumentTypes = (): JSX.Element => {
  const {
    columns,
    documentTypeGroups,
    refreshTypes,
    dataIsLoading,
    selectedDocumentTypeGroup,
    setSelectedDocumentTypeGroup,
    onChange,
  } = useDocumentTypes();
  return (
    <div>
      <Row spacingV="m">
        <Column span={12}>
          <h2>Document types</h2>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <span className="body1">
            Here you&apos;ll find the document types available for use. These are grouped by each standard your projects
            are registered against, along with a ‘General’ group for other use. If there is a document type missing,
            please email support@kana.earth.
          </span>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={4}>
          <Select
            placeholder="Select document type group"
            value={selectedDocumentTypeGroup}
            data={documentTypeGroups || []}
            label="Document type group"
            onChange={(value) => setSelectedDocumentTypeGroup(value)}
          />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <CursorDataGrid
            columns={columns}
            clientSide={false}
            filterable
            onChange={onChange}
            data={[]}
            refresh={refreshTypes}
            noDataMessage="No document types to display"
            dataIsLoading={dataIsLoading}
          />
        </Column>
      </Row>
    </div>
  );
};

export default DocumentTypes;
