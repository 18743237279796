import { OrganisationTypeConstants } from "../../../../../constants";
import kanaExpressionInterpreter from "../../../../../lib/KanaExpressionInterpreter";
import { StepItem, StepState } from "../../../../../models";
import { GetGroupDetailsResponse, GetProjectDetailsResponse } from "../../../../../service/query";
import { StepProps } from "../../../../../widget";
import {
  ActivityData,
  ActivityDefinition,
  ActivityStepData,
  ActivityStepDataItem,
  Component,
  HasComponents,
  HasData,
  HasDataSteps,
  HasDiscussions,
  HasKey,
  HasReviews,
  HasSteps,
  Step,
  UploadedDocumentEnriched,
} from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const arrayEquals = (arr1: any[], arr2: any[]): boolean => {
  if (arr1 == null && arr2 == null) return true;
  if (arr1 === null && arr2 !== null) return false;
  if (arr1 !== null && arr2 === null) return false;
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false; // will only handle primitives; sufficient for this use case
  }
  return true;
};

// Check target stepkeys with length of 2 to make sure they don't have children (wizard UI bugfix)
export const checkTargetStepkeysAppended = (
  targetStepKeys: string[],
  activityDefinition: ActivityDefinition
): string[] => {
  let targetStepKeysAppended = targetStepKeys;
  if (targetStepKeys.length === 2) {
    let curr = activityDefinition as HasSteps & HasComponents & HasKey;
    for (let i = 0; i < targetStepKeys.length; i++) {
      if (curr.steps === undefined)
        throw new Error(`No child steps defined in activity definition for step ${targetStepKeys[i]}`);
      const idx = curr.steps.findIndex((s) => s.key === targetStepKeys[i]);
      curr = curr.steps[idx];
    }
    if (curr.steps !== undefined) {
      [curr] = curr.steps;
      targetStepKeysAppended = [...targetStepKeys, curr.key];
    }
  }
  return targetStepKeysAppended;
};

export const getDataPath = (stepKeys: string[]): string => {
  let s = "";

  for (let i = 0; i < stepKeys.length; i++) {
    s += stepKeys[i];
    if (i < stepKeys.length - 1) s += ".";
  }
  return s;
};

export const getStepNumbers = (stepKeys: string[], activityDefinition: ActivityDefinition): number[] => {
  let curr = activityDefinition as HasSteps;
  const stepNumbers: number[] = [];

  for (let i = 0; i < stepKeys.length; i++) {
    if (curr.steps === undefined)
      throw new Error(`No child steps defined in activity definition for step ${stepKeys[i]}`);
    const idx = curr.steps.findIndex((s) => s.key === stepKeys[i]);
    stepNumbers.push(idx);
    curr = curr.steps[idx];
  }
  return stepNumbers;
};

export const getStepKeys = (stepNumbers: number[], activityDefinition: ActivityDefinition): string[] => {
  const stepKeys: string[] = [];
  let curr = activityDefinition as HasSteps & HasKey & HasComponents;

  for (let i = 0; i < stepNumbers.length; i++) {
    if (curr.steps === undefined)
      throw new Error(`No child steps defined in activity definition for step ${stepKeys[i]}`);
    const child = curr.steps[stepNumbers[i]];
    if (child === undefined)
      throw new Error(`Child step with index ${stepNumbers[i]} not found for parent ${curr.key}`);
    curr = child;
    stepKeys.push(curr.key);
  }
  return stepKeys;
};

export const getStepDefinition = (
  stepKeys: string[],
  activityDefinition: ActivityDefinition
): HasKey & HasSteps & HasComponents => {
  let curr = activityDefinition as HasSteps & HasKey & HasComponents;

  for (let i = 0; i < stepKeys.length; i++) {
    if (curr.steps === undefined)
      throw new Error(`No child steps defined in activity definition for step ${stepKeys[i]}`);
    const child = curr.steps.find((s) => s.key === stepKeys[i]);
    if (child === undefined) throw new Error(`Child step ${stepKeys[i]} not found for parent ${curr.key}`);
    curr = child;
  }
  return curr;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStep = (
  stepKeys: string[],
  activityData: ActivityData
): (HasKey & HasDataSteps & HasData & HasDiscussions & HasReviews) | undefined => {
  let curr = activityData as HasKey & HasDataSteps & HasData & HasReviews;

  for (let i = 0; i < stepKeys.length; i++) {
    if (curr.steps === undefined) return undefined;
    const child = curr.steps.find((s) => s.key === stepKeys[i]);
    if (child === undefined) return undefined;
    curr = child;
  }
  return curr;
};

export const setStepData = (
  stepKeys: string[],
  activityData: ActivityData,
  currentData: ActivityStepDataItem
): ActivityData => {
  const activityDataClone = { ...activityData };
  let curr = activityDataClone as HasKey & HasDataSteps & HasData;

  for (let i = 0; i < stepKeys.length; i++) {
    if (curr.steps === undefined) curr.steps = [];
    let child = curr.steps.find((s) => s.key === stepKeys[i]);
    if (child === undefined) {
      child = { key: stepKeys[i], valid: undefined };
      curr.steps.push(child);
    }
    curr = child;
  }
  if (curr !== undefined) curr.data = currentData;
  return activityDataClone;
};

export const getFirstStepKeys = (activityDefinition: ActivityDefinition): string[] => {
  if (activityDefinition.steps == null || activityDefinition.steps.length === 0)
    throw new Error("Activity definition contains no steps");

  const stepKeys: string[] = [activityDefinition.steps[0].key];
  let curr = activityDefinition.steps[0] as HasKey & HasSteps;

  while (curr.steps !== undefined && curr.steps.length > 0) {
    [curr] = curr.steps;
    stepKeys.push(curr.key);
  }
  return stepKeys;
};

/**
 * Perform a depth-first traversal of an object hierarchy, returning an array of keys
 * that represent the path of traversal through the hierarchy.
 * @param stepKeys An array of string values representing the current path of traversal
 * @param definitionStep An object with `steps` and `components` properties representing a step in the hierarchy
 * @param dataStep An object with `steps` and `valid` properties representing data for the current step
 * @returns An array of string values representing the path of traversal through the hierarchy
 */
export const getNextUnvalidatedStepKeys = (
  stepKeys: string[],
  definitionStep: HasSteps & HasKey & HasComponents,
  dataStep?: HasKey & HasDataSteps & HasData
): string[] => {
  // Traverse child steps recursively
  if (definitionStep.steps) {
    // Loop through each child step in the `steps` array
    // eslint-disable-next-line no-restricted-syntax
    for (const childDefinitionStep of definitionStep.steps) {
      // Find the corresponding child data step, if any
      const childDataStep = dataStep?.steps?.find((s) => s.key === childDefinitionStep.key);

      // Recursively call `getNextUnvalidatedStepKeys` on the child step
      const result = definitionStep.key
        ? getNextUnvalidatedStepKeys([...stepKeys, definitionStep.key], childDefinitionStep, childDataStep)
        : getNextUnvalidatedStepKeys([...stepKeys], childDefinitionStep, childDataStep);

      if (result.length > 0) {
        return result;
      }
    }
  }
  // If we've reached a step with `components` and no `valid` data, return `stepKeysClone`
  else if (dataStep?.valid === undefined || !dataStep?.valid) {
    return [...stepKeys, definitionStep.key];
  }

  return [];
};

export const getNextStepKeys = (stepKeys: string[], activityDefinition: ActivityDefinition): string[] => {
  const step = getStepDefinition(stepKeys, activityDefinition);
  const stepNumbers = getStepNumbers(stepKeys, activityDefinition);
  let targetStepNumbers = stepNumbers.slice(); // clone array

  if (step.parent === undefined)
    // root element; no next steps, return self
    return stepKeys;
  if (step.parent.steps === undefined)
    throw new Error(`No child steps defined in activity definition for step ${getDataPath(stepKeys)}`);
  let curr = step as HasKey & HasSteps;
  let idx = stepNumbers.length - 1;

  while (curr.parent != null) {
    if (curr.parent.steps === undefined)
      throw new Error(`No child steps defined in activity definition for step ${getDataPath(stepKeys.slice(0, idx))}`);
    if (curr.parent.steps.length > stepNumbers[idx] + 1) {
      targetStepNumbers[idx] = stepNumbers[idx] + 1;
      targetStepNumbers = targetStepNumbers.slice(0, idx + 1);
      let childSteps = curr.parent.steps[targetStepNumbers[idx]].steps;
      while (childSteps !== undefined && childSteps.length > 0) {
        targetStepNumbers.push(0); // next step has children
        childSteps = childSteps[0].steps;
      }
      break;
    }
    curr = curr.parent;
    idx -= 1;
  }
  return getStepKeys(targetStepNumbers, activityDefinition);
};

export const getLastStepKeys = (activityDefinition: ActivityDefinition): string[] => {
  let curr: Step | undefined = activityDefinition.steps?.[activityDefinition.steps.length - 1];
  if (curr == null) return [];
  const res: string[] = [curr.key];

  while (curr.steps != null) {
    curr = curr.steps[curr.steps.length - 1];
    res.push(curr.key);
  }
  return res;
};

export const hasNextStep = (stepKeys: string[], activityDefinition: ActivityDefinition): boolean => {
  const ret = getNextStepKeys(stepKeys, activityDefinition);
  return !arrayEquals(ret, stepKeys);
};

export const getPreviousStepKeys = (stepKeys: string[], activityDefinition: ActivityDefinition): string[] => {
  const step = getStepDefinition(stepKeys, activityDefinition);
  const stepNumbers = getStepNumbers(stepKeys, activityDefinition);
  let targetStepNumbers = stepNumbers.slice(); // clone array

  if (step.parent === undefined)
    // root element; no next steps, return self
    return stepKeys;
  if (step.parent.steps === undefined)
    throw new Error(`No child steps defined in activity definition for step ${getDataPath(stepKeys)}`);
  let curr = step as HasKey & HasSteps;
  let idx = stepNumbers.length - 1;

  while (curr.parent != null) {
    if (curr.parent.steps === undefined)
      throw new Error(`No child steps defined in activity definition for step ${getDataPath(stepKeys.slice(0, idx))}`);
    if (stepNumbers[idx] > 0) {
      targetStepNumbers[idx] = stepNumbers[idx] - 1;
      targetStepNumbers = targetStepNumbers.slice(0, idx + 1);
      let childSteps = curr.parent.steps[targetStepNumbers[idx]].steps;
      while (childSteps !== undefined && childSteps.length > 0) {
        targetStepNumbers.push(childSteps.length - 1); // next step has children
        childSteps = childSteps[childSteps.length - 1].steps;
      }
      break;
    }
    curr = curr.parent;
    idx -= 1;
  }
  return getStepKeys(targetStepNumbers, activityDefinition);
};

export const hasPreviousStep = (stepKeys: string[], activityDefinition: ActivityDefinition): boolean => {
  const ret = getPreviousStepKeys(stepKeys, activityDefinition);
  return !arrayEquals(ret, stepKeys);
};

export const populateParentLinks = (step: HasKey & HasSteps): void => {
  if (step.steps === undefined) return;

  for (let i = 0; i < step.steps.length; i++) {
    // eslint-disable-next-line no-param-reassign
    step.steps[i].parent = step;
    populateParentLinks(step.steps[i]);
  }
};

export const getActivityDefinitionFields = (
  stepKeys: string[],
  activityDefinition: ActivityDefinition
): Component[] => {
  let curr = activityDefinition as HasSteps & HasComponents & HasKey;

  for (let i = 0; i < stepKeys.length; i++) {
    if (curr.steps === undefined)
      throw new Error(`No child steps defined in activity definition for step ${stepKeys[i]}`);
    const idx = curr.steps.findIndex((s) => s.key === stepKeys[i]);
    curr = curr.steps[idx];
  }
  if (curr.components === undefined)
    throw new Error(`No child components defined in activity definition for step '${curr.key || "root"}'`);
  return curr.components;
};

/* Step.valid value meanings in dev wizards
 * true - valid - green
 * false - invalid - red
 * undefined - default - grey
 * null - warning (vvb marked invalid step | ONLY FOR DEV) - yellow
 */ export const mapToStepProps = (
  stepKeys: string[],
  activityData: ActivityData,
  currentUserType: OrganisationTypeConstants,
  steps?: Step[],
  isReview?: boolean
): StepProps[] => {
  return (
    steps?.map((s: Step) => {
      const step = getStep([s.key], activityData);
      let isStepValid;

      if (currentUserType === OrganisationTypeConstants.DEVELOPER) {
        // eslint-disable-next-line no-nested-ternary
        isStepValid = s.steps?.length
          ? undefined
          : step?.activityReviews?.review?.isValid === false || step?.activityReviews?.assessment?.isValid === false
          ? null
          : step?.valid;
      } else {
        isStepValid = s.steps?.length ? undefined : step?.valid;
      }

      return {
        key: s.key,
        stepHeader: s.label,
        current: s.key === stepKeys[0] && !isReview,
        valid: isStepValid,
        subSteps:
          s.steps?.map((c: Step) => {
            const subStep = getStep([s.key, c.key], activityData);
            let isSubStepValid;

            if (currentUserType === OrganisationTypeConstants.DEVELOPER) {
              // eslint-disable-next-line no-nested-ternary
              isSubStepValid =
                subStep?.activityReviews?.review?.isValid === false ||
                subStep?.activityReviews?.assessment?.isValid === false
                  ? null
                  : subStep?.valid;
            } else {
              isSubStepValid = subStep?.valid;
            }

            let inProgress = false;

            if (c.steps !== undefined) {
              isSubStepValid = undefined;
              const validationStatuses = c.steps.map((sss) => getStep([s.key, c.key, sss.key], activityData));

              if (validationStatuses.some((el) => el?.valid !== undefined)) {
                inProgress = true;
              }

              if (validationStatuses.every((el) => el?.valid === true)) {
                isSubStepValid = true;
              }

              if (validationStatuses.some((el) => el?.valid === false)) {
                isSubStepValid = false;
              }

              if (
                currentUserType === OrganisationTypeConstants.DEVELOPER &&
                validationStatuses.some(
                  (el) =>
                    el?.activityReviews?.review?.isValid === false || el?.activityReviews?.assessment?.isValid === false
                )
              ) {
                isSubStepValid = null;
              }
            }

            return {
              key: c.key,
              current: s.key === stepKeys[0] && c.key === stepKeys[1],
              stepName: c.label,
              valid: isSubStepValid,
              inProgress,
            };
          }) || [],
      };
    }) || []
  );
};

export const mapToStepItem = (
  stepKeys: string[],
  activityData: ActivityData,
  currentUserType: OrganisationTypeConstants,
  steps?: Step[]
): StepItem[] | undefined => {
  const cs = steps?.find((s) => s.key === stepKeys[0]);
  const css = cs?.steps?.find((s) => s.key === stepKeys[1]);
  const sssSteps = css?.steps;

  const res = sssSteps?.map((s) => {
    const l3Step = getStep([cs?.key || "", css?.key || "", s.key], activityData);

    let isValid;
    if (currentUserType === OrganisationTypeConstants.DEVELOPER) {
      isValid =
        l3Step?.activityReviews?.review?.isValid === false || l3Step?.activityReviews?.assessment?.isValid === false
          ? null
          : l3Step?.valid;
    } else {
      isValid = l3Step?.valid;
    }

    const isCurrent = stepKeys.length === 3 && stepKeys[2] === s.key;

    let stepState = StepState.NotStarted;

    if (isValid) {
      stepState = StepState.Complete;
    } else if (isValid === false) {
      stepState = StepState.Invalid;
    } else if (isValid === null && currentUserType === OrganisationTypeConstants.DEVELOPER) {
      stepState = StepState.Warning;
    } else if (isCurrent) {
      stepState = StepState.InProgress;
    }

    return {
      key: s.key,
      stepName: s.label,
      stepState,
    };
  });

  // only show the L3 stepper if there are more than 1 steps
  if (res?.length === 1) return undefined;

  return res;
};

export const getAllComponents = (activityDefinition: HasSteps & HasComponents): Component[] => {
  const components: Component[] = [];
  if (activityDefinition.components && activityDefinition.components.length) {
    components.push(...activityDefinition.components);
    activityDefinition.components.forEach((c) => {
      if (c.children && c.children.length) components.push(...c.children);
    });
  } else if (activityDefinition.steps && activityDefinition.steps.length) {
    activityDefinition.steps.forEach((item) => {
      components.push(...getAllComponents(item));
    });
  }
  return components;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const constructActivityContext = (activityData: ActivityData): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = {};
  if (activityData.data !== undefined) {
    Object.keys(activityData.data).forEach((key) => {
      if (!activityData.data || activityData.data[key] === undefined) return;
      const val = activityData.data[key];
      if (val?.children) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const repeaterValues: any[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        val.children.forEach((repeaterItem: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const repeaterValue: any = {};
          Object.keys(repeaterItem).forEach((rk) => {
            repeaterValue[rk] = repeaterItem[rk];
          });
          repeaterValues.push(repeaterValue);
        });
        res[key] = repeaterValues;
      } else {
        res[key] = val;
      }
    });
  } else if (activityData.steps && activityData.steps.length) {
    activityData.steps.forEach((item) => {
      res[item.key] = constructActivityContext(item);
    });
  }
  return res;
};

export const filterConditionalStep = (
  step: Step,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any,
  parent?: Step | ActivityDefinition
): Step | undefined => {
  if (step?.conditionExpression && !kanaExpressionInterpreter(step.conditionExpression, context)) return undefined;
  const result: Step = {
    key: step.key,
    label: step.label,
  };
  if (step.conditionExpression) result.conditionExpression = step.conditionExpression;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (parent !== undefined) result.parent = parent as any;

  if (step.components) {
    result.components ||= [];
    result.components.push(...step.components);
  } else if (step.steps) {
    result.steps ||= [];
    step.steps.forEach((item) => {
      const childResult = filterConditionalStep(item, context, result);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (childResult !== undefined) result.steps!.push(childResult);
    });
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterConditionalSteps = (activityDefinition: ActivityDefinition, context: any): ActivityDefinition => {
  const result: ActivityDefinition = {};
  if (!activityDefinition.steps) return result;
  result.steps = activityDefinition.steps
    .map((step) => filterConditionalStep(step, context, result))
    .filter((s) => s !== undefined) as Step[];
  return result;
};

export const createExpressionContext = (
  activityData: ActivityData,
  projectDetails: GetProjectDetailsResponse | undefined,
  groupDetails: GetGroupDetailsResponse | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  return { activity: constructActivityContext(activityData), project: projectDetails, group: groupDetails };
};

/*
 filters the list of documents to those that apply to the current step (including any documents of the same type,
 already uploaded to previous steps)
 */
export const getStepDocuments = (
  stepKeys: string[],
  activityData: ActivityData,
  stepComponents: Component[],
  documents: UploadedDocumentEnriched[]
): { updatedActivityData: ActivityData; stepDocuments: UploadedDocumentEnriched[] } => {
  const stepData: ActivityStepDataItem = getStep(stepKeys, activityData)?.data || {};
  const singleFileComponents = stepComponents.filter((c) => c.component === "SingleFileUpload");
  const multiFileComponents = stepComponents.filter((c) => c.component === "MultiFileUpload");
  const multiFileComponentKeys = multiFileComponents.map((mfc) => mfc.key);
  let res: UploadedDocumentEnriched[] = [];
  singleFileComponents.forEach((sfc) => {
    let activityDefinitionVersionDocumentTypeUuidAndVariant: string | undefined;
    if (stepData[sfc.key]) {
      // if the document has documentVariantSource (of any type) - DON'T allow re-use from a previous step
      activityDefinitionVersionDocumentTypeUuidAndVariant = stepData[sfc.key];
    } else if (sfc.componentProperties.documentVariantSource === undefined) {
      // if the document has no variant - allow re-use from a previous step
      const { activityDefinitionVersionDocumentTypeUuid } = sfc.componentProperties;
      if (activityDefinitionVersionDocumentTypeUuid === undefined)
        throw new Error(
          `activityDefinitionVersionDocumentTypeUuid missing from componentProperties for component with key '${sfc.key}', stepKeys '${stepKeys}'`
        );
      activityDefinitionVersionDocumentTypeUuidAndVariant = `${activityDefinitionVersionDocumentTypeUuid}|`;
    }
    const foundDocument = documents.find(
      (d) =>
        d.activityDefinitionVersionDocumentTypeUuidAndVariant === activityDefinitionVersionDocumentTypeUuidAndVariant
    );
    if (foundDocument) {
      res.push(foundDocument);
      stepData[sfc.key] = foundDocument.activityDefinitionVersionDocumentTypeUuidAndVariant; // mutate state
    }
  });
  // multi-file uploads always have a documentVariantSource -> no need for extra logic
  let uploadedDocumentValues: string[] = [];
  const multiFileDataKeys = Object.keys(stepData).filter((k) => multiFileComponentKeys.includes(k));
  uploadedDocumentValues = uploadedDocumentValues.concat(multiFileDataKeys.map((mfdk) => stepData[mfdk]).flat());
  // handle single file upload components in repeaters (but not multi-file uploads)
  const repeatersWithSingleFileComponents = stepComponents.filter(
    (c) =>
      c.children != null &&
      c.children.length > 0 &&
      c.children.findIndex((cc) => cc.component === "SingleFileUpload") > -1
  );
  repeatersWithSingleFileComponents.forEach((r) => {
    const repeaterData = stepData[r.key];
    if (repeaterData === undefined) return;
    const singleFileComponentKeys =
      r.children?.filter((cc) => cc.component === "SingleFileUpload").map((cc) => cc.key) || [];
    for (let i = 0; i < repeaterData.length; i++) {
      for (let j = 0; j < singleFileComponentKeys.length; j++) {
        const activityDefinitionVersionDocumentTypeUuidAndVariant = repeaterData[i][singleFileComponentKeys[j]];
        const foundDocument = documents.find(
          (d) =>
            d.activityDefinitionVersionDocumentTypeUuidAndVariant ===
            activityDefinitionVersionDocumentTypeUuidAndVariant
        );
        if (foundDocument) {
          res.push(foundDocument);
        }
      }
    }
  });

  // handle multiFile upload components in repeaters
  const repeatersWithMultiFileComponents = stepComponents.filter(
    (c) =>
      c.children != null &&
      c.children.length > 0 &&
      c.children.findIndex((cc) => cc.component === "MultiFileUpload") > -1
  );

  repeatersWithMultiFileComponents.forEach((r) => {
    const repeaterData = stepData[r.key];

    if (repeaterData === undefined) return;

    const repeaterMultiFileComponentKeys =
      r.children?.filter((cc) => cc.component === "MultiFileUpload").map((cc) => cc.key) || [];

    for (let i = 0; i < repeaterData.length; i++) {
      for (let j = 0; j < repeaterMultiFileComponentKeys.length; j++) {
        const activityDefinitionVersionDocumentTypeUuidAndVariants =
          repeaterData[i][repeaterMultiFileComponentKeys[j]] || [];

        for (let index = 0; index < activityDefinitionVersionDocumentTypeUuidAndVariants.length; index++) {
          const foundDocument = documents.find(
            (d) =>
              d.activityDefinitionVersionDocumentTypeUuidAndVariant ===
              activityDefinitionVersionDocumentTypeUuidAndVariants[index]
          );

          if (foundDocument) {
            res.push(foundDocument);
          }
        }
      }
    }
  });

  res = res.concat(
    // KAN-2918: remove uploaded files
    ...documents.filter(
      (d) =>
        uploadedDocumentValues.includes(d.activityDefinitionVersionDocumentTypeUuidAndVariant) ||
        // add in any files which have been deleted. These won't be included above, as they are no longer present in the stepData.
        (d.activityDocumentHistoryUuid === null &&
          res.findIndex(
            (r) =>
              r.activityDefinitionVersionDocumentTypeUuidAndVariant ===
              d.activityDefinitionVersionDocumentTypeUuidAndVariant
          ) === -1)
    )
  );

  // filtering duplicates out
  res = res.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (el) =>
          el.activityDefinitionVersionDocumentTypeUuidAndVariant ===
          value.activityDefinitionVersionDocumentTypeUuidAndVariant
      )
  );

  // reordering based on documents
  res.sort((a, b) => {
    const indexA = documents.findIndex(
      (item) =>
        item.activityDefinitionVersionDocumentTypeUuidAndVariant ===
        a.activityDefinitionVersionDocumentTypeUuidAndVariant
    );
    const indexB = documents.findIndex(
      (item) =>
        item.activityDefinitionVersionDocumentTypeUuidAndVariant ===
        b.activityDefinitionVersionDocumentTypeUuidAndVariant
    );

    return indexA - indexB;
  });

  const updatedActivityData = setStepData(stepKeys, activityData, stepData);
  return { stepDocuments: res, updatedActivityData };
};

/**
 * Perform a depth-first traversal of an object hierarchy, returning an array of keys
 * that represent the path of traversal to a given document.
 * @param activityDefinition An object with `steps` and `componenets` properties representing the activity definition
 * @param documentUuid The UUID of the document to find
 * @returns An array of string values representing the path of traversal through the hierarchy
 */
export const getStepKeysFromDocument = (activityDefinition: ActivityDefinition, documentUuid: string): string[] => {
  // Define a const that will determine if a component is the document's corresponding component
  const hasDocument = (c: Component): boolean => {
    return c.dataType === "File" && c.componentProperties.activityDefinitionVersionDocumentTypeUuid === documentUuid;
  };

  const findStepKeys = (stepKeys: string[], step: Step): string[] => {
    // Traverse child steps recursively
    if (step.components) {
      // Find the corresponding component, if any
      const foundComponent = step.components.find(
        (c) =>
          ((c.component === "Repeater" || c.component === "TableRepeater") &&
            c.children?.some((rc) => hasDocument(rc))) ||
          hasDocument(c)
      );
      // If we've found the component, return the step keys
      if (foundComponent) {
        return [...stepKeys, step.key];
      }
    } else if (step.steps) {
      // Loop through each child step in the `steps` array
      // eslint-disable-next-line no-restricted-syntax
      for (const childStep of step.steps) {
        // Recursively call `findStepKeys` on the child step
        const result = findStepKeys([...stepKeys, step.key], childStep);
        if (result.length > 0) {
          return result;
        }
      }
    }
    return [];
  };

  if (activityDefinition.steps) {
    // Loop through each top-level step in the `activityDefinition`
    // eslint-disable-next-line no-restricted-syntax
    for (const topLevelStep of activityDefinition.steps) {
      // Recursively call `findStepKeys` on the top-level step
      const result = findStepKeys([], topLevelStep);
      if (result.length > 0) {
        return result;
      }
    }
  }
  return [];
};

export const findCurrentStepName = (steps: StepProps[]): string | null => {
  const currentStep = steps.find((step) => step.current || step.subSteps.some((subStep) => subStep.current));
  if (!currentStep) return null;

  const currentSubStep = currentStep.subSteps.find((subStep) => subStep.current);
  return currentSubStep ? currentSubStep.stepName : currentStep.stepHeader;
};

// Checks if a step is valid by checking all the subSteps recursively
const isStepValid = (obj: ActivityStepData): boolean => {
  if (obj.valid === false) {
    return false;
  }

  if (obj.steps) {
    return obj.steps.every((item) => isStepValid(item));
  }

  return !!obj.valid;
};

export const isActivityDataValid = (steps?: ActivityStepData[]): boolean => {
  return steps ? steps.every((item) => isStepValid(item)) : false;
};

export const isNestedStepInvalid = (currentStepKeys: string[], obj: ActivityStepData): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let stepData = obj.steps as any;

  for (let i = 0; i < currentStepKeys.length; i++) {
    if (i < currentStepKeys.length - 1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      stepData = stepData?.find((object: any) => object.key === currentStepKeys[i])?.steps;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      stepData = stepData?.find((object: any) => object.key === currentStepKeys[i]);
    }
  }

  return stepData?.valid === false;
};
