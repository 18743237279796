import "./ManageProjectGroup.css";

import { ArrowFullLeftDarkIcon } from "../../../../../../assets";
import { GroupConstants } from "../../../../../../constants";
import { DeveloperTemplate } from "../../../../../../templates";
import { Button, Column, ErrorBox, InformationBox, Row, UserHeader } from "../../../../../../widget";
import { OverviewSection } from "./components/OverviewSection";
import { ProjectsSection } from "./components/ProjectsSection";
import { useManageProjectGroup } from "./useManageProjectGroup";

export const ManageProjectGroup = (): JSX.Element => {
  const { groupDetails, backButtonText, errors, handleBackClick } = useManageProjectGroup();

  return (
    <DeveloperTemplate>
      <div className="ManageProjectGroup">
        {handleBackClick ? (
          <div className="HeaderContainer">
            <Button
              text={backButtonText}
              variant="secondary"
              icon={<ArrowFullLeftDarkIcon />}
              iconPosition="left"
              onClick={handleBackClick}
            />
            <UserHeader />
          </div>
        ) : (
          <UserHeader />
        )}
        <Row spacingV="ll">
          <Column span={12}>
            <h1>Manage Project Group</h1>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={12}>
              <ErrorBox text={errors.map((error) => error.message)} />
            </Column>
          </Row>
        )}
        {groupDetails?.registryMatch === GroupConstants.REGISTRY_MISMATCH && (
          <Row spacingV="ll">
            <Column span={12}>
              <InformationBox variant="info">
                <p>
                  We have detected that this group does not match what is recorded on the external registry. Please
                  update the project membership in order to match the registry.
                </p>
              </InformationBox>
            </Column>
          </Row>
        )}
        {errors && errors.length === 0 && (
          <>
            <OverviewSection />
            <ProjectsSection />
          </>
        )}
      </div>
    </DeveloperTemplate>
  );
};
