import "./AboutTheCodeSection.css";

import { RightArrowOrangeIcon } from "../../../../../../assets";
import { getPublicCodeDetailsRoute } from "../../../../../../utils/routes";
import { Column, Row } from "../../../../../../widget";
import { ImageButton } from "../../../../../../widget/layout/ExpandableCard/components";
import { useAboutTheCodeSection } from "./useAboutTheCodeSection";

interface AboutTheCodeSectionProps {
  organisationUuid: string;
  projectUuid: string;
  isPreview?: boolean;
  isCodeCompliant: boolean;
}

export const AboutTheCodeSection = ({
  organisationUuid,
  projectUuid,
  isPreview = false,
  isCodeCompliant,
}: AboutTheCodeSectionProps): JSX.Element => {
  const { dataIsLoading, codeDetailsData } = useAboutTheCodeSection(organisationUuid);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!dataIsLoading && codeDetailsData && (
        <div className="AboutTheCodeSection">
          <Row spacingV="ll">
            <Column span={12}>
              <div className="AboutTheCodeHeader">
                <h2>About the {isCodeCompliant ? codeDetailsData?.displayName : "code"}</h2>
                {!isPreview && isCodeCompliant && (
                  <ImageButton
                    icon={<RightArrowOrangeIcon />}
                    route={`${getPublicCodeDetailsRoute(organisationUuid)}?projectUuid=${projectUuid}`}
                    linkText="Learn more about the code"
                  />
                )}
              </div>
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={12}>
              <img className="CodeLogo" src={codeDetailsData?.logo || undefined} alt="Code logo" />
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <p className="body1">
                {isCodeCompliant
                  ? codeDetailsData?.subtitle
                  : `This project hasn't been approved by a code yet, but is hoping to be verified against the ${codeDetailsData?.displayName}'s quality assurance standard for ${codeDetailsData?.projectType} projects in the UK`}
              </p>
            </Column>
          </Row>
          {/* TODO Maybe re-add this in the future. Removed as per KAN-3217 */}
          {/* {isCodeCompliant && (
            <>
              <Row spacingV="l">
                <Column span={12}>
                  {codeDetailsData?.ukEnvironmentalReporting ? (
                    <div className="ReportingStandards True">
                      <SuccessIcon />
                      <h3>
                        Approved for net emissions reporting under the UK&apos;s Environmental Reporting Guidelines
                      </h3>
                    </div>
                  ) : (
                    <div className="ReportingStandards False">
                      <CrossCircleDarkIcon />
                      <h3>
                        Not approved for net emissions reporting under the UK&apos;s Environmental Reporting Guidelines
                      </h3>
                    </div>
                  )}
                </Column>
              </Row>
              {codeDetailsData?.codeCharacteristics && (
                <>
                  <Row spacingV="l">
                    <Column span={12}>
                      <Divider type="horizontal" />
                    </Column>
                  </Row>
                  <Row spacingV="l">
                    <Column span={12}>
                      <CodeStatusKPI codeCharacteristics={codeDetailsData.codeCharacteristics} isProjectPage />
                    </Column>
                  </Row>
                </>
              )}
            </>
          )} */}
        </div>
      )}
    </>
  );
};
