import "./TotalCarbonImpactKPI.css";

import { useMemo } from "react";

import { ComponentProperties } from "../../../../route/shared/projects/project/overview/components/StandardSpecificSection/types";
import { thousandsFormatter } from "../../../../utils";
import { KPITooltip } from "../components";

interface TotalSequestrationProps {
  carbonImpactType: ComponentProperties | undefined;
  carbonImpactValue: number;
}

const getProjectSize = (totalSequestration: number): string => {
  if (totalSequestration > 10000) return "Large";
  if (totalSequestration > 2500) return "Medium";
  if (totalSequestration >= 0) return "Small";
  return "";
};

export const TotalCarbonImpactKPI = ({ carbonImpactType, carbonImpactValue }: TotalSequestrationProps): JSX.Element => {
  const steps = useMemo(
    () => [
      {
        tooltipText: "Large",
        offsetWidth: 55,
        offsetHeight: -120,
      },
      {
        tooltipText: "Medium",
        offsetWidth: 45,
        offsetHeight: -95,
      },
      {
        tooltipText: "Small",
        offsetWidth: 25,
        offsetHeight: -70,
      },
    ],
    []
  );

  const totalCarbonImpactValueFormatted = thousandsFormatter.format(carbonImpactValue).toLowerCase();

  return (
    <div className="TotalSequestrationContainer">
      <div className={`TotalSequestrationGraph ${getProjectSize(carbonImpactValue)}`}>
        {steps.map((step) => {
          return (
            <KPITooltip
              key={step.tooltipText}
              text={step.tooltipText}
              offsetWidth={step.offsetWidth}
              offsetHeight={step.offsetHeight}
            >
              <div className={`GraphSection ${step.tooltipText}`} />
            </KPITooltip>
          );
        })}
      </div>
      <div className="TotalSequestrationHeaderArea">
        {carbonImpactValue !== undefined && carbonImpactValue !== null && carbonImpactType ? (
          <h2>
            {totalCarbonImpactValueFormatted} {carbonImpactType.suffix}
          </h2>
        ) : (
          <h2>N/A</h2>
        )}
        {carbonImpactType && <p className="body2">{carbonImpactType.label}</p>}
        {carbonImpactValue && <p className="body3">{getProjectSize(carbonImpactValue)} size project</p>}
      </div>
    </div>
  );
};
