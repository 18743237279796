import "./FormLabel.css";

import { ReactNode } from "react";

export interface FormLabelProps {
  label?: string;
  labelAlignment?: "left" | "right" | "top" | "bottom";
  optional?: boolean;
  tooltip?: ReactNode;
  tooltipJustify?: "left" | "right";
  className?: string;
  children?: ReactNode;
  allowDangerousHtml?: boolean;
  htmlFor?: string;
}

export const FormLabel = ({
  label,
  labelAlignment = "top",
  optional,
  tooltipJustify = "right",
  tooltip,
  className,
  children,
  allowDangerousHtml = false,
  htmlFor,
}: FormLabelProps): JSX.Element => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`Label Label-${labelAlignment}`} htmlFor={htmlFor}>
      {label && (
        <div className={`LabelValue LabelValue-${tooltipJustify}`}>
          {allowDangerousHtml ? (
            <div className={`HtmlDiv ${className ?? ""}`}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: label }} />
              {optional && <span className="body2 Optional">(optional)</span>}
            </div>
          ) : (
            <div className={className}>
              {label}
              {optional && <span className="body2 Optional">(optional)</span>}
            </div>
          )}
          {tooltip}
        </div>
      )}
      {children}
    </label>
  );
};
