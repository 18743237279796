import { PublicProjectTypeTemplate, PublicTemplate } from "../../../templates";
import { useProjectType } from "./useProjectType";

const ProjectType = (): JSX.Element => {
  const { dataIsLoading, projectTypeData } = useProjectType();

  return (
    <PublicTemplate activeElement="Project types" isContentUnwrapped>
      {!dataIsLoading && projectTypeData && <PublicProjectTypeTemplate projectTypeData={projectTypeData} />}
    </PublicTemplate>
  );
};

export default ProjectType;
