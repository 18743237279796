import { PublicErrorTemplate } from "../../../templates/PublicErrorTemplate/PublicErrorTemplate";

export const PublicError500 = (): JSX.Element => {
  return (
    <PublicErrorTemplate
      errorText="Something went wrong. The server encountered an error and could not complete your request. Refresh the page or try again later."
      errorCode="500"
    />
  );
};
