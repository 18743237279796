import { SelectData } from "../../../../../../models";
import {
  ActionButtons,
  Column,
  ErrorBox,
  InformationBox,
  RadioButtonGroup,
  Row,
  Select,
} from "../../../../../../widget";
import { useEditForm } from "./useEditForm";

interface EditFormProps {
  userUuid: string | undefined;
  defaultRoleValue: string;
  defaultActiveValue: boolean;
  roles: SelectData | undefined;
  rolesInfo: string[];
  rowVersion: number;
  userSettingsRoute: string;
}

export const EditForm = ({
  userUuid,
  defaultRoleValue,
  defaultActiveValue,
  roles,
  rolesInfo,
  rowVersion,
  userSettingsRoute,
}: EditFormProps): JSX.Element => {
  const { radioValues, role, active, isHandleSubmitLoading, setRole, setActive, handleSubmit, handleCancel, errors } =
    useEditForm(userUuid, defaultRoleValue, defaultActiveValue, rowVersion, userSettingsRoute);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}

      <form onSubmit={(e) => handleSubmit(e)}>
        {roles && (
          <Row spacingV="2xl">
            <Column span={4}>
              <Select label="Role" initialKey={role} data={roles} onChange={(value) => setRole(value)} />
              <InformationBox variant="info" title="What can each role do?">
                <ul>
                  {rolesInfo.map((info, index) => (
                    <div key={info}>
                      <li>{info}</li>
                      {index !== rolesInfo.length - 1 && <br />}
                    </div>
                  ))}
                </ul>
              </InformationBox>
            </Column>
          </Row>
        )}
        <Row spacingV="4xl">
          <Column span={4}>
            <RadioButtonGroup
              data={radioValues}
              value={active}
              onChange={(e) => {
                setActive(e.target.value);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column span={2}>
            <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
          </Column>
        </Row>
      </form>
    </>
  );
};
