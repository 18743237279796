import "./RichListContentSection.css";

import { Link, useLocation } from "react-router-dom";

import { SectionPart } from "../../../models";
import { Column, Row } from "../../../widget";

interface RichListContentSectionProps {
  title: string;
  content?: string | null;
  sectionDataList: SectionPart[];
}

export const RichListContentSection = ({
  title,
  content,
  sectionDataList,
}: RichListContentSectionProps): JSX.Element => {
  const { search } = useLocation();
  return (
    <div className="RichListContentSection">
      <h2>{title}</h2>
      {content && (
        <Row spacingV="ll">
          <Column span={12}>
            <p className="body1">{content}</p>
          </Column>
        </Row>
      )}
      {sectionDataList.map((sd) => (
        <Row spacingV="2xl" justify="between" key={sd.title}>
          <Column span={2}>
            {sd.imageUrl && sd.imageLink && (
              <Link to={{ pathname: sd.imageLink, search }}>
                <img src={sd.imageUrl} alt={sd.title || undefined} />
              </Link>
            )}
            {sd.imageUrl && !sd.imageLink && <img src={sd.imageUrl} alt={sd.title || undefined} />}
          </Column>
          <Column span={9}>
            <Row spacingV="m">
              <Column span={12}>
                <h3>{sd.title}</h3>
              </Column>
            </Row>
            <Row>
              <Column span={12}>
                <p className="body1">{sd.content}</p>
              </Column>
            </Row>
          </Column>
        </Row>
      ))}
    </div>
  );
};
