import "./MarketingSettingsForm.css";

import { ProjectPermissionConstants } from "../../../../../../constants";
import { PreviewFile } from "../../../../../../models";
import { GetProjectDetailsResponse } from "../../../../../../service/query";
import { downloadDocument } from "../../../../../../utils";
import {
  ActionButtons,
  Button,
  // CodeSnippetTextArea,
  Column,
  Divider,
  ErrorBox,
  // Divider,
  InformationBox,
  KanaTooltip,
  MultipleFileUpload,
  RadioButtonGroup,
  Row,
  TextArea,
  TextInput,
} from "../../../../../../widget";
import { RadioChangeEvent } from "../../../../../../widget/forms/Input/RadioButtonGroup/components";
import { MarketingSettingsData } from "../models";
import { useMarketingSettingsForm } from "./useMarketingSettingsForm";

interface MarketingSettingsFormProps {
  defaultValues: MarketingSettingsData;
  projectDetails: GetProjectDetailsResponse;
}

export const MarketingSettingsForm = ({ defaultValues, projectDetails }: MarketingSettingsFormProps): JSX.Element => {
  const {
    radioButtonGroupData,
    hideProjectChecked,
    setHideProjectChecked,
    pilotProjectChecked,
    setPilotProjectChecked,

    shortDescription,
    longDescription,
    videoUrl,
    images,
    // embedCode,
    setShortDescription,
    setLongDescription,
    setVideoUrl,
    setImages,
    handleSubmit,
    handlePreview,
    onCancel,
    imageUploadRef,
    listUnitsChecked,
    setListUnitsChecked,
    unitPriceChecked,
    setUnitPriceChecked,
    seekingFundingChecked,
    setSeekingFundingChecked,
    disablePublicInterestChecked,
    setDisablePublicInterestChecked,
    redirectPublicInterestChecked,
    onRedirectPublicInterestChange,
    redirectPublicInterestEmail,
    setRedirectPublicInterestEmail,
    hasPermission,

    isHandleSubmitLoading,
    isHandlePreviewLoading,
    errors,
  } = useMarketingSettingsForm(defaultValues, projectDetails);
  return (
    <div className="MarketingSettingsFormContainer">
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Column span={5}>
            <Row spacingV="ll">
              <Column span={12}>
                <TextInput
                  label="Short Description"
                  name="shortDescription"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What is the short description used for?"
                      tooltipText="The short description will be visible in the public project search page and should offer a concise overview of the project."
                    />
                  }
                  value={shortDescription || ""}
                  onChange={(e) => setShortDescription(e)}
                  displayCharacterCount
                  maxLength={100}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <TextArea
                  label="Long Description"
                  name="longDescription"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What is the long description used for?"
                      tooltipText="The long description will be visible in the project overview page."
                    />
                  }
                  value={longDescription || ""}
                  onChange={(v) => setLongDescription(v)}
                  displayCharacterCount
                  maxLength={4000}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <MultipleFileUpload
                  accept="image/jpeg"
                  title="Images"
                  value={images}
                  ref={imageUploadRef}
                  subTitle="Upload up to 10 high resolution images of your project. Once uploaded, you can drag the images to re-order them. The first image in the order will be used as the main project photo."
                  onChange={(files) => setImages([...files])}
                  onPreviewFile={(file: PreviewFile) => {
                    downloadDocument(file.filename, file.mimeType, file.url, true);
                  }}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <TextInput
                  label="YouTube video"
                  name="videoUrl"
                  placeholder="http://www.youtube.com/watch?v="
                  value={videoUrl}
                  onChange={(value) => setVideoUrl(value)}
                />
              </Column>
            </Row>
            <Row spacingV="2xl">
              <Column span={12}>
                <RadioButtonGroup
                  label="Pilot project"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What is a pilot project?"
                      tooltipText="<p>A pilot project is a small-scale trial to test the feasibility of a methodology or approach, typically associated with an emerging code or standard which is still formulating its rules and requirements.</p><p>Selecting ‘Yes’ will designate this project as a pilot, making it discoverable as such in the public directory.</p>"
                      allowDangerousHtml
                    />
                  }
                  data={radioButtonGroupData}
                  direction="vertical"
                  value={pilotProjectChecked}
                  onChange={(e: RadioChangeEvent) => setPilotProjectChecked(e.target.value)}
                />
              </Column>
            </Row>
          </Column>
          <Column span={4} offset={1}>
            <InformationBox variant="info" title="What are marketing settings?">
              <p className="body2">
                Marketing Settings will determine what is displayed in the public project directory. Here you can
                control settings and add project descriptions and imagery.
              </p>
            </InformationBox>
          </Column>
          <Column span={12}>
            <Row spacingV="2xl">
              <Column span={12}>
                <Divider type="horizontal" />
              </Column>
            </Row>
          </Column>
          <Column span={5}>
            <Row spacingV="ll">
              <Column span={12}>
                <h2>Public visibility</h2>
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <RadioButtonGroup
                  label="Project visibility"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What does project visibility do?"
                      tooltipText="Project visibility dictates whether or not your project will be visible in the public directory. ‘Selecting hide project’  will mean your project no longer appears in the directory and it will not be displayed in search results. However, the project will still be visible to the public if they have its unique URL, which allows you to share the project details with interested parties. The project will also still be visible in your external registry."
                    />
                  }
                  data={[
                    { key: false, value: "Show project" },
                    { key: true, value: "Hide project" },
                  ]}
                  direction="vertical"
                  value={hideProjectChecked}
                  onChange={(e: RadioChangeEvent) => setHideProjectChecked(e.target.value)}
                />
              </Column>
            </Row>
            {(projectDetails?.status === "Draft" || projectDetails?.status === "Pre-development") && (
              <Row spacingV="ll">
                <Column span={12}>
                  <RadioButtonGroup
                    label="List project as seeking funding"
                    tooltip={
                      <KanaTooltip
                        tooltipHeader="What does this do?"
                        tooltipText="When you list a project as seeking funding it will appear in the directory with a banner to let buyers know that your project is seeking early-stage investment. "
                      />
                    }
                    data={radioButtonGroupData}
                    direction="vertical"
                    value={seekingFundingChecked}
                    onChange={(e: RadioChangeEvent) => setSeekingFundingChecked(e.target.value)}
                  />
                </Column>
              </Row>
            )}
            {(projectDetails?.cachedPiuQuantity > 0 || projectDetails?.cachedVcuQuantity > 0) && (
              <>
                <Row spacingV="ll">
                  <Column span={12}>
                    <RadioButtonGroup
                      label="Unit availability"
                      data={[
                        { key: true, value: "Available to buy" },
                        { key: false, value: "Not available to buy" },
                      ]}
                      direction="vertical"
                      value={listUnitsChecked}
                      onChange={(e: RadioChangeEvent) => setListUnitsChecked(e.target.value)}
                    />
                  </Column>
                </Row>
                <Row spacingV="ll">
                  <Column span={12}>
                    <RadioButtonGroup
                      label="Unit price"
                      tooltip={
                        <KanaTooltip
                          tooltipHeader="What does showing a unit price in the public directory do?"
                          tooltipText="This allows you to show and hide the price of your units in the public project search page and on the project page's issuances tab. To set unit prices, navigate to the ‘Issuances & pricing’ tab and enter the prices in the issuance table."
                        />
                      }
                      data={[
                        { key: true, value: "Show unit price" },
                        { key: false, value: "Hide unit price" },
                      ]}
                      direction="vertical"
                      value={unitPriceChecked}
                      onChange={(e: RadioChangeEvent) => setUnitPriceChecked(e.target.value)}
                    />
                  </Column>
                </Row>
              </>
            )}
          </Column>
          <Column span={12}>
            <Row spacingV="2xl">
              <Column span={12}>
                <Divider type="horizontal" />
              </Column>
            </Row>
          </Column>
          <Column span={5}>
            <Row spacingV="ll">
              <Column span={12}>
                <h2>Contact</h2>
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <RadioButtonGroup
                  label="Public interest emails"
                  data={[
                    { key: false, value: "Enabled" },
                    { key: true, value: "Disabled" },
                  ]}
                  direction="vertical"
                  value={disablePublicInterestChecked}
                  onChange={(e: RadioChangeEvent) => setDisablePublicInterestChecked(e.target.value)}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <RadioButtonGroup
                  label="Re-direct public interest emails"
                  data={radioButtonGroupData}
                  direction="vertical"
                  value={redirectPublicInterestChecked}
                  onChange={onRedirectPublicInterestChange}
                />
              </Column>
            </Row>
            {redirectPublicInterestChecked && (
              <Row spacingV="2xl">
                <Column span={12}>
                  <TextInput
                    type="email"
                    label="Alternative contact email address"
                    name="alternative email"
                    value={redirectPublicInterestEmail}
                    onChange={(value) => setRedirectPublicInterestEmail(value)}
                  />
                </Column>
              </Row>
            )}
            {/* <Row spacingV="3xl"> this is the row spacing for when the embedding area will be used */}
            <Row>
              <Column span={12}>
                <div className="ActionButtons">
                  <Button text="Preview" onClick={handlePreview} isLoading={isHandlePreviewLoading} />
                  {hasPermission(ProjectPermissionConstants.WRITE_PROJECT_DETAILS) && (
                    <ActionButtons onCancel={onCancel} isLoading={isHandleSubmitLoading} />
                  )}
                </div>
              </Column>
            </Row>
          </Column>
        </Row>
        {/* <Row spacingV="xl">
          <Column span={12}>
            <Divider type="horizontal" />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <h2>Embed your project</h2>
          </Column>
        </Row>
        <Row>
          <Column span={5}>
            <CodeSnippetTextArea name="embedCode" value={embedCode} />
          </Column>
        </Row> */}
      </form>
      {/* Temporarily disabling UnsavedChangesModal ENG-664 - by adding false check */}
      {/* <UnsavedChangesModal hasUnsavedChanges={false && !isPreviewMode && hasUnsavedChanges} /> */}
    </div>
  );
};
