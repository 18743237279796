import { Button, Column, ErrorBox, InformationBox, Password, Row, TextInput } from "../../../../widget";
import { useAcceptInvitationForm } from "./useAcceptInvitationForm";

interface AcceptInvitationFormProps {
  urlToken: string;
  email: string;
  onSuccess: (e: boolean) => void;
}

export const AcceptInvitationForm = ({ urlToken, email, onSuccess }: AcceptInvitationFormProps): JSX.Element => {
  const {
    newPassword,
    newPasswordConfirmation,
    errors,
    isHandleSubmitLoading,
    setNewPassword,
    setNewPasswordConfirmation,
    handleSubmit,
  } = useAcceptInvitationForm(urlToken, email, onSuccess);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox title="Please amend your password" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={12}>
            <TextInput type="email" name="email" label="Email address" value={email} disabled />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <InformationBox variant="info" title="Password requirements">
              <ul>
                <li>Be at least 8 characters long</li>
                <li>
                  Not be a commonly used password such as <q>Password1</q>
                </li>
              </ul>
            </InformationBox>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Password
              label="Password"
              name="newPassword"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.currentTarget.value);
              }}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Password
              label="Confirm password"
              name="newPasswordConfirmation"
              value={newPasswordConfirmation}
              onChange={(e) => {
                setNewPasswordConfirmation(e.currentTarget.value);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Button fullWidth type="submit" text="Accept invitation" isLoading={isHandleSubmitLoading} />
          </Column>
        </Row>
      </form>
    </>
  );
};
