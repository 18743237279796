import { EmbeddingPreferences } from "../../../../../models";
import { ActionButtons, CodeSnippetTextArea, Column, Divider, Row, TextInput } from "../../../../../widget";
import { PreviewButton } from "../components";
import { useEmbeddingForm } from "./useEmbeddingForm";

interface EmbeddingFormProps {
  defaultValues: EmbeddingPreferences[];
}

export const EmbeddingForm = ({ defaultValues }: EmbeddingFormProps): JSX.Element => {
  const {
    embedCode,
    primaryBgColour,
    primaryTextColour,
    secondaryBgColour,
    secondaryTextColour,
    setSecondaryTextColour,
    setPrimaryBgColour,
    setSecondaryBgColour,
    setPrimaryTextColour,
    handleSubmit,
    handleCancel,
  } = useEmbeddingForm(defaultValues);

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="m">
          <Column span={4}>
            <h2>Embedding</h2>
          </Column>
        </Row>
        <Row spacingV="xl">
          <Column span={12}>
            <span className="body1">
              Customise buttons with your own colours, so that your projects remain on-brand when embedding on your
              website
            </span>
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={4}>
            <TextInput
              label="Primary Background Colour"
              name="primaryBgColour"
              helperText="Please enter a hex code, for example #FFFFFF"
              value={primaryBgColour}
              onChange={(value) => setPrimaryBgColour(value)}
            />
          </Column>
          <Column span={4} offset={1}>
            <TextInput
              label="Primary Text Colour"
              name="primaryTextColour"
              helperText="Please enter a hex code, for example #FFFFFF"
              value={primaryTextColour}
              onChange={(value) => setPrimaryTextColour(value)}
            />
          </Column>
          <Column span={1} offset={1}>
            <PreviewButton
              bgColour={primaryBgColour || "var(--color-blue)"}
              textColour={primaryTextColour || "var(--color-white)"}
            />
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={4}>
            <TextInput
              label="Secondary Background Colour"
              name="secondaryBgColour"
              helperText="Please enter a hex code, for example #FFFFFF"
              value={secondaryBgColour}
              onChange={(value) => setSecondaryBgColour(value)}
            />
          </Column>
          <Column span={4} offset={1}>
            <TextInput
              label="Secondary Text Colour"
              name="secondaryTextColour"
              helperText="Please enter a hex code, for example #FFFFFF"
              value={secondaryTextColour}
              onChange={(value) => setSecondaryTextColour(value)}
            />
          </Column>
          <Column span={1} offset={1}>
            <PreviewButton
              bgColour={secondaryBgColour || "var(--color-white)"}
              textColour={secondaryTextColour || "var(--color-blue)"}
            />
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={4}>
            <ActionButtons onCancel={handleCancel} />
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={12}>
            <Divider type="horizontal" />
          </Column>
        </Row>
        <Row>
          <Column span={4}>
            <CodeSnippetTextArea name="embedCode" value={embedCode} />
          </Column>
        </Row>
      </form>
      {/* <UnsavedChangesModal hasUnsavedChanges={hasUnsavedChanges} /> */}
    </>
  );
};
