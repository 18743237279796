import "./Toggle.css";

import RcSwitch from "rc-switch";

import { FormLabel } from "../../FormLabel";
import { useToggle } from "./useToggle";

type ToggleVariant = "primary" | "secondary" | "tertiary";

export interface ToggleProps {
  value: boolean;
  variant?: ToggleVariant;
  label?: string;
  allowLabelDangerousHtml?: boolean;
  labelAlignment?: "left" | "right";
  onChange: (value: boolean) => void;
}

export const Toggle = ({
  label,
  variant = "primary",
  allowLabelDangerousHtml,
  labelAlignment = "right",
  onChange,
  value,
}: ToggleProps): JSX.Element => {
  const { toggleId, checked, handleToggleClick } = useToggle(value, onChange);

  return (
    <div className={`Toggle ${variant}`}>
      <FormLabel label={label} labelAlignment={labelAlignment} allowDangerousHtml={allowLabelDangerousHtml}>
        <RcSwitch id={toggleId} checked={checked} onClick={handleToggleClick} prefixCls="ToggleComponent" />
      </FormLabel>
    </div>
  );
};
