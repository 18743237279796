import "./VersionHistoryDetailsCell.css";

import { EyeBlueIcon } from "../../../../../../assets";
import { DataGridIconCellFormatterData } from "../../../../../../widget";
import { CellFormatter } from "../../../../../../widget/data/DataGrid/models";

export const VersionHistoryDetailsCellFormatter: CellFormatter<
  DataGridIconCellFormatterData | null | undefined
> = () => {
  return (value) => {
    if (!value || !value?.action) return <div />;

    return (
      <div className="VersionHistoryDetailsButtonWrapper">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/destructuring-assignment */}
        <div className="VersionHistoryDetailsButton" onClick={value.action}>
          <EyeBlueIcon />
        </div>
      </div>
    );
  };
};
