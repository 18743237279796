import "./ActivitiesDetailsCell.css";

import { ArrowheadRightDarkIcon } from "../../../../../../../assets";
import { CellFormatter } from "../../../../../../../widget/data/DataGrid/models";

export const ActivitiesDetailsCellFormatter: CellFormatter<string> = () => {
  return (value) => {
    return (
      <div className="ActivitiesDetailsButtonWrapper">
        <a href={value} className="ActivitiesDetailsButton">
          <ArrowheadRightDarkIcon />
        </a>
      </div>
    );
  };
};
