// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint @typescript-eslint/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// IdentityService
import mem from "mem";

import { axios } from "../axios";
import { BaseResponse, BaseResponseWithSingle, Status } from "../Shared";
import {
  AcceptInvitationRequest,
  AcceptInvitationResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  CreateForgottenPasswordRequestRequest,
  ForgottenPasswordCompleteRequest,
  InviteUserToJoinOrganisationRequest,
  LogonRequest,
  LogonResponse,
  LogoutResponse,
  RefreshTokenResponse,
  RegisterRequest,
  RegisterResponse,
  UpdatePersonalDetailsRequest,
  UpdatePersonalDetailsResponse,
} from "./Types";

export const acceptInvitation = async (
  req: AcceptInvitationRequest
): Promise<BaseResponseWithSingle<AcceptInvitationResponse>> => {
  return axios
    .request<BaseResponseWithSingle<AcceptInvitationResponse>>({
      method: "post",
      url: "authentication/invitation/accept",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const register = async (req: RegisterRequest): Promise<BaseResponseWithSingle<RegisterResponse>> => {
  return axios
    .request<BaseResponseWithSingle<RegisterResponse>>({
      method: "post",
      url: "authentication/register",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const refreshToken = async (): Promise<BaseResponseWithSingle<RefreshTokenResponse>> => {
  return axios
    .request<BaseResponseWithSingle<RefreshTokenResponse>>({
      method: "post",
      url: "identity/refresh-token",
      withCredentials: true,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updatePersonalDetails = async (
  req: UpdatePersonalDetailsRequest
): Promise<BaseResponseWithSingle<UpdatePersonalDetailsResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdatePersonalDetailsResponse>>({
      method: "patch",
      url: "authentication/personal-details",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const changePassword = async (
  req: ChangePasswordRequest
): Promise<BaseResponseWithSingle<ChangePasswordResponse>> => {
  return axios
    .request<BaseResponseWithSingle<ChangePasswordResponse>>({
      method: "put",
      url: "authentication/password-change",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const createForgottenPasswordRequest = async (
  req: CreateForgottenPasswordRequestRequest
): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "post",
      url: "authentication/forgotten-password/request",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const inviteUserToJoinOrganisation = async (req: InviteUserToJoinOrganisationRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "post",
      url: "authentication/invitation/create",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const forgottenPasswordComplete = async (req: ForgottenPasswordCompleteRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "post",
      url: "authentication/forgotten-password/reset",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const logon = async (req: LogonRequest): Promise<BaseResponseWithSingle<LogonResponse>> => {
  return axios
    .request<BaseResponseWithSingle<LogonResponse>>({
      method: "post",
      url: "authentication/logon",
      withCredentials: true,
      data: req,
    })
    .then((response) => {
      window.kanaCurrentUserUuid = response.data.data?.userUuid;
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const logout = async (): Promise<BaseResponseWithSingle<LogoutResponse>> => {
  return axios
    .request<BaseResponseWithSingle<LogoutResponse>>({
      method: "post",
      url: "authentication/logout",
      withCredentials: true,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

const refreshTokenFn = async (): Promise<BaseResponseWithSingle<RefreshTokenResponse>> => {
  const res = await axios
    .request<BaseResponseWithSingle<RefreshTokenResponse>>({
      method: "post",
      url: "authentication/refresh-token",
      withCredentials: true,
    })
    .then((result) => {
      window.ACCESS_TOKEN = result.data.data?.accessToken;
      return result.data;
    })
    .catch((error) => {
      return {
        status: Status.Error,
        data: undefined,
        errors: error,
      };
    });
  return res;
};

// The refresh token response is memoized for 15 seconds in case you land on a page with multiple unauthorized queries.
export const memoizedRefreshAccessToken = mem(refreshTokenFn, {
  maxAge: 5000,
});
