import "./RatingKPI.css";

import { RatingStarIcon } from "../../../../assets";

interface RatingKPIProps {
  rating: number;
}

export const RatingKPI = ({ rating }: RatingKPIProps): JSX.Element => {
  const score = [];
  for (let i = 1; i <= 5; i++) {
    score.push(<RatingStarIcon key={`Rating${i}`} className={rating >= i ? "Blue" : "Grey"} />);
  }

  return (
    <div className="RatingContainer">
      <p className="body01">Rating</p>
      <div className="ScoreSection">{score}</div>
      <h3>{rating}/5</h3>
    </div>
  );
};
