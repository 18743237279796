/*
 * Converters between the data formats required by the DataGrid, and other
 * application data formats
 */

import { ComponentType, Dispatch, SetStateAction } from "react";
import { Column as RdgColumn, FormatterProps as RdgFormatterProps, SortColumn as RdgSortColumn } from "react-data-grid";

import { FilterCriteria, SupportedDataTypeNames, SupportedDataTypes } from "../../../../models";
import { logError } from "../../../../service/error";
import { ResultData } from "../../../../service/Shared";
import { toNumber } from "../clientSide/ClientSideFilter";
import { cellFormatters } from "../components/CellFormatters";
import { HeaderRenderer, onFilterChangedEvent } from "../components/HeaderRenderer";
import { ColumnDefinition, ColumnDefinitionWithCustomCellFormatter } from "../models";

// maps `FilterCriteria` in flattened DataGrid format to a nested value
// e.g. `{ "a.b.c": { operator: "eq", value: "John" } }`
// becomes `{ a: { b: { c: { operator: "eq", value: "John" } } } }`
// NCU: this function is dynamic by nature -> `any` is required
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapFilterCriteria = (filterCriteria: FilterCriteria[]): any => {
  // NCU: this function is dynamic by nature -> `any` is required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const all: any = {};
  for (let i = 0; i < filterCriteria.length; i++) {
    // NCU: this function is dynamic by nature -> `any` is required
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let current: any = all;
    filterCriteria[i].key.split(".").forEach((prop) => {
      current[prop] = current[prop] || {};
      current = current[prop];
    });
    current.operator = filterCriteria[i].operator;
    current.value = filterCriteria[i].value;
  }
  return all;
};

export const mapColumnDefinitionToRdgColumn = (
  columns: ColumnDefinition[],
  dataGridSortable: boolean,
  dataGridFilterable: boolean,
  sortColumns: readonly RdgSortColumn[],
  setSortColumns: Dispatch<SetStateAction<readonly RdgSortColumn[]>>,
  filtersEnabled: boolean,
  filterCriteria: FilterCriteria[],
  setFilterCriteria: Dispatch<SetStateAction<FilterCriteria[]>>,
  onFilterChanged: onFilterChangedEvent
): RdgColumn<ResultData>[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return columns.map((columnDefinition) => {
    let formatter: ComponentType<RdgFormatterProps<ResultData>> | undefined;
    if (columnDefinition.formatter !== undefined) {
      let firstLevelFormatterFn = cellFormatters[columnDefinition.formatter];
      if (firstLevelFormatterFn === undefined && columnDefinition.formatter === "custom") {
        firstLevelFormatterFn = (columnDefinition as ColumnDefinitionWithCustomCellFormatter<SupportedDataTypes>)
          .customCellFormatter;
      }
      if (firstLevelFormatterFn === undefined)
        throw new Error(
          `Column definition formatter is ${columnDefinition.formatter}; expecting a "customCellFormatter" to be supplied, but was undefined`
        );
      const secondLevelFormatterFn = firstLevelFormatterFn(columnDefinition);
      const { key } = columnDefinition;
      formatter = (props) => secondLevelFormatterFn(props.row[key] as never);
    }
    return {
      key: columnDefinition.key,
      name: columnDefinition.name ?? columnDefinition.key[0].toUpperCase() + columnDefinition.key.substring(1),
      minWidth: columnDefinition.minWidth,
      formatter,
      sortable: (columnDefinition.sortable === undefined && dataGridSortable) || columnDefinition.sortable,
      headerRenderer: HeaderRenderer<ResultData, void>({
        onFilterChanged,
        columnDefinition,
        dataGridFilterable,
        dataGridSortable,
        sortColumns,
        setSortColumns,
        filtersEnabled,
        filterCriteria,
        setFilterCriteria,
      }),
    };
  });
};

const castFilterValueToCorrectType = (dataType: SupportedDataTypeNames, filter: FilterCriteria): FilterCriteria => {
  switch (dataType) {
    case "string": {
      const updatedFilter = filter;
      updatedFilter.value = (filter.value as string).toLowerCase();

      return updatedFilter;
    }
    case "number": {
      const updatedFilter = filter;

      if (filter.value === "") {
        // If value is empty (when a filter is first selected or when input data is deleted), default to `eq` filter
        // This makes it so that this filter can be removed from the filterCriteria by using the `filter` function in getFilterCriteriaWithCastedValues
        updatedFilter.operator = "eq";
      }

      // Defaults the filter to 0 if the inputted value cannot be converted to a number
      updatedFilter.value = toNumber(filter.value as number) ?? 0;

      return updatedFilter;
    }
    // TODO treat other types like boolean and date
    default:
      logError({ error: `Couldn't cast the filter value; unexpected dataType: ${dataType}` });
      throw new Error(`Couldn't cast the filter value; unexpected dataType: ${dataType}`);
  }
};

export const getFilterCriteriaWithCastedValues = (
  filterCriteria: FilterCriteria[],
  columns: ColumnDefinition[]
): FilterCriteria[] => {
  const filterColumnDefinitions = new Array<ColumnDefinition>(filterCriteria.length);

  for (let i = 0; i < filterCriteria.length; i++) {
    const filterColumnDefinition = columns.find((cd) => cd.key === filterCriteria[i].key);

    if (!filterColumnDefinition) {
      logError({
        error: `Couldn't find ColumnDefinition for column key ${filterCriteria[i].key} in useCursorDataGrid effect`,
      });
      throw new Error(
        `Couldn't find ColumnDefinition for column key ${filterCriteria[i].key} in useCursorDataGrid effect`
      );
    }

    filterColumnDefinitions[i] = filterColumnDefinition;
  }

  let updatedFilterCriteria = filterCriteria;

  for (let i = 0; i < filterColumnDefinitions.length; i++) {
    // `filterColumnDefinitions` was created from `filterCriteria`, this `find` will always return a value
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const filterToBeCasted = updatedFilterCriteria.find((fc) => fc.key === filterColumnDefinitions[i].key)!;

    updatedFilterCriteria[i] = castFilterValueToCorrectType(filterColumnDefinitions[i].dataType, filterToBeCasted);
  }

  updatedFilterCriteria = updatedFilterCriteria.filter((ufc) => !(ufc.operator === "eq" && Number.isNaN(ufc.value)));

  return updatedFilterCriteria;
};
