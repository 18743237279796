// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import { useClickOutside } from "../../../../utils";

interface useDatePickerProps {
  isShow: boolean;
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
}

interface useDatePickerReturnData {
  showCalendar: boolean;
  selectedDate: Date | undefined;
  datePickerRef: MutableRefObject<null | HTMLDivElement>;
  handleSelect: (selectedDate: moment.Moment) => void;
  handleOnChange: (selectedDate: moment.Moment | null) => void;
  handleCalendarInputClick: () => void;
  handleCalendarInputKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const useDatePicker = ({ isShow, value, onChange }: useDatePickerProps): useDatePickerReturnData => {
  const [showCalendar, setShowCalendar] = useState<boolean>(isShow);
  const [selectedDate, setSelectedDate] = useState(value);

  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleSelect = (dateSelected: moment.Moment): void => {
    if (dateSelected) {
      setShowCalendar(false);
      setSelectedDate(dateSelected.toDate());
      onChange(dateSelected.toDate());
    } else {
      setSelectedDate(undefined);
      onChange(undefined);
    }
  };

  const handleOnChange = (dateSelected: moment.Moment | null): void => {
    if (dateSelected) {
      // removing the local time and converting to javascript date
      const jsDate = dateSelected.utcOffset(0, true).toDate();

      setSelectedDate(jsDate);
      onChange(jsDate);
    }
  };

  const handleCalendarInputClick = (): void => {
    if (!showCalendar) {
      setShowCalendar(true);
    }
  };

  useEffect(() => {
    if (value !== selectedDate) {
      setSelectedDate(value);
    }
  }, [value]);

  const handleCalendarInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (e.key) {
      case "Enter":
        setShowCalendar(!showCalendar);
        break;
      case "Escape":
        setShowCalendar(false);
        break;
      default:
        break;
    }
  };

  useClickOutside(datePickerRef, () => {
    setShowCalendar(false);
  });

  return {
    showCalendar,
    selectedDate,
    datePickerRef,
    handleSelect,
    handleOnChange,
    handleCalendarInputClick,
    handleCalendarInputKeyDown,
  };
};
