import { useCallback, useState } from "react";

import { OrganisationTypeConstants } from "../../../constants";
import { CursorChangeProps, ResultType } from "../../../models";
import { searchProjects, SearchProjectsResponse } from "../../../service/query";
import { ResultData } from "../../../service/Shared";
import { flattenObject } from "../../../utils";
import { getProjectDetailsByUuid } from "../../../utils/routes";
import { DataGridColumnDefinition, DataGridLinkCellFormatterData, dataGridMapFilterCriteria } from "../../../widget";

interface UseProjectsReturnData {
  columns: DataGridColumnDefinition[];
  isLoading: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

const PAGE_SIZE = 10;

const columns: DataGridColumnDefinition[] = [
  {
    name: "Developer",
    key: "developer.displayName",
    dataType: "string",
  },
  {
    name: "Project",
    key: "link",
    dataType: "string",
    formatter: "link",
  },
  {
    name: "Registry ID",
    key: "projectReference.externalReference",
    dataType: "string",
  },
  {
    name: "Status",
    key: "status",
    dataType: "string",
    formatter: "projectStatusPill",
    alignment: "center",
    minWidth: 200,
  },
  {
    name: "PIUs",
    key: "cachedPiuQuantity",
    dataType: "number",
  },
  {
    name: "VCUs",
    key: "cachedVcuQuantity",
    dataType: "number",
  },
];

export const useProjects = (): UseProjectsReturnData => {
  const [isLoading, setIsLoading] = useState(true);

  const formatData = useCallback(
    (responseData: SearchProjectsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = <DataGridLinkCellFormatterData>{
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid, OrganisationTypeConstants.DEVELOPMENT_MANAGER),
        };

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (filterCriteria.link) {
      filterCriteria.displayName = filterCriteria.link;
      filterCriteria.link = undefined;
    }

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: PAGE_SIZE,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    await searchProjects({
      paging: {
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
        limit: PAGE_SIZE,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key !== "link" ? s.key : "displayName",
        direction: s.direction,
      })),
      /* eslint-enable */
      filter: { results: filterCriteria },
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: PAGE_SIZE,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setIsLoading(false);
      });

    return data;
  };

  return {
    columns,
    isLoading,
    onChange,
  };
};
