import "./SingleFileReplace.css";

import Upload from "rc-upload";

import {
  DownloadDarkIcon,
  DropdownLightIcon,
  EyeBlueIcon,
  FileLinesBlueIcon,
  TrashDark,
  UploadBlueIcon,
} from "../../../../assets";
import { GenericModal, GenericModalData } from "../../../../route/developer/components";
import { canPreviewFile } from "../../../../utils";
import { LoadingSpinner } from "../../../general";
import { Button } from "../../Button";
import { FormLabel } from "../../FormLabel";
import { SingleFileUploadPropsV2, SingleFileUploadV2 } from "../SingleFileUploadV2";
import { useSingleFileReplace } from "./useSingleFileReplace";

export interface SingleFileReplaceProps extends SingleFileUploadPropsV2 {
  replaceText?: string;
  uploadButtonText?: string;
  shouldConfirmDeletion?: boolean;
  deleteFileModalData?: GenericModalData;
}

export const SingleFileReplace = ({
  value,
  label = "Single file replace upload",
  allowLabelDangerousHtml,
  btnLabel = "Choose a file",
  maxFileSize = 1,
  informationLabel,
  disabled = false,
  hasPreview = false,
  previewLabel = "Image preview",
  tooltip,
  accept = "*",
  replaceText = "Replace this file throughout validation",
  uploadButtonText = "Upload a new version",
  shouldConfirmDeletion,
  deleteFileModalData,
  onFileUpload,
  onPreviewFile,
  onChange,
}: SingleFileReplaceProps): JSX.Element => {
  const {
    id,
    selectedFile,
    showDropdown,
    showDeleteFileModal,
    isFileUploading,
    dropdownRef,
    handleClick,
    handleKeyDown,
    handleDeleteFile,
    handleKeyDownDeleteFile,
    setSelectedFile,
    onAction,
    onConfirmFileDeleteModal,
    onCloseFileDeleteModal,
  } = useSingleFileReplace({
    value,
    onChange,
    onFileUpload,
    maxFileSize,
    shouldConfirmDeletion,
  });

  return selectedFile ? (
    <div ref={dropdownRef} className="SingleFileReplaceWrapper">
      <FormLabel label={label} tooltip={tooltip} allowDangerousHtml={allowLabelDangerousHtml} />
      <div className="SingleFileReplaceActions">
        <div
          className="SingleFileReplace"
          role="button"
          onClick={isFileUploading ? undefined : handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          <div className="CurrentUploadedFile">
            <div className="CurrentUploadedFileName">
              <FileLinesBlueIcon className="FileLinesBlueIcon" />
              <h3>{selectedFile.filename}</h3>
            </div>
            <DropdownLightIcon className="DropdownLightIcon" />
          </div>
          {isFileUploading && (
            <div className="TextWithLoadingSpinner">
              <p>Uploading</p>
              <LoadingSpinner type="dark" />
            </div>
          )}
        </div>
        {onPreviewFile && !isFileUploading && (
          <div className="SingleFileReplacePreviewBtn">
            <Button
              variant="tertiary"
              icon={canPreviewFile(selectedFile.mimeType) ? <EyeBlueIcon /> : <DownloadDarkIcon />}
              iconPosition="left"
              text={canPreviewFile(selectedFile.mimeType) ? "Preview file" : "Download file"}
              onClick={() => onPreviewFile(selectedFile)}
            />
          </div>
        )}
      </div>
      {showDropdown && (
        <div className="Dropdown">
          <p className="body3">{replaceText}</p>
          <Upload
            className="DropdownButton"
            action={onAction}
            multiple={false}
            disabled={isFileUploading}
            accept={accept}
            id={id}
            customRequest={() => null} // Removing default component request by providing an empty one
          >
            <UploadBlueIcon className="UploadBlueIcon" />
            <span className="body1">{uploadButtonText}</span>
          </Upload>
          <div
            className="DropdownButton"
            role="button"
            onClick={handleDeleteFile}
            onKeyDown={handleKeyDownDeleteFile}
            tabIndex={0}
          >
            <TrashDark className="TrashDarkIcon" />
            <span className="body1">Delete</span>
          </div>
        </div>
      )}
      {shouldConfirmDeletion && (
        <GenericModal
          show={showDeleteFileModal}
          onConfirm={onConfirmFileDeleteModal}
          closeModal={onCloseFileDeleteModal}
          data={deleteFileModalData}
        />
      )}
    </div>
  ) : (
    <SingleFileUploadV2
      value={selectedFile}
      label={label}
      allowLabelDangerousHtml={allowLabelDangerousHtml}
      btnLabel={btnLabel}
      maxFileSize={maxFileSize}
      informationLabel={informationLabel}
      accept={accept}
      disabled={disabled}
      onChange={(e) => {
        setSelectedFile(e);
        onChange(e);
      }}
      onFileUpload={onFileUpload}
      hasPreview={hasPreview}
      previewLabel={previewLabel}
      tooltip={tooltip}
      onPreviewFile={onPreviewFile}
    />
  );
};
