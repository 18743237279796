import { ApolloClient, FetchResult, from, HttpLink, InMemoryCache, Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import {
  apolloDefaultOptions,
  apolloParseJsonDatesLink,
  errorHandler400,
  errorHandler404,
  errorHandler500,
  otherErrorHandler,
} from "../utils";

const httpLink = new HttpLink({
  uri: `${window.API_URL}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }): Observable<FetchResult> | undefined => {
  if (graphQLErrors) {
    // TODO: show error message as not result of user input?
    // console.log(`[GraphQl error]: ${networkError}`);
  }
  if (networkError) {
    // console.log(`[Network error]: ${networkError}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { statusCode } = networkError;
    if (statusCode === 400) return errorHandler400(networkError);
    if (statusCode === 404) return errorHandler404(networkError);
    if (statusCode === 500) return errorHandler500(networkError);
    return otherErrorHandler(networkError);
  }
  return undefined;
});

export const apolloPublic = new ApolloClient({
  link: from(apolloParseJsonDatesLink ? [apolloParseJsonDatesLink, errorLink, httpLink] : []),
  cache: new InMemoryCache(),
  defaultOptions: apolloDefaultOptions,
});
