import { Helmet } from "react-helmet-async";

import { ProjectTypeData } from "../../../../route/public/project-type/useProjectType";

interface PublicProjectTypeMetaTagsProps {
  projectTypeData: ProjectTypeData;
}

export const PublicProjectTypeMetaTags = ({ projectTypeData }: PublicProjectTypeMetaTagsProps): JSX.Element | null => {
  return (
    <Helmet>
      <title>{projectTypeData?.hero.title}</title>
      <meta name="title" content={projectTypeData?.hero.title || undefined} />
      <meta name="description" content={projectTypeData?.aboutTheType.content || undefined} />
      <script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "Dataset",
            name: projectTypeData?.hero.title,
            description: projectTypeData?.aboutTheType.content,
          },
          null,
          "\t"
        )}
      </script>
    </Helmet>
  );
};
