/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "./VersionHistoryOpenActivityCell.css";

import { ArrowheadRightDarkIcon, EditFileBlueIcon } from "../../../../../../assets";
import { DataGridButtonLinkCellFormatterData } from "../../../../../../widget";
import { CellFormatter } from "../../../../../../widget/data/DataGrid/models";

export const VersionHistoryOpenActivityCellFormatter: CellFormatter<
  DataGridButtonLinkCellFormatterData | null | undefined
> = () => {
  return (value) => {
    if (!value || !value?.action || !value?.text) return <div />;

    return value.text === "Edit" ? (
      <div className="VersionHistoryEditButtonWrapper">
        <div className="VersionHistoryEditButton" onClick={value.action}>
          <EditFileBlueIcon />
        </div>
      </div>
    ) : (
      <div className="VersionHistoryViewButtonWrapper">
        <div className="VersionHistoryViewButton" onClick={value.action}>
          <ArrowheadRightDarkIcon />
        </div>
      </div>
    );
  };
};
