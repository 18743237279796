import { DeveloperSingleCardTemplate } from "../../../../../templates";
import { Column, InformationBox, Row } from "../../../../../widget";
import { InviteForm } from "./form";
import { useInvite } from "./useInvite";

export const Invite = (): JSX.Element => {
  const { handleBackClick } = useInvite();
  return (
    <DeveloperSingleCardTemplate
      pageTitle="New access invitation"
      handleBackClick={handleBackClick}
      backButtonText="Back to project access"
    >
      <Row spacingV="ll">
        <Column span={12}>
          <InformationBox variant="info">
            <div>
              <p className="body2">
                Granting access to your project will allow the chosen developer the ability to read, or read and edit,
                your selected project. Once granted, access can be later revoked.
              </p>
              <p className="body2">
                Sending an invitation will issue an notification to your selected developer organisation. The invitation
                request will expire after 10 days.
              </p>
            </div>
          </InformationBox>
        </Column>
      </Row>
      <InviteForm />
    </DeveloperSingleCardTemplate>
  );
};
