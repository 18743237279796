import { Dispatch, SetStateAction, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { downloadGeneratedDocument } from "../../../../route/developer/activities";
import { SearchActivityHistoryResponse } from "../../../../service/query";
import { ResultData } from "../../../../service/Shared";
import { flattenObject } from "../../../../utils";
import { DataGridColumnDefinition } from "../../../../widget";

interface UseVersionConflictModalReturnData {
  navigate: NavigateFunction;
  tableColumnsTopProjects: DataGridColumnDefinition[];
  data?: ResultData[];
  downloadDraft: () => Promise<void>;
  isDocumentDownloading: boolean;
  isResumeLatestActivityLoading: boolean;
  setIsResumeLatestActivityLoading: Dispatch<SetStateAction<boolean>>;
}

export const useVersionConflictModal = (
  draftActivityHistoryUuid?: string,
  newVersionActivityHistory?: SearchActivityHistoryResponse
): UseVersionConflictModalReturnData => {
  const navigate = useNavigate();

  const [isDocumentDownloading, setIsDocumentDownloading] = useState(false);
  const [isResumeLatestActivityLoading, setIsResumeLatestActivityLoading] = useState(false);

  const downloadDraft = async (): Promise<void> => {
    if (draftActivityHistoryUuid) {
      setIsDocumentDownloading(true);
      await downloadGeneratedDocument({ activityHistoryUuid: draftActivityHistoryUuid });

      setIsDocumentDownloading(false);
    }
  };

  const data = newVersionActivityHistory?.results?.map((el) => flattenObject(el));

  const tableColumnsTopProjects: DataGridColumnDefinition[] = [
    {
      key: "activity.activityDefinition.displayName",
      name: "Activity",
      dataType: "string",
      alignment: "left",
      formatter: "align",
    },
    {
      key: "versionNumber",
      name: "Version",
      dataType: "string",
      alignment: "left",
      formatter: "align",
    },
    {
      key: "createdByUser.fullName",
      name: "Created by",
      dataType: "string",
      alignment: "left",
      formatter: "align",
    },
    {
      key: "createdAt",
      name: "Date created",
      dataType: "Date",
      formatter: "dateOnly",
      alignment: "center",
      minWidth: 150,
      filterable: false,
    },
  ];

  return {
    navigate,
    tableColumnsTopProjects,
    data,
    downloadDraft,
    isDocumentDownloading,
    isResumeLatestActivityLoading,
    setIsResumeLatestActivityLoading,
  };
};
